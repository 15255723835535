import React, { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GenericNodeType, Path, ConditionOperator, Certification } from './types';
import { ConditionBuilderContext } from './ConditionBuilderContext';
import { addGroupNode, addSingleNode, moveElement, removeNode, setNegationValue, setOperatorValue } from './helpers';
import { DroppableWrapper } from './components/DroppableWrapper';
import { DraggableWrapper } from './components/DraggableWrapper';
import { StyledConditionBuilderWrapper } from './components/StyledComponents';
import { ErrorObject } from '../../helpers/types';

interface ConditionBuilderProps {
  value: GenericNodeType;
  errors?: ErrorObject;
  onChange: (v: GenericNodeType) => void;
}

export const ConditionBuilder = ({ value, onChange, errors = {} }: ConditionBuilderProps) => {
  const [targetPath, setTargetPath] = useState<Path>();

  const onDrop = (source: GenericNodeType) => {
    if (source && targetPath !== undefined) {
      onChange(moveElement(source.path, targetPath, value));
    }
    setTargetPath(undefined);
  };

  const conditionBuilderActions = useMemo(
    () => ({
      errors,
      removeNode: (path: Path) => onChange(removeNode(path, value)),
      addGroupNode: (path: Path) => onChange(addGroupNode(path, value)),
      addSingleNode: (path: Path, cert: Certification) => onChange(addSingleNode(path, cert, value)),
      setOperatorValue: (path: Path, operator: ConditionOperator) => onChange(setOperatorValue(path, operator, value)),
      setNegationValue: (path: Path, negation: boolean) => onChange(setNegationValue(path, negation, value)),
    }),
    [value, errors],
  );

  return (
    <StyledConditionBuilderWrapper>
      <ConditionBuilderContext.Provider value={conditionBuilderActions}>
        <DndProvider backend={HTML5Backend}>
          <DroppableWrapper element={value} onDrop={onDrop} onTargetChange={setTargetPath}>
            <DraggableWrapper root node={value} targetPath={targetPath} />
          </DroppableWrapper>
        </DndProvider>
      </ConditionBuilderContext.Provider>
    </StyledConditionBuilderWrapper>
  );
};
