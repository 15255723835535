import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import Cookie from 'js-cookie';
import { connect } from 'react-redux';
import { loadUser } from '../../../redux/modules/user';

const LoginSuccessHandler = (props: { loadUser: () => void } & RouteComponentProps): React.ReactElement<string> => {
  const urlParams = new URLSearchParams(props.location.search);
  const token = urlParams.get('token');
  const redirectUri = urlParams.get('redirect_uri');
  Cookie.set('access-token', token as string);
  props.loadUser();
  props.history.push(redirectUri as string);

  return <div>Working with your authorization...</div>;
};

export default connect(null, { loadUser })(withRouter(LoginSuccessHandler));
