import { AnyAction } from 'redux';
import { call, all, put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';

import { createNote } from '../../../api/notes';
import { toggleUpdateNotes } from '../modals';
import { ADD_CC_LOADED_NOTE, ADD_CC_LOADED_NOTE_WARNING } from './';

export function* addCCLoadedNoteSaga({ payload }: AnyAction) {
  const { id, cid, variant } = payload;
  try {
    yield call(createNote, { id, note: i18n.t('applications.cc.loadCC.loadedNote', { cid, variant }) });
    yield put(toggleUpdateNotes(true));
  } catch (e) {}
}

function* watchAddCCLoadedNoteSaga() {
  yield takeLatest(ADD_CC_LOADED_NOTE, addCCLoadedNoteSaga);
}

export function* addCCLoadedNoteSagaWarning({ payload }: AnyAction) {
  const { id } = payload;
  try {
    yield call(createNote, {
      id,
      note: i18n.t('applications.cc.loadCC.loadedNoteWarning'),
    });
    yield put(toggleUpdateNotes(true));
  } catch (e) {}
}

function* watchAddCCLoadedNoteSagaWarning() {
  yield takeLatest(ADD_CC_LOADED_NOTE_WARNING, addCCLoadedNoteSagaWarning);
}

export default function* watchMultipleAppFlowSagas() {
  yield all([watchAddCCLoadedNoteSaga(), watchAddCCLoadedNoteSagaWarning()]);
}
