// role unsupported
export const unsupportedRole = 'ROLE_UNSUPPORTED';

// member roles
export const regularMemberRole = 'ROLE_MEMBER_REGULAR';
export const implementerMemberRole = 'ROLE_MEMBER_IMPLEMENTER';
export const solutionProviderMemberRole = 'ROLE_MEMBER_SOLUTION_PROVIDER';
export const memberRoles = [regularMemberRole, implementerMemberRole, solutionProviderMemberRole];

// wfa staff roles
export const wfaEngineerRole = 'ROLE_WFA_STAFF_ENGINEER';
export const wfaAdminRole = 'ROLE_WFA_STAFF_ADMIN';
export const wfaRegularRole = 'ROLE_WFA_STAFF_REGULAR';
export const wfaStaffRoles = [wfaRegularRole, wfaEngineerRole, wfaAdminRole];

// atl roles
export const atlRegularRole = 'ROLE_ATL_REGULAR';
export const atlMemberRole = 'ROLE_ATL_MEMBER';
export const atlRoles = [atlRegularRole, atlMemberRole];
