import React from 'react';
import { PageTemplate } from '../../components/partials/page-template';
import { AxiosError } from 'axios';
import { messageKeyPrefix } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import { ValidationErrorResponse } from '../../helpers/types';
import { Button } from 'rsuite';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const Paragraph = styled.p`
  font-size: 2rem;
  margin-top: 2rem;
  text-align: center;
  color: #750000;
`;

const ErrorCard = ({ error, title = 'Failed' }: { error: AxiosError<ValidationErrorResponse>; title?: string }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { messageKey, message, messageParameters } = error.response?.data || {};
  const key = (messageKey || '').replace(messageKeyPrefix, '');
  let msg = t(key, messageParameters);
  if (!msg || msg === key) {
    msg = message || error.message;
  }
  return (
    <PageTemplate noFooter title={title} actionLeft={<Button onClick={() => history.goBack()}>Back</Button>}>
      <Paragraph className="text-muted mb-2">{msg}</Paragraph>
    </PageTemplate>
  );
};

export default ErrorCard;
