import React from 'react';
import { useTranslation } from 'react-i18next';

import { CCDetails } from '../../application/partials/cc-details/CCDetails';
import { ApplicationInfoRecord } from '../../application/types';
import { ResultsTable, TestResultTableRow } from '../../atl-testing/partials';
import { LRConformanceResults } from '../../atl-testing/types';
import { UploadedFile } from '../../../../shared-components/file-upload/types';
import { TestingResultsTable } from './TestingResultsTable';
import { NAComponent } from '../../../partials/NAComponent';
import { ExceptionsPanel } from '../../../partials/ExceptionsPanel';
import { TestingException } from '../../../../redux/modules/application-flow';
import { flat } from '../../../../helpers/array-helpers';
import { notEmpty } from '../../../../helpers/not-empty';
import { LRStatus } from '../../../../helpers/constants';
import { PreviouslyCertifiedLegendTitles } from '../../../../shared-components/styled-components/PreviouslyCertifiedLegend';
import { ExceptionTypes } from '../../../../components/pages/application/types';

interface ReviewAndApproveStep {
  application: ApplicationInfoRecord;
  onLrUpdate: () => void;
  isReview?: boolean;
  isTestingException?: boolean;
}

export const ReviewAndApproveStep: React.FC<ReviewAndApproveStep> = ({
  application,
  onLrUpdate,
  isReview = false,
  isTestingException,
}) => {
  const { t } = useTranslation();
  const { labRequests, componentCombination } = application;
  const haslabRequests = labRequests.length > 0;

  const conformanceResults = labRequests
    .filter(
      lr => [LRStatus.PENDING_APPROVAL, LRStatus.APPROVED].includes(lr.status) && lr.conformanceTestResult !== null,
    )
    .map(lr => ({
      fileToDownload: lr.conformanceTestResult as UploadedFile,
      conformanceResult: lr.conformanceTestResult as LRConformanceResults,
      conformanceReportToken: (lr.conformanceTestResult as LRConformanceResults).conformanceReportToken,
      lab: lr.lab,
      lrId: lr.id,
    }));

  const interoperabilityResults = flat<TestResultTableRow>(
    labRequests
      .filter(lr => [LRStatus.PENDING_APPROVAL, LRStatus.APPROVED].includes(lr.status))
      .map(lr =>
        lr.interoperabilityTestTemplateResults
          .filter(item => item.result !== null)
          .map(item => ({ fileToDownload: item.result as UploadedFile, lab: lr.lab, lrId: lr.id })),
      ),
  );

  const attachmentsTableData = flat<TestResultTableRow>(
    labRequests
      ?.filter(lr => [LRStatus.PENDING_APPROVAL, LRStatus.APPROVED].includes(lr.status))
      .map(lr => lr.attachments.map(attachment => ({ fileToDownload: attachment, lrId: lr.id, lab: lr.lab }))),
  );

  const labException = labRequests
    .filter(lr => [LRStatus.PENDING_APPROVAL, LRStatus.APPROVED].includes(lr.status))
    .map(lr => {
      return lr.testingException ? { exceptionType: ExceptionTypes.LAB_EXCEPTION, ...lr.testingException } : null;
    });

  const exceptions: TestingException[] = [
    application.testingException
      ? { exceptionType: ExceptionTypes.TEST_EXCEPTION, ...application.testingException }
      : null,
    application.coreCCException ? { exceptionType: ExceptionTypes.CC_EXCEPTION, ...application.coreCCException } : null,
    ...labException,
  ].filter(notEmpty);

  return (
    <>
      <h4 style={{ lineHeight: '1.3rem' }}>{t('staff.testResult.title')}</h4>
      <PreviouslyCertifiedLegendTitles
        title={t('applications.certifications.prevCertifiedLegend')}
        warningTitle={isTestingException ? t('certifications.warningTitleStaffReviewResult') : null}
      />
      <TestingResultsTable application={application} onLrUpdate={onLrUpdate} isReview={isReview} />
      <h4 className="mt-1">{t('testing.review.exceptionsRequested')}</h4>
      <ExceptionsPanel data={exceptions} />
      <h4 className="mt-1">
        {t('testing.uploadResults.testResultTitle', { type: t('applications.labs.conformance') })}
      </h4>
      {haslabRequests ? <ResultsTable data={conformanceResults} isConformanceResults showLab /> : <NAComponent />}
      <h4 className="mt-1">
        {t('testing.uploadResults.testResultTitle', { type: t('applications.labs.interoperability') })}
      </h4>

      {haslabRequests ? <ResultsTable data={interoperabilityResults} showLab /> : <NAComponent />}
      <h4 className="mt-1">{t('applications.cc.wifiComponent')}</h4>
      <CCDetails
        cc={componentCombination.componentCombination}
        isEditable={false}
        ccVariantSource={componentCombination.componentCombinationVariantSource}
      />
      <h4>{t('testing.uploadResults.additionalAttachments')}</h4>
      {haslabRequests ? <ResultsTable data={attachmentsTableData} isAttachments showLab /> : <NAComponent />}
    </>
  );
};
