import React, { CSSProperties, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

// @ts-ignore
import _variables from '../../styles/_variables.scss';

const ErrorText = styled.small`
  position: absolute;
  bottom: 4px;
  left: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  &:hover {
    overflow: unset;
  }
`;

const FormGroup = styled.div`
  padding-bottom: 1.2rem;
  position: relative;
  z-index: 1;

  .rs-form-control-wrapper > * {
    width: 100%;
  }

  .rs-form-control-wrapper > .rs-control-char-number {
    position: absolute;
    font-size: 10px;
    margin-top: 4px;
    text-align: right;
    color: ${_variables.textGray};
  }

  &.has-bold-title {
    .rs-control-label {
      font-weight: bold;
    }
  }
`;

interface Props {
  error?: string;
  noMargin: boolean;
  noPadding: boolean;
  style?: CSSProperties;
  className?: string;
  required: boolean;
  label?: React.ReactElement<string> | string;
  boldTitle?: boolean;
}

export const InputWrapper = ({
  error,
  noMargin,
  noPadding,
  style,
  className,
  required,
  children,
  label,
  boldTitle = false,
}: PropsWithChildren<Props>) => {
  return (
    <FormGroup
      className={classNames(
        'rs-form-group',
        {
          'has-error': Boolean(error),
          'm-0': noMargin,
          'p-0': noPadding,
          'has-bold-title': boldTitle,
        },
        className,
      )}
      role="group"
      style={style}
    >
      {label ? (
        <label className="rs-control-label">
          {required ? <span className="text-danger">* </span> : null}
          {label}
        </label>
      ) : null}
      <div className="rs-form-control-wrapper">{children}</div>
      {error ? <ErrorText className="text-danger">{error}</ErrorText> : null}
    </FormGroup>
  );
};
