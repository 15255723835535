/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import { clear, create, get, update } from './form-data';

export type FormUpdated = (handleChange: (e: ChangeEvent<{}>) => void, event: ChangeEvent<{}>) => void;

export const useChangeWrapper = (formId: string) => ({
  formUpdated: (handleChange: (e: ChangeEvent<{}>) => void, event: ChangeEvent<{}>) => {
    const target = event.target;
    update(formId, { [(target as HTMLInputElement).name]: (target as HTMLInputElement).value });
    handleChange(event);
  },
  formUpdatedByValue: (value: any) => {
    update(formId, value);
  },
  formCreated: (data: any) => create(formId, data),
  getForm: () => get(formId),
  clearForm: () => clear(formId),
});
