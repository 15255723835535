import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Row } from 'rsuite';

import { AppFlows, ApplicationFlowState } from '../../../../../../../redux/modules/application-flow';

import { ProductCategories, ProductInfoStep } from '../../../../../../../api/application/types';
import { jsonCopy } from '../../../../../../../helpers';
import { IsModifiedProps, numberSort, useChangeDetection } from '../../../../../../../helpers/hooks/useChangeDetection';
import { ErrorObject } from '../../../../../../../helpers/types';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { UploadedFile } from '../../../../../../../shared-components/file-upload/types';
import { WrapWithPublicIcon } from '../../../../../../../shared-components/helper-components/WrapWithPublicIcon';
import { LabeledBooleanField, LabeledInput } from '../../../../../../../shared-components/labeled-inputs';
import { RsControlLabel } from '../../../../../../../shared-components/rsuite';
import { Tooltip } from '../../../../../../../shared-components/tooltip/Tooltip';
import { FileUploader, UploadFileType } from '../../../../../../../shared-components/uploader/FileUploader';
import { DetailsItem } from '../../../../../../partials';
import { VariantNameTooltipContent } from '../../VariantNameTooltipContent';
import { isProductOfSolutionProvider } from '../../helpers';

const calculateHashValue = (value: ProductInfoStep) => {
  const info = jsonCopy(value);

  return JSON.stringify({
    modelVariants: [
      {
        ...info.modelVariants[0],
      },
    ],
    documents: info.documents.map(item => item.id).sort(numberSort),
  });
};

type Props = IsModifiedProps & {
  setLoading: (value: boolean) => void;
  data: ProductInfoStep;
  onChange: (
    value: string | ProductCategories | ProductCategories[] | UploadedFile | UploadedFile[] | boolean | null,
    name: string,
  ) => void;
  errors: ErrorObject;
};

export const EditVariantSection = ({ setLoading, setIsModified, data, onChange: handleChange, errors }: Props) => {
  const { t } = useTranslation();

  const { ...application } = useSelector<RootReducer, ApplicationFlowState>(
    state => state.applicationFlow,
    shallowEqual,
  );

  const [appDocLoading, setAppDocLoading] = useState(false);

  const isVariant = application['@type'] === AppFlows.VARIANT;

  const isCertifiedSelected = application.productInfo.certified !== null;

  const isQualifiedSolution = application.productInfo.qualifiedSolution;

  const isQualifying = application.productInfo.certified === false;

  const showQualifiedSolutionFields =
    isProductOfSolutionProvider(application) && application['@type'] !== AppFlows.DERIVATIVE;

  const isQualifiedSolutionSelected = isQualifiedSolution !== null;

  const isUseDraftCertified = isQualifiedSolution && isVariant && isQualifying;

  const isDraftCertified = application.productInfo.modelVariants[0].draftCertified === true;

  const updateChangeDetectionState = useChangeDetection(setIsModified, calculateHashValue, data);

  // due to data be modified from outside, should update dectction state
  if (
    showQualifiedSolutionFields &&
    isQualifiedSolution === false &&
    data.modelVariants[0].availableAsQualifiedSolution === true
  ) {
    data.modelVariants[0].availableAsQualifiedSolution = false;
    updateChangeDetectionState(data);
  }

  if (
    application.productInfo.certified === false &&
    application.productInfo.modelVariants[0].draftCertified === false &&
    (data.modelVariants[0].searchableByPublic === true || data.modelVariants[0].availableAsDerivative === true)
  ) {
    data.modelVariants[0].searchableByPublic = false;
    data.modelVariants[0].availableAsDerivative = false;
    updateChangeDetectionState(data);
  }

  useEffect(() => {
    setLoading(appDocLoading);
  }, [appDocLoading]);

  const onProductDocumentsChange = (files: UploadedFile[]): void => {
    handleChange(files, 'productInfo.documents');
  };

  return (
    <>
      <Form fluid>
        <Row gutter={20} className="mt-2">
          <Col xs={12}>
            <LabeledInput
              required
              label={
                <>
                  <WrapWithPublicIcon content={t('applications.info.variantName')} />
                  <Tooltip width={680} content={<VariantNameTooltipContent />} />
                </>
              }
              name="productInfo.modelVariants[0].name"
              placeholder={t('applications.info.variantName')}
              errors={errors}
              value={data.modelVariants[0].name}
              onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => handleChange(value, event.target.name)}
            />
          </Col>
          <Col xs={12}>
            <DetailsItem value={application.id} label={t('applications.summary.appId')} />
          </Col>
        </Row>
        {showQualifiedSolutionFields ? (
          <Row gutter={20} className="mt-2">
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.modelVariants[0].searchableByPublic"
                errors={errors}
                label={
                  <>
                    {t('applications.info.searchable')}
                    <Tooltip
                      content={
                        <>
                          {t('tooltips.searchableByPublic')}
                          <br /> <br />
                          {t('tooltips.searchableByPublicNote')}
                        </>
                      }
                    />
                  </>
                }
                disabled={
                  !(isQualifiedSolutionSelected && isCertifiedSelected) ||
                  (isUseDraftCertified && !isDraftCertified) ||
                  (!isUseDraftCertified && isQualifying)
                }
                value={data.modelVariants[0]?.searchableByPublic}
                onChange={value => handleChange(value, 'productInfo.modelVariants[0].searchableByPublic')}
              />
            </Col>
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.modelVariants[0].availableAsDerivative"
                errors={errors}
                label={
                  <>
                    {t('applications.info.available')}
                    <Tooltip translationKey="tooltips.availableAsSource" />
                  </>
                }
                disabled={
                  !(isQualifiedSolutionSelected && isCertifiedSelected) ||
                  (isUseDraftCertified && !isDraftCertified) ||
                  (!isUseDraftCertified && isQualifying)
                }
                value={data.modelVariants[0]?.availableAsDerivative}
                onChange={value => handleChange(value, 'productInfo.modelVariants[0].availableAsDerivative')}
              />
            </Col>
          </Row>
        ) : (
          <Row gutter={20} className="mt-2">
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.modelVariants[0].searchableByPublic"
                errors={errors}
                label={
                  <>
                    {t('applications.info.searchable')}
                    <Tooltip
                      content={
                        <>
                          {t('tooltips.searchableByPublic')}
                          <br /> <br />
                          {t('tooltips.searchableByPublicNote')}
                        </>
                      }
                    />
                  </>
                }
                value={data.modelVariants[0]?.searchableByPublic}
                onChange={value => handleChange(value, 'productInfo.modelVariants[0].searchableByPublic')}
              />
            </Col>
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.modelVariants[0].availableAsDerivative"
                errors={errors}
                label={
                  <>
                    {t('applications.info.available')}
                    <Tooltip translationKey="tooltips.availableAsSource" />
                  </>
                }
                value={data.modelVariants[0]?.availableAsDerivative}
                onChange={value => handleChange(value, 'productInfo.modelVariants[0].availableAsDerivative')}
              />
            </Col>
          </Row>
        )}
        <Row gutter={20} className="mb-2">
          <Col xs={12}>
            <FormGroup style={{ marginBottom: 0 }}>
              <RsControlLabel>{t('applications.info.docs')}</RsControlLabel>
              <FileUploader
                fileType={UploadFileType.APP_DOC}
                files={data.documents}
                onChange={onProductDocumentsChange}
                onLoadingChange={setAppDocLoading}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            {showQualifiedSolutionFields ? (
              <LabeledBooleanField
                required
                name="productInfo.modelVariants[0].availableAsQualifiedSolution"
                errors={errors}
                label={
                  <>
                    {t('applications.info.availableAsQualifiedSolution')}
                    <Tooltip translationKey="tooltips.availableAsQualifiedSolution" />
                  </>
                }
                disabled={!isQualifiedSolution || !isCertifiedSelected}
                value={data.modelVariants[0]?.availableAsQualifiedSolution}
                onChange={value => handleChange(value, 'productInfo.modelVariants[0].availableAsQualifiedSolution')}
              />
            ) : null}
          </Col>
        </Row>
      </Form>
    </>
  );
};
