import React from 'react';
import { DataTableColumnProps } from './DataTableColumn';
import { DataTableHeadComponentProps } from './DataTableHeadComponent';
import { DataTableSortingProps } from './types';

export interface DataTableHeadProps<T> extends Partial<DataTableSortingProps<T>> {
  expandable: boolean;
  settings: DataTableColumnProps<T>[];
  TableHeadComponent: (props: DataTableHeadComponentProps<T>) => JSX.Element;
  defaultExpanded: boolean;
  enableExpandAll: boolean;
  isExpandAll: boolean;
  onExpandAll?: (expanded: boolean) => void;
}

export function DataTableHead<T>(props: DataTableHeadProps<T>) {
  const { sorting, onChangeSorting, TableHeadComponent } = props;

  if (sorting !== undefined && onChangeSorting === undefined) {
    throw new Error('Invalid sorting settings');
  }

  const handleRequestSort = (property: string) => {
    if (sorting !== undefined && onChangeSorting !== undefined) {
      const isAsc = sorting.sortColumn === property && sorting.sortType === 'asc';
      onChangeSorting(property, isAsc ? 'desc' : 'asc');
    }
  };

  const getSortHandler = (property: string) => () => {
    handleRequestSort(property);
  };

  return <TableHeadComponent getSortHandler={getSortHandler} {...props} />;
}
