import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import ApplicationTypeDerivative from '../../../../../assets/tooltips-images/ApplicationTypeDerivative.png';
import ApplicationTypeProduct from '../../../../../assets/tooltips-images/ApplicationTypeProduct.png';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const useStyles = makeStyles({
  root: {
    width: 640,
    color: _var.textGray,
    padding: 10,
  },
  title: {
    color: _var.stepGreen,
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '1em',
  },
  table: {
    color: _var.white,
    display: 'flex',
  },
  tableColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  tableHeader: {
    background: _var.lightGreen,
    margin: 1,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    margin: 1,
    padding: '2px 10px',
    fontSize: 12,
    color: _var.textGray,
    background: _var.lightTeal,
    '& ul': {
      margin: '4px 0',
      paddingInlineStart: 15,
    },
  },
  tableFooter: {
    textAlign: 'center',
    '& img': {
      width: '70%',
    },
  },
});

export const VariantNameTooltipContent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <div className={classes.title}>{t('tooltips.variantName.title')}</div>
      <div className={classes.table}>
        <div className={classes.tableColumn}>
          <div className={classes.tableHeader}>{t('tooltips.variantName.definition')}</div>
          <div className={classes.tableContent}>
            <ul>
              <li>{t('tooltips.variantName.definitionLine1')}</li>
              <li>{t('tooltips.variantName.definitionLine2')}</li>
            </ul>
          </div>
        </div>
        <div className={classes.tableColumn}>
          <div className={classes.tableHeader}>{t('tooltips.variantName.examples')}</div>
          <div className={classes.tableContent}>
            <ul>
              <li>{t('tooltips.variantName.examplesLine1')}</li>
              <li>{t('tooltips.variantName.examplesLine2')}</li>
              <li>{t('tooltips.variantName.examplesLine3')}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ApplicationTypesTooltipContent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <div className={classes.title}>{t('tooltips.applicationTypes.title')}</div>
      <div className={classes.table}>
        <div className={classes.tableColumn}>
          <div className={classes.tableHeader}>{t('tooltips.applicationTypes.product')}</div>
          <div className={classes.tableContent}>{t('tooltips.applicationTypes.productDescription')}</div>
          <div className={classes.tableFooter}>
            <img src={ApplicationTypeProduct} />
          </div>
        </div>
        <div className={classes.tableColumn}>
          <div className={classes.tableHeader}>{t('tooltips.applicationTypes.variant')}</div>
          <div className={classes.tableContent}>{t('tooltips.applicationTypes.variantDescription')}</div>
          <div className={classes.tableFooter}>
            <img src={ApplicationTypeProduct} />
          </div>
        </div>
        <div className={classes.tableColumn}>
          <div className={classes.tableHeader}>{t('tooltips.applicationTypes.derivative')}</div>
          <div className={classes.tableContent}>{t('tooltips.applicationTypes.derivativeDescription')}</div>
          <div className={classes.tableFooter}>
            <img src={ApplicationTypeDerivative} />
          </div>
        </div>
      </div>
    </div>
  );
};
