import { prepareYupModel } from '../../../../../helpers';
import { ErrorObject } from '../../../../../helpers/types';
import { Schema } from 'yup';
import { SetStateAction } from 'react';
import { TreeItem } from 'react-sortable-tree';

export function validate<T>(schema: Schema<T>, data: T, setErrors: (x: SetStateAction<ErrorObject>) => void) {
  const errors = prepareYupModel(schema).checkFormatted(data);
  if (errors) {
    setErrors(errors as ErrorObject);
    return false;
  }
  return true;
}

export const validateMinAndMaxChildrenLimit = (
  countChildren: number,
  countMandatory: number,
  minChildren: number,
  maxChildren: number,
): boolean[] => {
  const isErrorChildrenFields = [false, false];

  if (countChildren < 1) return isErrorChildrenFields;

  if (maxChildren >= 0) {
    if (minChildren > maxChildren) {
      isErrorChildrenFields[0] = true;
      isErrorChildrenFields[1] = true;
    }
    if (
      maxChildren < countMandatory ||
      maxChildren > countChildren ||
      (maxChildren === countMandatory && countChildren > countMandatory) ||
      maxChildren === 0
    )
      isErrorChildrenFields[1] = true;
  }
  if (minChildren < countMandatory || minChildren > countChildren) isErrorChildrenFields[0] = true;
  return isErrorChildrenFields;
};

export const validateTreeDataChildrenLimit = (treeData: TreeItem[]): boolean => {
  let isValidate = true;

  for (let i = 0; i < treeData.length; i++) {
    let isErrorChildrenFields = [false, false];
    const item = treeData[i];
    if (!item.children || item.children.length == 0) {
      item.isErrorChildrenFields = isErrorChildrenFields;
      continue;
    }

    // do self check
    const countChildren = item.children.length;

    if (countChildren >= 1) {
      const countMandatory = (item.children as TreeItem[]).reduce((previous, item) => {
        return item.mandatory === true ? ++previous : previous;
      }, 0);

      isErrorChildrenFields = validateMinAndMaxChildrenLimit(
        countChildren,
        countMandatory,
        item.minChildren,
        item.maxChildren,
      );

      const isErrorChildren = isErrorChildrenFields.some(el => el === true);

      isValidate = !isErrorChildren && isValidate;
      // if error, expand this node
      if (isErrorChildren) item.expanded = true;
    }

    item.isErrorChildrenFields = isErrorChildrenFields;

    // do children check
    isValidate = validateTreeDataChildrenLimit(item.children as TreeItem[]) && isValidate;
  }

  return isValidate;
};
