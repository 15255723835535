import React from 'react';
import { ErrorObject } from '../../helpers/types';
import { DatePicker } from 'rsuite';
import { DatePickerProps } from 'rsuite/lib/DatePicker';
import { withLabel, WrappedInputProps } from './withLabel';
import { extractError } from './utils';

const Field = ({ name, placeholder, value, ...props }: DatePickerProps) => {
  return <DatePicker placeholder={placeholder} name={name} type="text" value={value} {...props} />;
};
const MemorizedField = React.memo(withLabel<DatePickerProps>(Field), (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value) &&
    prevProps.error === nextProps.error &&
    prevProps.disabled === nextProps.disabled
  );
});

interface LabeledFieldProps extends DatePickerProps {
  errors?: ErrorObject;
}
export const LabeledDatePicker = ({ errors, name, ...props }: WrappedInputProps<LabeledFieldProps>) => {
  // @ts-ignore
  return <MemorizedField name={name} error={extractError(errors, name)} {...props} />;
};
