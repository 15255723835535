import React from 'react';
import { Icon } from 'rsuite';
import { TestResult } from '../../../../redux/modules/testing-flow/types';
// @ts-ignore
import _scss from '../../../../styles/_variables.scss';
import styled from 'styled-components';
import { customIconsSet } from '../../../../helpers/constants';

const StyledIcon = styled(Icon)`
  &.dotted {
    padding: 5px;
    border: 2px dashed ${_scss.headerGray};
  }
`;

export const TestingResultIcon = ({
  testingResult,
  className,
}: {
  testingResult: TestResult | string | null;
  className?: string;
}) => {
  if (testingResult === TestResult.SUCCESS) {
    return <StyledIcon className={className} icon="check" />;
  }
  if (testingResult === TestResult.FAIL) {
    return <StyledIcon className={className} icon={customIconsSet.ANGLE_WITH_CROSS} size="lg" />;
  }
  if (testingResult === TestResult.NOT_TESTED) {
    return <StyledIcon className={className} icon={customIconsSet.QUESTION_SQUARE} size="lg" />;
  }
  return null;
};
