import { call, put, takeLatest, all } from 'redux-saga/effects';

import { LOAD_CATEGORIES, categoriesLoaded } from './';
import { appFinishLoading } from '../app';
import { getCategories } from '../../../api/application';

export function* getCategoriesSaga() {
  try {
    const response = yield call(getCategories);
    yield put(categoriesLoaded(response.data));
    yield put(appFinishLoading());
  } catch (e) {
    yield put(appFinishLoading());
  }
}

function* watchGetCategoriesSaga() {
  yield takeLatest(LOAD_CATEGORIES, getCategoriesSaga);
}

export default function* watchMultipleCategoriesSagas() {
  yield all([watchGetCategoriesSaga()]);
}
