import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTableColumn } from '../../../../../shared-components/table/DataTableColumn';
import DataTable from '../../../../../shared-components/table/DataTable';
import { SummaryTableWithoutTabProps } from '../../../../../helpers/types';
import { ApplicationStatuses, ApplicationTypes, Transaction, TransactionWithChildren } from '../types';
import { reformatDate } from '../../../../../helpers';
import TransactionsActions from './TransactionsActions';
import { Anchor, AnchorLink } from '../../../../../shared-components/styled-components';
import { salesForceUrl } from '../../../../../config';
import { formatFee } from '../../../../../helpers/billing';
import TransactionsBadge from './TransactionsBadge';

export const getTransactionKeyForAppType = (status: ApplicationTypes) => {
  switch (status) {
    case ApplicationTypes.NEW:
      return 'admin.transactions.applicationStatus.new';
    case ApplicationTypes.VARIANT:
      return 'admin.transactions.applicationStatus.variant';
    case ApplicationTypes.DERIVATIVE:
      return 'admin.transactions.applicationStatus.derivative';
    default:
      return '';
  }
};

export const TransactionsTable = (props: SummaryTableWithoutTabProps<Transaction>) => {
  const { data, loading, onChangeSorting, sorting, total, activePage, setActivePage } = props;
  const { t } = useTranslation();

  const reformattedData: TransactionWithChildren[] = useMemo(
    () =>
      data.reduce((acc: TransactionWithChildren[], transaction: Transaction) => {
        return [
          ...acc,
          {
            ...transaction,
            children: transaction.events.map((event, index) => ({
              ...event,
              showView:
                !!event.opportunity &&
                (index > 0
                  ? event.eventId !== transaction.events[index - 1].eventId
                  : event.eventId !== transaction.eventId),
            })),
            showView: !!transaction.opportunity && !!transaction.eventId,
          },
        ];
      }, []),
    [data],
  );

  const getAppStatusLabel = (status: ApplicationTypes) => {
    return t(getTransactionKeyForAppType(status));
  };

  return (
    <DataTable<Transaction>
      expandable
      pagination
      sticky
      data={reformattedData}
      total={total}
      sorting={sorting}
      loading={loading}
      activePage={activePage}
      setActivePage={setActivePage}
      onChangeSorting={onChangeSorting}
      rowKey={(row: Transaction) => row.eventId + row.opportunity + row.description}
      defaultExpanded
      data-test-id="transactionsTable"
      useEnvIdentifier={true}
    >
      <DataTableColumn
        field="appEvent"
        label={t('admin.transactions.appEvent')}
        minWidth={100}
        wordWrap
        textAlign="center"
      >
        {(row: Transaction) => <TransactionsBadge status={row.decisionKind} />}
      </DataTableColumn>
      <DataTableColumn field="cid" label={t('admin.transactions.cid')} minWidth={100} sortable>
        {(row: Transaction, nested) =>
          !nested ? (
            row.applicationStatus !== ApplicationStatuses.REVOKED ? (
              <AnchorLink to={`/product/${row.productId}/${row.productModelVariantId}`}>{row.cid}</AnchorLink>
            ) : (
              <span style={{ color: '#641246', fontWeight: 'bold' }}>{row.cid}</span>
            )
          ) : null
        }
      </DataTableColumn>
      <DataTableColumn field="applicationId" label={t('admin.transactions.appId')} minWidth={90} sortable>
        {(row: Transaction, nested) =>
          !nested ? <AnchorLink to={`/application/${row.applicationId}`}>{row.applicationId}</AnchorLink> : null
        }
      </DataTableColumn>
      <DataTableColumn field="applicationType" label={t('admin.transactions.appType')} minWidth={170} sortable wordWrap>
        {(row: Transaction, nested) => (!nested ? getAppStatusLabel(row.applicationType) : null)}
      </DataTableColumn>
      <DataTableColumn
        field="opportunity"
        label={t('admin.transactions.opportunityId')}
        minWidth={180}
        sortable
        wordWrap
      >
        {(row: Transaction) => (
          <Anchor href={`${salesForceUrl}${row.opportunity}`} target="_blank">
            {row.opportunity}
          </Anchor>
        )}
      </DataTableColumn>
      <DataTableColumn
        field="description"
        label={t('admin.transactions.feeDescription')}
        minWidth={180}
        sortable
        wordWrap
      />
      <DataTableColumn field="amount" label={t('admin.transactions.amount')} minWidth={100} sortable wordWrap>
        {(row: Transaction) => (row.amount ? formatFee(row.amount) : '')}
      </DataTableColumn>
      <DataTableColumn
        field="owningCompany"
        label={t(`admin.transactions.owningCompany`)}
        minWidth={180}
        sortable
        wordWrap
      />
      <DataTableColumn
        sortable
        field="requestingAccount"
        label={t('admin.transactions.requestingAccount')}
        minWidth={250}
      />
      <DataTableColumn field="date" label={t(`admin.transactions.opportunityCreated`)} minWidth={200} sortable wordWrap>
        {(row: Transaction) => (row.date ? reformatDate(row.date) : null)}
      </DataTableColumn>
      <DataTableColumn field="processed" label={t('admin.transactions.status.title')} minWidth={90} sortable>
        {(row: Transaction) =>
          row.processed ? (
            `${t('admin.transactions.status.succeed')}`
          ) : (
            <span className="text-danger">{t('admin.transactions.status.failed')}</span>
          )
        }
      </DataTableColumn>
      <DataTableColumn field="actions" label={t('common.actions.title')} width={95}>
        {(row: Transaction) => <TransactionsActions row={row} />}
      </DataTableColumn>
    </DataTable>
  );
};
