import React from 'react';
import { KeyboardDatePicker as MaterialKeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

// @ts-ignore
import _var from '../../styles/_variables.scss';

type StylesError = {
  hasError: boolean;
};

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      padding: 0,
      height: 36,
    },
    '& .MuiInputBase-input': {
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 11,
      fontSize: 14,
    },
    '& .MuiInputAdornment-positionStart': {
      position: 'absolute',
      left: 125,
    },
    '& .MuiIconButton-root': {
      padding: 0,
      margin: 11,
    },
    '& .MuiFormHelperText-root': {
      color: _var.errorRed,
      marginTop: 2,
      fontWeight: 400,
      lineHeight: 1.66,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: ({ hasError }: StylesError) => (hasError ? _var.errorRed : _var.grayBorder),
        color: _var.textLightGray,
      },
      '&:hover fieldset': {
        borderColor: ({ hasError }: StylesError) => (hasError ? _var.errorRed : _var.primary),
      },
      '&.Mui-focused fieldset': {
        borderColor: ({ hasError }: StylesError) => (hasError ? _var.errorRed : _var.primary),
      },
      '&.Mui-error': {
        '&.Mui-focused fieldset': {
          borderColor: _var.errorRed,
        },
        '&:hover fieldset': {
          borderColor: _var.errorRed,
        },
      },
    },
  },
});

interface DatePickerProps {
  error: boolean;
  handleClearInput: () => void;
}

export const DatePicker: React.FC<DatePickerProps & KeyboardDatePickerProps> = ({
  error,
  value,
  variant,
  onChange,
  handleClearInput,
  ...rest
}) => {
  const classes = useStyles({ hasError: error });

  return (
    <div className={classes.root}>
      <MaterialKeyboardDatePicker
        value={value}
        onChange={onChange}
        variant={variant}
        style={{ maxWidth: 200, margin: 'auto' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleClearInput}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </div>
  );
};
