import * as actionTypes from './actionTypes';
import { LRConformanceResults, LabRequest, LRCertification } from '../../../components/pages/atl-testing/types';
import { LRStatus } from '../../../helpers/constants';
import { ApplicationInfoRecord } from '../../../components/pages/application/types';
import { UploadedFile } from '../../../shared-components/file-upload/types';
import { TestResult } from './types';
import { SET_INTEROPERABILITY_RESULT_FILE } from './actionTypes';
import { TestingException } from '../application-flow';
import { ErrorObject } from '../../../helpers/types';

export function labRequestLoaded(payload: LabRequest) {
  return {
    type: actionTypes.LAB_REQUEST_LOADED,
    payload,
  };
}

export function updateLabRequestProperty(
  value:
    | { id: number }
    | { state: LRStatus }
    | { certifications: LRCertification[] }
    | { application: ApplicationInfoRecord }
    | { attachments: UploadedFile[] }
    | string
    | UploadedFile[],
  name = '',
) {
  return {
    type: actionTypes.UPDATE_LAB_REQUEST_PROPERTY,
    payload: { value, name },
  };
}

export function setInteroperabilityResult(id: number, parentId: number | null, value: TestResult) {
  return { type: actionTypes.SET_INTEROPERABILITY_RESULT, payload: { id, parentId, value } };
}

export function setConformanceResults(payload: LRConformanceResults) {
  return { type: actionTypes.SET_CONFORMANCE_RESULTS, payload };
}

export function setInteroperabilityResultFile(templateId: number, resultFile: UploadedFile | null) {
  return {
    type: SET_INTEROPERABILITY_RESULT_FILE,
    payload: { templateId, resultFile },
  };
}

export function updateExplanation(payload: TestingException | null) {
  return {
    type: actionTypes.UPDATE_EXPLANATION,
    payload: payload,
  };
}

export function updateValidationErrors(payload: ErrorObject) {
  return {
    type: actionTypes.SET_VALIDATION_ERRORS,
    payload,
  };
}

export function restoreTestingFlowData() {
  return {
    type: actionTypes.RESTORE_TESTING_DATA,
  };
}
