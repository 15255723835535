import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { YesNoColumn } from '../../../../shared-components';
import DataTable from '../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../shared-components/table/DataTableColumn';
import { WarningTooltip } from '../../../../shared-components/tooltip/WarningTooltip';
import { Tooltip } from '../../../../shared-components/tooltip/Tooltip';
import { selectUser } from '../../../../redux/modules/user/selectors';
import { DecisionType } from '../../../../api/application/types';
import { RightActions } from './HeaderActions';
import { wfaStaffRoles } from '../../../../helpers/constants';
import { SummaryTableWithoutTabProps } from '../../../../helpers/types';
import { jsonCopy, mergeObjects, reformatDate } from '../../../../helpers';
import { CertificationStatuses } from '../../../../helpers/constants/certification-statuses';
import { ReformattedSummaryProducts, SingleSummaryProduct } from '../types';
import { productStatuses } from '../../../../helpers/constants/product-statuses';
import styled from 'styled-components';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

// @ts-ignore
import _var from '../../../../styles/_variables.scss';

const InfoIconOutlined = styled(InfoOutlined)`
  color: ${_var.primary};
  margin-right: 10px;
  margin-left: 10px;
  && {
    font-size: 22px;
  }
`;

export const SummaryTable = (props: SummaryTableWithoutTabProps<SingleSummaryProduct> & { status?: string }) => {
  const { data, loading, onChangeSorting, sorting, total, activePage, setActivePage, status: statusTab } = props;
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const showQualifiedSolution = wfaStaffRoles.includes(user.authority) || user.company.qualifiedSolutionProvider;
  const isStaff = wfaStaffRoles.includes(user.authority);
  const reformattedData: ReformattedSummaryProducts[] = useMemo(
    () =>
      jsonCopy(data).map(({ variants, ...item }) =>
        mergeObjects(item, {
          children: variants.map(variant => {
            delete variant.variants;
            return variant;
          }),
        }),
      ),
    [data],
  );

  const getTooltipTextStatus = (status: CertificationStatuses) => {
    switch (status) {
      case CertificationStatuses.PUBLISHED:
        return t('products.summary.certificationWarning.published');
      case CertificationStatuses.FIRST_RETIREMENT_PHASE:
        return t('products.summary.certificationWarning.firstRetirementPhase');
      case CertificationStatuses.SECOND_RETIREMENT_PHASE:
        return t('products.summary.certificationWarning.secondRetirementPhase');
      case CertificationStatuses.THIRD_RETIREMENT_PHASE:
        return t('products.summary.certificationWarning.thirdRetirementPhase');
      default:
        return '';
    }
  };

  const getWarningIcons = (status: CertificationStatuses) => {
    if (status === CertificationStatuses.PUBLISHED) {
      return (
        <Tooltip translationKey={getTooltipTextStatus(status)}>
          <CheckCircleIcon style={{ fill: _var.statusPublishedText, margin: 10 }} />
        </Tooltip>
      );
    }
    return <WarningTooltip status={status} variant="rounded" translationKey={getTooltipTextStatus(status)} />;
  };

  const hasRetirementWarning = (status: CertificationStatuses | undefined) => {
    switch (status) {
      case CertificationStatuses.FIRST_RETIREMENT_PHASE:
        return true;
      case CertificationStatuses.SECOND_RETIREMENT_PHASE:
        return true;
      case CertificationStatuses.THIRD_RETIREMENT_PHASE:
        return true;
      default:
        return false;
    }
  };

  const hasChildrenWarningRow = (row: ReformattedSummaryProducts[]) =>
    row.reduce((result: boolean, item: ReformattedSummaryProducts) => {
      result = !item.canCreateDerivative || hasRetirementWarning(item.worstCertificationVersionStatus);
      return result;
    }, false);

  const getVariantCount = (cid: string) => {
    const targetProduct = data.find(p => p.cid === cid);
    if (targetProduct && targetProduct.variants) return targetProduct.variants.length + 1;
    return 0;
  };

  return (
    <DataTable<ReformattedSummaryProducts>
      sticky
      expandable
      pagination
      data={reformattedData}
      total={total}
      sorting={sorting}
      loading={loading}
      activePage={activePage}
      setActivePage={setActivePage}
      onChangeSorting={onChangeSorting}
      rowKey={(row: ReformattedSummaryProducts) => row.id + row.variantName}
      warningRedBorder={(row: ReformattedSummaryProducts) =>
        !row.canCreateDerivative || hasRetirementWarning(row.worstCertificationVersionStatus)
      }
      warningRowChildrenBorder={(row: ReformattedSummaryProducts) => hasChildrenWarningRow(row.children)}
      useEnvIdentifier={true}
    >
      <DataTableColumn field="cid" label={t('applications.summary.cid')} width={120} sortable>
        {(row: ReformattedSummaryProducts, nested) => (!nested ? row.cid : null)}
      </DataTableColumn>
      <DataTableColumn
        field="productName"
        label={t('applications.summary.name')}
        width={statusTab === productStatuses.qualified ? '16%' : '20%'}
        sortable
        wordWrap
      >
        {(row: ReformattedSummaryProducts, nested) => (!nested ? row.productName : null)}
      </DataTableColumn>
      <DataTableColumn field="variantName" label={t('applications.info.variantName')} width="10%" sortable wordWrap />
      <DataTableColumn field="modelNumber" label={t('applications.info.modelNumber')} width="10%" sortable wordWrap />
      <DataTableColumn field="owningCompanyName" label={t('products.summary.company')} width="12%" sortable />
      <DataTableColumn field="searchable" label={t('applications.info.searchable')} width={140} sortable>
        {(row: ReformattedSummaryProducts) => <YesNoColumn value={row.searchable} />}
      </DataTableColumn>
      <DataTableColumn field="available" label={t('applications.info.availableForDerivatives')} width={170} sortable>
        {(row: ReformattedSummaryProducts) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <YesNoColumn value={row.available} />
            {!row.canCreateDerivative && (
              <WarningTooltip
                variant="outlined"
                translationKey={'products.warningContent'}
                status={CertificationStatuses.THIRD_RETIREMENT_PHASE}
              />
            )}
          </div>
        )}
      </DataTableColumn>
      {showQualifiedSolution && statusTab === productStatuses.qualified && (
        <DataTableColumn
          field="availableAsQualifiedSolution"
          label={t('applications.info.availableAsQualifiedSolution')}
          width={180}
          sortable
        >
          {(row: ReformattedSummaryProducts) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <YesNoColumn value={row?.availableAsQualifiedSolution} />
              {row.worstCertificationVersionStatus && getWarningIcons(row.worstCertificationVersionStatus)}
            </div>
          )}
        </DataTableColumn>
      )}
      {statusTab === productStatuses.qualified && (
        <DataTableColumn field="certified" label={t('applications.info.isCertified')} width={120} sortable>
          {(row: ReformattedSummaryProducts) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <YesNoColumn value={DecisionType.CERTIFY === row.status} />
              {DecisionType.QUALIFY === row.status && row.certified && isStaff ? (
                <Tooltip translationKey={'tooltips.qualfiedToCertified'}>
                  <InfoIconOutlined />
                </Tooltip>
              ) : null}
            </div>
          )}
        </DataTableColumn>
      )}
      <DataTableColumn
        field="date"
        label={
          statusTab === productStatuses.qualified
            ? t('applications.summary.dates.QUALIFIED')
            : t('applications.summary.dates.CERTIFIED')
        }
        width={120}
        sortable
      >
        {(row: ReformattedSummaryProducts) => (row.date ? `${reformatDate(row.date)}` : null)}
      </DataTableColumn>
      <DataTableColumn field="actions" label={t('common.actions.title')} minWidth={100}>
        {(row: ReformattedSummaryProducts) => (
          <RightActions
            productId={row.id}
            privateCertToken={row.privateCertificateToken}
            authority={user.authority}
            variantId={row.variantId}
            companyId={row.owningCompanyId}
            publicVariant={row.publicVariant !== false}
            certified={row.status === DecisionType.CERTIFY}
            isRetirementCertStatus={!row.canCreateDerivative}
            canBeUsedAsSource={row.available}
            showViewBtn
            cid={row.cid}
            totalVariants={getVariantCount(row.cid)}
          />
        )}
      </DataTableColumn>
    </DataTable>
  );
};
