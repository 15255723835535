/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SyntheticEvent, useState } from 'react';
import { AutoCompleteProps } from './types';
import Suggestions from './Suggestions';
import Input from '../../input/Input';

const defaultValue: any[] = [];
export const AutoCompleteContext = React.createContext(defaultValue);
const AutoCompleteComponent = (props: AutoCompleteProps) => {
  const emptySuggestions: any[] = [];
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFocus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState(emptySuggestions);
  const { disabled, placeholder, name, size, onIconClick, popperClass, customItem } = props;

  const select = (item: any): void => {
    setInputValue(item.value || '');
    setSuggestions([]);

    if (props.onSelect) {
      props.onSelect(item);
    }
  };
  const suggestionVisible = (): boolean => {
    const isValidData = Array.isArray(suggestions) && suggestions.length > 0;
    return (isValidData || loading) && isFocus;
  };

  const getData = (queryString: string): void => {
    setLoading(true);
    props.fetchSuggestions(queryString, (suggestions: any[]) => {
      setLoading(false);

      if (Array.isArray(suggestions)) {
        setSuggestions(suggestions);
      }
    });
  };
  const handleChange = (event: any): void => {
    setInputValue(event.target.value);

    if (!props.triggerOnFocus && !event.target.value) {
      setSuggestions([]);
      return;
    }

    if (props.onChange) {
      props.onChange(event);
    }

    getData(event.target.value);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFocus = (e: SyntheticEvent<any>): void => {
    setFocus(true);
    if (props.onFocus) {
      props.onFocus(e);
    }
    if (props.triggerOnFocus) {
      getData(inputValue);
    }
  };
  const handleKeyEnter = (highlightedIndex: number): void => {
    if (suggestionVisible() && highlightedIndex >= 0 && highlightedIndex < suggestions.length) {
      select(suggestions[highlightedIndex]);
    }
  };
  const highlight = (index: number): void => {
    if (!suggestionVisible() || loading) {
      return;
    }
    if (index < 0) {
      index = 0;
    }
    if (index >= suggestions.length) {
      index = suggestions.length - 1;
    }
    setHighlightedIndex(index);
  };
  const onKeyDown = (e: any): void => {
    switch (e.keyCode) {
      case 13:
        handleKeyEnter(highlightedIndex);
        break;
      case 38:
        highlight(highlightedIndex - 1);
        break;
      case 40:
        highlight(highlightedIndex + 1);
        break;
      default:
        break;
    }
  };
  return (
    <div className={'el-autocomplete'}>
      <AutoCompleteContext.Provider value={[customItem, loading, highlightedIndex, select]}>
        <Input
          value={inputValue}
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          size={size}
          onIconClick={onIconClick}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={props.onBlur}
          onKeyDown={onKeyDown}
          type="text"
        />
        <Suggestions className={popperClass || ''} suggestions={suggestions} />
      </AutoCompleteContext.Provider>
    </div>
  );
};

export default AutoCompleteComponent;
