import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { Col, FlexboxGrid } from 'rsuite';

import { getSingleProduct, getVariantsByProductModelId } from '../../../api/product';
import { SingleProductRecord, VariantInfo } from './types';
import { DecisionType } from '../../../api/application/types';
import { DetailsItem, PageTemplate } from '../../partials';
import { RightActions } from './partials/HeaderActions';
import { User } from '../../../redux/modules/user/types';
import { userState } from '../../../redux/modules/user/reducer';
import { confirmAction, getStoredSummaryUrl, mergeObjects } from '../../../helpers';
import { ProductSummarySidebar } from './partials/ProductSummarySidebar';
import { ProductVariantWithCCDetails } from './partials/ProductVariantWithCCDetails';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { EditableProductInfo } from './partials/EditableProductInfo';
import { RowWithMargin, Section } from '../application/partials';
import { usePageChangeDetection } from '../../../helpers/hooks/useChangeDetection';
import { CertificationStatuses } from '../../../helpers/constants/certification-statuses';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import { closeWindow } from '../../../helpers';
import { BackToListButton } from '../../../shared-components/button/BackToListButton';
import { CloseButton } from '../../../shared-components/button/CloseButton';

export interface AsteriskState {
  productInfo: boolean;
  variantInfo: boolean;
}
const ProductDetailsComponent = ({ history, match, location }: RouteComponentProps<{ id: string }>) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const user = useSelector<{ userReducer: userState }, User>(state => state.userReducer.user as User);
  const [loading, setLoading] = useState(true);
  const [productItem, setProductItem] = useState<SingleProductRecord | null>(null);
  const [variants, setVariants] = useState<VariantInfo[]>([]);
  const [showAsteriskLegend, setShowAsteriskLegend] = useState<AsteriskState>({
    productInfo: false,
    variantInfo: false,
  });

  const [modifiedSections, setModifiedSections] = useState({
    productInfo: false,
    variantInfo: false,
  });

  const { isModified, resetChangeDetection } = usePageChangeDetection(value => JSON.stringify(value), modifiedSections);

  useEffect(() => {
    getSingleProduct(Number(match.params.id))
      .then((response: AxiosResponse<SingleProductRecord>) => {
        setProductItem(response.data);
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        handleRequestFail(error, addToast);
        history.push(getStoredSummaryUrl('product'));
        setLoading(false);
      });
  }, []);

  const getVariantId = () => {
    const variantId = Number(new URLSearchParams(location.search).get('variantId'));
    if (variantId && !Number.isNaN(variantId)) {
      return variantId;
    }
    return null;
  };

  const updateModifiedSections = (section: string) => (value: boolean) => {
    setModifiedSections(prevState => ({ ...prevState, [section]: value }));
  };

  const onBackToList = () =>
    confirmAction(
      () => isModified,
      () => {
        resetChangeDetection();
        history.push(getStoredSummaryUrl('product'));
      },
    );

  useEffect(() => {
    if (productItem?.id) {
      const variantId = getVariantId();
      setLoading(true);
      getVariantsByProductModelId(productItem.id)
        .then((response: AxiosResponse<VariantInfo[]>) => {
          // as we have an ID of product and have the same property for variant
          const variants = response.data;
          const variantData = variantId ? variants.find(variant => variant.id === variantId) : variants[0];
          setProductItem(
            mergeObjects(productItem, {
              variant: variantData,
            }),
          );
          setVariants(variants);
          setLoading(false);
        })
        .catch((error: AxiosError) => {
          handleRequestFail(error, addToast);
          setLoading(false);
        });
    }
  }, [productItem?.id]);

  if (loading || productItem === null) {
    return (
      <section style={{ height: '200px' }}>
        <CircularLoader backdrop content={t('common.placeholders.loadingData')} />
      </section>
    );
  }

  const isRetirementCertStatus = productItem?.variant?.certifications?.some(cert => {
    return (
      cert.version?.status === CertificationStatuses.THIRD_RETIREMENT_PHASE ||
      cert.version?.status === CertificationStatuses.SECOND_RETIREMENT_PHASE
    );
  });

  return (
    <PageTemplate
      title={t('products.viewProduct')}
      actionLeft={
        window.opener ? (
          <CloseButton onClick={closeWindow} />
        ) : (
          <BackToListButton className="btn-close" onClick={onBackToList} />
        )
      }
      footerActionLeft={<></>}
      actionRight={
        <RightActions
          productId={productItem.id}
          variantId={productItem.variant?.id}
          privateCertToken={productItem.variant?.privateCertificateToken}
          isFlowPage
          showWarning={isModified}
          authority={user.authority}
          companyId={productItem.company.id}
          publicVariant={productItem.variant?.publicVariant}
          certified={DecisionType.CERTIFY === productItem.variant.variantStatus}
          isRetirementCertStatus={isRetirementCertStatus}
          canBeUsedAsSource={productItem.variant?.availableAsDerivative}
          cid={productItem.cid}
          totalVariants={variants.length}
        />
      }
      footerAddition={
        (showAsteriskLegend.productInfo || showAsteriskLegend.variantInfo) && (
          <span className="text-muted pr-1">
            <span className="text-danger text-bold">* </span> {t('common.requiredFields')}
          </span>
        )
      }
    >
      <FlexboxGrid className="page-container" justify="space-between">
        <FlexboxGrid.Item colspan={18}>
          <EditableProductInfo
            productItem={productItem}
            updateProduct={setProductItem}
            setAsteriskLegend={setShowAsteriskLegend}
            setIsModified={updateModifiedSections('productInfo')}
          />
          {productItem.variant ? (
            <ProductVariantWithCCDetails
              setAsteriskLegend={setShowAsteriskLegend}
              setIsModified={updateModifiedSections('productInfo')}
              productItem={productItem}
              variants={variants}
              authority={(user as User).authority}
              updateProduct={setProductItem}
              updateVariants={setVariants}
              isRetirementCertStatus={isRetirementCertStatus}
            />
          ) : (
            <Section className="mb-1">
              <RowWithMargin gutter={20}>
                <Col xs={12}>
                  <h4 className="mb-0">{t('products.variantInfoTitle')}</h4>
                </Col>
              </RowWithMargin>
              <DetailsItem label="Something went wrong">
                <span className="text-muted">Variant info is not found.</span>
              </DetailsItem>
            </Section>
          )}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <ProductSummarySidebar applicationRecord={productItem} />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </PageTemplate>
  );
};

export const ProductDetailsPage = withRouter(ProductDetailsComponent);
