import React, { useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { getRowKey } from './DataTable';
import { DataTableColumnProps } from './DataTableColumn';
import { DataTableExpandButton } from './DataTableExpandButton';
import { DataTableExpandProps, DataTableRowKeyProps, DataTableRowOptionsProps } from './types';
import { DataTableCell, DataTableCellContent, StyledTableCell } from './DataTableCell';
// @ts-ignore
import _var from '../../styles/_variables.scss';

const StyledTableRow = withStyles(() =>
  createStyles({
    hover: {
      '&:hover': {
        backgroundColor: `${_var.secondaryLighten} !important`,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  striped: {
    backgroundColor: _var.secondary,
  },
  expandColumn: {
    padding: '12px 2px',
    textAlign: 'right',
  },
  warning: {
    borderLeft: `2px solid ${_var.errorRed}`,
  },
});

interface DataTableRowProps<T>
  extends DataTableRowKeyProps<T>,
    Partial<DataTableExpandProps>,
    Partial<DataTableRowOptionsProps<T>> {
  row: T;
  nested?: boolean;
  striped?: boolean;
  expandable: boolean;
  settings: DataTableColumnProps<T>[];
  warningRedBorder?: boolean;
  warningRowChildrenBorder?: boolean;
  defaultExpanded?: boolean;
}

export function DataTableRow<T extends { id: number; children?: T[] }>(props: DataTableRowProps<T>) {
  const {
    row,
    rowKey = 'id',
    settings,
    nested = false,
    striped = false,
    warningRedBorder,
    warningRowChildrenBorder,
    defaultExpanded = false,
  } = props;
  const { expandable = false, onExpand, expandedRows, getRowOptions, getRowClassName } = props;

  const rowKeyValue = getRowKey<T>(rowKey, row);
  const [open, setOpen] = React.useState(defaultExpanded);

  const classes = useStyles();

  const options = getRowOptions ? getRowOptions(row) : {};
  const className = getRowClassName ? getRowClassName(row, nested) : '';

  useEffect(() => {
    if (Array.isArray(expandedRows)) {
      setOpen(expandedRows.includes(rowKeyValue));
    }
  }, [expandedRows]);

  const handleOpen = () =>
    setOpen(prev => {
      if (onExpand) {
        onExpand(!prev, rowKeyValue);
      }
      return !prev;
    });

  const hasWarning = warningRedBorder || (!open && warningRowChildrenBorder);

  return (
    <>
      <StyledTableRow
        hover
        className={classNames(classes.row, { [classes.striped]: striped }, className, {
          [classes.warning]: hasWarning,
        })}
      >
        {settings
          .filter(settings => settings.prefix === true)
          .map(setting => (
            <DataTableCell
              row={row}
              nested={nested}
              settings={setting}
              rowOptions={options}
              key={setting.field + rowKeyValue}
            />
          ))}
        {expandable && !defaultExpanded && (
          <StyledTableCell className={classes.expandColumn}>
            {row.children && row.children.length > 0 && (
              <DataTableCellContent visible={!options.hidden}>
                <DataTableExpandButton open={open} onClick={handleOpen} />
              </DataTableCellContent>
            )}
          </StyledTableCell>
        )}
        {settings
          .filter(settings => settings.prefix !== true)
          .map(setting => (
            <DataTableCell
              row={row}
              nested={nested}
              settings={setting}
              rowOptions={options}
              key={setting.field + rowKeyValue}
            />
          ))}
      </StyledTableRow>
      {open &&
        row.children &&
        row.children.map(child => (
          <DataTableRow
            nested
            row={child}
            striped={striped}
            settings={settings}
            expandable={expandable}
            getRowOptions={getRowOptions}
            getRowClassName={getRowClassName}
            key={getRowKey<T>(rowKey, child)}
            warningRedBorder={warningRowChildrenBorder}
            defaultExpanded={defaultExpanded}
          />
        ))}
    </>
  );
}
