import { EasyMeshController, HomeDesignProps, OtherRolesProps } from '../types';

export const otherRoleProps: OtherRolesProps = {
  '@type': 'CommonComponent',
  roleName: '',
  antenna: '',
  firmware: '',
  core: {
    chipset: '',
    driver: '',
    rfArchitecture: [],
  },
  os: '',
  physicalInterface: '',
  rfComponents: '',
};

export const homeDesignProps: HomeDesignProps = {
  '@type': 'HomeDesign',
  units: null,
  floors: null,
  squareFootage: '',
  apInterconnect: '',
  numberOfAccessPoints: 0,
  equipment: [],
};

export const easyMeshProps: EasyMeshController = {
  '@type': 'EasyMeshController',
  os: '',
};

export const roleNameMapping: { [x: string]: string } = {
  accessPoint: 'accessPoint',
  station: 'station',
  configurator: 'configurator',
  source: 'source',
  display: 'display',
  easyMeshController: 'easyMeshController',
  homeDesign: 'homeDesign',
};
