import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { FlexboxGrid } from 'rsuite';

import { getSingleApplication } from '../../../api/application';
import { ApplicationDetails } from './partials';
import { PageTemplate } from '../../partials';
import { SummaryTemplate } from '../../partials/summary-template/SummaryTemplate';
import { ApplicationInfoRecord } from './types';
import { getStoredSummaryUrl, closeWindow } from '../../../helpers';
import { ApplicationDetailsSidebar } from './ApplicationDetailsSidebar';
import { RootReducer } from '../../../redux/rootReducer';
import { User } from '../../../redux/modules/user/types';
import { wfaStaffRoles } from '../../../helpers/constants';
import { PendingApprovalFlowPage } from '../';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import { getFeeByAppId } from '../../../api/billing/get-fee-by-app-id';
import { GetFeeResponse } from '../../partials/billing/types';
import { BackToListButton } from '../../../shared-components/button/BackToListButton';
import { CloseButton } from '../../../shared-components/button/CloseButton';

const ApplicationDetailsTemplate = ({ match, history }: RouteComponentProps<{ id: string }>) => {
  const user = useSelector<RootReducer, User>(state => state.userReducer.user as User);
  if (wfaStaffRoles.includes(user.authority)) {
    return <PendingApprovalFlowPage isReview />;
  }

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [applicationRecord, setApplicationRecord] = useState<ApplicationInfoRecord>({} as ApplicationInfoRecord);
  const [applicationFees, setApplicationFees] = useState<GetFeeResponse[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getSingleApplication(Number(match.params.id))
      .then((response: AxiosResponse<ApplicationInfoRecord>) => {
        setApplicationRecord(response.data);
        setLoading(false);
      })
      .catch((error: AxiosError<{ message: string }>) => {
        setErrorMessage(error.response?.data?.message || error.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      applicationRecord?.id &&
      (applicationRecord.productInfo.certified || applicationRecord.productInfo.modelVariants[0].draftCertified)
    ) {
      getFeeByAppId(applicationRecord.id)
        .then((response: AxiosResponse<GetFeeResponse[]>) => {
          setApplicationFees(response.data);
        })
        .catch((error: AxiosError<{ message: string }>) => {
          setErrorMessage(error.response?.data?.message || error.message);
        });
    }
  }, [applicationRecord]);

  const handleBackToList = () => {
    history.push(getStoredSummaryUrl('application'));
  };

  if (loading) {
    return (
      <section style={{ height: '200px' }}>
        <CircularLoader fullPage content={t('common.placeholders.loadingData')} />
      </section>
    );
  }

  return (
    <PageTemplate
      title={t('applications.viewApplication')}
      noFooter={errorMessage.length > 0}
      actionLeft={
        window.opener ? <CloseButton onClick={closeWindow} /> : <BackToListButton onClick={handleBackToList} />
      }
      footerActionLeft={<></>}
    >
      {errorMessage ? (
        errorMessage
      ) : (
        <FlexboxGrid className="page-container" justify="space-between">
          <FlexboxGrid.Item colspan={18}>
            <section className="mt-2">
              <ApplicationDetails application={applicationRecord} applicationFees={applicationFees} />
            </section>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={5}>
            <SummaryTemplate
              title={t('applications.summary.sideBar.heading.certifications')}
              applicationId={applicationRecord.id}
            >
              {errorMessage ? errorMessage : <ApplicationDetailsSidebar applicationRecord={applicationRecord} />}
            </SummaryTemplate>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
    </PageTemplate>
  );
};
export const ApplicationDetailsPage = withRouter(ApplicationDetailsTemplate);
