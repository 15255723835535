import React from 'react';
import { ErrorObject } from '../../helpers/types';
import { withLabel, WrappedInputProps } from './withLabel';
import { extractError } from './utils';
import { RadioGroup } from 'rsuite';
import { RadioGroupProps } from 'rsuite/lib/RadioGroup/RadioGroup';

const Field = ({ children, ...props }: LabeledRadioGroupProps) => {
  return <RadioGroup {...props}>{children}</RadioGroup>;
};

const LabeledField = withLabel<LabeledRadioGroupProps>(Field);

interface LabeledRadioGroupProps extends RadioGroupProps {
  errors?: ErrorObject;
}
export const LabeledRadioGroup = ({ errors, name, ...props }: WrappedInputProps<LabeledRadioGroupProps>) => {
  return <LabeledField name={name} error={extractError(errors, name)} {...props} />;
};
