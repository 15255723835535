import React from 'react';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const MenuStyled = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  height: 100%;
`;

const Menu = (props: PropsWithChildren<{}>) => <MenuStyled>{props.children}</MenuStyled>;

export default React.memo(Menu);
