import React, { forwardRef, useState } from 'react';
import { CollapsableSection } from './CollapsableSection';
import {
  BasicSaveResponse,
  CertificationType,
  CollapsedSectionProps,
  InteroperabilityTest,
  OnChangeType,
  TestResultsTemplate,
} from '../types';
import { ErrorObject, ValidationError } from '../../../../helpers/types';
import { ViewInteropSection, EditInteropSection } from './interoperability';
import { interoperabilityPrimaryCertTestSchema } from '../validation-schemes';
import { confirmAction, jsonCopy, mergeObjects, prepareYupModel } from '../../../../helpers';
import dot from 'dot-object';
import { formatErrors } from '../../../../helpers/request';
import { handleRequestSuccess } from '../../../../helpers/handle-request-success';
import { useToasts } from 'react-toast-notifications';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { updateCertificationInteroperability } from '../../../../api/certification/partial-update/update-certification-interoperability';
import { validate } from './helpers/validate';
import * as Yup from 'yup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '../../../../redux/rootReducer';
import {
  CertificationDefinition,
  SectionEditState,
  setLoading,
  setSectionEditState,
  setValidationErrors,
  updateCertificationProperty,
} from '../../../../redux/modules/certification';
import { saveData } from './helpers';
import { IsModifiedProps, useChangeDetection } from '../../../../helpers/hooks/useChangeDetection';

interface State {
  interoperabilityTest: InteroperabilityTest;
  type: CertificationType;
  versionId: number;
  sectionsEditState: SectionEditState;
  errors: ErrorObject;
  save: boolean;
}

const calculateHashValue = (values: {
  templateRequired: boolean;
  testingRequired: boolean;
  testResultsTemplate: TestResultsTemplate | null;
}) => {
  return JSON.stringify({
    templateRequired: values.templateRequired ?? '',
    testingRequired: values.testingRequired ?? '',
    testResultsTemplate: values.testResultsTemplate?.id ?? '',
  });
};

type InteroperabilitySectionProps = CollapsedSectionProps & Partial<IsModifiedProps>;

export const InteroperabilityTestingSection = forwardRef<HTMLDivElement, InteroperabilitySectionProps>(
  function InteroperabilityTestingSection(
    { isView = false, setIsModified: setIsSectionModified }: InteroperabilitySectionProps,
    ref,
  ) {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const { interoperabilityTest, type, versionId, sectionsEditState, errors, save } = useSelector<RootReducer, State>(
      ({ certificationReducer }) => ({
        interoperabilityTest: certificationReducer.certification.interoperabilityTest,
        type: certificationReducer.certification['@type'],
        versionId: certificationReducer.certification.versionId as number,
        sectionsEditState: certificationReducer.sectionsEditState,
        errors: certificationReducer.errors,
        save: certificationReducer.loadingState.save,
      }),
      shallowEqual,
    );
    const defaultValue = {
      ...interoperabilityTest,
      templateRequired: Boolean(interoperabilityTest.testResultsTemplate?.id),
    };
    const [state, setState] = useState({
      interoperabilityTest: defaultValue,
    });
    const [errorsState, setErrorsState] = useState<ErrorObject>({});
    const [isModified, setIsModified] = useState(false);

    const setIsModifiedState = (value: boolean) => {
      setIsModified(value);
      setIsSectionModified && setIsSectionModified(value);
    };

    const setChangeDetectionInitialValue = useChangeDetection(
      setIsModifiedState,
      calculateHashValue,
      state.interoperabilityTest,
    );

    const handleChange = (value: OnChangeType, name: string) => {
      const errorToClear =
        (value === false && name.includes('templateRequired')) || name.includes('testingRequired')
          ? 'interoperabilityTest.testResultsTemplate'
          : '';
      if (isView) {
        setState(prevState => {
          const newState = jsonCopy(prevState);
          dot.set(name, value, newState);
          const errors = prepareYupModel<{ interoperabilityTest: InteroperabilityTest }>(
            Yup.object().shape({
              interoperabilityTest: interoperabilityPrimaryCertTestSchema,
            }),
          ).checkForFieldFormatted(name, newState);
          if (errors || errorToClear) {
            setErrorsState(prevErrors => {
              const updatedErrors = mergeObjects(prevErrors, { ...(errors || {}) });
              if (errorToClear) {
                updatedErrors[errorToClear] = '';
              }
              return updatedErrors;
            });
          }
          return newState;
        });
      } else {
        dispatch(updateCertificationProperty(value, name, { errorToClear }));
      }
    };

    const onSave = () => {
      if (
        validate<{ interoperabilityTest: InteroperabilityTest }>(
          Yup.object().shape({
            interoperabilityTest: interoperabilityPrimaryCertTestSchema,
          }),
          state,
          setErrorsState,
        )
      ) {
        dispatch(setLoading({ save: true }));
        return updateCertificationInteroperability(versionId, state.interoperabilityTest)
          .then(
            ({
              data: { interoperabilityTest, ...dataToUpdate },
            }: AxiosResponse<{ interoperabilityTest: InteroperabilityTest } & BasicSaveResponse>) => {
              saveData(
                'interoperabilityTest',
                interoperabilityTest as Partial<CertificationDefinition>,
                'version',
                dataToUpdate,
              );
              handleRequestSuccess(t('certifications.notifications.saved'), addToast);
              setIsModifiedState(false);
              setChangeDetectionInitialValue(state.interoperabilityTest);
            },
          )
          .catch((errors: AxiosError) => {
            handleRequestFail(errors, addToast);
            if (errors.response?.data.errors) {
              dispatch(setValidationErrors(formatErrors(errors.response?.data.errors as ValidationError[])));
            }
          });
      }
    };

    const resetForm = () => {
      setState({ interoperabilityTest: defaultValue });
      setErrorsState({});
      setIsModifiedState(false);
      dispatch(setSectionEditState({ interoperabilityTest: false }));
    };

    const onCancel = () => confirmAction(() => isModified, resetForm);

    return (
      <CollapsableSection
        title={t('certifications.interoperabilityTest.title')}
        showEditIcon={isView}
        isEdit={sectionsEditState.interoperabilityTest}
        onCancel={onCancel}
        onSave={onSave}
        isSaving={save}
        ref={ref}
        onEditIconClick={() => dispatch(setSectionEditState({ interoperabilityTest: true }))}
      >
        {isView && !sectionsEditState.interoperabilityTest ? (
          <ViewInteropSection data={interoperabilityTest} type={type} />
        ) : (
          <EditInteropSection
            data={sectionsEditState.interoperabilityTest ? state.interoperabilityTest : interoperabilityTest}
            type={type}
            errors={sectionsEditState.interoperabilityTest ? errorsState : errors}
            onChange={handleChange}
          />
        )}
      </CollapsableSection>
    );
  },
);
