export interface Note {
  id: number;
  createdBy: { id: number; name: string };
  createdAt: string;
  text: string;
}

export enum NoteTypes {
  APPLICATION = 'APPLICATION',
}
