import axios from '../../helpers/axios-setup';
import { ComponentCombination, LoadCCComponentProperties } from './types';

export const getLoadCCCompanies = () => axios.get(`/applications/builder/details/load-cc/companies`);

export const getLoadCCCompaniesById = (companyId: number, params: LoadCCComponentProperties) =>
  axios.get(`/applications/builder/details/load-cc/companies/${companyId}`, { params });

export const getCCSourceProductByAppId = (id: number, filterParams: LoadCCComponentProperties) =>
  axios.get(`/applications/builder/details/load-cc/products/${id}`, { params: filterParams });

export const getVariantCCById = (id: number) =>
  axios.get<ComponentCombination>(`/applications/builder/details/load-cc/${id}`);
