import { GenericNodeType, GroupNodeType, SingleNodeType, NodeTypes, Path } from '../types';
import { NodePlaceholder } from './NodePlaceholder';
import React from 'react';
import { SingleNode } from './SingleNode';
import { GroupNode } from './GroupNode';

interface DraggableWrapperProps {
  root?: boolean;
  targetPath?: Path;
  node: GenericNodeType;
}

export const DraggableWrapper = ({ root, node, targetPath }: DraggableWrapperProps) => {
  return (
    <>
      {node.type === NodeTypes.GROUP ? (
        <GroupNode root={root} node={node as GroupNodeType}>
          <NodePlaceholder path={[...node.path, 0]} targetPath={targetPath} />
          {(node as GroupNodeType).elements.map((item, index) => (
            <React.Fragment key={item.uid}>
              <DraggableWrapper node={item} targetPath={targetPath} />
              <NodePlaceholder path={[...node.path, index + 1]} targetPath={targetPath} />
            </React.Fragment>
          ))}
        </GroupNode>
      ) : (
        <SingleNode node={node as SingleNodeType} />
      )}
    </>
  );
};
