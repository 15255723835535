import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalProps } from 'rsuite';

export const RsCenterModal = (props: ModalProps) => {
  const modalHeaderRef = useRef(null);
  const modalBodyRef = useRef(null);
  const modalFooterRef = useRef(null);
  const [modalMarginTop, setModalMarginTop] = useState(0);

  const { children: modalChildren, onEnter: onModalEnter, style: modalStyle, ...modalProps } = props;

  const childrenArray = React.Children.toArray(modalChildren);

  const header = childrenArray.length > 0 ? childrenArray[0] : null;
  const body = childrenArray.length > 1 ? childrenArray[1] : null;
  const footer = childrenArray.length > 2 ? childrenArray[2] : null;

  const adjustModalMargin = () => {
    if (modalHeaderRef.current && modalBodyRef.current && modalFooterRef.current) {
      setModalMarginTop(
        Math.max(
          40,
          (window.innerHeight -
            modalHeaderRef.current.clientHeight -
            modalBodyRef.current.clientHeight -
            modalFooterRef.current.clientHeight -
            80) /
            4,
        ),
      );
    }
    if (onModalEnter) onModalEnter(null);
  };

  useEffect(() => {
    adjustModalMargin();
  }, [
    modalHeaderRef?.current?.clientHeight,
    modalBodyRef?.current?.clientHeight,
    modalFooterRef?.current?.clientHeight,
  ]);

  return (
    <Modal
      onEnter={adjustModalMargin}
      style={
        modalStyle
          ? { ...modalStyle, margin: `${modalMarginTop}px auto 0 auto` }
          : { margin: `${modalMarginTop}px auto 0 auto` }
      }
      {...modalProps}
    >
      {header && (
        <Modal.Header {...header.props}>
          <div ref={modalHeaderRef}>{header.props.children}</div>
        </Modal.Header>
      )}
      {body && (
        <Modal.Body {...body.props}>
          <div ref={modalBodyRef}>{body.props.children}</div>
        </Modal.Body>
      )}
      {footer && (
        <Modal.Footer {...footer.props}>
          <div ref={modalFooterRef}>{footer.props.children}</div>
        </Modal.Footer>
      )}
    </Modal>
  );
};
