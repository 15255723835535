import React from 'react';
import { getProductSummary, getProductSummaryByTab } from '../../../api/product';
import { SingleSummaryProduct } from './types';
import { getSummaryWithoutTabPageComponentByObject } from '../../hoc/get-summary-without-tabs-page-component';
import { SummaryTable } from './partials/SummaryTable';
import { wfaStaffRoles } from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { RootReducer } from '../../../redux/rootReducer';
import { User } from '../../../redux/modules/user/types';
import { CreateNewApplicationButton } from '../application/partials';
import { getSummaryPageComponentByObject } from '../../hoc/get-summary-page-component';
import { useTranslation } from 'react-i18next';
import { productStatuses, productStatusTitles } from '../../../helpers/constants/product-statuses';

const SummaryPage = () => {
  const { t } = useTranslation();
  const user = useSelector<RootReducer, User>(state => state.userReducer.user as User);

  if (wfaStaffRoles.includes(user.authority) || user.company.qualifiedSolutionProvider) {
    const SummaryPageWithTabs = getSummaryPageComponentByObject<SingleSummaryProduct>({
      title: t('products.title'),
      apiCall: getProductSummaryByTab,
      object: productStatuses,
      titles: productStatusTitles,
      SummaryTable,
      pageRightAction: wfaStaffRoles.includes(user.authority) ? null : <CreateNewApplicationButton />,
      defaultSorting: { sortColumn: 'date', sortType: 'desc' },
      useAdvancedFilters: true,
      isProductSummary: true,
    });
    return <SummaryPageWithTabs />;
  }

  const SummaryPage = getSummaryWithoutTabPageComponentByObject<SingleSummaryProduct>({
    title: t('products.title'),
    apiCall: getProductSummary,
    SummaryTable,
    pageRightAction: <CreateNewApplicationButton />,
    defaultSorting: { sortColumn: 'date', sortType: 'desc' },
    useAdvancedFilters: true,
    isProductSummary: true,
  });
  return <SummaryPage />;
};

export const ProductSummaryPage = React.memo(SummaryPage);
