import { IterableObject } from '../types';

export const numberComparisonOperators = {
  between: 'BETWEEN',
  eq: 'EQ',
  gt: 'GT',
  ge: 'GE',
  lt: 'LT',
  le: 'LE',
  neq: 'NOT_EQ',
};
export const comparisonOperators = {
  ...numberComparisonOperators,
  regex: 'REGEX',
};

export const ComparisonOperatorTitles: IterableObject<string> = {
  [comparisonOperators.between]: 'Between',
  [comparisonOperators.eq]: '==',
  [comparisonOperators.regex]: 'Regex',
  [comparisonOperators.gt]: '>',
  [comparisonOperators.ge]: '>=',
  [comparisonOperators.lt]: '<',
  [comparisonOperators.le]: '<=',
  [comparisonOperators.neq]: '!=',
};
