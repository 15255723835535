import React from 'react';

import { roleNameMapping } from '../../application/data/constants';
import { CCInfoRecord } from '../../application/types';
import { DefaultRolesRowsCompare } from './DefaultRolesRowsCompare';
import { EasyMeshCompare } from './EasyMeshCompare';
import { HomeDesignCompare } from './HomeDesignCompare';

export const CCDetailsWithCompare = ({
  cc,
  compareCC,
  expandedCC,
  toggleCC,
  showPublicIcon = false,
  ccChanged = false,
  isFullSizeCol = false,
  togglePrefix = '',
}: {
  cc: CCInfoRecord;
  compareCC?: CCInfoRecord;
  expandedCC: string[];
  toggleCC: (key: string, isOpen: boolean) => void;
  showPublicIcon?: boolean;
  ccChanged?: boolean;
  isFullSizeCol?: boolean;
  togglePrefix?: string;
}) => (
  <>
    {cc?.accessPoint?.map((record, index) => (
      <DefaultRolesRowsCompare
        key={index}
        expandedCC={expandedCC}
        toggleCC={toggleCC}
        toggleKey={`${togglePrefix}${roleNameMapping.accessPoint}${index}`}
        record={record}
        roleProperty={roleNameMapping.accessPoint}
        compareRecord={compareCC?.accessPoint ? compareCC?.accessPoint[index] : undefined}
        showPublicIcon={showPublicIcon}
        ccChanged={ccChanged}
        isFullSizeCol={isFullSizeCol}
      />
    ))}
    {cc?.station?.map((record, index) => (
      <DefaultRolesRowsCompare
        key={index}
        expandedCC={expandedCC}
        toggleCC={toggleCC}
        toggleKey={`${togglePrefix}${roleNameMapping.station}${index}`}
        record={record}
        roleProperty={roleNameMapping.station}
        compareRecord={compareCC?.station ? compareCC?.station[index] : undefined}
        showPublicIcon={showPublicIcon}
        ccChanged={ccChanged}
        isFullSizeCol={isFullSizeCol}
      />
    ))}
    {cc?.configurator?.map((record, index) => (
      <DefaultRolesRowsCompare
        key={index}
        expandedCC={expandedCC}
        toggleCC={toggleCC}
        toggleKey={`${togglePrefix}${roleNameMapping.configurator}${index}`}
        record={record}
        roleProperty={roleNameMapping.configurator}
        compareRecord={compareCC?.configurator ? compareCC?.configurator[index] : undefined}
        showPublicIcon={showPublicIcon}
        ccChanged={ccChanged}
        isFullSizeCol={isFullSizeCol}
      />
    ))}
    {cc?.source?.map((record, index) => (
      <DefaultRolesRowsCompare
        key={index}
        expandedCC={expandedCC}
        toggleCC={toggleCC}
        toggleKey={`${togglePrefix}${roleNameMapping.source}${index}`}
        record={record}
        roleProperty={roleNameMapping.source}
        compareRecord={compareCC?.source ? compareCC?.source[index] : undefined}
        showPublicIcon={showPublicIcon}
        ccChanged={ccChanged}
        isFullSizeCol={isFullSizeCol}
      />
    ))}
    {cc?.display?.map((record, index) => (
      <DefaultRolesRowsCompare
        key={index}
        expandedCC={expandedCC}
        toggleCC={toggleCC}
        toggleKey={`${togglePrefix}${roleNameMapping.display}${index}`}
        record={record}
        roleProperty={roleNameMapping.display}
        compareRecord={compareCC?.display ? compareCC?.display[index] : undefined}
        showPublicIcon={showPublicIcon}
        ccChanged={ccChanged}
        isFullSizeCol={isFullSizeCol}
      />
    ))}
    {cc?.easyMeshController?.map((record, index) => (
      <EasyMeshCompare
        key={index}
        expandedCC={expandedCC}
        toggleCC={toggleCC}
        toggleKey={`${togglePrefix}${roleNameMapping.easyMeshController}${index}`}
        record={record}
        compareRecord={compareCC?.easyMeshController ? compareCC?.easyMeshController[index] : undefined}
        showPublicIcon={showPublicIcon}
        ccChanged={ccChanged}
        isFullSizeCol={isFullSizeCol}
      />
    ))}
    {cc?.homeDesign?.map((record, index) => (
      <HomeDesignCompare
        key={index}
        expandedCC={expandedCC}
        toggleCC={toggleCC}
        toggleKey={`${togglePrefix}${roleNameMapping.homeDesign}${index}`}
        record={record}
        compareRecord={compareCC?.homeDesign ? compareCC?.homeDesign[index] : undefined}
        showPublicIcon={showPublicIcon}
      />
    ))}
  </>
);
