import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Autocomplete, { AutocompleteRenderOptionState, AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';

import { ConditionComponentProps, FilterParameterValuesType, ConditionParameterValuesType } from '../types';
import { useStyles } from '../styles';

// to generate initial data for outside
export const generateDropdownConditionInitParameterValue = (
  selected: string[],
  showSelectedLabel: boolean,
  data: ConditionParameterValuesType[],
): FilterParameterValuesType => {
  if (data.length == 0) return null;
  const initValues = [];
  for (const s of selected) {
    const gotIt = data.find(d => d.value === s);
    if (gotIt) {
      initValues.push({
        value: s,
        label: gotIt.label,
      });
    }
  }
  if (initValues.length == 0) return null;
  return {
    values: initValues,
    showSelectedLabel,
  };
};

export const getDropdownConditionCurrentValue = (obj: FilterParameterValuesType): string[] => {
  return obj.values.map(v => v.value as string);
};

export function DropdownCondition({
  parameterName,
  updateFilterParameter,
  currentConditionParameterValues,
  showSelectedLabel,
  options,
}: ConditionComponentProps) {
  const classes = useStyles();

  // initail data from outside
  const [selected, setSelected] = useState<ConditionParameterValuesType[]>(
    currentConditionParameterValues ? currentConditionParameterValues : [],
  );

  // for clear all action from outside
  useEffect(() => {
    setSelected(currentConditionParameterValues ? currentConditionParameterValues : []);
  }, [currentConditionParameterValues]);

  // options data for specific component rendering
  const { data = [], placeholder, multiple = false, checkbox = false, limitTags = -1 } = options;

  const generateParameterValue = (values: ConditionParameterValuesType[]): FilterParameterValuesType => {
    return { values, showSelectedLabel };
  };

  const handleChange = (
    event: React.ChangeEvent<{}>,
    value: ConditionParameterValuesType | ConditionParameterValuesType[] | null,
  ) => {
    let newState = [];
    if (Array.isArray(value)) {
      newState = value;
    } else {
      newState = value ? [value] : [];
    }
    setSelected(newState);
    updateFilterParameter(parameterName, newState.length > 0 ? generateParameterValue(newState) : null);
  };

  const renderOption = (option: ConditionParameterValuesType, { selected }: AutocompleteRenderOptionState) => (
    <>
      {multiple && checkbox ? (
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon color="primary" />}
          checkedIcon={<CheckBoxIcon color="primary" />}
          checked={selected}
          className={classes.autocompleteCheckbox}
        />
      ) : null}
      {option.label}
    </>
  );

  const getOptionLabel = (option: ConditionParameterValuesType | ConditionParameterValuesType[]) => {
    return Array.isArray(option) && option.length > 0
      ? (option[0].label as string)
      : option.label
      ? (option.label as string)
      : '';
  };

  const getOptionSelected = (option: ConditionParameterValuesType, item: ConditionParameterValuesType) => {
    return (Array.isArray(item) && item.length > 0 ? item[0].value : item.value) === option.value;
  };

  const renderTags = (value: ConditionParameterValuesType[], getTagProps: AutocompleteGetTagProps) => {
    const numTags = value.length;
    return (
      <>
        {(limitTags > 0 ? value.slice(0, limitTags) : value).map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={index}
            label={option.label}
            deleteIcon={<ClearIcon className={classes.autocompleteTagChipIcon} />}
            className={classes.autocompleteTagChip}
          />
        ))}
        {limitTags > 0 && numTags > limitTags && ` +${numTags - limitTags}`}
      </>
    );
  };

  return (
    <Autocomplete<ConditionParameterValuesType, typeof multiple>
      multiple={multiple}
      disableCloseOnSelect={multiple}
      options={data}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={params => (
        <TextField {...params} placeholder={placeholder} variant="outlined" className={classes.autocompleteInput} />
      )}
      onChange={handleChange}
      getOptionSelected={getOptionSelected}
      fullWidth
      popupIcon={<ExpandMoreIcon className={classes.autocompleteIcon} />}
      closeIcon={<ClearIcon className={classes.autocompleteIcon} />}
      limitTags={limitTags}
      openOnFocus={true}
      renderTags={renderTags}
      value={selected}
      className={classNames(classes.autocomplete, selected.length > 0 ? classes.autocompleteWithValue : '')}
      classes={{
        option: classes.autocompleteOption,
        noOptions: classes.autocompleteNoOptions,
      }}
    />
  );
}
