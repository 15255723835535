import React from 'react';
import { Row, Col } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { CertificationType, InteroperabilityTest } from '../../types';

export const ViewInteropSection = ({ data, type }: { data: InteroperabilityTest; type: CertificationType }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={20} className="mt-1">
      <Col xs={12}>{!data.testingRequired ? t('certifications.notRequired') : t('certifications.required')}</Col>
      <Col xs={12}>
        {type === CertificationType.PRIMARY && data.testResultsTemplate ? (
          <>
            <p>{t('certifications.interoperabilityTest.templateRequired')}</p>
            <span className="ml-1">{data.testResultsTemplate.name}</span>
          </>
        ) : null}
      </Col>
    </Row>
  );
};
