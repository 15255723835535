import { makeStyles } from '@material-ui/core/styles';
import { StepIconProps } from '@material-ui/core/StepIcon';
import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import classNames from 'classnames';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

export const useLifeCycleStepIconStyles = makeStyles({
  root: {
    color: _var.grayBorder,
    backgroundColor: _var.white,
    border: `2px solid ${_var.grayBorder}`,
    zIndex: 1,
    width: 28,
    height: 28,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    color: _var.success,
    background: _var.white,
    borderColor: _var.success,
  },
  completed: {
    color: _var.white,
    background: _var.success,
    borderColor: _var.success,
  },
});

export default function LifeCycleStepIcon({ active, completed }: StepIconProps) {
  const classes = useLifeCycleStepIconStyles();

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <DoneIcon fontSize="small" />
    </div>
  );
}
