import React from 'react';
import styled from 'styled-components';
import { Anchor } from '../../shared-components/styled-components';

const FooterWrapper = styled.div`
  text-align: center;
`;

export const Footer = () => (
  <FooterWrapper className="mb-2 mt-1">
    © {new Date().getFullYear()} Wi-Fi Alliance. All rights reserved. Wi-Fi®, the Wi-Fi logo, the Wi-Fi CERTIFIED logo,
    and{' '}
    <Anchor href="https://www.wi-fi.org/who-we-are/our-brands" target="_blank" rel="noopener noreferrer">
      other marks
    </Anchor>{' '}
    are trademarks of Wi-Fi Alliance.
  </FooterWrapper>
);
