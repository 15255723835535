import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Icon, IconButton } from 'rsuite';

interface Props {
  variantId: number;
}

const ActionComponent = ({ variantId }: Props & RouteComponentProps<{}>): React.ReactElement<string> => {
  const onHistory = () => window.open(`#/application/${variantId}/history`);

  return (
    <Fragment>
      <span className={'actions'}>
        <IconButton onClick={onHistory} icon={<Icon icon="history" />} appearance="link" />
      </span>
    </Fragment>
  );
};

export const RightActions = withRouter(ActionComponent);
