import React from 'react';
import { Icon, Table, IconButton } from 'rsuite';
import { RsTable, RsColumn } from '../../../../../shared-components/theme';
import { jsonCopy } from '../../../../../helpers';
import { Prerequisites } from '../../types';
import { NoTableData } from '../../../../partials';
import { CellWithPopover } from '../../../../../shared-components/helper-components/CellWithPopover';
import { useTranslation } from 'react-i18next';

const { HeaderCell } = Table;

export const ViewPrerequisitesSection = ({ data }: { data: Prerequisites[] }) => {
  const { t } = useTranslation();
  const onViewCertification = (rowData: Prerequisites) =>
    window.open(
      `#/certification/?search=${rowData.certification.name}&certificationId=${rowData.certification.id}&tab=PUBLISHED`,
    );
  return (
    <>
      <RsTable data={jsonCopy(data)} className="form-table" rowHeight={65} renderEmpty={() => <NoTableData />}>
        <RsColumn align="left" flexGrow={2} verticalAlign="middle">
          <HeaderCell>{t('certifications.certification')}</HeaderCell>
          <CellWithPopover dataKey="certification.id">
            {(rowData: Prerequisites) => (
              <>
                {rowData.certification.name} ({rowData.certification.role?.name}) [
                {rowData.versions?.length
                  ? t('certifications.publishedVersions', { count: rowData.versions?.length })
                  : t('certifications.noPublishedVersions', { count: rowData.versions?.length })}
                ]
              </>
            )}
          </CellWithPopover>
        </RsColumn>

        <RsColumn verticalAlign="middle" flexGrow={1}>
          <HeaderCell>{t('certifications.version.version')}</HeaderCell>
          <CellWithPopover dataKey="version" />
        </RsColumn>

        <RsColumn verticalAlign="middle" flexGrow={1}>
          <HeaderCell>{t('certifications.version.revision.title')}</HeaderCell>
          <CellWithPopover dataKey="revision" />
        </RsColumn>

        <RsColumn width={90}>
          <HeaderCell>{t('common.actions.title')}</HeaderCell>
          <Table.Cell>
            {(rowData: Prerequisites) => (
              <div className="actions">
                <IconButton
                  appearance="link"
                  onClick={() => onViewCertification(rowData)}
                  icon={<Icon icon="eye" className="pl-1" />}
                />
              </div>
            )}
          </Table.Cell>
        </RsColumn>
      </RsTable>
    </>
  );
};
