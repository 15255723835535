import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { AxiosResponse } from 'axios';
import { Col, Row, Table } from 'rsuite';

import { DetailsItem, PageTemplate } from '../../partials';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { LabInfo, LabKey } from './types';
import { jsonCopy, reformatDate } from '../../../helpers';
import { RsColumn, RsTable } from '../../../shared-components/theme';
import { labSubmissionStatusTitles } from '../../../helpers/constants/lab-submission-status';
import { getLabInfo } from '../../../api/lab';
import { apiBaseUrl } from '../../../config';
import { summaryTableProps } from '../../../helpers/constants';
import { downloadBundleHandler } from '../../../api/certification/helper/downloadBundleHandler';
import { DownloadBundleButton } from '../../../shared-components/button/DownloadBundleButton';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import { LabKeyUploadModal } from '../../partials/LabKeyUploadModal';
import { MaterialAddButton } from '../../../shared-components/button/MaterialAddButton';

const { Cell, HeaderCell } = Table;

export const LabInfoPage = () => {
  const { t } = useTranslation();
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [lab, setLabInfo] = useState<LabInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const [downloadingBundle, setDownloadingBundle] = useState(false);
  const [name, setName] = useState('');
  const { addToast } = useToasts();
  const getCompanyInfo = () => {
    setLoading(true);
    getLabInfo()
      .then((response: AxiosResponse<LabInfo>) => {
        setLabInfo(response.data);
        setLoading(false);
      })
      .catch(error => {
        handleRequestFail(error, addToast);
        setLoading(false);
      });
  };

  const keyLoaded = (key: LabKey) => {
    addToast(t('lab.uploadedMessage'), {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    setLabInfo(prevState => {
      const newState = jsonCopy(prevState);
      newState?.keys.push(key);
      return newState;
    });
    setIsActiveModal(false);
    setName('');
  };

  const handleModalClose = () => {
    setIsActiveModal(false);
    setName('');
  };

  useEffect(getCompanyInfo, []);

  if (loading) {
    return (
      <section style={{ height: '200px' }}>
        <CircularLoader fullPage content={t('common.placeholders.loadingData')} />
      </section>
    );
  }

  return (
    <>
      {downloadingBundle && <CircularLoader backdrop content={t('common.placeholders.loadingData')} />}
      <PageTemplate title={t('lab.title')}>
        <h5>{t('lab.generalInfo')}</h5>
        <Row gutter={10}>
          <Col xs={6}>
            <DetailsItem label={t('lab.company')} value={lab?.name} />
          </Col>
          <Col xs={6}>
            <DetailsItem label={t('lab.email')} value={lab?.companyContact} />
          </Col>
          <Col xs={6}>
            <DetailsItem
              label={t('lab.status')}
              value={lab?.submissionStatus ? labSubmissionStatusTitles[lab.submissionStatus] : null}
            />
          </Col>
          <Col xs={6}>
            <DetailsItem
              label={t('lab.statusChangeDate')}
              value={lab?.lastStatusUpdate ? reformatDate(lab.lastStatusUpdate) : null}
            />
          </Col>
        </Row>
        <DetailsItem label="Tool Certifications Bundle">
          <DownloadBundleButton onClick={() => downloadBundleHandler(addToast, setDownloadingBundle)} />
        </DetailsItem>
        <h5 className="my-1">{t('lab.keys')}</h5>
        <RsTable data={lab?.keys || []} {...summaryTableProps}>
          <RsColumn width={150}>
            <HeaderCell>{t('lab.kid')}</HeaderCell>
            <Cell dataKey="kid" />
          </RsColumn>
          <RsColumn minWidth={200} flexGrow={1}>
            <HeaderCell>{t('lab.keyName')}</HeaderCell>
            <Cell dataKey="name" />
          </RsColumn>
          <RsColumn width={150}>
            <HeaderCell>{t('lab.keyCreatedDate')}</HeaderCell>
            <Cell dataKey="created">{(row: LabKey) => reformatDate(row.created)}</Cell>
          </RsColumn>
        </RsTable>
        <MaterialAddButton className="mt-1" id="new-key" onClick={() => setIsActiveModal(true)}>
          {t('lab.newKey')}
        </MaterialAddButton>

        {isActiveModal && (
          <LabKeyUploadModal
            showPopup={isActiveModal}
            onClose={handleModalClose}
            onSuccess={keyLoaded}
            url={`${apiBaseUrl}/company/tool-key/upload-key`}
            title={t('lab.modal.title')}
            bodyParams={name ? { name } : {}}
            name={name}
            onNameChange={setName}
          />
        )}
      </PageTemplate>
    </>
  );
};
