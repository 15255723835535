import { CertificationStatuses } from '../../../../helpers/constants/certification-statuses';
import { BasicRole } from '../../../../redux/modules/roles/types';
import { ImpersonateAutoCompleteItem } from '../../../partials/impersonate/types';

export type Lab = ImpersonateAutoCompleteItem & { subtype: LabSubType };

export enum LabSubType {
  ATL = 'ATL',
  CATL = 'CATL',
  MCTL = 'MCTL',
  STL = 'STL',
}

export const labSubTypeTitles = {
  [LabSubType.ATL]: 'ATL',
  [LabSubType.CATL]: 'CATL',
  [LabSubType.MCTL]: 'MCTL',
  [LabSubType.STL]: 'STL',
};
interface LabCertificationVersion {
  id: number;
  name: string;
  status: CertificationStatuses;
  conformance: boolean;
  interoperability: boolean;
}

export interface LabCertification {
  id: number;
  name: string;
  role: BasicRole;
  version: LabCertificationVersion[];
}

export type LabSummaryCertification = Omit<LabCertification, 'version'> & {
  version: LabCertificationVersion;
  children?: LabSummaryCertification[];
};

export type LabSummaryCertificationByGroup = Omit<LabCertification, 'version'> & {
  version: LabCertificationVersion | null;
  children: LabSummaryCertificationByGroup[];
};

export interface Qualification {
  certification: LabSummaryCertification;
  interoperability: boolean;
  conformance: boolean;
}
