import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Radio, RadioGroup, Row, SelectPicker } from 'rsuite';
import styled from 'styled-components';
import classNames from 'classnames';

import {
  addCCLoadedNoteWarning,
  addCCRole,
  AppFlows,
  AppFlowSteps,
  ApplicationFlowState,
  changeCCRole,
  CompareCCInfo,
  ComponentCombinationSource,
  ComponentCombinationWrapper,
  setCompareCCInfo,
  updateApplicationProperty,
} from '../../../../../redux/modules/application-flow';
import { LoadCCDialog } from '../LoadCCDialog';
import { roleNameMapping } from '../../data/constants';
import { ComponentCombination } from '../../../../../api/application/types';
import { RsField } from '../../../../../shared-components/rsuite';
import { Role } from '../../../../../redux/modules/roles/types';
import { RootReducer } from '../../../../../redux/rootReducer';
import { sortByProperty } from '../../../../../helpers/sort-by-property';
import { RoleItemComponent } from './partials/cc-step';
import { Confirm } from '../../../../../helpers/confirmationPopup/Confirm';
import { initialState, MemberClearData } from '../../../../../redux/modules/application-flow/data';
import { IsModifiedProps, useChangeDetection } from '../../../../../helpers/hooks/useChangeDetection';
import { jsonCopy } from '../../../../../helpers';
import { RoleItem } from './partials/cc-step/types';
import { ErrorObject } from '../../../../../helpers/types';
import { LoadedFromBreadcrumps } from '../../../../partials/LoadedFromBreadcrumbs.tsx';
import { MaterialAddButton } from '../../../../../shared-components/button/MaterialAddButton';

// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const InlineSelect = styled(RsField)`
  .rs-control-label {
    display: inline-block !important;
    margin-right: 5px;
  }

  .rs-form-control-wrapper {
    width: 200px !important;
  }

  .text-danger {
    left: 34px;
    bottom: -15px;
  }
`;

const Bold = styled.b`
  color: ${_var.errorRed};
`;

const calculateHashValue = (combinations: RoleItem[]) => {
  return JSON.stringify(
    combinations.map(combination => ({
      '@type': combination['@type'] ?? '',
      sortOrder: combination.sortOrder ?? 0,
      index: combination.index ?? 0,
      roleName: combination.roleName ?? '',
      antenna: 'antenna' in combination ? combination.antenna : '',
      firmware: 'firmware' in combination ? combination.firmware : '',
      os: 'os' in combination ? combination.os : '',
      physicalInterface: 'physicalInterface' in combination ? combination.physicalInterface : '',
      rfComponents: 'rfComponents' in combination ? combination.rfComponents : '',
      units: 'units' in combination ? combination.units : '',
      floors: 'floors' in combination ? combination.floors : '',
      squareFootage: 'squareFootage' in combination ? combination.squareFootage : '',
      apInterconnect: 'apInterconnect' in combination ? combination.apInterconnect : '',
      numberOfAccessPoints: 'numberOfAccessPoints' in combination ? combination.numberOfAccessPoints : '',
      equipment: 'equipment' in combination ? combination.equipment : '',
      core: {
        ...('core' in combination
          ? {
              ...combination.core,
              rfArchitecture: [...combination.core.rfArchitecture]
                .sort((a, b) => (a.band as string).localeCompare(b.band as string))
                .map(item => ({
                  band: item.band,
                  rx: item.rx,
                  tx: item.tx,
                })),
            }
          : {}),
      },
    })),
  );
};

type Props = IsModifiedProps & {
  saveData?: (cc?: ComponentCombination) => void;
  handleSetInitialValues?: (cc?: ComponentCombination) => void;
  initialComponentCombination?: ComponentCombination;
};

const reformatComponentCombination = (cc: ComponentCombination): RoleItem[] => {
  if (cc === null) {
    return [];
  }
  return (Object.keys(cc) as Array<keyof ComponentCombination>)
    .reduce((prev, key) => {
      const roleItem = cc[key];
      if (key !== 'newRoles' && Array.isArray(roleItem)) {
        // @ts-ignore
        prev.push(...roleItem.map((item, index) => ({ ...item, roleName: key, index })));
      }
      return prev;
    }, [] as RoleItem[])
    .sort((a: RoleItem, b: RoleItem) => sortByProperty(a, b, { sortType: 'asc', sortColumn: 'sortOrder' }));
};

export enum ComponentsFlow {
  PREPOPULATE = 'PREPOPULATE',
  CONFIGURE = 'CONFIGURE',
}

const AddRoleButton = ({ onClick, className }: { onClick: () => void; className?: string }) => {
  const { t } = useTranslation();
  return (
    <MaterialAddButton className={classNames('mt-1', className)} id="add-role" onClick={onClick} appearance="subtle">
      <span>{t('applications.cc.addNewRole')}</span>
    </MaterialAddButton>
  );
};

export const WiFiComponentsStep = ({
  saveData,
  handleSetInitialValues,
  setIsModified,
  initialComponentCombination,
}: Props) => {
  const [isActiveLoadCCModal, setLoadCCModalState] = useState(false);
  const errors = useSelector<RootReducer, ErrorObject>(state => state.applicationFlow.errors);
  const state = useSelector<RootReducer, ApplicationFlowState>(state => state.applicationFlow);
  const type = useSelector<RootReducer, AppFlows>(state => state.applicationFlow['@type']);
  const cc = state.componentCombination.componentCombination;
  const reformattedCC: RoleItem[] = useMemo(() => reformatComponentCombination(cc), [
    state.componentCombination.componentCombination,
  ]);
  const ccLength = reformattedCC.length;

  const updateChangeDetectionState = useChangeDetection(setIsModified, calculateHashValue, reformattedCC);

  const ccVariantSourceId = state.componentCombination.componentCombinationVariantSource?.id;
  const defaultComponentsFlow =
    ccVariantSourceId || type === AppFlows.VARIANT
      ? ComponentsFlow.PREPOPULATE
      : ccLength
      ? ComponentsFlow.CONFIGURE
      : null;

  const [componentsFlow, setComponentsFlow] = useState<ComponentsFlow | null>(defaultComponentsFlow);

  useEffect(() => {
    if (
      initialComponentCombination &&
      calculateHashValue(reformatComponentCombination(initialComponentCombination)) !==
        calculateHashValue(reformatComponentCombination(initialState.componentCombination.componentCombination))
    ) {
      updateChangeDetectionState(reformatComponentCombination(initialComponentCombination));
    }
  }, [initialComponentCombination]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const roles = useSelector<RootReducer, Role[]>(state => state.rolesReducer.roles);

  const dispatchAddRole = () => {
    dispatch(addCCRole(Math.max(0, ...reformattedCC.map(item => item.sortOrder))));
    window.scrollTo(0, document.body.scrollHeight);
  };

  const addRole = () => {
    if (state.showPopup && state.certifications.length > 0) {
      return Confirm({
        title: t('applications.cc.warning.title'),
        message: t('applications.cc.warning.message', {
          addition: t('applications.cc.warning.additions.certsAndLabs'),
        }),
        onAccept: () => dispatchAddRole(),
      });
    }
    return dispatchAddRole();
  };

  const dispatchChangeRole = (currentRole: string, newRole: string, index: number) => {
    const lastOrderNumber = ccLength ? reformattedCC[ccLength - 1].sortOrder : 0;
    dispatch(changeCCRole(currentRole, newRole, index, lastOrderNumber));
  };

  const changeRole = (currentRole: string, newRole: string, index: number) => {
    if (!componentsFlow || (componentsFlow === ComponentsFlow.PREPOPULATE && !ccVariantSourceId)) {
      setComponentsFlow(ComponentsFlow.CONFIGURE);
    }

    if (state.showPopup && state.certifications.length > 0) {
      return Confirm({
        title: t('applications.cc.warning.title'),
        message: t('applications.cc.warning.message', {
          addition: t('applications.cc.warning.additions.certsAndLabs'),
        }),
        onAccept: () => dispatchChangeRole(currentRole, newRole, index),
      });
    }
    return dispatchChangeRole(currentRole, newRole, index);
  };

  const handleSetComponentsFlow = (value: ComponentsFlow) => {
    setComponentsFlow(value);
    dispatch(
      updateApplicationProperty(
        {
          '@type': ComponentCombinationSource.BASE,
          componentCombination: jsonCopy(initialState.componentCombination.componentCombination),
          componentCombinationVariantSource: jsonCopy(
            initialState.componentCombination.componentCombinationVariantSource,
          ),
        } as ComponentCombinationWrapper<ComponentCombination>,
        'componentCombination',
        MemberClearData.CERTIFICATIONS,
        AppFlowSteps.CC,
        true,
      ),
    );

    if (type === AppFlows.NEW) {
      dispatch(setCompareCCInfo(jsonCopy({ ...initialState.compareCCInfo, initialCC: null }) as CompareCCInfo));
    }
  };

  const handleComponentsFlowChange = (value: ComponentsFlow) => {
    if (componentsFlow !== null && componentsFlow !== value && ccLength > 0) {
      Confirm({
        title: t('applications.cc.warning.title'),
        message: t('applications.cc.warning.componentFlowChangeWarning'),
        onAccept: () => {
          handleSetComponentsFlow(value);
          if (state.id && value === ComponentsFlow.CONFIGURE) {
            dispatch(addCCLoadedNoteWarning(state.id));
          }
        },
      });
    } else {
      handleSetComponentsFlow(value);
    }
  };

  return (
    <Form fluid className="mb-1">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {type === AppFlows.NEW && (
            <RadioGroup name="componentsFlow" value={componentsFlow} onChange={handleComponentsFlowChange}>
              <Radio value={ComponentsFlow.PREPOPULATE}>
                {t('applications.cc.loadCC.prepopulate')} <Bold>{t('applications.cc.loadCC.prepopulateWarning')}</Bold>
              </Radio>
              <Radio value={ComponentsFlow.CONFIGURE}>
                {t('applications.cc.loadCC.configure')} <Bold>{t('applications.cc.loadCC.configureWarning')}</Bold>
              </Radio>
            </RadioGroup>
          )}

          {(!!ccVariantSourceId || type === AppFlows.VARIANT) && (
            <LoadedFromBreadcrumps items={state.componentCombination.componentCombinationVariantSource} />
          )}
        </div>
        <div>
          {(componentsFlow === ComponentsFlow.PREPOPULATE || type === AppFlows.VARIANT) && (
            <Button appearance="primary" className="load-cc" onClick={() => setLoadCCModalState(true)}>
              {t('applications.cc.loadCC.btn')}
            </Button>
          )}
        </div>
      </div>

      {(componentsFlow === ComponentsFlow.CONFIGURE ||
        !!ccVariantSourceId ||
        errors['componentCombination.componentCombination.newRoles'] ||
        ccLength > 0) && (
        <>
          {reformattedCC.map((item: RoleItem, index: number) => (
            <div key={index}>
              <Row className="mb-1">
                <Col xs={12}>
                  <InlineSelect
                    className="pt-1"
                    label={`${t('applications.cc.role')}:`}
                    accepter={SelectPicker}
                    noPadding
                    data={roles.map(role => ({ value: role.propertyName, label: role.name }))}
                    cleanable={false}
                    searchable={false}
                    block
                    onChange={(value: string) => changeRole(item.roleName, value, item.index)}
                    name={`componentCombination.componentCombination.${item.roleName}[${item.index}].roleName`}
                    value={item.roleName}
                    placeholder={t('common.placeholders.select')}
                    errors={errors}
                  />
                </Col>
                {index === 0 && (
                  <Col xs={12} className="text-right">
                    <AddRoleButton className="mb-0" onClick={addRole} />
                  </Col>
                )}
              </Row>
              <RoleItemComponent
                roleItem={item}
                prefix={`componentCombination.componentCombination.${item.roleName}[${item.index}]`}
                key={index}
                ccLength={ccLength}
                saveData={saveData}
                handleSetInitialValues={handleSetInitialValues}
              />
            </div>
          ))}
          {cc !== null && (!ccLength || (cc.newRoles && cc.newRoles.length)) ? (
            <div className="mb-2">
              {(ccLength ? cc.newRoles : [{ roleName: '' }]).map((roleItem, key) => (
                <InlineSelect
                  key={key}
                  className="pt-1 mb-0 pb-0"
                  label={t('applications.cc.role')}
                  accepter={SelectPicker}
                  data={roles.map(role => ({ value: role.propertyName, label: role.name }))}
                  cleanable={false}
                  searchable={false}
                  block
                  onChange={(value: string) => changeRole('newRoles', value, 0)}
                  name={`componentCombination.componentCombination.newRoles[0].roleName`}
                  value={roleItem.roleName}
                  placeholder={t('common.placeholders.select')}
                  errors={errors}
                />
              ))}
              {errors['componentCombination.componentCombination.newRoles'] ? (
                <p style={{ marginLeft: 34 }}>
                  <small className="text-danger">{errors['componentCombination.componentCombination.newRoles']}</small>
                </p>
              ) : null}
            </div>
          ) : !reformattedCC.some((role: { roleName: string }) => role.roleName === roleNameMapping.homeDesign) ? (
            <div className="text-right">
              <AddRoleButton onClick={addRole} />
            </div>
          ) : null}
        </>
      )}
      <LoadCCDialog active={isActiveLoadCCModal} onClose={() => setLoadCCModalState(false)} />
    </Form>
  );
};
