import React, { useEffect, useRef, useState } from 'react';
import { Icon, Uploader } from 'rsuite';
import { getAuthHeader } from '../../helpers/axios-setup';
import { apiBaseUrl } from '../../config';
import { UploadedFile } from '../file-upload/types';
import { buildDownloadUrl } from '../../helpers/build-download-url';
import { UploaderInstance, UploaderServerErrorResponse } from '../../helpers/types';
import { useTranslation } from 'react-i18next';
import { customIconsSet, messageKeyPrefix } from '../../helpers/constants';
import styled from 'styled-components';
import { FileType } from 'rsuite/lib/Uploader';
import i18n from '../../helpers/i18n-setup';

export enum UploadFileType {
  APP_IMAGE = 'APP_IMAGE',
  APP_DOC = 'APP_DOC',
  ATL_ATTACHMENT = 'ATL_ATTACHMENT',
  TESTING_EXCEPTION_ATTACHMENT = 'TESTING_EXCEPTION_ATTACHMENT',
}

interface FileWithError extends FileType {
  errorMessage?: string;
}

export type UploadedFileType = FileWithError & UploadedFile;

export const supportedImageTypes = 'image/*';

const StyledUploader = styled(Uploader)`
  .rs-uploader-file-item-content .error {
    color: red;
  }

  .rs-uploader-trigger-btn.rs-icon {
    height: auto;
    line-height: 0;
  }
`;

export const validateAppImage = (file: UploadedFileType): string | null => {
  if (!file.id && file.blobFile && file.blobFile?.size > 51200) {
    return i18n.t('common.validation.fileSizeExceedsLimit', { size: '50Kb' });
  }

  return null;
};

const ChooseFileButton = ({ hidden = false, ...props }: { hidden: boolean }) => {
  const styles = hidden ? { display: 'none' } : {};
  return <Icon style={styles} {...props} icon={customIconsSet.PAPER_CLIP} size="2x" />;
};

const getDefaultFiles = (files: UploadedFile[]) =>
  files.map((file: UploadedFile) => ({ ...file, fileKey: file.id, url: buildDownloadUrl(file) } as UploadedFileType));

const getUploadedFiles = (files: UploadedFileType[]) =>
  files
    .filter(file => file.id)
    .map(file => {
      delete file.blobFile;
      return file;
    }) as UploadedFile[];

const isInProgress = (files: UploadedFileType[]) => files.filter(file => !file.id && !file.errorMessage).length > 0;

const updateFile = (files: UploadedFileType[], file: UploadedFileType) => {
  const updatedFiles = [...files];
  const index = updatedFiles.findIndex(current => current.fileKey === file.fileKey);
  updatedFiles[index] = file;
  return updatedFiles;
};

interface FileUploadModalProps {
  accept?: string;
  multiple?: boolean;
  maxNumberOfFiles?: number;
  fileType: UploadFileType;
  files?: UploadedFile[];
  onChange: (files: UploadedFile[]) => void;
  onLoadingChange?: (isLoading: boolean) => void;
  validateFile?: (file: UploadedFileType) => string | null;
  setError?: (error: boolean) => void;
}

export const FileUploader = ({
  accept,
  multiple = true,
  maxNumberOfFiles,
  fileType,
  files: defaultFiles = [],
  onChange,
  onLoadingChange,
  validateFile,
  setError,
}: FileUploadModalProps) => {
  const { t } = useTranslation();
  const uploaderRef = useRef<UploaderInstance>();
  const [files, setFiles] = useState<UploadedFileType[]>(getDefaultFiles(defaultFiles));
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(defaultFiles);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const updatedFiles = getUploadedFiles(files);
    if (JSON.stringify(uploadedFiles.map(f => f.id)) !== JSON.stringify(updatedFiles.map(f => f.id))) {
      setUploadedFiles(updatedFiles);
      onChange(updatedFiles);
    }

    const inProgress = isInProgress(files);
    if (onLoadingChange && isLoading !== inProgress) {
      onLoadingChange(inProgress);
    }
    setIsLoading(inProgress);
    if (setError) setError(files.some(file => !!file.errorMessage));
  }, [files]);

  const handleChange = (array: FileType[] = []) => {
    setFiles(array as UploadedFileType[]);
  };

  const handleError = async (error: UploaderServerErrorResponse, file: UploadedFileType) => {
    const { message, messageKey, messageParameters } = error.response;
    const translatedMessage = t(messageKey.replace(messageKeyPrefix, ''), messageParameters);
    file.errorMessage = `${messageKeyPrefix}${translatedMessage}` === messageKey ? message : translatedMessage;
    setFiles(updateFile(files, file));
  };

  const handleSuccess = (uploadedFile: UploadedFile, file: FileType) => {
    setFiles(updateFile(files, { ...file, ...uploadedFile } as UploadedFileType));
  };

  const shouldUpload = (file: UploadedFileType): boolean => {
    if (validateFile) {
      const errorMessage = validateFile(file);
      if (errorMessage !== null) {
        setFiles(updateFile(files, { ...file, errorMessage }));
        return false;
      }
    }

    return true;
  };

  const renderFileInfo = (file: UploadedFileType): React.ReactNode => (
    <div className="text-left">
      <span>{file.name}</span>
      {file.errorMessage ? <p className="error">{file.errorMessage}</p> : null}
    </div>
  );

  return (
    <StyledUploader
      accept={accept}
      autoUpload={true}
      listType="text"
      multiple={multiple}
      action={`${apiBaseUrl}/upload-file`}
      headers={{ Authorization: getAuthHeader() }}
      data={{ fileType }}
      onChange={handleChange}
      onSuccess={handleSuccess}
      onError={handleError}
      shouldUpload={shouldUpload}
      fileList={files}
      ref={uploaderRef}
      renderFileInfo={renderFileInfo}
    >
      <ChooseFileButton hidden={!!(maxNumberOfFiles && files.length >= maxNumberOfFiles)} />
    </StyledUploader>
  );
};

export default FileUploader;
