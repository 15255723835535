export const applicationTypes = {
  new: 'NEW',
  variant: 'VARIANT',
  derivative: 'DERIVATIVE',
};

export const staffSummaryAppTypes = {
  ...applicationTypes,
  all: 'ALL',
};

export const applicationTypeTitles = {
  [applicationTypes.new]: 'New',
  [applicationTypes.variant]: 'Variant (of ...)',
  [applicationTypes.derivative]: 'Derivative (of ...)',
};

export const applicationTypeSummary = {
  [applicationTypes.new]: 'New Product Model',
  [applicationTypes.variant]: 'A Variant of ',
  [applicationTypes.derivative]: 'A Derivative of ',
};

export const staffSummaryTitles = {
  [staffSummaryAppTypes.new]: 'New Products',
  [staffSummaryAppTypes.derivative]: 'Derivatives',
  [staffSummaryAppTypes.variant]: 'Variants',
  [staffSummaryAppTypes.all]: 'All',
};
