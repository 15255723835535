import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Table } from 'rsuite';
import { TableProps } from 'rsuite/lib/Table';
import { TableColumnProps } from 'rsuite/lib/Table/TableColumn';

export const RsTableClean = ({ className, height, ...props }: TableProps) => (
  <Table
    rowHeight={50}
    headerHeight={50}
    {...props}
    height={height}
    autoHeight={typeof height === 'undefined'}
    className={className}
    shouldUpdateScroll={false}
  />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRowClassName = (row: any) => {
  if (row && (row.isStriped || row._parent?.isStriped)) {
    return 'is-stripe';
  }
  return '';
};

export const RsTable = ({ data, ...props }: TableProps) => {
  // force render again at first that is workaround for fix RsTable weird row height
  const [loadCount, setLoadCount] = useState(0);
  useEffect(() => {
    if (loadCount == 0) setLoadCount(1);
  }, []);

  const dataArray = (data || []).map((item, index) => {
    item.isStriped = index % 2 !== 0;
    return item;
  });
  return <RsTableClean rowClassName={getRowClassName} data={dataArray} {...props} />;
};

export const RsColumn = ({ children, ...props }: PropsWithChildren<TableColumnProps>) => (
  <Table.Column verticalAlign="middle" {...props}>
    {children}
  </Table.Column>
);
