import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import { ConditionComponentProps, FilterParameterValuesType } from '../types';
import { useStyles } from '../styles';

// to generate initial data for outside
export const generateKeywordSerachConditionInitParameterValue = (
  keyword: string,
  showSelectedLabel: boolean,
): FilterParameterValuesType => {
  return {
    values: [
      {
        value: keyword,
        label: keyword,
      },
    ],
    showSelectedLabel,
  };
};

export const getKeywordSerachConditionCurrentValue = (obj: FilterParameterValuesType): string => {
  return obj.values[0].value as string;
};

export function KeywordSearchCondition({
  parameterName,
  updateFilterParameter,
  currentConditionParameterValues,
  showSelectedLabel,
}: ConditionComponentProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  // initial value from outside
  const [value, setValue] = useState<string>(
    currentConditionParameterValues && currentConditionParameterValues.length > 0
      ? (currentConditionParameterValues[0].value as string)
      : '',
  );

  // for clear all action from outside
  useEffect(() => {
    if (!currentConditionParameterValues) setValue('');
    else setValue(currentConditionParameterValues[0].value as string);
  }, [currentConditionParameterValues]);

  const generateParameterValue = (value: string): FilterParameterValuesType => {
    return { values: [{ value: value, label: value }], showSelectedLabel };
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      updateFilterParameter(parameterName, generateParameterValue(value));
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    let currentValue =
      currentConditionParameterValues &&
      currentConditionParameterValues.length > 0 &&
      (currentConditionParameterValues[0].value as string);
    currentValue = currentValue ? currentValue : '';

    if (currentValue !== value) {
      updateFilterParameter(parameterName, generateParameterValue(value));
    }
  };

  const handleSearch = () => {
    updateFilterParameter(parameterName, generateParameterValue(value));
  };

  const handleClear = () => {
    setValue('');
    updateFilterParameter(parameterName, null);
  };

  return (
    <OutlinedInput
      placeholder={t('common.placeholders.search')}
      endAdornment={
        <InputAdornment position="end">
          {value.length == 0 ? null : (
            <IconButton size="small" disableRipple onClick={handleClear} className={classes.adornmentIconButton}>
              <ClearIcon className={classes.adornmentIcon} />
            </IconButton>
          )}
          <IconButton disableRipple onClick={handleSearch} className={classes.adornmentIconButton}>
            <SearchIcon className={classes.adornmentIcon} />
          </IconButton>
        </InputAdornment>
      }
      onChange={handleChange}
      onKeyPress={handleEnter}
      onBlur={handleBlur}
      value={value}
      fullWidth
      className={classes.outlinedInput}
    />
  );
}
