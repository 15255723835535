import React from 'react';
import { HDRoleItem, RoleItemProps } from './types';
import { Card } from '../../../../../../../shared-components';
import { Anchor } from '../../../../../../../shared-components/styled-components';

interface Props extends RoleItemProps {
  roleItem: HDRoleItem;
}

export const HomeDesignErrorMessage = () => {
  return (
    <p>
      Please contact us at <Anchor href="mailto:support@wi-fi.org">support@wi-fi.org</Anchor> to apply for Wi-Fi Home
      Design certification.
    </p>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HomeDesignRoleItem = (props: Props) => {
  /*const { t } = useTranslation();
  const errors = useSelector<RootReducer, ErrorObject>(state => state.applicationFlow.errors);
  const emptySelectData: { value: string; label: string }[] = [];*/

  return (
    <Card className="mb-1" style={{ position: 'relative' }}>
      <HomeDesignErrorMessage />
      {/*<Row gutter={20}>
        <Col xs={12}>
          <RsField
            label={t('applications.cc.blueprintID')}
            required
            name={`homeDesign.blueprintId`}
            errors={errors}
            disabled
          />
        </Col>
        <Col xs={12}>
          {roleItem.apProducts.map((element: ApProducts, key: number) => (
            <Fragment key={key}>
              <RsFieldWrapper
                noMargin
                disabled
                accepter={SelectPicker}
                data={emptySelectData}
                name={`roles[${roleItem.index}].apProducts[${key}].field`}
                block
                placeholder={t('applications.cc.chooseCompany')}
                label={<HeaderWithButton />}
                errors={errors}
              />
              <div className="ml-1">
                <RsField
                  disabled
                  accepter={SelectPicker}
                  data={emptySelectData}
                  name={`roles[${roleItem.index}].apProducts[${key}].field`}
                  block
                  placeholder={t('applications.cc.chooseProduct')}
                  errors={errors}
                />
              </div>
              <div className="ml-3">
                <RsField
                  disabled
                  accepter={SelectPicker}
                  data={emptySelectData}
                  name={`roles[${roleItem.index}].apProducts[${key}].field`}
                  block
                  placeholder={t('applications.cc.chooseVariant')}
                  errors={errors}
                />
              </div>
            </Fragment>
          ))}
        </Col>
      </Row>
      <a className="add-item el-button el-button--default position-bottom" onClick={event => event.preventDefault()}>
        <Icon icon="plus" />
        <span>{t('applications.cc.anotherAP')}</span>
      </a>*/}
    </Card>
  );
};
