import { withStyles } from '@material-ui/core/styles';
import Step, { StepProps } from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import LifeCycleStepIcon from './LifeCycleStepIcon';
import React from 'react';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const StyledStepLabel = withStyles({
  iconContainer: {
    alignSelf: 'flex-start',
  },
  labelContainer: {
    '& > .MuiStepLabel-label': {
      textAlign: 'left',
    },
    '& .rs-form-group': {
      margin: '0 !important',
    },
  },
  disabled: {
    '& .MuiStepLabel-iconContainer > div': {
      borderWidth: 14,
      '& svg': {
        display: 'none',
      },
    },
    '& .rs-control-label > span': {
      color: `${_var.textLightGray} !important`,
    },
  },
})(StepLabel);

const StyledStep = withStyles({
  root: {
    width: 0,
  },
})(Step);

const LifeCycleStep = ({ children, ...props }: StepProps) => (
  <StyledStep {...props}>
    <StyledStepLabel StepIconComponent={LifeCycleStepIcon}>{children}</StyledStepLabel>
  </StyledStep>
);

export default LifeCycleStep;
