import React from 'react';
import { LabSummaryCertification } from '../types';

export const QualificationsInputSuggestion = ({ item }: { item: LabSummaryCertification }) => {
  return (
    <span>
      <span className="mr-1">{item.name}</span>
      <small className="text-muted mr-1">
        [{item.version.id} - {item.version.name}] ({item.role.name})
      </small>
    </span>
  );
};
