import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Row } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AppFlows, ApplicationFlowState } from '../../../../../../../redux/modules/application-flow';

import {
  LabeledBooleanField,
  LabeledInput,
  LabeledTextArea,
} from '../../../../../../../shared-components/labeled-inputs';
import { Tooltip } from '../../../../../../../shared-components/tooltip/Tooltip';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { isProductOfSolutionProvider } from '../../helpers';
import { ProductCategories, ProductInfoStep } from '../../../../../../../api/application/types';
import { UploadedFile } from '../../../../../../../shared-components/file-upload/types';
import { WrapWithPublicIcon } from '../../../../../../../shared-components/helper-components/WrapWithPublicIcon';
import { DetailsItem } from '../../../../../../partials';
import { Categories } from '../../../../types';
import { ImagePreview } from '../../../../../../../shared-components/helper-components/ImagePreview';
import { buildDownloadUrl } from '../../../../../../../helpers/build-download-url';
import { RsControlLabel } from '../../../../../../../shared-components/rsuite';
import {
  FileUploader,
  supportedImageTypes,
  UploadFileType,
  validateAppImage,
} from '../../../../../../../shared-components/uploader/FileUploader';
import { loadCategories } from '../../../../../../../redux/modules/categories';
import { jsonCopy } from '../../../../../../../helpers';
import { IsModifiedProps, numberSort, useChangeDetection } from '../../../../../../../helpers/hooks/useChangeDetection';
import { ErrorObject } from '../../../../../../../helpers/types';
import {
  AutocompleteField,
  AutocompleteMultipleField,
} from '../../../../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckBoxIcon from '@material-ui/icons/CheckBoxSharp';

interface ReformattedCategories {
  label: string;
  value: number;
  role: string;
}

const calculateHashValue = (value: ProductInfoStep) => {
  const info = jsonCopy(value);

  return JSON.stringify({
    certified: info.certified,
    qualifiedSolution: info.qualifiedSolution,
    name: info.name,
    modelNumber: info.modelNumber,
    url: info.url,
    description: info.description,
    image: info.image ? info.image.id : null,
    primaryCategory: info.primaryCategory ? info.primaryCategory.id : null,
    additionalCategories: info.additionalCategories.map(item => item.id).sort(numberSort),
  });
};

type Props = IsModifiedProps & {
  setLoading: (value: boolean) => void;
  data: ProductInfoStep;
  onChange: (
    value: string | ProductCategories | ProductCategories[] | UploadedFile | UploadedFile[] | boolean | null,
    name: string,
  ) => void;
  errors: ErrorObject;
};

export const EditProductSection = ({ setLoading, setIsModified, data, onChange: handleChange, errors }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { ...application } = useSelector<RootReducer, ApplicationFlowState>(
    state => state.applicationFlow,
    shallowEqual,
  );

  const categories = useSelector<RootReducer, Categories[]>(state => state.categoriesReducer.categories);

  const isVariant = application['@type'] === AppFlows.VARIANT;

  const isQualifiedSolution = data.qualifiedSolution;

  const showQualifiedSolutionFields =
    isProductOfSolutionProvider(application) && application['@type'] !== AppFlows.DERIVATIVE;

  if (showQualifiedSolutionFields && !isQualifiedSolution && data.certified === false) {
    data.certified = true;
  }

  /*const updateChangeDetectionState = */ useChangeDetection(setIsModified, calculateHashValue, data);

  const [appImageLoading, setAppImageLoading] = useState(false);

  useEffect(() => {
    setLoading(appImageLoading);
  }, [appImageLoading]);

  const onProductImageChange = (file: UploadedFile[]): void => {
    const productImage = file && file.length ? file[0] : null;
    handleChange(productImage, 'productInfo.image');
  };

  // convert from array object like [{ id: 1 }, { id: 2 }] => [1,2]
  const reformatFromIdToNumber = (categories: ProductCategories[]) => {
    return categories.map((category: ProductCategories) => category.id);
  };

  const getReformattedCategories = (): ReformattedCategories[] =>
    categories.reduce((accum, category) => {
      accum.push(
        ...(category.categories.map(categoryItem => ({
          label: categoryItem.name,
          value: categoryItem.id,
          role: category.name,
        })) as ReformattedCategories[]),
      );

      return accum;
    }, [] as ReformattedCategories[]);

  useEffect(() => {
    if (!categories.length) {
      dispatch(loadCategories());
    }
  }, [categories]);

  return (
    <>
      <Form fluid>
        {showQualifiedSolutionFields ? (
          <Row gutter={20} className="mt-2">
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.qualifiedSolution"
                errors={errors}
                label={
                  <>
                    {t('applications.info.isQualifiedSolution')}
                    <Tooltip translationKey="tooltips.isQualifiedSolution" />
                  </>
                }
                disabled={isVariant}
                value={data.qualifiedSolution}
                onChange={value => handleChange(value, 'productInfo.qualifiedSolution')}
              />
            </Col>
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.certified"
                errors={errors}
                label={
                  <>
                    {t('applications.info.isWiFiCertified')}
                    <Tooltip
                      width={400}
                      content={
                        <div>
                          <div>{t('tooltips.isWiFiCertifiedTitle')}</div>
                          <ul style={{ paddingInlineStart: '2em' }}>
                            <li>{t('tooltips.isWiFiCertifiedOptionYes')}</li>
                            <li>{t('tooltips.isWiFiCertifiedOptionNo')}</li>
                          </ul>
                        </div>
                      }
                    />
                  </>
                }
                disabled={!isQualifiedSolution || isVariant}
                value={data.certified}
                onChange={value => handleChange(value, 'productInfo.certified')}
              />
            </Col>
          </Row>
        ) : null}
        <Row gutter={20} className="mt-2">
          <Col xs={12}>
            <DetailsItem className="mb-1" label={t('applications.info.cid')} value={data.cid} />
          </Col>
          <Col xs={12}>
            <DetailsItem
              className="mb-1"
              label={t('applications.summary.company')}
              value={application.companyContactInfo?.owningCompany?.name}
            />
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            <LabeledInput
              label={<WrapWithPublicIcon content={t('applications.info.name')} />}
              name="productInfo.name"
              placeholder={t('applications.info.name')}
              errors={errors}
              value={data.name}
              onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => handleChange(value, event.target.name)}
              required
            />
          </Col>
          <Col xs={12}>
            <LabeledInput
              required
              label={<WrapWithPublicIcon content={t('applications.info.modelNumber')} />}
              name="productInfo.modelNumber"
              placeholder={t('applications.info.modelNumber')}
              errors={errors}
              value={data.modelNumber}
              onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => handleChange(value, event.target.name)}
            />
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            <>
              <label className="rs-control-label">
                <span className="text-danger">* </span>
                <WrapWithPublicIcon content={t('applications.info.primaryCategory')} />
              </label>
              <AutocompleteField
                value={data?.primaryCategory?.id}
                onChange={id => handleChange({ id }, 'productInfo.primaryCategory')}
                onClean={() => handleChange(null, 'productInfo.primaryCategory')}
                getOptionDisabled={option => data.additionalCategories.some(category => category.id === option.value)}
                data={getReformattedCategories()}
                labelKey={'label'}
                name={'productInfo.primaryCategory'}
                placeholder={'Start typing...'}
                valueKey={'value'}
                groupBy={'role'}
                errors={errors}
              />
            </>
          </Col>
          <Col xs={12}>
            <>
              <label className="rs-control-label">
                <WrapWithPublicIcon content={t('applications.info.additionalCategory')} />
              </label>
              <AutocompleteMultipleField
                value={reformatFromIdToNumber(data.additionalCategories)}
                onChange={(values: ReformattedCategories[]) =>
                  handleChange(
                    values.map(category => ({ id: category.value })),
                    'productInfo.additionalCategories',
                  )
                }
                data={getReformattedCategories()}
                labelKey={'label'}
                errors={errors}
                name={'productInfo.additionalCategories'}
                placeholder={'Start typing...'}
                valueKey={'value'}
                groupBy={'role'}
                getOptionDisabled={option => option.value === data.primaryCategory?.id}
                disableCloseOnSelect
                renderMenuItem={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={selected}
                      style={{ padding: 0, paddingRight: 10 }}
                    />
                    {option.label}
                  </>
                )}
              />
            </>
          </Col>
        </Row>
        <LabeledInput
          label={<WrapWithPublicIcon content={t('applications.info.url')} />}
          noMargin
          name="productInfo.url"
          placeholder={t('applications.info.url')}
          errors={errors}
          value={data.url || ''}
          onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => handleChange(value, event.target.name)}
        />
        <LabeledTextArea
          label={<WrapWithPublicIcon content={t('applications.info.description')} />}
          name="productInfo.description"
          placeholder={t('applications.info.description')}
          errors={errors}
          value={data.description}
          onChange={(value: string, event: ChangeEvent<HTMLTextAreaElement>) => handleChange(value, event.target.name)}
        />
        <Row gutter={20} className="mb-2">
          <Col xs={12}>
            <FormGroup style={{ marginBottom: 0 }}>
              <RsControlLabel>
                <WrapWithPublicIcon content={t('applications.info.image')} />
              </RsControlLabel>
              <FileUploader
                multiple={false}
                fileType={UploadFileType.APP_IMAGE}
                onChange={onProductImageChange}
                maxNumberOfFiles={1}
                validateFile={validateAppImage}
                accept={supportedImageTypes}
                files={data.image !== null && data.image ? [data.image] : []}
                onLoadingChange={setAppImageLoading}
              />
              <div>{data.image ? <ImagePreview src={buildDownloadUrl(data.image)} alt="Product Image" /> : null}</div>
            </FormGroup>
          </Col>
          <Col xs={12}></Col>
        </Row>
      </Form>
    </>
  );
};
