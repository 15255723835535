export const CertificationTypes = {
  primary: 'PRIMARY',
  sub: 'SUB',
};

export const CertificationTypeTitles = {
  [CertificationTypes.primary]: 'Primary',
  [CertificationTypes.sub]: 'Sub',
};

export const RedesignCertificationTypes = {
  primary: 'PRIMARY',
  capability: 'CAPABILITY',
};

export const RedesignCertificationTypeTitles = {
  [RedesignCertificationTypes.primary]: 'Primary',
  [RedesignCertificationTypes.capability]: 'Capability',
};
