import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';
import { AxiosError, AxiosResponse } from 'axios';

import { Confirm } from '../../../../helpers/confirmationPopup/Confirm';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { PageTemplate } from '../../../partials';
import { getAccountInfoById } from '../../../../api/staff/account-info';
import { AccountInfo } from '../../staff/types';
import { GeneralInfomation } from './components/GeneralInfomation';
import { TransferSection } from './components/TransferSection';
import { ChangeOwningCompanyTransferTable } from './components/ChangeOwningCompanyTransferTable';
import { SingleSummaryProduct } from '../../product/types';
import { SortingOptions, SortType } from '../../test-case/types';
import { parseUrlParams } from '../../../../helpers';
import { GetSummaryDefaultOptions, StartSizePaginated } from '../../../../helpers/types';
import { DataTableDataLoader } from '../../../../shared-components/table/DataTableDataLoader';
import {
  getTransferProducts,
  getAllTransferProducts,
  submitTransferProducts,
} from '../../../../api/staff/get-transfer-products';
import { TransferSummaryFiltersObject } from './types';
import { BackToListButton } from '../../../../shared-components/button/BackToListButton';

const ChangeOwningCompanyTemplate = ({ match, history }: RouteComponentProps<{ from: string; to: string }>) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const location = useLocation();
  const params = parseUrlParams(location.search);

  const [loadingCompanyData, setLoadingCompanyData] = useState(false);
  const [companyFrom, setCompanyFrom] = useState<AccountInfo | null>(null);
  const [companyTo, setCompanyTo] = useState<AccountInfo | null>(null);
  const [allProductModalIds, setAllProductModalIds] = useState<number[]>([]);
  const [singlePageProductModalIds, setSinglePageProductModalIds] = useState<number[]>([]);
  const [selectedProductModalIds, setSelectedProductModalIds] = useState<number[]>([]);

  const [loadingData, setLoadingData] = useState(false);
  const [loadingSelectAllData, setLoadingSelectAllData] = useState(false);
  const [sorting, setSorting] = useState<SortingOptions>({ sortColumn: 'date', sortType: 'desc' });
  const [currentPage, setCurrentPage] = useState(params.page ? Number(params.page) : 1);
  const [total, setTotal] = useState(0);
  const [totalCount, setTotalCount] = useState(-1);
  const [applicableCount, setApplicableCount] = useState(0);
  const [tableData, setTableData] = useState<SingleSummaryProduct[]>([]);
  const [filters, setFilters] = useState<TransferSummaryFiltersObject>({
    search: null,
    from: null,
    to: null,
  });

  const handleBackToList = () => history.push('/admin/change-owning-company-summary');

  const getSummary = (options: GetSummaryDefaultOptions & TransferSummaryFiltersObject = {}) => {
    setLoadingData(true);
    const { sortingOptions = sorting, page = currentPage, search, from, to } = options;

    getTransferProducts(companyFrom.id, page, sortingOptions, search, from, to)
      .then((response: AxiosResponse<StartSizePaginated<SingleSummaryProduct>>) => {
        const { data } = response;
        setTableData(data.content);
        setTotal(data.totalHits);
        if (totalCount === -1) setTotalCount(data.totalHits);
        setSinglePageProductModalIds(data.content.filter(d => d.unapprovedVariants?.length === 0).map(d => d.id));
        setLoadingData(false);
      })
      .catch((error: AxiosError) => {
        setTableData([]);
        setTotal(0);
        setTotalCount(0);
        setSinglePageProductModalIds([]);
        setLoadingData(false);
        handleRequestFail(error, addToast);
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getSummary({ page, search: filters.search, from: filters.from, to: filters.to });
  };

  const handleSortChange = (sortColumn: string, sortType: SortType) => {
    const sortingOptions = { sortColumn, sortType };
    setSorting(sortingOptions);
    getSummary({ sortingOptions, page: 1, search: filters.search, from: filters.from, to: filters.to });
  };

  const handleSelectSingle = (id: number, checked: boolean) => {
    setSelectedProductModalIds(prevState => {
      let newState = [...prevState];
      if (checked) newState.push(id);
      else newState = newState.filter(val => val !== id);
      return newState;
    });
  };

  const handleClearSelection = () => {
    setSelectedProductModalIds([]);
  };

  const handleSelectAll = () => {
    setSelectedProductModalIds(allProductModalIds);
  };

  const handleSinglePageSelectAll = (checked?: boolean) => {
    if (checked !== false) {
      setSelectedProductModalIds(
        singlePageProductModalIds.reduce(
          (accu, id) => {
            if (selectedProductModalIds.indexOf(id) === -1) accu.push(id);
            return accu;
          },
          [...selectedProductModalIds],
        ),
      );
    } else {
      setSelectedProductModalIds(selectedProductModalIds.filter(id => singlePageProductModalIds.indexOf(id) === -1));
    }
  };

  const isSinglePageSelectAll = () => {
    return singlePageProductModalIds.every(id => selectedProductModalIds.indexOf(id) !== -1);
  };

  const isSinglePageSelectPartial = () => {
    return singlePageProductModalIds.some(id => selectedProductModalIds.indexOf(id) !== -1);
  };

  const handleTransfer = () => {
    Confirm({
      title: t('common.placeholders.areYouSure'),
      message: (
        <span>
          {t('admin.changeOwningCompany.transfer.confirmMessage1')}
          <b> {selectedProductModalIds.length} </b>
          {t('admin.changeOwningCompany.transfer.confirmMessage2', { count: selectedProductModalIds.length })}
          <b> {companyFrom?.name} </b>
          {t('admin.changeOwningCompany.transfer.confirmMessage3')}
          <b> {companyTo?.name} </b>
          {t('admin.changeOwningCompany.transfer.confirmMessage4')}
        </span>
      ),
      onAccept: () => {
        submitTransferProducts(companyFrom.id, companyTo.id, selectedProductModalIds.sort())
          .then(() => {
            addToast(t('admin.changeOwningCompany.transfer.successMessage'), {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
            handleBackToList();
          })
          .catch(error => {
            handleRequestFail(error, addToast);
          });
      },
    });
  };

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      // companies infomation
      Promise.all([getAccountInfoById(match.params.from), getAccountInfoById(match.params.to)])
        .then(responses => {
          setCompanyFrom(responses[0].data);
          setCompanyTo(responses[1].data);
          setLoadingCompanyData(false);
        })
        .catch((error: AxiosError) => {
          setLoadingCompanyData(false);
          handleRequestFail(error, addToast);
        });
    };
    setLoadingCompanyData(true);
    fetchCompanyInfo();
  }, []);

  useEffect(() => {
    if (companyFrom) {
      getSummary();

      setLoadingSelectAllData(true);
      getAllTransferProducts(companyFrom.id)
        .then((response: AxiosResponse<number[]>) => {
          setAllProductModalIds(response.data);
          setApplicableCount(response.data.length);
          setLoadingSelectAllData(false);
        })
        .catch((error: AxiosError) => {
          setLoadingSelectAllData(false);
          handleRequestFail(error, addToast);
        });
    }
  }, [companyFrom]);

  useEffect(() => {
    if (companyFrom) {
      setCurrentPage(1);
      getSummary({ page: 1, search: filters.search, from: filters.from, to: filters.to });
    }
  }, [filters]);

  return (
    <PageTemplate
      title={t('admin.changeOwningCompany.changeOwningCompany')}
      actionLeft={<BackToListButton onClick={handleBackToList} />}
      footerActionLeft={<></>}
      withBorder
    >
      {loadingCompanyData ? null : <GeneralInfomation companyFrom={companyFrom} companyTo={companyTo} />}
      <TransferSection
        title={`${t('admin.changeOwningCompany.transfer.productsOf')} ${companyFrom?.name}`}
        totalCount={totalCount}
        applicableCount={applicableCount}
        selectedCount={selectedProductModalIds.length}
        onTransfer={handleTransfer}
        updateFilters={setFilters}
        onSelectAll={handleSelectAll}
        onClearAll={handleClearSelection}
      >
        <ChangeOwningCompanyTransferTable
          data={tableData}
          loading={loadingData}
          sorting={sorting}
          total={total}
          activePage={currentPage}
          setActivePage={handlePageChange}
          onChangeSorting={handleSortChange}
          selectedProductModalIds={selectedProductModalIds}
          handleSelectSingle={handleSelectSingle}
          handleSelectAll={handleSinglePageSelectAll}
          isSelectParital={isSinglePageSelectPartial() && !isSinglePageSelectAll()}
          isSelectAll={isSinglePageSelectAll()}
        />
      </TransferSection>
      {loadingSelectAllData && <DataTableDataLoader />}
    </PageTemplate>
  );
};

export const ChangeOwningCompanyPage = withRouter(ChangeOwningCompanyTemplate);
