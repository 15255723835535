import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { Button, FlexboxGrid } from 'rsuite';

import { HistoryEvent } from '../pages/application/types';
import { PageTemplate } from '../partials/page-template';
import Timeline from '../../shared-components/timeline/Timeline';
import { CircularLoader } from '../../shared-components/loader/CircularLoader';
import { closeWindow } from '../../helpers';
import { CloseButton } from '../../shared-components/button/CloseButton';

type EventHistoryApiCall = (id: number) => AxiosPromise<HistoryEvent[]>;

export function getEventHistoryPageComponent(title: string, apiCall: EventHistoryApiCall) {
  const HistoryPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch<{ id: string }>();

    const [events, setEvents] = useState<HistoryEvent[]>([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleGoBack = () => history.goBack();

    useEffect(() => {
      apiCall(Number(match.params.id))
        .then((response: AxiosResponse<HistoryEvent[]>) => {
          setEvents(response.data);
          setLoading(false);
        })
        .catch((error: AxiosError<{ message: string }>) => {
          setErrorMessage(error.response?.data?.message || error.message);
          setLoading(false);
        });
    }, []);

    if (loading) {
      return (
        <div style={{ height: '200px' }}>
          <CircularLoader content={t('common.placeholders.loadingData')} />
        </div>
      );
    }

    return (
      <PageTemplate
        noFooter
        title={title}
        actionLeft={
          window.opener ? (
            <CloseButton onClick={closeWindow} />
          ) : (
            <Button onClick={handleGoBack}>{t('common.actions.goBack')}</Button>
          )
        }
      >
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>{errorMessage || <Timeline events={events} />}</FlexboxGrid.Item>
        </FlexboxGrid>
      </PageTemplate>
    );
  };

  return React.memo(HistoryPage);
}
