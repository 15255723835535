import { createSelector } from 'reselect';
import {
  LabRequest,
  LRBasicRequestedTest,
  LRCertification,
  LRRequestedTest,
} from '../../../components/pages/atl-testing/types';
import { AppCertification } from '../application-flow';
import { TestResult } from './types';

const getBasicCertificationInfo = (
  requestedTest: LRBasicRequestedTest,
  allRequestedTests: LRRequestedTest[],
): LRCertification =>
  ({
    id: requestedTest.certification.id,
    name: requestedTest.certification.name,
    roleName: requestedTest.certification.role.name,
    versionName: requestedTest.certification.version.name,
    prequalified: requestedTest.certification.prequalified,
    prerequisiteOf: requestedTest.certification.prerequisiteOf.map(el => ({
      ...el,
      isBold: allRequestedTests.some(
        item =>
          item.certification.version.id === el.version.id ||
          item.capabilities.some(capability => capability.certification.version.id === el.version.id),
      ),
    })),
    conformanceTestResult: requestedTest.testResult.conformance,
    conformanceTestRequested: requestedTest.testResult.conformance !== TestResult.NOT_REQUESTED,
    interoperabilityTestResult: requestedTest.testResult.interoperability,
    interoperabilityTestRequested: requestedTest.testResult.interoperability !== TestResult.NOT_REQUESTED,
    status: requestedTest.certification.version.status,
  } as LRCertification);

export const selectLabRequest = (state: { testingFlow: LabRequest | null }) => state.testingFlow;

export const selectRequestedCertifications = createSelector([selectLabRequest], labRequest => {
  if (!labRequest) {
    return [];
  }

  const { requestedTests } = labRequest;

  const defaultValue: LRCertification[] = [];
  return requestedTests.reduce((certifications, next): LRCertification[] => {
    const certification = {
      ...getBasicCertificationInfo(next, requestedTests),
      interoperabilityTemplate: next.certification.interoperabilityTemplate,
      capabilities: next.capabilities.map(capability => getBasicCertificationInfo(capability, requestedTests)),
    };

    const isTemplateAndTestRequested =
      next.testResult.interoperability !== TestResult.NOT_REQUESTED &&
      next.certification.interoperabilityTemplate !== null;

    certification.uniqueTemplate =
      isTemplateAndTestRequested &&
      !certifications.some(
        (cert: LRCertification) =>
          cert.interoperabilityTemplate &&
          next.certification.interoperabilityTemplate &&
          cert.interoperabilityTestRequested &&
          cert.interoperabilityTemplate.id === next.certification.interoperabilityTemplate.id,
      );

    if (next.certification.interoperabilityTemplate && next.testResult.interoperability === TestResult.NONE) {
      certification.interoperabilityTestResult = TestResult.SUCCESS;
      certification.capabilities = [];
      certification.capabilities.forEach(subCert => {
        subCert.interoperabilityTestResult = TestResult.SUCCESS;
      });
    }

    certifications.push(certification);
    return certifications;
  }, defaultValue);
});

export const selectIsInteroperabilityNotUploaded = createSelector([selectLabRequest], labRequest => {
  return labRequest?.interoperabilityTestTemplateResults.some(interResult => interResult.result === null);
});

export const selectIsInteroperabilityNotPassed = createSelector([selectRequestedCertifications], certifications => {
  return certifications.some(
    cert =>
      cert.interoperabilityTestRequested &&
      (cert.interoperabilityTestResult !== TestResult.SUCCESS ||
        cert.capabilities.some(
          subCert => subCert.interoperabilityTestRequested && subCert.interoperabilityTestResult !== TestResult.SUCCESS,
        )),
  );
});

export const selectIsConformanceResultsInvalid = createSelector(
  [selectLabRequest, selectRequestedCertifications],
  (labRequest, certifications) => {
    return (
      certifications.some(cert => cert.conformanceTestRequested) &&
      labRequest?.conformanceTestResult?.passed !== TestResult.SUCCESS
    );
  },
);

export const selectAllLabRequestCertifications = createSelector([selectLabRequest], labRequest => {
  if (!labRequest) {
    return [];
  }

  const { application, requestedTests, otherTests } = labRequest;
  const { certifications } = application;

  // merge duplicate certifications in otherTests
  const mergedOtherTests: LRRequestedTest[] = [];
  otherTests.forEach(test => {
    const existTest = mergedOtherTests.find(otherTest => otherTest.certification.id === test.certification.id);
    if (existTest) {
      if (test.testResult.conformance !== TestResult.NOT_REQUESTED)
        existTest.testResult.conformance = test.testResult.conformance;
      if (test.testResult.interoperability !== TestResult.NOT_REQUESTED)
        existTest.testResult.interoperability = test.testResult.interoperability;
    } else {
      mergedOtherTests.push(test);
    }
  });

  const formatCertifications: AppCertification[] = certifications.map(cert => {
    const test = requestedTests.find(test => test.certification.id === cert.id);
    const otherLab = mergedOtherTests.find(otherTest => otherTest.certification.id === cert.id);
    return {
      ...cert,
      conformanceTestRequested: !!(test && test.testResult?.conformance !== TestResult.NOT_REQUESTED),
      interoperabilityTestRequested: !!(test && test.testResult?.interoperability !== TestResult.NOT_REQUESTED),
      conformanceTestRequestedByOtherLab: !!(otherLab && otherLab.testResult?.conformance !== TestResult.NOT_REQUESTED),
      interoperabilityTestRequestedByOtherLab: !!(
        otherLab && otherLab.testResult?.interoperability !== TestResult.NOT_REQUESTED
      ),
    } as AppCertification;
  });

  return formatCertifications;
});

export const selectLabRequestLabInfo = createSelector([selectLabRequest], labRequest => labRequest?.labInfo);
