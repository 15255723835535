import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'rsuite';
import styled from 'styled-components';

import { reformatDate } from '../../../../helpers';
import { formatFee } from '../../../../helpers/billing';
import { applicationStatusTitles } from '../../../../helpers/constants';
import { AppCertification, AppFlows } from '../../../../redux/modules/application-flow';
import { Tooltip } from '../../../../shared-components/tooltip/Tooltip';
import { CCDetailsWithCompare } from '../../../pages/staff/partials/CCDetailsWithCompare';
import { DetailsItem } from '../../../partials';
import { LoadedFromBreadcrumps } from '../../../partials/LoadedFromBreadcrumbs.tsx';
import SectionWithCollapse from '../../../partials/SectionWithCollapse';
import { GetFeeResponse } from '../../../partials/billing/types';
import { ApplicationTypeDetails } from '../../../partials/summary-template/ApplicationTypeDetails';
import { LRLabInfo } from '../../atl-testing/types';
import { ProductInfo } from '../../product/partials/ProductInfo';
import { CertificationsSections } from '../../staff/partials/CertificationsSection';
import { ApplicationInfoRecord } from '../types';

export const RowWithMargin = styled(Row)`
  margin-bottom: 1rem;
`;

export const Section = styled.section`
  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  > h4 {
    margin-bottom: 1rem;
  }
`;

export const ApplicationDetails = ({
  application,
  applicationFees,
  withCC = true,
  withVariantInfo = true,
  useCollapse = false,
  certifications,
  labInfo,
  showCertificationNote = false,
}: {
  application: ApplicationInfoRecord;
  applicationFees?: GetFeeResponse[];
  withCC?: boolean;
  withVariantInfo?: boolean;
  useCollapse?: boolean;
  certifications?: AppCertification[];
  labInfo?: LRLabInfo;
  showCertificationNote?: boolean;
}) => {
  const { t } = useTranslation();
  const [expandedCC, setExpandedCC] = useState<string[]>([]);
  const ccChanged = application.componentCombination.componentCombinationVariantSource?.coreCCChanged;
  const comparedCC = application.componentCombination.componentCombinationVariantSource?.componentCombination;

  const toggleCC = (key: string, isOpen: boolean) => {
    setExpandedCC(prevState => (isOpen ? [...prevState, key] : prevState.filter(item => item !== key)));
  };

  return (
    <>
      <SectionWithCollapse header={t('applications.info.companyInformation')} useCollapse={useCollapse}>
        <RowWithMargin gutter={20}>
          <Col xs={12}>
            <DetailsItem
              label={t('testing.review.submittingCompany')}
              value={application.companyContactInfo?.submittingCompany?.name}
            />
          </Col>
          <Col xs={12}>
            <DetailsItem label={t('applications.flowSelect.additionalEmail')}>
              {application.companyContactInfo?.additionalEmail.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </DetailsItem>
          </Col>
        </RowWithMargin>
        <RowWithMargin gutter={20}>
          <Col xs={12}>
            <DetailsItem label={t('testing.review.submittedBy')} value={application.companyContactInfo?.submittedBy} />
          </Col>
          <Col xs={12}>
            <DetailsItem
              label={t('testing.review.owningCompany')}
              value={application.companyContactInfo?.owningCompany?.name}
            />
          </Col>
        </RowWithMargin>
        <DetailsItem label={t('applications.summary.sideBar.applicationType')}>
          <ApplicationTypeDetails
            applicationType={application['@type']}
            companyContactInfo={application.companyContactInfo}
            productInfo={application.productInfo}
          />
        </DetailsItem>
      </SectionWithCollapse>
      <SectionWithCollapse header={t('applications.info.applicationInfo')} useCollapse={useCollapse}>
        <RowWithMargin gutter={20}>
          <Col xs={12}>
            <DetailsItem
              label={t('applications.info.applicationStatus')}
              value={applicationStatusTitles[application.status]}
            />
          </Col>
          <Col xs={12}>
            <DetailsItem
              label={t('applications.info.approvedDate')}
              value={
                application.majorEvents?.approved?.createdAt
                  ? reformatDate(application.majorEvents.approved.createdAt)
                  : null
              }
            />
          </Col>
        </RowWithMargin>
        {application.majorEvents?.firstSubmitted?.createdAt ? (
          <RowWithMargin gutter={20}>
            <Col xs={12}>
              <DetailsItem
                label={t('applications.info.firstSubmitDate')}
                value={reformatDate(application.majorEvents.firstSubmitted.createdAt)}
              />
            </Col>
            {application.additionalInfo && application.additionalInfo['@type'] === 'WithPayer' ? (
              <Col xs={12}>
                <DetailsItem
                  label={t('applications.payment.invoiceTo')}
                  value={
                    application.additionalInfo.payer && application.additionalInfo.payer.id
                      ? application.additionalInfo.payer.id == application.companyContactInfo.submittingCompany?.id
                        ? application.companyContactInfo.submittingCompany.name
                        : application.additionalInfo.payer.id == application.companyContactInfo.owningCompany?.id
                        ? application.companyContactInfo.owningCompany.name
                        : null
                      : null
                  }
                />
              </Col>
            ) : null}
          </RowWithMargin>
        ) : null}
      </SectionWithCollapse>
      <ProductInfo application={application} withVariantInfo={withVariantInfo} useCollapse={useCollapse} />
      {withCC ? (
        <Section>
          <h4>{t('applications.cc.wifiComponent')}</h4>
          <RowWithMargin gutter={20}>
            <Col xs={24}>
              {!!application.componentCombination.componentCombinationVariantSource && (
                <div style={{ marginTop: '1rem' }}>
                  <LoadedFromBreadcrumps
                    items={application.componentCombination.componentCombinationVariantSource}
                    ccChanged={ccChanged}
                    showCcChangeLabel={application['@type'] !== AppFlows.DERIVATIVE}
                  />
                </div>
              )}
              <CCDetailsWithCompare
                expandedCC={expandedCC}
                toggleCC={toggleCC}
                cc={application.componentCombination.componentCombination}
                compareCC={ccChanged ? comparedCC : undefined}
                ccChanged={ccChanged}
              />
            </Col>
          </RowWithMargin>
        </Section>
      ) : null}
      {certifications ? (
        <CertificationsSections
          certifications={certifications}
          compareCertifications={[]}
          useCollapse={useCollapse}
          labInfo={labInfo}
          showNote={showCertificationNote}
        />
      ) : null}
      {applicationFees?.map(
        (feeResponse, feeResponseIndex) =>
          !!feeResponse.fees.length &&
          feeResponse.fees.map(
            (fee, feeIndex) =>
              fee.feeType === 'SUBMITTER' && (
                <Section key={fee?.priceBookEntry?.id} data-testid="billingInfo">
                  {feeResponseIndex === 0 && feeIndex === 0 && <h4>{t('billing.billingInfo')}</h4>}
                  <RowWithMargin gutter={20}>
                    <Col xs={12}>
                      <DetailsItem
                        label={
                          feeResponse['@type'] === 'ESTIMATE' ? (
                            <>
                              {t('billing.estimatedLicenseFee')}
                              <Tooltip translationKey={t('billing.estimatedFeesTooltip')} />
                            </>
                          ) : (
                            t('billing.licenseFee')
                          )
                        }
                        value={formatFee(fee.priceBookEntry.price, fee.priceBookEntry.name)}
                      />
                    </Col>
                    <Col xs={12}>
                      <DetailsItem label={t('billing.requestingAccount')} value={feeResponse.opportunity?.owner.name} />
                    </Col>
                  </RowWithMargin>
                  <RowWithMargin gutter={20}>
                    <Col xs={12}>
                      <DetailsItem label={t('applications.payment.PONumber')} value={application.additionalInfo.po} />
                    </Col>
                    <Col xs={12}>
                      <DetailsItem
                        label={t('applications.payment.CTIANumber')}
                        value={application.additionalInfo.ctia}
                      />
                    </Col>
                  </RowWithMargin>
                </Section>
              ),
          ),
      )}
    </>
  );
};
