import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'rsuite';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router';

import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { getImpersonationCompanies } from '../../../../api/impersonate';
import { ImpersonateAutoCompleteItem, Role } from '../../../partials/impersonate/types';
import { PageTemplate } from '../../../partials';
import { ChangeOwningCompanySummaryRecord, ChangeOwningCompanySummaryDetail } from './types';
import { CircularLoader } from '../../../../shared-components/loader/CircularLoader';
import { ChangeOwningCompanySummaryTable } from './components/ChangeOwningCompanySummaryTable';
import { SelectCompaniesModal } from './components/SelectCompaniesModal';
import { SummaryDetailModal } from './components/SummaryDetailModal';
import { getTransferSummary, getTransferDetail } from '../../../../api/staff/get-transfer-products';
import { SortingOptions, SortType } from '../../../../components/pages/test-case/types';
import { recordPerPage } from '../../../../helpers/constants';

const ChangeOwningCompanySummaryTemplate = ({ history }: RouteComponentProps<{}>) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [loadingData, setLoadingData] = useState(false);
  const [summaryData, setSummaryData] = useState<ChangeOwningCompanySummaryRecord[]>([]);
  const [pageData, setPageData] = useState<ChangeOwningCompanySummaryRecord[]>([]);
  const [showSelectCompaniesModal, setShowSelectCompaniesModal] = useState(false);
  const [loadingDetailData, setLoadingDetailData] = useState(false);
  const [showSummaryDetailModal, setShowSummaryDetailModal] = useState(false);
  const [summaryDetailPayload, setSummaryDetailPayload] = useState<ChangeOwningCompanySummaryDetail>();
  const [companies, setCompanies] = useState<ImpersonateAutoCompleteItem[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [sorting, setSorting] = useState<SortingOptions>({ sortColumn: 'date', sortType: 'desc' });
  const refreshTimeoutId = useRef<number>(0);

  const clearRefreshTimeout = () => {
    if (refreshTimeoutId.current > 0) clearTimeout(refreshTimeoutId.current);
  };

  const getTransferData = () => {
    setLoadingData(true);
    getTransferSummary()
      .then((response: AxiosResponse<ChangeOwningCompanySummaryRecord[]>) => {
        setSummaryData(response.data);
        setLoadingData(false);

        // some data is still processing.
        if (response.data.some(record => record.processed === false)) {
          clearRefreshTimeout();
          refreshTimeoutId.current = setTimeout(getTransferData, 30 * 1000);
        }
      })
      .catch((error: AxiosError) => {
        setLoadingData(false);
        handleRequestFail(error, addToast);
      });
  };

  const getPageData = (pageNumber: number, sorting: SortingOptions) => {
    summaryData.sort((a: ChangeOwningCompanySummaryRecord, b: ChangeOwningCompanySummaryRecord): number => {
      let sortNumber = 0;

      if (sorting.sortColumn === 'date') sortNumber = a.timestamp.localeCompare(b.timestamp);
      if (sorting.sortColumn === 'sourceCompanyName')
        sortNumber = a.sourceCompanyName.localeCompare(b.sourceCompanyName);
      if (sorting.sortColumn === 'targetCompanyName')
        sortNumber = a.targetCompanyName.localeCompare(b.targetCompanyName);

      return sortNumber * (sorting.sortType === 'asc' ? 1 : -1);
    });
    setPageData(summaryData.slice(recordPerPage * (pageNumber - 1), recordPerPage * pageNumber));
    setActivePage(pageNumber);
    setSorting(sorting);
  };

  const handleChangePage = (newPage: number) => {
    getPageData(newPage, sorting);
  };

  const handleChangeSorting = (sortColumn: string, sortType: SortType) => {
    getPageData(1, { sortColumn, sortType });
  };

  useEffect(() => {
    getPageData(activePage, sorting);
  }, [summaryData]);

  useEffect(() => {
    Promise.all([getImpersonationCompanies(Role.MEMBER), getImpersonationCompanies(Role.IMPLEMENTER)])
      .then((responses: AxiosResponse<ImpersonateAutoCompleteItem[]>[]) => {
        const companies = [...responses[0].data, ...responses[1].data];
        companies.sort((a, b) => a.name.localeCompare(b.name));
        setCompanies(companies);
      })
      .catch((error: AxiosError) => {
        handleRequestFail(error, addToast);
      });
  }, []);

  useEffect(() => {
    getTransferData();
    return clearRefreshTimeout;
  }, []);

  const closeSelectCompaniesModal = () => {
    setShowSelectCompaniesModal(false);
  };

  const openSummaryDetailModal = (recordId: number, isSucceed: boolean) => {
    setLoadingDetailData(true);
    getTransferDetail(recordId)
      .then(response => {
        setSummaryDetailPayload({ ...response.data, isSucceed });
        setLoadingDetailData(false);
        setShowSummaryDetailModal(true);
      })
      .catch((error: AxiosError) => {
        setLoadingDetailData(false);
        handleRequestFail(error, addToast);
      });
  };

  const closeSummaryDetailModal = () => {
    setShowSummaryDetailModal(false);
  };

  const applyChooseCompany = (companyFrom: number, companyTo: number) => {
    history.push(`/admin/change-owning-company/${companyFrom}/${companyTo}`);
  };

  return (
    <PageTemplate
      title={t('admin.changeOwningCompany.title')}
      actionRight={
        <Button
          appearance="primary"
          data-test-id="button.changeOwningCompany"
          onClick={() => {
            setShowSelectCompaniesModal(true);
          }}
        >
          {t('admin.changeOwningCompany.changeOwningCompany')}
        </Button>
      }
      noFooter
    >
      <ChangeOwningCompanySummaryTable
        data={pageData}
        loading={loadingData}
        onClickSummaryDetail={openSummaryDetailModal}
        total={summaryData.length}
        activePage={activePage}
        sorting={sorting}
        handleChangePage={handleChangePage}
        handleChangeSorting={handleChangeSorting}
      />
      <SelectCompaniesModal
        show={showSelectCompaniesModal}
        companies={companies}
        onClose={closeSelectCompaniesModal}
        onApply={applyChooseCompany}
      />
      <SummaryDetailModal
        show={showSummaryDetailModal}
        payload={summaryDetailPayload}
        onClose={closeSummaryDetailModal}
      />
      {loadingDetailData && <CircularLoader fullPage backdrop />}
    </PageTemplate>
  );
};

export const ChangeOwningCompanySummaryPage = withRouter(ChangeOwningCompanySummaryTemplate);
