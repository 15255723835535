import React, { memo, useMemo } from 'react';
import { ErrorObject } from '../../helpers/types';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import _var from '../../styles/_variables.scss';
import { extractError } from '../labeled-inputs/utils';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import { SelectPicker } from 'rsuite';

const useStyles = makeStyles({
  ml1: {
    marginLeft: '1rem',
    paddingBottom: '1.2rem',
    '&:before': {
      top: '74px',
      width: '1rem',
      height: '1px',
      content: '""',
      position: 'absolute',
      backgroundColor: '#e5e5ea',
      marginLeft: '-1rem',
    },
  },
  ml3: {
    marginLeft: '3rem',
    paddingBottom: '1.2rem',
    '&:before': {
      top: '77%',
      width: '3rem',
      height: '1px',
      content: '""',
      position: 'absolute',
      backgroundColor: '#e5e5ea',
      marginLeft: '-3rem',
    },
  },
  verticalLine: {
    '&:before': {
      top: '3%',
      width: '1px',
      height: '123px',
      content: '""',
      position: 'absolute',
      backgroundColor: '#e5e5ea',
    },
    paddingBottom: '1.2rem',
  },
  shortVerticalLine: {
    '&:before': {
      top: '3%',
      width: '1px',
      height: '64%',
      content: '""',
      position: 'absolute',
      backgroundColor: '#e5e5ea',
    },
    paddingBottom: '1.2rem',
  },
  input: {
    '& .MuiInputBase-input': {
      padding: '4px !important',
      fontSize: '14px',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '22px !important',
      zIndex: 1,
      backgroundColor: 'white',
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EBEEF5',
      transition: 'border-color ease-in-out 0.3s',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      transition: 'border-color ease-in-out 0.3s',
      borderColor: '#4d0630',
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      opacity: 0.15,
    },
    border: 'none',
    color: 'transparent',
    textShadow: '0 0 0 gray',
    textAlign: 'center',

    '&:focus': {
      outline: 'none',
    },
  },
  option: {
    fontSize: 14,
    '&[data-focus="true"]': {
      backgroundColor: _var.secondary,
    },
    '&[aria-selected="true"]': {
      fontWeight: 'bold',
      color: _var.primary,
      backgroundColor: _var.secondary,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#641246',
    },
  },
  closeButton: {
    fontSize: '14px',
    background: 'inherit',
    color: '#a6a6a6',
    transition: '0.2s color linear',
    cursor: 'pointer',
    marginTop: '1px',
    // marginRight: '4px',
    '&:hover': {
      color: '#f44336',
    },
  },
  selectButton: {
    fontSize: '14px',
    background: 'inherit',
    color: '#a6a6a6',
    cursor: 'pointer',
    margin: '2px 4px',
    '&:before': {
      content: '"\\ea08"',
      fontFamily: 'rsuite-icon-font !important',
    },
  },
  autocomplete: {
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '40px !important',
    },
    position: 'relative',
  },
  multipleAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      paddingBottom: '3px !important',
      paddingTop: '3px !important',
      '& input': {
        marginBottom: '3px !important',
        marginTop: '3px !important',
      },
    },
    '& .MuiChip-root': { height: 24 },
    '& .MuiChip-deleteIcon': { height: 18 },
    paddingBottom: '1.2rem',
  },
  errorMessage: {
    position: 'absolute',
    top: '35px',
    left: '2px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  errorMessageMultiple: {
    position: 'absolute',
    top: '75px',
    left: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
});

export interface HierarchicalDropdownProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onSelect?: (value: any, item: any) => void;
  data: Array<any>;
  value: any;
  labelKey: string;
  valueKey: string;
  placeholder: string;
  onChange: (value: any) => void;
  onClean?: () => void;
  name: string;
  errors?: ErrorObject;
  loading?: boolean;
  hideSearch?: boolean;
  hideClose?: boolean;
  getOptionLabel?: (option: any) => string;
  renderMenuItem?: (option: any, state: any) => React.ReactNode;
  disabled?: boolean;
  groupBy?: string;
  multiple?: boolean;
  onDelete?: (value: any) => void;
  inputLabel?: React.ReactNode;
  getOptionDisabled?: (option: any) => boolean;
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  PopperComponent?: any;
}

/* eslint-enable @typescript-eslint/no-explicit-any */

export const AutocompleteMultipleField = (props: HierarchicalDropdownProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const value = useMemo(
    () => props?.data?.filter(dataItem => props.value.some((item: number) => dataItem[props.valueKey] === item)) || [],
    [props],
  );

  const error = extractError(props.errors, props.name);

  return (
    <Autocomplete
      multiple
      classes={{ option: classes.option }}
      value={value}
      className={classes.multipleAutocomplete}
      options={props.data}
      getOptionLabel={option => option[props.labelKey] || ''}
      groupBy={option => props.groupBy && option[props.groupBy]}
      popupIcon={<span className={classes.selectButton} />}
      onChange={(e, value) => props.onChange(value)}
      disableCloseOnSelect={props.disableCloseOnSelect}
      openOnFocus
      renderInput={params => (
        <>
          <TextField
            {...params}
            fullWidth
            size="small"
            variant="outlined"
            className={classes.input}
            label={props.inputLabel}
            error={!!error}
            placeholder={props.placeholder ?? t('common.placeholders.autoComplete')}
          />
          {!!error && <small className={classNames(classes.errorMessageMultiple, 'text-danger')}>{error}</small>}
        </>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <Chip key={option} label={option[props.labelKey]} {...getTagProps({ index })} />)
      }
      closeIcon={null}
      renderOption={props.renderMenuItem}
      getOptionDisabled={props.getOptionDisabled}
    />
  );
};

export const AutocompleteField = (props: HierarchicalDropdownProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const value = useMemo(() => props?.data?.find(dataItem => dataItem[props.valueKey] === props.value) || null, [props]);
  const error = extractError(props.errors, props.name);

  return props.hideSearch ? (
    // @ts-ignore
    <SelectPicker
      data={props.data}
      value={props.value}
      onChange={props.onChange}
      labelKey={props.labelKey}
      valueKey={props.valueKey}
      placeholder={props.placeholder}
      name={props.name}
      cleanable={!props.hideClose}
      searchable={!props.hideSearch}
      block
    />
  ) : (
    <Autocomplete
      PopperComponent={props.PopperComponent}
      disableClearable={props.disableClearable}
      options={props.data}
      classes={{ option: classes.option }}
      className={classes.autocomplete}
      multiple={props.multiple}
      closeIcon={
        value &&
        !props.hideClose && (
          <span className={classes.closeButton} role="button" tabIndex={-1}>
            ✕
          </span>
        )
      }
      popupIcon={<span className={classes.selectButton} />}
      value={value}
      onChange={(e, value) => (value ? props.onChange(value[props.valueKey]) : props.onClean && props.onClean())}
      getOptionLabel={item => (props.getOptionLabel ? props.getOptionLabel(item) : item[props.labelKey] || '')}
      renderOption={(item, state) =>
        props.renderMenuItem ? props.renderMenuItem(item, state) : <>{item[props.labelKey]}</>
      }
      disabled={props.disabled}
      groupBy={option => props.groupBy && option[props.groupBy]}
      openOnFocus
      getOptionDisabled={props.getOptionDisabled}
      renderInput={params => (
        <>
          <TextField
            {...params}
            fullWidth
            size="small"
            variant="outlined"
            className={classes.input}
            error={!!error}
            placeholder={props.placeholder ?? t('common.placeholders.autoComplete')}
          />
          {!!error && !props.disabled && (
            <small className={classNames(classes.errorMessage, 'text-danger')}>{error}</small>
          )}
        </>
      )}
    />
  );
};

const HierarchicalDropdown = ({ dropdownData }: { dropdownData: HierarchicalDropdownProps[] }) => {
  const classes = useStyles();

  const getClassname = (index: number) => {
    if (index === 1) return classes.ml1;
    if (index === 2) return classes.ml3;
    if (dropdownData.length === 2) return classes.shortVerticalLine;
    return classes.verticalLine;
  };

  return (
    <div>
      {dropdownData.map((item, index) => (
        <div className={getClassname(index)} key={index}>
          <AutocompleteField
            {...item}
            disabled={!!index && (!dropdownData[index - 1]?.value || dropdownData[index - 1].value === -1)}
          />
        </div>
      ))}
    </div>
  );
};

export default memo(HierarchicalDropdown);
