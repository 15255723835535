import React from 'react';
import styled from 'styled-components';

const Paragraph = styled.p`
  line-height: 1rem;
`;

export const TabLabel = (data: { value: string | number; label: string }): React.ReactElement<string> => {
  return (
    <div className="text-center">
      <Paragraph>{data.value}</Paragraph>
      <Paragraph>{data.label}</Paragraph>
    </div>
  );
};
