import styled from 'styled-components';
// @ts-ignore
import _var from '../../../styles/_variables.scss';

export const TabsHeader = styled.aside`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TabHeadersWrapper = styled.aside`
  display: flex;
`;

export const TabHeader = styled.div`
  background-color: #f5f5f5;
  padding: 1rem 2rem;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  color: ${_var.labelGray};
  &:not(:last-of-type) {
    margin-right: 3px;
  }

  &.is-active {
    color: ${_var.textGray};
    pointer-events: none;
    background-color: white;
    border-radius: 0;
    position: relative;
    font-weight: bold;

    &:before {
      content: '';
      height: 6px;
      width: 100%;
      background-color: ${_var.primary};
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 3px;
    }
  }
  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const TabContent = styled.div`
  padding-top: 1rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
