import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { StyledNodePlaceholderElement } from './StyledComponents';
import { certificationAutoComplete } from '../../../api/certification/auto-complete';
import { Certification } from '../types';
import { certificationTypeTitles } from '../../../components/pages/certification/types';
import { AsyncAutocomplete } from '../../inputs/AsyncAutocomplete';

const StyledWrapper = styled(StyledNodePlaceholderElement)`
  display: flex;
`;

const renderOption = (option: Certification) => {
  return (
    <div>
      <div className="m-0">{option.name}</div>
      <small className="text-muted">
        {certificationTypeTitles[option.type]} | {option.role.name}
      </small>
    </div>
  );
};

interface CertificationInputProps {
  onSelect: (item: Certification) => void;
  disabledItems?: number[];
}

export const CertificationInput = (props: PropsWithChildren<CertificationInputProps>) => {
  const { children, onSelect, disabledItems = [] } = props;
  return (
    <div className="run-element-wrapper">
      <StyledWrapper className="run-element">
        <AsyncAutocomplete<Certification, false, true, false>
          onChange={onSelect}
          renderOption={renderOption}
          getOptionLabel={option => option.name}
          searchService={certificationAutoComplete}
          getOptionDisabled={option => disabledItems?.includes(option.id)}
        />
        {children}
      </StyledWrapper>
    </div>
  );
};
