import React from 'react';
import { Icon } from 'rsuite';
import {
  addSelectedTestingLab,
  ApplicationFlowState,
  changeNameSelectedTestingLab,
  removeSelectedTestingLab,
} from '../../../../../../redux/modules/application-flow';
import { TestingLab } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '../../../../../../redux/rootReducer';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AutocompleteField } from '../../../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';
import { Popper } from '@material-ui/core';
import { defaultSelectedLabValue } from '../../../../../../redux/modules/application-flow/data';

const RemoveLabButton = styled.div`
  display: inline-flex;
  .rs-icon {
    font-size: 1.6rem;
    padding: 0 0.4rem;
    cursor: pointer;
    color: black;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s linear;
    }
  }
`;

const AddLabButton = styled(Icon)`
  position: absolute;
  cursor: pointer;
  top: 3px;
  right: 12px;
  padding: 6px;
  color: black;
  border: 1px solid black;
  border-radius: 1rem;
  opacity: 0.7;

  &:hover {
    opacity: 1;
    transition: opacity 0.3s linear;
  }
`;

export const LabItem = ({ currentLab, labs, index }: { labs: TestingLab[]; currentLab: TestingLab; index: number }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedTestingLabs } = useSelector<RootReducer, ApplicationFlowState>(state => state.applicationFlow);
  const handleRemoveLab = (id: number | null): void => {
    dispatch(removeSelectedTestingLab({ labId: id }));
  };

  const handleAddLab = (): void => {
    dispatch(addSelectedTestingLab());
  };

  const handleChangeName = (value: number, index: number): void => {
    dispatch(changeNameSelectedTestingLab({ lab: labs.find(lab => lab.id === value) as TestingLab, index }));
  };

  const handleResetName = (index: number): void => {
    dispatch(changeNameSelectedTestingLab({ lab: defaultSelectedLabValue as TestingLab, index }));
  };

  const AutoCompletePopper = function(props) {
    return <Popper {...props} style={{ width: 'fit-content', marginRight: '200px' }} placement="bottom-start" />;
  };

  return (
    <>
      <div style={{ width: '180px' }}>
        <AutocompleteField
          value={currentLab.id}
          onChange={value => handleChangeName(value, index)}
          getOptionDisabled={option => selectedTestingLabs.some(lab => lab.id === option.id)}
          disableClearable={false}
          data={labs}
          labelKey="name"
          name={`testingLab${index}`}
          placeholder={t('common.placeholders.select')}
          valueKey="id"
          PopperComponent={AutoCompletePopper}
          onClean={() => handleResetName(index)}
        />
      </div>
      {index !== 0 ? (
        <RemoveLabButton>
          <Icon
            icon="trash-o"
            onClick={() => handleRemoveLab(currentLab.id)}
            style={{
              paddingRight:
                selectedTestingLabs.length !== 3 && selectedTestingLabs.length - 1 === index ? '1.5rem' : '0',
            }}
          />
        </RemoveLabButton>
      ) : null}
      {labs.length > 1 && selectedTestingLabs.length < 3 && selectedTestingLabs.length - 1 === index ? (
        <AddLabButton icon="plus" onClick={() => handleAddLab()} />
      ) : null}
    </>
  );
};
