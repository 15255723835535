import React, { useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DateTime } from 'luxon';

import { DatePicker as MaterialKeyboardDatePicker } from '../../../../../shared-components/keyboard-datepicker/KeyboardDatePicker';
import { ConfirmDialog } from '../../../../../shared-components/confirm-dialog/ConfirmDialog';
import { extractError } from '../../../../../shared-components/labeled-inputs/utils';
import { isDateAfter } from '../../../../../helpers';
import { ErrorObject } from '../../../../../helpers/types';

// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const useStyles = makeStyles((theme: Theme) => ({
  errorText: {
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.75rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    color: _var.errorRed,
    '&:hover': {
      overflow: 'unset',
    },
  },
  dialog: {
    '& .MuiDialogActions-root': {
      display: 'none',
      width: 1,
      height: 1,
      visibility: 'hidden',
    },
  },
  confirmDialogContent: {
    marginBottom: theme.spacing(3.75),
  },
}));

interface DatePickerProps {
  errors?: ErrorObject;
  label?: string | JSX.Element;
  required?: boolean;
  name: string;
  value: MaterialUiPickersDate | string | null | Date;
  onChange: (value: MaterialUiPickersDate, name: string) => void;
  withConfirmDialog?: boolean;
}

export const KeyboardDatePicker: React.FC<DatePickerProps> = ({
  required,
  label,
  errors,
  name,
  value,
  onChange,
  withConfirmDialog = false,
}) => {
  const [openDialogConfirm, setOpenDialogConfirm] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(value ?? null);
  const [isUsedKeyboard, setIsUsedKeyboard] = useState<boolean>(false);

  const error = extractError(errors || {}, name);
  const classes = useStyles();
  const { t } = useTranslation();

  const showSelectedValue = !isUsedKeyboard && openDialogConfirm ? null : selectedDate;
  // @ts-ignore
  const dateNow = DateTime.now();
  const setChanges = _.throttle(onChange, 100);

  const handleOpenConfirmDialog = (date: string | undefined) => {
    if (date && withConfirmDialog) {
      const todayDate = new Date(Date.now()).toString();
      const isDateBefore = !isDateAfter(date, [todayDate]);
      if (isDateBefore) {
        return setOpenDialogConfirm(true);
      }
    } else {
      return null;
    }
  };

  const handleChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date);
    setChanges(date, name);
    if (isUsedKeyboard && date?.isValid) {
      return handleOpenConfirmDialog(date?.toString());
    }
    return setSelectedDate(date);
  };

  const handleConfirmOnDialog = () => {
    setOpenDialogConfirm(false);
    // @ts-ignore
    return onChange(selectedDate, name);
  };

  const handleCloseDialog = () => {
    setOpenDialogConfirm(false);
    setSelectedDate(null);
    return onChange(null, name);
  };

  const handleClearInput = () => {
    setSelectedDate(null);
    return onChange(null, name);
  };

  const useKeyboard = _.throttle(() => setIsUsedKeyboard(true), 500);

  return (
    <>
      {label ? (
        <label className="rs-control-label">
          {required ? <span className="text-danger">* </span> : null}
          {label}
        </label>
      ) : null}
      <MaterialKeyboardDatePicker
        error={!!error}
        handleClearInput={handleClearInput}
        autoOk
        value={showSelectedValue}
        placeholder="MM/DD/YYYY"
        onChange={handleChange}
        variant="dialog"
        name={name}
        onAccept={date => handleOpenConfirmDialog(date?.toString())}
        openTo={'year'}
        views={['year', 'month', 'date']}
        maxDate={dateNow.plus({ years: 25 })}
        minDate={dateNow.minus({ years: 10 })}
        inputVariant="outlined"
        format="MM/dd/yyyy"
        onInput={useKeyboard}
        onOpen={() => setIsUsedKeyboard(false)}
        DialogProps={{
          className: classes.dialog,
          transitionDuration: { enter: 300, exit: 400 },
        }}
      />
      {error ? <Typography className={classes.errorText}>{error}</Typography> : null}
      {withConfirmDialog ? (
        <ConfirmDialog
          open={openDialogConfirm}
          handleConfirm={handleConfirmOnDialog}
          handleClose={handleCloseDialog}
          title={t('common.placeholders.areYouSure')}
          style={{ width: 400 }}
        >
          <p className={classes.confirmDialogContent}>{t('certifications.version.confirmDateBeforeToday')}</p>
        </ConfirmDialog>
      ) : null}
    </>
  );
};
