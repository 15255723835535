import React from 'react';
import { Icon } from 'rsuite';
import styled from 'styled-components';
import { Card } from '../../../../shared-components';
import { RsControlLabel } from '../../../../shared-components/rsuite';
import { useTranslation } from 'react-i18next';
import { customIconsSet } from '../../../../helpers/constants';

const ExceptionLegendWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LegendCard = styled(Card)`
  .el-card__body {
    padding: 0.2rem 0.9rem;

    label {
      padding: 0.3rem 1rem;
    }

    i {
      vertical-align: text-bottom;
    }
  }
`;

export const IconsLegend = () => {
  const { t } = useTranslation();
  return (
    <ExceptionLegendWrapper>
      <LegendCard>
        <RsControlLabel>Legend:</RsControlLabel>
        <RsControlLabel>
          <Icon icon="check" size="lg" /> {t('testing.uploadResults.pass')}
        </RsControlLabel>
        <RsControlLabel>
          <Icon icon={customIconsSet.QUESTION_SQUARE} size="lg" style={{ verticalAlign: 'baseline' }} />
          {t('testing.uploadResults.notTested')}
        </RsControlLabel>
        <RsControlLabel>
          <Icon icon={customIconsSet.ANGLE_WITH_CROSS} size="lg" /> {t('testing.uploadResults.fail')}
        </RsControlLabel>
      </LegendCard>
    </ExceptionLegendWrapper>
  );
};
