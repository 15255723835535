import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon, Input, InputGroup, SelectPicker, Table, Tooltip, Whisper, Popover } from 'rsuite';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useDispatch, useSelector } from 'react-redux';

import { atlRoles, customIconsSet, memberRoles, wfaStaffRoles } from '../../../../../helpers/constants';
import { RsColumn, RsTable } from '../../../../../shared-components/theme';
import { TreeToEnable, TreeToUncheck } from '../../types';
import { SortingOptions, SortType } from '../../../test-case/types';
import { sortByProperty } from '../../../../../helpers/sort-by-property';
import {
  AppCertification,
  AppCertPrereqs,
  ApplicationFlowState,
  checkCertification,
  resetCertifications,
  setCertificationsLoader,
  unCheckCertification,
  uncheckCertificationTree,
  updateCertifications,
} from '../../../../../redux/modules/application-flow';
import { RootReducer } from '../../../../../redux/rootReducer';
import { CheckboxCell } from './partials/CheckboxCell';
import {
  CertificationVersion,
  getCertificationsByAppId,
  GetCertificationsByCC,
} from '../../../../../api/application/get-certifications-by-CC';
import { jsonCopy, mergeObjects } from '../../../../../helpers';
import { Confirm } from '../../../../../helpers/confirmationPopup/Confirm';
import { getFormattedCertifications } from '../../data/pre-req-helpers';
import { PreviouslyCertifiedLegendTitles } from '../../../../../shared-components/styled-components/PreviouslyCertifiedLegend';
import { IsModifiedProps, useChangeDetection } from '../../../../../helpers/hooks/useChangeDetection';
import { WarningTooltip } from '../../../../../shared-components/tooltip/WarningTooltip';
import { CertificationStatuses } from '../../../../../helpers/constants/certification-statuses';
import { userState } from '../../../../../redux/modules/user/reducer';
import { User } from '../../../../../redux/modules/user/types';
import { CircularLoader } from '../../../../../shared-components/loader/CircularLoader';
import { PrerequisiteOf } from '../../../../../components/pages/atl-testing/types';
import { PrerequisitesInfo } from '../../../../partials/prerequisites/PrerequisitesInfo';
import { ECNUrl } from '../../../../../config';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const macPhyCerts = [
  'Wi-Fi CERTIFIED 6™',
  'Wi-Fi CERTIFIED™ ac',
  'Wi-Fi CERTIFIED™ n',
  'Wi-Fi CERTIFIED™ g',
  'Wi-Fi CERTIFIED™ b',
  'Wi-Fi CERTIFIED™ a',
  'Wi-Fi CERTIFIED WiGig™',
];

const requiredCerts = ['Wi-Fi CERTIFIED WPA3™'];

const { HeaderCell, Cell } = Table;

const NonWrappingSpan = styled.span`
  white-space: pre;
`;

const NonWrappingText = ({ label, index, total }: { label: string; index: number; total: number }) => {
  return (
    <>
      <NonWrappingSpan key={label}>{label}</NonWrappingSpan>
      {index === total - 2 ? ' or ' : index === total - 1 ? '. ' : ', '}
    </>
  );
};

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;

  > span:first-of-type {
    padding-right: 20px;
  }
`;

const CheckBoxHeaderCell = styled(HeaderCell)`
  text-align: right;

  a {
    color: ${_var.primary};

    &:hover {
      color: ${_var.primary};
      opacity: 0.8;
    }

    &.is-disabled {
      color: ${_var.disabledGray};
    }
  }
`;

const calculateHashValue = (values: AppCertification[]) => {
  return JSON.stringify(
    jsonCopy(values)
      .map(cert => ({
        id: cert.id,
        versionId: cert.version.id,
      }))
      .sort((a, b) => a.id - b.id),
  );
};

export interface ReformattedCertTableRow extends GetCertificationsByCC {
  selectedVersion: CertificationVersion;
}

interface CertificationsStepProps extends IsModifiedProps {
  handleChangeProceedWithSelection: (value: boolean) => void;
}

export const CertificationsStep: React.FC<CertificationsStepProps> = ({
  setIsModified,
  handleChangeProceedWithSelection,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    certifications,
    certificationsLoading,
    testingInfo,
    selectedTestingLabs,
    ...application
  }: ApplicationFlowState = useSelector<RootReducer, ApplicationFlowState>(state => state.applicationFlow);
  const user = useSelector<{ userReducer: userState }, User>(state => state.userReducer.user as User);
  const [searchString, setSearchString] = useState('');
  const [searchStringApplied, setSearchStringApplied] = useState('');
  const [sorting, setSorting] = useState<SortingOptions>({
    sortColumn: 'certification.name',
    sortType: 'asc',
  });
  const [certificationsArray, setCertificationsArray] = useState<ReformattedCertTableRow[]>([]);
  const defaultMissingInfo = {
    skipAdding: false,
    roleName: '',
  };
  const missingInfoRef = useRef(defaultMissingInfo);
  const filtered = useMemo(
    () =>
      certificationsArray
        .filter(cert => cert.certification.name.toLowerCase().includes(searchStringApplied.toLowerCase()))
        .sort((a, b) => sortByProperty(a, b, sorting)),
    [certificationsArray, sorting, searchStringApplied],
  );

  const canProceedWithSelection = certificationsArray
    .filter(cert => certifications.find(selectedCert => cert.selectedVersion.version.id === selectedCert.version.id))
    .every(
      cert =>
        cert.versions.find(c => c.version.id === cert.selectedVersion.version.id)?.canProceedWithSelection === true,
    );

  useEffect(() => {
    handleChangeProceedWithSelection(canProceedWithSelection);
  }, [certifications]);

  const updateChangeDetectionState = useChangeDetection(setIsModified, calculateHashValue, certifications);

  const needToShowPopUp =
    certifications.some(cert => cert.capabilities.length > 0) ||
    testingInfo.testingLabs.length > 0 ||
    Boolean(testingInfo.testingException) ||
    selectedTestingLabs.some(el => el.id !== null);

  const handleSortChange = (sortColumn: string, sortType: SortType) => {
    setSorting({ sortColumn, sortType });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setSearchStringApplied(searchString);
    }
  };

  const applySearchFilter = () => {
    setSearchStringApplied(searchString);
  };

  const clearSearchFilter = () => {
    setSearchStringApplied('');
    setSearchString('');
  };

  const resetCertificationSelection = () =>
    Confirm({
      title: t('common.placeholders.areYouSure'),
      message: t('applications.certifications.resetSelection'),
      onAccept: () => dispatch(resetCertifications()),
    });

  const getCheckBoxProps = (row: ReformattedCertTableRow): { checked: boolean; disabled: boolean } => {
    const selectedCert = certifications.find(cert => cert.id === row.certification.id);
    if (selectedCert) {
      // disabled if prereq
      return {
        disabled: Boolean(selectedCert.isPreReq),
        checked: true,
      };
    }
    return { disabled: false, checked: false };
  };

  const getPrerequisiteOf = (row: ReformattedCertTableRow): PrerequisiteOf[] | undefined => {
    const selectedCert = certifications.find(cert => cert.id === row.certification.id);
    if (selectedCert && selectedCert.isPreReq) {
      const prerequisiteOf: PrerequisiteOf[] = [];
      certifications.forEach(cert => {
        if (cert.id === selectedCert.id) return;
        const certification = certificationsArray.find(item => item.certification.id === cert.id);
        if (certification) {
          certification.selectedVersion.prerequisites.forEach(preReq => {
            if (preReq.certification.id === row.certification.id) {
              prerequisiteOf.push({
                id: certification.certification.id,
                name: certification.certification.name,
                version: {
                  id: certification.selectedVersion.version.id,
                  name: certification.selectedVersion.version.name,
                  status: certification.selectedVersion.version.status as CertificationStatuses,
                },
                role: { ...certification.certification.role },
                isBold: true,
              });
            }
          });
        }
      });
      prerequisiteOf.sort((a, b) => a.name.localeCompare(b.name));
      return prerequisiteOf;
    }
    return undefined;
  };

  // find primary that is pre-req to other cert or it's sub
  const findUsedPreReqs = ({ certification }: AppCertPrereqs, currentId: number) => {
    return certifications.filter(
      cert =>
        cert.id !== currentId &&
        cert.version?.prerequisites?.some(prereq => prereq.certification.id === certification.id),
    );
  };

  const buildPreReqTree = (preReqs: AppCertPrereqs[], buildPreReqTreeStack: AppCertification[]): AppCertification[] => {
    if (!preReqs || preReqs.length === 0 || missingInfoRef.current.skipAdding) {
      return [];
    }
    const tree: AppCertification[] = [];
    preReqs.forEach(preReq => {
      const certification = certificationsArray.find(item => item.certification.id === preReq.certification.id);
      if (certification) {
        const { versions, certification: certificationInfo } = certification;
        const allowedVersions = versions.filter(({ version }) =>
          preReq.allowedVersions.some(allowedVer => allowedVer.id === version.id),
        );

        const version =
          // first we need to try if the already selected prereq version is ok for us:
          allowedVersions.find(version => version.version.id === certification.selectedVersion.version.id) ||
          // otherwise we just pick the first one from the allowed list (aka "the latest")
          allowedVersions[0] ||
          certification.versions.find(item => item.previouslySelected) ||
          certification.versions[0];
        //need to set the selected version here as well so that on reset it doesn't switch
        certification.selectedVersion = version;

        // map cert to correct structure
        const item = {
          ...certificationInfo,
          isPreReq: true,
          version: {
            ...version.version,
            prerequisites: version.prerequisites,
          },
          allowedVersions: allowedVersions.map(({ version }) => ({ id: version.id })),
          capabilities: [],
        };
        tree.push(item);
        const additionalItems = [];

        const isStackFull = buildPreReqTreeStack.some(({ id }) => id === item.id);

        if (item.version.prerequisites && item.version.prerequisites.length > 0 && !isStackFull) {
          buildPreReqTreeStack.push(item);
          additionalItems.push(...buildPreReqTree(item.version.prerequisites, buildPreReqTreeStack));
        }

        // additional items is found within lower lvl of pre-reqs or subs
        additionalItems.forEach(cert => {
          const idx = tree.findIndex(item => item.id === cert.id);
          // we need to ensure that we will not add same cert couple of times
          if (idx !== -1) {
            cert.isPreReq = cert.isPreReq || tree[idx].isPreReq;
          } else {
            tree.push(cert);
          }
        });
      } else {
        missingInfoRef.current = {
          skipAdding: true,
          roleName: preReq.certification.role.name,
        };
      }
    });
    return tree;
  };

  // find all certs that can be enabled and recursively build the tree
  const buildPreReqTreeToEnable = (certification: CertificationVersion, currentId: number): TreeToEnable[] => {
    const tree: TreeToEnable[] = [];
    certification.prerequisites.forEach(preReq => {
      const otherWithSamePreReq = findUsedPreReqs(preReq, currentId);

      const preReqCertification = certifications.find(item => item.id === preReq.certification.id);
      if (preReqCertification) {
        const treeItem: TreeToEnable = {
          id: preReqCertification.id,
          // enable if not pre-req of other cert or has no pre-req subs
          // property needed in cases when we need to enable subs, but primary remains disabled
          enable: otherWithSamePreReq.length === 0,
        };

        tree.push(treeItem);
      }
    });
    return tree;
  };

  const dispatchUnCheckCertification = payload => {
    dispatch(setCertificationsLoader(true));
    dispatch(unCheckCertification(payload));
  };

  const changeCheckedCertifications = (
    { certification, selectedVersion }: ReformattedCertTableRow,
    checked: boolean,
    cleanup = false,
  ) => {
    const certToChange: AppCertification = {
      ...certification,
      version: {
        ...selectedVersion.version,
        prerequisites: selectedVersion.prerequisites,
      },
      capabilities: [],
    };
    if (checked) {
      const preReqsInfo = buildPreReqTree(selectedVersion.prerequisites, []);
      if (!missingInfoRef.current.skipAdding) {
        dispatch(setCertificationsLoader(true));
        dispatch(
          checkCertification({
            certification: certToChange,
            preReqs: preReqsInfo,
            cleanup,
          }),
        );
      } else {
        return Confirm({
          title: 'Some prerequisites are missing',
          yesText: 'Got it',
          cancelable: false,
          message: `Please, return to Wi-Fi Components and add ${missingInfoRef.current.roleName} role`,
          onAccept: () => {
            missingInfoRef.current = defaultMissingInfo;
          },
        });
      }
    } else {
      const payload = {
        certification: certToChange,
        preReqs: buildPreReqTreeToEnable(selectedVersion, certification.id),
        cleanup,
      };
      // if it is pre-certified then poup warning
      if (certification.prequalified) {
        return Confirm({
          title: t('applications.cc.warning.title'),
          message: t('applications.certifications.removePreCertifiedWarning', {
            addition: 'certification',
          }),
          onAccept: () => {
            dispatchUnCheckCertification(payload);
          },
        });
      }
      dispatchUnCheckCertification(payload);
    }
  };

  const handleCertificationChange = (certification: ReformattedCertTableRow, checked: boolean) => {
    if (needToShowPopUp) {
      return Confirm({
        title: t('applications.cc.warning.title'),
        message: t('applications.cc.warning.message', {
          addition: `${t('applications.cc.warning.additions.labs')}, ${t(
            'applications.cc.warning.additions.capabilities',
          )}`,
        }),
        onAccept: () => {
          setTimeout(() => {
            changeCheckedCertifications(certification, checked, true);
          }, 150);
        },
      });
    }
    return changeCheckedCertifications(certification, checked);
  };

  const buildUnUsedPreReqsTree = (certification: ReformattedCertTableRow): TreeToUncheck[] => {
    const tree: TreeToUncheck[] = buildPreReqTree(certification.selectedVersion.prerequisites, []).map(
      ({ id, name }) => ({
        id,
        name,
        keep: false,
      }),
    );

    const otherCerts = certifications.filter(
      cert => cert.id !== certification.certification.id && !tree.some(treeItem => treeItem.id === cert.id),
    );
    if (otherCerts.length === 0) {
      return tree;
    }
    tree.forEach(item => {
      const preReqTo = otherCerts.filter(
        cert =>
          cert.version.prerequisites &&
          cert.version.prerequisites.some(({ certification }) => certification.id === item.id),
      );
      if (preReqTo.length > 0) {
        item.keep = true;
      }
    });
    return tree;
  };

  const changeVersion = (value: CertificationVersion, row: ReformattedCertTableRow, cleanUp = false) => {
    const indexOfSelected = certifications.findIndex(item => item.id === row.certification.id);
    if (indexOfSelected !== -1) {
      const tree = [{ id: row.certification.id, keep: false }, ...buildUnUsedPreReqsTree(row)];
      dispatch(
        uncheckCertificationTree({
          tree,
          cleanup: true,
        }),
      );
      changeCheckedCertifications(
        {
          ...row,
          certification: mergeObjects(row.certification, {
            isPreReq: certifications[indexOfSelected].isPreReq,
            allowedVersions: certifications[indexOfSelected].allowedVersions,
          }),
          selectedVersion: value,
        },
        true,
        cleanUp,
      );
    }
    setCertificationsArray(prevState => {
      const newState = jsonCopy(prevState);
      const idx = newState.findIndex(el => el.certification.id === row.certification.id);
      if (idx !== -1) {
        newState[idx].selectedVersion = value;
      }
      return newState;
    });
  };

  const handleVersionChange = (value: CertificationVersion, row: ReformattedCertTableRow) => {
    const indexOfSelected = certifications.findIndex(item => item.id === row.certification.id);
    if (needToShowPopUp && indexOfSelected !== -1) {
      return Confirm({
        title: t('applications.cc.warning.title'),
        message: t('applications.cc.warning.message', {
          addition: `${t('applications.cc.warning.additions.labs')}, ${t(
            'applications.cc.warning.additions.capabilities',
          )}`,
        }),
        onAccept: () => {
          changeVersion(value, row, true);
        },
      });
    }
    changeVersion(value, row);
  };

  const getSelectedVersion = useCallback(
    (item: GetCertificationsByCC) => {
      const certVersion = certifications.find(cert => cert.id === item.certification.id)?.version;
      if (certVersion) {
        const selectedVersion = item.versions.find(el => el.version.id === certVersion.id);
        if (selectedVersion) {
          return {
            version: {
              id: selectedVersion.version.id,
              name: selectedVersion.version.name,
              status: selectedVersion.version.status,
              ecn: selectedVersion.version.ecn,
              note: selectedVersion.version.note,
            },
            prerequisites: selectedVersion.prerequisites,
          };
        }
      }

      return jsonCopy(item.versions.find(version => version.previouslySelected) || item.versions[0]);
    },
    [certifications],
  );

  useEffect(() => {
    dispatch(setCertificationsLoader(true));
    getCertificationsByAppId(application.id as number).then(({ data }: AxiosResponse<GetCertificationsByCC[]>) => {
      const tableData = data.map(item => ({
        ...item,
        selectedVersion: getSelectedVersion(item),
      }));
      setCertificationsArray(tableData);
      if (certifications.length) {
        const updated = getFormattedCertifications(data, certifications);
        dispatch(updateCertifications(updated));
        updateChangeDetectionState(updated);
      } else {
        const preQualifiesOrSelected = data
          .filter(({ certification }) => certification.prequalified || certification.previouslySelected)
          .reduce((previous, { certification, versions }) => {
            const item: AppCertification = {
              id: certification.id,
              name: certification.name,
              role: certification.role,
              prequalified: certification.prequalified,
              previouslySelected: certification.previouslySelected,
              capabilities: [],
              version: versions.find(version => version.previouslySelected)?.version || versions[0].version,
            };
            previous.push(item);
            return previous;
          }, [] as AppCertification[]);

        const updated = getFormattedCertifications(data, preQualifiesOrSelected);
        dispatch(updateCertifications(updated));
        updateChangeDetectionState(updated);
      }
    });
  }, []);

  const windowHeight = window.innerHeight - 490;
  const lengthHeight = (filtered.length + 1) * 50;
  // with undefined table will use autoHeight
  const height = lengthHeight < 400 ? undefined : windowHeight < 400 ? 400 : windowHeight;

  const getWarningTitle = (user: User) => {
    if (memberRoles.includes(user?.authority)) {
      return t('certifications.warningTitle.member');
    }
    if (wfaStaffRoles.includes(user?.authority)) {
      return t('certifications.warningTitle.staff');
    }
    if (atlRoles.includes(user?.authority)) {
      return t('certifications.warningTitle.lab');
    }
    return '';
  };

  return (
    <>
      <SubHeader className="mb-1">
        <span>
          {t('applications.certifications.description')}
          <ul>
            <li>
              {t(`applications.certifications.certifiedOptions.optional`)}
              {macPhyCerts.map((cert, index) => (
                <NonWrappingText key={cert} index={index} total={macPhyCerts.length} label={cert} />
              ))}
            </li>
            <li>
              {t(`applications.certifications.certifiedOptions.required`)}
              {requiredCerts.map((cert, index) => (
                <NonWrappingText key={cert} index={index} total={requiredCerts.length} label={cert} />
              ))}
            </li>
          </ul>
        </span>
        <span>
          <InputGroup inside>
            <Input
              placeholder={t('common.placeholders.search')}
              id="search-input"
              value={searchString}
              onChange={value => setSearchString(value as string)}
              onKeyDown={handleKeyDown}
            />
            {searchString || searchStringApplied ? (
              <InputGroup.Button style={{ marginRight: '34px' }} onClick={clearSearchFilter} id="clean-search-filter">
                <Icon icon="close" />
              </InputGroup.Button>
            ) : null}
            <InputGroup.Button onClick={applySearchFilter} id="apply-search-filter">
              <Icon icon="search" />
            </InputGroup.Button>
          </InputGroup>
        </span>
      </SubHeader>
      <PreviouslyCertifiedLegendTitles
        title={t('applications.certifications.prevCertifiedLegend')}
        warningTitle={!canProceedWithSelection ? getWarningTitle(user) : null}
      />
      <RsTable
        virtualized={filtered.length > 15}
        // wordWrap
        rowHeight={56}
        className="mb-2"
        height={height}
        rowKey="idx"
        data={filtered}
        loading={certificationsLoading}
        renderLoading={() =>
          certificationsLoading ? <CircularLoader content={t('common.placeholders.loadingData')} size={20} /> : null
        }
        onSortColumn={handleSortChange}
        sortColumn={sorting.sortColumn}
        rowClassName={rowData => {
          if (rowData?.certification?.prequalified) {
            return 'is-precertified';
          }
          return rowData?.isStriped || rowData?._parent?.isStriped ? 'is-stripe' : '';
        }}
        sortType={sorting.sortType}
      >
        <RsColumn width={40} verticalAlign="middle" align="center">
          <CheckBoxHeaderCell>
            <Whisper
              placement="topStart"
              trigger="hover"
              speaker={<Tooltip>{t('applications.certifications.resetSelectionToolTip')}</Tooltip>}
            >
              <a
                href="#"
                onClick={event => {
                  event.preventDefault();
                  resetCertificationSelection();
                }}
                className={`clear-all ${certifications.length === 0 ? 'is-disabled' : ''}`}
              >
                <Icon icon={customIconsSet.RESET_ALL} size="2x" />
              </a>
            </Whisper>
          </CheckBoxHeaderCell>
          <Cell style={{ padding: '0 0 0 10px' }} />
        </RsColumn>
        <RsColumn verticalAlign="middle" flexGrow={1} minWidth={280} sortable>
          <HeaderCell>{t('certifications.title')}</HeaderCell>
          <Cell style={{ padding: 0 }} dataKey="certification.name">
            {(row: ReformattedCertTableRow) => {
              const prerequisiteOf = getPrerequisiteOf(row);
              return (
                <div style={{ display: 'flex' }}>
                  <CheckboxCell {...getCheckBoxProps(row)} row={row} handleChange={handleCertificationChange}>
                    {row.certification.name}
                  </CheckboxCell>
                  {prerequisiteOf && prerequisiteOf.length > 0 ? (
                    <PrerequisitesInfo prerequisites={prerequisiteOf} />
                  ) : null}
                </div>
              );
            }}
          </Cell>
        </RsColumn>
        <RsColumn verticalAlign="middle" flexGrow={1} sortable>
          <HeaderCell>{t('applications.certifications.role')}</HeaderCell>
          <Cell dataKey="certification.role.name">{(row: ReformattedCertTableRow) => row.certification.role.name}</Cell>
        </RsColumn>
        <RsColumn verticalAlign="middle" width={45}>
          <HeaderCell />
          <Cell dataKey="certification.versionStatus">
            {(row: ReformattedCertTableRow) => {
              const cert = certifications.find(item => item.id === row.certification.id);
              const version = cert?.version || row.selectedVersion.version;
              return (
                <>
                  {version.status &&
                  (version.status === CertificationStatuses.FIRST_RETIREMENT_PHASE ||
                    version.status === CertificationStatuses.SECOND_RETIREMENT_PHASE ||
                    version.status === CertificationStatuses.THIRD_RETIREMENT_PHASE) ? (
                    <WarningTooltip onHoverTooltipTextWarning status={version.status} variant="rounded" />
                  ) : null}
                </>
              );
            }}
          </Cell>
        </RsColumn>
        <RsColumn verticalAlign="middle" width={220}>
          <HeaderCell>{t('applications.certifications.version')}</HeaderCell>
          <Cell dataKey="versions">
            {(row: ReformattedCertTableRow) => {
              const cert = certifications.find(item => item.id === row.certification.id);
              const version = cert?.version || row.selectedVersion.version;
              return row.versions.length > 1 ? (
                <SelectPicker
                  name="role-picker"
                  block
                  cleanable={false}
                  searchable={false}
                  data={row.versions.map(({ version, prerequisites }) => ({
                    label: version.name,
                    value: version.id,
                    status: version.status,
                    ecn: version.ecn,
                    note: version.note,
                    prerequisites,
                  }))}
                  onSelect={(value: string, version) =>
                    handleVersionChange(
                      {
                        // @ts-ignore
                        version: {
                          id: version.value,
                          name: version.label,
                          status: version.status,
                          ecn: version.ecn,
                          note: version.note,
                        },
                        // @ts-ignore
                        prerequisites: version.prerequisites,
                      },
                      row,
                    )
                  }
                  disabledItemValues={
                    cert?.allowedVersions
                      ? row.versions
                          .filter(el => !cert?.allowedVersions?.some(item => item.id === el.version.id))
                          .map(el => el.version.id)
                      : []
                  }
                  value={version.id}
                  style={{ width: 200 }}
                />
              ) : (
                row.versions[0].version.name
              );
            }}
          </Cell>
        </RsColumn>
        <RsColumn verticalAlign="middle" width={150}>
          <HeaderCell>
            {t('applications.certifications.versionECN')}
            <a href={ECNUrl} rel="noreferrer" target="_blank">
              <OpenInNewIcon
                style={{ color: _var.primary, fontSize: '17px', marginLeft: '5px', verticalAlign: 'text-top' }}
              />
            </a>
          </HeaderCell>
          <Cell dataKey="versionECN">
            {(row: ReformattedCertTableRow) => {
              const hasNote = row.selectedVersion.version.note !== '';
              return hasNote ? (
                <Whisper
                  placement="topStart"
                  trigger="hover"
                  speaker={
                    <Popover style={{ maxWidth: '550px' }}>
                      <span className="text-muted">{'Notes'}</span>
                      <div className="note-body" style={{ fontWeight: 'bold' }}>
                        {row.selectedVersion.version.note}
                      </div>
                    </Popover>
                  }
                >
                  <div style={{ fontWeight: 'bold' }}>{row.selectedVersion.version.ecn}</div>
                </Whisper>
              ) : (
                <div>{row.selectedVersion.version.ecn}</div>
              );
            }}
          </Cell>
        </RsColumn>
      </RsTable>
    </>
  );
};
