import React from 'react';
import { Criteria } from '../types';
import { Row, Col } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { ComparisonOperatorTitles, numberComparisonOperators } from '../../../../helpers/constants';
import { ErrorObject } from '../../../../helpers/types';
import { LabeledSelect, LabeledInput } from '../../../../shared-components/labeled-inputs';

export const MeasurementComparison = ({
  criteria,
  handleChange,
  testIndex,
  testGroupIndex,
  measurementIndex,
  formErrors,
}: {
  criteria: Criteria;
  handleChange: (value: string, name: string) => void;
  testIndex: number;
  testGroupIndex: number;
  measurementIndex: number;
  formErrors: ErrorObject;
}) => {
  const { t } = useTranslation();
  const { measurement } = criteria;

  const measurementBaseKey = `testGroups[${testGroupIndex}].testCriteria[${testIndex}].criteria[${measurementIndex}]`;

  const checkOperandsOnSelect = (value: string) => {
    if (value !== 'BETWEEN') {
      handleChange('', `${measurementBaseKey}.operand2`);
    }
    return handleChange(value, `${measurementBaseKey}.compareOperation`);
  };

  if (measurement.type === 'BOOLEAN') {
    return (
      <>
        <input type="hidden" name={`${measurementBaseKey}.compareOperation`} value={criteria.compareOperation} />
        <LabeledSelect
          noMargin
          name={`${measurementBaseKey}.operand1`}
          value={criteria.operand1}
          onSelect={(value: string) => handleChange(value, `${measurementBaseKey}.operand1`)}
          data={[
            { value: 'any', label: 'Any' },
            { value: 'true', label: 'True' },
            { value: 'false', label: 'False' },
          ]}
          errors={formErrors}
          block
          cleanable={false}
          searchable={false}
          placeholder={t('common.placeholders.select')}
        />
      </>
    );
  }

  if (measurement.type === 'STRING') {
    return (
      <>
        <LabeledInput
          noMargin
          placeholder="Regex"
          name={`${measurementBaseKey}.operand1`}
          value={criteria.operand1 as string}
          errors={formErrors}
          onChange={(value: string) => handleChange(value, `${measurementBaseKey}.operand1`)}
        />
        <input type="hidden" name={`${measurementBaseKey}.compareOperation`} value={criteria.compareOperation} />
      </>
    );
  }

  if (measurement.type === 'INTEGER' || measurement.type === 'DOUBLE') {
    let comparison = (
      <LabeledInput
        noMargin
        type="number"
        name={`${measurementBaseKey}.operand1`}
        value={criteria.operand1 as number}
        errors={formErrors}
        onChange={(value: string) => handleChange(value, `${measurementBaseKey}.operand1`)}
      />
    );

    if (criteria.compareOperation === 'BETWEEN') {
      comparison = (
        <Row>
          <Col xs={12}>
            <LabeledInput
              noMargin
              type="number"
              placeholder={t('certifications.measurements.from')}
              name={`${measurementBaseKey}.operand1`}
              value={criteria.operand1 as number}
              errors={formErrors}
              onChange={(value: string) => handleChange(value, `${measurementBaseKey}.operand1`)}
            />
          </Col>
          <Col xs={12}>
            <LabeledInput
              noMargin
              type="number"
              placeholder={t('certifications.measurements.to')}
              name={`${measurementBaseKey}.operand2`}
              value={criteria.operand2 as number}
              errors={formErrors}
              onChange={(value: string) => handleChange(value, `${measurementBaseKey}.operand2`)}
            />
          </Col>
        </Row>
      );
    }

    return (
      <>
        <LabeledSelect
          noMargin
          name={`${measurementBaseKey}.compareOperation`}
          value={criteria.compareOperation}
          onSelect={checkOperandsOnSelect}
          data={Object.values(numberComparisonOperators).map(operator => ({
            value: operator,
            label: ComparisonOperatorTitles[operator],
          }))}
          errors={formErrors}
          block
          cleanable={false}
          searchable={false}
          placeholder={t('common.placeholders.select')}
        />
        {criteria.compareOperation ? comparison : null}
      </>
    );
  }

  return <span>{t('certifications.measurements.typeIsNotSupported')}</span>;
};
