import { ReactNode } from 'react';
import { AxiosResponse } from 'axios';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { downloadApplicationProfile } from '../download-application-profile';
import { BundleResponse } from '../../../components/pages/certification/types';
import { downloadStringAsFile } from '../../../helpers/download-file-from-string';

export const downloadApplicationProfileHandler = (
  labRequestId: number,
  applicationId: number,
  addToast: (content: ReactNode, options?: Options) => void,
) =>
  downloadApplicationProfile(labRequestId)
    .then((response: AxiosResponse<BundleResponse>) =>
      downloadStringAsFile(
        `application_profile_${labRequestId}_${Date.now()}_ID_${applicationId}.json`,
        JSON.stringify(response.data),
        'data:text/json',
      ),
    )
    .catch(error => handleRequestFail(error, addToast));
