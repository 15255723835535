import { IterableObject } from '../types';
import { CertificationStatuses } from './certification-statuses';
// @ts-ignore
import _var from '../../styles/_variables.scss';

export const getLifeCycleStatusKey = (status: CertificationStatuses): string => {
  switch (status) {
    case CertificationStatuses.DRAFT:
      return 'draft';
    case CertificationStatuses.PUBLISHED:
      return 'published';
    case CertificationStatuses.FIRST_RETIREMENT_PHASE:
      return 'retirement1';
    case CertificationStatuses.SECOND_RETIREMENT_PHASE:
      return 'retirement2';
    case CertificationStatuses.THIRD_RETIREMENT_PHASE:
      return 'retirement3';
    default:
      return '';
  }
};

export const getRetirementStatus = (status: CertificationStatuses): string | null => {
  switch (status) {
    case CertificationStatuses.FIRST_RETIREMENT_PHASE:
      return status;
    case CertificationStatuses.SECOND_RETIREMENT_PHASE:
      return status;
    case CertificationStatuses.THIRD_RETIREMENT_PHASE:
      return status;
    default:
      return null;
  }
};

export const statusColors: IterableObject<{ color: string; background: string }> = {
  draft: {
    color: _var.statusDraftText,
    background: _var.statusDraft,
  },
  published: {
    color: _var.statusPublishedText,
    background: _var.statusPublished,
  },
  retirement1: {
    color: _var.statusRetierementPhase1Text,
    background: _var.statusRetierementPhase1,
  },
  retirement2: {
    color: _var.statusRetierementPhase2Text,
    background: _var.statusRetierementPhase2,
  },
  retirement3: {
    color: _var.statusRetierementPhase3Text,
    background: _var.statusRetierementPhase3,
  },
};

// Returns boolean - check 'retirement' status
export const isRetirementStatus = (statusKey: string) => {
  if (!statusKey) return false;
  const retirement = 'retirement';
  const checkedCondition = statusKey
    ?.substring(0, 10)
    .toLowerCase()
    .includes(retirement);
  if (checkedCondition) return true;
  return false;
};
