import React from 'react';
import { useTranslation } from 'react-i18next';
import { getProductHistory } from '../../../api/product';
import { getEventHistoryPageComponent } from '../../hoc/get-event-history-page-component';

const ProductHistoryPage = () => {
  const { t } = useTranslation();

  const ProductHistoryPage = getEventHistoryPageComponent(t('products.history.title'), getProductHistory);

  return <ProductHistoryPage />;
};

export default ProductHistoryPage;
