import React from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { LabeledCheckbox } from '../../../../../../../shared-components/labeled-inputs';
import { ReformattedCapabilities } from './CapabilityRow';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { AppCapability, ApplicationFlowState } from '../../../../../../../redux/modules/application-flow';
import { isCapabilityNeedValidate, isCapabilityValidateError } from './helpers';
// @ts-ignore
import _scss from '../../../../../../../styles/_variables.scss';

const InstructionMessage = ({
  message,
  isError,
  additionClassNames,
}: {
  message: string | null;
  isError: boolean;
  additionClassNames?: string[] | null;
}) => {
  return (
    <span
      style={{ verticalAlign: 'baseline' }}
      className={classNames(isError ? 'text-danger' : 'text-muted', ...(additionClassNames ?? []))}
    >
      ({message})
    </span>
  );
};

export const CapabilityCheckbox = ({
  capabilityInfo,
  depth = 0,
  primaryVersionId,
  onChange,
  disabled,
  isParentSingleSelection = false,
  isParentErrorInstruction = false,
}: {
  capabilityInfo: ReformattedCapabilities;
  depth?: number;
  primaryVersionId: number;
  onChange: (capability: AppCapability, checked: boolean, needToShowUncheckPrecertified: boolean) => void;
  disabled?: boolean;
  isParentSingleSelection?: boolean;
  isParentErrorInstruction?: boolean;
}) => {
  const { certifications, autoValidateCapability } = useSelector<RootReducer, ApplicationFlowState>(
    state => state.applicationFlow,
  );
  const capabilities = certifications.find(cert => cert.version.id === primaryVersionId)?.capabilities;
  const capability = capabilities?.find(capability => capability.id === capabilityInfo.id);

  const getCheckboxProps = () => {
    const isPreReq = certifications.some(el =>
      el.capabilities.some(cap => cap.id === capabilityInfo.id && cap.isPreReq),
    );
    return {
      disabled: (disabled && !Boolean(capability)) || isPreReq || capability?.mandatory,
      checked: Boolean(capability),
    };
  };

  const checkboxProps = getCheckboxProps();

  const { t } = useTranslation();

  const getInstructionMessage = (currentCapability: ReformattedCapabilities) => {
    const { minChildren, maxChildren } = currentCapability;
    if (minChildren === 0 && maxChildren === -1) {
      return null;
    } else if (minChildren > 0 && maxChildren === -1) {
      return t('applications.capabilities.instructions.onlyMinChildren', { minChildren });
    } else if (minChildren === 0 && maxChildren >= 0) {
      return t('applications.capabilities.instructions.onlyMaxChildren', { maxChildren });
    } else if (minChildren === maxChildren) {
      return t('applications.capabilities.instructions.selectFixedChildren', { minChildren });
    } else {
      // minChildren !== maxChildren
      return t('applications.capabilities.instructions.selectRangeChildren', { minChildren, maxChildren });
    }
  };

  // is need show instruction
  const isShowInstruction: boolean = checkboxProps.checked && isCapabilityNeedValidate(capabilityInfo);
  // is single select => children use radio button
  const isSingleSelection: boolean =
    isShowInstruction && capabilityInfo.minChildren === 1 && capabilityInfo.maxChildren === 1;
  // is error
  const isErrorInstruction: boolean =
    checkboxProps.checked &&
    isShowInstruction &&
    autoValidateCapability &&
    isCapabilityValidateError(capabilities as AppCapability[], capabilityInfo);
  // instruction message
  const instructionMessage = isShowInstruction ? getInstructionMessage(capabilityInfo) : null;

  const checkBoxMarginLeft = capabilityInfo.displayParent !== null ? `${2.2 + 1.3 * depth}rem` : '2.2rem';

  const checkPreCertifiedBeforeChange = (appCapability: AppCapability, checked: boolean) => {
    onChange(appCapability, checked, capability?.prequalified === true && checked === false);
  };

  return (
    <>
      {isParentSingleSelection ? (
        <div className="mb-1">
          <FormControlLabel
            control={
              <Radio
                size="small"
                key={capabilityInfo.name}
                name={capabilityInfo.name}
                {...checkboxProps}
                style={{ marginLeft: checkBoxMarginLeft }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  checkPreCertifiedBeforeChange(
                    {
                      id: capabilityInfo.id,
                      name: capabilityInfo.name,
                      mandatory: capabilityInfo.mandatory,
                      prerequisites: capabilityInfo.prerequisites,
                      displayParent: capabilityInfo.displayParent,
                      index: capabilityInfo.index,
                    },
                    event.target.checked,
                  );
                }}
              />
            }
            label={capabilityInfo.name}
          />
          {isShowInstruction && <InstructionMessage message={instructionMessage} isError={isErrorInstruction} />}
        </div>
      ) : (
        <LabeledCheckbox
          noPadding
          className="my-1"
          key={capabilityInfo.name}
          name={capabilityInfo.name}
          {...checkboxProps}
          style={{ marginLeft: checkBoxMarginLeft }}
          onChange={(value: string, checked: boolean) => {
            checkPreCertifiedBeforeChange(
              {
                id: capabilityInfo.id,
                name: capabilityInfo.name,
                mandatory: capabilityInfo.mandatory,
                prerequisites: capabilityInfo.prerequisites,
                displayParent: capabilityInfo.displayParent,
                index: capabilityInfo.index,
              },
              checked,
            );
          }}
        >
          {capabilityInfo.name}
          {isShowInstruction && (
            <InstructionMessage
              message={instructionMessage}
              isError={isErrorInstruction}
              additionClassNames={['ml-1']}
            />
          )}
        </LabeledCheckbox>
      )}
      <div
        style={
          !isParentErrorInstruction && isErrorInstruction
            ? { borderLeftStyle: 'solid', borderLeftWidth: 2, borderLeftColor: `${_scss.errorRed}` }
            : { borderLeftStyle: 'solid', borderLeftWidth: 2, borderLeftColor: 'transparent' }
        }
      >
        {checkboxProps.checked && capabilityInfo.children
          ? capabilityInfo.children.map(el => (
              <CapabilityCheckbox
                key={uniqid()}
                capabilityInfo={el}
                depth={depth + 1}
                primaryVersionId={primaryVersionId}
                onChange={onChange}
                isParentSingleSelection={isSingleSelection}
                isParentErrorInstruction={isErrorInstruction}
              />
            ))
          : null}
      </div>
    </>
  );
};
