import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { Icon, IconButton } from 'rsuite';
import { customIconsSet } from '../../helpers/constants';

const StyledIconButton = styled(IconButton)`
  &.rs-btn.rs-btn-icon > .rs-icon {
    padding: 4px 0;
  }

  &.rs-btn.rs-btn-icon > .rs-icon > svg {
    height: 28px;
  }

  &.rs-btn.rs-btn-loading.rs-btn-icon > .rs-icon > svg {
    opacity: 0.2;
  }
`;

interface DownloadBundleButtonProps {
  className?: string;
  loading?: boolean;
  onClick: () => void;
}

export const DownloadBundleButton = ({ loading = false, onClick, className }: DownloadBundleButtonProps) => (
  <StyledIconButton
    loading={loading}
    appearance="subtle"
    className={classNames('pointer download-bundle', className)}
    icon={<Icon icon={customIconsSet.DOWNLOAD_ICON} />}
    onClick={onClick}
  />
);
