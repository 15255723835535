import { call, put, takeLatest, all } from 'redux-saga/effects';

import { LOAD_ROLES, rolesLoaded } from './';
import { appFinishLoading } from '../app';
import { getRoles } from '../../../api/roles';
import { Role } from './types';

export function* getRolesSaga() {
  try {
    const response = yield call(getRoles);
    yield put(rolesLoaded(response.data.sort((current: Role, next: Role) => current.displayOrder - next.displayOrder)));
    yield put(appFinishLoading());
  } catch (e) {
    yield put(appFinishLoading());
  }
}

function* watchGetRolesSaga() {
  yield takeLatest(LOAD_ROLES, getRolesSaga);
}

export default function* watchMultipleRolesSagas() {
  yield all([watchGetRolesSaga()]);
}
