import React from 'react';
import { Typography, Button, DialogProps, CircularProgress } from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { DropzoneArea, DropzoneAreaProps } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from '../confirm-dialog/ConfirmDialog';
import { useStyles } from './styles';

export type FileDetails = {
  name: string;
  size: string | number;
};

interface UploadFileDialogProps {
  title?: string;
  subtitle?: string;
  handleClose: () => void;
  handleConfirm?: (file?: File | null) => void;
  fileDetails?: File | null;
  onRemoveFile?: (file?: File) => void;
  disabledConfrimButton?: boolean;
  loading?: boolean;
  errorMessage?: string | null;
}

export const UploadFileDialog: React.FC<DialogProps & UploadFileDialogProps & DropzoneAreaProps> = ({
  open,
  title,
  handleClose,
  handleConfirm,
  fileDetails,
  onChange,
  onRemoveFile,
  disabledConfrimButton,
  subtitle,
  maxWidth,
  fullWidth,
  loading,
  errorMessage,
  filesLimit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      open={open}
      handleClose={handleClose}
      title={title ? title : t('file.uploadDialog.title')}
      subtitle={subtitle ? subtitle : t('file.uploadDialog.subtitle')}
      maxWidth={maxWidth ?? 'sm'}
      fullWidth={fullWidth}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      confirmButton={
        <Button
          startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <CloudUploadOutlinedIcon />}
          color="primary"
          variant="contained"
          onClick={() => handleConfirm && handleConfirm(fileDetails)}
          disabled={disabledConfrimButton || !!errorMessage}
        >
          {t('file.upload')}
        </Button>
      }
    >
      <div className={classes.root}>
        {loading ? (
          <div className={classes.loader}>
            <CircularProgress size={26} />
            <span className={classes.loadingTitle}>{t('common.placeholders.loadingData')}</span>
          </div>
        ) : null}
        <DropzoneArea
          // @ts-ignore
          Icon={CloudUploadOutlinedIcon}
          onChange={onChange}
          filesLimit={filesLimit}
          showPreviewsInDropzone={false}
          showPreviews={false}
          showAlerts={false}
          dropzoneText={t('file.uploadDialog.dropzoneText')}
          onDrop={onChange}
        />
        <div className={classes.wrapper}>
          <InsertDriveFileOutlinedIcon />
          <div className={classes.content}>
            {fileDetails ? (
              <>
                <div className={classes.fileName}>
                  <Typography component="p">{fileDetails.name}</Typography>
                  <Typography component="p">
                    {errorMessage ? <span className={classes.error}>{errorMessage}</span> : null}
                  </Typography>
                </div>
                <div>
                  <Typography component="span">
                    {fileDetails.size} {t('file.uploadDialog.content.fileSize')}
                  </Typography>
                  <Button
                    onClick={() => onRemoveFile && onRemoveFile(fileDetails)}
                    disableElevation
                    disableFocusRipple
                    disableRipple
                  >
                    <CloseIcon />
                  </Button>
                </div>
              </>
            ) : (
              <div className={classes.notFoundWrapper}>
                <Typography component="span">{t('file.uploadDialog.content.notFound')}</Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </ConfirmDialog>
  );
};
