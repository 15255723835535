import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dot from 'dot-object';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

import { CollapsableSection } from '../../../../../certification/partials/CollapsableSection';
import { CollapsedSectionProps } from '../../../../../certification/types';
import { confirmAction } from '../../../../../../../helpers';
import { saveProductInfo } from '../../../../../../../api/application/partial-update/save-product-info';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { IsModifiedProps } from '../../../../../../../helpers/hooks/useChangeDetection';
import {
  AppFlows,
  ApplicationFlowState,
  setReviewSectionEditState,
  updateApplicationProperty,
} from '../../../../../../../redux/modules/application-flow';
import { ProductCategories, ProductInfoStep } from '../../../../../../../api/application/types';
import { UploadedFile } from '../../../../../../../shared-components/file-upload/types';
import { jsonCopy, mergeObjects, prepareYupModel } from '../../../../../../../helpers';
import { ErrorObject } from '../../../../../../../helpers/types';
import { productScheme } from '../../../../data/validation-schemes';
import { handleRequestFail } from '../../../../../../../helpers/request-fail-handler';
import { Confirm } from '../../../../../../../helpers/confirmationPopup/Confirm';

import { ViewProductSection } from './ViewProductSection';
import { EditProductSection } from './EditProductSection';
import { SectionEditButton } from './SectionEditButton';

type ProductSectionProps = CollapsedSectionProps & Partial<IsModifiedProps>;

export const ProductSection = forwardRef<HTMLDivElement, ProductSectionProps>(function ProductSection(
  { setIsModified: setIsSectionModified }: ProductSectionProps,
  ref,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { reviewSectionEditState, productInfo, formId, ...application } = useSelector<
    RootReducer,
    ApplicationFlowState
  >(state => state.applicationFlow);

  const [state, setState] = useState({ productInfo });
  const [errorsState, setErrorsState] = useState<ErrorObject>({});
  const [isModified, setIsModified] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const [modelAttachmentLoading, setModelAttachmentLoading] = useState(false);

  const isVariant = application['@type'] === AppFlows.VARIANT;

  const setIsModifiedState = (value: boolean) => {
    setIsModified(value);
    setIsSectionModified && setIsSectionModified(value);
  };

  const validationSchema = Yup.object().shape({
    productInfo: productScheme(),
  });

  const resetForm = () => {
    setIsModifiedState(false);
    setState({ productInfo });
    setErrorsState({});
    dispatch(setReviewSectionEditState({ productInfo: false }));
  };

  const onCancel = () => confirmAction(() => isModified, resetForm, 'common.unsavedDataWarning');

  const onCollapse = async () => {
    if (isModified) {
      return await Confirm({
        title: t('common.placeholders.areYouSure'),
        message: t('common.unsavedDataWarning'),
        onAccept: resetForm,
      });
    } else {
      resetForm();
    }
  };

  const changeData = (
    value: string | ProductCategories | ProductCategories[] | UploadedFile | UploadedFile[] | boolean | null,
    name: string,
  ) => {
    setState(prevState => {
      const newState = jsonCopy(prevState);
      dot.set(name, value, newState);
      const errors = prepareYupModel(validationSchema).checkForFieldFormatted(name, newState);
      if (errors) {
        setErrorsState(prevErrors => mergeObjects(prevErrors, { ...errors }));
      }
      return newState;
    });
  };

  const handleChange = (
    value: string | ProductCategories | ProductCategories[] | UploadedFile | UploadedFile[] | boolean | null,
    name: string,
  ) => {
    changeData(value, name);
  };

  const catchPromiseError = (error: AxiosError) => {
    handleRequestFail(error, addToast);
    setSaveState(false);
    throw error;
  };

  const onSave = () => {
    const hasError = Object.keys(errorsState).some(key => errorsState[key] !== '');
    if (!hasError) {
      setSaveState(true);
      const copyInfo = {
        qualifiedSolution: state.productInfo.qualifiedSolution,
        certified: state.productInfo.certified,
        name: state.productInfo.name,
        modelNumber: state.productInfo.modelNumber,
        primaryCategory: state.productInfo.primaryCategory,
        additionalCategories: state.productInfo.additionalCategories,
        url: state.productInfo.url,
        description: state.productInfo.description,
        image: state.productInfo.image,
      };
      const mergeInfo = mergeObjects(productInfo, copyInfo);
      // do re-correct data
      if (state.productInfo.certified === false) {
        mergeInfo.modelVariants[0].availableAsDerivative = false;
        mergeInfo.modelVariants[0].searchableByPublic = false;
      }
      if (state.productInfo.qualifiedSolution === false) {
        mergeInfo.modelVariants[0].availableAsQualifiedSolution = false;
      }
      saveProductInfo(Number(formId), mergeInfo)
        .then(({ data }: AxiosResponse<ProductInfoStep>) => {
          dispatch(setReviewSectionEditState({ productInfo: false }));
          dispatch(updateApplicationProperty(data, 'productInfo'));
          setIsModifiedState(false);
          setSaveState(false);

          addToast(t('applications.notifications.saved'), {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        })
        .catch(catchPromiseError);
    }
  };

  return (
    <CollapsableSection
      title={t('applications.review.section.product.title')}
      showEditIcon={isVariant ? false : true}
      isEdit={reviewSectionEditState.productInfo}
      onSave={onSave}
      isSaving={saveState || modelAttachmentLoading}
      disabledBtn={!isModified}
      onCancel={onCancel}
      ref={ref}
      onEditIconClick={() => dispatch(setReviewSectionEditState({ productInfo: true }))}
      hideDivider={true}
      editIcon={<SectionEditButton />}
      onCollapse={onCollapse}
      hideEditIconWhenCollapse={true}
    >
      {!reviewSectionEditState.productInfo ? (
        <ViewProductSection data={productInfo} />
      ) : (
        <EditProductSection
          data={state.productInfo}
          onChange={handleChange}
          setLoading={setModelAttachmentLoading}
          setIsModified={setIsModifiedState}
          errors={errorsState}
        />
      )}
    </CollapsableSection>
  );
});
