import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';

// @ts-ignore
import _var from '../../styles/_variables.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      zIndex: 15,
    },
    fullPageWrapper: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      zIndex: 14,
    },
    contentWrapper: {
      marginLeft: theme.spacing(1.5),
    },
    backdrop: {
      backgroundColor: _var.white,
      opacity: 0.7,
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 14,
    },
  }),
);

interface CircularLoaderProps {
  content?: string | React.ReactNode;
  fullPage?: boolean;
  backdrop?: boolean;
}

export const CircularLoader: React.FC<CircularProgressProps & CircularLoaderProps> = ({
  color,
  disableShrink,
  size,
  thickness,
  value,
  variant,
  content,
  fullPage,
  backdrop,
}) => {
  const classes = useStyles();

  const renderLoader = () => (
    <>
      {backdrop ? <div className={classes.backdrop} /> : null}
      <div className={classes.root}>
        <CircularProgress
          color={color ? color : 'primary'}
          disableShrink={disableShrink}
          size={size ? size : 20}
          thickness={thickness}
          value={value}
          variant={variant}
        />
        {content ? <div className={classes.contentWrapper}>{content}</div> : null}
      </div>
    </>
  );

  return fullPage ? <div className={classes.fullPageWrapper}>{renderLoader()}</div> : renderLoader();
};
