export enum LabSubmissionStatus {
  COMPLIANT = 'COMPLIANT',
  LEVEL_1_RECOVERY = 'LEVEL_1_RECOVERY',
  LEVEL_2_RECOVERY = 'LEVEL_2_RECOVERY',
  LEVEL_3_RECOVERY = 'LEVEL_3_RECOVERY',
  DEFECT_PLAN = 'DEFECT_PLAN',
}

export const labSubmissionStatusTitles = {
  [LabSubmissionStatus.COMPLIANT]: 'Compliant',
  [LabSubmissionStatus.LEVEL_1_RECOVERY]: 'Level 1 Recovery',
  [LabSubmissionStatus.LEVEL_2_RECOVERY]: 'Level 2 Recovery',
  [LabSubmissionStatus.LEVEL_3_RECOVERY]: 'Level 3 Recovery',
  [LabSubmissionStatus.DEFECT_PLAN]: '0 Defect Plan',
};
