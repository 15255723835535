import {
  Certification,
  ConditionOperator,
  GenericNodeType,
  GroupNodeType,
  NodeTypes,
  Path,
  SingleNodeType,
} from './types';
import uniqid from 'uniqid';
import dot from 'dot-object';
import { jsonCopy } from '../../helpers';

export const createGroup = (elements: Partial<GenericNodeType>[] = []): Partial<GroupNodeType> => ({
  '@type': NodeTypes.GROUP,
  operator: ConditionOperator.AND,
  negation: false,
  elements: elements as GenericNodeType[],
});

export const createSingle = (certification: Certification): Partial<SingleNodeType> => ({
  '@type': NodeTypes.ELEMENT,
  certification,
  negation: false,
});

export const pathToString = (path: Path): string => path.join('.');

export const stringToPath = (path: string): Path => path.split('.').map(v => Number(v));

export const pathToDotString = (path: Path): string => 'elements[' + path.join('].elements[') + ']';

export const reindexTree = (element: Partial<GenericNodeType>, parentPath?: Path): GenericNodeType => {
  element.type = element['@type'];

  if (parentPath === undefined) {
    element.path = [];
  }

  if (element.uid === undefined) {
    element.uid = uniqid();
  }

  if (element.type === NodeTypes.GROUP) {
    const list = (element as GroupNodeType).elements;

    for (let index = 0; index < list.length; index++) {
      list[index].path = [...(parentPath ?? []), index];
      reindexTree(list[index], list[index].path);
    }
  }

  return element as GenericNodeType;
};

export const isHigherInTree = (path1: Path, path2: Path): boolean => {
  const lastIndex = Math.min(path1.length, path2.length);
  for (let i = 0; i < lastIndex; i++) {
    if (path1[i] === path2[i]) {
      continue;
    }
    return path1[i] < path2[i];
  }

  return false;
};

export const getParentPath = (path: Path): [Path, number | undefined] => {
  const parentPath = [...path];
  const index = parentPath.pop();
  return [parentPath, index];
};

const getElement = (path: Path, root: GenericNodeType) => (path.length ? dot.pick(pathToDotString(path), root) : root);

export const moveElement = (source: Path, target: Path, data: GenericNodeType): GenericNodeType => {
  const root = jsonCopy(data);

  const moveDown = isHigherInTree(source, target);
  const [sourceParentPath, sourceIndex] = getParentPath(source);
  const [targetParentPath, targetIndex] = getParentPath(target);

  const targetParentElement = getElement(targetParentPath, root);

  if (pathToString(sourceParentPath) === pathToString(targetParentPath)) {
    // moving the element inside the same branch

    const sourceElement = targetParentElement.elements[sourceIndex as number];
    const startIndex = (targetIndex as number) - (moveDown ? 1 : 0);
    targetParentElement.elements.splice(sourceIndex, 1);
    targetParentElement.elements.splice(startIndex, 0, sourceElement);

    if (targetParentPath.length) {
      dot.set(pathToDotString(targetParentPath), targetParentElement, root);
    }
  } else {
    // moving the element to the different branch

    const sourceElement = dot.pick(pathToDotString(source), root);

    if (!moveDown) {
      dot.delete(pathToDotString(source), root);
    }

    targetParentElement.elements.splice(targetIndex, 0, sourceElement);

    if (targetParentPath.length) {
      dot.set(pathToDotString(targetParentPath), targetParentElement, root);
    }

    if (moveDown) {
      dot.delete(pathToDotString(source), root);
    }
  }

  return reindexTree(root);
};

export const removeNode = (path: Path, data: GenericNodeType): GenericNodeType => {
  const root = jsonCopy(data);

  dot.delete(pathToDotString(path), root);

  return reindexTree(root);
};

export const addGroupNode = (path: Path, data: GenericNodeType): GenericNodeType => {
  const root = jsonCopy(data);

  const targetElement = getElement(path, root);
  targetElement.elements.push(createGroup());

  if (path.length) {
    dot.set(pathToDotString(path), targetElement, root);
  }

  return reindexTree(root);
};

export const addSingleNode = (path: Path, cert: Certification, data: GenericNodeType): GenericNodeType => {
  const root = jsonCopy(data);

  const targetElement = getElement(path, root);
  targetElement.elements.push(createSingle(cert));

  if (path.length) {
    dot.set(pathToDotString(path), targetElement, root);
  }

  return reindexTree(root);
};

export const setOperatorValue = (path: Path, value: ConditionOperator, data: GenericNodeType): GenericNodeType => {
  const root = jsonCopy(data);

  const targetPath = `${path.length ? pathToDotString(path) + '.' : ''}operator`;

  dot.set(targetPath, value, root);

  return reindexTree(root);
};

export const setNegationValue = (path: Path, value: boolean, data: GenericNodeType): GenericNodeType => {
  const root = jsonCopy(data);

  const targetPath = `${path.length ? pathToDotString(path) + '.' : ''}negation`;

  dot.set(targetPath, value, root);

  return reindexTree(root);
};

export const getCertificationIds = (element: GroupNodeType): number[] =>
  element.elements
    .filter(item => item.type === NodeTypes.ELEMENT)
    .map(item => (item as SingleNodeType).certification.id);

export const isAllowedToMove = (sourcePath: Path, targetPath: Path, root: GenericNodeType): boolean => {
  // not allowed to move the node into nested elements
  if (pathToString(targetPath).startsWith(pathToString(sourcePath))) {
    return false;
  }

  const [sourceParent, sourceIndex] = getParentPath(sourcePath);
  const [targetParent, targetIndex] = getParentPath(targetPath);

  // not allowed to move the node to the same position
  if (pathToString(sourceParent) === pathToString(targetParent) && targetIndex === (sourceIndex as number) + 1) {
    return false;
  }

  // not allowed to move the node to the node with the same certification
  const sourceElement = getElement(sourcePath, root);
  if (sourceElement.type === NodeTypes.ELEMENT && pathToString(sourceParent) !== pathToString(targetParent)) {
    const targetElement = getElement(targetParent, root) as GroupNodeType;

    if (getCertificationIds(targetElement).includes((sourceElement as SingleNodeType).certification.id)) {
      return false;
    }
  }

  return true;
};
