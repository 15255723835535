import { CertificationStatuses } from '../../../helpers/constants/certification-statuses';
import { TestResult } from '../../../redux/modules/testing-flow/types';
import { PrerequisiteOf } from '../atl-testing/types';
import { LabInfo } from '../lab-info/types';

export interface LabColumnData {
  item: { id: number; name: string };
  header: boolean;
  lrId: number;
}

export interface LabTestResult {
  conformance: TestResult | null;
  interoperability: TestResult | null;
}

export interface LabResultsRow {
  id: number;
  name: string;
  roleName: string;
  versionName?: string;
  versionStatus?: CertificationStatuses;
  prequalified?: boolean;
  prerequisiteOf: PrerequisiteOf[];
  results?: { [x: string]: LabTestResult };
  children?: LabResultsRow[];
}

export enum StaffSummaryStatuses {
  DRAFT = 'Draft',
  IN_PROGRESS = 'In Progress',
  PENDING_APPROVAL = 'Pending Approval',
  QUALIFIED = 'Qualified',
  CERTIFIED = 'Certified',
  REVOKED = 'Revoked',
}

// eslint-disable-next-line
export interface AccountInfo extends LabInfo {}

export const getAccountSubTypeFullName = (subType: string) => {
  switch (subType) {
    case 'SPONSOR':
      return 'account.subTypeName.sponsor';
    case 'CONTRIBUTOR':
      return 'account.subTypeName.contributor';
    case 'AFFILIATE':
      return 'account.subTypeName.affiliate';
    case 'SBIP':
      return 'account.subTypeName.sbip';
    case 'IMPLEMENTER':
      return 'account.subTypeName.implementer';
    case 'SBII':
      return 'account.subTypeName.sbii';
    default:
      return subType;
  }
};
