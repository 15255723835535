export interface GetFeeResponse {
  '@type': FeeTypes;
  fees: Fee[];
  application?: ApplicationFeeRecord;
  opportunity?: OpportunityFeeRecord;
}

export interface ApplicationFeeRecord {
  cid: string;
  applicationId: number;
  applicationType: string;
  productName: string;
  variantName: string;
  approved: string;
  owner: ApplicationOwner;
  po?: string;
  ctia?: string;
}

export interface OpportunityFeeRecord {
  id: string;
  name: string;
  created: string;
  eventId: number;
  owner: ApplicationOwner;
}

export interface ApplicationOwner {
  id: number;
  name: string;
}

export interface Fee {
  feeType: string;
  priceBookEntry: {
    id: string;
    name: string;
    type: string;
    priceBook: string;
    price: number;
    comment: string;
  };
  quantity: number;
  realEntry: boolean;
}

export enum FeeTypes {
  ESTIMATE = 'ESTIMATE',
  PROCESSED_FULL = 'PROCESSED_FULL',
  PROCESSED = 'PROCESSED',
  NO_FEES = 'NO_FEES',
}
