import React from 'react';
import { Icon } from 'rsuite';

export const RemoveRoleButton = ({ onClick }: { onClick: () => void }): React.ReactElement<string> => (
  <div className="flex-item-right">
    <a className="el-button el-button--default remove-item" onClick={() => onClick()}>
      <Icon icon="trash-o" size="2x" />
    </a>
  </div>
);
