import React from 'react';
import styled from 'styled-components';
import { Popover, Whisper } from 'rsuite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { InfoOutlined } from '@material-ui/icons';

import { PrerequisiteOf } from '../../pages/atl-testing/types';

const IconWrapper = styled.span`
  padding: 0 0.5rem;
  position: relative;
  top: 5px;
`;

const PrereqItem = styled.p`
  &.is-bold {
    font-weight: bold;
  }
`;

const StyledPopover = styled(Popover)`
  max-width: 550px;
  word-break: break-word;
  white-space: pre-line;
`;

interface Props {
  prerequisites: PrerequisiteOf[];
}

export const PrerequisitesInfo = ({ prerequisites }: Props) => {
  const { t } = useTranslation();

  return (
    <Whisper
      placement="autoVerticalStart"
      trigger="hover"
      speaker={
        <StyledPopover>
          <span className="text-muted">{t('lab.prerequisiteOf')}</span>
          {prerequisites.map((item, index) => (
            <PrereqItem
              key={item.id}
              className={classNames('mt-0', {
                'is-bold': item.isBold,
              })}
            >
              {item?.name} ({item.role?.name}) {item.version?.name}
              {index === prerequisites.length - 1 ? '' : ', '}
            </PrereqItem>
          ))}
        </StyledPopover>
      }
    >
      <IconWrapper>
        <InfoOutlined />
      </IconWrapper>
    </Whisper>
  );
};
