import React from 'react';
import { Icon } from 'rsuite';
import { messageKeyPrefix } from '../../helpers/constants';
import { AppEventTypes, HistoryEvent, HistoryEventTypes } from '../../components/pages/application/types';
// @ts-ignore
import _variables from '../../styles/_variables.scss';

const TimelineIcon = ({ event }: { event: HistoryEvent }) => {
  if (event['@type'] === HistoryEventTypes.NOTE) {
    return <Icon icon="comments" size="2x" />;
  }

  const eventType = event.eventType?.key;
  const key = eventType?.replace(`${messageKeyPrefix}history.event.`, '').toUpperCase();

  switch (key) {
    case AppEventTypes.APPLICATION_CREATED:
      return <Icon icon="dot-circle-o" size="2x" />;
    case AppEventTypes.APPLICATION_QUALIFIED:
    case AppEventTypes.APPLICATION_CERTIFIED:
      return <Icon icon="check" size="2x" style={{ background: _variables.success, color: '#fff' }} />;
    case AppEventTypes.APPLICATION_RECALLED:
      return <Icon icon="close" size="2x" />;
    case AppEventTypes.LAB_REQUEST_ACCEPTED:
    case AppEventTypes.LAB_REQUEST_SENT_TO_STAFF:
    case AppEventTypes.APPLICATION_SENT_TO_LAB:
    case AppEventTypes.APPLICATION_SUBMITTED:
      return <Icon icon="check" size="2x" />;
    case AppEventTypes.LAB_REQUEST_REJECTED:
    case AppEventTypes.APPLICATION_RETURNED_FROM_STAFF_TO_MEMBER:
      return <Icon icon="close" size="2x" style={{ background: _variables.errorRed, color: '#fff' }} />;
    default:
      return <Icon icon="pencil" size="lg" style={{ paddingTop: '9px' }} />;
  }
};

export default TimelineIcon;
