import * as actionTypes from './actionTypes';
import { AuditingInfo, RunCondition } from '../../../components/pages/certification/types';
import { LoadingState, SectionEditState } from './reducer';
import { CertificationDefinition } from './index';
import { ErrorObject } from '../../../helpers/types';

export type Additions = {
  auditingInfo?: {
    propertyToUpdate: keyof AuditingInfo;
    dataToUpdate: {
      updatedAt: string;
      updatedBy: string;
    };
  };
  errorToClear?: string;
  sectionEditState?: { [key: string]: boolean };
  saving?: boolean;
};

export function resetCertification() {
  return {
    type: actionTypes.RESTORE_CERTIFICATION,
  };
}

export function editCertificationLoaded(payload: CertificationDefinition) {
  return {
    type: actionTypes.CERTIFICATION_FOR_EDIT_LOADED,
    payload,
  };
}

export function updateCertificationProperty(
  value: Partial<CertificationDefinition> | string | boolean | Date | RunCondition | number | null,
  name: string,
  additions?: Additions,
) {
  return {
    type: actionTypes.UPDATE_CERTIFICATION_PROPERTY,
    payload: {
      value,
      name,
      additions,
    },
  };
}

export function setLoading(payload: Partial<LoadingState>) {
  return {
    type: actionTypes.SET_LOADING,
    payload,
  };
}

export function setValidationErrors(validationErrors: ErrorObject, sectionsToEdit?: Partial<SectionEditState>) {
  return {
    type: actionTypes.SET_CERTIFICATION_VALIDATION_ERRORS,
    payload: { validationErrors, sectionsToEdit },
  };
}

export function setSectionEditState(payload: Partial<SectionEditState>) {
  return {
    type: actionTypes.SET_SECTIONS_EDIT_STATE,
    payload,
  };
}
