import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Checkbox, Icon } from 'rsuite';
import { Confirm } from '../../../../../helpers/confirmationPopup/Confirm';
import { certificationStatusTitles } from '../../../../../helpers/constants/certification-statuses';
import { SummaryTableWithoutTabProps } from '../../../../../helpers/types';
import { updateSelectedCertificationProperty } from '../../../../../redux/modules/admin/atl-qualifications';
import { YesNoColumn } from '../../../../../shared-components';
import { AnchorLink } from '../../../../../shared-components/styled-components';
import DataTable from '../../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../../shared-components/table/DataTableColumn';
import { LabSummaryCertificationByGroup } from '../types';
import { QualificationsSummaryTableActions } from './QualificationsSummaryTableActions';

type QualificationsSummaryTableProps = Partial<SummaryTableWithoutTabProps<LabSummaryCertificationByGroup>> & {
  data: LabSummaryCertificationByGroup[];
  pagination?: boolean;
  creationMode?: boolean;
  deleteQualification: (certification: LabSummaryCertificationByGroup) => void;
  enableExpandAll?: boolean;
  onExpandAll?: (expanded: boolean) => void;
};

export const QualificationsSummaryTable = (props: QualificationsSummaryTableProps) => {
  const {
    data,
    pagination = false,
    creationMode = false,
    deleteQualification,
    enableExpandAll = false,
    ...rest
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // store which row need to expand
  const [expandedRows, setExpendedRows] = useState<string[]>([]);
  // store which row is new or update
  const [expandedRowCount, setExpandedRowCount] = useState<Record<string, number>>({});

  const getRowKey = (cert: LabSummaryCertificationByGroup) => `row-${cert.id}-${cert.version?.id ?? ''}`;

  const onExpand = (expend: boolean, rowKey: string) => {
    if (expend) {
      setExpendedRows([...expandedRows, rowKey]);
    } else {
      setExpendedRows(expandedRows.filter(r => r !== rowKey));
    }
  };

  const onExpandAll = (expend: boolean) => {
    if (expend) {
      setExpendedRows(data.map(cert => getRowKey(cert)));
    } else {
      setExpendedRows([]);
    }
  };

  const isExpandAll = useMemo(() => {
    return expandedRows.length > 0 && data.every(cert => expandedRows.includes(getRowKey(cert)));
  }, [data, expandedRows]);

  const onDelete = (certification: LabSummaryCertificationByGroup) => {
    return Confirm({
      title: t('common.placeholders.areYouSure'),
      message: t('common.placeholders.cantBeUndone'),
      onAccept: () => {
        // update row count
        const rowKey = `row-${certification.id}-`;
        if (certification.version !== null) {
          setExpandedRowCount(prev => {
            const newExpandedRowCount = { ...prev };
            newExpandedRowCount[rowKey] -= 1;
            return newExpandedRowCount;
          });
        } else {
          setExpandedRowCount(prev => {
            const newExpandedRowCount = { ...prev };
            newExpandedRowCount[rowKey] = 0;
            return newExpandedRowCount;
          });
        }
        // do delete
        dispatch(deleteQualification(certification));
      },
    });
  };

  useEffect(() => {
    const newRowKeys: string[] = [];
    const newExpandedRowCount = { ...expandedRowCount };
    data.forEach((cert: LabSummaryCertificationByGroup) => {
      const rowKey = getRowKey(cert);
      const childrenCount = cert.children.length;
      // new data
      if (!newExpandedRowCount.hasOwnProperty(rowKey)) {
        newExpandedRowCount[rowKey] = childrenCount;
        newRowKeys.push(rowKey);
      } else {
        // update data
        if (newExpandedRowCount[rowKey] !== childrenCount) {
          newExpandedRowCount[rowKey] = childrenCount;
          if (!expandedRows.includes(rowKey)) {
            newRowKeys.push(rowKey);
          }
        }
      }
    });
    if (newRowKeys.length > 0) {
      setExpendedRows(prev => [...prev, ...newRowKeys]);
      setExpandedRowCount(newExpandedRowCount);
    }
  }, [data]);

  const setInteroperability = (certification: LabSummaryCertificationByGroup, value: boolean) =>
    certification.version &&
    dispatch(updateSelectedCertificationProperty(certification.version.id, 'interoperability', value));

  const setConformance = (certification: LabSummaryCertificationByGroup, value: boolean) =>
    certification.version &&
    dispatch(updateSelectedCertificationProperty(certification.version.id, 'conformance', value));

  return (
    <DataTable<LabSummaryCertificationByGroup>
      sticky
      topOffset={80}
      expandable
      data={data}
      pagination={pagination}
      rowKey={getRowKey}
      expandedRows={expandedRows}
      enableExpandAll={enableExpandAll}
      isExpandAll={isExpandAll}
      onExpandAll={onExpandAll}
      onExpand={onExpand}
      {...rest}
    >
      {creationMode && (
        <DataTableColumn width={'2%'} style={{ padding: '10px 0px 10px 12px' }} field="id" label="" prefix>
          {(row: LabSummaryCertificationByGroup) => (
            <Icon icon="trash-o" size="lg" onClick={() => onDelete(row)} style={{ cursor: 'pointer' }} />
          )}
        </DataTableColumn>
      )}
      <DataTableColumn wordWrap width="30%" field="name" label={t('certifications.name')} sortable>
        {(row: LabSummaryCertificationByGroup, nested) => {
          if (nested) return row.name;
          else return <span style={{ fontWeight: 'bold' }}>{row.name}</span>;
        }}
      </DataTableColumn>
      <DataTableColumn wordWrap minWidth={70} field="id" label={t('certifications.id')}>
        {(row: LabSummaryCertificationByGroup) => {
          if (row.version == null) return null;
          if (creationMode) return row.version.id;
          else
            return (
              <AnchorLink to={`/certification/${row.version.id}`} target="_blank">
                {row.version.id}
              </AnchorLink>
            );
        }}
      </DataTableColumn>
      <DataTableColumn wordWrap width="30%" field="version" label={t('certifications.version.version')}>
        {(row: LabSummaryCertificationByGroup) => row.version?.name ?? null}
      </DataTableColumn>
      <DataTableColumn wordWrap width="30%" field="role" label={t('certifications.role')} sortable>
        {(row: LabSummaryCertificationByGroup) => row.role.name}
      </DataTableColumn>
      <DataTableColumn wordWrap minWidth={150} field="status" label={t('certifications.status')}>
        {(row: LabSummaryCertificationByGroup) => (row.version ? certificationStatusTitles[row.version.status] : null)}
      </DataTableColumn>
      <DataTableColumn minWidth={150} field="interoperability" label={t('applications.labs.interoperability')}>
        {(row: LabSummaryCertificationByGroup) =>
          row.version == null ? null : creationMode ? (
            <Checkbox
              checked={row.version.interoperability}
              onChange={(_, checked) => setInteroperability(row, checked)}
            />
          ) : (
            <YesNoColumn value={row.version.interoperability} />
          )
        }
      </DataTableColumn>
      <DataTableColumn minWidth={150} field="conformance" label={t('applications.labs.conformance')}>
        {(row: LabSummaryCertificationByGroup) =>
          row.version == null ? null : creationMode ? (
            <Checkbox checked={row.version.conformance} onChange={(_, checked) => setConformance(row, checked)} />
          ) : (
            <YesNoColumn value={row.version.conformance} />
          )
        }
      </DataTableColumn>
      <DataTableColumn
        minWidth={100}
        field="actions"
        label={t('common.actions.title')}
        style={{ textAlign: 'center', height: 60 }}
      >
        {(certification: LabSummaryCertificationByGroup, nested) => (
          <QualificationsSummaryTableActions
            creationMode={creationMode}
            certification={certification}
            deleteQualification={deleteQualification}
            nested={nested}
          />
        )}
      </DataTableColumn>
    </DataTable>
  );
};
