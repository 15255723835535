import React from 'react';
import { Row, Col, Whisper, Popover, Icon } from 'rsuite';
import { useTranslation } from 'react-i18next';

import { CertificationType, VersionInfo, ProfileType, ProfileTypeTitle } from '../../types';
import { CertificationStatuses } from '../../../../../helpers/constants/certification-statuses';
import { reformatDateToFormat, summaryDateFormat } from '../../../../../helpers';
import { DetailsItem } from '../../../../partials';
import LifeCycleStep from '../lifecycle/LifeCycleStep';
import LifeCycleStepper from '../lifecycle/LifeCycleStepper';
import { LifeCycleDetailsLabel } from './LifeCycleDetailsLabel';
import { getLifeCycleActiveStep } from '../lifecycle/helpers';

export const ViewVersionSection = ({
  data,
  type,
  status,
}: {
  data: VersionInfo;
  type: CertificationType;
  status: CertificationStatuses;
}) => {
  const { t } = useTranslation();
  const activeStep = getLifeCycleActiveStep(data, status);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={8}>
              <DetailsItem label={t('certifications.version.version')} value={data.version} />
            </Col>
            <Col xs={8}>
              <DetailsItem
                label={t('certifications.version.versionAppearsOnCertificate')}
                value={
                  data.visibleOnCertificate === null || data.visibleOnCertificate === undefined
                    ? ''
                    : data.visibleOnCertificate
                    ? 'Yes'
                    : 'No'
                }
              />
            </Col>
            <Col xs={8}>
              <DetailsItem
                label={t('certifications.version.profileType')}
                value={data.profile?.type ? ProfileTypeTitle[data.profile?.type] : ''}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row gutter={20}>
            <Col xs={8}>
              {[ProfileType.PROFILE, ProfileType.ASD].includes(data.profile?.type) ? (
                <DetailsItem
                  label={t(
                    `certifications.version.${
                      data.profile?.type === ProfileType.PROFILE ? 'profileName' : 'ASDTestPlan'
                    }`,
                  )}
                  value={data.profile?.name}
                />
              ) : null}
            </Col>
            <Col xs={8}>
              <DetailsItem label={t('certifications.version.ECN')} value={data.ecn} />
            </Col>
            <Col xs={8}>
              <DetailsItem
                label={
                  <>
                    {t('certifications.version.revision.title')}
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={
                        <Popover style={{ maxWidth: '250px' }}>{t('certifications.version.revision.info')}</Popover>
                      }
                    >
                      <Icon className="pointer text-muted" icon="question2" style={{ margin: '0 0.5rem' }} />
                    </Whisper>
                  </>
                }
                value={data.revision}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {type === CertificationType.PRIMARY && (
        <Row>
          <Col xs={20}>
            <LifeCycleStepper activeStep={activeStep}>
              <LifeCycleStep disabled={!data.certificationStart}>
                <DetailsItem
                  label={<LifeCycleDetailsLabel highlighted translationKey="certifications.certStart" />}
                  value={
                    data.certificationStart ? reformatDateToFormat(data.certificationStart, summaryDateFormat) : null
                  }
                  placeholder={t('certifications.lifecycle.noDateSelected')}
                />
              </LifeCycleStep>

              <LifeCycleStep disabled={!data.publicLaunch}>
                <DetailsItem
                  label={<LifeCycleDetailsLabel highlighted translationKey="certifications.publicLaunch" />}
                  value={data.publicLaunch ? reformatDateToFormat(data.publicLaunch, summaryDateFormat) : null}
                  placeholder={t('certifications.lifecycle.noDateSelected')}
                />
              </LifeCycleStep>

              <LifeCycleStep disabled={!data.firstRetirementPhaseDate}>
                <DetailsItem
                  label={<LifeCycleDetailsLabel highlighted translationKey="certifications.retirementPhase1" />}
                  value={
                    data.firstRetirementPhaseDate
                      ? reformatDateToFormat(data.firstRetirementPhaseDate, summaryDateFormat)
                      : null
                  }
                  placeholder={t('certifications.lifecycle.noDateSelected')}
                />
              </LifeCycleStep>

              <LifeCycleStep disabled={!data.secondRetirementPhaseDate}>
                <DetailsItem
                  label={<LifeCycleDetailsLabel highlighted translationKey="certifications.retirementPhase2" />}
                  value={
                    data.secondRetirementPhaseDate
                      ? reformatDateToFormat(data.secondRetirementPhaseDate, summaryDateFormat)
                      : null
                  }
                  placeholder={t('certifications.lifecycle.noDateSelected')}
                />
              </LifeCycleStep>

              <LifeCycleStep disabled={!data.thirdRetirementPhaseDate}>
                <DetailsItem
                  label={<LifeCycleDetailsLabel highlighted translationKey="certifications.retirementPhase3" />}
                  value={
                    data.thirdRetirementPhaseDate
                      ? reformatDateToFormat(data.thirdRetirementPhaseDate, summaryDateFormat)
                      : null
                  }
                  placeholder={t('certifications.lifecycle.noDateSelected')}
                />
              </LifeCycleStep>
            </LifeCycleStepper>
          </Col>
        </Row>
      )}
      <DetailsItem label={t('certifications.version.summaryOfChanges')} value={data.changesSummary} />
      <Row gutter={20}>
        <Col xs={12}>
          <DetailsItem label={t('certifications.version.notesFolLab')} value={data.labNotes} className="my-1" />
        </Col>
        <Col xs={12}>
          <DetailsItem label={t('certifications.version.notesForMember')} value={data.memberNotes} className="my-1" />
        </Col>
      </Row>
    </>
  );
};
