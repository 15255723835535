import React from 'react';
import { reformatDate } from '../../../../helpers';
import { RsColumn, RsTable } from '../../../../shared-components/theme';
import { Icon, Table } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { LRConformanceResults } from '../types';
import { UploadedFile } from '../../../../shared-components/file-upload/types';
import { buildDownloadUrl } from '../../../../helpers/build-download-url';
import { apiBaseUrl } from '../../../../config';
import { TestResult } from '../../../../redux/modules/testing-flow/types';
import { customIconsSet } from '../../../../helpers/constants';

const { HeaderCell, Cell } = Table;

export interface TestResultTableRow {
  fileToDownload: UploadedFile;
  conformanceResult?: LRConformanceResults;
  conformanceReportToken?: string;
  lrId: number;
  lab?: { id: number; name: string };
}

export const ResultsTable = ({
  data,
  isConformanceResults = false,
  isAttachments = false,
  showLab = false,
}: {
  data: TestResultTableRow[];
  isConformanceResults?: boolean;
  isAttachments?: boolean;
  showLab?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <RsTable className="mb-1" data={data} wordWrap>
      <RsColumn flexGrow={1} align="left" verticalAlign="middle" fixed>
        <HeaderCell>{isAttachments ? t('common.columns.fileName') : t('common.columns.resultsFileName')}</HeaderCell>
        <Cell dataKey="fileToDownload.name">{(rowData: TestResultTableRow) => rowData.fileToDownload.name}</Cell>
      </RsColumn>
      {isConformanceResults ? (
        <RsColumn width={80} verticalAlign="middle" align="center">
          <HeaderCell>{t('testing.review.report')}</HeaderCell>
          <Cell>
            {(rowData: TestResultTableRow) => (
              <a
                href={`${apiBaseUrl}/reports/conformance?token=${rowData.conformanceReportToken}&labRequestId=${rowData.lrId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon={customIconsSet.DOWNLOAD_ICON} size="2x" />
              </a>
            )}
          </Cell>
        </RsColumn>
      ) : null}
      {showLab ? (
        <RsColumn width={140} verticalAlign="middle" align="center">
          <HeaderCell>{t('testing.uploadResults.lab')}</HeaderCell>
          <Cell dataKey="lab.id">{(rowData: TestResultTableRow) => rowData.lab?.name}</Cell>
        </RsColumn>
      ) : null}
      {isConformanceResults ? (
        <RsColumn width={95} verticalAlign="middle" align="center">
          <HeaderCell>{t('testing.uploadResults.validated')}</HeaderCell>
          <Cell>
            {(rowData: TestResultTableRow) => (rowData.conformanceResult?.passed === TestResult.SUCCESS ? 'Yes' : 'No')}
          </Cell>
        </RsColumn>
      ) : null}
      <RsColumn width={140} verticalAlign="middle" align="center">
        <HeaderCell>{t('testing.uploadResults.uploadedDate')}</HeaderCell>
        <Cell dataKey="uploadedAt">
          {(rowData: TestResultTableRow) => reformatDate(rowData.fileToDownload.uploadedAt)}
        </Cell>
      </RsColumn>
      <RsColumn width={100} verticalAlign="middle" align="center">
        <HeaderCell>{t('common.actions.download')}</HeaderCell>
        <Cell>
          {(rowData: TestResultTableRow) => (
            <a href={buildDownloadUrl(rowData.fileToDownload)} download>
              <Icon icon={customIconsSet.DOWNLOAD_ICON} size="2x" />
            </a>
          )}
        </Cell>
      </RsColumn>
    </RsTable>
  );
};
