import React from 'react';
import { Button } from 'rsuite';
import Card from '../../../shared-components/card/Card';
import { RouteProps, withRouter } from 'react-router';

const LoginFailedPage = ({ location }: RouteProps): React.ReactElement<string> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const urlParams = new URLSearchParams((location as any).search);
  const message = urlParams.get('message') || 'No error description provided. Sorry!';
  const redirectUri = urlParams.get('redirect_uri');

  const leavePage = () => {
    if (redirectUri) {
      window.location.href = redirectUri;
    }
  };

  return (
    <section className="login-failed-page">
      <Card>
        <h3>Authorization failed</h3>
        <p>{message}</p>
        {redirectUri ? (
          <Button color="orange" onClick={leavePage}>
            Leave this page
          </Button>
        ) : null}
      </Card>
    </section>
  );
};

export default withRouter(LoginFailedPage);
