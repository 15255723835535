import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AxiosError, AxiosResponse } from 'axios';
import { TCRecord, TestCaseFormValues } from './types';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getSingleTC, updateTC } from '../../../api/test-case';
import { ErrorCard } from '../../../shared-components';
import { TCForm } from './partials/TCForm';
import { getStoredSummaryUrl } from '../../../helpers';
import { ValidationErrorResponse } from '../../../helpers/types';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';

const TCEditComponent = ({ match, history }: RouteComponentProps<{ id: string }>): React.ReactElement<string> => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState();
  const [tcItem, setTCItem] = useState();

  const updateTestCase = (data: TestCaseFormValues, saveOnly = false) => {
    return updateTC(data)
      .then((response: AxiosResponse<TCRecord>) => {
        addToast(t('testCases.updated'), {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        setTCItem(response.data);
        if (!saveOnly) {
          history.push(getStoredSummaryUrl('test-case'));
        }
      })
      .catch((error: AxiosError<ValidationErrorResponse>) => {
        handleRequestFail(error, addToast);
        throw error;
      });
  };

  useEffect(() => {
    getSingleTC(match.params.id)
      .then((response: AxiosResponse<TCRecord>) => {
        setTCItem(response.data);
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        setLoadingError(error);
      });
  }, []);

  return (
    <>
      {loading ? <CircularLoader fullPage backdrop content={t('common.placeholders.loadingData')} /> : null}
      {loadingError ? <ErrorCard error={loadingError} /> : null}
      {tcItem ? <TCForm item={tcItem} onSave={updateTestCase} /> : null}
    </>
  );
};

export const TCUpdatePage = withRouter(TCEditComponent);
