import React, { useEffect, useState } from 'react';
import { Icon, IconButton, Table } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { RsColumn, RsTable } from '../../../../../shared-components/theme';
import { jsonCopy } from '../../../../../helpers';
import styled from 'styled-components';
import { CertificationType, OnChangeType, PrereqAutocomplete, Prerequisites } from '../../types';
import { getSuggestedPrereqs } from '../../../../../api/certification';
import { AxiosResponse } from 'axios';
import { defaultPrerequisiteValue } from '../../../../../redux/modules/certification/data';
import { handleRequestFail } from '../../../../../helpers/request-fail-handler';
import { useToasts } from 'react-toast-notifications';
import { NoTableData } from '../../../../partials';
import { CertificationDefinition } from '../../../../../redux/modules/certification';
import { Item } from './Item';
import { RoundIcon } from '../../../../../shared-components/button/AddButton';
import { AutocompleteField } from '../../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';

const { HeaderCell, Cell } = Table;

const TrashBtn = styled(IconButton)`
  &.rs-btn-icon > .rs-icon {
    font-size: 24px;
    line-height: 1.2;
    padding: 4px 0;
  }
`;

export const EditPrerequisitesSection = ({
  data,
  onChange,
  id,
}: {
  id?: number;
  data: Prerequisites[];
  onChange: (x: OnChangeType, name: string) => void;
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [suggestions, setSuggestions] = useState<PrereqAutocomplete[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getSelectedIds = (array: Prerequisites[]) =>
    array.filter(prereq => prereq.certification.id !== -1).map(prereq => prereq.certification.id);

  const getSuggestions = (ids?: number[]) => {
    setLoading(true);
    getSuggestedPrereqs(ids || getSelectedIds(data))
      .then((response: AxiosResponse<PrereqAutocomplete[]>) => {
        setLoading(false);
        setSuggestions(response.data.filter(el => el.id !== id));
      })
      .catch(error => {
        handleRequestFail(error, addToast);
        setLoading(false);
      });
  };

  const handleChange = (value: PrereqAutocomplete, name: string, currentItem: { id: number }, rowIndex: number) => {
    if (value.id !== currentItem.id) {
      const newData = jsonCopy(data);
      newData[rowIndex] = {
        certification: {
          id: value.id,
          name: value.name,
          role: {
            id: -1,
            name: value.role,
          },
        },
        version: '',
        revision: '',
      };
      getSuggestions(getSelectedIds(newData));
      // @ts-ignore
      onChange(newData[rowIndex] as Partial<CertificationDefinition>, name);
    }
  };

  const getVersions = (certId: number) => {
    return (
      suggestions
        .find(item => item.id === certId)
        ?.versions.map(item => ({
          label: item.version,
          value: item.version,
        })) || []
    );
  };

  const getRevisions = (version: string, certId: number) => {
    const versions = suggestions.find(item => item.id === certId)?.versions;
    if (!versions) {
      return [];
    }
    if (version) {
      return (
        versions
          .find(item => item.version === version)
          ?.revision.map(item => ({
            label: item,
            value: item,
          })) || []
      );
    }
    return versions
      .reduce((accum, next) => {
        next.revision.forEach(revision => {
          if (!accum.includes(revision)) {
            accum.push(revision);
          }
        });
        return accum;
      }, [] as string[])
      .map(item => ({
        label: item,
        value: item,
      }));
  };

  const handleVersionChange = (version: string, name: string, currentItem: Prerequisites) => {
    if (!getRevisions(version, currentItem.certification.id).some(item => item.value === currentItem.revision)) {
      onChange('', name.replace('version', 'revision'));
    }
    onChange(version, name);
  };

  const onRemove = (index: number) => {
    const newData = jsonCopy(data);
    newData.splice(index, 1);
    onChange(newData as Partial<CertificationDefinition>, 'prerequisites');
    newData.length > 0 && getSuggestions(getSelectedIds(newData));
  };

  const onAdd = () => {
    const newData = jsonCopy(data);
    newData.push(defaultPrerequisiteValue);
    onChange(newData as Partial<CertificationDefinition>, 'prerequisites');
  };

  useEffect(() => {
    if (suggestions.length > 0 && getSelectedIds(data).length > 0) {
      const filteredData = data.filter(prereq =>
        suggestions.some(suggestion => suggestion.id === prereq.certification.id),
      );
      if (filteredData.length !== data.length) {
        onChange(filteredData as Partial<CertificationDefinition>, 'prerequisites');
      }
    }
  }, [suggestions]);

  useEffect(getSuggestions, []);

  const isVersionDisabled = (certId: number) =>
    suggestions.find(item => item.id === certId)?.type === CertificationType.CAPABILITY;

  return (
    <>
      <RsTable
        data={jsonCopy(data)}
        className="form-table"
        loading={loading}
        rowHeight={65}
        renderEmpty={() => <NoTableData />}
      >
        <RsColumn align="left" width={45} verticalAlign="middle">
          <HeaderCell />
          <Cell>
            {(rowData: Prerequisites, rowIndex: number) => (
              <TrashBtn icon={<Icon icon="trash-o" />} onClick={() => onRemove(rowIndex)} />
            )}
          </Cell>
        </RsColumn>
        <RsColumn align="left" flexGrow={2} verticalAlign="middle">
          <HeaderCell>{t('certifications.certification')}</HeaderCell>
          <Cell>
            {(rowData: Prerequisites, rowIndex: number) => (
              <AutocompleteField
                data={suggestions}
                labelKey="name"
                valueKey="id"
                value={rowData.certification.id}
                getOptionLabel={item => `${item.name} (${item.role})`}
                renderMenuItem={item => <Item item={item} />}
                getOptionDisabled={option => getSelectedIds(data).some(id => id === option.id)}
                name={`prerequisites[${rowIndex}].certification.id`}
                onChange={(value: number) => {
                  const suggestion =
                    suggestions.find(suggestion => suggestion.id === value) || ({} as PrereqAutocomplete);
                  handleChange(suggestion, `prerequisites[${rowIndex}]`, rowData.certification, rowIndex);
                }}
                placeholder={'Start typing...'}
                hideClose
              />
            )}
          </Cell>
        </RsColumn>

        <RsColumn verticalAlign="middle" flexGrow={1}>
          <HeaderCell>{t('certifications.version.version')}</HeaderCell>
          <Cell>
            {(rowData: Prerequisites, rowIndex: number) => (
              <AutocompleteField
                data={getVersions(rowData.certification.id)}
                labelKey="label"
                valueKey="value"
                value={rowData.version}
                name={`prerequisites[${rowIndex}].certification.version`}
                onChange={value => handleVersionChange(value, `prerequisites[${rowIndex}].version`, rowData)}
                placeholder={'Start typing...'}
                onClean={() => onChange('', `prerequisites[${rowIndex}].version`)}
                disabled={isVersionDisabled(rowData.certification.id) || rowData.certification.id === -1}
              />
            )}
          </Cell>
        </RsColumn>

        <RsColumn verticalAlign="middle" flexGrow={1}>
          <HeaderCell>{t('certifications.version.revision.title')}</HeaderCell>
          <Cell>
            {(rowData: Prerequisites, rowIndex: number) => (
              <AutocompleteField
                data={getRevisions(rowData.version, rowData.certification.id)}
                labelKey="label"
                valueKey="value"
                value={rowData.revision}
                name={`prerequisites[${rowIndex}].certification.revision`}
                onChange={(value: string) => onChange(value, `prerequisites[${rowIndex}].revision`)}
                placeholder={'Start typing...'}
                onClean={() => onChange('', `prerequisites[${rowIndex}].revision`)}
                disabled={!rowData.version}
              />
            )}
          </Cell>
        </RsColumn>
      </RsTable>
      <RoundIcon className="add-prerequisite" icon={<Icon icon="plus" />} onClick={onAdd} circle />
    </>
  );
};
