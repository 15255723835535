import { call, put, takeLatest, all } from 'redux-saga/effects';

import { ACCEPT_GDPR, LOAD_USER, loadUser, USER_LOGOUT, userLoaded } from './';
import { appFinishLoading } from '../app';
import { acceptGDPRPolicy, getUserProfile, logoutUser } from '../../../api/user';
import { loadRoles } from '../roles';
import Cookie from 'js-cookie';

export function* getUserProfileSaga() {
  try {
    const response = yield call(getUserProfile);
    yield put(userLoaded(response.data));
    if (!response.data.requiresGdprDataTransferConsent) {
      yield put(loadRoles());
    }
    yield put(appFinishLoading());
  } catch (e) {
    yield put(userLoaded(null));
    yield put(appFinishLoading());
  }
}

export function* logoutUserSaga() {
  try {
    yield call(logoutUser);
  } catch (e) {
    // TODO: Update with toast
    console.error(e, 'Can`t log user out');
  }
}

export function* acceptGDPRSaga({ addToast }: { type: string; addToast: AddFn }) {
  try {
    const response = yield call(acceptGDPRPolicy);
    Cookie.set('access-token', response.data.jwt);
    yield put(loadUser());
  } catch (e) {
    addToast(e.message + ': ' + e.response?.data?.message, {
      appearance: 'error',
      autoDismiss: false,
    });
  }
}

function* watchGetUserSaga() {
  yield takeLatest(LOAD_USER, getUserProfileSaga);
}

function* watchUserLogoutSaga() {
  yield takeLatest(USER_LOGOUT, logoutUserSaga);
}

function* watchAcceptGDPRSaga() {
  yield takeLatest(ACCEPT_GDPR, acceptGDPRSaga);
}

export default function* watchMultipleUserSagas() {
  yield all([watchGetUserSaga(), watchUserLogoutSaga(), watchAcceptGDPRSaga()]);
}
