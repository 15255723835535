import { IterableObject } from '../types';
import i18n from '../i18n-setup';

export enum CertificationStatuses {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  FIRST_RETIREMENT_PHASE = 'FIRST_RETIREMENT_PHASE',
  SECOND_RETIREMENT_PHASE = 'SECOND_RETIREMENT_PHASE',
  THIRD_RETIREMENT_PHASE = 'THIRD_RETIREMENT_PHASE',
}

export const certificationStatuses: IterableObject<string> = {
  draft: CertificationStatuses.DRAFT,
  published: CertificationStatuses.PUBLISHED,
  firstRetirementPhase: CertificationStatuses.FIRST_RETIREMENT_PHASE,
  secondRetirementPhase: CertificationStatuses.SECOND_RETIREMENT_PHASE,
  thirdRetirementPhase: CertificationStatuses.THIRD_RETIREMENT_PHASE,
};
export const certificationStatusTitles: IterableObject<string> = {
  [certificationStatuses.draft]: i18n.t('common.tabs.draft'),
  [certificationStatuses.published]: i18n.t('common.tabs.published'),
  [certificationStatuses.firstRetirementPhase]: i18n.t('common.tabs.retirementPhase1'),
  [certificationStatuses.secondRetirementPhase]: i18n.t('common.tabs.retirementPhase2'),
  [certificationStatuses.thirdRetirementPhase]: i18n.t('common.tabs.retirementPhase3'),
};
