import React, { HTMLAttributes, PropsWithChildren } from 'react';

export interface TabPaneProps extends HTMLAttributes<HTMLDivElement>, PropsWithChildren<{}> {
  label: React.ReactElement<string> | string;
  name: string;
  disabled?: boolean;
}

export const TabPane = ({ children, ...props }: TabPaneProps): React.ReactElement<string> => (
  <div {...props}>{children}</div>
);
