import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router';
import { userState } from '../../../redux/modules/user/reducer';
import { loadUser } from '../../../redux/modules/user';
import { connect } from 'react-redux';
import { loadRoles } from '../../../redux/modules/roles';
import { SSORedirect } from '../../pages';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

interface AppComponentProps {
  loadUser: () => void;
  loadRoles: () => void;
}

const RouteWrapper = ({
  component: Component,
  user,
  grantedRoles,
  ...props
}: RouteProps & userState & AppComponentProps & { grantedRoles?: string[] }) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  // check if we have user
  if (user === null) {
    return <SSORedirect />;
  }
  //  if we passed granted roles for this route
  if (grantedRoles && !grantedRoles.includes(user.authority)) {
    addToast(t('common.access.notAllowed'), {
      appearance: 'warning',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    return <Redirect to={{ pathname: '/' }} />;
  }
  // @ts-ignore
  return <Route {...props} render={innerProps => <Component {...innerProps} />} />;
};
const mapStateToProps = (state: { userReducer: userState }): userState => ({
  user: state.userReducer.user,
  loading: state.userReducer.loading,
});

export const PrivateRoute = connect(mapStateToProps, { loadUser, loadRoles })(RouteWrapper);
