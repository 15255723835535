import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles, Theme } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import { DataTablePaginationProps } from '../../../../../shared-components/table/types';
import { SingleSummaryProduct, ReformattedSummaryProducts } from '../../../product/types';
import { SortingOptions, SortType } from '../../../test-case/types';

import { RightActions } from './HeaderActions';
import { jsonCopy, mergeObjects, reformatDate } from '../../../../../helpers';
import DataTable from '../../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../../shared-components/table/DataTableColumn';
import { YesNoColumn } from '../../../../../shared-components';
import { AnchorLink } from '../../../../../shared-components/styled-components';

const StyledCheckbox = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  checked: {
    color: theme.palette.primary.main,
  },
}))(Checkbox);

export const ChangeOwningCompanyTransferTable = ({
  data,
  loading,
  onChangeSorting,
  sorting,
  total,
  activePage,
  setActivePage,
  selectedProductModalIds,
  isSelectParital,
  isSelectAll,
  handleSelectSingle,
  handleSelectAll,
}: Partial<DataTablePaginationProps> & {
  data: SingleSummaryProduct[];
  loading: boolean;
  sorting: SortingOptions;
  selectedProductModalIds: number[];
  isSelectParital: boolean;
  isSelectAll: boolean;
  handleSelectSingle: (id: number, checked: boolean) => void;
  handleSelectAll: (hecked: boolean) => void;
  onChangeSorting: (sortColumn: string, sortType: SortType) => void;
}) => {
  const { t } = useTranslation();

  const reformattedData: ReformattedSummaryProducts[] = useMemo(
    () =>
      jsonCopy(data).map(({ variants, ...item }) =>
        mergeObjects(item, {
          children: [
            ...variants.map(variant => {
              delete variant.variants;
              return { ...variant, transferrable: true };
            }),
            ...item.unapprovedVariants.map(variant => {
              delete variant.variants;
              return { ...variant, transferrable: false };
            }),
          ],
        }),
      ),
    [data],
  );

  return (
    <DataTable<ReformattedSummaryProducts>
      expandable
      pagination
      sticky
      data={reformattedData}
      sorting={sorting}
      loading={loading}
      total={total}
      activePage={activePage}
      setActivePage={setActivePage}
      onChangeSorting={onChangeSorting}
      useEnvIdentifier={true}
      rowKey={'variantId'}
    >
      <DataTableColumn
        field="cid"
        label={
          <StyledCheckbox
            onChange={(event, checked) => {
              handleSelectAll(checked);
            }}
            checked={isSelectAll || isSelectParital}
            indeterminate={isSelectParital}
          />
        }
        width={'2%'}
        style={{ padding: '10px 0px 10px 12px' }}
        prefix
      >
        {(row: ReformattedSummaryProducts, nested) =>
          !nested ? (
            <StyledCheckbox
              checked={selectedProductModalIds.includes(row.id)}
              onChange={(event, checked) => {
                handleSelectSingle(row.id, checked);
              }}
              disabled={row.unapprovedVariants?.length > 0}
            />
          ) : null
        }
      </DataTableColumn>
      <DataTableColumn field="cid" label={t('applications.summary.cid')} width={120} sortable>
        {(row: ReformattedSummaryProducts, nested) => (!nested ? row.cid : null)}
      </DataTableColumn>
      <DataTableColumn field="variantId" label={t('applications.summary.appId')} minWidth={120}>
        {(row: ReformattedSummaryProducts) => (
          <AnchorLink to={`/application/${row.variantId}`} target="_blank">
            {row.variantId}
          </AnchorLink>
        )}
      </DataTableColumn>
      <DataTableColumn field="productName" label={t('applications.summary.name')} width={'23%'} sortable wordWrap>
        {(row: ReformattedSummaryProducts, nested) => (!nested ? row.productName : null)}
      </DataTableColumn>
      <DataTableColumn field="variantName" label={t('applications.info.variantName')} width="15%" sortable wordWrap />
      <DataTableColumn field="modelNumber" label={t('applications.info.modelNumber')} width="15%" sortable />
      <DataTableColumn
        field="status"
        label={t('admin.changeOwningCompany.summary.transferrable')}
        width={120}
        textAlign="center"
      >
        {(row: ReformattedSummaryProducts, nested) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <YesNoColumn value={nested ? row.transferrable === true : true} />
          </div>
        )}
      </DataTableColumn>
      <DataTableColumn
        field="date"
        label={`${t('applications.summary.dates.CERTIFIED')} / ${t('applications.summary.dates.QUALIFIED')}`}
        minWidth={100}
      >
        {(row: ReformattedSummaryProducts) => (row.date ? reformatDate(row.date) : null)}
      </DataTableColumn>
      <DataTableColumn field="actions" label={t('common.actions.title')} minWidth={100}>
        {(row: ReformattedSummaryProducts) => <RightActions variantId={row.variantId} />}
      </DataTableColumn>
    </DataTable>
  );
};
