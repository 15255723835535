export enum LRSummaryStatuses {
  NEW = 'NEW_REQUEST',
  IN_TESTING = 'IN_TESTING',
  RETURNED = 'RETURNED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REVOKED = 'REVOKED',
}

export enum LRStatus {
  DRAFT = 'DRAFT',
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  IN_TESTING = 'IN_TESTING',
  APPROVED = 'APPROVED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  RETURNED = 'RETURNED',
  REVOKED = 'REVOKED',
}

export const LRStatusTitles = {
  [LRSummaryStatuses.NEW]: 'New Requests',
  [LRSummaryStatuses.IN_TESTING]: 'In Progress',
  [LRSummaryStatuses.RETURNED]: 'Returned',
  [LRSummaryStatuses.PENDING_APPROVAL]: 'Pending Approval',
  [LRSummaryStatuses.APPROVED]: 'Approved',
  [LRSummaryStatuses.REVOKED]: 'Revoked',
};
