import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';

import AdvancedFilter from '../../../shared-components/filters/AdvancedFilter';
import {
  KeywordSearchCondition,
  DateRangeCondition,
  CheckboxCondition,
  DropdownCondition,
  generateKeywordSerachConditionInitParameterValue,
  generateDateRangeConditionInitParameterValue,
  generateCheckboxConditionInitParameterValue,
  generateDropdownConditionInitParameterValue,
  getKeywordSerachConditionCurrentValue,
  getDateRangeConditionCurrentValueStartDate,
  getDateRangeConditionCurrentValueEndDate,
  getDropdownConditionCurrentValue,
  getCheckboxConditionCurrentValue,
} from '../../../shared-components/filters/conditions';
import { FilterParametersType, ConditionParameterValuesType } from '../../../shared-components/filters/types';
import { SummaryFiltersObject } from '../../../helpers/types';
import { getLabsInAllApps, getOwningCompaniesInAllApps } from '../../../api/application';
import { getOwningCompaniesInAllLabRequests } from '../../../api/lab-request';
import { getImpersonationCompanies } from '../../../api/impersonate';
import { getCapabilities } from '../../../api/certification';
import { wfaStaffRoles, atlRoles } from '../../../helpers/constants';
import { ImpersonateAutoCompleteItem, Role } from '../../partials/impersonate/types';
import { userState } from '../../../redux/modules/user/reducer';
import { User } from '../../../redux/modules/user/types';
import {
  ApplicationTypeData,
  CanCreateDerivativeData,
  WorstCertificationVersionStatusData,
  BooleanTypeData,
  DecisionTypeData,
  AmountData,
  ApplicationStatusData,
  CertificationTypeData,
  RolesData,
  HasDraftCapabilityData,
} from './ConditionData';

interface AdvancedSummaryFiltersProps {
  appliedFilters: SummaryFiltersObject;
  onFiltersApply: (filters: SummaryFiltersObject) => void;
  isApplicationSummary?: boolean;
  isProductSummary?: boolean;
  isTestingSummary?: boolean;
  isTransactionSummary?: boolean;
  isCertificationSummary?: boolean;
  isTestCaseSummary?: boolean;
}

export function AdvancedSummaryFilters({
  appliedFilters,
  onFiltersApply,
  isApplicationSummary,
  isProductSummary,
  isTestingSummary,
  isTransactionSummary,
  isCertificationSummary,
  isTestCaseSummary,
}: AdvancedSummaryFiltersProps) {
  const user = useSelector<{ userReducer: userState }, User>(state => state.userReducer.user as User);
  const { t } = useTranslation();
  const [labData, setLabData] = useState<ConditionParameterValuesType[]>([]);
  const [owningCompanyData, setOwningCompnayData] = useState<ConditionParameterValuesType[]>([]);
  const [allCompanyData, setAllCompanyData] = useState<ConditionParameterValuesType[]>([]);
  const [capabilitiesData, setCapabilitiesData] = useState<ConditionParameterValuesType[]>([]);

  useEffect(() => {
    if (isApplicationSummary) {
      if (wfaStaffRoles.includes(user.authority)) {
        getImpersonationCompanies(Role.LAB).then((response: AxiosResponse<ImpersonateAutoCompleteItem[]>) => {
          const labs = response.data.map(d => {
            return { value: `${d.id}`, label: d.name };
          });
          labs.sort((a, b) => a.label.localeCompare(b.label));
          setLabData(labs);
        });
      } else {
        getLabsInAllApps().then(response => {
          setLabData(
            response.data.map((d: { id: number; name: string }) => {
              return { value: `${d.id}`, label: d.name };
            }),
          );
        });
      }
    }
    if (isProductSummary || (isApplicationSummary && atlRoles.includes(user.authority))) {
      if (wfaStaffRoles.includes(user.authority)) {
        Promise.all([getImpersonationCompanies(Role.MEMBER), getImpersonationCompanies(Role.IMPLEMENTER)]).then(
          (responses: AxiosResponse<ImpersonateAutoCompleteItem[]>[]) => {
            const companies = [...responses[0].data, ...responses[1].data].map(d => {
              return { value: `${d.id}`, label: d.name };
            });
            companies.sort((a, b) => a.label.localeCompare(b.label));
            setOwningCompnayData(companies);
          },
        );
      } else {
        getOwningCompaniesInAllApps().then(response => {
          setOwningCompnayData(
            response.data.map((d: { id: number; name: string }) => {
              return { value: `${d.id}`, label: d.name };
            }),
          );
        });
      }
    }
    if (isApplicationSummary && wfaStaffRoles.includes(user.authority)) {
      Promise.all([getImpersonationCompanies(Role.MEMBER), getImpersonationCompanies(Role.IMPLEMENTER)]).then(
        (responses: AxiosResponse<ImpersonateAutoCompleteItem[]>[]) => {
          const companies = [...responses[0].data, ...responses[1].data].map(d => {
            return { value: `${d.id}`, label: d.name };
          });
          companies.sort((a, b) => a.label.localeCompare(b.label));
          setAllCompanyData(companies);
        },
      );
    }
    if (isTransactionSummary) {
      Promise.all([
        getImpersonationCompanies(Role.MEMBER),
        getImpersonationCompanies(Role.IMPLEMENTER),
        getImpersonationCompanies(Role.LAB),
      ]).then((responses: AxiosResponse<ImpersonateAutoCompleteItem[]>[]) => {
        const companies = [...responses[0].data, ...responses[1].data, ...responses[2].data].map(d => {
          return { value: `${d.id}`, label: d.name };
        });
        companies.sort((a, b) => a.label.localeCompare(b.label));
        setAllCompanyData(companies);
      });
    }
    if (isTestCaseSummary) {
      getCapabilities().then(response => {
        setCapabilitiesData(
          response.data.map((d: { id: number; name: string }) => {
            return { value: `${d.id}`, label: d.name };
          }),
        );
      });
    }
    if (isTestingSummary) {
      getOwningCompaniesInAllLabRequests().then(response => {
        setOwningCompnayData(
          response.data.map((d: { id: number; name: string }) => {
            return { value: `${d.id}`, label: d.name };
          }),
        );
      });
    }
  }, []);

  const handleConditionChange = (filterParameters: FilterParametersType) => {
    const newFilterObject: SummaryFiltersObject = {
      search: '',
      from: '',
      to: '',
      roleId: null,
      type: null,
      certificationId: null,
      applicationType: null,
      applicationTypeId: null,
      labId: null,
      searchable: null,
      available: null,
      canCreateDerivative: null,
      owningCompanyId: null,
      isCertified: null,
      availableAsQualifiedSolution: null,
      worstCertificationVersionStatus: null,
      decision: null,
      amount: null,
      requestingAccountId: null,
      applicationStatus: null,
      hasDraftCapability: null,
      capabilityId: null,
    };
    if (filterParameters.search) {
      newFilterObject.search = encodeURIComponent(getKeywordSerachConditionCurrentValue(filterParameters.search));
    }
    if (filterParameters.dates) {
      newFilterObject.from = encodeURIComponent(
        DateTime.fromJSDate(getDateRangeConditionCurrentValueStartDate(filterParameters.dates))
          .startOf('day')
          .toISO(),
      );
      newFilterObject.to = encodeURIComponent(
        DateTime.fromJSDate(getDateRangeConditionCurrentValueEndDate(filterParameters.dates))
          .endOf('day')
          .toISO(),
      );
    }
    if (filterParameters.applicationTypeId) {
      newFilterObject.applicationTypeId = getCheckboxConditionCurrentValue(filterParameters.applicationTypeId);
    }
    if (filterParameters.labId) {
      newFilterObject.labId = getDropdownConditionCurrentValue(filterParameters.labId);
    }
    if (filterParameters.searchable) {
      newFilterObject.searchable = getCheckboxConditionCurrentValue(filterParameters.searchable);
    }
    if (filterParameters.available) {
      newFilterObject.available = getCheckboxConditionCurrentValue(filterParameters.available);
    }
    if (filterParameters.canCreateDerivative) {
      newFilterObject.canCreateDerivative = getCheckboxConditionCurrentValue(filterParameters.canCreateDerivative);
    }
    if (filterParameters.owningCompanyId) {
      newFilterObject.owningCompanyId = getDropdownConditionCurrentValue(filterParameters.owningCompanyId);
    }
    if (filterParameters.isCertified) {
      newFilterObject.isCertified = getCheckboxConditionCurrentValue(filterParameters.isCertified);
    }
    if (filterParameters.availableAsQualifiedSolution) {
      newFilterObject.availableAsQualifiedSolution = getCheckboxConditionCurrentValue(
        filterParameters.availableAsQualifiedSolution,
      );
    }
    if (filterParameters.worstCertificationVersionStatus) {
      newFilterObject.worstCertificationVersionStatus = getCheckboxConditionCurrentValue(
        filterParameters.worstCertificationVersionStatus,
      );
    }
    if (filterParameters.decision) {
      newFilterObject.decision = getCheckboxConditionCurrentValue(filterParameters.decision);
    }
    if (filterParameters.amount) {
      newFilterObject.amount = getCheckboxConditionCurrentValue(filterParameters.amount);
    }
    if (filterParameters.requestingAccountId) {
      newFilterObject.requestingAccountId = getCheckboxConditionCurrentValue(filterParameters.requestingAccountId);
    }
    if (filterParameters.applicationStatus) {
      newFilterObject.applicationStatus = getCheckboxConditionCurrentValue(filterParameters.applicationStatus);
    }
    if (filterParameters.roleId) {
      newFilterObject.roleId = getDropdownConditionCurrentValue(filterParameters.roleId);
    }
    if (filterParameters.type) {
      newFilterObject.type = getDropdownConditionCurrentValue(filterParameters.type);
    }
    if (filterParameters.hasDraftCapability) {
      newFilterObject.hasDraftCapability = getDropdownConditionCurrentValue(filterParameters.hasDraftCapability);
    }
    if (filterParameters.capabilityId) {
      newFilterObject.capabilityId = getDropdownConditionCurrentValue(filterParameters.capabilityId);
    }
    onFiltersApply(newFilterObject);
  };

  const getInitParameters = (): FilterParametersType => {
    const initParameters: FilterParametersType = {};

    if (appliedFilters) {
      if (appliedFilters.search) {
        initParameters['search'] = generateKeywordSerachConditionInitParameterValue(
          decodeURIComponent(appliedFilters.search),
          false,
        );
      }

      if (appliedFilters.from && appliedFilters.to) {
        const defined = generateDateRangeConditionInitParameterValue(
          DateTime.fromISO(decodeURIComponent(appliedFilters.from)).toJSDate(),
          DateTime.fromISO(decodeURIComponent(appliedFilters.to)).toJSDate(),
          false,
        );
        initParameters['dates'] = defined;
      }

      if (appliedFilters.applicationTypeId) {
        initParameters['applicationTypeId'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.applicationTypeId,
          true,
          ApplicationTypeData,
        );
      }

      if (appliedFilters.labId) {
        initParameters['labId'] = generateDropdownConditionInitParameterValue(appliedFilters.labId, true, labData);
      }

      if (appliedFilters.searchable) {
        initParameters['searchable'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.searchable,
          true,
          BooleanTypeData,
          t('filters.condition.searchable.title'),
        );
      }

      if (appliedFilters.available) {
        initParameters['available'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.available,
          true,
          BooleanTypeData,
          t('filters.condition.available.title'),
        );
      }

      if (appliedFilters.canCreateDerivative) {
        initParameters['canCreateDerivative'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.canCreateDerivative,
          true,
          CanCreateDerivativeData,
        );
      }

      if (appliedFilters.owningCompanyId) {
        console.log('owningCompanyData', owningCompanyData);
        initParameters['owningCompanyId'] = generateDropdownConditionInitParameterValue(
          appliedFilters.owningCompanyId,
          true,
          owningCompanyData,
        );
      }

      if (appliedFilters.isCertified) {
        initParameters['isCertified'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.isCertified,
          true,
          BooleanTypeData,
          t('filters.condition.certified.title'),
        );
      }

      if (appliedFilters.availableAsQualifiedSolution) {
        initParameters['availableAsQualifiedSolution'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.availableAsQualifiedSolution,
          true,
          BooleanTypeData,
          t('filters.condition.availableAsQualifiedSolution.title'),
        );
      }

      if (appliedFilters.worstCertificationVersionStatus) {
        initParameters['worstCertificationVersionStatus'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.worstCertificationVersionStatus,
          true,
          WorstCertificationVersionStatusData,
        );
      }

      if (appliedFilters.decision) {
        initParameters['decision'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.decision,
          true,
          DecisionTypeData,
          t('filters.condition.decision.title'),
        );
      }

      if (appliedFilters.amount) {
        initParameters['amount'] = generateCheckboxConditionInitParameterValue(appliedFilters.amount, true, AmountData);
      }

      if (appliedFilters.requestingAccountId) {
        initParameters['requestingAccountId'] = generateDropdownConditionInitParameterValue(
          appliedFilters.requestingAccountId,
          true,
          allCompanyData,
        );
      }

      if (appliedFilters.applicationStatus) {
        initParameters['applicationStatus'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.applicationStatus,
          true,
          ApplicationStatusData,
        );
      }

      if (appliedFilters.roleId) {
        initParameters['roleId'] = generateCheckboxConditionInitParameterValue(appliedFilters.roleId, false, RolesData);
      }

      if (appliedFilters.type) {
        initParameters['type'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.type,
          false,
          CertificationTypeData,
        );
      }

      if (appliedFilters.hasDraftCapability) {
        initParameters['hasDraftCapability'] = generateCheckboxConditionInitParameterValue(
          appliedFilters.hasDraftCapability,
          true,
          HasDraftCapabilityData,
        );
      }
    }

    return initParameters;
  };

  const initParameters = getInitParameters();

  const inlineConditions = [
    { conditionType: KeywordSearchCondition, parameterName: 'search' },
    { conditionType: DateRangeCondition, parameterName: 'dates' },
  ];

  let popupConditions;
  let popupTooltip;

  if (isApplicationSummary) {
    popupConditions = [
      {
        conditionType: CheckboxCondition,
        parameterName: 'applicationTypeId',
        title: t('filters.condition.applicationType.title'),
        showSelectedLabel: true,
        options: {
          data: ApplicationTypeData,
          perRowNumber: 3,
        },
      },
      {
        conditionType: DropdownCondition,
        parameterName: 'labId',
        title: t('filters.condition.atl.title'),
        showSelectedLabel: true,
        options: {
          data: labData,
          placeholder: t('filters.condition.atl.placeholder'),
          multiple: true,
          checkbox: true,
          limitTags: 2,
        },
      },
    ];

    if (wfaStaffRoles.includes(user.authority)) {
      popupConditions.splice(1, 0, {
        conditionType: DropdownCondition,
        parameterName: 'owningCompanyId',
        title: t('filters.condition.company.title'),
        showSelectedLabel: true,
        options: {
          data: allCompanyData,
          placeholder: t('filters.condition.company.placeholder'),
          multiple: true,
          checkbox: true,
          limitTags: 2,
        },
      });
    }

    if (atlRoles.includes(user.authority)) {
      popupConditions.splice(1, 0, {
        conditionType: DropdownCondition,
        parameterName: 'owningCompanyId',
        title: t('filters.condition.company.title'),
        showSelectedLabel: true,
        options: {
          data: owningCompanyData,
          placeholder: t('filters.condition.company.placeholder'),
          multiple: true,
          checkbox: true,
          limitTags: 2,
        },
      });
    }

    if (wfaStaffRoles.includes(user.authority)) {
      popupConditions.push({
        conditionType: CheckboxCondition,
        parameterName: 'applicationStatus',
        title: t('filters.condition.applicationStatus.title'),
        showSelectedLabel: true,
        options: {
          data: ApplicationStatusData,
          perRowNumber: 3,
          prefixLabel: t('filters.condition.applicationStatus.title'),
        },
      });
    }
  }

  if (isTestingSummary) {
    popupConditions = [
      {
        conditionType: DropdownCondition,
        parameterName: 'owningCompanyId',
        title: t('filters.condition.company.title'),
        showSelectedLabel: true,
        options: {
          data: owningCompanyData,
          placeholder: t('filters.condition.company.placeholder'),
          multiple: true,
          checkbox: true,
          limitTags: 2,
        },
      },
      {
        conditionType: CheckboxCondition,
        parameterName: 'decision',
        title: `${t('filters.condition.decision.title')}`,
        showSelectedLabel: true,
        options: {
          data: DecisionTypeData,
          prefixLabel: t('filters.condition.decision.title'),
        },
      },
    ];
  }

  if (isProductSummary) {
    popupTooltip = 'filters.popup.productSummaryTooltip';
    popupConditions = [
      {
        conditionType: DropdownCondition,
        parameterName: 'owningCompanyId',
        title: t('filters.condition.owningCompany.title'),
        showSelectedLabel: true,
        options: {
          data: owningCompanyData,
          placeholder: t('filters.condition.owningCompany.placeholder'),
          multiple: true,
          checkbox: true,
          limitTags: 2,
        },
      },
      {
        conditionType: CheckboxCondition,
        parameterName: 'searchable',
        title: `${t('filters.condition.searchable.title')}?`,
        showSelectedLabel: true,
        options: {
          data: BooleanTypeData,
          prefixLabel: t('filters.condition.searchable.title'),
        },
      },
      {
        conditionType: CheckboxCondition,
        parameterName: 'available',
        title: `${t('filters.condition.available.title')}?`,
        showSelectedLabel: true,
        options: {
          data: BooleanTypeData,
          prefixLabel: t('filters.condition.available.title'),
        },
      },
      {
        conditionType: CheckboxCondition,
        parameterName: 'canCreateDerivative',
        showSelectedLabel: true,
        showDivider: true,
        options: {
          data: CanCreateDerivativeData,
          perRowNumber: 1,
        },
      },
    ];

    if (wfaStaffRoles.includes(user.authority) || user.company.qualifiedSolutionProvider) {
      popupConditions.splice(2, 0, {
        conditionType: CheckboxCondition,
        parameterName: 'isCertified',
        title: `${t('filters.condition.certified.title')}?`,
        showSelectedLabel: true,
        options: {
          data: BooleanTypeData,
          prefixLabel: t('filters.condition.certified.title'),
        },
      });
      popupConditions.push({
        conditionType: CheckboxCondition,
        parameterName: 'availableAsQualifiedSolution',
        title: `${t('filters.condition.availableAsQualifiedSolution.title')}?`,
        showSelectedLabel: true,
        options: {
          data: BooleanTypeData,
          prefixLabel: t('filters.condition.availableAsQualifiedSolution.title'),
        },
      });
      popupConditions.push({
        conditionType: CheckboxCondition,
        parameterName: 'worstCertificationVersionStatus',
        showSelectedLabel: true,
        showDivider: true,
        options: {
          data: WorstCertificationVersionStatusData,
          perRowNumber: 1,
        },
      });
    }
  }

  if (isTransactionSummary) {
    popupConditions = [
      {
        conditionType: DropdownCondition,
        parameterName: 'requestingAccountId',
        title: t('filters.condition.requestingAccountId.title'),
        showSelectedLabel: true,
        options: {
          data: allCompanyData,
          placeholder: t('filters.condition.requestingAccountId.placeholder'),
          multiple: true,
          checkbox: true,
          limitTags: 2,
        },
      },
      {
        conditionType: CheckboxCondition,
        parameterName: 'applicationTypeId',
        title: t('filters.condition.applicationType.title'),
        showSelectedLabel: true,
        options: {
          data: ApplicationTypeData,
          perRowNumber: 3,
        },
      },
      {
        conditionType: CheckboxCondition,
        parameterName: 'amount',
        title: t('filters.condition.amount.title'),
        showSelectedLabel: true,
        options: {
          data: AmountData,
          perRowNumber: 3,
        },
      },
    ];
  }

  if (isCertificationSummary) {
    inlineConditions.splice(
      1,
      0,
      {
        conditionType: DropdownCondition,
        parameterName: 'type',
        options: {
          data: CertificationTypeData,
          placeholder: t('filters.condition.type.title'),
          multiple: true,
          checkbox: true,
          limitTags: 1,
        },
      },
      {
        conditionType: DropdownCondition,
        parameterName: 'roleId',
        options: {
          data: RolesData,
          placeholder: t('filters.condition.role.title'),
          multiple: true,
          checkbox: true,
          limitTags: 1,
        },
      },
    );
  }

  if (isTestCaseSummary) {
    popupConditions = [
      {
        conditionType: DropdownCondition,
        parameterName: 'capabilityId',
        title: t('filters.condition.capabilityId.title'),
        showSelectedLabel: true,
        options: {
          data: capabilitiesData,
          placeholder: t('filters.condition.capabilityId.placeholder'),
          multiple: true,
          checkbox: true,
          limitTags: 2,
        },
      },
      {
        conditionType: CheckboxCondition,
        parameterName: 'hasDraftCapability',
        title: `${t('filters.condition.hasDraftCapability.title')}`,
        showSelectedLabel: true,
        options: {
          data: HasDraftCapabilityData,
          prefixLabel: t('filters.condition.hasDraftCapability.title'),
        },
      },
    ];
  }

  return (
    <AdvancedFilter
      inlineConditions={inlineConditions}
      popupConditions={popupConditions}
      onConditionChange={handleConditionChange}
      initParameters={initParameters}
      popupTooltip={popupTooltip}
    />
  );
}
