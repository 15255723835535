import React, { PropsWithChildren } from 'react';
import Stepper from '@material-ui/core/Stepper';
import LifeCycleStepConnector from './LifeCycleStepConnector';
import { withStyles } from '@material-ui/core/styles';

const StyledStepper = withStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiStep-root': {
      paddingLeft: 0,
    },
  },
})(Stepper);

const LifeCycleStepper = ({ children, activeStep }: PropsWithChildren<{ activeStep?: number }>) => (
  <StyledStepper alternativeLabel activeStep={activeStep} connector={<LifeCycleStepConnector />}>
    {children}
  </StyledStepper>
);

export default LifeCycleStepper;
