export const customIconsSet = {
  DOWNLOAD_ICON: { viewBox: '0 0 25 24', id: 'download-icon-002545' },
  UPLOAD_ICON: { viewBox: '0 0 25 24', id: 'upload-icon-002524' },
  ANGLE_WITH_CROSS: { viewBox: '0 0 22 19', id: 'angle-with-cross-002219' },
  QUESTION_SQUARE: { viewBox: '0 0 24 24', id: 'question-square-002424' },
  PAPER_CLIP: { viewBox: '0 0 24 24', id: 'paper-clip-002424' },
  PLUS_SQUARE: { viewBox: '0 0 24 24', id: 'plus-square-002424' },
  MINUS_SQUARE: { viewBox: '0 0 24 24', id: 'minus-square-002424' },
  RESET_ALL: { viewBox: '0 0 27 28', id: 'reset-all-002728' },
  PDF: { viewBox: '0 0 24 24', id: 'pdf-002424' },
  MAINTENANCE: { viewBox: '0 0 124 124', id: 'maintenance-00124124' },
  PLUS_SQUARE_GREY: { viewBox: '0 0 24 24', id: 'plus-square-grey-002424' },
  MINUS_SQUARE_GREY: { viewBox: '0 0 24 24', id: 'minus-square-grey-002424' },
  TRANSFER_ICON: { viewBox: '0 0 24 24', id: 'transfer-icon-002424' },
};
