import React from 'react';
import { DetailsItem } from '../../../partials';
import { Icon } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { VariantInfo } from '../types';
// @ts-ignore
import _var from '../../../../styles/_variables.scss';
import styled from 'styled-components';
import { memberRoles } from '../../../../helpers/constants';
import { WrapWithPublicIcon } from '../../../../shared-components/helper-components/WrapWithPublicIcon';

const Wrapper = styled.div`
  .variantName {
    text-align: center;
    min-width: 200px;
    max-width: 200px;
  }

  .rs-icon-caret-left,
  .rs-icon-caret-right {
    color: ${_var.primary};
    opacity: 1;

    &:hover {
      opacity: 0.8;
      transition: opacity 0.3s linear;
    }
  }

  .details-item-content {
    display: flex;
    align-items: center;
  }
`;

export const SwitchVariants = ({
  variants,
  setActiveVariant,
  activeVariant,
  authority,
}: {
  variants: VariantInfo[];
  setActiveVariant: (item: VariantInfo) => void;
  activeVariant: VariantInfo;
  authority: string;
}) => {
  const { t } = useTranslation();

  if (variants.length === 1) {
    return (
      <DetailsItem
        label={
          memberRoles.includes(authority) ? (
            <WrapWithPublicIcon content={t('applications.info.variantName')} />
          ) : (
            t('applications.info.variantName')
          )
        }
        value={activeVariant.name}
      />
    );
  }

  const currentIndex = variants.findIndex(item => item.id === activeVariant.id);
  const prevIndex = currentIndex - 1;
  const nextIndex = currentIndex + 1;

  return (
    <Wrapper>
      <DetailsItem label={<WrapWithPublicIcon content={t('applications.info.variantName')} />}>
        {prevIndex !== -1 && variants[prevIndex] ? (
          <Icon icon="caret-left" className="pointer" size="2x" onClick={() => setActiveVariant(variants[prevIndex])} />
        ) : null}
        <span className="mx-1 variantName">{activeVariant.name}</span>
        {variants[nextIndex] ? (
          <Icon
            icon="caret-right"
            className="pointer"
            size="2x"
            onClick={() => setActiveVariant(variants[nextIndex])}
          />
        ) : null}
        <span className="ml-1">{t('products.variantsLength', { length: variants.length })}</span>
      </DetailsItem>
    </Wrapper>
  );
};
