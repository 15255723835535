import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { ChangeOwningCompanySummaryRecord } from '../types';
import { reformatDate } from '../../../../../helpers';
import DataTable from '../../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../../shared-components/table/DataTableColumn';
import { SortingOptions, SortType } from '../../../../../components/pages/test-case/types';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const useStyles = makeStyles({
  transferNumber: {
    fontWeight: 'bold',
    color: _var.primary,
  },
  transferNumberWithUnderline: {
    fontWeight: 'bold',
    color: _var.primary,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  succeedStatus: {
    color: _var.black,
  },
  failedStatus: {
    color: _var.errorRed,
  },
});

export const ChangeOwningCompanySummaryTable = ({
  data,
  loading,
  onClickSummaryDetail,
  total,
  activePage,
  handleChangePage,
  sorting,
  handleChangeSorting,
}: {
  data: ChangeOwningCompanySummaryRecord[];
  loading: boolean;
  onClickSummaryDetail: (recordId: number, isSucceed: boolean) => void;
  total: number;
  activePage: number;
  handleChangePage: (newPage: number) => void;
  sorting: SortingOptions;
  handleChangeSorting: (sortColumn: string, sortType: SortType) => void;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <DataTable<ChangeOwningCompanySummaryRecord>
      data={data}
      loading={loading}
      pagination={true}
      total={total}
      activePage={activePage}
      setActivePage={handleChangePage}
      sorting={sorting}
      onChangeSorting={handleChangeSorting}
    >
      <DataTableColumn
        field="sourceCompanyName"
        label={t('admin.changeOwningCompany.summary.companyFrom')}
        width="27%"
        sortable
      />
      <DataTableColumn
        field="targetCompanyName"
        label={t('admin.changeOwningCompany.summary.companyTo')}
        width="27%"
        sortable
      />
      <DataTableColumn
        field="successCount"
        label={t('admin.changeOwningCompany.summary.transferredProducts')}
        width={150}
        wordWrap
      >
        {(row: ChangeOwningCompanySummaryRecord) => (
          <span
            data-test-id={`span.tranferred.${row.id}`}
            className={
              row.processed && row.successCount > 0 ? classes.transferNumberWithUnderline : classes.transferNumber
            }
            onClick={() => {
              if (row.processed && row.successCount > 0) onClickSummaryDetail(row.id, true);
            }}
          >
            {row.processed ? row.successCount : '-'}
          </span>
        )}
      </DataTableColumn>
      <DataTableColumn
        field="failedCount"
        label={t('admin.changeOwningCompany.summary.failedProducts')}
        width={150}
        wordWrap
      >
        {(row: ChangeOwningCompanySummaryRecord) => (
          <span
            data-test-id={`span.failed.${row.id}`}
            className={
              row.processed && row.failedCount > 0 ? classes.transferNumberWithUnderline : classes.transferNumber
            }
            onClick={() => {
              if (row.processed && row.failedCount > 0) onClickSummaryDetail(row.id, false);
            }}
          >
            {row.processed ? row.failedCount : '-'}
          </span>
        )}
      </DataTableColumn>
      <DataTableColumn field="status" label={t('admin.changeOwningCompany.summary.status')} width={150}>
        {(row: ChangeOwningCompanySummaryRecord) => (
          <span className={row.processed && row.failedCount > 0 ? classes.failedStatus : classes.succeedStatus}>
            {row.processed
              ? row.failedCount === 0
                ? t('admin.changeOwningCompany.summary.succeed')
                : t('admin.changeOwningCompany.summary.failed')
              : t('admin.changeOwningCompany.summary.processing')}
          </span>
        )}
      </DataTableColumn>
      <DataTableColumn field="date" label={t('admin.changeOwningCompany.summary.date')} width={120} sortable>
        {(row: ChangeOwningCompanySummaryRecord) => reformatDate(row.timestamp)}
      </DataTableColumn>
    </DataTable>
  );
};
