import React, { forwardRef, useState } from 'react';
import { CollapsableSection } from './CollapsableSection';
import { BasicSaveResponse, CollapsedSectionProps, OnChangeType, Prerequisites, UmbrellaInfo } from '../types';
import { ValidationError } from '../../../../helpers/types';
import { ViewPrerequisitesSection, EditPrerequisitesSection } from './prerequisites';
import { CertificationStatuses } from '../../../../helpers/constants/certification-statuses';
import { confirmAction, jsonCopy } from '../../../../helpers';
import { Confirm } from '../../../../helpers/confirmationPopup/Confirm';
import dot from 'dot-object';
import { formatErrors } from '../../../../helpers/request';
import { handleRequestSuccess } from '../../../../helpers/handle-request-success';
import { useToasts } from 'react-toast-notifications';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { updateCertificationPrerequisites } from '../../../../api/certification/partial-update/update-certification-prerequisites';
import { RootReducer } from '../../../../redux/rootReducer';
import {
  CertificationDefinition,
  SectionEditState,
  setLoading,
  setSectionEditState,
  setValidationErrors,
  updateCertificationProperty,
} from '../../../../redux/modules/certification';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { defaultPrerequisiteValue } from '../../../../redux/modules/certification/data';
import { saveData } from './helpers';
import { IsModifiedProps, useChangeDetection } from '../../../../helpers/hooks/useChangeDetection';
import { RoleTypeTitles } from '../../../../redux/modules/roles/types';

interface State {
  generalInfo: UmbrellaInfo;
  prerequisites: Prerequisites[];
  status?: CertificationStatuses;
  versionId: number;
  sectionsEditState: SectionEditState;
  save: boolean;
  id?: number;
}

const calculateHashValue = (values: Prerequisites[]) => {
  return JSON.stringify(
    jsonCopy(values)
      .map((item: Prerequisites) => ({
        id: item.certification.id ?? '',
        version: item.version ?? '',
        revision: item.revision ?? '',
      }))
      .sort((a, b) => a.id - b.id),
  );
};

type PrerequisiteSectionProps = CollapsedSectionProps & Partial<IsModifiedProps>;

export const PrerequisiteSection = forwardRef<HTMLDivElement, PrerequisiteSectionProps>(function PrerequisiteSection(
  { isView = false, setIsModified: setIsSectionModified }: PrerequisiteSectionProps,
  ref,
) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const {
    generalInfo,
    prerequisites,
    status = CertificationStatuses.DRAFT,
    versionId,
    sectionsEditState,
    save,
    id,
  } = useSelector<RootReducer, State>(
    ({ certificationReducer }) => ({
      generalInfo: certificationReducer.certification.generalInfo,
      prerequisites: certificationReducer.certification.prerequisites,
      status: certificationReducer.certification.status,
      versionId: certificationReducer.certification.versionId as number,
      sectionsEditState: certificationReducer.sectionsEditState,
      save: certificationReducer.loadingState.save,
      id: certificationReducer.certification.id,
    }),
    shallowEqual,
  );
  const [state, setState] = useState({ prerequisites });
  const [isModified, setIsModified] = useState(false);
  const certDefRoleId = generalInfo.role.id;

  const setIsModifiedState = (value: boolean) => {
    setIsModified(value);
    setIsSectionModified && setIsSectionModified(value);
  };

  const setChangeDetectionInitialValue = useChangeDetection(
    setIsModifiedState,
    calculateHashValue,
    state.prerequisites,
  );

  const resetForm = () => {
    setState({ prerequisites });
    setIsModifiedState(false);
    dispatch(setSectionEditState({ prerequisites: false }));
  };

  const onCancel = () => confirmAction(() => isModified, resetForm);

  const onSave = () => {
    const filteredPrereqs = state.prerequisites.filter(el => el.certification.id !== -1);
    dispatch(setLoading({ save: true }));
    return updateCertificationPrerequisites(versionId, filteredPrereqs)
      .then(
        ({
          data: { prerequisites, ...dataToUpdate },
        }: AxiosResponse<{ prerequisites: Prerequisites[] } & BasicSaveResponse>) => {
          saveData('prerequisites', prerequisites as Partial<CertificationDefinition>, 'version', dataToUpdate);
          setState({ prerequisites });
          handleRequestSuccess(t('certifications.notifications.saved'), addToast);
          setIsModifiedState(false);
          setChangeDetectionInitialValue(state.prerequisites);
        },
      )
      .catch((errors: AxiosError) => {
        handleRequestFail(errors, addToast);
        if (errors.response?.data.errors) {
          dispatch(setValidationErrors(formatErrors(errors.response?.data.errors as ValidationError[])));
        }
      });
  };

  const setEdit = () => {
    if (!state.prerequisites.length) {
      setState({ prerequisites: [defaultPrerequisiteValue] });
    }
    dispatch(setSectionEditState({ prerequisites: true }));
  };

  const handleChange = (value: OnChangeType, name: string) => {
    if (isView) {
      setState(prevState => {
        const newState = jsonCopy(prevState);
        dot.set(name, value, newState);
        return newState;
      });
    } else {
      dispatch(updateCertificationProperty(value, name));
    }
  };

  const onHandleChange = (value: OnChangeType, name: string) => {
    const selectedRole = value?.certification?.role.name;
    handleChange(value, name);
    if (selectedRole && certDefRoleId && selectedRole !== RoleTypeTitles[certDefRoleId]) {
      return Confirm({
        title: t('certifications.prerequisites.differentRolesNoteTitle'),
        message: t('certifications.prerequisites.differentRolesNote'),
        yesText: t('certifications.prerequisites.differentRolesNoteBtn'),
        cancelable: false,
        onAccept: () => handleChange(value, name),
      });
    }
  };

  return (
    <CollapsableSection
      title={t('certifications.prerequisites.title')}
      showEditIcon={isView && status === CertificationStatuses.DRAFT}
      isEdit={sectionsEditState.prerequisites}
      onCancel={onCancel}
      onSave={onSave}
      isSaving={save}
      ref={ref}
      onEditIconClick={setEdit}
    >
      {isView && !sectionsEditState.prerequisites ? (
        <ViewPrerequisitesSection data={prerequisites} />
      ) : (
        <EditPrerequisitesSection
          id={id}
          data={sectionsEditState.prerequisites ? state.prerequisites : prerequisites}
          onChange={onHandleChange}
        />
      )}
    </CollapsableSection>
  );
});
