import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, Table } from 'rsuite';

import {
  ApplicationFlowState,
  selectAllCertForLab,
  selectCertificationLabCheckbox,
  updateSelectedTestingLabs,
} from '../../../../../../redux/modules/application-flow';
import { RootReducer } from '../../../../../../redux/rootReducer';
import { defaultSelectedLabValue } from '../../../../../../redux/modules/application-flow/data';
import { Tooltip } from '../../../../../../shared-components/tooltip/Tooltip';
import { RsColumn, RsTable } from '../../../../../../shared-components/theme';
import { ReformattedCertTableRow } from '../CertificationsStep';
import { CertLabCheckbox } from './CertLabCheckbox';
import { LabItem } from './LabItem';
import { mergeCertWithLabData } from '../../../data/formatters';
import { LabsResponse } from '../../../../../../api/application/get-labs-by-app-id';
import { sortByProperty } from '../../../../../../helpers/sort-by-property';
import { jsonCopy } from '../../../../../../helpers';
import { ErrorObject } from '../../../../../../helpers/types';
import { SortingOptions, SortType } from '../../../../test-case/types';
import { CertificationWithLabData, COMPANY_SUB_TYPE, testingFieldTypes, TestingItem, TestingLab } from '../../../types';

const { Cell, HeaderCell } = Table;

const ErrorText = styled.small`
  position: absolute;
  top: 34px;
  left: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  &:hover {
    overflow: unset;
  }
`;

interface Props {
  labs: TestingLab[];
  certLabData?: LabsResponse[];
}

export const TestingLabsForm = ({ labs, certLabData = [] }: Props): React.ReactElement<string> => {
  const [sorting, setSorting] = useState<SortingOptions>({
    sortColumn: 'certification.name',
    sortType: 'asc',
  });
  const [loading, setLoading] = useState(true);

  const { errors, testingInfo, certifications, selectedTestingLabs = [] } = useSelector<
    RootReducer,
    ApplicationFlowState
  >(state => state.applicationFlow);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getHouseLab = (labs: TestingLab[]): TestingLab | null => {
    const houseLab: TestingLab | undefined = labs.find(lab =>
      [COMPANY_SUB_TYPE.MCTL, COMPANY_SUB_TYPE.MATL, COMPANY_SUB_TYPE.MRCL, COMPANY_SUB_TYPE.STL].includes(
        lab.companySubType as COMPANY_SUB_TYPE,
      ),
    );
    return houseLab || null;
  };

  useEffect(() => {
    const houseLab = getHouseLab(labs);
    const newSelectedTestingLabs = selectedTestingLabs.filter(
      lab =>
        ![COMPANY_SUB_TYPE.MCTL, COMPANY_SUB_TYPE.MATL, COMPANY_SUB_TYPE.MRCL, COMPANY_SUB_TYPE.STL].includes(
          lab.companySubType as COMPANY_SUB_TYPE,
        ),
    );
    // if labs that we have, have length 1, and we have one element with empty name
    // then we can assume that we don't have any labs (create new application)
    if (
      newSelectedTestingLabs.length === 0 ||
      (newSelectedTestingLabs.length === 1 && newSelectedTestingLabs.filter(lab => lab.name === '').length === 1)
    ) {
      const newValue = [{ ...houseLab }, { ...defaultSelectedLabValue }].filter(lab => Object.keys(lab).length);
      dispatch(updateSelectedTestingLabs({ selectedTestingLabs: newValue as TestingLab[] }));
    } else {
      const newValue = [{ ...houseLab }, ...newSelectedTestingLabs].filter(lab => Object.keys(lab).length);
      dispatch(updateSelectedTestingLabs({ selectedTestingLabs: newValue as TestingLab[] }));
    }
    setLoading(false);
  }, []);

  const certificationsWithVersions = useMemo<CertificationWithLabData[]>(
    () => mergeCertWithLabData(certifications, certLabData),
    [certifications, certLabData],
  );

  const sortedData = useMemo(() => jsonCopy(certificationsWithVersions).sort((a, b) => sortByProperty(a, b, sorting)), [
    certificationsWithVersions,
    sorting,
  ]);

  const handleSortChange = (sortColumn: string, sortType: SortType) => {
    setSorting({ sortColumn, sortType });
  };

  const getError = (versionId: number) => {
    if (testingInfo.testingException) {
      return '';
    }
    const errorPath = `testingInfo[${versionId}].testingDetails`;
    const conformanceErrorPath = `${errorPath}.conformance`;
    const interopErrorPath = `${errorPath}.interoperability`;
    // for testingDetails error
    if (errors[errorPath] || !!errors[conformanceErrorPath] || !!errors[interopErrorPath]) {
      const certificationByVersion = certificationsWithVersions.find(cert => cert.version.id === versionId);
      const certification = certificationByVersion?.certification;
      const requiredTesting = certificationByVersion?.requiredTesting;

      const requiredConformance = certification?.testingType.conformance && requiredTesting?.conformance;
      const requiredInteroperability = certification?.testingType.interoperability && requiredTesting?.interoperability;
      const testingDetails = testingInfo.testingLabs.find(el => el.version.id === versionId)?.testingDetails;

      const bothTestRequired =
        requiredConformance &&
        requiredInteroperability &&
        (!testingDetails || (!testingDetails.conformance && !testingDetails.interoperability));

      if (bothTestRequired) {
        return t('applications.labs.validation.bothTests', {
          first: t('applications.labs.conformance'),
          second: t('applications.labs.interoperability'),
        });
      }

      const conformanceTestRequired =
        certification?.testingType.conformance && !(testingDetails && testingDetails.conformance);
      if (conformanceTestRequired) {
        return t('applications.labs.validation.singleTest', {
          test: t('applications.labs.conformance'),
        });
      }

      const interoperabilityTestRequired =
        certification?.testingType.interoperability && !(testingDetails && testingDetails.interoperability);

      if (interoperabilityTestRequired) {
        return t('applications.labs.validation.singleTest', {
          test: t('applications.labs.interoperability'),
        });
      }
    }
    return '';
  };

  const formErrors = useMemo(() => {
    return certifications.reduce((accum, cert) => {
      accum[cert.version.id] = getError(cert.version.id);
      return accum;
    }, {} as ErrorObject);
  }, [testingInfo.testingLabs, errors]);

  const isLabSelectedForConformanceTesting = (labId: number | null): boolean => {
    return labId
      ? testingInfo.testingLabs.some(({ testingDetails }) => testingDetails.conformance?.id === labId)
      : false;
  };

  const isLabNotAuthorizedForTesting = (labId: number | null, field: testingFieldTypes): boolean => {
    const certsRequired = certificationsWithVersions.filter(item => item.requiredTesting[field]);
    const certs = certsRequired.filter(item => item.certification.testingType[field]);

    if (certs.length === 0 || !labId) {
      return false;
    }

    const hasAllowedTests = certs.some(({ laboratories }) => {
      const lab = laboratories.find(lab => lab.id === labId);
      return lab && lab.allowedTesting[field];
    });

    return !hasAllowedTests;
  };

  const areAllCertsPrequalified = (field: testingFieldTypes): boolean => {
    const certs = certificationsWithVersions.filter(item => item.certification.testingType[field]);
    return certs.length > 0 && certs.every(({ requiredTesting }) => !requiredTesting[field]);
  };

  const handleCertLabCheckBoxChange = (
    lab: TestingLab,
    certVersionId: number,
    checked: boolean,
    field: testingFieldTypes,
  ): void => {
    dispatch(
      selectCertificationLabCheckbox(checked ? { id: lab.id as number, name: lab.name } : null, certVersionId, field),
    );
  };

  const handleSelectAll = (lab: TestingItem, checked: boolean, field: testingFieldTypes) => {
    const allCertsArePrequalified = areAllCertsPrequalified(field);
    const certsToUpdate = certificationsWithVersions
      .filter(
        cert =>
          cert.certification.testingType[field] &&
          (cert.requiredTesting[field] || allCertsArePrequalified) &&
          cert.laboratories.some(labItem => labItem.id === lab.id && labItem.allowedTesting[field]),
      )
      .map(cert => cert.version.id);
    dispatch(selectAllCertForLab(lab, checked, field, certsToUpdate));
  };

  const isCheckboxChecked = (labId: number | null, certVersionId: number, field: testingFieldTypes): boolean => {
    // check if current lab assigned to certification (if checkbox checked)
    const checked = Boolean(
      testingInfo.testingLabs.find(
        ({ version, testingDetails }) => version.id === certVersionId && testingDetails[field]?.id === labId,
      ),
    );
    return checked;
  };

  const isAllCertCheckboxChecked = (labId: number | null, field: testingFieldTypes) => {
    if (testingInfo.testingLabs.length === 0) {
      return false;
    }

    const allCertsArePrequalified = areAllCertsPrequalified(field);

    if (
      labId &&
      certificationsWithVersions.some(
        cert =>
          cert.certification.testingType[field] &&
          (allCertsArePrequalified || cert.requiredTesting[field]) &&
          cert.laboratories.some(el => el.id === labId && el.allowedTesting[field]),
      )
    ) {
      return !certificationsWithVersions.some(
        cert =>
          cert.certification.testingType[field] &&
          (allCertsArePrequalified || cert.requiredTesting[field]) &&
          cert.laboratories.some(el => el.id === labId && el.allowedTesting[field]) &&
          !testingInfo.testingLabs.some(
            ({ version, testingDetails }) => version.id === cert.version.id && testingDetails[field]?.id === labId,
          ),
      );
    }
    return false;
  };

  const isTestingSelected = (certVersionId: number, field: testingFieldTypes): boolean => {
    return Boolean(
      testingInfo.testingLabs.some(
        ({ version, testingDetails }) => version.id === certVersionId && testingDetails[field] !== null,
      ),
    );
  };

  const getTestingInfo = (labId: number, field: testingFieldTypes) => {
    const isTestingNotRequested = !certificationsWithVersions.some(
      ({ certification }) => certification.testingType[field],
    );
    if (isTestingNotRequested) {
      return {
        isTestingNotRequested,
        isSelectAllDisabled: false,
      };
    }
    const requested = certificationsWithVersions.filter(({ certification }) => certification.testingType[field]);

    const isSelectAllDisabled = !requested[
      field === testingFieldTypes.interoperability ? 'some' : 'every'
    ](({ laboratories }) => laboratories.some(({ id, allowedTesting }) => id === labId && allowedTesting[field]));

    return {
      isTestingNotRequested,
      isSelectAllDisabled,
    };
  };

  const extendedList: CertificationWithLabData[] = [
    {
      certification: {
        id: -1,
        name: 'All Certifications',
        role: {
          id: -1,
          name: '',
        },
        testingType: {
          conformance: true,
          interoperability: true,
        },
        version: { id: -1 },
      },
      version: { id: -1, name: '' },
      requiredTesting: {
        conformance: true,
        interoperability: true,
      },
      laboratories: [],
    },
    ...sortedData,
  ];

  return (
    <Form>
      <RsTable
        className="is-lab-step"
        data={extendedList}
        headerHeight={90}
        rowHeight={rowData => {
          if (
            extendedList[extendedList.length - 1]?.certification.version.id ===
            (rowData as CertificationWithLabData)?.certification?.version.id
          ) {
            return 65;
          }
          return 51;
        }}
        autoHeight
        loading={loading}
        minHeight={100}
        rowClassName={rowData => {
          if (!rowData) {
            return '';
          }
          if (!rowData.requiredTesting.interoperability || !rowData.requiredTesting.conformance) {
            return 'is-precertified';
          }
          return rowData.isStriped ? 'is-stripe' : '';
        }}
        onSortColumn={handleSortChange}
        sortColumn={sorting.sortColumn}
        sortType={sorting.sortType}
      >
        <RsColumn align="left" fixed flexGrow={1} minWidth={300} sortable>
          <HeaderCell className="header-cell-group">
            <div className="header-cell-group-title" style={{ minHeight: '37px' }} />
            <div className="header-cell-group-subtitle" style={{ display: 'inline-flex', marginTop: '2.5rem' }}>
              <div style={{ padding: '.5rem 1rem' }}>{t('applications.labs.certifications')}</div>
            </div>
          </HeaderCell>
          <Cell dataKey="certification.name" style={{ padding: '1rem' }}>
            {(rowData: LabsResponse, rowIndex: number) => {
              const errorMessage = formErrors[rowData.certification.version.id];
              return (
                <>
                  {rowIndex === 0 ? <b>{rowData.certification.name}</b> : rowData.certification.name}
                  {errorMessage && <ErrorText className="text-danger">{errorMessage}</ErrorText>}
                </>
              );
            }}
          </Cell>
        </RsColumn>
        <RsColumn flexGrow={1} minWidth={180} sortable>
          <HeaderCell className="header-cell-group">
            <div className="header-cell-group-title" style={{ minHeight: '37px' }} />
            <div className="header-cell-group-subtitle" style={{ display: 'inline-flex', marginTop: '2.5rem' }}>
              <div style={{ padding: '.5rem 1rem' }}>{t('applications.certifications.role')}</div>
            </div>
          </HeaderCell>
          <Cell dataKey="certification.role.name" style={{ padding: '1rem' }}>
            {(row: ReformattedCertTableRow) => row.certification.role.name}
          </Cell>
        </RsColumn>
        {selectedTestingLabs.map((selectedLab, index) => {
          const isLabForConformance = isLabSelectedForConformanceTesting(selectedLab.id);
          return (
            <RsColumn align="center" colSpan={2} width={340} key={index}>
              <HeaderCell className="header-cell-group">
                <div className="header-cell-group-title">
                  <LabItem labs={labs} currentLab={selectedLab} index={index} />
                </div>
                <div className="header-cell-group-subtitle">
                  <span className="p-1">
                    {t('applications.labs.conformance')}
                    <Tooltip translationKey="tooltips.conformance" />
                  </span>
                  <span className="p-1">
                    {t('applications.labs.interoperability')}
                    <Tooltip translationKey="tooltips.interoperability" />
                  </span>
                </div>
              </HeaderCell>
              <Cell className="p-0">
                {(rowData: CertificationWithLabData, rowIndex: number) => {
                  if (rowIndex === 0 && selectedLab.id !== null) {
                    const conformanceTestingInfo = getTestingInfo(selectedLab.id, testingFieldTypes.conformance);
                    const interopTestingInfo = getTestingInfo(selectedLab.id, testingFieldTypes.interoperability);

                    return (
                      <div className="content-cell-group-sub-cell">
                        <div>
                          {conformanceTestingInfo.isTestingNotRequested ? null : (
                            <CertLabCheckbox
                              className="select-all-conformance"
                              disabled={conformanceTestingInfo.isSelectAllDisabled}
                              isPrequalified={areAllCertsPrequalified(testingFieldTypes.conformance)}
                              isNotAuthorized={isLabNotAuthorizedForTesting(
                                selectedLab.id,
                                testingFieldTypes.conformance,
                              )}
                              onChange={(value, checked) =>
                                handleSelectAll(selectedLab as TestingItem, checked, testingFieldTypes.conformance)
                              }
                              checked={isAllCertCheckboxChecked(selectedLab.id, testingFieldTypes.conformance)}
                            />
                          )}
                        </div>
                        <div>
                          {interopTestingInfo.isTestingNotRequested ? null : (
                            <CertLabCheckbox
                              className="select-all-interoperability"
                              disabled={interopTestingInfo.isSelectAllDisabled}
                              isPrequalified={areAllCertsPrequalified(testingFieldTypes.interoperability)}
                              isNotAuthorized={isLabNotAuthorizedForTesting(
                                selectedLab.id,
                                testingFieldTypes.interoperability,
                              )}
                              onChange={(value, checked) =>
                                handleSelectAll(selectedLab as TestingItem, checked, testingFieldTypes.interoperability)
                              }
                              checked={isAllCertCheckboxChecked(selectedLab.id, testingFieldTypes.interoperability)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  const existingLab = rowData.laboratories.find(lab => lab.id === selectedLab.id);
                  if (selectedLab.name !== '' && existingLab) {
                    const isInteropChecked = isCheckboxChecked(
                      selectedLab.id,
                      rowData.version.id,
                      testingFieldTypes.interoperability,
                    );
                    const isConformanceChecked = isCheckboxChecked(
                      selectedLab.id,
                      rowData.version.id,
                      testingFieldTypes.conformance,
                    );

                    const isInteropSelected = isTestingSelected(rowData.version.id, testingFieldTypes.interoperability);

                    return (
                      <div className="content-cell-group-sub-cell">
                        <div>
                          {rowData.certification.testingType.conformance ? (
                            <CertLabCheckbox
                              className="select-conformance"
                              hasErrors={
                                !isTestingSelected(rowData.version.id, testingFieldTypes.conformance) &&
                                Boolean(formErrors[rowData.certification.version.id])
                              }
                              disabled={!isLabForConformance || rowData.requiredTesting.conformance}
                              checked={isConformanceChecked}
                              isPrequalified={!rowData.requiredTesting.conformance}
                              isNotAuthorized={!existingLab.allowedTesting.conformance}
                              onChange={(value: string, checked: boolean) =>
                                handleCertLabCheckBoxChange(
                                  selectedLab,
                                  rowData.certification.version.id,
                                  checked,
                                  testingFieldTypes.conformance,
                                )
                              }
                            />
                          ) : null}
                        </div>
                        <div>
                          {rowData.certification.testingType.interoperability ? (
                            <CertLabCheckbox
                              className="select-interoperability"
                              hasErrors={!isInteropSelected && Boolean(formErrors[rowData.certification.version.id])}
                              disabled={!isInteropChecked && isInteropSelected}
                              isNotAuthorized={rowData.laboratories.some(
                                lab => lab.id === selectedLab.id && !lab.allowedTesting.interoperability,
                              )}
                              onChange={(value: string, checked: boolean) =>
                                handleCertLabCheckBoxChange(
                                  selectedLab,
                                  rowData.certification.version.id,
                                  checked,
                                  testingFieldTypes.interoperability,
                                )
                              }
                              checked={isInteropChecked}
                              isPrequalified={!rowData.requiredTesting.interoperability}
                            />
                          ) : null}
                        </div>
                      </div>
                    );
                  }
                  return <>{null}</>;
                }}
              </Cell>
            </RsColumn>
          );
        })}
      </RsTable>
    </Form>
  );
};
