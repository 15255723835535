import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'rsuite';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';
import Cookie from 'js-cookie';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getImpersonationCompanies, getImpersonationUsers, impersonate } from '../../../api/impersonate';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { loadUser } from '../../../redux/modules/user';
import { ImpersonateAutoCompleteItem, Role } from './types';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import HierarchicalDropdown, {
  HierarchicalDropdownProps,
} from '../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';
import { RsCenterModal } from '../../../shared-components/rsuite';

interface Props {
  isActive: boolean;
  hideModal: () => void;
  loadUser: () => void;
}

interface ImpersonateUserAutocompleteItem extends ImpersonateAutoCompleteItem {
  login: string;
}

const ImpersonateModalComponent = ({ isActive, hideModal, loadUser }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const history = useHistory();
  const roles = [
    { type: Role.MEMBER, name: 'Member' },
    { type: Role.IMPLEMENTER, name: 'Implementer' },
    { type: Role.LAB, name: 'ATL' },
  ];
  const [loadingData, setLoadingData] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [companies, setCompanies] = useState<ImpersonateAutoCompleteItem[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  const [users, setUsers] = useState<ImpersonateUserAutocompleteItem[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [isImpersonating, setIsImpersonating] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setSelectedRole(null);
      setSelectedCompany(null);
      setSelectedUser(null);
      setCompanies([]);
      setUsers([]);
    }
  }, [isActive]);

  useEffect(() => {
    if (selectedRole !== null) {
      setSelectedCompany(null);
      setSelectedUser(null);
      setCompanies([]);
      setUsers([]);
      setLoadingData(true);
      getImpersonationCompanies(selectedRole)
        .then((response: AxiosResponse<ImpersonateAutoCompleteItem[]>) => {
          setCompanies(response.data);
          setLoadingData(false);
        })
        .catch((error: AxiosError) => {
          handleRequestFail(error, addToast);
          setLoadingData(false);
        });
    }
  }, [selectedRole]);

  useEffect(() => {
    if (selectedCompany !== null) {
      setSelectedUser(null);
      setUsers([]);
      setLoadingData(true);
      getImpersonationUsers(selectedCompany)
        .then((response: AxiosResponse<ImpersonateUserAutocompleteItem[]>) => {
          setUsers(response.data);
          setLoadingData(false);
        })
        .catch((error: AxiosError) => {
          handleRequestFail(error, addToast);
          setLoadingData(false);
        });
    }
  }, [selectedCompany]);

  const impersonateUser = () => {
    setIsImpersonating(true);
    impersonate(selectedUser as string)
      .then((response: AxiosResponse<{ jwt: string }>) => {
        Cookie.set('access-token', response.data.jwt);
        setIsImpersonating(false);
        loadUser();
        history.push('/');
      })
      .catch((error: AxiosError) => {
        handleRequestFail(error, addToast);
        setIsImpersonating(false);
      });
  };

  const handleRoleChange = (value: string | null) => {
    setSelectedRole(value);
  };

  const onRoleClean = () => {
    handleRoleChange(null);
    setSelectedCompany(null);
    setSelectedUser(null);
  };

  const onCompanyClean = () => {
    setSelectedCompany(null);
    setSelectedUser(null);
  };

  const dropdownData: HierarchicalDropdownProps[] = [
    {
      data: roles,
      value: selectedRole,
      labelKey: 'name',
      valueKey: 'type',
      placeholder: 'Choose role',
      onChange: handleRoleChange,
      onClean: onRoleClean,
      name: 'role',
      hideSearch: true,
      hideClose: true,
    },
    {
      data: companies,
      value: selectedCompany,
      labelKey: 'name',
      valueKey: 'id',
      placeholder: 'Choose company',
      onChange: setSelectedCompany,
      onClean: onCompanyClean,
      name: 'company',
    },
    {
      data: users,
      value: selectedUser,
      labelKey: 'name',
      valueKey: 'login',
      placeholder: 'Choose user',
      onChange: setSelectedUser,
      onClean: () => setSelectedUser(null),
      name: 'username',
    },
  ];

  return (
    <RsCenterModal backdrop show={isActive} onHide={hideModal}>
      <Modal.Header>
        <Modal.Title>Impersonation Mode</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingBottom: 0, marginBottom: 10 }}>
        {loadingData ? <CircularLoader backdrop content={t('common.placeholders.loadingData')} /> : null}
        <HierarchicalDropdown dropdownData={dropdownData} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal} appearance="link">
          {t('common.navigation.cancel')}
        </Button>
        <Button
          appearance="primary"
          onClick={impersonateUser}
          disabled={!selectedUser}
          loading={isImpersonating}
          id="impersonate"
        >
          {t('auth.impersonate')}
        </Button>
      </Modal.Footer>
    </RsCenterModal>
  );
};

export const ImpersonateModal = connect(null, { loadUser })(ImpersonateModalComponent);
