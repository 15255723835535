import * as actionTypes from './actionTypes';
import { Role } from './types';

export function rolesLoaded(roles: Role[]) {
  return {
    type: actionTypes.ROLES_LOADED,
    payload: roles,
  };
}
export function loadRoles() {
  return {
    type: actionTypes.LOAD_ROLES,
  };
}
