import React, { useMemo } from 'react';
import { GenericNodeType, GroupNodeType, NodeTypes, SingleNodeType } from './types';
import styled from 'styled-components';

const Bold = styled.b`
  font-size: 18px;
`;

const getLabelElements = (node: Partial<GenericNodeType>, parentKey = ''): JSX.Element[] => {
  if (node['@type'] === NodeTypes.GROUP) {
    const items: JSX.Element[] = [];

    (node as GroupNodeType).elements.forEach((element, index) => {
      const isGroup = element['@type'] === NodeTypes.GROUP;
      if (element.negation) {
        items.push(<b key={`${parentKey}.not.${index}`}>NOT </b>);
      }

      if (isGroup) {
        items.push(<Bold key={`${parentKey}.brackets-open.${index}`}>(</Bold>);
      }

      items.push(...getLabelElements(element, `${parentKey}.${index}`));

      if (isGroup) {
        items.push(<Bold key={`${parentKey}.brackets-close.${index}`}>)</Bold>);
      }

      if (index < (node as GroupNodeType).elements.length - 1) {
        items.push(<b key={`${parentKey}.operator.${index}`}> {(node as GroupNodeType).operator} </b>);
      }
    });

    return items;
  } else {
    return [<span key={`${parentKey}.0`}>{(node as SingleNodeType).certification?.name}</span>];
  }
};

export const ConditionLabel = ({ value }: { value: Partial<GenericNodeType> }) => {
  const label = useMemo(() => getLabelElements(value), [value]);
  return <div>{label}</div>;
};
