import axios from '../../helpers/axios-setup';
import { AppCertVersion, BasicAppCertification, AppCertPrereqs } from '../../redux/modules/application-flow';

export interface CertificationVersion {
  version: AppCertVersion;
  prerequisites: AppCertPrereqs[];
  prequalified?: boolean;
  previouslySelected?: boolean;
  canProceedWithSelection?: boolean;
}

export interface GetCertificationsByCC {
  certification: BasicAppCertification;
  versions: CertificationVersion[];
}

export const getCertificationsByAppId = (appId: number) =>
  axios.get(`/applications/builder/details/certifications/${appId}`);
