/* eslint-disable  @typescript-eslint/no-explicit-any */
import { mergeObjects } from '../';
import dot from 'dot-object';

export const create = (formId: string, data: any) => {
  sessionStorage.setItem(String(formId), JSON.stringify(data));
  return Promise.resolve();
};

export const update = (formId: string, data: any) => {
  const existingData = sessionStorage.getItem(formId);
  if (!existingData) {
    console.error(`No data found for ${formId}`);
  }

  dot.object(data);
  const storedData = existingData ? JSON.parse(existingData) : {};
  sessionStorage.setItem(formId, JSON.stringify(mergeObjects(storedData, data)));
  return Promise.resolve();
};

export const reset = (formId: string, data: any) => {
  sessionStorage.setItem(formId, JSON.stringify(data));
  return Promise.resolve();
};

export const clear = (formId: string) => {
  sessionStorage.removeItem(formId);
  return Promise.resolve();
};

export const get = (formId: string) => {
  const data = sessionStorage.getItem(formId);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};
