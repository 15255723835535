import React, { ReactNode } from 'react';
import { ErrorObject } from '../../helpers/types';
import { withLabel, WrappedInputProps } from './withLabel';
import { CheckPicker } from 'rsuite';
import { CheckPickerProps } from 'rsuite/lib/CheckPicker';
import { extractError } from './utils';

interface Props extends CheckPickerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderMenuItem?: (label: ReactNode, item: any) => ReactNode | JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (checked: any, item: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledItemValues?: any[];
}

const Field = ({ name, placeholder, value, onChange, ...props }: Props) => {
  return <CheckPicker placeholder={placeholder} name={name} value={value} onChange={onChange} {...props} />;
};
const MemorizedField = React.memo(withLabel<Props>(Field), (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.disabledItemValues) === JSON.stringify(nextProps.disabledItemValues) &&
    prevProps.data === nextProps.data &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error
  );
});

interface LabeledFieldProps extends Props {
  errors?: ErrorObject;
}
export const LabeledCheckPicker = ({ errors, name, ...props }: WrappedInputProps<LabeledFieldProps>) => {
  // @ts-ignore
  return <MemorizedField name={name} error={extractError(errors, name)} {...props} />;
};
