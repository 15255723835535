import { CERTIFY_PRODUCT_COMPANY, CompanyContactInfo } from '../../../../../redux/modules/application-flow';

export const isProductOfSolutionProvider = (application: {
  companyContactInfo: CompanyContactInfo;
  certifyProductCompany: CERTIFY_PRODUCT_COMPANY;
}) => {
  return !!(
    application.companyContactInfo.creatingCompany?.solutionProvider &&
    (application.certifyProductCompany === CERTIFY_PRODUCT_COMPANY.MY_COMPANY ||
      !!application.companyContactInfo.owningCompany?.solutionProvider)
  );
};
