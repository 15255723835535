import { AppFlows, ApplicationFlowState } from '../redux/modules/application-flow';
import { CertificationStatuses } from './constants/certification-statuses';

export const getApplicationLastStep = (reduxData: ApplicationFlowState) => {
  if ([AppFlows.NEW, AppFlows.VARIANT].includes(reduxData['@type'])) {
    // if there are any certifications that are not in published state we show the cert step so that
    // user can make some adjustments if necessary.
    if (reduxData.certifications.some(cert => cert.version.status !== CertificationStatuses.PUBLISHED)) {
      return 3;
    }

    const hasStep5Data =
      Boolean(reduxData.testingInfo.testingException) ||
      reduxData.testingInfo.testingLabs.length > 0 ||
      (reduxData.selectedTestingLabs || []).filter(el => el.id !== null).length > 0;
    if (hasStep5Data) {
      return 5;
    }

    const hasStep4Data = reduxData.certifications.some(
      cert => cert.capabilities?.length && cert.capabilities.length > 0,
    );
    if (hasStep4Data) {
      return 4;
    }

    const hasStep3Data = reduxData.certifications.length > 0;
    if (hasStep3Data) {
      return 3;
    }

    const { componentCombination } = reduxData;
    if (!componentCombination) {
      return 1;
    }
    // has home design
    if (componentCombination.componentCombination?.homeDesign !== null) {
      return 2;
    }
    const hasStep2OtherRoles =
      componentCombination &&
      componentCombination.componentCombination &&
      Object.values(componentCombination.componentCombination).some(item => Array.isArray(item) && item.length > 0);
    if (hasStep2OtherRoles) {
      return 2;
    }
    return 1;
  }
  return 1;
};
