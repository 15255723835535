import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { AxiosError, AxiosResponse } from 'axios';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

import { DerivativeApplicationFlowPage } from './DerivativeApplicationFlowPage';
import { NewApplicationFlowPage } from './NewApplicationFlowPage';
import { VariantApplicationFlowPage } from './VariantApplicationFlowPage';
import {
  AppFlows,
  AppFlowSteps,
  ApplicationFlowState,
  editApplicationLoaded,
  RESTORE_APPLICATION_DATA,
  setApplicationId,
  setAutoValidateCapability,
  setValidationState,
  StepsValidation,
  updateApplicationProperty,
  updateValidationErrors,
  ComponentCombinationSource,
} from '../../../redux/modules/application-flow';
import {
  AdditionalInfoType,
  CreateApplicationParams,
  createNewApplication,
  getSingleApplication,
  submitApplicationToTesting,
} from '../../../api/application';
import { getApplicationLastStep } from '../../../helpers/get-application-last-step';
import { create } from '../../../helpers/storage/form-data';
import { useChangeWrapper } from '../../../helpers/storage/formik-change-wrapper';
import { StepPageProps } from './types';
import { getStoredSummaryUrl, mergeObjects, prepareYupModel } from '../../../helpers';
import { ErrorObject, IterableObject, ValidationErrorResponse } from '../../../helpers/types';
import { applicationStatuses } from '../../../helpers/constants';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { formatErrors } from '../../../helpers/request';
import {
  applicationLegacyScheme,
  CWGRFScheme,
  productScheme,
  testingExplanationScheme,
  coreCCExplanationScheme,
  wifiComponentValidationScheme,
} from './data/validation-schemes';
import { Confirm } from '../../../helpers/confirmationPopup/Confirm';
import { ComponentCombination, ProductInfoStep, WiFiComponents } from '../../../api/application/types';
import { RootReducer } from '../../../redux/rootReducer';
import { reformatCC } from './data/formatters';
import { saveProductInfo } from '../../../api/application/partial-update/save-product-info';
import { saveCC } from '../../../api/application/partial-update/save-cc';
import { saveCertifications } from '../../../api/application/partial-update/save-certifications';
import { saveCapabilities } from '../../../api/application/partial-update/save-capabilities';
import { saveTestingLabs } from '../../../api/application/partial-update/save-testing-labs';
import { saveCoreCcException } from '../../../api/application/partial-update/save-core-cc-exception';
import {
  getUniqueCapabilities,
  isParentPresent,
  validateTestingInfo,
} from '../../../redux/modules/application-flow/helpers';
import { IsModifiedProps } from '../../../helpers/hooks/useChangeDetection';
import {
  selectComponentCombinationVariantId,
  selectFeesResponse,
  selectRequiresPurchaseOrder,
  selectsShowInvoiceToOptions,
} from '../../../redux/modules/application-flow/selectors';
import { store } from '../../../redux/applicationStore';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import { isSomeCapabilityValidateError } from './partials/new-application-flow-steps/partials/capabilities-step/helpers';
import { initialState, MemberClearData } from '../../../redux/modules/application-flow/data';
import { showAddNoteModal, toggleUpdateNotes } from '../../../redux/modules/modals';
import { NoteActionTypes } from '../../../redux/modules/modals/constants';
import { getNotesByAppId } from '../../../api/notes';
import { Note } from '../../partials/notes/types';

enum SubmitType {
  save = 'SAVE',
  update = 'UPDATE',
  submit = 'SUBMIT',
}

export const ApplicationFlowComponent = ({
  match,
  history,
  location,
}: RouteComponentProps<{ formId: string; action: 'new' | 'edit' | 'derivative' }>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const stepParam = new URLSearchParams(location.search).get('step');
  const [step, setStep] = useState(stepParam ? Number(stepParam) : 1);
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState<IterableObject<string>>({});
  const [submitting, setSubmittingState] = useState(false);
  const [saving, setSavingState] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [step2Saved, setStep2Saved] = useState(false);

  const productType = useSelector<{ applicationFlow: ApplicationFlowState }, string>(state =>
    state.applicationFlow['@type'].toLowerCase(),
  );
  const reduxData = useSelector<RootReducer, ApplicationFlowState>(state => state.applicationFlow);
  const [initialValues, setInitialValues] = useState<ComponentCombination>(reduxData.compareCCInfo.initialCC);
  const componentCombinationVariantId = useSelector(selectComponentCombinationVariantId);

  const successMessageKeys = {
    [SubmitType.save]: 'applications.notifications.saved',
    [SubmitType.update]: 'applications.notifications.saved',
    [SubmitType.submit]: 'applications.notifications.submitted',
  };

  const getApplicationTitles = () => {
    if (productType === 'derivative') {
      return [t('applications.steps.1'), t('applications.steps.6'), t('applications.steps.7')];
    }
    return Array(7)
      .fill(null)
      .map((el, index) => t(`applications.steps.${index + 1}`));
  };

  const stepTitles = getApplicationTitles();

  const getTabAfterSubmit = () => {
    if ([AppFlows.VARIANT, AppFlows.NEW].includes(reduxData['@type'])) {
      if (
        reduxData.testingInfo.testingLabs.some(
          el => el.testingDetails.interoperability !== null || el.testingDetails.conformance !== null,
        )
      ) {
        return applicationStatuses.inTesting;
      }
      return applicationStatuses.pendingApproval;
    }
    return applicationStatuses.pendingApproval;
  };

  const getUrlFunctionsByStatus = {
    [SubmitType.save]: () => '/application',
    [SubmitType.update]: () => getStoredSummaryUrl('application'),
    [SubmitType.submit]: () => `/application?tab=${getTabAfterSubmit()}`,
  };

  const { clearForm, getForm } = useChangeWrapper(match.params.formId);
  const changeStep = (stepNumber: number, pathname: string = location.pathname) => {
    window.scrollTo(0, 0);
    setStep(stepNumber);
    history.push({
      pathname,
      search: `?step=${stepNumber}`,
    });
  };

  const handleSuccess = (submitType: SubmitType) => {
    addToast(t(successMessageKeys[submitType]), {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
  };

  const makeSaveSubmitRequest = () => {
    // reduxData.id can not be null on this stage
    setSubmittingState(true);
    return submitApplicationToTesting(reduxData.id as number, {
      ...reduxData.additionalInfo,
      '@type': reduxData.additionalInfo.payer?.id ? AdditionalInfoType.WITH_PAYER : AdditionalInfoType.SIMPLE,
    })
      .then(() => {
        handleSuccess(SubmitType.submit);
        setSubmittingState(false);
        history.push(getUrlFunctionsByStatus[SubmitType.submit]());
        clearForm();
        dispatch({ type: RESTORE_APPLICATION_DATA });
      })
      .catch((error: AxiosError<ValidationErrorResponse>) => {
        const data = error?.response?.data;
        if (data?.messageKey.includes('ValidationError') && data?.errors) {
          const formatted = formatErrors(data.errors);
          dispatch(updateValidationErrors(formatted));
        }
        handleRequestFail(error, addToast);
        setSubmittingState(false);
      });
  };

  const getStepKey = (step: number): keyof StepsValidation => {
    if (reduxData['@type'] === AppFlows.DERIVATIVE) {
      switch (step) {
        case 1:
          return AppFlowSteps.PRODUCT;
        case 2:
          return AppFlowSteps.REVIEW;
        case 3:
          return AppFlowSteps.PAYMENT;
      }
    }
    switch (step) {
      case 1:
        return AppFlowSteps.PRODUCT;
      case 2:
        return AppFlowSteps.CC;
      case 3:
        return AppFlowSteps.CERTIFICATIONS;
      case 4:
        return AppFlowSteps.CAPABILITIES;
      case 5:
        return AppFlowSteps.LABS;
      case 6:
        return AppFlowSteps.REVIEW;
      case 7:
        return AppFlowSteps.PAYMENT;
    }
    return AppFlowSteps.PAYMENT;
  };

  const getValidationSchemeAndData = (): {
    scheme: Schema<Partial<ApplicationFlowState>> | null;
    data: Partial<ApplicationFlowState>;
  } => {
    switch (step) {
      case 1:
        return {
          scheme: Yup.object().shape({ productInfo: productScheme(reduxData) }),
          data: { productInfo: reduxData.productInfo },
        };
      case 2:
        if (reduxData['@type'] === AppFlows.DERIVATIVE) {
          return {
            scheme: null,
            data: reduxData,
          };
        }
        const data: WiFiComponents = {
          ...(reduxData.componentCombination.componentCombination || {}),
          homeDesign: reduxData.componentCombination.componentCombination?.homeDesign || null,
        } as WiFiComponents;
        return {
          scheme: [AppFlows.NEW, AppFlows.VARIANT].includes(reduxData['@type'])
            ? Yup.object().shape({
                componentCombination: Yup.object().shape({ componentCombination: wifiComponentValidationScheme }),
              })
            : null,
          data: {
            componentCombination: {
              componentCombination: data,
            },
          },
        };
      case 3:
        if (reduxData['@type'] === AppFlows.DERIVATIVE) {
          const isPORequired = selectRequiresPurchaseOrder(store.getState());
          const isInvoiceToRequired = selectsShowInvoiceToOptions(store.getState());
          return {
            scheme: Yup.object().shape({
              legacy: applicationLegacyScheme,
              additionalInfo: CWGRFScheme(isPORequired, isInvoiceToRequired),
            }),
            data: {
              legacy: reduxData.legacy,
              additionalInfo: reduxData.additionalInfo,
            },
          };
        }
        return {
          scheme: null,
          data: reduxData,
        };
      case 5:
        if (reduxData.componentCombination.componentCombinationVariantSource?.coreCCExceptionRequested) {
          return {
            scheme: Yup.object().shape({
              testingInfo: Yup.object().shape({
                testingException: testingExplanationScheme,
                coreCCException: coreCCExplanationScheme,
                testingLabs: Yup.array(),
              }),
            }),
            data: {
              testingInfo: reduxData.testingInfo,
            },
          };
        } else {
          return {
            scheme: Yup.object().shape({
              testingInfo: Yup.object().shape({
                testingException: testingExplanationScheme,
                testingLabs: Yup.array(),
              }),
            }),
            data: {
              testingInfo: reduxData.testingInfo,
            },
          };
        }
      case 7:
        const isInvoiceToRequired = selectsShowInvoiceToOptions(store.getState());
        const feesResponse = selectFeesResponse(store.getState());
        const feesNotEmpty = !!feesResponse.length;
        return {
          scheme: Yup.object().shape({
            legacy: applicationLegacyScheme,
            additionalInfo: CWGRFScheme(false, isInvoiceToRequired && feesNotEmpty),
          }),
          data: {
            legacy: reduxData.legacy,
            additionalInfo: reduxData.additionalInfo,
          },
        };
      default:
        return {
          scheme: null,
          data: reduxData,
        };
    }
  };

  const validateForm = () => {
    let additionalErrors: ErrorObject = {};
    if (step === 4) {
      if (isSomeCapabilityValidateError(reduxData.certifications, reduxData.capabilitisByCC)) {
        dispatch(setAutoValidateCapability(true));
        return Promise.reject();
      }
    }
    if (step === 5) {
      additionalErrors = validateTestingInfo(
        reduxData.certificationsLabs,
        reduxData.certifications,
        reduxData.testingInfo,
      );
    }
    const { scheme, data } = getValidationSchemeAndData();
    if (scheme !== null) {
      const errors = prepareYupModel(scheme).checkFormatted(data);
      if (errors !== null || Object.keys(additionalErrors).some(key => additionalErrors[key] !== '')) {
        if (errors === null) {
          dispatch(updateValidationErrors({ ...additionalErrors }));
        } else {
          dispatch(updateValidationErrors({ ...(errors as ErrorObject), ...additionalErrors }));
        }
        return Promise.reject();
      }
    }
    return Promise.resolve();
  };

  const isStepDataChanged = (flow: AppFlows) => (flow === AppFlows.DERIVATIVE && step === 3 ? false : isModified);

  const showPreviousConfirmation = () => {
    Confirm({
      onAccept: () => changeStep(step - 1),
      title: t('common.placeholders.areYouSure'),
      message: t('applications.wizardDataChangedMessage'),
    });
  };

  const catchPromiseError = (error: AxiosError) => {
    handleRequestFail(error, addToast);
    setSavingState(false);
    throw error;
  };

  const getProductPathAndLoadedFrom = (formId: number) => {
    getSingleApplication(formId).then((response: AxiosResponse<ApplicationFlowState>) => {
      const { data } = response;
      if (data.status === applicationStatuses.draft || data.status === applicationStatuses.needsAttention) {
        if (data.productInfo?.cid) {
          dispatch(
            updateApplicationProperty(data.productInfo.cid, 'productInfo.cid', MemberClearData.NONE, null, true),
          );
        }
        if (data.componentCombination.componentCombinationVariantSource?.productType) {
          dispatch(
            updateApplicationProperty(
              data.componentCombination.componentCombinationVariantSource,
              'componentCombination.componentCombinationVariantSource',
              MemberClearData.NONE,
              null,
              true,
            ),
          );
        }
        if (data.productInfo.modelVariants[0]?.productType) {
          dispatch(
            updateApplicationProperty(
              data.productInfo.modelVariants[0],
              'productInfo.modelVariants[0]',
              MemberClearData.NONE,
              null,
              true,
            ),
          );
        }
      }
    });
  };

  const saveDataByStep = async (cc?: ComponentCombination) => {
    const stepKey = getStepKey(step);
    if (!reduxData.id) {
      return createNewApplication({
        '@type': reduxData['@type'],
        companyContactInfo: {
          ...reduxData.companyContactInfo,
          applicationOwner: {
            '@type': reduxData.certifyProductCompany,
            owningCompany: reduxData.companyContactInfo.owningCompany || null,
          },
        },
        productInfo: reduxData.productInfo,
        majorEvents: reduxData.majorEvents,
      })
        .then(({ data }: AxiosResponse<CreateApplicationParams>) => {
          dispatch(editApplicationLoaded(mergeObjects(reduxData, data)));
          dispatch(setValidationState({ [stepKey]: true }));
          dispatch(setApplicationId(data.id as number));
          handleSuccess(SubmitType.save);
          setSavingState(false);
          clearForm();
          getProductPathAndLoadedFrom(data.id as number);
          return data;
        })
        .catch(error => {
          handleRequestFail(error, addToast);
          setSavingState(false);
        });
    }
    switch (step) {
      case 2:
        const data = cc || reformatCC(reduxData.componentCombination.componentCombination);
        // save CC only
        return saveCC(reduxData.id, data, componentCombinationVariantId)
          .then(({ data }: AxiosResponse<ComponentCombination>) => {
            const applicationData = mergeObjects(reduxData, data);
            const formId = applicationData.id;
            getProductPathAndLoadedFrom(formId);
            handleSuccess(SubmitType.save);
            dispatch(setValidationState({ [stepKey]: true }));
            dispatch(
              updateApplicationProperty(
                reformatCC(data),
                'componentCombination.componentCombination',
                MemberClearData.NONE,
                null,
                true,
              ),
            );
            setSavingState(false);
            setStep2Saved(true);
          })
          .catch(catchPromiseError);
      case 3:
        if (reduxData['@type'] === AppFlows.DERIVATIVE) {
          return makeSaveSubmitRequest();
        }
        // save Certifications only
        return saveCertifications(reduxData.id, reduxData.certifications)
          .then(() => {
            dispatch(setValidationState({ [stepKey]: true }));
            handleSuccess(SubmitType.save);
            setSavingState(false);
          })
          .catch(catchPromiseError);
      case 4:
        // save Capabilities only
        const filtered = reduxData.certifications.map(item => ({
          ...item,
          capabilities: getUniqueCapabilities(item.capabilities.filter(el => isParentPresent(el, item.capabilities))),
        }));
        return saveCapabilities(reduxData.id, filtered)
          .then(() => {
            dispatch(setValidationState({ [stepKey]: true }));
            handleSuccess(SubmitType.save);
            setSavingState(false);
          })
          .catch(catchPromiseError);
      case 5:
        // save Testing Details only
        if (reduxData.testingInfo.coreCCException) {
          const coreCCExceptionInfo = reduxData.testingInfo.coreCCException
            ? { coreCCException: reduxData.testingInfo.coreCCException }
            : { coreCCException: null };
          try {
            await saveCoreCcException(reduxData.id, coreCCExceptionInfo);
            return saveTestingLabs(reduxData.id, reduxData.testingInfo)
              .then(() => {
                dispatch(setValidationState({ [stepKey]: true }));
                handleSuccess(SubmitType.save);
                setSavingState(false);
              })
              .catch(catchPromiseError);
          } catch (error) {
            catchPromiseError(error as AxiosError);
          }
        } else {
          return saveTestingLabs(reduxData.id, reduxData.testingInfo)
            .then(() => {
              dispatch(setValidationState({ [stepKey]: true }));
              handleSuccess(SubmitType.save);
              setSavingState(false);
            })
            .catch(catchPromiseError);
        }
      case 6:
        return new Promise(resolve => {
          setSavingState(false);
          resolve(null);
        });
      case 7:
        return makeSaveSubmitRequest();
      default:
        // save Product Info
        return saveProductInfo(reduxData.id, reduxData.productInfo)
          .then(({ data }: AxiosResponse<ProductInfoStep>) => {
            dispatch(setValidationState({ [stepKey]: true }));
            dispatch(updateApplicationProperty(data, 'productInfo'));
            setSavingState(false);
          })
          .catch(catchPromiseError);
    }
  };

  const handlePrevious = () => {
    if (step === 5) {
      dispatch(setAutoValidateCapability(false));
    }
    if (step === 7) {
      return showPreviousConfirmation();
    }
    if (reduxData.validated[getStepKey(step)] || !isStepDataChanged(reduxData['@type'])) {
      return changeStep(step - 1);
    }
    return showPreviousConfirmation();
  };

  const handleNext = () => {
    if (step === 6 && isStepDataChanged(reduxData['@type'])) {
      return;
    }
    const key = getStepKey(step);
    const isLastStep = reduxData['@type'] === AppFlows.DERIVATIVE ? step === 3 : step === 7;
    // For Derivative, handle the next step if it is not the last step
    if (reduxData['@type'] === AppFlows.DERIVATIVE && step === 2 && !isLastStep) {
      return changeStep(step + 1);
    }
    if (
      reduxData.validated[key] &&
      (step === 2 ? Boolean(reformatCC(reduxData.componentCombination.componentCombination)) : true) &&
      !isLastStep
    ) {
      return changeStep(step + 1);
    }
    setSavingState(true);

    validateForm()
      .then(() => {
        return saveDataByStep().then(data => {
          if (!isLastStep) {
            changeStep(
              step + 1,
              !reduxData.id ? `/application/${(data as CreateApplicationParams).id}/edit` : undefined,
            );
          }
        });
      })
      .catch(() => setSavingState(false));
  };

  // this function wrap handleNext() with note submit function
  const onNextHandler = () => {
    if (
      step === 2 &&
      reduxData.componentCombination['@type'] === ComponentCombinationSource.LOADED &&
      JSON.stringify(reformatCC(initialValues, true)) !==
        JSON.stringify(reformatCC(reduxData.componentCombination.componentCombination, true))
    ) {
      dispatch(
        showAddNoteModal(
          {
            appId: reduxData.id,
            onSuccess: () => {
              dispatch(toggleUpdateNotes(true));
              setInitialValues(reduxData.componentCombination.componentCombination);
              handleNext && handleNext();
              getNotesByAppId(reduxData.id).then((response: AxiosResponse<Note[]>) => {
                dispatch(
                  updateApplicationProperty(
                    response.data[0].text,
                    'componentChangeNote',
                    MemberClearData.NONE,
                    null,
                    true,
                  ),
                );
              });
            },
          },
          NoteActionTypes.CHANGE_CC,
        ),
      );
      return;
    }
    setInitialValues(reduxData.componentCombination.componentCombination);
    handleNext();
  };

  const getValidationLastStep = (validated: StepsValidation) => {
    const { product, cc, certifications, capabilities, labs } = validated;
    if (!product) {
      return 1;
    }
    if (!cc) {
      return 2;
    }
    if (!certifications) {
      return 3;
    }
    if (!capabilities) {
      return 4;
    }
    if (!labs) {
      return 5;
    }
    return 6;
  };

  const arrangeApplicationData = (applicationData: ApplicationFlowState, formId: number) => {
    const dataWithFormId = { ...applicationData, formId };
    const lastStep = getApplicationLastStep(applicationData);
    const validated: StepsValidation = {
      product: false,
      cc: false,
      certifications: false,
      capabilities: false,
      labs: false,
      payment: false,
      review: false,
    };
    for (let i = 1; i <= lastStep; i++) {
      validated[getStepKey(i)] = true;
    }

    dataWithFormId.certifications.forEach(cert => {
      cert.capabilities.forEach(capability => {
        capability.id = capability.version?.id as number;
      });
    });

    const application = mergeObjects(dataWithFormId, { validated });
    create(String(applicationData.id), application);
    dispatch(editApplicationLoaded(application));
    if (!stepParam) {
      changeStep(lastStep);
    }
    setLoading(false);
  };

  useEffect(() => {
    const savedForm = getForm();
    const formId = Number(match.params.formId);
    if (savedForm?.formId) {
      dispatch(editApplicationLoaded(savedForm));
      const lastValidStep = getValidationLastStep(savedForm.validated);
      if (lastValidStep !== Number(stepParam)) {
        changeStep(lastValidStep);
      }
      setLoading(false);
    } else if (!Number.isNaN(formId)) {
      dispatch({ type: RESTORE_APPLICATION_DATA });
      getSingleApplication(formId)
        .then((response: AxiosResponse<ApplicationFlowState>) => {
          const applicationData = response.data;
          if (
            applicationData.status === applicationStatuses.draft ||
            applicationData.status === applicationStatuses.needsAttention
          ) {
            arrangeApplicationData(applicationData, formId);
          } else {
            addToast(t('common.access.notAllowed'), {
              appearance: 'warning',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
            history.push(getStoredSummaryUrl('application'));
          }
        })
        .catch((error: AxiosError) => {
          handleRequestFail(error, addToast);
          history.push(getStoredSummaryUrl('application'));
          setLoading(false);
          throw error;
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!reduxData.acknowledged && !loading && (reduxData['@type'] === AppFlows.DERIVATIVE ? step !== 2 : step !== 6)) {
      addToast(t('common.access.notAllowed'), {
        appearance: 'warning',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      history.push(getStoredSummaryUrl('application'));
    }
  }, [loading, submitting]);

  useEffect(() => {
    if (
      step == 2 &&
      reduxData.compareCCInfo.initialCC &&
      initialValues &&
      (JSON.stringify(initialValues) === JSON.stringify(initialState.componentCombination.componentCombination) ||
        !step2Saved)
    ) {
      setInitialValues(reduxData.compareCCInfo.initialCC);
    }
  }, [reduxData.compareCCInfo.initialCC]);

  const handleSetInitialValues = (initCC: ComponentCombination) => {
    setInitialValues(initCC);
  };

  if (loading) {
    return (
      <section className="pt-4">
        <CircularLoader content={t('common.placeholders.loadingData')} />
      </section>
    );
  }
  const props: StepPageProps & IsModifiedProps & { step: number; stepTitles: Array<string> } = {
    step,
    saveData: saveDataByStep,
    handleSetInitialValues: handleSetInitialValues,
    onPrevious: handlePrevious,
    onNext: reduxData['@type'] === AppFlows.DERIVATIVE ? handleNext : onNextHandler,
    onStepChange: changeStep,
    submitting: submitting,
    setSubmitting: setSubmittingState,
    saving: saving,
    setSaving: setSavingState,
    errors: formErrors,
    setErrors: setFormErrors,
    isModified,
    setIsModified,
    stepTitles,
  };

  if (reduxData['@type'] === AppFlows.NEW) {
    return <NewApplicationFlowPage {...props} />;
  }
  if (reduxData['@type'] === AppFlows.DERIVATIVE) {
    return <DerivativeApplicationFlowPage {...props} />;
  }
  if (reduxData['@type'] === AppFlows.VARIANT) {
    return <VariantApplicationFlowPage {...props} />;
  }
  return <>{null}</>;
};

export const ApplicationFlowPage = withRouter(ApplicationFlowComponent);
