import React, { PropsWithChildren } from 'react';
import { ControlLabel, FormGroup } from 'rsuite';
import { FormGroupProps } from 'rsuite/lib/FormGroup';
import { useTranslation } from 'react-i18next';

interface Props extends FormGroupProps, PropsWithChildren<{}> {
  label: React.ReactElement<string> | string;
  value?: JSX.Element | string | null | number;
  placeholder?: string;
}

const Item = ({ label, value, children, placeholder, ...props }: Props) => {
  const { t } = useTranslation();
  const getBody = () => {
    if (children && React.Children.count(children) > 0) {
      return children;
    }
    return value || <span className="text-muted">{placeholder || t('common.placeholders.noDataSpecified')}</span>;
  };
  return (
    <FormGroup {...props}>
      <ControlLabel>{label}</ControlLabel>
      <div className="details-item-content">{getBody()}</div>
    </FormGroup>
  );
};

export const DetailsItem = React.memo(Item);
