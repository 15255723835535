import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Popover, Whisper } from 'rsuite';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { DataTableColumnProps } from './DataTableColumn';
import classNames from 'classnames';
import styled from 'styled-components';
import { RowOptions } from './types';

export const StyledTableCell = withStyles(() =>
  createStyles({
    root: {
      padding: '12px 10px',
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

export const useStyles = makeStyles({
  wordWrap: {
    wordBreak: 'break-word',
  },
});

export const DataTableCellContent = styled.div<{ visible: boolean; width?: number; fixed?: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  ${({ fixed, width }) =>
    fixed &&
    `width: ${width}px;
     display: block;
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis;`}
`;

interface DataTableCellProps<T> {
  row: T;
  nested?: boolean;
  rowOptions: RowOptions;
  settings: DataTableColumnProps<T>;
}

const DataTableCellContentWrapper = ({
  children,
  isEllipsisApplied,
}: PropsWithChildren<{ isEllipsisApplied: boolean }>) => (
  <>
    {isEllipsisApplied ? (
      <Whisper
        placement="topStart"
        speaker={<Popover style={{ maxWidth: window.innerWidth / 3, wordBreak: 'break-word' }}>{children}</Popover>}
      >
        <span className="inner">{children}</span>
      </Whisper>
    ) : (
      children
    )}
  </>
);

export function DataTableCell<T>(props: PropsWithChildren<DataTableCellProps<T>>) {
  const { children, row, nested = false, settings, rowOptions } = props;
  const classes = useStyles();

  const ref = useRef<HTMLDivElement>(null);
  const [isEllipsisApplied, setIsEllipsisApplied] = useState(false);

  useEffect(() => {
    if (settings.fixed && ref.current) {
      setIsEllipsisApplied(ref.current.offsetWidth < ref.current.scrollWidth);
    }
  }, [ref.current?.offsetWidth, window.innerWidth, children, settings.fixed]);

  return (
    <StyledTableCell className={classNames(settings.wordWrap && classes.wordWrap)} style={settings.style}>
      <DataTableCellContent
        ref={ref}
        fixed={settings.fixed}
        visible={!rowOptions.hidden}
        width={settings.fixed ? Number(settings.width) : undefined}
      >
        <DataTableCellContentWrapper isEllipsisApplied={isEllipsisApplied}>
          {settings.children
            ? settings.children(row, nested)
            : settings.field in row
            ? row[settings.field as keyof T]
            : null}
        </DataTableCellContentWrapper>
      </DataTableCellContent>
    </StyledTableCell>
  );
}
