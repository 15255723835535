import React from 'react';
import { Modal, Button } from 'rsuite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HomeDesignErrorMessage } from './new-application-flow-steps/partials/cc-step';
import { RsCenterModal } from '../../../../shared-components/rsuite';

const ModalBody = styled(Modal.Body)``;

export const NotSupportedHomeDesignModal = ({ show, onConfirm }: { show: boolean; onConfirm: () => void }) => {
  const { t } = useTranslation();

  return (
    <RsCenterModal backdrop show={show} onHide={onConfirm}>
      <Modal.Header>
        <Modal.Title>{t('applications.homeDesign.modal.title')}</Modal.Title>
      </Modal.Header>
      <ModalBody style={{ overflow: 'hidden' }}>
        {t('applications.homeDesign.modal.body')}
        <HomeDesignErrorMessage />
      </ModalBody>
      <Modal.Footer>
        <Button appearance="primary" onClick={onConfirm}>
          {t('applications.cc.warning.btn.gotIt')}
        </Button>
      </Modal.Footer>
    </RsCenterModal>
  );
};
