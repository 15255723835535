import { combineReducers } from 'redux';
import { userReducer, userState } from './modules/user/reducer';
import { appReducer, appState } from './modules/app/reducer';
import { rolesReducer, RolesState } from './modules/roles/reducer';
import { categoriesReducer, CategoriesState } from './modules/categories/reducer';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { applicationFlowReducer, ApplicationFlowState } from './modules/application-flow';
import { LabRequest } from '../components/pages/atl-testing/types';
import { testingFlowReducer } from './modules/testing-flow';
import { classificationsReducer, ClassificationsState } from './modules/classification/reducer';
import { modalsReducer, ModalsReducerState } from './modules/modals/reducer';
import { certificationReducer, CertificationState } from './modules/certification';
import { atlQualificationsReducer, AtlQualificationsState } from './modules/admin/atl-qualifications/reducer';

export const rootReducer = (history: History) =>
  combineReducers({
    userReducer,
    appReducer,
    rolesReducer,
    categoriesReducer,
    modalsReducer,
    applicationFlow: applicationFlowReducer,
    testingFlow: testingFlowReducer,
    router: connectRouter(history),
    classificationsReducer: classificationsReducer,
    certificationReducer,
    atlQualificationsReducer,
  });

export interface RootReducer {
  userReducer: userState;
  appReducer: appState;
  rolesReducer: RolesState;
  categoriesReducer: CategoriesState;
  applicationFlow: ApplicationFlowState;
  testingFlow: LabRequest | null;
  classificationsReducer: ClassificationsState;
  modalsReducer: ModalsReducerState;
  certificationReducer: CertificationState;
  atlQualificationsReducer: AtlQualificationsState;
}
