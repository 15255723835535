import React, { useState } from 'react';
import { Icon, Radio, RadioGroup, Table } from 'rsuite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TestResult } from '../../../../redux/modules/testing-flow/types';
import { jsonCopy } from '../../../../helpers';
import { CertificationRow, LRCertification } from '../types';
import { sortByProperty } from '../../../../helpers/sort-by-property';
import { SortingOptions, SortType } from '../../test-case/types';
import { renderTreeToggleIconFunction, RsColumn, RsTable } from '../../../../shared-components/theme';
import { TestingResultIcon } from './TestingResultIcon';
import { RsField } from '../../../../shared-components/rsuite';
import { customIconsSet, getRetirementStatus } from '../../../../helpers/constants';
import { PrerequisitesInfo } from '../../../partials/prerequisites/PrerequisitesInfo';
import { WarningTooltip } from '../../../../shared-components/tooltip/WarningTooltip';
// @ts-ignore
import _var from '../../../../styles/_variables.scss';

const { HeaderCell, Cell } = Table;

const StyledField = styled(RsField)`
  .rs-radio-group-picker {
    border: 2px solid white;
  }

  .rs-radio.rs-radio-inline {
    padding: 0 5px;
    border-left: 2px solid white;
    border-right: 2px solid white;
    margin: 0;
    background-color: ${_var.secondary};

    .rs-icon {
      color: black;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &:hover {
      opacity: 0.8;
    }

    + .rs-radio-inline {
      margin: 0;
    }

    &:last-of-type,
    &:first-of-type {
      border: none;
    }

    &.rs-radio-checked {
      background-color: ${_var.primary};
      .rs-icon {
        color: white;
      }

      svg {
        fill: white;
      }
    }
  }
`;

const WrapperNameInnerCell = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  certifications: LRCertification[];
  loading?: boolean;
  handleInteroperabilityResultChange?: (certId: number, parentId: number | null, value: TestResult) => void;
  uploadStep?: boolean;
}
export const TestResultTable = ({
  certifications,
  loading,
  handleInteroperabilityResultChange,
  uploadStep = false,
}: Props) => {
  const [sorting, setSorting] = useState<SortingOptions>({
    sortColumn: 'name',
    sortType: 'asc',
  });
  const formattedCertifications: CertificationRow[] = certifications
    .map(certification => ({
      ...certification,
      rowId: certification.id,
      children: certification.capabilities.map(capability => ({
        ...capability,
        rowId: `${certification.id}_${capability.id}`,
      })),
    }))
    .sort((a, b) => sortByProperty(a, b, sorting));
  const [expandedRows, setExpandedRows] = useState<number[]>(
    formattedCertifications.filter(cert => cert.children.length).map(cert => cert.rowId),
  );
  const { t } = useTranslation();

  const handleSortChange = (sortColumn: string, sortType: SortType) => {
    setSorting({ sortColumn, sortType });
  };

  return (
    <RsTable
      isTree={formattedCertifications.some(cert => cert.children?.length)}
      defaultExpandAllRows
      data={jsonCopy(formattedCertifications)}
      rowHeight={60}
      loading={loading}
      rowKey="rowId"
      className="mb-1"
      renderTreeToggle={(icon, rowData) =>
        renderTreeToggleIconFunction(expandedRows.includes(rowData.rowId), rowData.children.length > 0)
      }
      sortColumn={sorting.sortColumn}
      sortType={sorting.sortType}
      onSortColumn={handleSortChange}
      rowClassName={rowData => (rowData?.prequalified ? 'is-precertified' : rowData?.isStriped ? 'is-stripe' : '')}
      onExpandChange={(isOpen, rowData) => {
        setExpandedRows(prevState =>
          isOpen ? [...prevState, rowData.rowId] : prevState.filter(num => num !== rowData.rowId),
        );
      }}
    >
      {formattedCertifications.some(cert => cert.children?.length) ? (
        <RsColumn width={40} verticalAlign="middle" fixed>
          <HeaderCell />
          <Cell dataKey="id"> </Cell>
        </RsColumn>
      ) : null}
      <RsColumn flexGrow={1} minWidth={250} align="left" verticalAlign="middle" fixed sortable>
        <HeaderCell>{t('certifications.title')}</HeaderCell>
        <Cell dataKey="name">
          {(row: CertificationRow) => (
            <WrapperNameInnerCell>
              <span className={row._parent ? 'ml-1' : ''}>{row.name}</span>
              {row.prerequisiteOf.length > 0 && <PrerequisitesInfo prerequisites={row.prerequisiteOf} />}
              {row.status && getRetirementStatus(row.status) ? (
                <WarningTooltip variant="rounded" onHoverTooltipTextWarning status={row.status} />
              ) : null}
            </WrapperNameInnerCell>
          )}
        </Cell>
      </RsColumn>
      <RsColumn width={165} verticalAlign="middle" sortable>
        <HeaderCell>{t('certifications.role')}</HeaderCell>
        <Cell dataKey="roleName" />
      </RsColumn>
      <RsColumn width={140} verticalAlign="middle" align="center">
        <HeaderCell>{t('applications.labs.conformance')}</HeaderCell>
        <Cell dataKey="conformanceTestResult">
          {(row: CertificationRow) => <TestingResultIcon testingResult={row.conformanceTestResult} />}
        </Cell>
      </RsColumn>
      <RsColumn width={170} verticalAlign="middle" align="center">
        <HeaderCell>{t('applications.labs.interoperability')}</HeaderCell>
        <Cell dataKey="interoperabilityTestRequested">
          {(row: CertificationRow) =>
            uploadStep &&
            row.interoperabilityTestRequested &&
            row.interoperabilityTestResult !== TestResult.NOT_REQUESTED ? (
              <StyledField
                inline
                appearance="picker"
                accepter={RadioGroup}
                value={row.interoperabilityTestResult}
                onChange={(value: TestResult) =>
                  handleInteroperabilityResultChange &&
                  handleInteroperabilityResultChange(row.id, row._parent?.id || null, value)
                }
                noPadding
              >
                <Radio value={TestResult.SUCCESS}>
                  <Icon icon="check" />
                </Radio>
                <Radio value={TestResult.NOT_TESTED}>
                  <Icon icon={customIconsSet.QUESTION_SQUARE} size="lg" />
                </Radio>
                <Radio value={TestResult.FAIL}>
                  <Icon icon={customIconsSet.ANGLE_WITH_CROSS} size="lg" />
                </Radio>
              </StyledField>
            ) : (
              <TestingResultIcon testingResult={row.interoperabilityTestResult} />
            )
          }
        </Cell>
      </RsColumn>
    </RsTable>
  );
};
