import React from 'react';
import { TransactionDecisionTypes } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../shared-components/tooltip/Tooltip';

const useStyles = makeStyles({
  iconHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  approved: {
    color: '#26716D',
  },
  unApproved: {
    color: '#D0021B',
  },
  ownershipFrom: {
    color: '#E7A153',
  },
  ownershipTo: {
    color: '#CC703D',
  },
  mergeIn: {
    color: '#8C3416',
  },
  mergeOut: {
    color: '#641246',
  },
});

export const getBadgeDetails = (status: TransactionDecisionTypes) => {
  switch (status) {
    case TransactionDecisionTypes.APPROVE_APPLICATION:
      return 'approved';
    case TransactionDecisionTypes.UNAPPROVE_APPLICATION:
      return 'unApproved';
    case TransactionDecisionTypes.TRANSFER_OWNERSHIP_FROM_COMPANY:
      return 'ownershipFrom';
    case TransactionDecisionTypes.TRANSFER_OWNERSHIP_TO_COMPANY:
      return 'ownershipTo';
    case TransactionDecisionTypes.MERGE_VARIANT_IN:
      return 'mergeIn';
    case TransactionDecisionTypes.MERGE_VARIANT_OUT:
      return 'mergeOut';
    default:
      return 'unApproved';
  }
};

const TransactionsBadge = ({
  status,
  useTooltip = true,
}: {
  status: TransactionDecisionTypes;
  useTooltip?: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.iconHolder}>
      <Tooltip translationKey={useTooltip ? t(`admin.transactions.decisionTypes.${getBadgeDetails(status)}`) : ''}>
        <FiberManualRecordIcon className={classes[getBadgeDetails(status)]} />
      </Tooltip>
    </div>
  );
};

export default TransactionsBadge;
