import React from 'react';
import { useTranslation } from 'react-i18next';
import { LabelTypes } from '../../../../helpers/constants/label-types';
import { productTypes, productTypesTitles } from '../../../../helpers/constants/product-types';
import { AppFlows } from '../../../../redux/modules/application-flow';
import { LabelType } from '../../../../shared-components/label-type/LabelType';
import { ApplicationTypeDetails } from '../../../partials/summary-template/ApplicationTypeDetails';
import { GroupedCertifications, SummaryCertifications } from '../../../partials/summary-template/GroupedCertifications';
import { SummaryItem, SummaryTemplate } from '../../../partials/summary-template/SummaryTemplate';
import { BasicApplicationInfoRecord } from '../../application/types';

export const TestingSummarySidebar = ({
  step,
  certifications,
  application,
  hideName = false,
}: {
  step: number;
  certifications: SummaryCertifications[];
  application: BasicApplicationInfoRecord;
  hideName?: boolean;
}) => {
  const { t } = useTranslation();
  const getBody = (value: string | null) =>
    value || <span className="text-muted">{t('common.placeholders.noDataSpecified')}</span>;

  const { companyContactInfo, productInfo, componentCombination } = application;

  const isVariant = application['@type'] === AppFlows.VARIANT;

  const isQualifying = productInfo.certified === false;

  const isQualifiedSolution = productInfo.qualifiedSolution;

  const isUseDraftCertified = isQualifiedSolution && isVariant && isQualifying;

  if (step === 1) {
    return (
      <SummaryTemplate
        title={t('applications.summary.sideBar.heading.certifications')}
        applicationId={application.id}
        includeBilling
      >
        {(productInfo.modelVariants && productInfo.modelVariants[0].productType) ||
        productInfo.cid ||
        productInfo.qualifiedSolution ? (
          <SummaryItem title={t('applications.summary.sideBar.applicationId')}>
            {productInfo.cid}
            {productInfo.qualifiedSolution ? (
              <LabelType
                labelTypes={LabelTypes.SUMMARY_LABEL}
                label={productTypesTitles[productTypes.qualifiedSolution]}
              />
            ) : (
              <>
                {productInfo.modelVariants && productInfo.modelVariants[0].productType ? (
                  <LabelType
                    labelTypes={LabelTypes.SUMMARY_LABEL}
                    label={productTypesTitles[productInfo.modelVariants[0].productType]}
                  />
                ) : null}
              </>
            )}
          </SummaryItem>
        ) : null}
        <SummaryItem title={t('applications.summary.sideBar.applicationType')}>
          <ApplicationTypeDetails
            applicationType={application['@type']}
            companyContactInfo={companyContactInfo}
            productInfo={productInfo}
            wrapWithLink={Boolean(companyContactInfo.source?.cid)}
          />
        </SummaryItem>
        {productInfo.qualifiedSolution && productInfo.certified !== null ? (
          <SummaryItem title={t('applications.summary.sideBar.wifiCertified')}>
            <LabelType
              labelTypes={LabelTypes.BLOCK_LABEL}
              label={
                (isUseDraftCertified
                ? productInfo.modelVariants[0].draftCertified === true
                : productInfo.certified)
                  ? 'Yes'
                  : 'No'
              }
            />
          </SummaryItem>
        ) : null}
        {componentCombination.componentCombinationVariantSource &&
        componentCombination.componentCombinationVariantSource.productType ? (
          <SummaryItem title={t('applications.summary.sideBar.componentLoadedFrom')}>
            <LabelType
              labelTypes={LabelTypes.SUMMARY_LABEL}
              label={productTypesTitles[componentCombination.componentCombinationVariantSource.productType]}
            />
          </SummaryItem>
        ) : null}
        {hideName ? null : <SummaryItem title={t('applications.info.name')}>{productInfo.name}</SummaryItem>}
        <GroupedCertifications certifications={certifications} />
      </SummaryTemplate>
    );
  } else if (step === 2 || step === 3) {
    return (
      <SummaryTemplate title={t('applications.summary.sideBar.heading.application')} applicationId={application.id}>
        {(productInfo.modelVariants && productInfo.modelVariants[0].productType) ||
        productInfo.cid ||
        productInfo.qualifiedSolution ? (
          <SummaryItem title={t('applications.summary.sideBar.applicationId')}>
            {productInfo.cid}
            {productInfo.qualifiedSolution ? (
              <LabelType
                labelTypes={LabelTypes.SUMMARY_LABEL}
                label={productTypesTitles[productTypes.qualifiedSolution]}
              />
            ) : (
              <>
                {productInfo.modelVariants && productInfo.modelVariants[0].productType ? (
                  <LabelType
                    labelTypes={LabelTypes.SUMMARY_LABEL}
                    label={productTypesTitles[productInfo.modelVariants[0].productType]}
                  />
                ) : null}
              </>
            )}
          </SummaryItem>
        ) : null}
        <SummaryItem title={t('applications.summary.sideBar.applicationType')}>
          <ApplicationTypeDetails
            applicationType={application['@type']}
            companyContactInfo={companyContactInfo}
            productInfo={productInfo}
            wrapWithLink={Boolean(companyContactInfo.source?.cid)}
          />
        </SummaryItem>
        {productInfo.qualifiedSolution && productInfo.certified !== null ? (
          <SummaryItem title={t('applications.summary.sideBar.wifiCertified')}>
            <LabelType
              labelTypes={LabelTypes.BLOCK_LABEL}
              label={
                (isUseDraftCertified
                ? productInfo.modelVariants[0].draftCertified === true
                : productInfo.certified)
                  ? 'Yes'
                  : 'No'
              }
            />
          </SummaryItem>
        ) : null}
        {componentCombination.componentCombinationVariantSource &&
        componentCombination.componentCombinationVariantSource.productType ? (
          <SummaryItem title={t('applications.summary.sideBar.componentLoadedFrom')}>
            <LabelType
              labelTypes={LabelTypes.SUMMARY_LABEL}
              label={productTypesTitles[componentCombination.componentCombinationVariantSource.productType]}
            />
          </SummaryItem>
        ) : null}
        <SummaryItem title={t('testing.review.submittingCompany')}>
          {companyContactInfo.submittingCompany?.name}
        </SummaryItem>
        <SummaryItem title={t('applications.flowSelect.additionalEmail')}>
          {companyContactInfo.additionalEmail.length
            ? companyContactInfo.additionalEmail.map((item, index) => <p key={index}>{item}</p>)
            : getBody('')}
        </SummaryItem>
        <SummaryItem title={t('testing.review.submittedBy')}>{companyContactInfo.submittedBy}</SummaryItem>
        <SummaryItem title={t('testing.review.owningCompany')}>{companyContactInfo.owningCompany?.name}</SummaryItem>

        <SummaryItem title={t('applications.info.name')}>{productInfo.name}</SummaryItem>
        <SummaryItem title={t('applications.info.modelNumber')}>{productInfo.modelNumber}</SummaryItem>
        <SummaryItem title={t('applications.info.url')}>{getBody(productInfo.url)}</SummaryItem>
        <SummaryItem title={t('applications.info.description')}>{getBody(productInfo.description)}</SummaryItem>
      </SummaryTemplate>
    );
  } else {
    return null;
  }
};
