import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { recordPerPage } from '../../helpers/constants';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import { Icon } from 'rsuite';
import { DataTablePaginationProps } from './types';

const usePaginationStyles = makeStyles(() =>
  createStyles({
    spacer: {
      flex: 0,
    },
    caption: {
      flex: '1 1 100%',
    },
  }),
);

const usePaginationActionsStyles = makeStyles(() =>
  createStyles({
    root: {
      flexShrink: 0,
    },
  }),
);

const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  font-size: 1.1rem;
`;

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const classes = usePaginationActionsStyles();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.ceil(count / rowsPerPage) - 1);
  };

  return (
    <div className={classes.root}>
      <IconButton className="pagination-first" onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <StyledIcon icon="page-top" />
      </IconButton>
      <IconButton className="pagination-previous" onClick={handleBackButtonClick} disabled={page === 0}>
        <StyledIcon icon="page-previous" />
      </IconButton>
      <IconButton
        className="pagination-next"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <StyledIcon icon="page-next" />
      </IconButton>
      <IconButton
        className="pagination-end"
        onClick={handleLastButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <StyledIcon icon="page-end" />
      </IconButton>
    </div>
  );
};

const TablePaginationActionsNoLastBtn = (props: TablePaginationActionsProps) => {
  const classes = usePaginationActionsStyles();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <div className={classes.root}>
      <IconButton className="pagination-first" onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <StyledIcon icon="page-top" />
      </IconButton>
      <IconButton className="pagination-previous" onClick={handleBackButtonClick} disabled={page === 0}>
        <StyledIcon icon="page-previous" />
      </IconButton>
      <IconButton
        className="pagination-next"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <StyledIcon icon="page-next" />
      </IconButton>
    </div>
  );
};

const getDisplayedRowsLabel = ({ from, to, count }: { from: number; to: number; count: number; page: number }) => {
  return `${from}-${to} of ${count}`;
};

export const DataTablePagination = ({
  total,
  activePage,
  setActivePage,
  showLastBtn = false,
}: DataTablePaginationProps) => {
  const classes = usePaginationStyles();

  const handleChangePage = (event: unknown, newPage: number) => {
    setActivePage(newPage + 1);
  };

  return (
    <TablePagination
      component="div"
      count={total}
      classes={classes}
      page={total === 0 ? 0 : activePage - 1}
      rowsPerPage={recordPerPage}
      rowsPerPageOptions={[recordPerPage]}
      onPageChange={handleChangePage}
      ActionsComponent={showLastBtn ? TablePaginationActions : TablePaginationActionsNoLastBtn}
      labelDisplayedRows={getDisplayedRowsLabel}
    />
  );
};
