import React from 'react';
import { SelectPicker } from 'rsuite';

interface Props {
  value?: number;
  streamNumber?: number;
  onChange?: (args: string) => void;
  disabled?: boolean;
}

export const BandSelect = ({
  value,
  streamNumber = 8,
  onChange,
  disabled = false,
  ...props
}: Props): React.ReactElement<string> => {
  const data = Array(streamNumber)
    .fill(null)
    .map((el, index) => ({ value: index + 1, label: index + 1 }));

  return (
    <SelectPicker
      data={data}
      value={value || 1}
      onChange={onChange}
      disabled={disabled}
      cleanable={false}
      searchable={false}
      block={true}
      placeholder={'- Select -'}
      {...props}
    />
  );
};
