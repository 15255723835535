import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LabelTypes } from '../../helpers/constants/label-types';
import { productTypesTitles, productTypesTooltips } from '../../helpers/constants/product-types';
import { CombinationVariantDetails } from '../../redux/modules/application-flow';
import { Breadcrumbs } from '../../shared-components/breadcrumbs/Breadcrumbs';
import { LabelType } from '../../shared-components/label-type/LabelType';

interface LoadedFromBreadcrumpsProps {
  items: CombinationVariantDetails | null | undefined;
  linkToOnName?: string;
  ccChanged?: boolean;
  showCcChangeLabel?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const LoadedFromBreadcrumps: React.FC<LoadedFromBreadcrumpsProps> = ({
  items,
  linkToOnName,
  ccChanged,
  showCcChangeLabel = true,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const separatorBreadcrumbs = '>';

  if (!items || !items.name) return null;

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapper}>
        <b>{t('applications.cc.loadCC.loadedFrom')}</b>
        {items.productType ? (
          <LabelType
            label={productTypesTitles[items.productType]}
            tooltipText={productTypesTooltips[items.productType]}
          />
        ) : null}
        {showCcChangeLabel && ccChanged ? (
          <LabelType label={t('applications.cc.coreChangeLabelTxt')} labelTypes={LabelTypes.CC_UPDATE_LABEL} />
        ) : null}
      </div>
      <Breadcrumbs
        companyName={items?.company?.name}
        productName={items.product?.name}
        productCid={items?.product?.cid}
        name={items?.name}
        separator={separatorBreadcrumbs}
        linkTo={linkToOnName}
      />
    </div>
  );
};
