import React, { useState } from 'react';
import { TestGroup } from '../../types';
import { ViewConfSection } from './ViewConfSection';
import { useTranslation } from 'react-i18next';
import { Col, Icon, Row } from 'rsuite';
import { RunConditionLabel } from './RunConditionLabel';
import { DetailsItem } from '../../../../partials';

const ViewTestGroup = ({ testGroup }: { testGroup: TestGroup }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div>
      <Row>
        <Col xs={1}>
          <DetailsItem
            label={
              <Icon
                className="is-cursor-pointer text-muted expand-test-group"
                style={{ paddingRight: '5px' }}
                icon={collapsed ? 'plus-square-o' : 'minus-square-o'}
                size="lg"
                onClick={() => setCollapsed(prevState => !prevState)}
              />
            }
            value={' '}
          />
        </Col>
        <Col xs={5}>
          <DetailsItem label={t('certifications.testGroup.testGroup')} value={testGroup.name} />
        </Col>
        <Col xs={3}>
          <DetailsItem label={t('certifications.testGroup.testCases')} value={testGroup.testCriteria.length} />
        </Col>
        <Col xs={15}>
          <DetailsItem
            label={t('certifications.testGroup.runCondition')}
            value={<RunConditionLabel condition={testGroup.condition} />}
          />
        </Col>
      </Row>
      {collapsed || <ViewConfSection data={testGroup.testCriteria} />}
    </div>
  );
};

export const ViewTestGroupSection = ({ data }: { data: TestGroup[] }) => {
  return (
    <>
      {data.map(testGroup => (
        <ViewTestGroup key={testGroup.name} testGroup={testGroup} />
      ))}
    </>
  );
};
