import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import axios from '../../../helpers/axios-setup';
import { AxiosResponse } from 'axios';
import { baseUrl } from '../../../config';
import Lottie from 'react-lottie';
import completeAnimation from '../../../assets/lottie/completed.json';
import loadingAnimation from '../../../assets/lottie/loading.json';

// @ts-ignore
import { Trans, useTranslation } from 'react-i18next';
import { WarningCard } from '../../../shared-components/warning-card/WarningCard';
import { Box } from '@material-ui/core';
import logo from '../../../assets/logo.png';
import { Footer } from '../../../layouts/partials/Footer';

const getFileInfo = (variantId: string, token: string) =>
  axios.get(`certificate/download?variantId=${variantId}&token=${token}`);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const completeOptions = {
  loop: true,
  autoplay: true,
  animationData: completeAnimation,
};

const loadingOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
};

export const LandingPage = () => {
  const { t } = useTranslation();
  const [certificateDownload, setCertificateDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const supportLink = 'support@wi-fi.org';
  const productFinderUrl = 'http://www.wi-fi.org/product-finder';
  const loginUrl = '/#';

  /* Parse Query String */
  const query = useQuery();
  const variantId = '' + query.get('variantId');
  const token = '' + query.get('token');
  const [queryString] = useState(query);
  const certDownURL = baseUrl + '/api/certificate/download?variantId=' + variantId + '&token=' + token;

  useEffect(() => {
    if (!queryString) {
      return;
    }
    const vid = '' + queryString.get('variantId');
    const tkn = '' + queryString.get('token');

    getFileInfo(vid, tkn)
      .then((response: AxiosResponse) => {
        console.log('download test ok. ', response.status);
        location.href = certDownURL;
        setIsLoading(false);
        setCertificateDownload(true);
      })
      .catch(error => {
        console.log('download test failed. ', error);
        setIsLoading(false);
        setCertificateDownload(false);
      });
  }, [queryString]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'grid',
          gridGap: '10px',
          padding: '10px',
          gridTemplateRows: 'auto',
          gridTemplateAreas: `'header header header header header header header header'
          'left left main main main main right right'
          'footer footer footer footer footer footer footer footer'`,
        }}
      >
        <Box sx={{ gridArea: 'header', height: '349px' }}></Box>
        <Box sx={{ gridArea: 'left' }}>&nbsp;</Box>
        <Box sx={{ gridArea: 'main' }}>
          <Lottie options={loadingOptions} height={326} width={326} />
        </Box>
        <Box sx={{ gridArea: 'right' }}>&nbsp;</Box>
        <Box sx={{ gridArea: 'footer' }}>&nbsp;</Box>
      </Box>
    );
  } else {
    return (
      <div>
        {certificateDownload ? (
          <Box
            sx={{
              display: 'grid',
              gridGap: '10px',
              padding: '10px',
              gridTemplateRows: 'auto',
              gridTemplateAreas: `'header header header header header header header header'
              'left left main main main main right right'
              'footer footer footer footer footer footer footer footer'`,
            }}
          >
            <Box sx={{ gridArea: 'header' }}></Box>
            <Box sx={{ gridArea: 'left' }}>&nbsp;</Box>
            <Box sx={{ gridArea: 'main' }} style={{ textAlign: 'center' }}>
              <img src={logo} alt="Logo" style={{ marginTop: '90px', marginLeft: '10px' }} />
              <Lottie options={completeOptions} height={126} width={126} style={{ marginTop: '157px' }} />
              <p style={{ fontWeight: '700', fontSize: '36pt', marginTop: '25px' }}>Download Completed.</p>
              <p style={{ fontSize: '18pt', marginTop: '20px' }}>This tab can now be closed.</p>
            </Box>
            <Box sx={{ gridArea: 'right' }}>&nbsp;</Box>
            <Box sx={{ gridArea: 'footer', position: 'fixed', bottom: '0px', width: '-webkit-fill-available' }}>
              <Footer />
            </Box>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'grid',
                gridGap: '10px',
                padding: '10px',
                gridTemplateRows: 'auto',
                gridTemplateAreas: `'header header header header header header header header'
              'left left logo main main main right right'
              'footer footer footer footer footer footer footer footer'`,
              }}
            >
              <Box sx={{ gridArea: 'header', height: '100px' }}></Box>
              <Box sx={{ gridArea: 'left' }}>&nbsp;</Box>
              <Box sx={{ gridArea: 'logo' }}>
                <div style={{ textAlign: 'right', verticalAlign: 'top', padding: '20px' }}>
                  <img src={logo} alt="Logo" />
                </div>
              </Box>
              <Box sx={{ gridArea: 'main' }}>
                <div style={{ textAlign: 'left', verticalAlign: 'top', padding: '20px', width: '800px' }}>
                  <WarningCard
                    content={
                      <>
                        <p>{t('landing.expired_token')}</p>
                        <br />
                        {t('landing.download_instructions.title')}
                        <ol>
                          <li>
                            <Trans i18nKey="landing.download_instructions.stepOne">
                              <a href={loginUrl}>log in link</a>
                            </Trans>
                          </li>
                          <li>{t('landing.download_instructions.stepTwo')}</li>
                          <li>{t('landing.download_instructions.stepThree')}</li>
                          <li>
                            <Trans i18nKey="landing.download_instructions.stepFour" />
                          </li>
                        </ol>
                        <p>
                          <Trans i18nKey="landing.declaration">
                            <a href={productFinderUrl}>Product Finder link</a>
                          </Trans>
                        </p>
                        <br />
                        {t('landing.certification_public.title')}
                        <ol>
                          <li>
                            <Trans i18nKey="landing.certification_public.stepOne">
                              <a href={loginUrl}>log in link</a>
                            </Trans>
                          </li>
                          <li>{t('landing.certification_public.stepTwo')}</li>
                          <li>{t('landing.certification_public.stepThree')}</li>
                          <li>{t('landing.certification_public.stepFour')}</li>
                          <li>
                            <Trans i18nKey="landing.certification_public.stepFive" />
                          </li>
                          <li>{t('landing.certification_public.stepSix')}</li>
                        </ol>

                        {t('landing.commercial_product.title')}
                        <ol>
                          <li>{t('landing.commercial_product.stepOne')}</li>
                          <li>{t('landing.commercial_product.stepTwo')}</li>
                          <li>
                            <Trans i18nKey="landing.commercial_product.stepThree" />
                          </li>
                          <li>{t('landing.commercial_product.stepFour')}</li>
                        </ol>
                        <p>
                          {t('landing.support_information')}
                          <strong>
                            <a style={{ color: '#0377fc', textDecoration: 'none' }} href="mailto:support@wi-fi.org">
                              {supportLink}
                            </a>
                          </strong>
                          .
                        </p>
                      </>
                    }
                  />
                </div>
              </Box>
              <Box sx={{ gridArea: 'right' }}>&nbsp;</Box>
              <Box sx={{ gridArea: 'footer', position: 'fixed', bottom: '0px', width: '-webkit-fill-available' }}>
                <Footer />
              </Box>
            </Box>
          </>
        )}
      </div>
    );
  }
};
