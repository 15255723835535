import React from 'react';
import { Checkbox } from 'rsuite';
import { CheckboxProps } from 'rsuite/lib/Checkbox';
import classNames from 'classnames';
import styled from 'styled-components';
// @ts-ignore
import _scss from '../../../../../../styles/_variables.scss';

interface Props extends CheckboxProps {
  isPrequalified?: boolean;
  isNotAuthorized?: boolean;
  hasErrors?: boolean;
}

const CheckboxWrapper = styled(Checkbox)`
  &.has-error {
    .rs-checkbox-inner::before {
      border-color: ${_scss.errorRed}!important;
    }
  }

  &.read-only {
    pointer-events: none;
  }

  &.rs-checkbox-disabled:not(.is-unauthorized):not(.has-error) {
    opacity: 0.5;

    &.rs-checkbox-checked .rs-checkbox-inner:before {
      background-color: ${_scss.primary}!important;
    }

    .rs-checkbox-inner::before {
      border-color: ${_scss.primary}!important;
    }
  }

  &.is-prequalified:not(.is-unauthorized) {
    &.rs-checkbox-checked {
      .rs-checkbox-wrapper {
        .rs-checkbox-inner {
          &:before {
            background-color: ${_scss.primary};
          }
          &:after {
            border-color: white;
          }
        }
      }
    }

    .rs-checkbox-wrapper {
      .rs-checkbox-inner {
        &:before {
          border: 2px dashed ${_scss.primary};
          // just to override the default value for disabled state
          border-color: ${_scss.primary}!important;
          background-color: transparent;
        }
        &:after {
          border-color: ${_scss.primary};
        }
      }
    }
  }

  &.is-unauthorized {
    .rs-checkbox-wrapper {
      .rs-checkbox-inner {
        &::before {
          border: 2px solid lightgray !important;
          background-color: transparent;
        }

        &::after {
          border: none;
          border-left: 2px solid lightgray;
          width: 4px;
          height: 30px;
          margin-top: -3px;
          margin-left: 9px;
          transform: rotate(135deg) scale(1);
          opacity: 1;
        }
      }
    }
    &.has-error {
      .rs-checkbox-wrapper {
        .rs-checkbox-inner {
          &::before {
            border-color: ${_scss.errorRed} !important;
          }

          &::after {
            border-color: ${_scss.errorRed} !important;
          }
        }
      }
    }
  }

  &.rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate)
    .rs-checkbox-wrapper
    .rs-checkbox-inner::before {
    background-color: transparent;
  }

  label {
    line-height: 2.2;
    padding-left: 0.5rem;
    color: black;
  }

  label,
  .rs-checkbox-checker {
    padding-left: 0;
  }

  &.rs-checkbox-checked,
  &.rs-checkbox-indeterminate {
    opacity: 1;
    .rs-checkbox-wrapper {
      .rs-checkbox-inner::after {
        width: 6px;
        height: 11px;
        margin-top: 5px;
        margin-left: 9px;
      }

      [type='checkbox'] {
        &:focus {
          ~ .rs-checkbox-inner {
            &::before {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .rs-checkbox-wrapper {
    left: 40%;
    width: 24px;
    height: 24px;

    [type='checkbox'] {
      &:focus {
        ~ .rs-checkbox-inner {
          &::before {
            box-shadow: none;
          }
        }
      }
    }

    .rs-checkbox-inner::before,
    &::before {
      width: 24px;
      height: 24px;
    }
    .rs-checkbox-inner::before {
      border-width: 2px;
    }
  }
`;

export const CertLabCheckbox = ({
  checked,
  disabled,
  onChange,
  children,
  className,
  hasErrors = false,
  isPrequalified = false,
  isNotAuthorized = false,
  readOnly = false,
}: Props) => {
  return (
    <CheckboxWrapper
      checked={checked}
      disabled={disabled || isNotAuthorized}
      onChange={onChange}
      className={classNames(className, {
        'is-prequalified': isPrequalified,
        'is-unauthorized': isNotAuthorized,
        'has-error': hasErrors,
        'read-only': readOnly,
      })}
    >
      {children}
    </CheckboxWrapper>
  );
};
