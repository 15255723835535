import axios from '../../../helpers/axios-setup';
import { AdditionalInfo } from '../../../redux/modules/application-flow';

export enum AdditionalInfoType {
  SIMPLE = 'Simple',
  WITH_PAYER = 'WithPayer',
}

export const submitApplicationToTesting = (appId: number, params: AdditionalInfo & { '@type': AdditionalInfoType }) =>
  axios.post(`/applications/builder/submit/${appId}`, params);
