import { SortingOptions, SortType } from '../components/pages/test-case/types';
import { ApplicationTypes } from '../components/pages/admin/transactions/types';
import { LabSubType } from '../components/pages/admin/atl-qualifications/types';

export interface IterableObject<T> {
  [x: string]: T;
}

export type IterableInterface<T, I> = T & { [index: string]: I };

export interface Paginated<T> {
  data: T[];
  currentPage: number;
  total: number;
  perPage: number;
}

export interface StartSizePaginated<T> {
  content: T[];
  start: number;
  size: number;
  counts: IterableObject<number>;
  totalHits: number;
}

export interface GetSummaryDefaultOptions {
  tab?: string;
  searchString?: string;
  sortingOptions?: SortingOptions;
  page?: number;
}

export interface LabsSummaryFiltersObject extends IterableObject<string | string[] | null> {
  search: string | null;
  subtype: LabSubType[] | null;
}

export interface SummaryFiltersObject extends IterableObject<string | string[] | number[] | null> {
  search: string;
  from: string;
  to: string;
  roleId: string[] | null;
  type: string[] | null;
  applicationType: ApplicationTypes | null;
  certificationId: string | null;
  applicationTypeId: string[] | null;
  labId: string[] | null;
  owningCompanyId: string[] | null;
  searchable: string[] | null;
  available: string[] | null;
  canCreateDerivative: string[] | null;
  isCertified: string[] | null;
  availableAsQualifiedSolution: string[] | null;
  worstCertificationVersionStatus: string[] | null;
  decision: string[] | null;
  amount: string[] | null;
  requestingAccountId: string[] | null;
  applicationStatus: string[] | null;
  hasDraftCapability: string[] | null;
  capabilityId: string[] | null;
}

export interface GetSummaryWithFiltersOptions extends GetSummaryDefaultOptions {
  filters?: SummaryFiltersObject;
}

export interface SummaryTableProps<T> {
  data: T[];
  onChangeSorting: (sortColumn: string, sortType: SortType) => void;
  sorting: SortingOptions;
  loading: boolean;
  setActivePage: (value: number) => void;
  total: number;
  activePage: number;
  firstLoad: boolean;
  status: string;
  showLastBtn: boolean;
}

export interface SummaryTableWithoutTabProps<T> {
  data: T[];
  onChangeSorting: (sortColumn: string, sortType: SortType) => void;
  sorting: SortingOptions;
  loading: boolean;
  setActivePage: (value: number) => void;
  total: number;
  activePage: number;
}

export interface UseSummaryTableProps<T> extends SummaryTableWithoutTabProps<T> {
  filters: SummaryFiltersObject;
  onChangeFilters: (filters: SummaryFiltersObject) => void;
  refreshSummary?: (options?: GetSummaryWithFiltersOptions) => void;
}

export interface ValidationError {
  objectName: string;
  field: string;
  messageKey: string;
  messageParameters: string;
}

export interface ValidationErrorById {
  id: number;
  errorDescriptions: { message: string; messageKey: string; parameters: string[] }[];
}

export interface ValidationErrorResponse {
  message: string;
  messageKey: string;
  messageParameters?: string[];
  errors: ValidationError[];
}

export interface MixedValidationErrorResponse {
  message: string;
  messageKey: string;
  messageParameters?: string[];
  errors: ValidationError[] | ValidationErrorById[];
}

export interface ErrorObject {
  [x: string]: string | ErrorObject;
}

export interface ComplexObjectErrorDescriptions {
  message: string;
  messageKey: string;
  parameters: number[];
}

export interface ComplexObjectError {
  id: number;
  errorDescriptions: ComplexObjectErrorDescriptions[];
}

export interface UploaderInstance {
  start: () => void;
  cleanInputValue: () => void;
}

export interface UploaderServerErrorResponse {
  type: string;
  response: { message: string; messageKey: string; messageParameters: string[] };
}

export enum SupportingFilesType {
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT',
  LICENSE_AGREEMENT = 'LICENSE_AGREEMENT',
  APP_IMAGE = 'APP_IMAGE',
  APP_DOC = 'APP_DOC',
  INTEROPERABILITY_TEMPLATE = 'INTEROPERABILITY_TEMPLATE',
  TESTING_EXCEPTION_ATTACHMENT = 'TESTING_EXCEPTION_ATTACHMENT',
  INTEROPERABILITY_TEST_RESULT = 'INTEROPERABILITY_TEST_RESULT',
  CONFORMANCE_TEST_RESULT = 'CONFORMANCE_TEST_RESULT',
  ATL_ATTACHMENT = 'ATL_ATTACHMENT',
  OTHER = 'OTHER',
}
