import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import _var from '../../../styles/_variables.scss';

const Paragraph = styled.p`
  line-height: 1rem;
`;

const Span = styled.span`
  color: ${_var.primary};
  margin-left: 10px;
`;

export const TabHorizontalLabel = (data: { value: string | number; label: string }): React.ReactElement<string> => {
  return (
    <div className="text-center">
      <Paragraph>
        {data.label}
        <Span>{data.value}</Span>
      </Paragraph>
    </div>
  );
};
