import React, { ChangeEvent, useEffect } from 'react';
import { CertificationType, Classification, OnChangeType, UmbrellaInfo } from '../../types';
import { loadClassifications } from '../../../../../redux/modules/classification';
import { RootReducer } from '../../../../../redux/rootReducer';
import { Role } from '../../../../../redux/modules/roles/types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'rsuite';
import {
  LabeledInput,
  LabeledBooleanField,
  LabeledTextArea,
  LabeledSelect,
} from '../../../../../shared-components/labeled-inputs';
import { useTranslation } from 'react-i18next';
import { ErrorObject } from '../../../../../helpers/types';
import { DetailsItem } from '../../../../partials';
import { RedesignCertificationTypeTitles } from '../../../../../helpers/constants';

export const EditUmbrellaSection = ({
  id,
  data,
  type,
  errors,
  onChange,
}: {
  id: number | null;
  data: UmbrellaInfo;
  type: CertificationType;
  errors: ErrorObject;
  onChange: (value: OnChangeType, name: string) => void;
}) => {
  const { roles, classifications } = useSelector<RootReducer, { roles: Role[]; classifications: Classification[] }>(
    state => ({
      roles: state.rolesReducer.roles,
      classifications: state.classificationsReducer.classifications,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!classifications.length) {
      dispatch(loadClassifications());
    }
  }, []);

  return (
    <Row gutter={20}>
      <Col xs={12}>
        <Row gutter={10}>
          <Col xs={16}>
            <LabeledInput
              noMargin
              required
              label={t('certifications.name')}
              name="generalInfo.name"
              defaultValue={data.name}
              errors={errors}
              placeholder={t('certifications.name')}
              onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => onChange(value, event.target.name)}
            />
          </Col>
          <Col xs={8}>
            <LabeledBooleanField
              noMargin
              required
              errors={errors}
              name="generalInfo.visibleOnCertificate"
              label={t('certifications.nameAppearsOnCertificate')}
              value={data.visibleOnCertificate}
              onChange={(value: string) => onChange(value, 'generalInfo.visibleOnCertificate')}
            />
          </Col>
        </Row>
        <LabeledTextArea
          noMargin
          rows={6}
          errors={errors}
          name="generalInfo.description"
          defaultValue={data.description}
          label={t('certifications.description')}
          placeholder={t('certifications.description')}
          onChange={(value: string, event: ChangeEvent<HTMLTextAreaElement>) => onChange(value, event.target.name)}
        />
      </Col>
      <Col xs={12}>
        <Row gutter={10}>
          <Col xs={8}>
            <LabeledSelect
              block
              noMargin
              required
              cleanable={false}
              searchable={false}
              data={roles}
              errors={errors}
              labelKey="name"
              valueKey="id"
              name="generalInfo.role.id"
              label={t('certifications.role')}
              placeholder={t('common.placeholders.select')}
              value={data.role.id}
              disabled={Boolean(id)}
              onSelect={value => onChange(value, 'generalInfo.role.id')}
            />
          </Col>
          <Col xs={8}>
            <DetailsItem label={t('certifications.type')}>
              <p style={{ marginTop: '0.5rem' }}>{RedesignCertificationTypeTitles[type]}</p>
            </DetailsItem>
          </Col>
          <Col xs={8}>
            <LabeledSelect
              block
              required
              valueKey="id"
              labelKey="name"
              cleanable={false}
              searchable={false}
              errors={errors}
              name="generalInfo.classification"
              label={t('certifications.classification')}
              placeholder={t('common.placeholders.select')}
              data={classifications}
              value={data.classification?.id}
              onSelect={(value, item: Classification) => onChange(item, 'generalInfo.classification')}
              disabled={type === CertificationType.CAPABILITY}
            />
          </Col>
        </Row>
        <LabeledTextArea
          rows={6}
          name="generalInfo.internalComments"
          errors={errors}
          defaultValue={data.internalComments}
          label={t('certifications.internalComments')}
          placeholder={t('certifications.internalComments')}
          onChange={(value: string, event: ChangeEvent<HTMLTextAreaElement>) => onChange(value, event.target.name)}
        />
      </Col>
    </Row>
  );
};
