import React from 'react';
import { Radio } from 'rsuite';
import { ConditionOperator } from '../types';
import { StyledOperatorInputWrapper } from './StyledComponents';

interface OperatorInputProps {
  disabled?: boolean;
  value: ConditionOperator;
  onChange: (value: ConditionOperator) => void;
}

export const OperatorInput = ({ disabled = false, value, onChange }: OperatorInputProps) => {
  return (
    <StyledOperatorInputWrapper inline appearance="picker" value={value} onChange={onChange}>
      <Radio disabled={disabled} style={{ minWidth: 50 }} value={ConditionOperator.AND}>
        AND
      </Radio>
      <Radio disabled={disabled} style={{ minWidth: 50 }} value={ConditionOperator.OR}>
        OR
      </Radio>
    </StyledOperatorInputWrapper>
  );
};
