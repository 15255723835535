import React from 'react';
import { TCMeasurementAutocomplete } from '../../test-case/types';
import { SuggestionItemProps } from './types';

export const SuggestionItem: React.FunctionComponent<SuggestionItemProps> = ({ item }: SuggestionItemProps) => {
  return (
    <div>
      <div className="m-0">{item.name}</div>
      <small className="text-muted">
        <b>Measurements: </b>
        {item.measurements.map((measurement: TCMeasurementAutocomplete): string => measurement.name).join(', ')}
      </small>
    </div>
  );
};
