import React from 'react';
import {
  Breadcrumbs as BreadcrumbsMaterial,
  BreadcrumbsProps as BreadcrumbsPropsMaterial,
  makeStyles,
  Theme,
} from '@material-ui/core';

import { AnchorLink } from '../styled-components';

interface BreadcrumbsItemsProps {
  companyName: string | null;
  productCid: string | null;
  productName: string | null;
  name: string | null;
  linkTo?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiBreadcrumbs-root': {
      '& span': {
        fontSize: 14,
      },
    },
  },
  item: {
    fontWeight: 'normal',
    color: 'black',
  },
  lastItem: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: 14,
  },
}));

export const Breadcrumbs: React.FC<BreadcrumbsItemsProps & BreadcrumbsPropsMaterial> = ({
  companyName,
  productCid,
  productName,
  name,
  linkTo,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BreadcrumbsMaterial {...rest} aria-label="breadcrumb">
        <span className={classes.item}>{companyName}</span>
        <span className={classes.item}>
          {productCid} - {productName}
        </span>
        {!!linkTo ? (
          <AnchorLink to={linkTo} target="_blank" className={classes.lastItem}>
            {name}
          </AnchorLink>
        ) : (
          <span className={classes.lastItem}>{name}</span>
        )}
      </BreadcrumbsMaterial>
    </div>
  );
};
