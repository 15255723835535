import { IterableObject } from './types';

export const setUrlParams = (paramsToSet: IterableObject<string | string[] | number | null>, search: string) => {
  const params = new URLSearchParams(search);
  for (const key in paramsToSet) {
    const value = paramsToSet[key];
    if (value) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          if (i == 0) params.set(key, String(value[i]));
          else params.append(key, String(value[i]));
        }
      } else {
        params.set(key, String(value));
      }
    } else {
      params.delete(key);
    }
  }
  return params;
};
