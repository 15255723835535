import { createTheme } from '@material-ui/core/styles';
// @ts-ignore
import _var from '../../styles/_variables.scss';

export const theme = createTheme({
  palette: {
    primary: {
      main: _var.primary,
      dark: _var.primary,
    },
    secondary: {
      main: _var.secondary,
      light: _var.secondaryLighten,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Arial', 'Helvetica', 'Roboto', 'sans-serif'].join(','),
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused fieldset': {
          borderWidth: '1px !important',
        },
      },
    },
    MuiRadio: {
      root: {
        color: _var.primary,
      },
      colorSecondary: {
        '&$checked': {
          color: _var.primary,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-selected': {
          color: _var.primary,
          fontWeight: 'bold',
          backgroundColor: '#ffedfa',
          '&:hover': {
            backgroundColor: '#ffedfa',
          },
        },
        '&:hover': {
          backgroundColor: '#ffedfa',
        },
      },
    },
  },
});
