import React, { forwardRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CollapsableSection } from '../../../../../certification/partials/CollapsableSection';
import { CollapsedSectionProps } from '../../../../../certification/types';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { AppFlows, ApplicationFlowState, AppCertification } from '../../../../../../../redux/modules/application-flow';
import { Confirm } from '../../../../../../../helpers/confirmationPopup/Confirm';
import { CertificationsSections } from '../../../../../staff/partials/CertificationsSection';
import { confirmAction } from '../../../../../../../helpers';

import { GoBackStepButton } from './GoBackStepButton';

type CertificationsSectionProps = CollapsedSectionProps & {
  onStepChange: (step: number) => void;
  certifications: AppCertification[];
  compareCertifications: AppCertification[];
  isOtherSectionModified: boolean;
};

export const CertificationsSection = forwardRef<HTMLDivElement, CertificationsSectionProps>(
  function CertificationsSection(
    { onStepChange, certifications, compareCertifications, isOtherSectionModified }: CertificationsSectionProps,
    ref,
  ) {
    const { t } = useTranslation();

    const { ...application } = useSelector<RootReducer, ApplicationFlowState>(state => state.applicationFlow);

    const isDerivative = application['@type'] === AppFlows.DERIVATIVE;

    const confirmGoBackCertificationsStep = () => {
      Confirm({
        title: t('applications.review.section.certifications.goBackConfirm.title'),
        message: <Trans i18nKey="applications.review.section.certifications.goBackConfirm.message" />,
        onAccept: () => {
          onStepChange(3);
        },
        size: 'sm',
      });
    };

    const confirmGoBackCapabilitiesStep = () => {
      Confirm({
        title: t('applications.review.section.capabilities.goBackConfirm.title'),
        message: <Trans i18nKey="applications.review.section.capabilities.goBackConfirm.message" />,
        onAccept: () => {
          onStepChange(4);
        },
        size: 'sm',
      });
    };

    const checkDataAndConfirmGoBackCertificationsStep = () => {
      if (isOtherSectionModified) {
        confirmAction(
          () => true,
          () => {
            setTimeout(confirmGoBackCertificationsStep, 200);
          },
          'common.unsavedDataWarning',
        );
      } else {
        confirmGoBackCertificationsStep();
      }
    };

    const checkDataAndConfirmGoBackCapabilitiesStep = () => {
      if (isOtherSectionModified) {
        confirmAction(
          () => true,
          () => {
            setTimeout(confirmGoBackCapabilitiesStep, 200);
          },
          'common.unsavedDataWarning',
        );
      } else {
        confirmGoBackCapabilitiesStep();
      }
    };

    return (
      <CollapsableSection
        title={t('applications.review.section.certifications.title')}
        showEditIcon={isDerivative ? false : true}
        ref={ref}
        onEditIconClick={checkDataAndConfirmGoBackCertificationsStep}
        hideDivider={true}
        editIcon={<GoBackStepButton />}
        hideEditIconWhenCollapse={true}
        subTitle={t('applications.review.section.capabilities.title')}
        subEditIcon={<GoBackStepButton />}
        onSubEditIconClick={checkDataAndConfirmGoBackCapabilitiesStep}
      >
        <CertificationsSections
          certifications={certifications}
          compareCertifications={compareCertifications}
          hideTitle={true}
        />
      </CollapsableSection>
    );
  },
);
