import React, { forwardRef, PropsWithChildren } from 'react';
import { Icon, Button } from 'rsuite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { customIconsSet } from '../../../../../helpers/constants';
import { TransferSummaryFiltersObject } from '../types';
import AdvancedFilter from '../../../../../shared-components/filters/AdvancedFilter';
import {
  KeywordSearchCondition,
  getDateRangeConditionCurrentValueStartDate,
  getDateRangeConditionCurrentValueEndDate,
  getKeywordSerachConditionCurrentValue,
  DateRangeCondition,
} from '../../../../../shared-components/filters/conditions';
import { FilterParametersType } from '../../../../../shared-components/filters/types';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .rs-icon,
  h4 {
    display: inline-block;
  }

  .pointer {
    user-select: none;
    position: relative;
  }
`;

interface Props {
  title: string;
  totalCount: number;
  applicableCount: number;
  selectedCount: number;
  onTransfer: () => void;
  updateFilters: (value: TransferSummaryFiltersObject) => void;
  onSelectAll: () => void;
  onClearAll: () => void;
}

export const TransferSection = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(function CollapsableSection(
  {
    children,
    title,
    totalCount,
    applicableCount,
    selectedCount,
    onTransfer,
    updateFilters,
    onSelectAll,
    onClearAll,
  }: PropsWithChildren<Props>,
  ref,
) {
  const { t } = useTranslation();

  const handleConditionChange = (filterParameters: FilterParametersType) => {
    const newFilterObject: TransferSummaryFiltersObject = {
      search: null,
      from: null,
      to: null,
    };
    if (filterParameters.search) {
      newFilterObject.search = getKeywordSerachConditionCurrentValue(filterParameters.search);
    }
    if (filterParameters.dates) {
      newFilterObject.from = encodeURIComponent(
        DateTime.fromJSDate(getDateRangeConditionCurrentValueStartDate(filterParameters.dates))
          .startOf('day')
          .toISO(),
      );
      newFilterObject.to = encodeURIComponent(
        DateTime.fromJSDate(getDateRangeConditionCurrentValueEndDate(filterParameters.dates))
          .endOf('day')
          .toISO(),
      );
    }
    updateFilters(newFilterObject);
  };

  return (
    <Wrapper className="mt-1" ref={ref}>
      <div className="header">
        <div>
          <h4>{title}</h4>
          <div style={{ marginTop: 10 }}>
            <AdvancedFilter
              inlineConditions={[
                { conditionType: KeywordSearchCondition, parameterName: 'search' },
                { conditionType: DateRangeCondition, parameterName: 'dates' },
              ]}
              onConditionChange={handleConditionChange}
            />
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <div className="btn-wrapper">
            <Button
              appearance="primary"
              data-test-id="button.request.transfer"
              onClick={onTransfer}
              disabled={selectedCount === 0}
            >
              <Icon icon={customIconsSet.TRANSFER_ICON} size="lg" style={{ marginRight: 5 }} />
              {t('admin.changeOwningCompany.transfer.transfer')}
            </Button>
          </div>
        </div>
      </div>
      <div className="header" style={{ marginTop: 18, marginBottom: 18 }}>
        <span style={{ fontSize: 16, marginLeft: 24 }}>
          <span>
            {t('admin.changeOwningCompany.transfer.total')}: {totalCount}
          </span>
          <span style={{ marginLeft: 16 }}>
            {t('admin.changeOwningCompany.transfer.applicable')}: {applicableCount}
          </span>
          <span style={{ marginLeft: 16 }}>
            {t('admin.changeOwningCompany.transfer.selected')}:{' '}
            <span data-test-id="text.selectedCount">{selectedCount}</span>
          </span>
        </span>
        <span>
          <Button appearance="link" data-test-id="button.selectAll" onClick={onSelectAll}>
            {t('admin.changeOwningCompany.transfer.selectAll')}
          </Button>
          <Button appearance="link" data-test-id="button.clearall" onClick={onClearAll} disabled={selectedCount === 0}>
            {t('admin.changeOwningCompany.transfer.clearSelected')}
          </Button>
        </span>
      </div>
      {children}
    </Wrapper>
  );
});
