import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';

import { TCRecord, TestCaseFormValues } from './types';
import { createNewTC, getSingleTC } from '../../../api/test-case';
import { TCForm } from './partials/TCForm';
import { ValidationErrorResponse } from '../../../helpers/types';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { ErrorCard } from '../../../shared-components';
import { jsonCopy, parseUrlParams } from '../../../helpers';
import { emptyTCItem } from './data/constants';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';

const prepareTestCase = (record: TCRecord): TestCaseFormValues => {
  return {
    name: record.name,
    description: record.description,
    instructions: record.instructions,
    pythonScript: record.pythonScript,
    pythonScriptHash: record.pythonScriptHash,
    measurements: record.measurements.map(measurement => ({
      name: measurement.name,
      comment: measurement.comment,
      alwaysProduced: measurement.alwaysProduced,
      measurementType: measurement.measurementType,
    })),
  };
};

const TCCreateComponent = ({ history }: RouteComponentProps): React.ReactElement<string> => {
  const { t } = useTranslation();
  const location = useLocation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState();
  const [tcItem, setTCItem] = useState(jsonCopy(emptyTCItem));
  const sourceId = +parseUrlParams(location.search).sourceId;

  useEffect(() => {
    if (sourceId) {
      getSingleTC(sourceId)
        .then((response: AxiosResponse<TCRecord>) => {
          setTCItem(prepareTestCase(response.data));
          setLoading(false);
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          setLoadingError(error);
        });
    }
  }, []);

  const createTestCase = (data: TestCaseFormValues, saveOnly = false) => {
    return createNewTC(data)
      .then((response: AxiosResponse<TestCaseFormValues>) => {
        addToast(t('testCases.saved'), {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        if (saveOnly) {
          history.push(`/test-case/${response.data.id}/edit`);
        } else {
          history.push('/test-case');
        }
      })
      .catch((error: AxiosError<ValidationErrorResponse>) => {
        handleRequestFail(error, addToast);
        throw error;
      });
  };

  if (sourceId && (loading || loadingError)) {
    return (
      <>
        {loading ? <CircularLoader fullPage backdrop content={t('common.placeholders.loadingData')} /> : null}
        {loadingError ? <ErrorCard error={loadingError} /> : null}
      </>
    );
  } else {
    return <TCForm item={tcItem} onSave={createTestCase} />;
  }
};

export const TCCreatePage = withRouter(TCCreateComponent);
