import dot from 'dot-object';
import { AnyAction } from 'redux';
import { GetCapabilitiesByCC } from '../../../api/application/get-capabilities-by-CC';
import { LabsResponse } from '../../../api/application/get-labs-by-app-id';
import {
  ComponentCombination,
  DecisionType,
  DerivativeType,
  MajorEvents,
  PaymentData,
  ProductInfoStep,
  WiFiComponents,
} from '../../../api/application/types';
import {
  easyMeshProps,
  homeDesignProps,
  otherRoleProps,
  roleNameMapping,
} from '../../../components/pages/application/data/constants';
import { ApplicationScheme } from '../../../components/pages/application/data/validation-schemes';
import { PreReqTree } from '../../../components/pages/application/partials/new-application-flow-steps';
import { isProductOfSolutionProvider } from '../../../components/pages/application/partials/new-application-flow-steps/helpers';
import {
  CCInfoRecord,
  OtherRolesProps,
  TestingInfoLab,
  TestingItem,
  TestingLab,
  TreeToEnable,
  TreeToUncheck,
  testingFieldTypes,
} from '../../../components/pages/application/types';
import { CertificationType } from '../../../components/pages/certification/types';
import { Fee } from '../../../components/partials/billing/types';
import { jsonCopy, mergeObjects, prepareYupModel } from '../../../helpers';
import { updateCCWithOrder } from '../../../helpers/add-order-to-cc';
import { CertificationStatuses } from '../../../helpers/constants/certification-statuses';
import { ProductTypes } from '../../../helpers/constants/product-types';
import { ErrorObject } from '../../../helpers/types';
import { UploadedFile } from '../../../shared-components/file-upload/types';
import {
  ADD_CC_ROLE,
  ADD_RF_BAND,
  ADD_SELECTED_TESTING_LAB,
  APPLICATION_FOR_EDIT_LOADED,
  CHANGE_CC_ROLE,
  CHANGE_NAME_SELECTED_TESTING_LAB,
  CHECK_CAPABILITY,
  CHECK_CERTIFICATION,
  NEW_APPLICATION_STARTED,
  REMOVE_CC_ROLE,
  REMOVE_RF_BAND,
  REMOVE_SELECTED_TESTING_LAB,
  RESET_CERTIFICATIONS,
  RESTORE_APPLICATION_DATA,
  SELECT_ALL_CERT_FOR_LAB,
  SELECT_CERT_LAB_CHECKBOX,
  SET_APPLICATION_ID,
  SET_APPLICATION_VALIDATION_ERRORS,
  SET_AUTO_VALIDATE_CAPABILITY,
  SET_CAPABILITIES_BY_CC,
  SET_CERTIFICATIONS_LOADER,
  SET_COMPARE_CC_INFO,
  SET_FEES_RESPONSE,
  SET_LABS_RESPONSE,
  SET_REVIEW_SECTIONS_EDIT_STATE,
  SET_SHOW_POPUP,
  SET_VALIDATION_STATE,
  UN_CHECK_CAPABILITY,
  UN_CHECK_CERTIFICATION,
  UN_CHECK_CERT_TREE,
  UPDATE_APPLICATION_CORE_CC_EXCEPTION_REQUESTED,
  UPDATE_APPLICATION_CORE_CC_EXPLANATION,
  UPDATE_APPLICATION_EXPLANATION,
  UPDATE_APPLICATION_PROPERTY,
  UPDATE_CERTIFICATIONS,
  UPDATE_PAYMENT_DATA,
  UPDATE_SELECTED_TESTING_LABS,
} from './actionTypes';
import { MemberClearData, defaultSelectedLabValue, initialState } from './data';
import {
  dropCCRole,
  filterErrors,
  getAllowedVersions,
  getParentCapability,
  getUpdatedCertsWithErrors,
  isChildOfParentCapability,
  isParentPresent,
  isSingleSelectCapability,
  validateTestingInfo,
} from './helpers';

export interface AppSubCertification {
  id: number;
  name: string;
}

export interface AppCertVersion {
  id: number;
  name: string;
  prerequisites?: AppCertPrereqs[];
  status?: CertificationStatuses;
  note?: string;
  ecn?: string;
}

export interface BasicCertification {
  id: number;
  name: string;
  role: {
    id: number;
    name: string;
  };
}

export interface BasicAppCertification extends BasicCertification {
  prequalified?: boolean;
  previouslySelected?: boolean;
}

export interface AppCapability {
  id: number;
  name: string;
  prerequisites?: AppCertPrereqs[];
  isPreReq?: boolean;
  preReqOf?: number[];
  displayParent?: number | null;
  index?: number;
  mandatory?: boolean;
  prequalified?: boolean;
  previouslySelected?: boolean;
  version?: AppCertVersion;
  minChildren?: number;
  maxChildren?: number;
}

export interface AllowedVersion {
  id: number;
}

export interface AppCertification extends BasicAppCertification {
  isPreReq?: boolean;
  version: AppCertVersion;
  capabilities: AppCapability[];
  allowedVersions?: AllowedVersion[];
  conformanceTestRequested?: boolean;
  interoperabilityTestRequested?: boolean;
  conformanceTestRequestedByOtherLab?: boolean;
  interoperabilityTestRequestedByOtherLab?: boolean;
}

export type PossiblePrimaries = { id: number; name: string };

export interface AppCertPrereqs {
  certification: BasicCertification;
  allowedVersions: AllowedVersion[];
  type: CertificationType;
  possiblePrimaries: PossiblePrimaries[];
}

export interface TestingException {
  exceptionType?: string;
  explanation: string;
  attachments: UploadedFile[];
  requestedBy?: {
    name: string;
    company: { id: number; name: string };
  };
}
export interface CoreCCException {
  exceptionType?: string;
  explanation: string;
  attachments: UploadedFile[];
  requestedBy?: {
    name: string;
    company: { id: number; name: string };
  };
}

export interface Legacy {
  terms: boolean;
  agreement: boolean;
}

export enum AppFlowSteps {
  PRODUCT = 'product',
  CC = 'cc',
  CERTIFICATIONS = 'certifications',
  CAPABILITIES = 'capabilities',
  LABS = 'labs',
  PAYMENT = 'payment',
  REVIEW = 'review',
}

export type StepsValidation = {
  [x in AppFlowSteps]: boolean;
};

export enum AppFlows {
  NEW = 'NEW',
  DERIVATIVE = 'DERIVATIVE',
  VARIANT = 'VARIANT',
}

export enum CERTIFY_PRODUCT_COMPANY {
  MY_COMPANY = 'MY_COMPANY',
  ON_BEHALF_OF = 'ON_BEHALF_OF',
}

export interface CompanyContactInfo {
  additionalEmail: string[];
  source: DerivativeType;
  owningCompany: {
    id: number;
    name: string;
    requiredPurchaseOrder: boolean;
    solutionProvider?: boolean;
  } | null;
  creatingCompany?: {
    id: number;
    name: string;
    solutionProvider?: boolean;
  } | null;
  applicationOwner: {
    '@type': CERTIFY_PRODUCT_COMPANY;
  };
}

export interface CompareCCInfo {
  initialCC: ComponentCombination;
  showConfirmNotes: boolean;
  showVariantWarning: string[];
}

export interface AdditionalInfo {
  po: string;
  ctia: string;
  payer: {
    id: number;
  };
}

export interface TestingInfo {
  testingLabs: TestingInfoLab[];
  testingException: TestingException | null;
  coreCCException?: CoreCCException | null;
}

export interface CoreCCExceptionInfo {
  coreCCException: CoreCCException | null;
}

export type CombinationVariantDetails = {
  id: number | null;
  name: string | null;
  company: {
    id: number | null;
    name: string | null;
  };
  product: {
    id: number | null;
    name: string | null;
    cid: string | null;
  };
  productType?: ProductTypes;
  coreCCChanged: boolean;
  coreCCExceptionAvailable: boolean;
  coreCCExceptionRequested: boolean;
  componentCombination: CCInfoRecord;
};

export enum ComponentCombinationSource {
  BASE = 'Base',
  LOADED = 'Loaded',
}

export interface ComponentCombinationWrapper<T extends ComponentCombination | CCInfoRecord> {
  '@type'?: ComponentCombinationSource;
  componentCombination: T;
  componentCombinationVariantSource?: CombinationVariantDetails;
}

export type ReviewSectionEditState = {
  productInfo: boolean;
  variantInfo: boolean;
};

export type ApplicationFlowState = {
  id: number | null;
  '@type': AppFlows;
  formId: string | number;
  status: string;
  certifyProductCompany: CERTIFY_PRODUCT_COMPANY;
  companyContactInfo: CompanyContactInfo;
  productInfo: ProductInfoStep;
  majorEvents: MajorEvents;
  componentCombination: ComponentCombinationWrapper<ComponentCombination>;
  certifications: AppCertification[];
  payment: PaymentData;
  errors: ErrorObject;
  showPopup: boolean;
  certificationsLoading: boolean;
  validated: StepsValidation;
  certificationsLabs: LabsResponse[];
  compareCCInfo: CompareCCInfo;
  legacy: Legacy;
  acknowledged: boolean;
  additionalInfo: AdditionalInfo;
  selectedTestingLabs: TestingLab[];
  testingInfo: TestingInfo;
  autoValidateCapability: boolean;
  capabilitisByCC: GetCapabilitiesByCC[];
  reviewSectionEditState: ReviewSectionEditState;
  fees: Fee[];
  componentChangeNote?: string;
};

const validationModel = prepareYupModel<{
  productInfo: ProductInfoStep;
  componentCombination: {
    componentCombination: ComponentCombination;
  };
}>(ApplicationScheme);

export const applicationFlowReducer = (
  state: ApplicationFlowState = JSON.parse(JSON.stringify(initialState)),
  action: AnyAction,
) => {
  const stateCopy = jsonCopy(state);
  if (stateCopy.componentCombination === null) {
    stateCopy.componentCombination = jsonCopy(initialState.componentCombination);
  }

  if (stateCopy.componentCombination.componentCombination === null) {
    stateCopy.componentCombination.componentCombination = jsonCopy(
      initialState.componentCombination.componentCombination,
    );
  }

  if (!stateCopy.reviewSectionEditState) {
    stateCopy.reviewSectionEditState = jsonCopy(initialState.reviewSectionEditState);
  }

  switch (action.type) {
    case NEW_APPLICATION_STARTED:
      return mergeObjects(state, action.payload);
    case APPLICATION_FOR_EDIT_LOADED:
      const application = action.application as ApplicationFlowState;
      const cc: ComponentCombination = updateCCWithOrder(
        application.componentCombination.componentCombination,
        state.componentCombination.componentCombination,
      );

      const acknowledged = Boolean(application.id) || application.acknowledged;
      const additionalInfo = mergeObjects(initialState.additionalInfo, application.additionalInfo || {});
      const productInfo = mergeObjects(initialState.productInfo, application.productInfo || {});

      if (!isProductOfSolutionProvider(application) || application['@type'] === AppFlows.DERIVATIVE) {
        productInfo.certified = true;
        productInfo.qualifiedSolution = false;
        productInfo.modelVariants[0].availableAsQualifiedSolution = false;
      }

      if (action.application.applicationInfo?.decision === DecisionType.QUALIFY) {
        productInfo.modelVariants[0].searchableByPublic = false;
      }

      if (
        action.application['@type'] === AppFlows.DERIVATIVE &&
        (action.application.certifyProductCompany === CERTIFY_PRODUCT_COMPANY.MY_COMPANY ||
          (action.application.companyContactInfo.applicationOwner &&
            action.application.companyContactInfo.applicationOwner['@type'] !== CERTIFY_PRODUCT_COMPANY.ON_BEHALF_OF))
      ) {
        additionalInfo.payer.id = action.application.companyContactInfo.owningCompany?.id;
      }

      return {
        id: action.id,
        certifications: [],
        validated: {
          product: false,
          cc: false,
          certifications: false,
          capabilities: false,
          labs: false,
          payment: false,
        },
        ...application,
        additionalInfo,
        productInfo,
        acknowledged,
        componentCombination: {
          '@type': application.componentCombination['@type'],
          componentCombination: cc === null ? { newRoles: [] } : cc,
          componentCombinationVariantSource: application.componentCombination.componentCombinationVariantSource,
        },
        selectedTestingLabs: application.selectedTestingLabs || [],
        errors: {},
        showPopup: true,
        certificationsLoading: false,
        compareCCInfo: mergeObjects(initialState.compareCCInfo, {
          initialCC: application.componentCombination.componentCombination,
        }),
        legacy: initialState.legacy,
      };
    case SET_APPLICATION_ID:
      const id = action.payload as number;
      return mergeObjects(state, { id, formId: id });
    case SET_VALIDATION_STATE:
      return mergeObjects(state, { validated: action.payload as Partial<StepsValidation> });
    case RESTORE_APPLICATION_DATA:
      return jsonCopy(initialState);
    case UPDATE_APPLICATION_PROPERTY:
      const { value, name, clear, step, skipValidation } = action.payload;
      dot.set(name, value, stateCopy);
      const errors = skipValidation ? {} : validationModel.checkForFieldFormatted(name, stateCopy);
      let updateValidationState = {} as Partial<StepsValidation>;
      if (clear === MemberClearData.PAYMENT) {
        stateCopy.payment = jsonCopy(initialState.payment);
        updateValidationState.payment = false;
      } else if (clear === MemberClearData.LABS) {
        stateCopy.testingInfo.testingLabs = [];
        stateCopy.errors = filterErrors(stateCopy.errors, 'testingDetails');
        updateValidationState = {
          labs: false,
          payment: false,
        };
      } else if (clear === MemberClearData.CERTIFICATIONS) {
        stateCopy.certifications = [];
        stateCopy.payment = jsonCopy(initialState.payment);
        stateCopy.selectedTestingLabs = [];
        stateCopy.testingInfo = {
          testingLabs: [],
          testingException: null,
        };
        stateCopy.errors = filterErrors(stateCopy.errors, 'testingDetails');
        updateValidationState = {
          certifications: false,
          capabilities: false,
          labs: false,
          payment: false,
        };
      }

      if (step !== null) {
        stateCopy.validated = mergeObjects(stateCopy.validated, {
          ...updateValidationState,
          [step as AppFlowSteps]: false,
        });
      }

      if (stateCopy?.productInfo?.qualifiedSolution === false) {
        stateCopy.productInfo.certified = true;
        stateCopy.productInfo.modelVariants[0].availableAsQualifiedSolution = false;
      }

      if (
        stateCopy?.productInfo?.certified === false &&
        stateCopy?.productInfo?.modelVariants[0].draftCertified === false
      ) {
        stateCopy.productInfo.modelVariants[0].searchableByPublic = false;
        stateCopy.productInfo.modelVariants[0].availableAsDerivative = false;
      }

      if (!isProductOfSolutionProvider(stateCopy)) {
        stateCopy.productInfo.modelVariants[0].availableAsQualifiedSolution = false;
      }

      return mergeObjects(stateCopy, { errors });
    case REMOVE_CC_ROLE:
      const { roleName, index } = action.payload;

      stateCopy.componentCombination.componentCombination = dropCCRole(
        stateCopy.componentCombination.componentCombination,
        roleName,
        index,
      );
      const existingErrors = dot.object(state.errors);
      // @ts-ignore
      dot.delete(`componentCombination.componentCombination.${roleName}[${index}]`, existingErrors);
      stateCopy.compareCCInfo = mergeObjects(state.compareCCInfo, {
        showVariantWarning: stateCopy.compareCCInfo.showVariantWarning.filter(item =>
          item.includes(`${roleName}${index}`),
        ),
        initialCC: stateCopy.componentCombination.componentCombination,
      });
      const reIndexedErrors = dot.dot(existingErrors);
      stateCopy.errors = reIndexedErrors;

      // clear certifications step and further
      if (stateCopy.certifications.length) {
        stateCopy.certifications = [];
        stateCopy.payment = jsonCopy(initialState.payment);
        stateCopy.testingInfo = {
          testingLabs: [],
          testingException: null,
        };
        stateCopy.errors = filterErrors(stateCopy.errors, 'testingDetails');
      }
      // reset step validation state
      stateCopy.validated = mergeObjects(stateCopy.validated, {
        cc: false,
        certifications: false,
        labs: false,
        payment: false,
      });

      return stateCopy;
    case ADD_CC_ROLE:
      const item = { roleName: '', sortOrder: action.payload.sortOrder + 1 };
      stateCopy.componentCombination.componentCombination.newRoles = [item];
      // clear certifications step and further
      if (stateCopy.certifications.length > 0) {
        stateCopy.certifications = [];
        stateCopy.payment = jsonCopy(initialState.payment);
        stateCopy.testingInfo = {
          testingLabs: [],
          testingException: null,
        };
        stateCopy.errors = filterErrors(stateCopy.errors, 'testingDetails');
      }
      // reset step validation state
      stateCopy.validated = mergeObjects(stateCopy.validated, {
        cc: false,
        certifications: false,
        labs: false,
        payment: false,
      });

      return stateCopy;
    case CHANGE_CC_ROLE:
      // clear certifications step and further
      if (stateCopy.certifications.length > 0) {
        stateCopy.testingInfo = {
          testingLabs: [],
          testingException: null,
        };
        stateCopy.certifications = [];
        stateCopy.payment = jsonCopy(initialState.payment);
        stateCopy.errors = filterErrors(stateCopy.errors, 'testingDetails');
      }
      // reset step validation state
      stateCopy.validated = mergeObjects(stateCopy.validated, {
        cc: false,
        certifications: false,
        capabilities: false,
        labs: false,
        payment: false,
      });

      const { newRole, currentRole, arrayIndex, lastOrderNumber } = action.payload;

      if (newRole === roleNameMapping.homeDesign) {
        stateCopy.componentCombination.componentCombination = {
          ...jsonCopy(initialState).componentCombination.componentCombination,
          homeDesign: [jsonCopy(homeDesignProps)],
        };
        stateCopy.errors = {};
        return stateCopy;
      }

      const notAPLikeRoles = ['newRoles', roleNameMapping.easyMeshController, roleNameMapping.homeDesign];
      // if we are changing APLike role to APLike role
      if (notAPLikeRoles.every(item => item !== newRole && item != currentRole)) {
        // @ts-ignore
        const item = dot.delete(`${currentRole}[${arrayIndex}]`, stateCopy.componentCombination.componentCombination);
        if (!stateCopy.componentCombination.componentCombination[newRole as keyof ComponentCombination]) {
          (stateCopy.componentCombination.componentCombination[
            newRole as keyof ComponentCombination
          ] as OtherRolesProps[]) = [];
        }
        (stateCopy.componentCombination.componentCombination[
          newRole as keyof ComponentCombination
        ] as OtherRolesProps[]).push(item);
        return stateCopy;
      }

      if (currentRole === roleNameMapping.homeDesign) {
        stateCopy.componentCombination.componentCombination.homeDesign = null;
      } else {
        // @ts-ignore
        dot.delete(`${currentRole}[${arrayIndex}]`, stateCopy.componentCombination.componentCombination);
      }

      if (currentRole === 'newRoles') {
        delete stateCopy.errors['componentCombination.componentCombination.newRoles'];
        delete stateCopy.errors['componentCombination.componentCombination.newRoles[0].roleName'];
      }

      if (newRole === roleNameMapping.easyMeshController) {
        const length = stateCopy.componentCombination.componentCombination.easyMeshController?.length;
        if (stateCopy.componentCombination.componentCombination.easyMeshController && length) {
          const item = stateCopy.componentCombination.componentCombination.easyMeshController[length - 1];
          stateCopy.componentCombination.componentCombination.easyMeshController.push(jsonCopy(item));
        } else {
          stateCopy.componentCombination.componentCombination.easyMeshController = [
            { ...jsonCopy(easyMeshProps), sortOrder: lastOrderNumber + 1 },
          ];
        }
        return stateCopy;
      } else {
        const cc: WiFiComponents = stateCopy.componentCombination.componentCombination;
        const array: OtherRolesProps[] = Object.keys(cc).reduce((prev, curr) => {
          if (!notAPLikeRoles.includes(curr)) {
            const roleItem = cc[curr as keyof ComponentCombination];
            if (roleItem && Array.isArray(roleItem)) {
              prev.push(...(roleItem as OtherRolesProps[]));
            }
            return prev;
          }
          return prev;
        }, [] as OtherRolesProps[]);
        const length = array.length;
        const item: OtherRolesProps =
          length > 0
            ? { ...jsonCopy(array[length - 1]), sortOrder: lastOrderNumber + 1 }
            : { ...jsonCopy(otherRoleProps), sortOrder: lastOrderNumber + 1 };
        if (!stateCopy.componentCombination.componentCombination[newRole as keyof ComponentCombination]) {
          (stateCopy.componentCombination.componentCombination[
            newRole as keyof ComponentCombination
          ] as OtherRolesProps[]) = [];
        }
        (stateCopy.componentCombination.componentCombination[
          newRole as keyof ComponentCombination
        ] as OtherRolesProps[]).push(item);
        return stateCopy;
      }
    case ADD_RF_BAND:
      const role = (action.payload.roleName as keyof WiFiComponents) as string;
      if (![roleNameMapping.easyMeshController, roleNameMapping.homeDesign].includes(role)) {
        const roleComponentCombination = stateCopy.componentCombination.componentCombination[role];
        if (roleComponentCombination) {
          (roleComponentCombination[action.payload.index] as OtherRolesProps).core.rfArchitecture.push(
            action.payload.data,
          );
        }

        if (stateCopy.certifications.length > 0) {
          stateCopy.testingInfo = {
            testingLabs: [],
            testingException: null,
          };
          stateCopy.certifications = [];
          stateCopy.payment = jsonCopy(initialState.payment);
          stateCopy.errors = filterErrors(stateCopy.errors, 'testingDetails');
        }
        // reset step validation state
        stateCopy.validated = mergeObjects(stateCopy.validated, {
          cc: false,
          certifications: false,
          labs: false,
          payment: false,
        });

        delete stateCopy.errors[
          `componentCombination.componentCombination.${action.payload.roleName}[${action.payload.index}].core.rfArchitecture`
        ];
      }

      return stateCopy;
    case REMOVE_RF_BAND:
      // @ts-ignore
      dot.delete(action.payload, stateCopy);
      if (stateCopy.certifications.length > 0) {
        stateCopy.certifications = [];
        stateCopy.payment = jsonCopy(initialState.payment);
        stateCopy.testingInfo = {
          testingLabs: [],
          testingException: null,
        };
        stateCopy.errors = filterErrors(stateCopy.errors, 'testingDetails');
      }
      // reset step validation state
      stateCopy.validated = mergeObjects(stateCopy.validated, {
        cc: false,
        certifications: false,
        labs: false,
        payment: false,
      });

      return stateCopy;
    case SET_SHOW_POPUP:
      stateCopy.showPopup = action.payload;
      return stateCopy;
    case SET_APPLICATION_VALIDATION_ERRORS:
      if (Object.keys(action.payload).length === 0 && action.payload.constructor === Object) {
        // use action.payload = {} to reset errors
        stateCopy.errors = action.payload;
        return stateCopy;
      }
      return mergeObjects(state, { errors: action.payload });
    case UPDATE_CERTIFICATIONS:
      return mergeObjects(state, {
        certificationsLoading: false,
        certifications: action.payload,
        showPopup: true,
      });
    case SET_AUTO_VALIDATE_CAPABILITY:
      stateCopy.autoValidateCapability = action.payload;
      return stateCopy;
    case SET_CAPABILITIES_BY_CC:
      stateCopy.capabilitisByCC = action.payload;
      return stateCopy;
    case CHECK_CERTIFICATION: {
      const { certification, preReqs, cleanup } = action.payload;
      stateCopy.certificationsLoading = false;
      // add pre-reqs (or mark as pre-req if checked)
      (preReqs as AppCertification[]).forEach(cert => {
        const primaryIdx = stateCopy.certifications.findIndex(item => item.id === cert.id);
        if (primaryIdx !== -1) {
          stateCopy.certifications[primaryIdx].isPreReq = true;
          stateCopy.certifications[primaryIdx].allowedVersions = getAllowedVersions(
            stateCopy.certifications[primaryIdx],
            cert,
          );
          stateCopy.certifications[primaryIdx].version = cert.version;
        } else {
          stateCopy.certifications.push(cert);
        }
      });
      const idx = stateCopy.certifications.findIndex(el => el.id === certification.id);
      if (idx !== -1) {
        stateCopy.certifications[idx] = certification;
      } else {
        stateCopy.certifications.push(certification);
      }
      // reset step validation state
      stateCopy.validated = mergeObjects(stateCopy.validated, {
        certifications: false,
        capabilities: false,
        labs: false,
        payment: false,
      });
      if (cleanup) {
        stateCopy.errors = {};
        stateCopy.selectedTestingLabs = [];
        return mergeObjects(stateCopy, {
          certifications: stateCopy.certifications.map(el => ({ ...el, capabilities: [] })),
          testingInfo: {
            testingException: null,
            testingLabs: [],
          },
        });
      }
      return stateCopy;
    }
    case CHECK_CAPABILITY: {
      const { additions, prereqs, cleanup } = action.payload as {
        capability: AppCapability;
        additions: {
          id: number;
          capabilities: AppCapability[];
        }[];
        prereqs: PreReqTree[];
        cleanup: boolean;
      };
      prereqs.forEach(cert => {
        const idx = stateCopy.certifications.findIndex(certification => certification.version.id === cert.id);
        if (idx !== -1) {
          cert.capabilities.forEach(capability => {
            const idxCap = stateCopy.certifications[idx].capabilities.findIndex(el => capability.id === el.id);
            if (idxCap !== -1) {
              const item = stateCopy.certifications[idx].capabilities[idxCap];
              stateCopy.certifications[idx].capabilities[idxCap] = {
                ...item,
                isPreReq: true,
                preReqOf: Array.from(new Set([...(item.preReqOf || []), ...(capability.preReqOf || [])])),
              };
            } else {
              stateCopy.certifications[idx].capabilities.push({ ...capability });
            }
          });
        }
      });

      stateCopy.certifications.forEach(cert => {
        const idx = additions.findIndex(addition => addition.id === cert.version.id);
        if (idx !== -1) {
          additions[idx].capabilities.forEach(capabilityItem => {
            // single select, clear other options (include children) first
            if (isSingleSelectCapability(additions, stateCopy.capabilitisByCC)) {
              const topParentCapability = getParentCapability(additions, stateCopy.capabilitisByCC);
              if (topParentCapability) {
                cert.capabilities = cert.capabilities.filter(
                  el =>
                    !isChildOfParentCapability(
                      cert.version.id,
                      el.id,
                      topParentCapability.capability.id,
                      stateCopy.capabilitisByCC,
                    ),
                );
              }
            }

            const capIdx = cert.capabilities.findIndex(
              el => el.id === capabilityItem.id && el.index === capabilityItem.index,
            );
            const isParent = isParentPresent(capabilityItem, cert.capabilities);

            if (capIdx !== -1 && isParent) {
              cert.capabilities[capIdx] = capabilityItem;
            } else if (isParent || capabilityItem.mandatory === false) {
              cert.capabilities.push(capabilityItem);
            }
          });
        }
      });

      stateCopy.validated = mergeObjects(stateCopy.validated, {
        capabilities: false,
        labs: false,
        payment: false,
      });
      if (cleanup) {
        stateCopy.errors = {};
        stateCopy.selectedTestingLabs = [];
        return mergeObjects(stateCopy, {
          testingInfo: {
            testingException: null,
            testingLabs: [],
          },
        });
      }
      return stateCopy;
    }
    case UN_CHECK_CAPABILITY: {
      const { capability, cleanup } = action.payload;
      stateCopy.certifications.forEach(certification => {
        const sameCapability = certification.capabilities.find(el => el.id == capability.id);
        if (sameCapability == undefined) return;
        const displayParents: number[] = certification.capabilities.reduce(
          (accum, current) => {
            if (accum.includes(current.displayParent as number)) {
              accum.push(current.index as number);
            }
            return accum;
          },
          [sameCapability.index as number],
        );
        certification.capabilities = certification.capabilities
          .map(el => {
            if (el.preReqOf?.length && el.preReqOf?.length > 1 && el.preReqOf?.includes(capability.id)) {
              const preReqOf = (el.preReqOf || []).filter(item => item !== capability.id);
              return { ...el, preReqOf, isPreReq: preReqOf.length > 0 };
            }
            return { ...el };
          })
          .filter(capabilityItem => {
            if (capabilityItem.mandatory || capabilityItem.preReqOf?.length) {
              return true;
            }
            if (displayParents.length > 1 && capabilityItem.id !== capability.id) {
              return (
                !displayParents.includes(capabilityItem.displayParent as number) &&
                !(
                  capabilityItem.preReqOf ||
                  (capabilityItem.preReqOf && capabilityItem.preReqOf[0] === capability.id)
                ) &&
                !capabilityItem.mandatory
              );
            }
            return capabilityItem.id !== capability.id;
          });
      });
      stateCopy.validated = mergeObjects(stateCopy.validated, {
        capabilities: false,
        labs: false,
        payment: false,
      });
      if (cleanup) {
        stateCopy.errors = {};
        stateCopy.selectedTestingLabs = [];
        return mergeObjects(stateCopy, {
          testingInfo: {
            testingException: null,
            testingLabs: [],
          },
        });
      }
      return stateCopy;
    }
    case UN_CHECK_CERTIFICATION: {
      const { certification, preReqs, cleanup } = action.payload as {
        certification: AppCertification;
        preReqs: TreeToEnable[];
        cleanup: boolean;
      };
      stateCopy.certificationsLoading = false;
      stateCopy.certifications = stateCopy.certifications.filter(item => item.id !== certification.id);
      stateCopy.certifications.forEach(cert => {
        const preReqItem = preReqs.find(item => item.id === cert.id);
        if (preReqItem && preReqItem.enable) {
          cert.isPreReq = false;
          delete cert.allowedVersions;
        }
      });

      // unlocking disabled certification's versions in case when the certification is also a prerequisite of other certification
      preReqs.forEach(preReqCert => {
        const otherPrerequisites = [] as AllowedVersion[][];

        // looking for prerequisites in other certifications
        stateCopy.certifications.forEach(cert => {
          const foundPrerequisites = cert.version.prerequisites?.find(item => item.certification.id === preReqCert.id);
          if (foundPrerequisites && foundPrerequisites.allowedVersions?.length > 0) {
            otherPrerequisites.push(foundPrerequisites.allowedVersions);
          }
        });

        // getting and assigning the most restricted allowed versions
        if (otherPrerequisites.length > 0) {
          const minLength = Math.min(...otherPrerequisites.map(versions => versions.length));
          const allowedVersions = otherPrerequisites.find(versions => versions.length === minLength);
          const certIndex = stateCopy.certifications.findIndex(value1 => value1.id == preReqCert.id);

          if (allowedVersions && certIndex !== -1) {
            stateCopy.certifications[certIndex].allowedVersions = allowedVersions;
          }
        }
      });

      // reset step validation state
      stateCopy.validated = mergeObjects(stateCopy.validated, {
        certifications: false,
        capabilities: false,
        labs: false,
        payment: false,
      });
      if (cleanup) {
        stateCopy.selectedTestingLabs = [];
        stateCopy.errors = {};
        return mergeObjects(stateCopy, {
          certifications: stateCopy.certifications.map(el => ({ ...el, capabilities: [] })),
          testingInfo: {
            testingException: null,
            testingLabs: [],
          },
        });
      }
      return stateCopy;
    }
    case UN_CHECK_CERT_TREE: {
      const { tree, cleanup } = action.payload as { tree: TreeToUncheck[]; cleanup: boolean };
      stateCopy.certifications = stateCopy.certifications
        .map(el => ({
          ...el,
          isPreReq: tree.some(item => item.id === el.id)
            ? !tree.some(item => item.id === el.id && !item.keep)
            : el.isPreReq,
        }))
        .filter(
          cert =>
            !tree.some(item => item.id === cert.id && !item.keep && !cert.prequalified && !cert.previouslySelected),
        );
      // reset step validation state
      stateCopy.validated = mergeObjects(stateCopy.validated, { certifications: false, labs: false, payment: false });
      if (cleanup) {
        stateCopy.errors = {};
        return mergeObjects(stateCopy, {
          testingInfo: {
            testingLabs: [],
            testingException: null,
          },
          errors: {},
        });
      }
      return stateCopy;
    }
    case RESET_CERTIFICATIONS: {
      return mergeObjects(state, {
        certifications: state.certifications
          .filter(item => item.prequalified)
          .map(item => ({ ...item, capabilities: [] })),
        testingInfo: {
          testingLabs: [],
          testingException: null,
        },
        selectedTestingLabs: [],
        errors: {},
        certificationsLoading: false,
        validated: {
          certifications: false,
          capabilities: false,
          labs: false,
          payment: false,
        },
      });
    }
    case SET_CERTIFICATIONS_LOADER: {
      return mergeObjects(state, { certificationsLoading: action.payload });
    }
    case UPDATE_APPLICATION_EXPLANATION:
      return mergeObjects(state, {
        errors: {},
        testingInfo: {
          testingException: action.payload ? { ...state.testingInfo.testingException, ...action.payload } : null,
        },
        validated: {
          labs: false,
          payment: false,
        },
      });

    case UPDATE_APPLICATION_CORE_CC_EXPLANATION:
      if (action.payload) {
        return mergeObjects(state, {
          errors: {},
          testingInfo: {
            coreCCException: action.payload ? { ...state.testingInfo.coreCCException, ...action.payload } : null,
          },
          validated: {
            labs: false,
            payment: false,
          },
        });
      } else {
        stateCopy.testingInfo = {
          testingException: state.testingInfo.testingException,
          testingLabs: state.testingInfo.testingLabs,
        };
        return stateCopy;
      }

    case UPDATE_APPLICATION_CORE_CC_EXCEPTION_REQUESTED:
      return mergeObjects(state, {
        errors: {},
        componentCombination: {
          componentCombinationVariantSource: {
            coreCCExceptionRequested: action.payload,
          },
        },
        validated: {
          labs: false,
          payment: false,
        },
      });

    case UPDATE_PAYMENT_DATA:
      return {
        ...state,
        payment: action.payload,
        showPopup: true,
      };
    case SELECT_ALL_CERT_FOR_LAB:
      const { lab, field, checked, certList } = action.payload as {
        lab: TestingItem;
        field: testingFieldTypes;
        checked: boolean;
        certList: number[];
      };
      certList.forEach(versionId => {
        const idx = stateCopy.testingInfo.testingLabs.findIndex(el => el.version.id === versionId);
        if (idx !== -1) {
          stateCopy.testingInfo.testingLabs[idx].testingDetails[field] = checked ? lab : null;
        } else {
          const testingDetails =
            field === testingFieldTypes.conformance
              ? {
                  conformance: lab,
                  interoperability: null,
                }
              : {
                  conformance: null,
                  interoperability: lab,
                };
          const item = {
            version: {
              id: versionId,
            },
            testingDetails,
          };
          stateCopy.testingInfo.testingLabs.push(item);
        }
      });
      const { testingInfo, certifications, certificationsLabs } = stateCopy;
      stateCopy.errors = mergeObjects(
        stateCopy.errors,
        validateTestingInfo(certificationsLabs, certifications, testingInfo, field),
      );
      // reset step validation state
      stateCopy.validated = mergeObjects(stateCopy.validated, { labs: false, payment: false });

      return stateCopy;
    case SELECT_CERT_LAB_CHECKBOX: {
      const { lab, certVersionId, field } = action.payload;
      const idx = stateCopy.testingInfo.testingLabs.findIndex(el => el.version.id === certVersionId);
      if (idx !== -1) {
        stateCopy.testingInfo.testingLabs[idx].testingDetails[field as testingFieldTypes] = lab;
      } else {
        stateCopy.testingInfo.testingLabs.push({
          version: {
            id: certVersionId,
          },
          testingDetails: {
            interoperability: field === testingFieldTypes.interoperability ? lab : null,
            conformance: field === testingFieldTypes.conformance ? lab : null,
          },
        });
      }
      const { testingInfo, certifications, certificationsLabs } = stateCopy;
      stateCopy.errors = mergeObjects(
        stateCopy.errors,
        validateTestingInfo(certificationsLabs, certifications, testingInfo, field as testingFieldTypes, certVersionId),
      );
      // reset step validation state
      stateCopy.validated = mergeObjects(stateCopy.validated, { labs: false, payment: false });

      return stateCopy;
    }
    case UPDATE_SELECTED_TESTING_LABS:
      stateCopy.selectedTestingLabs = action.payload.selectedTestingLabs;
      return stateCopy;
    case REMOVE_SELECTED_TESTING_LAB:
      const result = getUpdatedCertsWithErrors(stateCopy.testingInfo, action.payload.labId);
      stateCopy.testingInfo.testingLabs = result.updatedTestingLabs;
      stateCopy.errors = mergeObjects(stateCopy.errors, result.validationResult);
      const labsToUpdate = stateCopy.selectedTestingLabs.filter(lab => lab.id !== action.payload.labId);
      stateCopy.selectedTestingLabs = labsToUpdate.length === 0 ? [{ ...defaultSelectedLabValue }] : labsToUpdate;
      stateCopy.validated = mergeObjects(stateCopy.validated, { labs: false, payment: false });
      return stateCopy;
    case ADD_SELECTED_TESTING_LAB:
      stateCopy.selectedTestingLabs.push({
        id: null,
        name: '',
        allowedTesting: {
          conformance: false,
          interoperability: false,
        },
      });
      stateCopy.validated = mergeObjects(stateCopy.validated, { labs: false, payment: false });
      return stateCopy;
    case CHANGE_NAME_SELECTED_TESTING_LAB:
      if (stateCopy.selectedTestingLabs[action.payload.index].id) {
        const result = getUpdatedCertsWithErrors(
          stateCopy.testingInfo,
          stateCopy.selectedTestingLabs[action.payload.index].id as number,
        );
        stateCopy.errors = mergeObjects(stateCopy.errors, result.validationResult);
        stateCopy.testingInfo.testingLabs = result.updatedTestingLabs;
      }
      dot.set(`selectedTestingLabs[${action.payload.index}]`, action.payload.lab, stateCopy);
      stateCopy.validated = mergeObjects(stateCopy.validated, { labs: false, payment: false });
      return stateCopy;
    case SET_LABS_RESPONSE:
      stateCopy.certificationsLabs = action.payload;
      return stateCopy;
    case SET_COMPARE_CC_INFO:
      return { ...stateCopy, compareCCInfo: { ...state.compareCCInfo, ...action.payload } };
    case SET_REVIEW_SECTIONS_EDIT_STATE:
      return { ...stateCopy, reviewSectionEditState: mergeObjects(stateCopy.reviewSectionEditState, action.payload) };
    case SET_FEES_RESPONSE:
      return { ...state, fees: action.payload };
    default:
      return state;
  }
};
