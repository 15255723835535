import { NewRoles, ProductInfoStep } from '../../../../api/application/types';
import { Schema } from 'yup';
import * as Yup from 'yup';
import i18n from '../../../../helpers/i18n-setup';
import { CreateApplicationForm, EasyMeshController, HomeDesignProps, OtherRolesProps } from '../types';
import { AppFlows, ApplicationFlowState, CERTIFY_PRODUCT_COMPANY } from '../../../../redux/modules/application-flow';
import { isProductOfSolutionProvider } from '../partials/new-application-flow-steps/helpers';

export const modelVariantsScheme = (context?: ApplicationFlowState) =>
  Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required(i18n.t('common.validation.required'))
        .max(128, i18n.t('common.validation.length', { length: 128 })),
      searchableByPublic: Yup.mixed().required(i18n.t('common.validation.required')),
      availableAsDerivative: Yup.mixed().required(i18n.t('common.validation.required')),
      availableAsQualifiedSolution: Yup.mixed().test({
        name: 'is-required-available-as-qualified-solution',
        test: function(value) {
          const isRequired = context ? isProductOfSolutionProvider(context) : false;
          return !(isRequired && value === null);
        },
        message: i18n.t('common.validation.required'),
      }),
      productType: Yup.mixed().test({
        name: 'product-type',
        test: () => true,
        message: i18n.t('common.validation.required'),
      }),
    }),
  );

export const productScheme = (context?: ApplicationFlowState): Schema<ProductInfoStep> =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(128, i18n.t('common.validation.length', { length: 128 }))
      .required(i18n.t('common.validation.required')),
    modelNumber: Yup.string()
      .trim()
      .required(i18n.t('common.validation.required'))
      .max(128, i18n.t('common.validation.length', { length: 128 })),
    description: Yup.string().trim(),
    primaryCategory: Yup.object()
      .shape({ id: Yup.number() })
      .nullable()
      .required(i18n.t('common.validation.required')),
    additionalCategories: Yup.array(),
    url: Yup.string()
      .nullable()
      .trim()
      .matches(/^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/, {
        message: i18n.t('common.validation.URL'),
        excludeEmptyString: true,
      }),
    image: Yup.mixed().nullable(),
    modelVariants: modelVariantsScheme(context),
    qualifiedSolution: Yup.mixed().test({
      name: 'is-required-qualified-solution',
      test: function(value) {
        const isRequired = context ? isProductOfSolutionProvider(context) : false;
        return !(isRequired && value === null);
      },
      message: i18n.t('common.validation.required'),
    }),
    certified: Yup.mixed().test({
      name: 'is-required-certified',
      test: function(value) {
        const isRequired = context ? isProductOfSolutionProvider(context) : false;
        return !(isRequired && value === null);
      },
      message: i18n.t('common.validation.required'),
    }),
    documents: Yup.array(),
  });

const sourceScheme = Yup.object().shape({
  id: Yup.number()
    .positive(i18n.t('common.validation.required'))
    .required(i18n.t('common.validation.required')),
  cid: Yup.string().trim(),
});

const initialAppScheme = Yup.object().shape({
  additionalEmail: Yup.array().of(Yup.string().trim()),
  source: sourceScheme,
  owningCompany: Yup.object().nullable(),
});

const anotherCompanyScheme = initialAppScheme.concat(
  Yup.object().shape({
    owningCompany: Yup.object()
      .shape({
        id: Yup.number(),
        name: Yup.string(),
      })
      .nullable()
      .required(i18n.t('common.validation.required')),
  }),
);

export const applicationOptionsScheme = Yup.object<CreateApplicationForm>().shape({
  type: Yup.string().test({
    name: 'is-required',
    test: function(value) {
      return !(value === '' && this.parent.companyContactInfo.owningCompany !== null);
    },
    message: i18n.t('common.validation.required'),
  }),
  certifyProductCompany: Yup.string(),
  companyContactInfo: Yup.object()
    .when(['certifyProductCompany', 'type'], {
      is: (company, type) => {
        return company === CERTIFY_PRODUCT_COMPANY.ON_BEHALF_OF && type === '';
      },
      then: anotherCompanyScheme.concat(
        Yup.object().shape({
          source: Yup.object().nullable(),
        }),
      ),
    })
    .when(['certifyProductCompany', 'type'], {
      is: (company, type) => {
        return company === CERTIFY_PRODUCT_COMPANY.MY_COMPANY && type === AppFlows.DERIVATIVE;
      },
      then: initialAppScheme,
    })
    .when(['certifyProductCompany', 'type'], {
      is: (company, type) => {
        return company === CERTIFY_PRODUCT_COMPANY.ON_BEHALF_OF && type === AppFlows.DERIVATIVE;
      },
      then: anotherCompanyScheme,
    })
    .when(['certifyProductCompany', 'type'], {
      is: (company, type) => {
        return company === CERTIFY_PRODUCT_COMPANY.MY_COMPANY && [AppFlows.NEW].includes(type);
      },
      then: initialAppScheme.concat(
        Yup.object().shape({
          source: Yup.object().nullable(),
        }),
      ),
      otherwise: initialAppScheme.concat(
        Yup.object().shape({
          source: sourceScheme.concat(
            Yup.object().shape({
              productId: Yup.number(),
            }),
          ),
        }),
      ),
    })
    .when(['certifyProductCompany', 'type'], {
      is: (company, type) => {
        return company === CERTIFY_PRODUCT_COMPANY.ON_BEHALF_OF && [AppFlows.NEW].includes(type);
      },
      then: anotherCompanyScheme.concat(
        Yup.object().shape({
          source: Yup.object().nullable(),
        }),
      ),
      otherwise: anotherCompanyScheme.concat(
        Yup.object().shape({
          source: sourceScheme.concat(
            Yup.object().shape({
              productId: Yup.number(),
            }),
          ),
        }),
      ),
    }),
});

export const APLikeRoleValidationScheme = Yup.array()
  .of(
    Yup.object().shape<OtherRolesProps>({
      '@type': Yup.string().trim(),
      roleName: Yup.string()
        .trim()
        .max(64, i18n.t('common.validation.length', { length: 64 })),
      antenna: Yup.string()
        .trim()
        .max(128, i18n.t('common.validation.length', { length: 128 }))
        .required(i18n.t('common.validation.required')),
      firmware: Yup.string()
        .trim()
        .max(128, i18n.t('common.validation.length', { length: 128 }))
        .required(i18n.t('common.validation.required')),
      core: Yup.object().shape({
        chipset: Yup.string()
          .trim()
          .max(128, i18n.t('common.validation.length', { length: 128 }))
          .required(i18n.t('common.validation.required')),
        driver: Yup.string()
          .trim()
          .max(128, i18n.t('common.validation.length', { length: 128 }))
          .required(i18n.t('common.validation.required')),
        rfArchitecture: Yup.array()
          .of(Yup.object())
          .required(i18n.t('common.validation.choseAtLeast', { length: 'one' })),
      }),
      os: Yup.string()
        .trim()
        .max(128, i18n.t('common.validation.length', { length: 128 }))
        .required(i18n.t('common.validation.required')),
      physicalInterface: Yup.string()
        .trim()
        .max(128, i18n.t('common.validation.length', { length: 128 }))
        .required(i18n.t('common.validation.required')),
      rfComponents: Yup.string()
        .trim()
        .max(128, i18n.t('common.validation.length', { length: 128 }))
        .required(i18n.t('common.validation.required')),
    }),
  )
  .nullable();

export const EMCValidationScheme = Yup.array()
  .of(
    Yup.object().shape<EasyMeshController>({
      '@type': Yup.string(),
      os: Yup.string()
        .trim()
        .max(128, i18n.t('common.validation.length', { length: 128 }))
        .required(i18n.t('common.validation.required')),
    }),
  )
  .nullable();

export const HDValidationScheme = Yup.array()
  .of(
    Yup.object().shape<HomeDesignProps>({
      '@type': Yup.string(),
      units: Yup.number().nullable(),
      floors: Yup.number().nullable(),
      squareFootage: Yup.string()
        .trim()
        .required(i18n.t('common.validation.required')),
      apInterconnect: Yup.string()
        .trim()
        .required(i18n.t('common.validation.required')),
      numberOfAccessPoints: Yup.number().required(i18n.t('common.validation.required')),
      equipment: Yup.array().of(
        Yup.object().shape({
          id: Yup.number().required(i18n.t('common.validation.required')),
          variantId: Yup.number().required(i18n.t('common.validation.required')),
          cid: Yup.string()
            .trim()
            .required(i18n.t('common.validation.required')),
          modelNumber: Yup.string()
            .trim()
            .required(i18n.t('common.validation.required')),
          vendor: Yup.object().shape({
            id: Yup.number().required(i18n.t('common.validation.required')),
            name: Yup.string()
              .trim()
              .required(i18n.t('common.validation.required')),
          }),
        }),
      ),
    }),
  )
  .nullable();

export const wifiComponentValidationScheme = Yup.object().shape({
  accessPoint: APLikeRoleValidationScheme,
  station: APLikeRoleValidationScheme,
  configurator: APLikeRoleValidationScheme,
  display: APLikeRoleValidationScheme,
  source: APLikeRoleValidationScheme,
  easyMeshController: EMCValidationScheme,
  homeDesign: HDValidationScheme,
  newRoles: Yup.array()
    .of(
      Yup.object().shape<NewRoles>({
        roleName: Yup.string()
          .trim()
          .max(64, i18n.t('common.validation.length', { length: 64 }))
          .required(i18n.t('common.validation.required')),
      }),
    )
    .when(['accessPoint', 'station', 'configurator', 'source', 'easyMeshController', 'homeDesign', 'display'], {
      is: null,
      then: Yup.array().required(i18n.t('common.validation.required')),
    }),
});

export const testingExplanationScheme = Yup.object()
  .shape({
    explanation: Yup.string()
      .trim()
      .required(i18n.t('common.validation.required')),
    attachments: Yup.array(),
  })
  .nullable();

export const coreCCExplanationScheme = Yup.object()
  .shape({
    explanation: Yup.string()
      .trim()
      .required(i18n.t('common.validation.required')),
    attachments: Yup.array(),
  })
  .nullable();

export const applicationLegacyScheme = Yup.object().shape({
  terms: Yup.boolean().oneOf([true], i18n.t('common.validation.required')),
  agreement: Yup.boolean().oneOf([true], i18n.t('common.validation.required')),
});

export const CWGRFScheme = (isPORequired = false, isInvoiceToRequired = false) =>
  Yup.object().shape({
    po: Yup.string()
      .trim()
      .max(30, i18n.t('common.validation.length', { length: 30 }))
      .test({
        name: 'is-required-po-number',
        test: function(value): boolean {
          return isPORequired ? value.length > 0 : true;
        },
        message: i18n.t('common.validation.required'),
      }),
    ctia: Yup.string()
      .trim()
      .max(128, i18n.t('common.validation.length', { length: 128 })),
    payer: Yup.object().shape({
      id: Yup.mixed().test({
        name: 'is-required-payer-id',
        test: function(value): boolean {
          return isInvoiceToRequired ? !!value : true;
        },
        message: i18n.t('common.validation.required'),
      }),
    }),
  });

export const ApplicationScheme = Yup.object().shape({
  productInfo: productScheme(),
  componentCombination: Yup.object().shape({
    componentCombination: wifiComponentValidationScheme,
  }),
  testingInfo: Yup.object().shape({
    testingException: testingExplanationScheme,
    testingLabs: Yup.array(),
  }),
  legacy: applicationLegacyScheme,
  additionalInfo: CWGRFScheme(),
});
