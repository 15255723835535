import styled from 'styled-components';
import { OtherRolesProps } from '../../types';
import { useTranslation } from 'react-i18next';
import { BandSelect } from '../new-application-flow-steps/partials/cc-step/BandSelect';
import React from 'react';
import { Panel } from 'rsuite';
// @ts-ignore
import _variables from '../../../../../styles/_variables.scss';

export const RfCard = styled(Panel)`
  .rs-panel-body {
    background-color: ${_variables.lightGray};
    border-color: ${_variables.lightGray};
    min-height: 170px;

    table {
      width: 100%;

      tr th {
        padding-bottom: 0.5rem;
        color: ${_variables.textGray};
      }
      tr:not(:last-of-type) {
        td {
          padding: 0.5rem 0;
        }
      }
      tr:last-of-type {
        td {
          padding-top: 0.5rem;
        }
      }

      tr {
        td:first-child,
        th:first-child {
          text-align: left;
        }
        td:not(:first-child),
        th:not(:first-child) {
          text-align: right;
        }
      }
    }
  }
`;
const SelectWrapper = styled.div`
  margin-left: 3rem;
`;

export const RfViewComponent = ({ record, isEditable }: { record: OtherRolesProps; isEditable?: boolean }) => {
  const { t } = useTranslation();

  const getBandSelectBlock = (data?: number) =>
    isEditable ? (
      <SelectWrapper>
        <BandSelect />
      </SelectWrapper>
    ) : (
      data
    );

  return (
    <RfCard bordered>
      <table>
        <thead>
          <tr>
            <th>{t('applications.cc.rf.bands')}</th>
            <th>{t('applications.cc.rf.tx')}</th>
            <th>{t('applications.cc.rf.rx')}</th>
          </tr>
        </thead>
        <tbody>
          {record.core.rfArchitecture.map(item => (
            <tr key={item.band}>
              <td>{item.band}</td>
              <td>{getBandSelectBlock(item.tx)}</td>
              <td>{getBandSelectBlock(item.rx)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </RfCard>
  );
};
