import React from 'react';
import { Note } from './types';
import styled from 'styled-components';
import { Icon } from 'rsuite';
import { reformatDateToFormat } from '../../../helpers';

const CommentWrapper = styled.div`
  padding: 0.5rem;

  .note-header {
    display: flex;
    align-items: center;

    .rs-icon {
      margin: 0 0.5rem;
    }
  }

  .note-body {
    margin-top: 0.5rem;
    padding: 1rem;
    background-color: #e5e8ed;
    border-radius: 1rem;

    .date {
      font-style: italic;
    }
  }
`;

export const NoteItem = ({ note }: { note: Note }) => (
  <CommentWrapper className="mt-1">
    <p className="note-header">
      <Icon icon="comment-o" size="lg" />
      <span>{note.createdBy.name}</span>
    </p>
    <div className="note-body">
      <p className="date">{reformatDateToFormat(note.createdAt, 'MM/dd/yyyy h:mm a')}</p>
      <p>{note.text}</p>
    </div>
  </CommentWrapper>
);
