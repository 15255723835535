import { StepItem, Steps } from '../../../../shared-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StepStatus } from '../../../../shared-components/step/Steps';

interface NewApplicationStepsProps {
  currentStep: number;
  onClick?: (step: number) => void;
  action?: string;
}

export const TestingApplicationSteps = ({ currentStep, onClick, action = 'submit' }: NewApplicationStepsProps) => {
  const { t } = useTranslation();
  const stepTitles = [
    t('applications.lab-steps.0'),
    t('applications.lab-steps.1'),
    t('applications.lab-steps.2', { type: t(`applications.lab-steps.${action}`) }),
  ];
  const lastStep = 1;
  const lastHighlightedStep = lastStep > currentStep ? lastStep : currentStep;

  return (
    <>
      <Steps active={lastHighlightedStep - 1} currentStep={currentStep} finishStatus={StepStatus.SUCCESS}>
        {stepTitles.map((title: string) => (
          <StepItem title={title} onFinishedClick={onClick} key={title} />
        ))}
      </Steps>
    </>
  );
};
