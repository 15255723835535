import { applyMiddleware, compose, createStore } from 'redux';
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory, History } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { saveApplicationState } from './save-application-data-to-storage';

const sagaMiddleware = createSagaMiddleware();
const history: History = createBrowserHistory();
// @ts-ignore
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const configureStore = () => {
  const middleware = [
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddleware(history)),
    applyMiddleware(saveApplicationState),
  ];

  const store = createStore(rootReducer(history), composeEnhancers(...middleware));
  sagaMiddleware.run(rootSaga);
  return store;
};
export default configureStore;
