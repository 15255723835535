import React from 'react';
import { EasyMeshController } from '../../application/types';
import { DetailsItem } from '../../../partials';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RolesState } from '../../../../redux/modules/roles/reducer';
import { Role } from '../../../../redux/modules/roles/types';
import { roleNameMapping } from '../../application/data/constants';
import classNames from 'classnames';
import { RoleItemName } from './RoleItemName';
import { WrapWithPublicIcon } from '../../../../shared-components/helper-components/WrapWithPublicIcon';
import { Col, Row } from 'rsuite';
import styled from 'styled-components';

export const RowWithMargin = styled(Row)`
  margin-bottom: 1rem;
`;

export const EasyMeshCompare = ({
  record,
  compareRecord,
  expandedCC,
  toggleCC,
  toggleKey,
  showPublicIcon = false,
  ccChanged = false,
  isFullSizeCol = false,
}: {
  record: EasyMeshController;
  compareRecord?: EasyMeshController;
  expandedCC: string[];
  toggleCC: (key: string, isOpen: boolean) => void;
  toggleKey: string;
  showPublicIcon?: boolean;
  ccChanged?: boolean;
  isFullSizeCol?: boolean;
}) => {
  const { t } = useTranslation();
  const roles = useSelector<{ rolesReducer: RolesState }, Role[]>(state => state.rolesReducer.roles);
  const roleName = roles.find(item => item.propertyName === roleNameMapping.easyMeshController)?.name;

  const isOpen = expandedCC.includes(toggleKey);

  return (
    <div className="mb-2">
      <RoleItemName isOpen={isOpen} roleName={roleName} onToggle={() => toggleCC(toggleKey, !isOpen)} />
      {isOpen ? (
        <div className="ml-2">
          <Row>
            <Col sm={isFullSizeCol ? 24 : 12}>
              <RowWithMargin gutter={20}>
                <Col xs={12}>
                  <DetailsItem
                    label={
                      showPublicIcon ? (
                        <WrapWithPublicIcon content={t('applications.cc.componentOS')} />
                      ) : (
                        t('applications.cc.componentOS')
                      )
                    }
                    className={
                      ccChanged
                        ? classNames({ 'core-change-text-different': compareRecord && compareRecord.os !== record.os })
                        : classNames({ 'text-different': compareRecord && compareRecord.os !== record.os })
                    }
                    value={record.os}
                  />
                </Col>
              </RowWithMargin>
            </Col>
            {ccChanged && compareRecord ? (
              <Col sm={12}>
                <RowWithMargin gutter={20}>
                  <Col xs={12}>
                    <DetailsItem
                      label={
                        showPublicIcon ? (
                          <WrapWithPublicIcon content={t('applications.cc.componentOS')} />
                        ) : (
                          t('applications.cc.componentOS')
                        )
                      }
                      value={compareRecord?.os}
                    />
                  </Col>
                </RowWithMargin>
              </Col>
            ) : null}
          </Row>
        </div>
      ) : null}
    </div>
  );
};
