import React from 'react';
import { Form, FormGroup, Row, Col } from 'rsuite';
import { makeStyles } from '@material-ui/core/styles';
import { RsControlLabel } from '../../../shared-components/rsuite';
import { FileUploader } from '../../../shared-components';
import { TestingException } from '../../../redux/modules/application-flow';
import { UploadedFile } from '../../../shared-components/file-upload/types';
import { ErrorObject } from '../../../helpers/types';
import { LabeledCheckbox, LabeledTextArea } from '../../../shared-components/labeled-inputs';
import { UploadFileType } from '../../../shared-components/uploader/FileUploader';
import { Tooltip } from '../../../shared-components/tooltip/Tooltip';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginTop: '-20px',
  },
}));

export const Exception = ({
  exception,
  handleExplanationChange,
  handleExplanationAttachmentsChange,
  requestExceptionChange,
  setExceptionAttachmentLoading,
  errors,
  field = 'testingInfo.testingException.explanation',
  text,
  isChecked,
  defaultExplanation,
  tooltipText,
  tooltipContent,
}: {
  exception: TestingException | null;
  handleExplanationChange: (value: string) => void;
  handleExplanationAttachmentsChange?: (files: UploadedFile[]) => void;
  requestExceptionChange: (testingData: TestingException | null) => void;
  setExceptionAttachmentLoading?: (value: boolean) => void;
  errors: ErrorObject;
  field?: string;
  text?: string;
  isChecked: boolean;
  defaultExplanation?: string;
  tooltipText?: string;
  tooltipContent?: NonNullable<React.ReactNode>;
}) => {
  const handleRequestExceptionChange = (checked: boolean) => {
    const exceptionData = checked
      ? {
          explanation: '',
          attachments: [],
        }
      : null;
    requestExceptionChange(exceptionData);
  };

  const classes = useStyles();
  return (
    <div className="mb-2">
      <div className={classes.root}>
        <LabeledCheckbox
          className="default"
          checked={isChecked}
          name="requestException"
          onChange={(value: string, checked: boolean) => handleRequestExceptionChange(checked)}
        >
          {text}
        </LabeledCheckbox>
        {tooltipText && (
          <div className={classes.tooltip}>
            <Tooltip translationKey={tooltipText} />
          </div>
        )}
        {tooltipContent && (
          <div className={classes.tooltip}>
            <Tooltip content={tooltipContent} />
          </div>
        )}
      </div>
      {exception || isChecked ? (
        <Form fluid style={{ marginBottom: '4.2rem' }}>
          <LabeledTextArea
            required
            label="Explanation"
            rows={5}
            name={field}
            defaultValue={exception?.explanation || defaultExplanation || ''}
            onChange={handleExplanationChange}
            errors={errors}
          />
          <Row>
            <Col xs={12}>
              <FormGroup>
                <RsControlLabel>Supporting docs or files</RsControlLabel>
                <FileUploader
                  fileType={UploadFileType.TESTING_EXCEPTION_ATTACHMENT}
                  files={exception?.attachments || []}
                  onChange={handleExplanationAttachmentsChange}
                  onLoadingChange={setExceptionAttachmentLoading}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      ) : null}
    </div>
  );
};
