import React, { useState } from 'react';
import { OnChangeFunc, RunCondition, TestGroup } from '../../types';
import { ErrorObject } from '../../../../../helpers/types';
import { useTranslation } from 'react-i18next';
import { EditConfSection } from './EditConfSection';
import { LabeledInput } from '../../../../../shared-components/labeled-inputs';
import { CertificationDefinition } from '../../../../../redux/modules/certification';
import { Col, Icon, Row } from 'rsuite';
import { jsonCopy } from '../../../../../helpers';
import { defaultTestGroupValue } from '../../../../../redux/modules/certification/data';
import styled from 'styled-components';
import { RunConditionModal } from './RunConditionModal';
import { RunConditionLabel } from './RunConditionLabel';
import { DetailsItem } from '../../../../partials';
import { MaterialAddButton } from '../../../../../shared-components/button/MaterialAddButton';

interface EditTestGroupProps {
  testGroupIndex: number;
  testGroup: TestGroup;
  errors: ErrorObject;
  onChange: OnChangeFunc;
  removeTestGroup: (index: number) => void;
}

const StyledIcon = styled(Icon)`
  margin-left: 5px;
`;

const EditTestGroup = ({ testGroup, errors, onChange, testGroupIndex, removeTestGroup }: EditTestGroupProps) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onCancel = () => setShowModal(false);

  const onApply = (value: RunCondition) => {
    onChange(value, `testGroups[${testGroupIndex}].condition`);
    setShowModal(false);
  };

  const handleEditRunCondition = () => setShowModal(true);

  return (
    <div>
      <Row>
        <Col xs={1}>
          <DetailsItem
            label={
              <>
                <Icon
                  className="is-cursor-pointer text-muted expand-test-group"
                  style={{ paddingRight: '5px' }}
                  icon={collapsed ? 'plus-square-o' : 'minus-square-o'}
                  size="lg"
                  onClick={() => setCollapsed(prevState => !prevState)}
                />
                <Icon
                  className="is-cursor-pointer text-muted"
                  icon="trash-o"
                  size="lg"
                  onClick={() => removeTestGroup(testGroupIndex)}
                />
              </>
            }
            value={' '}
          />
        </Col>
        <Col xs={5}>
          <LabeledInput
            required
            label={t('certifications.testGroup.testGroup')}
            placeholder={t('certifications.testGroup.testGroup')}
            name={`testGroups[${testGroupIndex}].name`}
            value={testGroup.name}
            errors={errors}
            onChange={(value: string) => onChange(value, `testGroups[${testGroupIndex}].name`)}
          />
        </Col>
        <Col xs={3}>
          <DetailsItem
            label={t('certifications.testGroup.testCases')}
            value={<p style={{ marginTop: '0.5rem' }}>{testGroup.testCriteria.length}</p>}
          />
        </Col>
        <Col xs={15}>
          <DetailsItem
            label={
              <>
                {t('certifications.testGroup.runCondition')}
                <StyledIcon icon="edit" className="pointer edit-run-condition" onClick={handleEditRunCondition} />
              </>
            }
            value={
              <div style={{ marginTop: '0.5rem' }}>
                <RunConditionLabel condition={testGroup.condition} />
              </div>
            }
          />
        </Col>
      </Row>
      <EditConfSection
        collapsed={collapsed}
        data={testGroup.testCriteria}
        testGroupIndex={testGroupIndex}
        errors={errors}
        onChange={onChange}
      />
      {showModal && <RunConditionModal show onApply={onApply} onCancel={onCancel} value={testGroup.condition} />}
    </div>
  );
};

export const EditTestGroupSection = (props: { data: TestGroup[]; errors: ErrorObject; onChange: OnChangeFunc }) => {
  const { data, errors, onChange } = props;
  const { t } = useTranslation();

  const removeTestGroup = (index: number) => {
    const updated = [...data];
    updated.splice(index, 1);
    onChange(updated as Partial<CertificationDefinition>, `testGroups`);
  };

  const addTestGroup = () => {
    const newData = jsonCopy(data);
    newData.push(defaultTestGroupValue);
    onChange(newData as Partial<CertificationDefinition>, 'testGroups');
  };

  return (
    <>
      {data.map((testGroup, index) => (
        <EditTestGroup
          key={index}
          testGroupIndex={index}
          testGroup={testGroup}
          errors={errors}
          onChange={onChange}
          removeTestGroup={removeTestGroup}
        />
      ))}
      <MaterialAddButton className="add-test-group" onClick={addTestGroup} appearance="ghost">
        {t('certifications.testGroup.testGroup')}
      </MaterialAddButton>
    </>
  );
};
