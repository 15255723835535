import * as actionTypes from './actionTypes';
import { Categories } from '../../../components/pages/application/types';

export function categoriesLoaded(categories: Categories[]) {
  return {
    type: actionTypes.CATEGORIES_LOADED,
    payload: categories,
  };
}
export function loadCategories() {
  return {
    type: actionTypes.LOAD_CATEGORIES,
  };
}
