import React from 'react';
import { AppFlows, CompanyContactInfo } from '../../../redux/modules/application-flow';
import { applicationTypeSummary, applicationTypeTitles } from '../../../helpers/constants';
import { AnchorLink } from '../../../shared-components/styled-components';
import { ProductInfoStep } from '../../../api/application/types';
import { AppCompanyContactInfo } from '../../pages/application/types';

export const ApplicationTypeDetails = ({
  companyContactInfo,
  productInfo,
  wrapWithLink = false,
  applicationType = AppFlows.NEW,
}: {
  companyContactInfo: CompanyContactInfo | AppCompanyContactInfo;
  productInfo: Partial<ProductInfoStep>;
  wrapWithLink?: boolean;
  applicationType?: AppFlows;
}) => {
  const { source } = companyContactInfo;

  if (applicationType === AppFlows.DERIVATIVE) {
    return (
      <>
        {/* Derivative (of ...) */}
        {applicationTypeTitles[applicationType].replace('...)', '')}
        {wrapWithLink ? (
          <AnchorLink to={`/product/${source?.productId}`} target="_blank">
            {source?.cid}
          </AnchorLink>
        ) : (
          source?.cid
        )}
        {')'}
      </>
    );
  }
  if (applicationType === AppFlows.VARIANT) {
    return (
      <>
        {/* Variant (of ...) */}
        {applicationTypeTitles[applicationType].replace('...)', '')}
        {wrapWithLink ? (
          <AnchorLink to={`/product/${productInfo.id}`} target="_blank">
            {productInfo?.cid}
          </AnchorLink>
        ) : (
          productInfo?.cid
        )}
        {')'}
      </>
    );
  }
  return <>{applicationTypeSummary[applicationType]}</>;
};
