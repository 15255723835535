import React, { CSSProperties, useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { LabResultsRow, LabTestResult } from '../types';
import { SortingOptions, SortType } from '../../test-case/types';
import { sortByProperty } from '../../../../helpers/sort-by-property';
import { ApplicationInfoRecord } from '../../application/types';
import { TestingResultIcon } from '../../atl-testing/partials';
import { getRetirementStatus, LRStatus } from '../../../../helpers/constants';
import { jsonCopy } from '../../../../helpers';
import { PrerequisitesInfo } from '../../../partials/prerequisites/PrerequisitesInfo';
import { DataTableColumn } from '../../../../shared-components/table/DataTableColumn';
import DataTable, { DataTableChildType } from '../../../../shared-components/table/DataTable';
import { TestingResultTableFooter } from './TestingResultTableFooter';
import { WarningTooltip } from '../../../../shared-components/tooltip/WarningTooltip';
import { TestingResultTableHeader } from './TestingResultTableHeader';
// @ts-ignore
import _vars from '../../../../styles/_variables.scss';

export const useStyles = makeStyles(() => ({
  precertified: {
    background: _vars.lightGray,
  },
  columnVersion: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

interface TestingResultsTableProps {
  application: ApplicationInfoRecord;
  onLrUpdate: () => void;
  isReview?: boolean;
}

export const TestingResultsTable = ({ application, onLrUpdate, isReview = false }: TestingResultsTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [sorting, setSorting] = useState<SortingOptions>({
    sortColumn: 'name',
    sortType: 'asc',
  });
  const { certifications, labRequests } = application;
  const LRs = jsonCopy(labRequests).sort((a, b) => sortByProperty(a, b, { sortType: 'asc', sortColumn: 'id' }, true));
  const handleSortChange = (sortColumn: string, sortType: SortType) => {
    setSorting({ sortColumn, sortType });
  };

  const resultByLab = LRs.reduce((obj, lr) => {
    if (![LRStatus.PENDING_APPROVAL, LRStatus.APPROVED].includes(lr.status)) {
      return obj;
    }
    lr.requestedTests.forEach(cert => {
      if (!obj[cert.certification.version.id]) {
        obj[cert.certification.version.id] = {};
        obj[cert.certification.version.id].capabilities = {};
      }
      obj[cert.certification.version.id][lr.lab.id] = {
        conformance: cert.testResult.conformance,
        interoperability: cert.testResult.interoperability,
      };
      if (cert.capabilities.length > 0) {
        cert.capabilities.forEach(capability => {
          if (!obj[cert.certification.version.id].capabilities[capability.certification.version.id]) {
            obj[cert.certification.version.id].capabilities[capability.certification.version.id] = {};
          }
          obj[cert.certification.version.id].capabilities[capability.certification.version.id][lr.lab.id] = {
            conformance: capability.testResult.conformance,
            interoperability: capability.testResult.interoperability,
          };
        });
      }
    });
    return obj;
  }, Object.create(null));

  const data: LabResultsRow[] = certifications
    .map(cert => {
      return {
        id: cert.id,
        name: cert.name,
        prequalified: Boolean(cert.prequalified),
        prerequisiteOf: cert.prerequisiteOf.map(el => ({
          ...el,
          isBold: true,
        })),
        roleName: cert.role.name,
        versionName: cert.version.name,
        versionStatus: cert.version.status,
        results: resultByLab[cert.version.id],
        children: cert.capabilities.length
          ? cert.capabilities.map(capability => {
              return {
                id: capability.id,
                name: capability.name,
                prequalified: Boolean(capability.prequalified),
                prerequisiteOf: capability.prerequisiteOf.map(el => ({
                  ...el,
                  isBold: true,
                })),
                roleName: cert.role.name,
                results: resultByLab[cert.version.id]?.capabilities[capability.version.id],
              };
            })
          : undefined,
      };
    })
    .sort((a, b) => sortByProperty(a, b, sorting));

  const labCols = LRs.map(item => ({ ...item.lab, requestId: item.id }));

  const getResultsRow = (row: LabResultsRow, labId: number, field: keyof LabTestResult) => {
    if (row.results && row.results[labId] && field in row.results[labId]) {
      return (
        <TestingResultIcon
          className={classNames({ dotted: row.prequalified })}
          testingResult={row.results[labId][field]}
        />
      );
    }
  };

  const showFooter = !isReview && labRequests?.some(lr => lr.status === LRStatus.PENDING_APPROVAL);

  const alignCenter = { textAlign: 'center' } as CSSProperties;

  const TableFooterComponent = () => (
    <TestingResultTableFooter labs={labCols} labRequests={labRequests} onLrUpdate={onLrUpdate} />
  );

  return (
    <DataTable<LabResultsRow>
      expandable
      data={data}
      minWidth={500}
      sorting={sorting}
      onChangeSorting={handleSortChange}
      className="is-certifications-results"
      getRowClassName={row => (row?.prequalified ? classes.precertified : '')}
      TableHeadComponent={props => <TestingResultTableHeader labs={labCols} {...props} />}
      TableFooterComponent={showFooter ? TableFooterComponent : undefined}
    >
      <DataTableColumn sortable wordWrap width="25%" minWidth={200} field="name" label={t('certifications.title')}>
        {(row: LabResultsRow) => (
          <>
            {row.name}
            {row.prerequisiteOf.length > 0 && <PrerequisitesInfo prerequisites={row.prerequisiteOf} />}
          </>
        )}
      </DataTableColumn>
      <DataTableColumn sortable field="roleName" width="15%" label={t('certifications.role')} />
      {
        (labCols.map(lab => {
          return [
            <DataTableColumn key={lab.id} field={`labResult${lab.id}Conf`} label={lab.name} style={alignCenter}>
              {(row: LabResultsRow) => getResultsRow(row, lab.id, 'conformance')}
            </DataTableColumn>,
            <DataTableColumn key={lab.id} field={`labResult${lab.id}Interop`} label={lab.name} style={alignCenter}>
              {(row: LabResultsRow) => getResultsRow(row, lab.id, 'interoperability')}
            </DataTableColumn>,
          ];
        }) as unknown) as DataTableChildType<LabResultsRow>
      }
      <DataTableColumn
        width={240}
        field="versionName"
        label={t('staff.pendingApproval.version')}
        style={{ textAlign: 'right' }}
      >
        {(row: LabResultsRow) => (
          <div className={classes.columnVersion}>
            {row.versionStatus && getRetirementStatus(row.versionStatus) ? (
              <WarningTooltip variant="rounded" status={row.versionStatus} onHoverTooltipTextWarning />
            ) : null}
            {row.versionName}
          </div>
        )}
      </DataTableColumn>
    </DataTable>
  );
};
