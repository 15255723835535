import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { setUnsavedChanges } from '../../redux/modules/app';
import { useDispatch } from 'react-redux';

export interface IsModifiedProps {
  isModified?: boolean;
  setIsModified: (value: boolean) => void;
}

export function useChangeDetection<T>(
  setIsChanged: (changed: boolean) => void,
  getHash: (value: T) => string,
  item: T,
  defaultValue?: T,
) {
  const [initial, setInitial] = useState(getHash(defaultValue ?? item));
  const updateInitialState = (value: T) => setInitial(getHash(value));

  useEffect(() => {
    setIsChanged(initial !== getHash(item));
  }, [item, initial]);

  return updateInitialState;
}

export function usePageChangeDetection<T>(getHash: (value: T) => string, item: T, defaultValue?: T) {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const [isModified, setIsModified] = useState(false);

  const resetChangeDetection = useCallback(() => dispatch(setUnsavedChanges(url, false)), [url]);

  const setIsChanged = useCallback(
    (value: boolean) => {
      setIsModified(value);
      dispatch(setUnsavedChanges(url, value));
    },
    [getHash],
  );

  const setChangeDetectionState = useChangeDetection(setIsChanged, getHash, item, defaultValue);

  return { isModified, resetChangeDetection, setChangeDetectionState };
}

export const numberSort = (a: number, b: number) => a - b;
