import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Button, Divider } from 'rsuite';

import { getAllLabCertifications } from '../../../../api/company/lab-management/certifications';
import { getLabInfoById } from '../../../../api/lab';
import { closeWindow, confirmAction, parseUrlParams } from '../../../../helpers';
import {
  addSelectedCertifications,
  publishSelectedCertification,
  removeSelectedCertification,
  selectAddedCertifications,
  selectAtlQualificationsState,
  setAvailableCertifications,
} from '../../../../redux/modules/admin/atl-qualifications';
import { CloseButton } from '../../../../shared-components/button/CloseButton';
import { CircularLoader } from '../../../../shared-components/loader/CircularLoader';
import { PageTemplate } from '../../../partials';
import { LabInfo } from '../../lab-info/types';
import { LabInfoTable } from './components/LabInfoTable';
import { QualificationsInput } from './components/QualificationsInput';
import { QualificationsSummaryTable } from './components/QualificationsSummaryTable';
import { reformatFlatSummaryDataByGroup } from './helpers';
import { LabSummaryCertification, LabSummaryCertificationByGroup } from './types';

export const useStickyStyles = makeStyles({
  pageTemplate: {
    '& .el-card__body': {
      paddingTop: 0,
    },
    '& .page-header': {
      position: 'sticky',
      zIndex: 5,
      top: 64,
      paddingTop: 24,
      background: 'white',
    },
  },
});

export const AtlQualificationsMultipleEnablePage = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = parseUrlParams(location.search);
  const [labIds] = useState(params.labIds.split(',').map(s => parseInt(s)));
  const [isLoadingLabInfo, setIsLoadingLabInfo] = useState(false);
  const [labInfos, setLabInfos] = useState<LabInfo[]>([]);
  const [isLoadingAvailableCertifications, setIsLoadingAvailableCertifications] = useState(false);

  const classes = useStickyStyles();

  const addedCertifications = reformatFlatSummaryDataByGroup(useSelector(selectAddedCertifications));
  const { isPublishing, availableCertifications } = useSelector(selectAtlQualificationsState);

  const confirmationRequired = () => addedCertifications.length > 0;

  const onClose = () => {
    if (window.opener) closeWindow();
    else history.push(`/admin/atl-qualifications/`);
  };

  const onPublish = () =>
    dispatch(
      publishSelectedCertification(
        labInfos.map(info => info.id),
        addToast,
        onClose,
      ),
    );

  const addQualification = (
    certifications: LabSummaryCertification[],
    interoperability: boolean,
    conformance: boolean,
  ) => {
    dispatch(
      addSelectedCertifications(
        certifications.map(certification => ({
          certification,
          interoperability,
          conformance,
        })),
      ),
    );
  };

  const deleteQualification = (certification: LabSummaryCertificationByGroup) => {
    if (certification.version) {
      dispatch(removeSelectedCertification([certification.version.id]));
    } else {
      const versionIds = certification.children.map(c => c.version?.id ?? -1);
      dispatch(removeSelectedCertification(versionIds));
    }
  };

  const removeLab = (labId: number) => {
    setLabInfos(labInfos.filter(l => l.id !== labId));
  };

  useEffect(() => {
    // load all lab info
    setIsLoadingLabInfo(true);
    const loadPromises = labIds.map(id => getLabInfoById(id));
    Promise.all(loadPromises).then(responses => {
      const labInfos = responses.map(res => res.data);
      setLabInfos(labInfos.sort((a, b) => a.name.localeCompare(b.name)));
      setIsLoadingLabInfo(false);
    });

    // load all certifications
    setIsLoadingAvailableCertifications(true);
    getAllLabCertifications().then(response => {
      dispatch(setAvailableCertifications(response.data));
      setIsLoadingAvailableCertifications(false);
    });
  }, [labIds]);

  return (
    <PageTemplate
      withBorder
      className={classNames('add-new-qualifications-page', classes.pageTemplate)}
      title={t('admin.qualifications.updateLabQualificationTitle')}
      actionLeft={<CloseButton onClick={() => confirmAction(confirmationRequired, onClose)} />}
      footerActionLeft={<></>}
      actionRight={
        <div className="btn-wrapper">
          <Button
            appearance="primary"
            className="btn-publish"
            loading={isPublishing}
            disabled={addedCertifications.length == 0 || labInfos.length == 0}
            style={{ minWidth: 110 }}
            onClick={() => confirmAction(confirmationRequired, onPublish, 'admin.qualifications.publishWarning')}
          >
            {t('common.actions.apply')}
          </Button>
        </div>
      }
    >
      {(isPublishing || isLoadingLabInfo) && (
        <CircularLoader fullPage backdrop content={t('common.placeholders.loadingData')} />
      )}
      <div>
        <h4>{`${t('lab.generalInfo')} ${labInfos.length}`}</h4>
        <LabInfoTable labs={labInfos} removeLab={removeLab} />
      </div>

      <Divider style={{ marginBottom: '12px' }} />

      {labIds.length > 0 && (
        <div className="mt-2">
          <h4 className="mb-2">{t('admin.qualifications.addNewQualifications')}</h4>
          <QualificationsInput
            onSelect={addQualification}
            certifications={availableCertifications}
            loading={isLoadingAvailableCertifications}
            disabledCertifications={addedCertifications}
          />
        </div>
      )}

      <div className="mt-3">
        <h4 className="mb-2">{t('admin.qualifications.addedQualifications')}</h4>
        <QualificationsSummaryTable creationMode deleteQualification={deleteQualification} data={addedCertifications} />
      </div>
    </PageTemplate>
  );
};
