import React, { forwardRef, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { AxiosResponse } from 'axios';

import { CollapsableSection } from '../../../../../certification/partials/CollapsableSection';
import { CollapsedSectionProps } from '../../../../../certification/types';
import { VariantInfo } from '../../../../../product/types';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { AppFlows, ApplicationFlowState } from '../../../../../../../redux/modules/application-flow';
import { LoadedFromBreadcrumps } from '../../../../../../partials/LoadedFromBreadcrumbs.tsx';
import { CCDetailsWithCompare } from '../../../../../staff/partials/CCDetailsWithCompare';
import { CCInfoRecord, CertificationInfo } from '../../../../../application/types';
import { Confirm } from '../../../../../../../helpers/confirmationPopup/Confirm';
import { getVariantsByProductModelId } from '../../../../../../../api/product';
import { DetailsItem } from '../../../../../../partials';
import { isSolutionProvider } from '../../../../../staff/partials/helpers';
import { isProductOfSolutionProvider } from '../../helpers';
import { confirmAction } from '../../../../../../../helpers';
import { CCDetails } from '../../../../../application/partials/cc-details/CCDetails';

import { GoBackStepButton } from './GoBackStepButton';
import { CertificationsSection } from './CertificationsSection';
import { AutocompleteField } from '../../../../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';

const useStyles = makeStyles((theme: Theme) => ({
  rowWithMargin: {
    marginBottom: '1rem',
  },
  rightCol: {
    paddingLeft: theme.spacing(1.25),
  },
}));

type WiFiComponentSectionProps = CollapsedSectionProps & {
  onStepChange: (step: number) => void;
  isOtherSectionModified: boolean;
  certifications: CertificationInfo[];
};

export const WiFiComponentSection = forwardRef<HTMLDivElement, WiFiComponentSectionProps>(function WiFiComponentSection(
  { onStepChange, isOtherSectionModified, certifications }: WiFiComponentSectionProps,
  ref,
) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [variants, setVariants] = useState<VariantInfo[]>([]);
  const [selectedVariantId, setSelectedVariantId] = useState<number>();
  const [variantToCompare, setVariantToCompare] = useState<VariantInfo>();
  const [expandedCC, setExpandedCC] = useState<string[]>([]);

  const { productInfo, ...application } = useSelector<RootReducer, ApplicationFlowState>(
    state => state.applicationFlow,
  );

  const isDerivative = application['@type'] === AppFlows.DERIVATIVE;
  const isVariant = application['@type'] === AppFlows.VARIANT;
  const applicationAvailableAsQualifiedSolution = isProductOfSolutionProvider(application);
  const variantAvailableAsQualifiedSolution = isSolutionProvider(variantToCompare);

  const toggleCC = (key: string, isOpen: boolean) =>
    setExpandedCC(prevState => (isOpen ? [...prevState, key] : prevState.filter(item => item !== key)));

  const renderLeftColumn = () => (
    <>
      {variants.length === 0 ? null : (
        <>
          <DetailsItem
            label={t('applications.info.thisVariantName')}
            value={productInfo.modelVariants[0].name}
            className="mb-1"
          />
          <DetailsItem className="mb-1" label={t('applications.summary.appId')} value={application.id} />
          <DetailsItem
            className="mb-1"
            label={t('applications.info.searchable')}
            value={
              (productInfo.modelVariants[0].searchableByPublic
                ? t('common.options.yes')
                : t('common.options.no')) as string
            }
          />
          <DetailsItem
            label={t('applications.info.available')}
            className="mb-1"
            value={
              (productInfo.modelVariants[0].availableAsDerivative
                ? t('common.options.yes')
                : t('common.options.no')) as string
            }
          />
          {applicationAvailableAsQualifiedSolution && (
            <DetailsItem
              className="mb-1"
              label={t('applications.info.availableAsQualifiedSolution')}
              value={
                (productInfo.modelVariants[0].availableAsQualifiedSolution
                  ? t('common.options.yes')
                  : t('common.options.no')) as string
              }
            />
          )}
        </>
      )}

      {variants.length === 0 ? (
        <>
          {!!application?.componentCombination.componentCombinationVariantSource && (
            <LoadedFromBreadcrumps
              items={application?.componentCombination.componentCombinationVariantSource}
              linkToOnName={''}
            />
          )}
          <CCDetailsWithCompare
            expandedCC={expandedCC}
            toggleCC={toggleCC}
            cc={application.componentCombination.componentCombination as CCInfoRecord}
            compareCC={variantToCompare?.componentCombination.componentCombination as CCInfoRecord}
            showPublicIcon={true}
            isFullSizeCol={true}
          />
        </>
      ) : null}
    </>
  );

  const renderCCInComparison = () => (
    <>
      <Grid container>
        <Grid item xs={6}>
          {!!application?.componentCombination.componentCombinationVariantSource && (
            <LoadedFromBreadcrumps
              items={application?.componentCombination.componentCombinationVariantSource}
              linkToOnName={''}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.rightCol}>
          {!!variantToCompare?.componentCombination.componentCombinationVariantSource && (
            <LoadedFromBreadcrumps
              items={variantToCompare?.componentCombination.componentCombinationVariantSource}
              linkToOnName={''}
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.rowWithMargin}>
        <Grid item xs={6}>
          <CCDetailsWithCompare
            expandedCC={expandedCC}
            toggleCC={toggleCC}
            cc={application.componentCombination.componentCombination as CCInfoRecord}
            compareCC={variantToCompare?.componentCombination.componentCombination as CCInfoRecord}
            showPublicIcon={true}
            isFullSizeCol={true}
          />
        </Grid>
        <Grid item xs={6} className={classes.rightCol}>
          <CCDetailsWithCompare
            expandedCC={expandedCC}
            toggleCC={toggleCC}
            cc={variantToCompare?.componentCombination.componentCombination as CCInfoRecord}
            showPublicIcon={true}
            isFullSizeCol={true}
          />
        </Grid>
      </Grid>
    </>
  );

  const renderRightColumnInComparison = () => (
    <>
      <label className="rs-control-label">Other Product Model Variant</label>
      <AutocompleteField
        data={variants}
        valueKey="id"
        labelKey="name"
        onChange={setSelectedVariantId}
        value={selectedVariantId}
        hideClose
        name="otherVariant"
        placeholder=""
      />
      {variantToCompare?.id ? (
        <>
          <DetailsItem
            className="mb-1"
            label={t('applications.summary.appId')}
            value={variantToCompare.application.id}
          />
          <DetailsItem
            label={t('applications.info.searchable')}
            className="mb-1"
            value={(variantToCompare?.searchableByPublic ? t('common.options.yes') : t('common.options.no')) as string}
          />
          <DetailsItem
            label={t('applications.info.available')}
            className="mb-1"
            value={
              (variantToCompare?.availableAsDerivative ? t('common.options.yes') : t('common.options.no')) as string
            }
          />
          {variantAvailableAsQualifiedSolution && (
            <DetailsItem
              label={t('applications.info.availableAsQualifiedSolution')}
              className="mb-1"
              value={
                (variantToCompare?.availableAsQualifiedSolution
                  ? t('common.options.yes')
                  : t('common.options.no')) as string
              }
            />
          )}
        </>
      ) : null}
    </>
  );

  const confirmGoBack = () => {
    Confirm({
      title: t('applications.review.section.wifiComponents.goBackConfirm.title'),
      message: <Trans i18nKey="applications.review.section.wifiComponents.goBackConfirm.message" />,
      onAccept: () => {
        onStepChange(2);
      },
      size: 'sm',
    });
  };

  const checkDataAndConfirmGoBack = () => {
    if (isOtherSectionModified) {
      confirmAction(
        () => true,
        () => {
          setTimeout(confirmGoBack, 200);
        },
        'common.unsavedDataWarning',
      );
    } else {
      confirmGoBack();
    }
  };

  useEffect(() => {
    if (!isDerivative) {
      getVariantsByProductModelId(productInfo.id as number).then((response: AxiosResponse<VariantInfo[]>) => {
        const filteredVariants = response.data.filter(el => el.application.id !== application.id);
        setVariants(filteredVariants);
        const { length } = filteredVariants;
        if (length > 0) {
          const source = filteredVariants.find(value => value.id === application?.companyContactInfo?.source?.id);
          if (source) {
            setSelectedVariantId(source.id);
          } else {
            setSelectedVariantId(filteredVariants[length - 1].id);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedVariantId) {
      const index = variants.findIndex(el => el.id === selectedVariantId);
      if (index !== -1) {
        setVariantToCompare(variants[index]);
      }
    }
  }, [selectedVariantId]);

  return (
    <>
      <CollapsableSection
        title={
          isVariant
            ? t('applications.review.section.wifiComponents.titleWithOtherVariant')
            : t('applications.review.section.wifiComponents.title')
        }
        showEditIcon={isDerivative ? false : true}
        ref={ref}
        onEditIconClick={checkDataAndConfirmGoBack}
        hideDivider={true}
        editIcon={<GoBackStepButton />}
        hideEditIconWhenCollapse={true}
      >
        {isDerivative ? (
          <Grid className="mt-1">
            <CCDetails
              cc={application.componentCombination.componentCombination as CCInfoRecord}
              ccVariantSource={application.componentCombination.componentCombinationVariantSource}
              showPublicIcon={true}
            />
          </Grid>
        ) : (
          <Grid container className="mt-1">
            <Grid item xs={6}>
              {renderLeftColumn()}
            </Grid>
            {variants.length > 0 ? (
              <>
                <Grid item xs={6} className={classes.rightCol}>
                  {renderRightColumnInComparison()}
                </Grid>
                {variantToCompare?.id ? renderCCInComparison() : null}
              </>
            ) : null}
          </Grid>
        )}
      </CollapsableSection>
      <CertificationsSection
        onStepChange={onStepChange}
        certifications={certifications}
        compareCertifications={variantToCompare?.certifications || []}
        isOtherSectionModified={isOtherSectionModified}
      />
    </>
  );
});
