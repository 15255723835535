import { UploadedFile } from '../../../shared-components/file-upload/types';
import { Role } from '../../../redux/modules/roles/types';
import { GetSummaryArguments } from './partials/types';
import { SetStateAction } from 'react';
import { CertificationDefinition } from '../../../redux/modules/certification';
import { CertificationStatuses } from '../../../helpers/constants/certification-statuses';
import { GenericNodeType } from '../../../shared-components/condition-builder/types';

export interface BasicCertificationRecord {
  id: number;
  name: string;
  version: string;
}

export interface InteroperabilityTemplateSelectOptions {
  id: number;
  name: string;
}

export interface CertificationFormRecord {
  id?: number;
  name: string;
  description: string;
  instructions: string;
  versionName: string;
  certificationStart: string;
  publicLaunch: string;
  roleId?: number;
  roleName?: string;
  type: string;
  state?: string;
  interoperabilityTestingRequired: boolean;
  interoperabilityTemplate: UploadedFile | null;
  testInvokers: CertificationFormRecordTestInvoker[];
  capabilities: BasicCertificationRecord[];
  preRequisiteCertifications: PreReqAutoCompleteResponse;
  createdBy?: string;
  createdAt?: string;
  classification: Classification | null;
  visibleOnCertificate: boolean;
}

export interface CertificationRecord extends CertificationFormRecord, BasicCertificationRecord {
  id: number;
  roleId: number;
  roleName: string;
  rubricOverride: string | null;
  state: string;
  type: 'PRIMARY' | 'SUB';
  interoperability?: boolean;
  testInvokers: CertificationRecordTestInvoker[];
  certification?: boolean;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  classification: Classification;
}

export interface CertificationFormRecordTestInvoker {
  testId: number;
  name: string;
  testState: string;
  testInvokerType?: string;
  criterions: CertificationRecordCriterion[];
}

export interface CertificationRecordTestInvoker extends CertificationFormRecordTestInvoker {
  id: number;
  testName: string;
  qualificationDefinitionId: number;
  qualificationDefinitionName: string;
}

export interface CertificationRecordCriterion {
  measurementDefinitionId?: number;
  variableName?: string;
  measurementType?: string;
  required: boolean;
  compareOperation: string;
  operand1: string | boolean | number;
  operand2?: string | boolean | number;
  comment?: string;
}

export interface Classification {
  id: number;
  name: string;
}

export enum PreReqItemType {
  PRIMARY = 'PRIMARY',
  SUB = 'SUB',
}

export interface PreReqAutocompleteItem {
  id: number;
  name: string;
  version: string;
  type: PreReqItemType;
}

export interface PreReqAutoCompleteArrayItem {
  master: PreReqAutocompleteItem;
  subs: PreReqAutocompleteItem[];
}
export type PreReqAutoCompleteResponse = PreReqAutoCompleteArrayItem[];

export interface BundleResponse {
  roles: Role[];
  qualificationsDefinitions: CertificationRecord[];
}

// REDESIGN TYPES
export interface Capability {
  id: number;
  name: string;
}

export interface BasicCertificationSummaryRecord {
  id: number;
  name: string;
  roleName: string;
  visibleOnCertificate: boolean;
  type: 'PRIMARY' | 'CAPABILITY';
  date: string;
  user: string;
}

export interface CertificationVersion {
  id: number;
  version: string;
  date: string;
  user: string;
  capabilities: Capability[];
}

export interface CertificationSummaryRecord extends BasicCertificationSummaryRecord {
  versions: CertificationVersion[];
}

export interface ReformattedSummaryCertification extends BasicCertificationSummaryRecord {
  children: CertificationVersion[];
  capabilities: Capability[];
  actualName: string;
}

export interface SummaryTableRow extends ReformattedSummaryCertification {
  actions: {
    getSummary: (args?: GetSummaryArguments) => void;
    setLoadingData: (x: SetStateAction<boolean>) => void;
  };
}

export interface RoleInfo {
  id: number | null;
  name?: string;
}

export interface ClassificationInfo {
  id: number;
  name?: string;
}

export interface UmbrellaInfo {
  name: string;
  visibleOnCertificate: boolean | null;
  role: RoleInfo;
  classification: ClassificationInfo | null;
  description: string;
  internalComments: string;
}

export interface TestResultsTemplate {
  id: number;
  name: string;
}

export interface InteroperabilityTest {
  testingRequired: boolean;
  templateRequired: boolean;
  testResultsTemplate: TestResultsTemplate | null;
}

export interface Criteria {
  measurement: { id: number; name: string; type: string; required: boolean; comment: string; measurementType: string };
  comment: string;
  required: boolean;
  compareOperation: string;
  operand1: string | boolean | number;
  operand2: string | boolean | number;
}

export interface TestCriteria {
  test: { id: number; name: string; status: string };
  criteria: Criteria[];
}

export enum RunConditionTypes {
  ALWAYS_ON = 'AlwaysOn',
  CONDITIONAL = 'Conditional',
}

export interface RunCondition {
  '@type': RunConditionTypes;
  raw?: Partial<GenericNodeType>;
}

export interface TestGroup {
  name: string;
  condition: RunCondition;
  testCriteria: TestCriteria[];
}

export enum ProfileType {
  STANDARD = 'STANDARD',
  PROFILE = 'PROFILE',
  ASD = 'ASD',
}

export const ProfileTypeTitle = {
  [ProfileType.STANDARD]: 'Standard',
  [ProfileType.PROFILE]: 'Profile',
  [ProfileType.ASD]: 'ASD',
};

export interface Profile {
  type: ProfileType;
  name?: string;
}

export interface LifeCycleInfo {
  certificationStart: string | null;
  publicLaunch: string | null;
  firstRetirementPhaseDate: string | null;
  secondRetirementPhaseDate: string | null;
  thirdRetirementPhaseDate: string | null;
}

export interface VersionInfo extends LifeCycleInfo {
  version: string;
  visibleOnCertificate: boolean | null;
  profile: Profile;
  ecn: string;
  revision: string;
  changesSummary: string;
  labNotes: string;
  memberNotes: string;
}

export enum CertificationType {
  PRIMARY = 'PRIMARY',
  CAPABILITY = 'CAPABILITY',
}

export const certificationTypeTitles = {
  [CertificationType.PRIMARY]: 'Primary',
  [CertificationType.CAPABILITY]: 'Capability',
};

export interface AuditingInfoCert {
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

export interface AuditingInfoVersion {
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

export interface AuditingInfo {
  certification: AuditingInfoCert;
  version: AuditingInfoVersion;
}

export enum CapabilitySelectType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export interface FormCapability {
  capability: {
    id: number;
    name?: string;
    version?: string;
    revision?: string;
    status: CertificationStatuses | null;
  };
  displayParent: number | null;
  mandatory: boolean;
  minChildren?: number;
  maxChildren?: number;
  isErrorChildrenFields?: boolean[];
}

export interface Version {
  id: number;
  name: string;
  status: CertificationStatuses;
}

export interface Prerequisites {
  certification: {
    id: number;
    name?: string;
    role?: {
      id: number;
      name: string;
    };
  };
  version: string;
  revision: string;
  versions?: Version[];
}

export interface DetailedCertificationRecord extends CertificationDefinition {
  id: number;
  auditingInformation: AuditingInfo;
}

export interface CapabilitiesResponseItem {
  id: number;
  name: string;
  status: CertificationStatuses | null;
  roleId: number;
  roleName: string;
  version: string;
}

export type OnChangeType = Partial<CertificationDefinition> | string | boolean | Date | RunCondition | number | null;

export type OnChangeFunc = (value: OnChangeType, name: string) => void;
export type CleanErrorFunc = (x: string) => void;

export interface PrereqAutocomplete {
  id: number;
  name: string;
  type: CertificationType;
  role: string;
  versions: {
    version: string;
    revision: string[];
  }[];
}

export interface CollapsedSectionProps {
  isView?: boolean;
}

export interface BasicSaveResponse {
  updatedAt: string;
  updatedBy: string;
}
