import React from 'react';
import { Row, Col } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootReducer } from '../../../../redux/rootReducer';
import { DetailsItem } from '../../../partials';
import { User } from '../../../../redux/modules/user/types';
import { AuditingInfoCert, AuditingInfoVersion } from '../types';
import { DateTime } from 'luxon';
import { reformatDate } from '../../../../helpers';
import styled from 'styled-components';
import classNames from 'classnames';

const Wrapper = styled.div`
  &.with-divider {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid rgba(100, 18, 70, 0.2);
  }
`;

export const AuditingInfo = ({
  data,
  id,
  withDivider = false,
}: {
  data?: AuditingInfoCert | AuditingInfoVersion;
  id?: number;
  withDivider?: boolean;
}) => {
  const { t } = useTranslation();
  const user = useSelector<RootReducer, User>(state => state.userReducer.user as User);

  return (
    <Wrapper className={classNames({ 'with-divider': withDivider })}>
      <Row gutter={20}>
        <Col xs={4}>{id ? <DetailsItem label={t('certifications.id')} value={id} /> : null}</Col>
        <Col xs={4}>
          <DetailsItem
            label={t('certifications.createdAt')}
            value={reformatDate(data?.createdAt || DateTime.local().toISO())}
          />
        </Col>
        <Col xs={4}>
          <DetailsItem
            label={t('certifications.createdBy')}
            value={data?.createdBy || (user && `${user.firstName} ${user.lastName}`)}
          />
        </Col>
        <Col xs={4}>
          <DetailsItem
            label={t('certifications.updatedAt')}
            value={data?.updatedAt ? reformatDate(data?.updatedAt) : ' '}
          />
        </Col>
        <Col xs={4}>
          <DetailsItem label={t('certifications.updatedBy')} value={data?.updatedBy || ' '} />
        </Col>
      </Row>
    </Wrapper>
  );
};
