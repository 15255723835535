import { SingleNodeType } from '../types';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import React, { useContext } from 'react';
import { ConditionBuilderContext } from '../ConditionBuilderContext';
import { StyledNodeWrapper, StyledSingleNodeElement } from './StyledComponents';
import classNames from 'classnames';
import { NegationInput } from './NegationInput';
import { certificationTypeTitles } from '../../../components/pages/certification/types';
import styled from 'styled-components';
import { DeleteButton } from './Buttons';

interface SingleNodeProps {
  node: SingleNodeType;
}

const StyledCertificationName = styled.div`
  font-weight: bold;
`;

const StyledCertificationDetails = styled.div`
  font-size: 0.85em;
`;

export const SingleNode = ({ node }: SingleNodeProps) => {
  const [{ isDragging }, connectDrag] = useDrag({
    item: node,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const { removeNode, setNegationValue } = useContext(ConditionBuilderContext);

  return (
    <StyledNodeWrapper className={classNames('run-element-wrapper', isDragging && 'is-dragging')}>
      <NegationInput value={node.negation} onChange={value => setNegationValue(node.path, value)} />
      <StyledSingleNodeElement ref={connectDrag} className="run-element">
        <div>
          <StyledCertificationName>{node.certification.name}</StyledCertificationName>
          <StyledCertificationDetails>
            {certificationTypeTitles[node.certification.type]} | {node.certification.role.name}
          </StyledCertificationDetails>
        </div>
        <DeleteButton onClick={() => removeNode(node.path)} />
      </StyledSingleNodeElement>
    </StyledNodeWrapper>
  );
};
