import React from 'react';
import { ErrorObject } from '../../helpers/types';
import { withLabel, WrappedInputProps } from './withLabel';
import { Radio, RadioGroup } from 'rsuite';
import { RadioGroupProps } from 'rsuite/lib/RadioGroup';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import _variables from '../../styles/_variables.scss';
import styled from 'styled-components';
import { extractError } from './utils';

export const StyledRadioGroup = styled(RadioGroup)`
  border: none;
  border-radius: 0;

  .rs-radio-inline {
    background-color: ${_variables.igoPlumLighten};

    & .rs-radio-checker > label {
      color: ${_variables.labelGray};
    }

    &.rs-radio-checked {
      background-color: ${_variables.primary};

      & .rs-radio-checker > label {
        color: white;
      }

      &:first-of-type {
        border-right-color: ${_variables.primary};

        &.rs-radio-disabled {
          border-right-color: ${_variables.primaryDisabled};
        }
      }

      &:last-of-type {
        border-left-color: ${_variables.primary};

        &.rs-radio-disabled {
          border-left-color: ${_variables.primaryDisabled};
        }
      }

      &.rs-radio-disabled {
        background-color: ${_variables.primaryDisabled};
      }
    }

    &:first-of-type {
      text-align: center;
      border-radius: 0.3rem 0 0 0.3rem;
      position: relative;
      border-right: 1px solid ${_variables.igoPlumDarken};
    }

    &:last-of-type {
      border-radius: 0 0.3rem 0.3rem 0;
      text-align: center;
    }
  }

  & .rs-radio-inline + .rs-radio-inline {
    margin-left: 0;
  }
`;

const StyledField = styled(StyledRadioGroup)`
  .rs-radio-inline {
    &:first-of-type {
      width: 45px;
    }

    &:last-of-type {
      width: 44px;
    }
  }

  & .rs-radio-inline + .rs-radio-inline {
    margin-left: 0;
  }
`;

interface Props {
  yesValue?: string;
  noValue?: string;
}

export const RadioField = ({ name, yesLabel, noLabel, disabled, ...props }: RadioGroupProps<Props>) => {
  const { t } = useTranslation();
  return (
    <StyledField appearance="picker" inline className="is-boolean" name={name} {...props}>
      <Radio value={true} disabled={disabled}>
        <div className="corner" />
        {yesLabel || t('common.options.yes')}
      </Radio>
      <Radio value={false} disabled={disabled}>
        <div className="corner" />
        {noLabel || t('common.options.no')}
      </Radio>
    </StyledField>
  );
};
const MemorizedField = React.memo(withLabel<RadioGroupProps>(RadioField), (prevProps, nextProps) => {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error
  );
});

interface LabeledFieldProps extends RadioGroupProps {
  errors?: ErrorObject;
}

export const LabeledBooleanField = ({ errors, name, ...props }: WrappedInputProps<LabeledFieldProps>) => {
  // @ts-ignore
  return <MemorizedField name={name} error={extractError(errors, name)} {...props} />;
};
