import { store } from '../../../../redux/applicationStore';
import {
  CertificationDefinition,
  SectionEditState,
  updateCertificationProperty,
} from '../../../../redux/modules/certification';
import { AuditingInfo, BasicSaveResponse, CertificationType } from '../types';
import { ErrorObject, IterableObject } from '../../../../helpers/types';
import { CertificationStatuses } from '../../../../helpers/constants/certification-statuses';
import { getSchemeByTypeAndStatus } from '../validation-schemes';
import { prepareYupModel } from '../../../../helpers';
import { RefObject, useRef } from 'react';

export const saveData = (
  key: keyof CertificationDefinition,
  data: Partial<CertificationDefinition>,
  auditingKey: keyof AuditingInfo,
  auditingInfoData: BasicSaveResponse,
) => {
  store.dispatch(
    updateCertificationProperty(data, key, {
      auditingInfo: {
        propertyToUpdate: auditingKey,
        dataToUpdate: { ...auditingInfoData },
      },
      saving: false,
      sectionEditState: { [key]: false },
    }),
  );
};

export const isErrorsNotEmpty = (errors: ErrorObject, keyName: string) => {
  const keys = Object.keys(errors);
  return keys.length !== 0 && keys.some(key => key.includes(keyName) && errors[key] !== '');
};

export const getSectionsToEditByErrors = (errors: ErrorObject) =>
  Object.keys(errors).reduce((accum, nextKey) => {
    const splittedKey = nextKey.split('.')[0] as keyof SectionEditState;
    if (!accum[splittedKey]) {
      accum[splittedKey] = true;
    }
    return accum;
  }, {} as Partial<SectionEditState>);

export const moveToErrorSection = (sectionName: string, refs: IterableObject<RefObject<HTMLDivElement>>) => {
  const offset = refs[sectionName.replace(/\[|\]|[0-9]/g, '')].current?.offsetTop || 0;
  window.scrollTo(0, offset > 0 ? offset - 60 : offset);
};

export const scrollToErrors = (
  type: CertificationType,
  status: CertificationStatuses,
  certification: CertificationDefinition,
  refs: IterableObject<RefObject<HTMLDivElement>>,
) => {
  const validationErrors = prepareYupModel(
    getSchemeByTypeAndStatus(type, CertificationStatuses.PUBLISHED),
  ).checkFormatted(certification) as ErrorObject;
  const sectionsToEdit = getSectionsToEditByErrors(validationErrors);
  moveToErrorSection(Object.keys(sectionsToEdit)[0], refs);
  return {
    validationErrors,
    sectionsToEdit,
  };
};

export const getRefsForSections = () =>
  ['generalInfo', 'version', 'capabilities', 'prerequisites', 'interoperabilityTest', 'testGroups'].reduce(
    (acc, value: string) => {
      acc[value] = useRef({} as HTMLDivElement);
      return acc;
    },
    {} as IterableObject<RefObject<HTMLDivElement>>,
  );
