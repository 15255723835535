import React, { useEffect, useState } from 'react';
import { getSourceProductByAndCompanyId, getSourceProductByAppId } from '../../../../api/application';
import { AxiosResponse } from 'axios';
import { ErrorObject } from '../../../../helpers/types';
import { useTranslation } from 'react-i18next';
import HierarchicalDropdown, {
  HierarchicalDropdownProps,
} from '../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';

interface Variant {
  id: number;
  name: string;
  certified: boolean;
}

export interface SourceProduct {
  id: number;
  cid: string;
  name: string;
  variants: Variant[];
}

interface Props {
  products: { name: string; id: number }[];
  cid?: string | null;
  id: number | undefined | null;
  defaultCompanyId?: number;
  defaultSourceProductId?: number;
  onChange: (value: number | undefined, name?: string | undefined) => void;
  onCIDChange?: (value: string | undefined, name?: string | undefined, id?: number | undefined) => void;
  onCompanyChange?: (id: number | undefined, name?: string | undefined) => void;
  errors?: ErrorObject;
  behalfCompanyId?: number;
}

export const FindProductVariant = ({
  products,
  cid,
  id,
  onChange,
  onCIDChange,
  onCompanyChange,
  errors,
  defaultCompanyId,
  defaultSourceProductId,
  behalfCompanyId,
}: Props) => {
  const derivativePropsName = {
    cid: 'companyContactInfo.source.cid',
    id: 'companyContactInfo.source.id',
  };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sourceCompanyId, setSourceCompanyId] = useState<number | undefined>(defaultCompanyId);
  const [sourceProducts, setSourceProducts] = useState<SourceProduct[]>([]);
  const [variants, setVariants] = useState<Variant[]>([]);
  const [selectedSourceProduct, setSelectedSourceProduct] = useState<number | undefined>(defaultSourceProductId);

  const handleSourceClean = () => {
    setSelectedSourceProduct(-1);
    id && onChange(undefined);
    onCIDChange && onCIDChange('');
    setVariants([]);
  };

  const handleCompanyChange = (companyId: number) => {
    setVariants([]);
    setSourceProducts([]);
    setSourceCompanyId(companyId);
    handleSourceClean();
    if (onCompanyChange) {
      const company = products.find(item => item.id === companyId);
      onCompanyChange(companyId, company?.name);
    }
  };

  useEffect(() => {
    if (sourceCompanyId && sourceCompanyId !== -1) {
      setLoading(true);
      const request = behalfCompanyId
        ? getSourceProductByAndCompanyId(sourceCompanyId, behalfCompanyId)
        : getSourceProductByAppId(sourceCompanyId);
      request
        .then((response: AxiosResponse<SourceProduct[]>) => {
          setSourceProducts(
            response.data.map(product => ({ ...product, fullName: `${product.cid} - ${product.name}` })),
          );
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [sourceCompanyId, behalfCompanyId]);

  const handleSourceChange = (productId: number) => {
    setSelectedSourceProduct(productId);
    if (id) {
      onChange(undefined);
    }
    const sourceProduct = sourceProducts.find(product => product.id === productId);
    onCIDChange && onCIDChange(sourceProduct?.cid, sourceProduct?.name, sourceProduct?.id);
    setVariants(sourceProduct?.variants || []);
  };

  useEffect(() => {
    if (selectedSourceProduct && sourceProducts.length && !cid) {
      const product = sourceProducts.find(sp => sp.id === selectedSourceProduct);
      setVariants(product?.variants || []);
      onCIDChange && onCIDChange(product?.cid, product?.name, product?.id);
    }
  }, [selectedSourceProduct, sourceProducts]);

  useEffect(() => {
    if (sourceCompanyId === -1) {
      if (cid) {
        setSelectedSourceProduct(-1);
        onCIDChange && onCIDChange('');
      }
      if (id) {
        onChange(undefined);
      }
    }
  }, [sourceCompanyId]);

  useEffect(() => {
    if (selectedSourceProduct === -1 && id) {
      onChange(undefined);
    }
  }, [selectedSourceProduct]);

  const handleVariantChanged = (value: number) => {
    const variant = variants.find(item => item.id === value);
    onChange(value || undefined, variant ? variant.name : undefined);
  };

  const dropdownData: HierarchicalDropdownProps[] = [
    {
      data: products,
      value: sourceCompanyId,
      labelKey: 'name',
      valueKey: 'id',
      placeholder: 'Choose a source company',
      onChange: handleCompanyChange,
      onClean: () => handleCompanyChange(-1),
      name: 'company',
    },
    {
      data: sourceProducts,
      value: selectedSourceProduct,
      labelKey: 'fullName',
      valueKey: 'id',
      placeholder: 'Choose a source product',
      onChange: handleSourceChange,
      onClean: handleSourceClean,
      name: derivativePropsName.cid,
      loading,
      errors,
    },
    {
      data: variants,
      value: id,
      labelKey: 'name',
      valueKey: 'id',
      placeholder: t('applications.cc.chooseVariant'),
      onChange: handleVariantChanged,
      onClean: () => onChange(undefined),
      name: derivativePropsName.id,
      loading,
      errors,
    },
  ];

  return <HierarchicalDropdown dropdownData={dropdownData} />;
};
