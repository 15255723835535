import { SortingOptions } from '../components/pages/test-case/types';
import { SummaryFiltersObject } from './types';
import { recordPerPage } from './constants';

export const buildSummaryURL = (
  baseUrl: string,
  page: number,
  sortingOptions?: SortingOptions,
  filters?: SummaryFiltersObject,
  activeTab?: string,
): string => {
  const status = activeTab ? `&status=${activeTab}` : '';
  let url = `/${baseUrl}?start=${(page - 1) * recordPerPage}&size=${recordPerPage}${status}`;
  if (filters) {
    if (filters.search) {
      url += `&search=${filters.search}`;
    }
    if (filters.from) {
      url += `&from=${filters.from}`;
    }
    if (filters.to) {
      url += `&to=${filters.to}`;
    }
    if (filters.roleId) {
      for (const id of filters.roleId) {
        url += `&roleId=${id}`;
      }
    }
    if (filters.type) {
      for (const id of filters.type) {
        url += `&type=${id}`;
      }
    }
    if (filters.certificationId) {
      url += `&certificationId=${filters.certificationId}`;
    }
    if (filters.testCaseId) {
      url += `&testCaseId=${filters.testCaseId}`;
    }
    if (filters.applicationType) {
      url += `&type=${filters.applicationType}`;
    }
    if (filters.applicationTypeId) {
      for (const id of filters.applicationTypeId) {
        url += `&applicationTypeId=${id}`;
      }
    }
    if (filters.labId) {
      for (const id of filters.labId) {
        url += `&labId=${id}`;
      }
    }
    if (filters.owningCompanyId) {
      for (const id of filters.owningCompanyId) {
        url += `&owningCompanyId=${id}`;
      }
    }
    if (filters.searchable) {
      for (const id of filters.searchable) {
        url += `&searchable=${id}`;
      }
    }
    if (filters.available) {
      for (const id of filters.available) {
        url += `&available=${id}`;
      }
    }
    if (filters.canCreateDerivative) {
      for (const id of filters.canCreateDerivative) {
        url += `&canCreateDerivative=${id}`;
      }
    }
    if (filters.isCertified) {
      for (const id of filters.isCertified) {
        url += `&isCertified=${id}`;
      }
    }
    if (filters.availableAsQualifiedSolution) {
      for (const id of filters.availableAsQualifiedSolution) {
        url += `&availableAsQualifiedSolution=${id}`;
      }
    }
    if (filters.worstCertificationVersionStatus) {
      for (const id of filters.worstCertificationVersionStatus) {
        url += `&worstCertificationVersionStatus=${id}`;
      }
    }
    if (filters.decision) {
      for (const id of filters.decision) {
        url += `&decision=${id}`;
      }
    }
    if (filters.amount) {
      for (const id of filters.amount) {
        url += `&amount=${id}`;
      }
    }
    if (filters.requestingAccountId) {
      for (const id of filters.requestingAccountId) {
        url += `&requestingAccountId=${id}`;
      }
    }
    if (filters.applicationStatus) {
      for (const id of filters.applicationStatus) {
        url += `&applicationStatus=${id}`;
      }
    }
    if (filters.hasDraftCapability) {
      for (const id of filters.hasDraftCapability) {
        url += `&hasDraftCapability=${id}`;
      }
    }
    if (filters.capabilityId) {
      for (const id of filters.capabilityId) {
        url += `&capabilityId=${id}`;
      }
    }
  }
  if (sortingOptions) {
    url += `&sort=${sortingOptions.sortColumn},${sortingOptions.sortType}`;
  }
  return url;
};
