import React from 'react';
import { Chip } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// @ts-ignore
import _var from '../../styles/_variables.scss';

const useStyles = makeStyles({
  buttonValue: {
    borderRadius: '6px',
    fontSize: '12px',
    padding: '2px 8px',
    width: '36px',
    lineHeight: 1.66666667,

    '& .MuiChip-label': {
      padding: 0,
    },
  },
  buttonValueYes: {
    backgroundColor: _var.greenYes,
    color: 'white',
  },
  buttonValueNo: {
    backgroundColor: _var.greenNo,
    color: 'black',
  },
});

export const YesNoColumn = (props: { value: boolean }): React.ReactElement<string> => {
  const classes = useStyles();
  const clazz = props.value ? classes.buttonValueYes : classes.buttonValueNo;

  return <Chip label={props.value ? 'Yes' : 'No'} className={classNames(classes.buttonValue, clazz)} />;
};
