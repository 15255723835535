import { AppCertification } from '../../../../redux/modules/application-flow';
import { jsonCopy, mergeObjects } from '../../../../helpers';
import { ComponentCombination, NewRoles, WiFiComponents } from '../../../../api/application/types';
import { LabsResponse } from '../../../../api/application/get-labs-by-app-id';
import { CertificationWithLabData, EasyMeshController, HomeDesignProps, OtherRolesProps } from '../types';

export const mergeCertWithLabData = (
  certifications: AppCertification[],
  labData: LabsResponse[],
): CertificationWithLabData[] => {
  return certifications.map(cert => {
    const responseCert = labData.find(item => item.certification.id === cert.id);

    return mergeObjects(
      {
        certification: {
          id: cert.id,
          name: cert.name,
          role: cert.role,
          version: cert.version,
          testingType: {
            interoperability: false,
            conformance: false,
          },
        },
        version: cert.version,
      },
      responseCert || {
        laboratories: [],
        requiredTesting: {
          interoperability: true,
          conformance: true,
        },
      },
    );
  });
};

export const reformatCC = (cc: ComponentCombination, removeSortOrder = false) => {
  if (cc !== null) {
    const reformattedData = Object.keys(cc).reduce((prev, curr: string) => {
      let calculatedData = cc[curr as keyof ComponentCombination];
      if (calculatedData !== null && Array.isArray(calculatedData) && (calculatedData as []).length !== 0) {
        if (removeSortOrder) {
          calculatedData = jsonCopy(calculatedData);
          calculatedData.forEach((cc: OtherRolesProps | EasyMeshController | HomeDesignProps | NewRoles | null) => {
            if (cc && 'sortOrder' in cc) {
              delete cc.sortOrder;
            }
          });
        }
        prev[curr as keyof ComponentCombination] = calculatedData;
      }
      return prev;
    }, {} as WiFiComponents);
    return Object.keys(reformattedData).length ? reformattedData : null;
  }
  return null;
};
