import { ProductCategories } from '../api/application/types';
import { Categories } from '../components/pages/application/types';
import { jsonCopy } from './json-copy';

export interface ReformattedCategories {
  label: string;
  value: number;
  role: string;
}

// convert from array object like [{ id: 1 }, { id: 2 }] => [1,2]
export const reformatFromIdToNumber = (categories: ProductCategories[]) =>
  jsonCopy(categories.map((category: ProductCategories) => category.id));

export const getReformattedCategories = (categories: Categories[]): ReformattedCategories[] =>
  jsonCopy(
    categories.reduce((accum, category) => {
      accum.push(
        ...(category.categories.map(categoryItem => ({
          label: categoryItem.name,
          value: categoryItem.id,
          role: category.name,
        })) as ReformattedCategories[]),
      );

      return accum;
    }, [] as ReformattedCategories[]),
  );
