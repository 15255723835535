import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { LabelTypes } from '../../helpers/constants/label-types';
import WarningIcon from '@material-ui/icons/Warning';

// @ts-ignore
import _var from '../../styles/_variables.scss';
import { Tooltip } from '../tooltip/Tooltip';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: 12,
    lineHeight: '16px',
    borderRadius: '4px',
    padding: '4px 10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'cetner',
    backgroundColor: _var.igoPlumLighten,
    color: _var.primary,
    height: 'fit-content',
    maxHeight: theme.spacing(5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  summaryLabel: {
    fontSize: 12,
    lineHeight: '16px',
    borderRadius: '4px',
    padding: '4px 10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'cetner',
    backgroundColor: _var.igoPlumLighten,
    color: _var.primary,
    height: 'fit-content',
    maxHeight: theme.spacing(5),
    marginTop: theme.spacing(1),
  },
  blockLabel: {
    fontSize: 12,
    lineHeight: '16px',
    borderRadius: '4px',
    padding: '4px 10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'cetner',
    backgroundColor: _var.primary,
    color: _var.white,
    height: 'fit-content',
    maxHeight: theme.spacing(5),
    marginTop: theme.spacing(1),
  },
  ccUpdateLabel: {
    fontSize: 12,
    lineHeight: '16px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'cetner',
    color: _var.primary,
    height: 'fit-content',
    maxHeight: theme.spacing(5),
  },
  exceptionLabel: {
    fontSize: 12,
    lineHeight: '16px',
    padding: '4px 10px',
    borderRadius: '14px',
    color: _var.primary,
    height: 'fit-content',
    backgroundColor: _var.igoPlumLighten,
    maxHeight: theme.spacing(5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
}));

interface LabelTypeProps {
  label: string | null;
  tooltipText?: string;
  tooltipContent?: NonNullable<React.ReactNode>;
  labelTypes?: LabelTypes;
}

export const LabelType: React.FC<LabelTypeProps> = ({
  label,
  tooltipText,
  tooltipContent,
  labelTypes = LabelTypes.NORMAL_LABEL,
}) => {
  const classes = useStyles();

  if (!label) return null;

  switch (labelTypes) {
    case LabelTypes.NORMAL_LABEL:
      return (
        <div className={classes.root}>
          <label data-testid="label-type" className={classes.label}>
            {label}
          </label>
          {tooltipText && (
            <Tooltip translationKey={tooltipText}>
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </div>
      );
    case LabelTypes.SUMMARY_LABEL:
      return (
        <div className={classes.root}>
          <label data-testid="label-type" className={classes.summaryLabel}>
            {label}
          </label>
          {tooltipText && (
            <Tooltip translationKey={tooltipText}>
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </div>
      );
    case LabelTypes.BLOCK_LABEL:
      return (
        <div className={classes.root}>
          <label data-testid="label-type" className={classes.blockLabel}>
            {label}
          </label>
          {tooltipText && (
            <Tooltip translationKey={tooltipText}>
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </div>
      );
    case LabelTypes.CC_UPDATE_LABEL: {
      const CcChangeLabelStyle = {
        top: 0,
        left: 0,
        fontSize: '14px',
        width: '18px',
        height: '18px',
        marginLeft: '8px',
        marginRight: '5px',
        color: '#641246',
      };
      return (
        <div className={classes.root}>
          <WarningIcon style={CcChangeLabelStyle} />
          <label data-testid="label-type" className={classes.ccUpdateLabel}>
            {label}
          </label>
        </div>
      );
    }
    case LabelTypes.CC_EXCEPTION_LABEL: {
      const exceptionLabelStyle = {
        top: 0,
        left: 0,
        fontSize: '14px',
        width: '18px',
        height: '18px',
        marginRight: '5px',
        color: '#641246',
      };
      return (
        tooltipContent && (
          <Tooltip content={tooltipContent}>
            <label data-testid="label-type" className={classes.exceptionLabel}>
              <WarningIcon style={exceptionLabelStyle} />
              {label}
            </label>
          </Tooltip>
        )
      );
    }
    case LabelTypes.EXCEPTION_LABEL: {
      return (
        <>
          <label data-testid="label-type" className={classes.exceptionLabel}>
            {label}
          </label>
        </>
      );
    }
    default:
      return (
        <div className={classes.root}>
          <label data-testid="label-type" className={classes.label}>
            {label}
          </label>
          {tooltipText && (
            <Tooltip translationKey={tooltipText}>
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </div>
      );
  }
};
