import { ErrorObject, ValidationError, ValidationErrorById } from '../types';
import { mergeObjects } from '../object-merge';
import i18next from 'i18next';
import { messageKeyPrefix } from '../constants';
import i18n from '../i18n-setup';

export const formatErrors = (errors: ValidationError[]): ErrorObject => {
  return errors.reduce((prev: ErrorObject, current) => {
    return mergeObjects(prev, {
      [current.field]: i18next.t(`common.validation.${current.messageKey}`, current.messageParameters),
    });
  }, Object.create(null));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatErrorById = (errors: ValidationErrorById[], data: any[], prefix: string, property = 'id') => {
  return errors.reduce((prev: ErrorObject, current) => {
    const idx = data.findIndex(item => item[property] === current.id);
    if (idx === -1) {
      return prev;
    }
    const errorDescription = current.errorDescriptions[0];
    const messageKey = (errorDescription.messageKey || '').replace(messageKeyPrefix, '');
    let message = i18n.t(messageKey, errorDescription.parameters);
    if (!message || message === messageKey) {
      message = errorDescription.message;
    }
    return mergeObjects(prev, { [`${prefix}[${idx}]`]: message });
  }, Object.create(null));
};
