import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'rsuite';
import { makeStyles } from '@material-ui/core/styles';
import { AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

import { reformatDate } from '../../../../../helpers';
import { ChangeOwningCompanySummaryDetail } from '../types';
import { DownloadBundleButton } from '../../../../../shared-components/button/DownloadBundleButton';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';
import { downloadTransferResult } from '../../../../../api/staff/get-transfer-products';
import { downloadStringAsFile } from '../../../../../helpers/download-file-from-string';
import { handleRequestFail } from '../../../../../helpers/request-fail-handler';
import { CircularLoader } from '../../../../../shared-components/loader/CircularLoader';
import { RsCenterModal } from '../../../../../shared-components/rsuite/RsCenterModal';
interface SummaryDetailModalProps {
  show: boolean;
  onClose: () => void;
  payload: ChangeOwningCompanySummaryDetail;
}

const useStyles = makeStyles({
  modal: { width: 500 },
  modalBody: { paddingTop: 20, paddingBottom: 20, marginTop: 0, maxHeight: 400, overflow: 'hidden' },
  header: {
    fontWeight: 'bold',
    color: _var.textGray,
    size: 16,
    marginRight: 16,
  },
  footer: {
    color: _var.textGray,
    size: 14,
    textAlign: 'left',
  },
  body: { display: 'flex', overflow: 'hidden' },
  leftBody: {
    flex: 1,
    padding: '15px 20px 15px 0px',
    borderRight: `1px solid ${_var.grayBorder}`,
    overflow: 'hidden auto',
  },
  rightBody: {
    padding: '15px 0px 15px 20px',
    flex: 1,
    overflow: 'hidden',
    maxHeight: 350,
  },
  leftBodyHeader: {
    flex: 1,
    fontWeight: 'bold',
    color: _var.textGray,
    size: 14,
    marginBottom: 7,
  },
  leftBodyContent: {
    color: _var.textGray,
    size: 14,
    marginBottom: 30,
  },
  rightBodyHeader: {
    flex: 1,
    fontWeight: 'bold',
    color: _var.textGray,
    size: 14,
    marginBottom: 7,
  },
  rightBodyContainer: { height: '100%', overflow: 'hidden auto', paddingBottom: 20 },
  rightBodyContent: { flex: 1, color: _var.textGray, size: 14 },
});

export const SummaryDetailModal = (props: SummaryDetailModalProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const classes = useStyles();
  const [downloadingFile, setDownloadingFile] = useState(false);

  const { show, onClose, payload } = props;

  if (payload == null) return null;

  const { isSucceed = true, sourceCompanyName, targetCompanyName, timestamp, transferResult, id } = payload;

  const productIds = isSucceed ? transferResult.successProducts : transferResult.failedProducts;

  const displayLimit = 100;
  let displayCount = 0;

  const downloadResult = () => {
    setDownloadingFile(true);
    downloadTransferResult(id, isSucceed)
      .then((response: AxiosResponse) => {
        setDownloadingFile(false);
        downloadStringAsFile(
          `${isSucceed ? 'Transferred' : 'Failed'}_Products_Report.csv`,
          response.data,
          'data:text/csv',
        );
      })
      .catch(error => {
        setDownloadingFile(false);
        handleRequestFail(error, addToast);
      });
  };

  return (
    <RsCenterModal backdrop show={show} onHide={onClose} className={classes.modal}>
      <Modal.Header>
        <span className={classes.header}>
          {isSucceed
            ? t('admin.changeOwningCompany.summary.transferredProducts')
            : t('admin.changeOwningCompany.summary.failedProducts')}
          : {productIds.length}
        </span>
        <DownloadBundleButton onClick={() => downloadResult()} />
      </Modal.Header>
      <Modal.Body className={classes.modalBody}>
        {downloadingFile && <CircularLoader backdrop content={t('common.placeholders.loadingData')} />}
        <div className={classes.body}>
          <div className={classes.leftBody}>
            <div className={classes.leftBodyHeader}>{t('admin.changeOwningCompany.companyInfo.fromCompany')}</div>
            <div className={classes.leftBodyContent}>{sourceCompanyName}</div>
            <div className={classes.leftBodyHeader}>{t('admin.changeOwningCompany.companyInfo.toCompany')}</div>
            <div className={classes.leftBodyContent}>{targetCompanyName}</div>
            <div className={classes.leftBodyHeader}>{t('admin.changeOwningCompany.summary.date')}</div>
            <div className={classes.leftBodyContent}>{reformatDate(timestamp)}</div>
          </div>
          <div className={classes.rightBody}>
            <div style={{ display: 'flex' }}>
              <div className={classes.rightBodyHeader}>CID</div>
              <div className={classes.rightBodyHeader}>AppID</div>
            </div>
            <div className={classes.rightBodyContainer}>
              {productIds.map((p, idx) => {
                if (displayCount >= displayLimit) return null;
                return (
                  <div style={{ display: 'flex' }} key={`${p.product.cid}${idx}`}>
                    <div className={classes.rightBodyContent}>{p.product.cid}</div>
                    <div className={classes.rightBodyContent}>
                      {p.product.variants.map((appid, idx) => {
                        displayCount++;
                        if (displayCount > displayLimit) return null;
                        return <div key={`${appid}${idx}`}>{appid}</div>;
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={classes.footer}>{t('admin.changeOwningCompany.summary.maximum')}</div>
      </Modal.Footer>
    </RsCenterModal>
  );
};
