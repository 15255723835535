import React from 'react';
import { SingleAppStaffSummaryRecord } from '../../application/types';
import { useTranslation } from 'react-i18next';
import { SummaryTableWithoutTabProps } from '../../../../helpers/types';
import { reformatDate } from '../../../../helpers';
import { StaffSummaryStatuses } from '../types';
import { ApplicationsActionsColumn } from './ApplicationsActionsColumn';
import DataTable from '../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../shared-components/table/DataTableColumn';

export const ApplicationsSummaryTable = ({
  data,
  loading,
  onChangeSorting,
  sorting,
  total,
  activePage,
  setActivePage,
}: SummaryTableWithoutTabProps<SingleAppStaffSummaryRecord>) => {
  const { t } = useTranslation();

  return (
    <DataTable<SingleAppStaffSummaryRecord>
      sticky
      pagination
      data={data}
      total={total}
      sorting={sorting}
      loading={loading}
      activePage={activePage}
      setActivePage={setActivePage}
      onChangeSorting={onChangeSorting}
      useEnvIdentifier={true}
    >
      <DataTableColumn field="cid" label={t('applications.summary.cid')} minWidth={110} sortable />
      <DataTableColumn field="id" label={t('applications.summary.appId')} minWidth={100} sortable />
      <DataTableColumn
        field="productName"
        label={t('applications.summary.name')}
        minWidth={160}
        width="20%"
        sortable
        wordWrap
      />
      <DataTableColumn
        field="variantName"
        label={t('applications.info.variantName')}
        minWidth={160}
        width="20%"
        sortable
        wordWrap
      />
      <DataTableColumn
        field="typeLabel"
        label={t('applications.summary.type')}
        minWidth={200}
        width="10%"
        sortable
        wordWrap
      >
        {(row: SingleAppStaffSummaryRecord) => row.type}
      </DataTableColumn>
      <DataTableColumn
        field="owningCompanyName"
        label={t('applications.summary.company')}
        minWidth={100}
        width="20%"
        sortable
        wordWrap
      >
        {(row: SingleAppStaffSummaryRecord) => row.owningCompany}
      </DataTableColumn>
      <DataTableColumn field="atl" label={t('applications.summary.atl')} minWidth={150} width="20%" wordWrap>
        {(row: SingleAppStaffSummaryRecord) => row.atl.map(atl => <p key={atl.id}>{atl.name}</p>)}
      </DataTableColumn>
      <DataTableColumn field="staffAllApplicationsState" label={t('staff.summary.status')} minWidth={160} sortable>
        {(row: SingleAppStaffSummaryRecord) => row.staffStatus && StaffSummaryStatuses[row.staffStatus]}
      </DataTableColumn>
      <DataTableColumn field="staffAllApplicationsDate" label={t('staff.summary.statusChange')} minWidth={160} sortable>
        {(row: SingleAppStaffSummaryRecord) => (row.date ? reformatDate(row.date) : null)}
      </DataTableColumn>
      <DataTableColumn field="actions" label={t('common.actions.title')} minWidth={100}>
        {(row: SingleAppStaffSummaryRecord) => <ApplicationsActionsColumn record={row} />}
      </DataTableColumn>
    </DataTable>
  );
};
