import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'rsuite';

import { ImpersonateAutoCompleteItem } from '../../../../../components/partials/impersonate/types';
import { AutocompleteField } from '../../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';
import { RsCenterModal } from '../../../../../shared-components/rsuite';
interface SelectCompaniesModalProps {
  companies: ImpersonateAutoCompleteItem[];
  show: boolean;
  onClose: () => void;
  onApply: (from: number, to: number) => void;
}

export const SelectCompaniesModal = (props: SelectCompaniesModalProps) => {
  const { t } = useTranslation();
  const { companies, show, onClose, onApply } = props;

  const [selectedCompanyFrom, setSelectedCompanyFrom] = useState<number | null>(null);
  const [selectedCompanyTo, setSelectedCompanyTo] = useState<number | null>(null);

  const handleExitModal = () => {
    setSelectedCompanyFrom(null);
    setSelectedCompanyTo(null);
  };

  const handleApply = () => {
    // @ts-ignore
    onApply(selectedCompanyFrom, selectedCompanyTo);
    onClose();
  };

  return (
    <RsCenterModal backdrop show={show} onExit={handleExitModal}>
      <Modal.Header closeButton={false}>
        <Modal.Title>{t('admin.changeOwningCompany.modal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0, paddingBottom: 48, marginTop: 16 }}>
        <div style={{ marginBottom: 16 }}>
          {t('admin.changeOwningCompany.modal.slectTheCompanyToTransfer')}{' '}
          <span style={{ fontWeight: 'bold' }}>{t('admin.changeOwningCompany.modal.from')}</span>
        </div>
        <AutocompleteField
          placeholder={t('common.placeholders.chooseCompany')}
          data={selectedCompanyTo !== null ? companies.filter(c => c.id !== selectedCompanyTo) : companies}
          value={selectedCompanyFrom}
          labelKey={'name'}
          valueKey={'id'}
          name={'chooseCompanyFrom'}
          onChange={setSelectedCompanyFrom}
          onClean={() => {
            setSelectedCompanyFrom(null);
          }}
        />
        <div style={{ marginTop: 24, marginBottom: 16 }}>
          {t('admin.changeOwningCompany.modal.slectTheCompanyToTransfer')}{' '}
          <span style={{ fontWeight: 'bold' }}>{t('admin.changeOwningCompany.modal.to')}</span>
        </div>
        <AutocompleteField
          placeholder={t('common.placeholders.chooseCompany')}
          data={selectedCompanyFrom !== null ? companies.filter(c => c.id !== selectedCompanyFrom) : companies}
          value={selectedCompanyTo}
          labelKey={'name'}
          valueKey={'id'}
          name={'chooseCompanyTo'}
          onChange={setSelectedCompanyTo}
          onClean={() => {
            setSelectedCompanyTo(null);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="link" onClick={onClose} data-test-id="selectCompanyModal.button.close">
          {t('common.navigation.cancel')}
        </Button>
        <Button
          appearance="primary"
          onClick={handleApply}
          disabled={selectedCompanyFrom == null || selectedCompanyTo == null}
          data-test-id="selectCompanyModal.button.apply"
        >
          {t('common.actions.apply')}
        </Button>
      </Modal.Footer>
    </RsCenterModal>
  );
};
