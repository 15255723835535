import React, { ChangeEvent } from 'react';
import { Row, Col, Whisper, Popover, Icon } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateTime } from 'luxon';

import {
  LabeledInput,
  LabeledBooleanField,
  LabeledSelect,
  LabeledTextArea,
} from '../../../../../shared-components/labeled-inputs';
import LifeCycleStepper from '../lifecycle/LifeCycleStepper';
import LifeCycleStep from '../lifecycle/LifeCycleStep';
import { KeyboardDatePicker } from './KeyboardDatePicker';
import { LifeCycleDetailsLabel } from './LifeCycleDetailsLabel';
import { DetailsItem } from '../../../../partials';
import { profileData } from '../../../../../redux/modules/certification/data';
import { CertificationStatuses } from '../../../../../helpers/constants/certification-statuses';
import { getDateDifference, reformatDateToFormat, summaryDateFormat } from '../../../../../helpers';
import { getLifeCycleActiveStep } from '../lifecycle/helpers';
import { CertificationType, OnChangeFunc, ProfileType, VersionInfo } from '../../types';
import { ErrorObject } from '../../../../../helpers/types';

interface EditVersionSectionProps {
  data: VersionInfo;
  type: CertificationType;
  errors?: ErrorObject;
  onChange: OnChangeFunc;
  status: CertificationStatuses;
  initialData: VersionInfo;
  handleChangeDate: (value: MaterialUiPickersDate, name: string) => void;
}

export const EditVersionSection: React.FC<EditVersionSectionProps> = ({
  data,
  type,
  errors,
  onChange,
  status,
  initialData,
  handleChangeDate,
}) => {
  const activeStep = getLifeCycleActiveStep(initialData, status);
  const { t } = useTranslation();

  const isDateEditable = (date: string | null) =>
    status === CertificationStatuses.DRAFT || !date || getDateDifference(date) > 0;

  const onProfileTypeChange = (value: string) => {
    onChange(value, 'version.profile.type');
    if (data.profile.name !== '') {
      onChange('', 'version.profile.name');
    }
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={8}>
              <LabeledInput
                noMargin
                required
                label={t('certifications.version.version')}
                placeholder="Version"
                name="version.version"
                defaultValue={data.version}
                errors={errors}
                onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => onChange(value, event.target.name)}
                disabled={type === CertificationType.CAPABILITY}
              />
            </Col>
            <Col xs={8}>
              <LabeledBooleanField
                noMargin
                required
                name="version.visibleOnCertificate"
                label={t('certifications.version.versionAppearsOnCertificate')}
                value={data.visibleOnCertificate}
                errors={errors}
                onChange={(value: string) => onChange(value, 'version.visibleOnCertificate')}
                disabled={type === CertificationType.CAPABILITY}
              />
            </Col>
            <Col xs={8}>
              <LabeledSelect
                block
                noMargin
                required
                cleanable={false}
                searchable={false}
                label={t('certifications.version.profileType')}
                name="version.profile.type"
                data={profileData}
                placeholder={t('common.placeholders.select')}
                value={data.profile?.type}
                errors={errors}
                onSelect={value => onProfileTypeChange(value)}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row gutter={20}>
            <Col xs={8}>
              {[ProfileType.PROFILE, ProfileType.ASD].includes(data.profile?.type) ? (
                <LabeledInput
                  required
                  label={t(
                    `certifications.version.${
                      data.profile?.type === ProfileType.PROFILE ? 'profileName' : 'ASDTestPlan'
                    }`,
                  )}
                  name="version.profile.name"
                  value={data.profile?.name}
                  errors={errors}
                  placeholder={t(
                    `certifications.version.${
                      data.profile?.type === ProfileType.PROFILE ? 'profileName' : 'ASDTestPlan'
                    }`,
                  )}
                  onChange={(value, event) => onChange(value, event.target.name)}
                />
              ) : null}
            </Col>
            <Col xs={8}>
              <LabeledInput
                noMargin
                label="ECN"
                name="version.ecn"
                placeholder="ECN"
                defaultValue={data.ecn}
                errors={errors}
                onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => onChange(value, event.target.name)}
                disabled={type === CertificationType.CAPABILITY}
              />
            </Col>
            <Col xs={8}>
              <LabeledInput
                label={
                  <>
                    {t('certifications.version.revision.title')}
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={
                        <Popover style={{ maxWidth: '250px' }}>{t('certifications.version.revision.info')}</Popover>
                      }
                    >
                      <Icon className="pointer text-muted" icon="question2" style={{ margin: '0 0.5rem' }} />
                    </Whisper>
                  </>
                }
                name="version.revision"
                required
                noMargin
                placeholder="Revision"
                defaultValue={data.revision}
                errors={errors}
                onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => onChange(value, event.target.name)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {type === CertificationType.PRIMARY && (
        <Row>
          <Col xs={20}>
            <LifeCycleStepper activeStep={activeStep}>
              <LifeCycleStep disabled={!data.certificationStart}>
                {isDateEditable(initialData.certificationStart) ? (
                  <KeyboardDatePicker
                    required
                    errors={errors}
                    name="version.certificationStart"
                    label={<LifeCycleDetailsLabel translationKey="certifications.certStart" />}
                    value={data.certificationStart ? DateTime.fromISO(data.certificationStart) : null}
                    onChange={handleChangeDate}
                    withConfirmDialog={status !== CertificationStatuses.DRAFT}
                  />
                ) : (
                  <DetailsItem
                    label={<LifeCycleDetailsLabel translationKey="certifications.certStart" />}
                    value={
                      data.certificationStart ? reformatDateToFormat(data.certificationStart, summaryDateFormat) : null
                    }
                    placeholder={t('certifications.lifecycle.noDateSelected')}
                  />
                )}
              </LifeCycleStep>

              <LifeCycleStep disabled={!data.publicLaunch}>
                {isDateEditable(initialData.publicLaunch) ? (
                  <KeyboardDatePicker
                    required
                    errors={errors}
                    name="version.publicLaunch"
                    label={<LifeCycleDetailsLabel translationKey="certifications.publicLaunch" />}
                    value={data.publicLaunch ? DateTime.fromISO(data.publicLaunch) : null}
                    onChange={handleChangeDate}
                    withConfirmDialog={status !== CertificationStatuses.DRAFT}
                  />
                ) : (
                  <DetailsItem
                    label={<LifeCycleDetailsLabel translationKey="certifications.publicLaunch" />}
                    value={data.publicLaunch ? reformatDateToFormat(data.publicLaunch, summaryDateFormat) : null}
                    placeholder={t('certifications.lifecycle.noDateSelected')}
                  />
                )}
              </LifeCycleStep>

              <LifeCycleStep disabled={!data.firstRetirementPhaseDate}>
                {isDateEditable(initialData.firstRetirementPhaseDate) ? (
                  <KeyboardDatePicker
                    errors={errors}
                    name="version.firstRetirementPhaseDate"
                    label={<LifeCycleDetailsLabel translationKey="certifications.retirementPhase1" />}
                    value={data.firstRetirementPhaseDate ? DateTime.fromISO(data.firstRetirementPhaseDate) : null}
                    onChange={handleChangeDate}
                    withConfirmDialog={status !== CertificationStatuses.DRAFT}
                  />
                ) : (
                  <DetailsItem
                    label={<LifeCycleDetailsLabel translationKey="certifications.retirementPhase1" />}
                    value={
                      data.firstRetirementPhaseDate
                        ? reformatDateToFormat(data.firstRetirementPhaseDate, summaryDateFormat)
                        : null
                    }
                    placeholder={t('certifications.lifecycle.noDateSelected')}
                  />
                )}
              </LifeCycleStep>

              <LifeCycleStep disabled={!data.secondRetirementPhaseDate}>
                {isDateEditable(initialData.secondRetirementPhaseDate) ? (
                  <KeyboardDatePicker
                    errors={errors}
                    name="version.secondRetirementPhaseDate"
                    label={<LifeCycleDetailsLabel translationKey="certifications.retirementPhase2" />}
                    value={data.secondRetirementPhaseDate ? DateTime.fromISO(data.secondRetirementPhaseDate) : null}
                    onChange={handleChangeDate}
                    withConfirmDialog={status !== CertificationStatuses.DRAFT}
                  />
                ) : (
                  <DetailsItem
                    label={<LifeCycleDetailsLabel translationKey="certifications.retirementPhase2" />}
                    value={
                      data.secondRetirementPhaseDate
                        ? reformatDateToFormat(data.secondRetirementPhaseDate, summaryDateFormat)
                        : null
                    }
                    placeholder={t('certifications.lifecycle.noDateSelected')}
                  />
                )}
              </LifeCycleStep>

              <LifeCycleStep disabled={!data.thirdRetirementPhaseDate}>
                {isDateEditable(initialData.thirdRetirementPhaseDate) ? (
                  <KeyboardDatePicker
                    errors={errors}
                    name="version.thirdRetirementPhaseDate"
                    label={<LifeCycleDetailsLabel translationKey="certifications.retirementPhase3" />}
                    value={data.thirdRetirementPhaseDate ? DateTime.fromISO(data.thirdRetirementPhaseDate) : null}
                    onChange={handleChangeDate}
                    withConfirmDialog={status !== CertificationStatuses.DRAFT}
                  />
                ) : (
                  <DetailsItem
                    label={<LifeCycleDetailsLabel translationKey="certifications.retirementPhase3" />}
                    value={
                      data.thirdRetirementPhaseDate
                        ? reformatDateToFormat(data.thirdRetirementPhaseDate, summaryDateFormat)
                        : null
                    }
                    placeholder={t('certifications.lifecycle.noDateSelected')}
                  />
                )}
              </LifeCycleStep>
            </LifeCycleStepper>
          </Col>
        </Row>
      )}
      <LabeledTextArea
        rows={6}
        label={t('certifications.version.summaryOfChanges')}
        name="version.changesSummary"
        defaultValue={data.changesSummary}
        errors={errors}
        placeholder="Summary of Changes"
        onChange={(value: string, event: ChangeEvent<HTMLTextAreaElement>) => onChange(value, event.target.name)}
      />
      <Row gutter={20}>
        <Col xs={12}>
          <LabeledTextArea
            label={t('certifications.version.notesFolLab')}
            name="version.labNotes"
            rows={6}
            defaultValue={data.labNotes}
            errors={errors}
            placeholder="Notes for Lab"
            onChange={(value: string, event: ChangeEvent<HTMLTextAreaElement>) => onChange(value, event.target.name)}
          />
        </Col>
        <Col xs={12}>
          <LabeledTextArea
            label={t('certifications.version.notesForMember')}
            name="version.memberNotes"
            rows={6}
            defaultValue={data.memberNotes}
            errors={errors}
            placeholder="Notes for Member"
            onChange={(value: string, event: ChangeEvent<HTMLTextAreaElement>) => onChange(value, event.target.name)}
          />
        </Col>
      </Row>
    </>
  );
};
