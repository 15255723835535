import React, { isValidElement, PropsWithChildren, ReactNode, useState } from 'react';
import { Section } from '../pages/application/partials';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandCollapseIcon } from '../../shared-components/theme';

const useStyles = makeStyles({
  holder: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: ({ withoutMarginBottom }: { withoutMarginBottom: boolean }) => (withoutMarginBottom ? 0 : '1rem'),
    cursor: 'pointer',
  },
  collapseHeader: { marginLeft: 10 },
});

const SectionWithCollapse = ({
  header,
  useCollapse = false,
  children,
  withoutMarginBottom = false,
}: PropsWithChildren<{ header: string | ReactNode; useCollapse: boolean; withoutMarginBottom?: boolean }>) => {
  const classes = useStyles({ withoutMarginBottom });
  const [isOpen, setIsOpen] = useState(true);
  const onToggle = () => setIsOpen(!isOpen);

  return (
    <Section>
      {useCollapse ? (
        <div className={classes.holder} onClick={onToggle}>
          <ExpandCollapseIcon isOpen={isOpen} grey />
          {isValidElement(header) ? (
            <div className={classes.collapseHeader}>{header}</div>
          ) : (
            <h4 className={classes.collapseHeader} style={{ lineHeight: '1.3rem' }}>
              {header}
            </h4>
          )}
        </div>
      ) : isValidElement(header) ? (
        header
      ) : (
        <h4 style={withoutMarginBottom ? { marginBottom: 0 } : { lineHeight: '1.3rem' }}>{header}</h4>
      )}
      {isOpen && children}
    </Section>
  );
};

export default SectionWithCollapse;
