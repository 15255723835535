import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Icon, IconButton, Popover, Whisper } from 'rsuite';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import {
  defaultWhisperProps,
  memberRoles,
  regularMemberRole,
  solutionProviderMemberRole,
  wfaStaffRoles,
  wfaAdminRole,
} from '../../../../helpers/constants';
import { IconButtonProps } from 'rsuite/lib/IconButton';
import { apiBaseUrl } from '../../../../config';
import { RootReducer } from '../../../../redux/rootReducer';
import { User } from '../../../../redux/modules/user/types';
import { AppFlows } from '../../../../redux/modules/application-flow';
import { confirmAction } from '../../../../helpers';
import { MaterialIconButton } from '../../../../shared-components/button/MaterialIconButton';
import { showRevokeModal } from '../../../../redux/modules/modals';
import { RevokeActionTypes } from '../../../../redux/modules/modals/constants';
interface Props {
  productId: number;
  authority: string;
  companyId: number;
  variantId: number;
  privateCertToken: string;
  showViewBtn?: boolean;
  isEdit?: boolean;
  onSave?: () => void;
  isFlowPage?: boolean;
  showWarning?: boolean;
  publicVariant: boolean;
  certified?: boolean | null;
  isRetirementCertStatus?: boolean;
  canBeUsedAsSource?: boolean;
  cid: string;
  totalVariants: number;
}

const ActionComponent = ({
  productId,
  history,
  variantId,
  companyId,
  privateCertToken,
  showViewBtn = false,
  isEdit = false,
  isFlowPage = false,
  showWarning = false,
  publicVariant,
  isRetirementCertStatus,
  onSave,
  certified,
  canBeUsedAsSource = false,
  cid,
  totalVariants,
}: Props & RouteComponentProps<{}>): React.ReactElement<string> => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { authority, company } = useSelector<RootReducer, User>(state => state.userReducer.user as User);

  const onView = () => window.open(`#/product/${productId}?variantId=${variantId}`);

  const conformationRequired = () => isFlowPage && showWarning;

  const onCreateDerivative = () =>
    confirmAction(conformationRequired, () =>
      history.push(
        `/application/new?companyId=${companyId}&sourceProductId=${productId}&variantId=${variantId}&type=${AppFlows.DERIVATIVE}`,
      ),
    );

  const onCreateVariant = () =>
    confirmAction(conformationRequired, () =>
      history.push(
        `/application/new?companyId=${companyId}&sourceProductId=${productId}&variantId=${variantId}&type=${AppFlows.VARIANT}`,
      ),
    );

  const onHistory = () => window.open(`#/product/${productId}/history`);

  const iconBtnProps: IconButtonProps = isFlowPage ? { className: 'ml-1', size: 'lg' } : { appearance: 'link' };

  const handleRevokeApp = () => {
    dispatch(
      showRevokeModal(
        {
          cid: cid,
          productId: productId,
          totalVariants: totalVariants,
        },
        RevokeActionTypes.REVOKE_PRODUCT,
      ),
    );
  };

  const canCreateVariant = [regularMemberRole, solutionProviderMemberRole].includes(authority);

  return (
    <Fragment>
      <span className={isFlowPage ? 'product-actions' : 'actions'}>
        {isEdit ? (
          <Button
            appearance="primary"
            onClick={() => {
              onSave && onSave();
            }}
            className="save"
          >
            {t('common.navigation.saveAndExit')}
          </Button>
        ) : null}
        {showViewBtn ? <IconButton onClick={onView} icon={<Icon icon="eye" />} appearance="link" /> : null}
        <Whisper
          {...defaultWhisperProps}
          speaker={
            <Popover full>
              <Dropdown.Menu className="action-icons">
                {memberRoles.includes(authority) ? (
                  <>
                    <Dropdown.Item onClick={onCreateVariant} disabled={!canCreateVariant}>
                      <IconButton icon={<Icon icon="plus" />} appearance="link">
                        {t('common.actions.createVariant')}
                      </IconButton>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={onCreateDerivative}
                      disabled={
                        (!certified || !company.affiliates.includes(companyId) || isRetirementCertStatus) &&
                        !canBeUsedAsSource
                      }
                    >
                      <IconButton icon={<Icon icon="plus" />} appearance="link">
                        {t('common.actions.createDerivative')}
                      </IconButton>
                    </Dropdown.Item>
                  </>
                ) : null}
                {publicVariant && (
                  <Dropdown.Item
                    disabled={!certified}
                    componentClass="a"
                    href={`${apiBaseUrl}/certificate/download/public?variantId=${variantId}`}
                  >
                    <IconButton icon={<Icon icon="eye" />} appearance="link">
                      {t('common.actions.viewCertificate', { type: t('products.certificateTypes.public') })}
                    </IconButton>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  componentClass="a"
                  disabled={!certified}
                  href={`${apiBaseUrl}/certificate/download?variantId=${variantId}&token=${privateCertToken}`}
                >
                  <IconButton icon={<Icon icon="eye" />} appearance="link">
                    {t('common.actions.viewCertificate', { type: t('products.certificateTypes.private') })}
                  </IconButton>
                </Dropdown.Item>
                {wfaStaffRoles.includes(authority) && (
                  <>
                    <Dropdown.Item disabled>
                      <IconButton icon={<Icon icon="flag" />}>{t('common.actions.flagForAudit')}</IconButton>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onHistory}>
                      <IconButton icon={<Icon icon="history" />} appearance="link">
                        {t('applications.summary.history')}
                      </IconButton>
                    </Dropdown.Item>
                  </>
                )}
                {[wfaAdminRole].includes(authority) && (
                  <Dropdown.Item onClick={handleRevokeApp}>
                    <MaterialIconButton iconComponent={CancelOutlinedIcon}>
                      {t('applications.summary.revoke')}
                    </MaterialIconButton>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Popover>
          }
        >
          <IconButton {...iconBtnProps} icon={<Icon icon="more" className="icon pointer" size="2x" />} />
        </Whisper>
      </span>
    </Fragment>
  );
};

export const RightActions = withRouter(ActionComponent);
