import * as actionTypes from './actionTypes';
import { User } from './types';

export function loadUser() {
  return {
    type: actionTypes.LOAD_USER,
  };
}

export function userLoaded(data: null | User) {
  return {
    type: actionTypes.USER_LOADED,
    payload: data,
  };
}

export function logoutUser() {
  return {
    type: actionTypes.USER_LOGOUT,
  };
}

export function acceptGDPRPolicy(addToast: AddFn) {
  return {
    type: actionTypes.ACCEPT_GDPR,
    addToast,
  };
}
