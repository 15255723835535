import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoadCCCompany,
  LoadCCComponentProperties,
  LoadCCSourceProduct,
  LoadCCVariant,
} from '../../../../api/application/types';
import { Loading } from './LoadCCDialog';
import { CircularLoader } from '../../../../shared-components/loader/CircularLoader';

// @ts-ignore
import _var from '../../../../styles/_variables.scss';
import HierarchicalDropdown, {
  HierarchicalDropdownProps,
} from '../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
  item: {
    fontWeight: 'bold',
    borderRadius: '4px',
    fontSize: '0.8rem',
    padding: '2px 6px',
    marginLeft: '8px',
    cursor: 'pointer',
    backgroundColor: _var.igoPlumLighten,
    color: _var.primary,
  },
}));

interface Props {
  loading: Loading;
  companies: LoadCCCompany[];
  selectedCompany: LoadCCCompany | null;
  products: LoadCCSourceProduct[];
  selectedProduct: LoadCCSourceProduct | null;
  variants: LoadCCVariant[];
  selectedVariant: LoadCCVariant | null;
  handleChangeSelectedCompany: (company: LoadCCCompany | null) => void;
  handleChangeSelectedProduct: (product: LoadCCSourceProduct | null) => void;
  handleChangeSelectedVariants: (value: LoadCCVariant | null) => void;
}

// Return from object only keys === true
export const getTrueKeys = (options: LoadCCComponentProperties | undefined) => {
  const result: string[] = [];
  for (const key in options) {
    // @ts-ignore
    if (options[key]) {
      result.push(key);
    }
  }
  return result;
};

export const FindProductVariantInputs = ({
  loading,
  companies,
  selectedCompany,
  products,
  selectedProduct,
  variants,
  selectedVariant,
  handleChangeSelectedCompany,
  handleChangeSelectedProduct,
  handleChangeSelectedVariants,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onCompanySelect = (id: number) =>
    handleChangeSelectedCompany(companies.find(company => company.id === id) || null);

  const onProductSelect = (id: number) =>
    handleChangeSelectedProduct(products.find(product => product.id === id) || null);

  const onVariantSelect = (id: number) =>
    handleChangeSelectedVariants(variants.find(variant => variant.id === id) || null);

  const onCompanyClear = () => {
    handleChangeSelectedCompany(null);
    handleChangeSelectedProduct(null);
    handleChangeSelectedVariants(null);
  };

  const onProductClear = () => {
    handleChangeSelectedProduct(null);
    handleChangeSelectedVariants(null);
  };

  const dropdownData: HierarchicalDropdownProps[] = [
    {
      data: companies,
      value: selectedCompany?.id || null,
      labelKey: 'name',
      valueKey: 'id',
      placeholder: t('applications.cc.loadCC.chooseCompany'),
      onChange: onCompanySelect,
      onClean: onCompanyClear,
      name: 'company',
    },
    {
      data: products,
      value: selectedProduct?.id || null,
      labelKey: 'name',
      valueKey: 'id',
      placeholder: t('applications.cc.loadCC.chooseProduct'),
      onChange: onProductSelect,
      onClean: onProductClear,
      name: 'product',
      getOptionLabel: item => `${item.cid} - ${item.name}`,
      renderMenuItem: item => (
        <>
          {`${item.cid} - ${item.name}`}
          {getTrueKeys(item.componentProperties).map((option: string, idx) => (
            <span className={classes.item} key={idx}>
              {t(`applications.cc.loadCC.filterOptions.${option}`)}
            </span>
          ))}
        </>
      ),
    },
    {
      data: variants,
      value: selectedVariant?.id || null,
      labelKey: 'name',
      valueKey: 'id',
      placeholder: t('applications.cc.loadCC.chooseVariant'),
      onChange: onVariantSelect,
      onClean: () => handleChangeSelectedVariants(null),
      name: 'variant',
      renderMenuItem: item => (
        <>
          {item.name}
          {getTrueKeys(item.componentProperties).map((option: string, idx) => (
            <span className={classes.item} key={idx}>
              {t(`applications.cc.loadCC.filterOptions.${option}`)}
            </span>
          ))}
        </>
      ),
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      {Loading.NONE !== loading ? <CircularLoader backdrop content={t('common.placeholders.loadingData')} /> : null}
      <HierarchicalDropdown dropdownData={dropdownData} />
    </div>
  );
};
