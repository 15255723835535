import React, { Fragment } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import {
  DataTableHeadComponentProps,
  useExpandColumnStyles,
} from '../../../../shared-components/table/DataTableHeadComponent';
import { DataTableHeadCell, StyledTableHeadCell } from '../../../../shared-components/table/DataTableHeadCell';
import { DataTableColumnProps } from '../../../../shared-components/table/DataTableColumn';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  column: {
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '0',
    textAlign: 'center',
    verticalAlign: 'top',
  },
  subColumn: {
    fontSize: '14px',
    paddingBottom: '10px',
  },
  noBorder: {
    borderBottom: 0,
  },
}));

type TestingResultTableHeaderProps<T> = DataTableHeadComponentProps<T> & {
  labs: { id: number; name: string }[];
};

export function TestingResultTableHeader<T>(props: TestingResultTableHeaderProps<T>) {
  const { t } = useTranslation();
  const { sorting, settings, getSortHandler, labs } = props;
  const rest = { sorting, getSortHandler };
  const classes = useStyles();
  const expandClasses = useExpandColumnStyles();

  const getColumnSetting = (field: string) => settings.find(item => item.field === field) as DataTableColumnProps<T>;

  return (
    <TableHead>
      <TableRow>
        <StyledTableHeadCell rowSpan={2} className={expandClasses.expandColumn} />
        <DataTableHeadCell rowSpan={2} settings={getColumnSetting('name')} {...rest} />
        <DataTableHeadCell rowSpan={2} settings={getColumnSetting('roleName')} {...rest} />
        {labs.map(lab => (
          <StyledTableHeadCell
            key={`lab${lab.id}`}
            colSpan={2}
            className={classNames(classes.column, classes.noBorder)}
            style={{ minWidth: 280 }}
          >
            <span>{lab.name}</span>
          </StyledTableHeadCell>
        ))}
        <DataTableHeadCell
          rowSpan={2}
          settings={{ ...getColumnSetting('versionName'), textAlign: 'right' }}
          {...rest}
        />
      </TableRow>
      <TableRow className={'text-muted'}>
        {labs.map(lab => (
          <Fragment key={`labResult${lab.id}`}>
            <StyledTableHeadCell className={classNames(classes.column, classes.subColumn)} style={{ minWidth: 140 }}>
              <span>{t('applications.labs.conformance')}</span>
            </StyledTableHeadCell>
            <StyledTableHeadCell className={classNames(classes.column, classes.subColumn)} style={{ minWidth: 140 }}>
              <span>{t('applications.labs.interoperability')}</span>
            </StyledTableHeadCell>
          </Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}
