import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  GetSummaryWithFiltersOptions,
  IterableObject,
  StartSizePaginated,
  SummaryFiltersObject,
  SummaryTableWithoutTabProps,
  UseSummaryTableProps,
} from '../../helpers/types';
import { SortingOptions, SortType } from '../pages/test-case/types';
import { useToasts } from 'react-toast-notifications';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { handleRequestFail } from '../../helpers/request-fail-handler';
import { PageTemplate } from '../partials';
import { DefaultSummaryFilters } from '../partials/DefaultSummaryFilters';
import { useHistory, useLocation } from 'react-router';
import { mergeObjects, parseUrlParams, resetSummaryUrl, setUrlParams } from '../../helpers';
import DefaultFiltersButtons from '../pages/admin/transactions/components/DefaultFiltersButtons';
import { ApplicationTypes } from '../pages/admin/transactions/types';
import { AdvancedSummaryFilters } from '../partials/filters/AdvancedSummaryFilters';

type SummaryApiCall<T> = (
  page: number,
  sortingOptions?: SortingOptions,
  filters?: SummaryFiltersObject,
) => AxiosPromise<StartSizePaginated<T>>;

function getDefaultSortingParams(params: IterableObject<string>, defaultSorting: SortingOptions) {
  return params.sortColumn && params.sortType
    ? { sortColumn: params.sortColumn, sortType: params.sortType as SortType }
    : defaultSorting;
}

export function initSummaryFiltersObject(params: IterableObject<string>) {
  return {
    search: params.search || '',
    from: params.from || '',
    to: params.to || '',
    roleId: params.roleId ? params.roleId.split(',') : null,
    type: params.type ? params.type.split(',') : null,
    certificationId: params.certificationId || null,
    applicationType: params.applicationType in ApplicationTypes ? (params.applicationType as ApplicationTypes) : null,
    applicationTypeId: params.applicationTypeId ? params.applicationTypeId.split(',') : null,
    labId: params.labId ? params.labId.split(',') : null,
    owningCompanyId: params.owningCompanyId ? params.owningCompanyId.split(',') : null,
    searchable: params.searchable ? params.searchable.split(',') : null,
    available: params.available ? params.available.split(',') : null,
    canCreateDerivative: params.canCreateDerivative ? params.canCreateDerivative.split(',') : null,
    isCertified: params.isCertified ? params.isCertified.split(',') : null,
    availableAsQualifiedSolution: params.availableAsQualifiedSolution
      ? params.availableAsQualifiedSolution.split(',')
      : null,
    worstCertificationVersionStatus: params.worstCertificationVersionStatus
      ? params.worstCertificationVersionStatus.split(',')
      : null,
    decision: params.decision ? params.decision.split(',') : null,
    amount: params.amount ? params.amount.split(',') : null,
    requestingAccountId: params.requestingAccountId ? params.requestingAccountId.split(',') : null,
    applicationStatus: params.applicationStatus ? params.applicationStatus.split(',') : null,
    hasDraftCapability: params.hasDraftCapability ? params.hasDraftCapability.split(',') : null,
    capabilityId: null,
  };
}

export function useSummaryTable<T>(
  apiCall: SummaryApiCall<T>,
  defaultSorting: SortingOptions = { sortColumn: 'date', sortType: 'desc' },
): UseSummaryTableProps<T> {
  const history = useHistory();
  const location = useLocation();
  const params = parseUrlParams(location.search);
  const { addToast } = useToasts();

  const [sorting, setSorting] = useState<SortingOptions>(getDefaultSortingParams(params, defaultSorting));

  const [currentPage, setCurrentPage] = useState(params.page ? Number(params.page) : 1);

  const [{ total, tableData, loading }, setData] = useState<{ loading: boolean; total: number; tableData: T[] }>({
    loading: false,
    total: 0,
    tableData: [],
  });

  const [appliedFilters, setAppliedFilters] = useState<SummaryFiltersObject>(initSummaryFiltersObject(params));

  const getSummary = (options: GetSummaryWithFiltersOptions = {}) => {
    setData(prevState => mergeObjects(prevState, { loading: true }));
    const { sortingOptions = sorting, page = currentPage, filters = appliedFilters } = options;
    apiCall(page, sortingOptions, filters)
      .then((response: AxiosResponse<StartSizePaginated<T>>) => {
        const tableData = response.data.content.map(item => ({
          ...item,
          actions: {
            setLoading: (loading: boolean) => setData(prevState => mergeObjects(prevState, { loading })),
            getSummary,
          },
        }));
        setData({
          loading: false,
          total: response.data.totalHits,
          tableData,
        });
      })
      .catch((error: AxiosError) => {
        handleRequestFail(error, addToast);
        setData(prevState => mergeObjects(prevState, { tableData: [], loading: false }));
      });
  };

  useEffect(() => {
    const { search, pathname } = location;
    resetSummaryUrl(pathname.substring(1), pathname + search);
    getSummary();
  }, []);

  const setUrlParam = (paramsToSet: IterableObject<string | number | null>) => {
    const { pathname } = location;
    const search = setUrlParams(paramsToSet, location.search).toString();
    resetSummaryUrl(pathname.substring(1), `${pathname}?${search.toString()}`);
    history.push({
      pathname,
      search,
    });
  };

  const handleSortChange = (sortColumn: string, sortType: SortType) => {
    setCurrentPage(1);
    const sortingOptions = { sortColumn, sortType };
    setSorting(sortingOptions);
    setUrlParam({ sortColumn, sortType, page: 1 });
    return getSummary({ sortingOptions, page: 1 });
  };

  const handleApplyFilters = (filters: SummaryFiltersObject) => {
    setAppliedFilters(filters);
    setUrlParam({ ...filters, page: 1 });
    setCurrentPage(1);
    getSummary({ filters, page: 1 });
  };

  const handlePageChange = (page: number) => {
    setUrlParam({ page });
    setCurrentPage(page);
    return getSummary({ page });
  };

  return {
    data: tableData,
    total,
    loading,
    sorting,
    activePage: currentPage,
    filters: appliedFilters,
    setActivePage: handlePageChange,
    onChangeSorting: handleSortChange,
    onChangeFilters: handleApplyFilters,
    refreshSummary: getSummary,
  };
}

export function getSummaryWithoutTabPageComponent<T>(
  title: string,
  apiCall: SummaryApiCall<T>,
  SummaryTable: (props: SummaryTableWithoutTabProps<T>) => JSX.Element | null,
  pageRightAction: React.Component | React.ReactElement | null = null,
  defaultSorting: SortingOptions = { sortColumn: 'date', sortType: 'desc' },
  searchOnly = false,
  useCollapse = false,
  collapseOpen = false,
  useAppTypeFilter = false,
  useAdvancedFilters = false,
  isProductSummary = false,
  isTransactionSummary = false,
  isApplicationSummary = false,
) {
  const SummaryComponent = () => {
    const {
      loading,
      onChangeFilters,
      data,
      onChangeSorting,
      activePage,
      setActivePage,
      filters,
      sorting,
      total,
    } = useSummaryTable<T>(apiCall, defaultSorting);

    const [draftFilters, setDraftFilters] = useState(filters);
    const ref = useRef('');

    const onFiltersApply = (filters: SummaryFiltersObject) => {
      useAppTypeFilter ? setDraftFilters(filters) : onChangeFilters(filters);
    };

    const summaryFilters = useMemo(() => {
      return useAppTypeFilter ? { ...filters, ...draftFilters } : filters;
    }, [filters, draftFilters]);

    const updateFilters = (filters: SummaryFiltersObject) => {
      setDraftFilters(filters);
      onChangeFilters(filters);
    };

    useEffect(() => {
      if (useAppTypeFilter) {
        onChangeFilters(draftFilters);
      }
    }, [draftFilters.applicationType]);

    const onClearButtonClick = () => {
      updateFilters({
        certificationId: null,
        from: '',
        roleId: null,
        search: '',
        to: '',
        type: null,
        applicationType: null,
        applicationTypeId: null,
        labId: null,
        searchable: null,
        available: null,
        canCreateDerivative: null,
        owningCompanyId: null,
        isCertified: null,
        availableAsQualifiedSolution: null,
        worstCertificationVersionStatus: null,
        decision: null,
        amount: null,
        requestingAccountId: null,
        applicationStatus: null,
        hasDraftCapability: null,
        capabilityId: null,
      });
    };

    const onApplyButtonClick = () => {
      updateFilters({ ...draftFilters, search: ref.current });
    };

    useEffect(() => {
      if (useAppTypeFilter) {
        ref.current = draftFilters.search;
      }
    }, [draftFilters.search]);

    return (
      <PageTemplate
        title={title}
        actionRight={pageRightAction}
        filters={
          useAdvancedFilters ? null : (
            <DefaultSummaryFilters
              appliedFilters={summaryFilters}
              onFiltersApply={onFiltersApply}
              style={{ marginRight: Boolean(pageRightAction) ? '1rem' : 0 }}
              hideTimeFilter={searchOnly}
              hideAppTypeFilter={!useAppTypeFilter}
              updateFilters={updateFilters}
              searchRef={ref}
            />
          )
        }
        filterButtons={<DefaultFiltersButtons onApply={onApplyButtonClick} onClear={onClearButtonClick} />}
        noFooter
        useCollapse={useCollapse}
        collapseOpen={collapseOpen}
      >
        {useAdvancedFilters ? (
          <AdvancedSummaryFilters
            appliedFilters={summaryFilters}
            onFiltersApply={onFiltersApply}
            isApplicationSummary={isApplicationSummary}
            isProductSummary={isProductSummary}
            isTransactionSummary={isTransactionSummary}
          />
        ) : null}
        <SummaryTable
          data={data}
          loading={loading}
          total={total}
          activePage={activePage}
          setActivePage={setActivePage}
          sorting={sorting}
          onChangeSorting={onChangeSorting}
        />
      </PageTemplate>
    );
  };
  return React.memo(SummaryComponent);
}

export function getSummaryWithoutTabPageComponentByObject<T>(summaryPageComponentObject: {
  title: string;
  apiCall: SummaryApiCall<T>;
  SummaryTable: (props: SummaryTableWithoutTabProps<T>) => JSX.Element | null;
  pageRightAction?: React.Component | React.ReactElement | null;
  defaultSorting: SortingOptions;
  searchOnly?: boolean;
  useCollapse?: boolean;
  collapseOpen?: boolean;
  useAppTypeFilter?: boolean;
  useAdvancedFilters?: boolean;
  isApplicationSummary?: boolean;
  isProductSummary?: boolean;
  isTransactionSummary?: boolean;
}) {
  const {
    title,
    apiCall,
    SummaryTable,
    pageRightAction = null,
    defaultSorting = { sortColumn: 'date', sortType: 'desc' },
    searchOnly = false,
    useCollapse = false,
    collapseOpen = false,
    useAppTypeFilter = false,
    useAdvancedFilters = false,
    isApplicationSummary = false,
    isProductSummary = false,
    isTransactionSummary = false,
  } = summaryPageComponentObject;
  return getSummaryWithoutTabPageComponent(
    title,
    apiCall,
    SummaryTable,
    pageRightAction,
    defaultSorting,
    searchOnly,
    useCollapse,
    collapseOpen,
    useAppTypeFilter,
    useAdvancedFilters,
    isProductSummary,
    isTransactionSummary,
    isApplicationSummary,
  );
}
