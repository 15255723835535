import React from 'react';
import { useTranslation } from 'react-i18next';

import { LabRequest, LRCertification } from '../types';
import { CCDetails } from '../../application/partials/cc-details/CCDetails';
import { ResultsTable, IconsLegend, TestResultTable } from './';
import { UploadedFile } from '../../../../shared-components/file-upload/types';
import { ExceptionsPanel } from '../../../partials/ExceptionsPanel';
import { ExceptionTypes } from '../../../../components/pages/application/types';

interface Props {
  labRequest: LabRequest;
  certifications: LRCertification[];
}

export const ReviewResultsStep = ({ labRequest, certifications }: Props) => {
  const { t } = useTranslation();
  const { id } = labRequest;
  const conformanceTableData =
    labRequest.conformanceTestResult !== null
      ? [
          {
            conformanceResult: labRequest.conformanceTestResult,
            conformanceReportToken: labRequest.conformanceTestResult.conformanceReportToken,
            fileToDownload: labRequest.conformanceTestResult,
            lrId: id,
          },
        ]
      : [];

  const interoperabilityTableData = labRequest.interoperabilityTestTemplateResults
    .filter(item => item.result !== null)
    .map(item => ({ fileToDownload: item.result as UploadedFile, lrId: id }));
  return (
    <>
      <h4>{t('testing.uploadResults.testResultTitle', { type: t('applications.labs.conformance') })}</h4>
      <ResultsTable data={conformanceTableData} isConformanceResults />
      <h4>{t('testing.uploadResults.testResultTitle', { type: t('applications.labs.interoperability') })}</h4>
      <ResultsTable data={interoperabilityTableData} />
      <h4>{t('testing.uploadResults.resultTitle')}</h4>
      <TestResultTable certifications={certifications} />
      <IconsLegend />
      {labRequest.testingException ? (
        <>
          <h4 className="mt-1">{t('testing.review.exceptionsRequested')}</h4>
          <ExceptionsPanel data={[{ exceptionType: ExceptionTypes.LAB_EXCEPTION, ...labRequest.testingException }]} />
        </>
      ) : null}
      <h4 className="my-1">{t('applications.cc.wifiComponent')}</h4>
      <CCDetails
        cc={labRequest.application.componentCombination.componentCombination}
        ccVariantSource={labRequest.application.componentCombination.componentCombinationVariantSource}
      />
      <h4>{t('testing.uploadResults.additionalAttachments')}</h4>
      <ResultsTable
        data={labRequest.attachments.map(item => ({ fileToDownload: item, lrId: labRequest.id }))}
        isAttachments
      />
    </>
  );
};
