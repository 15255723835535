import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'rsuite';
import { RowWithMargin } from '../../application/partials';
import { BasicApplicationInfoRecord, CategoryItem } from '../../application/types';

import { buildDownloadUrl } from '../../../../helpers/build-download-url';
import { AppFlows } from '../../../../redux/modules/application-flow';
import { ImagePreview } from '../../../../shared-components/helper-components/ImagePreview';
import { DetailsItem } from '../../../partials';
import { FileItem } from '../../../partials/FileItem';
import SectionWithCollapse from '../../../partials/SectionWithCollapse';
import { VariantInfoTitle } from '../../../partials/VariantInfoTitle';
import { isSolutionProvider } from '../../staff/partials/helpers';

export const ProductInfo = ({
  application,
  withVariantInfo = true,
  useCollapse = false,
}: {
  application: BasicApplicationInfoRecord;
  withVariantInfo?: boolean;
  useCollapse?: boolean;
}) => {
  const { t } = useTranslation();

  const isVariant = application['@type'] === AppFlows.VARIANT;

  const isQualifying = application.productInfo.certified === false;

  const isQualifiedSolution = application.productInfo.qualifiedSolution;

  const isUseDraftCertified = isQualifiedSolution && isVariant && isQualifying;

  const showQualifiedSolutionFields = isSolutionProvider(application) && application['@type'] !== AppFlows.DERIVATIVE;

  return (
    <>
      <SectionWithCollapse header={t('applications.steps.1')} useCollapse={useCollapse}>
        {showQualifiedSolutionFields && (
          <RowWithMargin gutter={20}>
            <Col xs={12}>
              <DetailsItem
                label={t('applications.info.isQualifiedSolution')}
                value={
                  (application.productInfo.qualifiedSolution
                    ? t('common.options.yes')
                    : t('common.options.no')) as string
                }
              />
            </Col>
            <Col xs={12}>
              {isUseDraftCertified &&
              application.productInfo.modelVariants[0].draftCertified !== application.productInfo.certified ? (
                <DetailsItem
                  label={t('applications.info.isWiFiCertified')}
                  value={
                    <span className="core-change-text-different">
                      {
                        (application.productInfo.modelVariants[0].draftCertified
                          ? t('common.options.yes')
                          : t('common.options.no')) as string
                      }
                    </span>
                  }
                />
              ) : (
                <DetailsItem
                  label={t('applications.info.isWiFiCertified')}
                  value={
                    ((isUseDraftCertified
                    ? application.productInfo.modelVariants[0].draftCertified
                    : application.productInfo.certified)
                      ? t('common.options.yes')
                      : t('common.options.no')) as string
                  }
                />
              )}
            </Col>
          </RowWithMargin>
        )}
        <RowWithMargin gutter={20}>
          <Col xs={12}>
            <DetailsItem label={t('applications.info.cid')} value={application.productInfo.cid} />
          </Col>
          <Col xs={12}>
            <DetailsItem
              label={t('applications.summary.company')}
              value={application.companyContactInfo?.owningCompany?.name}
            />
          </Col>
        </RowWithMargin>
        <RowWithMargin gutter={20}>
          <Col xs={12}>
            <DetailsItem label={t('applications.info.name')} value={application.productInfo.name} />
          </Col>
          <Col xs={12}>
            <DetailsItem label={t('applications.info.modelNumber')} value={application.productInfo.modelNumber} />
          </Col>
        </RowWithMargin>
        <RowWithMargin gutter={20}>
          <Col xs={12}>
            <DetailsItem
              label={t('applications.info.primaryCategory')}
              value={(application.productInfo.primaryCategory as CategoryItem)?.name}
            />
          </Col>
          <Col xs={12}>
            <DetailsItem
              label={t('applications.info.additionalCategory')}
              value={(application.productInfo.additionalCategories as CategoryItem[]).map(item => item.name).join(', ')}
            />
          </Col>
        </RowWithMargin>
        <DetailsItem className="mb-1" label={t('applications.info.url')}>
          {application.productInfo.url ? (
            <a href={application.productInfo.url} target="_blank" rel="noopener noreferrer">
              {application.productInfo.url}
            </a>
          ) : null}
        </DetailsItem>
        <DetailsItem
          className="mb-1"
          label={t('applications.info.description')}
          value={application.productInfo.description}
        />
        <RowWithMargin gutter={20}>
          <Col xs={12}>
            <DetailsItem label={t('applications.info.image')}>
              {application.productInfo.image ? (
                <div style={{ display: 'flex', alignItems: 'top', wordBreak: 'break-all' }}>
                  <ImagePreview src={buildDownloadUrl(application.productInfo.image)} className="mr-1 ml-0" />
                </div>
              ) : null}
            </DetailsItem>
          </Col>
        </RowWithMargin>
      </SectionWithCollapse>
      {withVariantInfo ? (
        <SectionWithCollapse
          header={
            <VariantInfoTitle productType={application.productInfo.modelVariants[0].productType} withMargin={false} />
          }
          useCollapse={useCollapse}
        >
          <RowWithMargin gutter={20}>
            <Col xs={12}>
              <DetailsItem
                label={t('applications.info.variantName')}
                value={application.productInfo.modelVariants[0].name}
              />
            </Col>
            <Col xs={12}>
              <DetailsItem label={t('applications.summary.appId')} value={application.id} />
            </Col>
          </RowWithMargin>
          <RowWithMargin gutter={20}>
            <Col xs={12}>
              <DetailsItem
                label={t('applications.info.searchable')}
                value={
                  (application.productInfo.modelVariants[0].searchableByPublic
                    ? t('common.options.yes')
                    : t('common.options.no')) as string
                }
              />
            </Col>
            <Col xs={12}>
              <DetailsItem
                label={t('applications.info.available')}
                value={
                  (application.productInfo.modelVariants[0].availableAsDerivative
                    ? t('common.options.yes')
                    : t('common.options.no')) as string
                }
              />
            </Col>
          </RowWithMargin>
          {showQualifiedSolutionFields && (
            <RowWithMargin gutter={20}>
              <Col xs={12} />
              <Col xs={12}>
                <DetailsItem
                  label={t('applications.info.availableAsQualifiedSolution')}
                  value={
                    (application.productInfo.modelVariants[0].availableAsQualifiedSolution
                      ? t('common.options.yes')
                      : t('common.options.no')) as string
                  }
                />
              </Col>
            </RowWithMargin>
          )}
          <RowWithMargin gutter={20}>
            <Col xs={12}>
              <DetailsItem label={t('applications.info.docs')}>
                {application.productInfo.documents.length
                  ? application.productInfo.documents.map(file => <FileItem file={file} key={file.id} />)
                  : null}
              </DetailsItem>
            </Col>
          </RowWithMargin>
        </SectionWithCollapse>
      ) : null}
    </>
  );
};
