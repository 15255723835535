import React from 'react';
import { SummaryTemplate } from '../../../partials/summary-template/SummaryTemplate';
import { SingleProductRecord } from '../types';
import { useTranslation } from 'react-i18next';
import { GroupedCertifications } from '../../../partials/summary-template/GroupedCertifications';
import { ApplicationTypeDetails } from '../../../partials/summary-template/ApplicationTypeDetails';
import { SummaryItem } from '../../../partials/summary-template/SummaryTemplate';
import { LabelType } from '../../../../shared-components/label-type/LabelType';
import { productTypesTitles, productTypes } from '../../../../helpers/constants/product-types';
import { LabelTypes } from '../../../../helpers/constants/label-types';
import { AppFlows } from '../../../../redux/modules/application-flow';

export const ProductSummarySidebar = ({ applicationRecord }: { applicationRecord: SingleProductRecord }) => {
  const { t } = useTranslation();
  const wrapWithLink = applicationRecord.variant.application.type === AppFlows.VARIANT;
  return (
    <SummaryTemplate title={t('applications.summary.sideBar.heading.certifications')}>
      {(applicationRecord.variant && applicationRecord.variant.productType) ||
      applicationRecord.cid ||
      applicationRecord.qualifiedSolution ? (
        <SummaryItem title={t('applications.summary.sideBar.applicationId')}>
          {applicationRecord.cid}
          {applicationRecord.qualifiedSolution ? (
            <LabelType
              labelTypes={LabelTypes.SUMMARY_LABEL}
              label={productTypesTitles[productTypes.qualifiedSolution]}
            />
          ) : (
            <>
              {applicationRecord.variant && applicationRecord.variant.productType ? (
                <LabelType
                  labelTypes={LabelTypes.SUMMARY_LABEL}
                  label={productTypesTitles[applicationRecord.variant.productType]}
                />
              ) : null}
            </>
          )}
        </SummaryItem>
      ) : null}
      <></>
      {applicationRecord.variant && applicationRecord.variant.application.type ? (
        <SummaryItem title={t('applications.summary.sideBar.applicationType')}>
          <ApplicationTypeDetails
            applicationType={applicationRecord.variant.application.type}
            companyContactInfo={applicationRecord.variant?.companyContactInfo}
            productInfo={{
              id: applicationRecord.id,
              cid: applicationRecord.cid,
            }}
            wrapWithLink={wrapWithLink}
          />
        </SummaryItem>
      ) : null}
      {applicationRecord.qualifiedSolution && applicationRecord.certified !== null ? (
        <SummaryItem title={t('applications.summary.sideBar.wifiCertified')}>
          <LabelType labelTypes={LabelTypes.BLOCK_LABEL} label={applicationRecord.certified ? 'Yes' : 'No'} />
        </SummaryItem>
      ) : null}
      {applicationRecord.variant.componentCombination.componentCombinationVariantSource &&
      applicationRecord.variant.componentCombination.componentCombinationVariantSource.productType ? (
        <SummaryItem title={t('applications.summary.sideBar.componentLoadedFrom')}>
          <LabelType
            labelTypes={LabelTypes.SUMMARY_LABEL}
            label={
              productTypesTitles[
                applicationRecord.variant.componentCombination.componentCombinationVariantSource.productType
              ]
            }
          />
        </SummaryItem>
      ) : null}
      {applicationRecord.variant?.certifications !== undefined ? (
        <GroupedCertifications
          certifications={applicationRecord.variant?.certifications.map(cert => ({
            id: cert.id,
            name: cert.name,
            roleName: cert.role?.name,
            capabilities: cert.capabilities,
            status: cert.version.status,
          }))}
        />
      ) : null}
    </SummaryTemplate>
  );
};
