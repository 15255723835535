import { SortingOptions } from '../components/pages/test-case/types';
import dot from 'dot-object';

export function sortByProperty<T>(
  a: T,
  b: T,
  sorting: SortingOptions,
  numeric = false,
  additionalSorting: SortingOptions | null = null,
): number {
  const dottedA = dot.dot(a);
  const dottedB = dot.dot(b);
  const compare = String(dottedA[sorting.sortColumn as keyof T]).localeCompare(
    String(dottedB[sorting.sortColumn as keyof T]),
    undefined,
    { numeric },
  );
  if (compare === 0) {
    return additionalSorting ? sortByProperty(a, b, additionalSorting) : 0;
  }
  return sorting.sortType === 'desc' ? -compare : compare;
}
