/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import useAxios from 'axios-hooks';
import uniqid from 'uniqid';
import { AutoComplete, AutoCompleteProps } from 'rsuite';

import { withLabel, WrappedInputProps } from '../labeled-inputs/withLabel';
import { ErrorObject } from '../../helpers/types';
import { extractError } from '../labeled-inputs/utils';
import { CircularLoader } from '../loader/CircularLoader';

interface RsAutoCompleteProps extends AutoCompleteProps {
  url: string;
  searchParam?: string;
  transformData?: (data: any[]) => { value: string; label: string }[];
  onFocus?: (event: any) => void;
}

interface LabeledFieldProps extends RsAutoCompleteProps {
  errors?: ErrorObject;
  loading: boolean;
  data: any[];
  searchTerm: string;
}

const loaderKey = `${uniqid()}-show-loader`;

export const RsAutoComplete = ({
  transformData,
  onChange,
  renderItem,
  searchTerm,
  loading = false,
  data,
  ...props
}: LabeledFieldProps) => {
  const transformedData = loading ? [searchTerm + loaderKey] : transformData ? transformData(data) : data;

  return (
    <AutoComplete
      data={transformedData}
      onChange={(value: string, event: SyntheticEvent<Element, Event>) => {
        if (!value.includes(loaderKey)) {
          onChange && onChange(value, event);
        }
      }}
      {...props}
      renderItem={item =>
        item.value.includes(loaderKey) ? (
          <div style={{ padding: '0.7rem 3rem' }}>
            <CircularLoader content="Loading..." />
          </div>
        ) : renderItem ? (
          renderItem(item)
        ) : (
          item.value
        )
      }
    />
  );
};

const MemorizedField = React.memo(withLabel<LabeledFieldProps>(RsAutoComplete), (prevProps, nextProps) => {
  const isDataSame = JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  return (
    isDataSame &&
    prevProps.loading === nextProps.loading &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.value === nextProps.value &&
    prevProps.defauldValue === nextProps.defauldValue &&
    prevProps.error === nextProps.error
  );
});

export const LabeledInlineAutoComplete = ({
  errors,
  name,
  searchParam = 'search',
  url,
  onChange,
  ...props
}: WrappedInputProps<RsAutoCompleteProps>) => {
  const [searchTerm, setSearchTerm] = useState('');
  const savedTimeout = useRef<number>(null);
  const [{ data, loading }, executeFetchItems] = useAxios(url, { manual: true });
  useEffect(() => {
    return () => {
      if (savedTimeout.current) {
        clearTimeout(savedTimeout.current);
        // @ts-ignore
        savedTimeout.current = null;
      }
    };
  }, []);

  const handleChange = (value: string, event: SyntheticEvent<Element, Event>) => {
    setSearchTerm(value);
    onChange && onChange(value, event);
    if (savedTimeout.current) {
      clearTimeout(savedTimeout.current);
    }
    // @ts-ignore
    savedTimeout.current = setTimeout(() => {
      if (savedTimeout.current) {
        clearTimeout(savedTimeout.current);
        // @ts-ignore
        savedTimeout.current = null;
      }
      executeFetchItems({ params: { [searchParam]: value } });
    }, 400);
  };

  return (
    <MemorizedField
      name={name}
      error={extractError(errors, name)}
      searchTerm={searchTerm}
      onChange={handleChange}
      data={data}
      loading={loading}
      {...props}
    />
  );
};
