import React from 'react';

import { withStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { Icon } from 'rsuite';
import Box from '@material-ui/core/Box';

const StyledBox = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:hover': {
      color: '#976083',
    },
  },
}))(Box);

export const SectionEditButton = () => {
  const { t } = useTranslation();

  return (
    <StyledBox component="span">
      <Icon icon="edit" />
      <span style={{ marginLeft: 8 }}>{t('common.actions.edit')}</span>
    </StyledBox>
  );
};
