import React from 'react';
import styled from 'styled-components';

// @ts-ignore
import _var from '../../styles/_variables.scss';

const PreviouslyCertifiedLegend = styled.div`
  margin: 10px 0;
  display: flex;
  .mark {
    background-color: ${_var.lightGray};
    padding: 0.1rem 0.8rem;
    margin-right: 0.4rem;
    border-top: 1px solid ${_var.disabledGray};
    border-bottom: 1px solid ${_var.disabledGray};
    max-height: calc(1rem + 5px);
  }
`;

const WarningTitle = styled.p`
  color: ${_var.errorRed};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

interface PreviouslyCertifiedLegendProps {
  title: string;
  warningTitle?: string | null;
}

export const PreviouslyCertifiedLegendTitles: React.FC<PreviouslyCertifiedLegendProps> = ({ title, warningTitle }) => {
  return (
    <PreviouslyCertifiedLegend>
      <span className="mark" />
      :&nbsp;
      <div>
        {title}
        {!!warningTitle ? <WarningTitle>{warningTitle}</WarningTitle> : null}
      </div>
    </PreviouslyCertifiedLegend>
  );
};

export default PreviouslyCertifiedLegend;
