import React from 'react';
import { LRSummaryStatuses } from '../../../../helpers/constants';
import { useTranslation } from 'react-i18next';
import { LRSummaryRecord } from '../types';
import { reformatDate } from '../../../../helpers';
import { ActionsColumn } from './ActionsColumn';
import { SummaryTableProps } from '../../../../helpers/types';
import { ApplicationActions } from '../../application/types';
import DataTable from '../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../shared-components/table/DataTableColumn';

export const SummaryTable = (props: SummaryTableProps<LRSummaryRecord>): React.ReactElement<string> => {
  const { data, loading, onChangeSorting, sorting, status, total, activePage, setActivePage } = props;
  const { t } = useTranslation();

  const getActionsWidth = () => {
    switch (status as LRSummaryStatuses) {
      case LRSummaryStatuses.NEW:
        return 230;
      case LRSummaryStatuses.IN_TESTING:
      case LRSummaryStatuses.RETURNED:
        return 170;
      default:
        return 100;
    }
  };

  const dateLabel = t(
    status === LRSummaryStatuses.APPROVED ? 'applications.summary.dates.APPROVED' : 'testing.summary.requestedAt',
  );

  return (
    <DataTable<LRSummaryRecord>
      sticky
      pagination
      data={data}
      total={total}
      sorting={sorting}
      loading={loading}
      activePage={activePage}
      setActivePage={setActivePage}
      onChangeSorting={onChangeSorting}
      useEnvIdentifier={true}
    >
      <DataTableColumn field="cid" label={t('testing.summary.cid')} minWidth={110} sortable />
      <DataTableColumn field="appId" label={t('testing.summary.appId')} minWidth={100} sortable />
      <DataTableColumn
        field="productName"
        label={t('testing.summary.name')}
        minWidth={100}
        width="30%"
        sortable
        wordWrap
      />
      <DataTableColumn
        field="owningCompanyName"
        label={t('testing.summary.companyName')}
        minWidth={100}
        width="20%"
        sortable
        wordWrap
      />
      <DataTableColumn
        field="certificationsLabel"
        label={t('testing.summary.certification')}
        width={350}
        sortable
        fixed
      >
        {(row: LRSummaryRecord) => row.certifications.map(cert => cert.name).join(', ')}
      </DataTableColumn>
      {status === LRSummaryStatuses.APPROVED && (
        <DataTableColumn field="decision" label={t('applications.summary.decision')} minWidth={120} />
      )}
      <DataTableColumn field="date" label={dateLabel} minWidth={170} sortable>
        {(row: LRSummaryRecord) => reformatDate(row.date)}
      </DataTableColumn>
      <DataTableColumn field="actions" label={t('common.actions.title')} minWidth={getActionsWidth()}>
        {(row: LRSummaryRecord & { actions: ApplicationActions }) => <ActionsColumn record={row} />}
      </DataTableColumn>
    </DataTable>
  );
};
