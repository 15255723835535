import React, { PropsWithChildren } from 'react';
import { FormGroup } from '@material-ui/core';
import { ControlLabel } from 'rsuite';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  detailsItem: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
    flexDirection: 'row',
  },
  label: {
    width: 200,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  value: {
    width: 200,
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

const TransactionDetailsItem = ({ label, children, value }: PropsWithChildren<{ label: string; value?: string }>) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormGroup className={classes.detailsItem}>
      <ControlLabel className={classes.label}>{label}</ControlLabel>
      <div className={classes.value}>
        {children || value || <span className="text-muted">{t('common.placeholders.noDataSpecified')}</span>}
      </div>
    </FormGroup>
  );
};

export default TransactionDetailsItem;
