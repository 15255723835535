import axios from '../../../helpers/axios-setup';
import { ComponentCombination } from '../types';
import { ComponentCombinationSource } from '../../../redux/modules/application-flow';

export const saveCC = (
  appId: number,
  componentCombination: ComponentCombination,
  componentCombinationVariantSourceId: number | null,
) =>
  axios.patch<ComponentCombination>(`/applications/builder/cc/${appId}`, {
    '@type': componentCombinationVariantSourceId ? ComponentCombinationSource.LOADED : ComponentCombinationSource.BASE,
    componentCombination,
    componentCombinationVariantSource: {
      id: componentCombinationVariantSourceId,
    },
  });
