import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FlexboxGrid } from 'rsuite';

import {
  CapabilitiesStep,
  CertificationsStep,
  PaymentStep,
  ProductModelStep,
  ReviewStep,
  TestingLabsStep,
  WiFiComponentsStep,
} from './partials/new-application-flow-steps';
import { StepPageProps } from './types';
import { ApplicationSummarySideBar, NewApplicationSteps } from './partials';
import { PageTemplate } from '../../partials';
import {
  AppFlows,
  ApplicationFlowState,
  setAutoValidateCapability,
  setCompareCCInfo,
} from '../../../redux/modules/application-flow';
import { RootReducer } from '../../../redux/rootReducer';
import { confirmAction, getStoredSummaryUrl } from '../../../helpers';
import { Confirm } from '../../../helpers/confirmationPopup/Confirm';
import { selectMissingRoles } from '../../../redux/modules/application-flow/selectors';
import { IsModifiedProps, usePageChangeDetection } from '../../../helpers/hooks/useChangeDetection';
import { ComponentCombination, ProductInfoStep } from '../../../api/application/types';
import { getVariantCCById } from '../../../api/application/load-cc';
import { isSomeCapabilityValidateError } from './partials/new-application-flow-steps/partials/capabilities-step/helpers';
import classNames from 'classnames';
import { BackToListButton } from '../../../shared-components/button/BackToListButton';

export const PageComponent = ({
  history,
  step,
  saveData,
  handleSetInitialValues,
  onStepChange,
  onPrevious,
  onNext,
  submitting,
  saving,
  isModified,
  setIsModified,
  initialProductInfo,
  initialComponentCombination,
  stepTitles,
}: RouteComponentProps<{ action: 'new' | 'edit' | 'derivative' }> & {
  step: number;
  stepTitles: Array<string>;
} & StepPageProps &
  IsModifiedProps & {
    initialProductInfo?: ProductInfoStep;
    initialComponentCombination?: ComponentCombination;
  }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const flowValues: ApplicationFlowState = useSelector<RootReducer, ApplicationFlowState>(
    state => state.applicationFlow,
  );
  const missingRoles = useSelector(selectMissingRoles);
  const [modelAttachmentLoading, setModelAttachmentLoading] = useState(false);
  const [exceptionAttachmentLoading, setExceptionAttachmentLoading] = useState(false);
  const [estimatedFeeLoading, setEstimatedFeeLoading] = useState(false);
  const [appImageError, setAppImageError] = useState(false);
  const [canProceedWithSelection, setCanProceedWithSelection] = useState(true);
  const [isCapabilitesMissingInfo, setIsCapabilitesMissingInfo] = useState(false);

  const { resetChangeDetection } = usePageChangeDetection(value => JSON.stringify(value), isModified);

  const { certifications, capabilitisByCC, autoValidateCapability } = flowValues;

  const hasCertificationsSelected = certifications.length > 0;

  const isNewProductType = flowValues['@type'] === AppFlows.NEW;

  useEffect(() => {
    if (isNewProductType && flowValues.componentCombination.componentCombinationVariantSource?.id) {
      getVariantCCById(flowValues.componentCombination.componentCombinationVariantSource.id).then(
        ({ data }: AxiosResponse<ComponentCombination>) => {
          dispatch(setCompareCCInfo({ initialCC: data as ComponentCombination }));
        },
      );
    }
  }, []);

  const onBackToList = () =>
    confirmAction(
      () => !!isModified,
      () => {
        resetChangeDetection();
        history.push(getStoredSummaryUrl('application'));
      },
    );

  const handleBackStep = () => onStepChange(step - 1);

  const getStepComponent = () => {
    switch (step) {
      case 1:
        return (
          <ProductModelStep
            setIsModified={setIsModified}
            setLoading={setModelAttachmentLoading}
            setAppImageError={setAppImageError}
            initialProductInfo={initialProductInfo}
          />
        );
      case 2:
        return (
          <WiFiComponentsStep
            setIsModified={setIsModified}
            saveData={saveData}
            handleSetInitialValues={handleSetInitialValues}
            initialComponentCombination={initialComponentCombination}
          />
        );
      case 3:
        return (
          <CertificationsStep
            setIsModified={setIsModified}
            handleChangeProceedWithSelection={setCanProceedWithSelection}
          />
        );
      case 4:
        return (
          <CapabilitiesStep
            setIsModified={setIsModified}
            onPreviousStep={handleBackStep}
            handleCapabilitesMissingInfo={setIsCapabilitesMissingInfo}
          />
        );
      case 5:
        return (
          <TestingLabsStep
            setIsModified={setIsModified}
            setExceptionAttachmentLoading={setExceptionAttachmentLoading}
          />
        );
      case 6:
        return <ReviewStep onStepChange={onStepChange} setIsModified={setIsModified} />;
      case 7:
        return <PaymentStep setIsModified={setIsModified} setLoading={setEstimatedFeeLoading} />;
      default:
        return null;
    }
  };

  const getStepButtons = () => {
    const handleNext = () => {
      if (step === 3) dispatch(setAutoValidateCapability(false));
      if (step === 3 && missingRoles.length) {
        Confirm({
          title: t('common.placeholders.areYouSure'),
          yesText: t('applications.cc.warning.btn.gotIt'),
          message: t('applications.cc.warning.missingRolesWarning', {
            roles: missingRoles.map(r => r.name).join(', '),
          }),
          onAccept: () => {
            if (onNext) {
              onNext();
            }
          },
        });
      } else {
        if (onNext && !appImageError) {
          onNext();
        }
      }
    };

    const nextBtnProps = {
      className: step !== 7 ? 'next-step' : 'submit',
      loading: saving || submitting,
      onClick: handleNext,
      disabled: modelAttachmentLoading || exceptionAttachmentLoading || estimatedFeeLoading,
    };
    if (step === 3) {
      nextBtnProps.disabled = !hasCertificationsSelected || !canProceedWithSelection;
    }
    if (step === 4) {
      nextBtnProps.disabled =
        isCapabilitesMissingInfo ||
        (autoValidateCapability && isSomeCapabilityValidateError(certifications, capabilitisByCC));
    }

    if (step === 6) {
      nextBtnProps.disabled = !!isModified;
    }

    return (
      <div className="btn-wrapper">
        {step > 1 ? (
          <Button className="previous-step" onClick={onPrevious} disabled={exceptionAttachmentLoading}>
            {t('common.navigation.previous')}
          </Button>
        ) : null}
        <Button appearance="primary" {...nextBtnProps}>
          {step === 7 ? t('common.navigation.submit') : t('common.navigation.next')}
        </Button>
      </div>
    );
  };

  return (
    <PageTemplate
      title="New Application"
      actionLeft={
        <BackToListButton
          disabled={modelAttachmentLoading || exceptionAttachmentLoading || estimatedFeeLoading}
          onClick={onBackToList}
          className="btn-back-to-list"
        />
      }
      footerActionLeft={<></>}
      actionRight={getStepButtons()}
      footerAddition={
        (step === 1 || step === 2) && (
          <span className="text-muted pr-1">
            <span className="text-danger text-bold">* </span> {t('common.requiredFields')}
          </span>
        )
      }
    >
      <FlexboxGrid className="page-container" justify="space-between">
        <FlexboxGrid.Item colspan={24}>
          <NewApplicationSteps onClick={onStepChange} currentStep={step} stepTitles={stepTitles} />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={18}>
          <h5
            className={classNames('mt-1', 'mb-2')}
            dangerouslySetInnerHTML={{
              __html: `${t(`applications.steps.step`, {
                number: step,
              })}${stepTitles[step - 1]}`,
            }}
          />
          {getStepComponent()}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <ApplicationSummarySideBar />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </PageTemplate>
  );
};

export const NewApplicationFlowPage = withRouter(PageComponent);
