import * as actionTypes from './actionTypes';
import { ComponentCombination, PaymentData, ProductCategories } from '../../../api/application/types';
import { UploadedFile } from '../../../shared-components/file-upload/types';
import {
  AppCapability,
  AppCertification,
  AppFlowSteps,
  ApplicationFlowState,
  CompareCCInfo,
  ComponentCombinationWrapper,
  ReviewSectionEditState,
  StepsValidation,
  TestingException,
  CoreCCException,
} from './reducer';
import { ErrorObject } from '../../../helpers/types';
import { MemberClearData } from './data';
import {
  CreateApplicationForm,
  testingFieldTypes,
  TestingItem,
  TestingLab,
  TreeToEnable,
  TreeToUncheck,
} from '../../../components/pages/application/types';
import { PreReqTree } from '../../../components/pages/application/partials/new-application-flow-steps';
import { LabsResponse } from '../../../api/application/get-labs-by-app-id';
import { GetCapabilitiesByCC } from '../../../api/application/get-capabilities-by-CC';
import { Fee } from '../../../components/partials/billing/types';

export function newApplicationStarted(payload: CreateApplicationForm) {
  return {
    type: actionTypes.NEW_APPLICATION_STARTED,
    payload,
  };
}

export function editApplicationLoaded(application: ApplicationFlowState) {
  return {
    type: actionTypes.APPLICATION_FOR_EDIT_LOADED,
    application,
    id: application.id as number,
  };
}

export function setApplicationId(payload: number) {
  return {
    type: actionTypes.SET_APPLICATION_ID,
    payload,
  };
}

export function setValidationState(payload: Partial<StepsValidation>) {
  return { type: actionTypes.SET_VALIDATION_STATE, payload, save: true };
}

export function updateCertifications(payload: AppCertification[]) {
  return {
    type: actionTypes.UPDATE_CERTIFICATIONS,
    payload: payload,
  };
}

export function updatePaymentData(payload: PaymentData) {
  return {
    type: actionTypes.UPDATE_PAYMENT_DATA,
    payload: payload,
  };
}

export function updateExplanation(payload: TestingException | null) {
  return {
    type: actionTypes.UPDATE_APPLICATION_EXPLANATION,
    payload: payload,
  };
}

export function updateCoreCCExplanation(payload: CoreCCException | null) {
  return {
    type: actionTypes.UPDATE_APPLICATION_CORE_CC_EXPLANATION,
    payload: payload,
  };
}

export function updateCoreCCExceptionRequested(payload: boolean) {
  return {
    type: actionTypes.UPDATE_APPLICATION_CORE_CC_EXCEPTION_REQUESTED,
    payload: payload,
  };
}

export function updateApplicationProperty(
  value:
    | string
    | number
    | boolean
    | ProductCategories
    | ProductCategories[]
    | UploadedFile
    | TestingException
    | ComponentCombination
    | Partial<ApplicationFlowState>
    | ComponentCombinationWrapper<ComponentCombination>
    | null,
  name: string,
  clear: MemberClearData = MemberClearData.NONE,
  step: AppFlowSteps | null = null,
  skipValidation = false,
) {
  return {
    payload: { value, name, clear, step, skipValidation },
    type: actionTypes.UPDATE_APPLICATION_PROPERTY,
    save: true,
  };
}

export function addCCLoadedNote(id: number, cid: string, variant: string) {
  return {
    payload: { id, cid, variant },
    type: actionTypes.ADD_CC_LOADED_NOTE,
  };
}

export function addCCLoadedNoteWarning(id: number) {
  return {
    payload: { id },
    type: actionTypes.ADD_CC_LOADED_NOTE_WARNING,
  };
}

export function addRfBand(data: { band: string; rx: number; tx: number }, roleName: string, index: number) {
  return {
    type: actionTypes.ADD_RF_BAND,
    payload: { data, roleName, index },
    save: true,
  };
}
export function removeRfBand(payload: string) {
  return {
    type: actionTypes.REMOVE_RF_BAND,
    payload,
    save: true,
  };
}

export function removeCCRole(roleName: keyof ComponentCombination, index: number) {
  return {
    payload: { roleName, index },
    type: actionTypes.REMOVE_CC_ROLE,
    save: true,
  };
}

export function addCCRole(sortOrder: number) {
  return {
    payload: { sortOrder },
    type: actionTypes.ADD_CC_ROLE,
    save: true,
  };
}

export function changeCCRole(currentRole: string, newRole: string, arrayIndex: number, lastOrderNumber: number) {
  return {
    type: actionTypes.CHANGE_CC_ROLE,
    payload: { currentRole, newRole, arrayIndex, lastOrderNumber },
    save: true,
  };
}

export function updateValidationErrors(payload: ErrorObject) {
  return {
    type: actionTypes.SET_APPLICATION_VALIDATION_ERRORS,
    payload,
  };
}

export function selectAllCertForLab(
  lab: TestingItem | null,
  checked: boolean,
  field: testingFieldTypes,
  certList: number[],
) {
  return {
    type: actionTypes.SELECT_ALL_CERT_FOR_LAB,
    payload: { lab, checked, field, certList },
    save: true,
  };
}

export function selectCertificationLabCheckbox(
  lab: TestingItem | null,
  certVersionId: number,
  field: testingFieldTypes,
) {
  return {
    type: actionTypes.SELECT_CERT_LAB_CHECKBOX,
    payload: { lab, certVersionId, field },
    save: true,
  };
}

export function setShowPopup(payload: boolean) {
  return {
    type: actionTypes.SET_SHOW_POPUP,
    payload,
  };
}

export function setAutoValidateCapability(payload: boolean) {
  return {
    type: actionTypes.SET_AUTO_VALIDATE_CAPABILITY,
    payload,
  };
}

export function setCapabilitisByCC(payload: GetCapabilitiesByCC[]) {
  return {
    type: actionTypes.SET_CAPABILITIES_BY_CC,
    payload,
  };
}

export function checkCapability(payload: {
  additions: {
    id: number;
    capabilities: AppCapability[];
  }[];
  prereqs: PreReqTree[];
  cleanup: boolean;
}) {
  return {
    type: actionTypes.CHECK_CAPABILITY,
    payload,
    save: true,
  };
}

export function unCheckCapability(payload: { capability: AppCapability; cleanup: boolean }) {
  return {
    type: actionTypes.UN_CHECK_CAPABILITY,
    payload,
    save: true,
  };
}

export function checkCertification(payload: {
  certification: AppCertification;
  preReqs: AppCertification[];
  cleanup: boolean;
}) {
  return {
    type: actionTypes.CHECK_CERTIFICATION,
    payload,
    save: true,
  };
}

export function unCheckCertification(payload: {
  certification: AppCertification;
  preReqs: TreeToEnable[];
  cleanup: boolean;
}) {
  return {
    type: actionTypes.UN_CHECK_CERTIFICATION,
    payload,
    save: true,
  };
}

export function uncheckCertificationTree(payload: { tree: TreeToUncheck[]; cleanup: boolean }) {
  return {
    type: actionTypes.UN_CHECK_CERT_TREE,
    payload,
    save: true,
  };
}

export function resetCertifications() {
  return {
    type: actionTypes.RESET_CERTIFICATIONS,
    save: true,
  };
}

export function setCertificationsLoader(payload: boolean) {
  return {
    type: actionTypes.SET_CERTIFICATIONS_LOADER,
    payload,
  };
}

export function updateSelectedTestingLabs(payload: { selectedTestingLabs: TestingLab[] }) {
  return {
    type: actionTypes.UPDATE_SELECTED_TESTING_LABS,
    payload,
    save: true,
  };
}

export function removeSelectedTestingLab(payload: { labId: number | null }) {
  return {
    type: actionTypes.REMOVE_SELECTED_TESTING_LAB,
    payload,
    save: true,
  };
}

export function addSelectedTestingLab() {
  return {
    type: actionTypes.ADD_SELECTED_TESTING_LAB,
    save: true,
  };
}

export function changeNameSelectedTestingLab(payload: { lab: TestingLab; index: number }) {
  return {
    type: actionTypes.CHANGE_NAME_SELECTED_TESTING_LAB,
    payload,
    save: true,
  };
}

export function setLabsResponse(payload: LabsResponse[]) {
  return {
    type: actionTypes.SET_LABS_RESPONSE,
    payload,
  };
}

export function setCompareCCInfo(payload: Partial<CompareCCInfo>) {
  return {
    type: actionTypes.SET_COMPARE_CC_INFO,
    payload,
  };
}

export function setReviewSectionEditState(payload: Partial<ReviewSectionEditState>) {
  return {
    type: actionTypes.SET_REVIEW_SECTIONS_EDIT_STATE,
    payload,
  };
}

export function setFeesResponse(payload: Fee[] | null) {
  return {
    type: actionTypes.SET_FEES_RESPONSE,
    payload: payload,
  };
}
