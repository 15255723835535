import React from 'react';
import { EasyMeshController } from '../../types';
import { DetailsItem } from '../../../../partials';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RolesState } from '../../../../../redux/modules/roles/reducer';
import { Role } from '../../../../../redux/modules/roles/types';
import { roleNameMapping } from '../../data/constants';
import { WrapWithPublicIcon } from '../../../../../shared-components/helper-components/WrapWithPublicIcon';

export const EasyMesh = ({
  record,
  showPublicIcon = false,
}: {
  record: EasyMeshController;
  showPublicIcon?: boolean;
}) => {
  const { t } = useTranslation();
  const roles = useSelector<{ rolesReducer: RolesState }, Role[]>(state => state.rolesReducer.roles);
  const roleName = roles.find(item => item.propertyName === roleNameMapping.easyMeshController)?.name;

  return (
    <div className="mb-2">
      <p className="mb-1">
        <strong>{t('applications.cc.role')}:</strong> {roleName}
      </p>
      <DetailsItem
        label={
          showPublicIcon ? (
            <WrapWithPublicIcon content={t('applications.cc.componentOS')} />
          ) : (
            t('applications.cc.componentOS')
          )
        }
        value={record.os}
      />
    </div>
  );
};
