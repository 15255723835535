import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Theme } from '@material-ui/core';

import { ApplicationDetails, Section } from '../../application/partials';
import { ApplicationInfoRecord, CCInfoRecord } from '../../application/types';
import { applicationTypes, wfaStaffRoles } from '../../../../helpers/constants';
import { CertificationsSections } from './CertificationsSection';
import { RootReducer } from '../../../../redux/rootReducer';
import { User } from '../../../../redux/modules/user/types';
import { getVariantsByProductModelId } from '../../../../api/product';
import { DetailsItem } from '../../../partials';
import { FileItem } from '../../../partials/FileItem';
import { LabeledSelect } from '../../../../shared-components/labeled-inputs';
import { VariantInfo } from '../../product/types';
import { CCDetailsWithCompare } from './CCDetailsWithCompare';
import { CCDetails } from '../../application/partials/cc-details/CCDetails';
import { isSolutionProvider } from './helpers';
import { VariantInfoTitle } from '../../../partials/VariantInfoTitle';
import { LoadedFromBreadcrumps } from '../../../partials/LoadedFromBreadcrumbs.tsx';
import { GetFeeResponse } from '../../../partials/billing/types';
import ReviewAppBillingSection from './ReviewAppBillingSection';

const useStyles = makeStyles((theme: Theme) => ({
  rowWithMargin: {
    marginBottom: '1rem',
  },
  rightCol: {
    paddingLeft: theme.spacing(1.25),
  },
}));

export const ReviewAppStep = ({
  application,
  applicationFees,
}: {
  application: ApplicationInfoRecord;
  applicationFees: GetFeeResponse[];
}) => {
  const isDerivative = application['@type'] === applicationTypes.derivative;
  const user = useSelector<RootReducer, User>(state => state.userReducer.user as User);
  const [variants, setVariants] = useState<VariantInfo[]>([]);
  const [selectedVariantId, setSelectedVariantId] = useState<number>();
  const [variantToCompare, setVariantToCompare] = useState<VariantInfo>();
  const [expandedCC, setExpandedCC] = useState<string[]>([]);
  const ccChanged = application.componentCombination.componentCombinationVariantSource?.coreCCChanged;
  const comparedCC = application.componentCombination.componentCombinationVariantSource?.componentCombination;
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (!isDerivative) {
      getVariantsByProductModelId(application.productInfo.id as number).then(
        (response: AxiosResponse<VariantInfo[]>) => {
          const filteredVariants = response.data.filter(el => el.application.id !== application.id);
          setVariants(filteredVariants);
          const { length } = filteredVariants;
          if (length > 0) {
            const source = filteredVariants.find(value => value.id === application?.companyContactInfo?.source?.id);
            if (source) {
              setSelectedVariantId(source.id);
            } else {
              setSelectedVariantId(filteredVariants[length - 1].id);
            }
          }
        },
      );
    }
  }, []);

  useEffect(() => {
    if (selectedVariantId) {
      const index = variants.findIndex(el => el.id === selectedVariantId);
      if (index !== -1) {
        setVariantToCompare(variants[index]);
      }
    }
  }, [selectedVariantId]);

  const applicationAvailableAsQualifiedSolution = isSolutionProvider(application);
  const variantAvailableAsQualifiedSolution = isSolutionProvider(variantToCompare);

  const toggleCC = (key: string, isOpen: boolean) => {
    setExpandedCC(prevState => (isOpen ? [...prevState, key] : prevState.filter(item => item !== key)));
  };

  const renderCCComparisonLoadedFrom = () => (
    <>
      {!!application?.componentCombination.componentCombinationVariantSource && (
        <LoadedFromBreadcrumps
          items={application?.componentCombination.componentCombinationVariantSource}
          linkToOnName={
            wfaStaffRoles.includes(user.authority)
              ? `/product/${application.componentCombination.componentCombinationVariantSource.product.id}`
              : ''
          }
          ccChanged={ccChanged}
        />
      )}
      <CCDetailsWithCompare
        expandedCC={expandedCC}
        toggleCC={toggleCC}
        cc={application.componentCombination.componentCombination}
        compareCC={
          ccChanged ? comparedCC : (variantToCompare?.componentCombination.componentCombination as CCInfoRecord)
        }
        ccChanged={ccChanged}
        togglePrefix={'CCComparisonLoadedFrom_'}
      />
    </>
  );

  const renderLeftColumn = () => (
    <>
      <DetailsItem
        label={t('applications.info.thisVariantName')}
        value={application.productInfo.modelVariants[0].name}
        className="mb-1"
      />
      <DetailsItem className="mb-1" label={t('applications.summary.appId')} value={application.id} />
      <DetailsItem
        className="mb-1"
        label={t('applications.info.searchable')}
        value={
          (application.productInfo.modelVariants[0].searchableByPublic
            ? t('common.options.yes')
            : t('common.options.no')) as string
        }
      />
      <DetailsItem
        label={t('applications.info.available')}
        className="mb-1"
        value={
          (application.productInfo.modelVariants[0].availableAsDerivative
            ? t('common.options.yes')
            : t('common.options.no')) as string
        }
      />
      {applicationAvailableAsQualifiedSolution && (
        <DetailsItem
          className="mb-1"
          label={t('applications.info.availableAsQualifiedSolution')}
          value={
            (application.productInfo.modelVariants[0].availableAsQualifiedSolution
              ? t('common.options.yes')
              : t('common.options.no')) as string
          }
        />
      )}
      {variants.length === 0 ? (
        <>
          {!!application?.componentCombination.componentCombinationVariantSource && (
            <LoadedFromBreadcrumps
              items={application?.componentCombination.componentCombinationVariantSource}
              linkToOnName={
                wfaStaffRoles.includes(user.authority)
                  ? `/product/${application.componentCombination.componentCombinationVariantSource.product.id}`
                  : ''
              }
              ccChanged={ccChanged}
            />
          )}
          <CCDetailsWithCompare
            expandedCC={expandedCC}
            toggleCC={toggleCC}
            cc={application.componentCombination.componentCombination}
            compareCC={
              ccChanged ? comparedCC : (variantToCompare?.componentCombination.componentCombination as CCInfoRecord)
            }
            ccChanged={ccChanged}
          />
        </>
      ) : null}
    </>
  );

  const renderRightColumnInComparison = () => (
    <>
      <LabeledSelect
        data={variants}
        noPadding
        label="Other Product Model Variant"
        valueKey="id"
        labelKey="name"
        onChange={setSelectedVariantId}
        value={selectedVariantId}
        cleanable={false}
      />
      {variantToCompare?.id ? (
        <>
          <DetailsItem
            className="mb-1"
            label={t('applications.summary.appId')}
            value={variantToCompare.application.id}
          />
          <DetailsItem
            label={t('applications.info.searchable')}
            className="mb-1"
            value={(variantToCompare?.searchableByPublic ? t('common.options.yes') : t('common.options.no')) as string}
          />
          <DetailsItem
            label={t('applications.info.available')}
            className="mb-1"
            value={
              (variantToCompare?.availableAsDerivative ? t('common.options.yes') : t('common.options.no')) as string
            }
          />
          {variantAvailableAsQualifiedSolution && (
            <DetailsItem
              label={t('applications.info.availableAsQualifiedSolution')}
              className="mb-1"
              value={
                (variantToCompare?.availableAsQualifiedSolution
                  ? t('common.options.yes')
                  : t('common.options.no')) as string
              }
            />
          )}
        </>
      ) : null}
    </>
  );

  const renderCCInComparison = () => (
    <>
      <Grid container>
        <Grid item xs={6}>
          {!!application?.componentCombination.componentCombinationVariantSource && (
            <LoadedFromBreadcrumps
              items={application?.componentCombination.componentCombinationVariantSource}
              linkToOnName={
                wfaStaffRoles.includes(user.authority)
                  ? `/product/${application.componentCombination.componentCombinationVariantSource.product.id}`
                  : ''
              }
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.rightCol}>
          {!!variantToCompare?.componentCombination.componentCombinationVariantSource && (
            <LoadedFromBreadcrumps
              items={variantToCompare?.componentCombination.componentCombinationVariantSource}
              linkToOnName={
                wfaStaffRoles.includes(user.authority)
                  ? `/product/${variantToCompare.componentCombination.componentCombinationVariantSource.product.id}`
                  : ''
              }
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.rowWithMargin}>
        <Grid item xs={6}>
          <CCDetailsWithCompare
            expandedCC={expandedCC}
            toggleCC={toggleCC}
            cc={application.componentCombination.componentCombination}
            compareCC={variantToCompare?.componentCombination.componentCombination as CCInfoRecord}
            isFullSizeCol={true}
          />
        </Grid>
        <Grid item xs={6} className={classes.rightCol}>
          <CCDetailsWithCompare
            expandedCC={expandedCC}
            toggleCC={toggleCC}
            cc={variantToCompare?.componentCombination.componentCombination as CCInfoRecord}
            isFullSizeCol={true}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <ApplicationDetails application={application} withCC={false} withVariantInfo={false} />
      <Section>
        <VariantInfoTitle productType={application.productInfo.modelVariants[0].productType} />
        <Grid container className={classes.rowWithMargin}>
          <Grid item xs={6}>
            <DetailsItem
              value={application.productInfo.modelVariants[0].name}
              label={t('applications.info.variantName')}
            />
          </Grid>
          <Grid item xs={6}>
            <DetailsItem value={application.id} label={t('applications.summary.appId')} />
          </Grid>
        </Grid>
        {isDerivative ? (
          <>
            <DetailsItem
              label={t('applications.info.searchable')}
              value={
                (application.productInfo.modelVariants[0].searchableByPublic
                  ? t('common.options.yes')
                  : t('common.options.no')) as string
              }
            />
            <DetailsItem
              className="mb-1"
              label={t('applications.info.available')}
              value={
                (application.productInfo.modelVariants[0].availableAsDerivative
                  ? t('common.options.yes')
                  : t('common.options.no')) as string
              }
            />
          </>
        ) : null}
        <Grid container className={classes.rowWithMargin}>
          <Grid item xs={6}>
            <DetailsItem label={t('applications.info.docs')}>
              {application.productInfo.documents.length
                ? application.productInfo.documents.map(file => <FileItem file={file} key={file.id} />)
                : null}
            </DetailsItem>
          </Grid>
        </Grid>
      </Section>

      {!isDerivative && variants.length !== 0 && ccChanged ? (
        <Section>
          <h4>{t('applications.review.section.wifiComponents.titleWithLoadedComponent')}</h4>
          <>
            <Grid container>
              <Grid item xs={12}>
                {renderCCComparisonLoadedFrom()}
              </Grid>
            </Grid>
          </>
        </Section>
      ) : null}

      <Section>
        <h4>
          {!isDerivative && wfaStaffRoles.includes(user.authority) ? t('staff.cc') : t('applications.cc.wifiComponent')}
        </h4>
        {isDerivative ? (
          <CCDetails
            cc={application.componentCombination.componentCombination}
            ccVariantSource={application.componentCombination.componentCombinationVariantSource}
          />
        ) : (
          <>
            <Grid container>
              {variants.length > 0 ? (
                <>
                  <Grid item xs={6}>
                    {renderLeftColumn()}
                  </Grid>
                  <Grid item xs={6} className={classes.rightCol}>
                    {renderRightColumnInComparison()}
                  </Grid>
                  {variantToCompare?.id ? renderCCInComparison() : null}
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    {renderLeftColumn()}
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </Section>
      {!isDerivative ? (
        <CertificationsSections
          certifications={application.certifications}
          compareCertifications={variantToCompare?.certifications || []}
        />
      ) : null}
      {!!applicationFees.length && (
        <Section>
          <h4>{t('billing.billingInfo')}</h4>
          {applicationFees?.map(fee => (
            <ReviewAppBillingSection key={fee.fees[0].priceBookEntry.id} fee={fee} applicationRecord={application} />
          ))}
        </Section>
      )}
    </>
  );
};
