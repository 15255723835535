import { DateTime } from 'luxon';
export const summaryDateFormat = 'MM/dd/yyyy';
export const pickerDateFormat = 'MM/DD/YYYY';
export const eventDateFormat = 'MM/dd/yyyy h:mm a';
export const opportunityDateFormat = 'MM/dd/yyyy h:mm:ss a';

export const reformatDate = (date: string): string => {
  const formattedDate = DateTime.fromISO(date).toFormat(summaryDateFormat);
  if (DateTime.isDateTime(formattedDate)) {
    return formattedDate;
  }
  return DateTime.fromJSDate(new Date(date)).toFormat(summaryDateFormat);
};

export const reformatDateToFormat = (date: string, format: string): string => DateTime.fromISO(date).toFormat(format);

export const getDateDifference = (inputDate: string, currentDate = DateTime.local()): number => {
  return DateTime.fromISO(inputDate)
    .startOf('day')
    .diff(currentDate.startOf('day'), 'seconds').seconds;
};

export const getDayTimestamp = (date: string): number =>
  Date.parse(DateTime.fromISO(new Date(date).toISOString()).toFormat('yyyy-MM-dd'));

export const isDateAfter = (currentDate: string, dates: string[]): boolean => {
  if (!currentDate) {
    return true;
  }

  const currentTimestamp = getDayTimestamp(new Date(currentDate).toISOString());
  const timestamps = dates.filter(date => !!date).map(date => getDayTimestamp(date));

  if (!timestamps.length) {
    return true;
  }

  const maxTimestamp = Math.max(...timestamps);

  return currentTimestamp >= maxTimestamp;
};
