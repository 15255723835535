import MuiCheckbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckBoxIcon from '@material-ui/icons/CheckBoxSharp';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'rsuite';
import styled from 'styled-components';

import { getLifeCycleStatusKey } from '../../../../../helpers/constants';
import { Autocomplete } from '../../../../../shared-components/inputs/Autocomplete';
import LifeCycleStatusBadge from '../../../certification/partials/version/LifeCycleStatusBadge';
import { LabSummaryCertification, LabSummaryCertificationByGroup } from '../types';
import { QualificationsInputSuggestion } from './QualificationsInputSuggestion';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const StyledFormWrapper = styled.div`
  display: flex;
  align-content: center;

  .rs-checkbox,
  .rs-btn {
    align-self: flex-end;
  }
`;

const useStyles = makeStyles({
  root: {
    fontSize: 24,
  },
});

const useStyles4AutoComplete = makeStyles(theme => {
  return {
    groupLabel: {
      backgroundColor: theme.palette.background.paper,
      top: -8,
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 'bold',
    },
    /* Styles applied to the group's ul elements. */
    groupUl: {
      padding: 0,
      '& $option': {
        paddingLeft: 24,
      },
    },
  };
});

interface QualificationsInputProps {
  loading: boolean;
  certifications: LabSummaryCertification[];
  disabledCertifications?: LabSummaryCertificationByGroup[];
  onSelect: (certs: LabSummaryCertification[], interoperability: boolean, conformance: boolean) => void;
}

export const QualificationsInput = (props: QualificationsInputProps) => {
  const { loading, certifications, onSelect, disabledCertifications = [] } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  const classes4AutoComplete = useStyles4AutoComplete();
  const uncheckedIcon = <CheckBoxOutlineBlankIcon classes={classes} />;
  const checkedIcon = <CheckBoxIcon classes={classes} style={{ color: _var.primary }} />;
  const indeterminateIcon = <IndeterminateCheckBoxIcon classes={classes} style={{ color: _var.primary }} />;

  const [selectedCertifications, setSelectedCertifications] = useState<LabSummaryCertification[]>([]);
  const [conformance, setConformance] = useState(false);
  const [interoperability, setInteroperability] = useState(false);

  const handleSelect = () => {
    onSelect(selectedCertifications, interoperability, conformance);
    setSelectedCertifications([]);
    setInteroperability(false);
    setConformance(false);
  };

  const getCertificationGroupName = (cert: LabSummaryCertification) => `${cert.name} (${cert.role.name})`;

  const lookupGroupStartIndexByName = (groupName: string) => {
    for (let i = 0; i < certifications.length; i++) {
      if (getCertificationGroupName(certifications[i]) === groupName) return i;
    }
    return -1;
  };

  const isGroupDisabled = (groupStartIndex: number, groupLength: number) => {
    if (groupStartIndex === -1) return false;
    const childrenIds = certifications.slice(groupStartIndex, groupStartIndex + groupLength).map(k => k.version.id);
    return childrenIds.every(id =>
      disabledCertifications.some(value => value.children.some(c => c.version?.id === id)),
    );
  };

  const isGroupChecked = (groupStartIndex: number, groupLength: number) => {
    if (groupStartIndex === -1) return false;
    const childrenIds = certifications.slice(groupStartIndex, groupStartIndex + groupLength).map(k => k.version.id);
    return (
      !isGroupDisabled(groupStartIndex, groupLength) &&
      childrenIds
        .filter(c => !disabledCertifications.some(value => value.children.some(d => d.version?.id === c)))
        .every(id => selectedCertifications.findIndex(c => c.version.id === id) != -1)
    );
  };

  const isGroupIndeterminate = (groupStartIndex: number, groupLength: number) => {
    if (groupStartIndex === -1) return false;
    const childrenIds = certifications.slice(groupStartIndex, groupStartIndex + groupLength).map(k => k.version.id);
    return (
      !isGroupChecked(groupStartIndex, groupLength) &&
      childrenIds
        .filter(c => !disabledCertifications.some(value => value.children.some(d => d.version?.id === c)))
        .some(id => selectedCertifications.findIndex(c => c.version.id === id) != -1)
    );
  };

  const createGroupOnClick = (groupStartIndex: number, groupLength: number, checked: boolean) => {
    return () => {
      const childrenIds = certifications.slice(groupStartIndex, groupStartIndex + groupLength).map(k => k.version.id);
      if (checked) {
        const addChildrenIds = childrenIds.filter(
          id =>
            selectedCertifications.findIndex(c => c.version.id === id) == -1 &&
            !disabledCertifications.some(d => d.children.some(c => c.version?.id === id)),
        );
        setSelectedCertifications(prev => [
          ...prev,
          ...addChildrenIds.reduce<LabSummaryCertification[]>((accumulator, id) => {
            const c = certifications.find(c => c.version.id == id);
            if (c != undefined) {
              return [...accumulator, c];
            }
            return accumulator;
          }, []),
        ]);
      } else {
        setSelectedCertifications(selectedCertifications.filter(c => !childrenIds.includes(c.version.id)));
      }
    };
  };

  return (
    <StyledFormWrapper>
      <Autocomplete<LabSummaryCertification, true>
        multiple
        className="mr-1"
        disableCloseOnSelect
        value={selectedCertifications}
        onChange={setSelectedCertifications}
        options={certifications}
        loading={loading}
        getOptionDisabled={option =>
          disabledCertifications.some(value => value.children.some(c => c.version.id === option.version.id))
        }
        renderGroup={params => {
          const groupStartIndex = lookupGroupStartIndexByName(params.group as string);
          const groupLength = params.children?.length ?? 0;

          return (
            <li key={params.key}>
              <div
                className={classes4AutoComplete.groupLabel}
                onClick={createGroupOnClick(
                  groupStartIndex,
                  groupLength,
                  !isGroupChecked(groupStartIndex, groupLength),
                )}
              >
                <MuiCheckbox
                  icon={uncheckedIcon}
                  checkedIcon={checkedIcon}
                  indeterminateIcon={indeterminateIcon}
                  style={{ marginRight: 8 }}
                  indeterminate={isGroupIndeterminate(groupStartIndex, groupLength)}
                  checked={isGroupChecked(groupStartIndex, groupLength)}
                  disabled={isGroupDisabled(groupStartIndex, groupLength)}
                />
                <span>{params.group}</span>
              </div>
              <ul className={classes4AutoComplete.groupUl}>{params.children}</ul>
            </li>
          );
        }}
        groupBy={getCertificationGroupName}
        getOptionLabel={cert =>
          `${cert.name} [${cert.version.id} - ${cert.version.name}] (${cert.role.name}) (${t(
            'certifications.lifecycle.status.' + getLifeCycleStatusKey(cert.version.status),
          )})`
        }
        renderOption={(item, { selected }) => (
          <>
            <MuiCheckbox icon={uncheckedIcon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            <QualificationsInputSuggestion item={item} />
            <LifeCycleStatusBadge status={item.version.status} />
          </>
        )}
        placeholder={t('common.placeholders.chooseCertification')}
      />
      <Checkbox className="mr-1" checked={interoperability} onChange={(_, checked) => setInteroperability(checked)}>
        {t('applications.labs.interoperability')}
      </Checkbox>
      <Checkbox className="mr-1" checked={conformance} onChange={(_, checked) => setConformance(checked)}>
        {t('applications.labs.conformance')}
      </Checkbox>
      <Button
        appearance="primary"
        className="add-qualification"
        onClick={handleSelect}
        style={{ minWidth: 110 }}
        disabled={!selectedCertifications.length || (!conformance && !interoperability)}
      >
        {t('admin.qualifications.addCertification')}
      </Button>
    </StyledFormWrapper>
  );
};
