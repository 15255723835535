import React, { useEffect, useState } from 'react';
import {
  GetSummaryWithFiltersOptions,
  IterableObject,
  StartSizePaginated,
  SummaryFiltersObject,
  SummaryTableProps,
} from '../../helpers/types';
import { SortingOptions, SortType } from '../pages/test-case/types';
import { useToasts } from 'react-toast-notifications';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { handleRequestFail } from '../../helpers/request-fail-handler';
import { TabLabel, TabPane, TabPaneProps, Tabs, TabHorizontalLabel } from '../../shared-components/theme';
import { PageTemplate } from '../partials';
import { DefaultSummaryFilters } from '../partials/DefaultSummaryFilters';
import { useHistory, useLocation } from 'react-router';
import { parseUrlParams, resetSummaryUrl, setUrlParams } from '../../helpers';
import { ActionsWrapper } from '../../shared-components/theme/tabs/styled-components';
import { AdvancedSummaryFilters } from '../partials/filters/AdvancedSummaryFilters';
import { initSummaryFiltersObject } from './get-summary-without-tabs-page-component';

type SummaryApiCall<T> = (
  page: number,
  activeTab: string,
  sortingOptions?: SortingOptions,
  filters?: SummaryFiltersObject,
) => AxiosPromise<StartSizePaginated<T>>;

export function getSummaryPageComponent<T>(
  title: string,
  apiCall: SummaryApiCall<T>,
  object: IterableObject<string>,
  titles: IterableObject<string>,
  SummaryTable: (props: SummaryTableProps<T> & { status: string }) => JSX.Element | null,
  pageRightAction: React.Component | React.ReactElement | null = null,
  defaultSorting: SortingOptions = { sortColumn: 'date', sortType: 'desc' },
  showLastBtn = false,
  searchOnly = false,
  rightContentTab: React.ReactElement | null = null,
  hideRoleFilter = true,
  withoutPageWrapper = false,
  additionalFilters = {},
  hideCertificationTypeFilter = true,
  useAdvancedFilters = false,
  isApplicationSummary = false,
  isProductSummary = false,
  isTestingSummary = false,
  isCertificationSummary = false,
  isTestCaseSummary = false,
) {
  const SummaryComponent = () => {
    const history = useHistory();
    const location = useLocation();
    const params = parseUrlParams(location.search);
    function getInitialData<T>(value: T): IterableObject<T> {
      return Object.keys(object).reduce((obj, key) => {
        // @ts-ignore
        obj[object[key]] = value;
        return obj;
      }, {});
    }
    const [total, setTotal] = useState(getInitialData<number>(0));
    const [tableData, setTableData] = useState(getInitialData<T[]>([]));
    const [appliedFilters, setAppliedFilters] = useState<SummaryFiltersObject>(initSummaryFiltersObject(params));
    const paramsSorting: SortingOptions =
      params.sortColumn && params.sortType
        ? { sortColumn: params.sortColumn, sortType: params.sortType as SortType }
        : defaultSorting;
    const [sorting, setSorting] = useState<SortingOptions>(paramsSorting);
    const defaultTab =
      params.tab && Object.values(object).includes(params.tab) ? params.tab : object[Object.keys(object)[0]];
    const [currentTab, setCurrentTab] = useState(defaultTab);
    const [loadingData, setLoadingData] = useState(false);
    const { addToast } = useToasts();
    const [currentPage, setCurrentPage] = useState(params.page ? Number(params.page) : 1);
    const [firstLoadByStatus, setFirstLoad] = useState(getInitialData(true));

    const getSummary = (options: GetSummaryWithFiltersOptions = {}) => {
      setLoadingData(true);
      const { sortingOptions = sorting, tab = currentTab, page = currentPage, filters = appliedFilters } = options;
      apiCall(page, tab, sortingOptions, { ...filters, ...additionalFilters })
        .then((response: AxiosResponse<StartSizePaginated<T>>) => {
          const content = response.data.content.map(item => ({
            ...item,
            actions: { setLoadingData, getSummary },
          }));
          setTableData(prevState => ({ ...prevState, [tab]: content }));
          setFirstLoad(prevState => ({ ...prevState, [tab]: false }));
          setTotal(response.data.counts);
          setLoadingData(false);
        })
        .catch((error: AxiosError) => {
          setFirstLoad(prevState => ({ ...prevState, [tab]: false }));
          setTableData(prevState => ({ ...prevState, [tab]: [] }));
          setTotal(getInitialData(0));
          handleRequestFail(error, addToast);
          setLoadingData(false);
        });
    };

    const setParam = (paramsToSet: IterableObject<string | string[] | number | null>) => {
      const { pathname } = location;
      const search = setUrlParams(paramsToSet, location.search).toString();
      resetSummaryUrl(pathname.substring(1), `${pathname}?${search.toString()}`);
      history.push({
        pathname,
        search,
      });
    };

    useEffect(() => {
      const { search, pathname } = location;
      resetSummaryUrl(pathname.substring(1), pathname + search);
      if (params.tab && currentTab !== params.tab) {
        setCurrentTab(params.tab);
        setCurrentPage(1);
        getSummary({ page: 1, tab: params.tab });
      } else {
        getSummary();
      }
    }, [location]);

    const handleTabClick = ({ props }: { props: TabPaneProps }) => {
      const { name } = props;
      if (name !== currentTab) {
        setParam({ tab: name, page: 1 });
        setCurrentTab(name);
        setCurrentPage(1);
        getSummary({ page: 1, tab: name });
      }
    };

    useEffect(() => {
      if (params.tab !== currentTab) {
        if (params.tab === undefined && currentTab !== defaultTab) {
          setCurrentTab(defaultTab);
        } else if (Object.values(object).includes(params.tab)) {
          setParam({ tab: params.tab, page: 1 });
          setCurrentTab(params.tab);
          setCurrentPage(1);
        }
      }
    }, []);

    const handleApplyFilters = (filters: SummaryFiltersObject) => {
      setAppliedFilters(filters);
      setParam({ ...filters, page: 1 });
      setCurrentPage(1);
      getSummary({ filters, page: 1 });
    };

    const handleSortChange = (sortColumn: string, sortType: SortType) => {
      setCurrentPage(1);
      const sortingOptions = { sortColumn, sortType };
      setSorting(sortingOptions);
      setParam({ sortColumn, sortType, page: 1 });
      return getSummary({ sortingOptions, page: 1 });
    };

    const handlePageChange = (page: number) => {
      setParam({ page });
      setCurrentPage(page);
      return getSummary({ page });
    };

    const getTabsWithContent = () => (
      <Tabs value={currentTab} onTabClick={handleTabClick} rightContent={rightContentTab}>
        {Object.values(object).map((status, index) => (
          <TabPane
            key={index}
            name={status}
            label={
              useAdvancedFilters ? (
                <TabHorizontalLabel value={total[status]} label={titles[status]} />
              ) : (
                <TabLabel value={total[status]} label={titles[status]} />
              )
            }
          >
            {currentTab === status && (
              <SummaryTable
                data={tableData[status]}
                status={status}
                loading={loadingData}
                total={total[status]}
                activePage={currentPage}
                setActivePage={handlePageChange}
                sorting={sorting}
                onChangeSorting={handleSortChange}
                firstLoad={firstLoadByStatus[status]}
                showLastBtn={showLastBtn}
              />
            )}
          </TabPane>
        ))}
      </Tabs>
    );

    if (withoutPageWrapper) {
      return getTabsWithContent();
    }

    return (
      <PageTemplate
        title={title}
        actionRight={
          useAdvancedFilters ? (
            <ActionsWrapper>{pageRightAction}</ActionsWrapper>
          ) : (
            <ActionsWrapper>
              <DefaultSummaryFilters
                appliedFilters={appliedFilters}
                onFiltersApply={handleApplyFilters}
                style={{ marginRight: Boolean(pageRightAction) ? '1rem' : 0 }}
                hideTimeFilter={searchOnly}
                hideRoleFilter={hideRoleFilter}
                hideCertificationTypeFilter={hideCertificationTypeFilter}
              />
              {pageRightAction}
            </ActionsWrapper>
          )
        }
        noFooter
      >
        {useAdvancedFilters ? (
          <AdvancedSummaryFilters
            appliedFilters={appliedFilters}
            onFiltersApply={handleApplyFilters}
            isApplicationSummary={isApplicationSummary}
            isProductSummary={isProductSummary}
            isTestingSummary={isTestingSummary}
            isCertificationSummary={isCertificationSummary}
            isTestCaseSummary={isTestCaseSummary}
          />
        ) : null}
        {getTabsWithContent()}
      </PageTemplate>
    );
  };
  return React.memo(SummaryComponent);
}

export function getSummaryPageComponentByObject<T>(summaryPageComponentObject: {
  title: string;
  apiCall: SummaryApiCall<T>;
  object: IterableObject<string>;
  titles: IterableObject<string>;
  SummaryTable: (props: SummaryTableProps<T> & { status: string }) => JSX.Element | null;
  pageRightAction?: React.Component | React.ReactElement | null;
  defaultSorting?: SortingOptions;
  showLastBtn?: boolean;
  searchOnly?: boolean;
  rightContentTab?: React.ReactElement | null;
  hideRoleFilter?: boolean;
  withoutPageWrapper?: boolean;
  additionalFilters?: {};
  hideCertificationTypeFilter?: boolean;
  useAdvancedFilters?: boolean;
  isApplicationSummary?: boolean;
  isProductSummary?: boolean;
  isTestingSummary?: boolean;
  isCertificationSummary?: boolean;
  isTestCaseSummary?: boolean;
}) {
  const {
    title,
    apiCall,
    object,
    titles,
    SummaryTable,
    pageRightAction = null,
    defaultSorting = { sortColumn: 'date', sortType: 'desc' },
    showLastBtn = false,
    searchOnly = false,
    rightContentTab = null,
    hideRoleFilter = true,
    withoutPageWrapper = false,
    additionalFilters = {},
    hideCertificationTypeFilter = true,
    useAdvancedFilters = false,
    isApplicationSummary = false,
    isProductSummary = false,
    isTestingSummary = false,
    isCertificationSummary = false,
    isTestCaseSummary = false,
  } = summaryPageComponentObject;
  return getSummaryPageComponent(
    title,
    apiCall,
    object,
    titles,
    SummaryTable,
    pageRightAction,
    defaultSorting,
    showLastBtn,
    searchOnly,
    rightContentTab,
    hideRoleFilter,
    withoutPageWrapper,
    additionalFilters,
    hideCertificationTypeFilter,
    useAdvancedFilters,
    isApplicationSummary,
    isProductSummary,
    isTestingSummary,
    isCertificationSummary,
    isTestCaseSummary,
  );
}
