import axios from '../../helpers/axios-setup';
import { SortingOptions } from '../../components/pages/test-case/types';
import { SummaryFiltersObject } from '../../helpers/types';
import { buildSummaryURL } from '../../helpers/build-summary-url';

export const getApplicationsSummary = (
  page: number,
  activeTab: string,
  sortingOptions?: SortingOptions,
  filters?: SummaryFiltersObject,
) => axios.get(buildSummaryURL('applications', page, sortingOptions, filters, activeTab));
