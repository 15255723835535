import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { removeCertification, publishCertificationById } from '../../../../api/certification';
import { useToasts } from 'react-toast-notifications';
import { AxiosError } from 'axios';
import { Dropdown, Icon, IconButton, Popover, Whisper } from 'rsuite';
import { CertificationType, SummaryTableRow } from '../types';
import { useTranslation } from 'react-i18next';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { certificationStatuses } from '../../../../helpers/constants/certification-statuses';
import { Confirm } from '../../../../helpers/confirmationPopup/Confirm';
import { useDispatch } from 'react-redux';
import { showCloneCertificationModal } from '../../../../redux/modules/modals';
import { defaultWhisperProps } from '../../../../helpers/constants';
import { handleRequestSuccess } from '../../../../helpers/handle-request-success';

export const CustomActions = withRouter(
  ({
    record,
    status,
    history,
  }: { record: SummaryTableRow; status: string } & RouteComponentProps): React.ReactElement<string> => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const dispatch = useDispatch();

    const onClone = () =>
      dispatch(
        showCloneCertificationModal({
          id: record.id,
          type: record.type as CertificationType,
        }),
      );

    const onView = () => {
      if (history) {
        window.open(`#/certification/${record.id}`);
      }
    };

    const onDelete = () => {
      return Confirm({
        title: t('common.placeholders.areYouSure'),
        message: t('common.placeholders.cantBeUndone'),
        onAccept: () =>
          removeCertification(record.id)
            .then(() => {
              handleRequestSuccess(t('certifications.notifications.deleted'), addToast);
              return record.actions.getSummary({});
            })
            .catch((error: AxiosError) => handleRequestFail(error, addToast)),
      });
    };

    const onPublish = () => {
      Confirm({
        title: 'Are you sure?',
        message: `You want to ${t('common.actions.publish')} ${record.name}`,
        onAccept: () => {
          publishCertificationById(record.id)
            .then(() => {
              handleRequestSuccess(t('certifications.notifications.published'), addToast);
              history.push(`/certification?tab=${certificationStatuses.published}`);
              return record.actions.getSummary({ tab: certificationStatuses.published });
            })
            .catch((error: AxiosError) => handleRequestFail(error, addToast));
        },
      });
    };
    return (
      <div className="actions">
        <IconButton onClick={onView} icon={<Icon icon="eye" />} appearance="link" />
        <Whisper
          {...defaultWhisperProps}
          speaker={
            <Popover full>
              <Dropdown.Menu className="action-icons">
                {status === certificationStatuses.draft && (
                  <Dropdown.Item onClick={onPublish}>
                    <IconButton icon={<Icon icon="check" />} appearance="link">
                      {t('common.actions.publish')}
                    </IconButton>
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={onClone}>
                  <IconButton icon={<Icon icon="copy" />} appearance="link">
                    {t('common.actions.duplicate')}
                  </IconButton>
                </Dropdown.Item>
                {status === certificationStatuses.draft ? (
                  <Dropdown.Item onClick={onDelete}>
                    <IconButton icon={<Icon icon="trash-o" />} appearance="link">
                      {t('common.actions.remove')}
                    </IconButton>
                  </Dropdown.Item>
                ) : null}
              </Dropdown.Menu>
            </Popover>
          }
        >
          <IconButton appearance="link" icon={<Icon icon="more" className="icon pointer medium" />} />
        </Whisper>
      </div>
    );
  },
);
