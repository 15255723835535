import { APP_LOADED, SET_APP_HEALTH, SET_UNSAVED_CHANGES, SET_HEALTH_LAST_CHECKED } from './actionTypes';
import { AnyAction } from 'redux';
import { mergeObjects } from '../../../helpers';

export enum APP_STATUS {
  UP = 'UP',
  DOWN = 'DOWN',
}

export interface AppHealth {
  status: APP_STATUS;
  lastUpdated: number;
}

const initialState = {
  loaded: false,
  appHealth: {
    status: APP_STATUS.UP,
    lastUpdated: 0,
  },
  unsavedChanges: {
    url: null,
    isUnsaved: false,
  },
};

export type appState = Readonly<{
  loaded: boolean;
  appHealth: AppHealth;
  unsavedChanges: {
    url: string | null;
    isUnsaved: boolean;
  };
}>;

export const appReducer = (state: appState = initialState, action: AnyAction) => {
  switch (action.type) {
    case APP_LOADED:
      return {
        ...state,
        loaded: true,
      };
    case SET_APP_HEALTH:
      return {
        ...state,
        appHealth: action.payload,
      };
    case SET_HEALTH_LAST_CHECKED:
      const appHealth = mergeObjects(state.appHealth, { lastUpdated: action.payload });
      return {
        ...state,
        appHealth,
      };
    case SET_UNSAVED_CHANGES:
      return {
        ...state,
        unsavedChanges: {
          url: action.payload.url,
          isUnsaved: action.payload.isUnsaved,
        },
      };
    default:
      return state;
  }
};
