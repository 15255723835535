import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { reset } from '../helpers/storage/form-data';

export const saveApplicationState: Middleware = ({ getState }: MiddlewareAPI) => (next: Dispatch) => action => {
  if (action.save) {
    const result = next(action);
    const state = getState().applicationFlow;
    state.formId && reset(state.formId, state);
    return result;
  }
  return next(action);
};
