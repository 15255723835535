import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { Tooltip } from '../tooltip/Tooltip';
import {
  AdvancedFilterProps,
  FilterParametersType,
  FilterParameterValuesType,
  AdvancedFilterSettingConditionsProps,
} from './types';
import { useStyles } from './styles';

const AdvancedFilter = ({
  inlineConditions,
  popupConditions,
  onConditionChange,
  initParameters,
  popupTooltip,
}: AdvancedFilterProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [parameters, setParameters] = useState<FilterParametersType>(initParameters ? initParameters : {});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const updateFilterParameter = (key: string, values: FilterParameterValuesType) => {
    setParameters((prev: FilterParametersType) => {
      const newState = { ...prev };
      if (values) {
        newState[key] = values;
      } else {
        delete newState[key];
      }
      setTimeout(() => {
        onConditionChange(newState);
      }, 100);
      return newState;
    });
  };

  const clearAllParameters = () => {
    onConditionChange({});
    setParameters({});
  };

  const clearPopupParameters = () => {
    setParameters((prev: FilterParametersType) => {
      const newState = { ...prev };
      if (popupConditions) {
        for (const popup of popupConditions) {
          delete newState[popup.parameterName];
        }
      }
      onConditionChange(newState);
      return newState;
    });
  };

  const clearSingleParameter = (key: string, index: number) => {
    setParameters((prev: FilterParametersType) => {
      const newState = { ...prev };
      if (newState.hasOwnProperty(key) && newState[key].values) {
        newState[key].values = [...prev[key].values.slice(0, index), ...prev[key].values.slice(index + 1)];
        if (newState[key].values.length == 0) delete newState[key];
      }
      onConditionChange(newState);
      return newState;
    });
  };

  const handleOpenPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const generateConditionComponent = (popup: AdvancedFilterSettingConditionsProps) => {
    const TagName = popup.conditionType;
    return (
      <TagName
        parameterName={popup.parameterName}
        updateFilterParameter={updateFilterParameter}
        currentConditionParameterValues={parameters[popup.parameterName]?.values}
        showSelectedLabel={popup.showSelectedLabel === true}
        options={popup.options}
        data-testid={`condition-${popup.parameterName}`}
      />
    );
  };

  const getPopupCondtionComponents = () => {
    return (
      <div style={{ width: 550, padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: -5 }}>
          <div style={{ display: 'flex' }}>
            <IconButton
              size="small"
              disableRipple
              onClick={clearPopupParameters}
              className={classes.adornmentIconButton}
              data-testid="clearPopupButton"
            >
              <RotateLeftIcon />
            </IconButton>
            <IconButton
              size="small"
              disableRipple
              onClick={handleClosePopup}
              className={classes.adornmentIconButton}
              data-testid="closePopupButton"
            >
              <ClearIcon />
            </IconButton>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h6>{t('filters.popup.title')}</h6>
          {popupTooltip && (
            <Tooltip translationKey={popupTooltip}>
              <HelpOutlineIcon fontSize="small" style={{ marginLeft: 5 }} />
            </Tooltip>
          )}
        </div>
        <div>
          {popupConditions &&
            popupConditions.map((popup, index) => {
              return (
                <div key={`popup-condition-wrapper-${index}`}>
                  {popup.showDivider === true ? <Divider /> : null}
                  <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    {popup.title ? (
                      <div
                        className={classes.popupConditionTitle}
                        style={{ marginBottom: 10 }}
                        data-testid="popupConditionTitle"
                      >
                        {popup.title}
                      </div>
                    ) : null}
                    <div>{generateConditionComponent(popup)}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const getInlineConditionComponents = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {inlineConditions &&
          inlineConditions.map((inline, index) => {
            return (
              <div
                key={`inline-condition-wrapper-${index}`}
                className={classNames(index > 0 ? 'ml-1' : '')}
                style={{ width: 250 }}
              >
                {generateConditionComponent(inline)}
              </div>
            );
          })}
        {popupConditions ? (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenPopup}
              className={classNames(classes.filterButton, 'ml-1', open ? classes.filterButtonWithFocus : '')}
              data-testid="openPopupConditionsButton"
            >
              <FilterListIcon
                className={classNames(classes.filterButtonIcon, open ? classes.filterButtonIconWithFocus : '')}
              />
            </Button>
            <Popover
              id="filter-popup"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopup}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {getPopupCondtionComponents()}
            </Popover>
          </>
        ) : null}
        <Button
          color="primary"
          onClick={clearAllParameters}
          disabled={Object.keys(parameters).length == 0}
          className={classNames(classes.clearAllButton, 'ml-1')}
          data-testid="clearAllButton"
        >
          {t('common.actions.clearAll')}
        </Button>
      </div>
    );
  };

  const getShowLabelChips = () => {
    const chips = [];
    for (const key in parameters) {
      const param = parameters[key];
      if (param?.showSelectedLabel === true) {
        chips.push(
          param.values.map((val, index) => (
            <Chip
              key={`chip-${key}-${index}`}
              label={val.label}
              onDelete={() => {
                clearSingleParameter(key, index);
              }}
              deleteIcon={<ClearIcon className={classes.selectedChipIcon} />}
              className={classes.selectedChip}
              data-testid="selectedChip"
            />
          )),
        );
      }
    }
    return chips.length > 0 ? <div className="mt-1">{chips}</div> : null;
  };

  return (
    <div className="mb-1">
      {getInlineConditionComponents()}
      {getShowLabelChips()}
    </div>
  );
};

export default AdvancedFilter;
