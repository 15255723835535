import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

import { getSingleApplication } from '../../../../../api/application';
import { IsModifiedProps, useChangeDetection } from '../../../../../helpers/hooks/useChangeDetection';
import { RootReducer } from '../../../../../redux/rootReducer';
import { handleRequestFail } from '../../../../../helpers/request-fail-handler';
import { ApplicationInfoRecord } from '../../../application/types';
import {
  ApplicationFlowState,
  setReviewSectionEditState,
  updateApplicationProperty,
} from '../../../../../redux/modules/application-flow';
import { DownloadPreviewCertificateButton } from './partials/review-step/DownloadPreviewCertificateButton';
import { CircularLoader } from '../../../../../shared-components/loader/CircularLoader';
import { jsonCopy } from '../../../../../helpers';
import { ProductSection } from './partials/review-step/ProductSection';
import { VariantSection } from './partials/review-step/VariantSection';
import { WiFiComponentSection } from './partials/review-step/WiFiComponentSection';

interface ReviewStepsProps {
  onStepChange: (step: number) => void;
}

const calculateHashValue = (values: { [key: string]: boolean }) => JSON.stringify(jsonCopy(values));

const modifiedSectionsInitialValue = {
  product: false,
  variant: false,
};

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;

  > span:first-of-type {
    padding-right: 20px;
  }
`;

export const ReviewStep = ({ setIsModified, onStepChange }: ReviewStepsProps & IsModifiedProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const reduxData = useSelector<RootReducer, ApplicationFlowState>(state => state.applicationFlow);

  const [loading, setLoading] = useState(true);
  const [applicationRecord, setApplicationRecord] = useState<ApplicationInfoRecord>({} as ApplicationInfoRecord);
  const [modifiedSections, setModifiedSections] = useState(modifiedSectionsInitialValue);
  /*const updateChangeDetectionState = */ useChangeDetection(setIsModified, calculateHashValue, modifiedSections);

  const isModified = Object.values(modifiedSections).some(val => val);

  const updateModifiedSections = (section: string) => (value: boolean) => {
    setModifiedSections(prevState => ({ ...prevState, [section]: value }));
  };

  const formId = Number(reduxData.formId);
  const variantId = formId;

  useEffect(() => {
    // clear setting
    dispatch(
      setReviewSectionEditState({
        productInfo: false,
        variantInfo: false,
      }),
    );
    getSingleApplication(formId)
      .then((response: AxiosResponse<ApplicationInfoRecord>) => {
        setApplicationRecord(response.data);
        dispatch(
          updateApplicationProperty(
            response.data.productInfo.modelVariants[0].productType as string,
            'productInfo.modelVariants[0].productType',
          ),
        );
        setLoading(false);
      })
      .catch((error: AxiosError<{ message: string }>) => {
        handleRequestFail(error, addToast);
        //history.push(getStoredSummaryUrl('application'));
        setLoading(false);
        throw error;
      });
  }, []);

  if (loading) {
    return (
      <section style={{ height: '200px' }}>
        <CircularLoader fullPage content={t('common.placeholders.loadingData')} />
      </section>
    );
  }

  return (
    <>
      <SubHeader>
        <span>{t('applications.review.description')}</span>
        <span>
          <DownloadPreviewCertificateButton
            variantId={variantId}
            token={applicationRecord.previewCertificateToken}
            certified={
              reduxData.productInfo.certified === true || reduxData.productInfo.modelVariants[0].draftCertified === true
            }
            publicVariant={reduxData.productInfo.modelVariants[0].searchableByPublic === true}
          />
        </span>
      </SubHeader>
      <ProductSection setIsModified={updateModifiedSections('product')} />
      <VariantSection setIsModified={updateModifiedSections('variant')} />
      <WiFiComponentSection
        onStepChange={onStepChange}
        isOtherSectionModified={isModified}
        certifications={applicationRecord.certifications}
      />
      <div style={{ display: 'flex', marginTop: 32, marginBottom: 32, justifyContent: 'flex-end' }}>
        <DownloadPreviewCertificateButton
          variantId={variantId}
          token={applicationRecord.previewCertificateToken}
          certified={
            reduxData.productInfo.certified === true || reduxData.productInfo.modelVariants[0].draftCertified === true
          }
          publicVariant={reduxData.productInfo.modelVariants[0].searchableByPublic === true}
          upside={true}
        />
      </div>
    </>
  );
};
