import * as actionTypes from './actionTypes';
import { APP_STATUS } from './reducer';

export function appFinishLoading() {
  return {
    type: actionTypes.APP_LOADED,
  };
}

export function setAppHealth(status: APP_STATUS) {
  return {
    type: actionTypes.SET_APP_HEALTH,
    payload: {
      status,
      lastUpdated: Date.now(),
    },
  };
}

export function setHealthLastChecked() {
  return {
    type: actionTypes.SET_HEALTH_LAST_CHECKED,
    payload: Date.now(),
  };
}

export function setUnsavedChanges(url: string, isUnsaved = true) {
  return {
    type: actionTypes.SET_UNSAVED_CHANGES,
    payload: { url, isUnsaved },
  };
}
