import React, { FocusEvent, useEffect } from 'react';
import { roleNameMapping } from '../../../../data/constants';
import { APLikeRoleItem, EasyMeshRoleItem, HomeDesignRoleItem } from './';
import { AccessPointLikeRoleItem, EMCRoleItem, HDRoleItem, RoleItemBaseProps } from './types';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppFlows,
  AppFlowSteps,
  ApplicationFlowState,
  setCompareCCInfo,
  setShowPopup,
  removeCCRole,
  updateApplicationProperty,
} from '../../../../../../../redux/modules/application-flow';
import { ComponentCombination } from '../../../../../../../api/application/types';
import { Confirm } from '../../../../../../../helpers/confirmationPopup/Confirm';
import { useTranslation } from 'react-i18next';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { MemberClearData } from '../../../../../../../redux/modules/application-flow/data';
import { showAddNoteModal, toggleUpdateNotes } from '../../../../../../../redux/modules/modals';
import { NoteActionTypes } from '../../../../../../../redux/modules/modals/constants';
import { reformatCC } from '../../../../data/formatters';
import { dropCCRole } from '../../../../../../../redux/modules/application-flow/helpers';
import { selectComponentCombinationVariantId } from '../../../../../../../redux/modules/application-flow/selectors';

export const RoleItemComponent = ({
  roleItem,
  prefix,
  ccLength,
  saveData,
  handleSetInitialValues,
}: RoleItemBaseProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { compareCCInfo, errors, showPopup, certifications, id, componentCombination, ...application } = useSelector<
    RootReducer,
    ApplicationFlowState
  >(state => state.applicationFlow);
  const componentCombinationVariantId = useSelector(selectComponentCombinationVariantId);

  const { roleName, index } = roleItem;

  const { showVariantWarning } = compareCCInfo;

  const myStateRef = React.useRef(showVariantWarning);
  const needToShowPopUp = React.useRef(showPopup && certifications.length > 0);
  const componentCombinationVariantIdRef = React.useRef(componentCombinationVariantId);

  useEffect(() => {
    componentCombinationVariantIdRef.current = componentCombinationVariantId;
  }, [componentCombinationVariantId]);

  const showVariantWarnings = (name: string) => {
    if (name && name.match(/(chipset|driver|firmware)/gi)) {
      const splittedName = name.split('.');
      const fieldName = splittedName[splittedName.length - 1];
      return Confirm({
        title: t('applications.cc.variantFlow.title', {
          addition: `${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}`,
        }),
        yesText: t('applications.cc.warning.btn.gotIt'),
        cancelable: false,
        message: t(`applications.cc.variantFlow.${fieldName}`),
        onAccept: () => dispatch(setCompareCCInfo({ showVariantWarning: myStateRef.current })),
      });
    }
  };

  const formErrors = Object.keys(errors).reduce((object, key) => {
    if (key.includes(prefix)) {
      object[key] = errors[key];
    }
    return object;
  }, Object.create(null));

  const handleChange = (value: string, name: string) => {
    dispatch(
      updateApplicationProperty(
        value,
        name,
        certifications.length > 0 ? MemberClearData.CERTIFICATIONS : MemberClearData.NONE,
        AppFlowSteps.CC,
      ),
    );
  };

  const removeRole = () => {
    dispatch(removeCCRole(roleItem.roleName as keyof ComponentCombination, roleItem.index));
  };

  const handleRemove = () => {
    dispatch(
      showAddNoteModal(
        {
          appId: id,
          onSuccess: () => {
            removeRole();
            dispatch(toggleUpdateNotes(true));
            handleSetInitialValues(reformatCC(dropCCRole(componentCombination.componentCombination, roleName, index)));
            saveData && saveData(reformatCC(dropCCRole(componentCombination.componentCombination, roleName, index)));
          },
        },
        NoteActionTypes.REMOVE_CC,
      ),
    );
  };

  const handleFocus = ({ target }: FocusEvent<HTMLInputElement>) => {
    if (application['@type'] === AppFlows.VARIANT && !myStateRef.current.includes(target.name)) {
      myStateRef.current = [...showVariantWarning, target.name];
      return showVariantWarnings(target.name);
    }

    if (
      application['@type'] === AppFlows.NEW &&
      componentCombinationVariantIdRef.current &&
      !myStateRef.current.includes(target.name)
    ) {
      myStateRef.current = [...showVariantWarning, target.name];
      return showVariantWarnings(target.name);
    }

    if (needToShowPopUp.current) {
      return Confirm({
        yesText: t('applications.cc.warning.btn.gotIt'),
        cancelable: false,
        title: t('applications.cc.warning.title'),
        message: t('applications.cc.warning.message', {
          addition: t('applications.cc.warning.additions.certsAndLabs'),
        }),
        onAccept: () => {
          needToShowPopUp.current = false;
          dispatch(setShowPopup(false));
        },
      });
    }
  };

  if (roleName !== 'newRoles') {
    if (roleName === roleNameMapping.easyMeshController) {
      return (
        <EasyMeshRoleItem
          key={roleName}
          roleItem={roleItem as EMCRoleItem}
          prefix={prefix}
          onChange={handleChange}
          onRemove={handleRemove}
          onFocus={handleFocus}
          ccLength={ccLength}
          showPopUp={needToShowPopUp}
          errors={formErrors}
        />
      );
    }
    if (roleName === roleNameMapping.homeDesign) {
      return (
        <HomeDesignRoleItem
          roleItem={roleItem as HDRoleItem}
          prefix={prefix}
          onChange={handleChange}
          onRemove={handleRemove}
          onFocus={handleFocus}
          ccLength={ccLength}
          showPopUp={needToShowPopUp}
          errors={formErrors}
        />
      );
    }

    return (
      <APLikeRoleItem
        key={roleName}
        roleItem={roleItem as AccessPointLikeRoleItem}
        prefix={prefix}
        onChange={handleChange}
        onRemove={handleRemove}
        onFocus={handleFocus}
        ccLength={ccLength}
        showPopUp={needToShowPopUp}
        errors={formErrors}
      />
    );
  }
  return null;
};
