import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { login, LoginForm } from '../../../api/login';
import { useToasts } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { loadUser } from '../../../redux/modules/user';
import { RouteComponentProps } from 'react-router';
import Cookie from 'js-cookie';
import { Button, Form } from 'rsuite';
import { RsField } from '../../../shared-components/rsuite';
import { IterableObject } from '../../../helpers/types';
import { prepareYupModel } from '../../../helpers';

const PageWrapper = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8e8e8;
`;

const FormWrapper = styled.div`
  max-width: 350px;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
`;

const PageComponent = (props: { loadUser: () => void } & RouteComponentProps) => {
  const { addToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginError, setLoginError] = useState('');
  const initialValues: LoginForm = { login: '', password: '' };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState<IterableObject<string>>();
  const validationSchema = Yup.object().shape({
    login: Yup.string()
      .trim()
      .email('Must be a valid email address')
      .required('Field is required'),
    password: Yup.string()
      .trim()
      .required('Field is required'),
  });

  const changeField = (value: string, name: string) => {
    setLoginError('');
    setFormValues(prevState => {
      const newState = { ...prevState, [name]: value };
      const error = prepareYupModel<LoginForm>(validationSchema).checkForFieldFormatted(name, newState);
      setFormErrors(state => ({ ...state, ...error }));
      return newState;
    });
  };

  const onLoginChange = (login: string) => changeField(login, 'login');

  const onPasswordChange = (password: string) => changeField(password, 'password');

  const handleSubmit = () => {
    const errors = prepareYupModel<LoginForm>(validationSchema).checkFormatted(formValues);
    if (errors) {
      return setFormErrors(errors as IterableObject<string>);
    }
    return login(formValues)
      .then(response => {
        setFormValues(initialValues);
        Cookie.set('access-token', response.data.jwt as string);
        props.loadUser();
        setIsSubmitting(false);
        props.history.push('/');
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.details) {
          setLoginError(error.response.data.details);
        } else {
          addToast(`Login Failed: ${error.message}`, {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        }
        setIsSubmitting(false);
      });
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e): void => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  useEffect(() => Cookie.remove('access-token'), []);

  return (
    <PageWrapper>
      <FormWrapper>
        <h4 className="text-center mb-1">Login</h4>
        <Form>
          {loginError ? (
            <p className="m-0 text-danger">
              <small>{loginError}</small>
            </p>
          ) : null}
          <RsField
            noMargin
            onChange={onLoginChange}
            onKeyDown={onKeyDown}
            errors={formErrors}
            name="login"
            placeholder="Login"
          />
          <RsField
            noMargin
            onChange={onPasswordChange}
            onKeyDown={onKeyDown}
            type="password"
            errors={formErrors}
            name="password"
            placeholder="Password"
          />
          <div className="text-right">
            <Button onClick={handleSubmit} appearance="primary" loading={isSubmitting} className="submit-button">
              Login
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </PageWrapper>
  );
};

export const DevLoginPage = connect(null, { loadUser })(PageComponent);
