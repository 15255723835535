import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SummaryTable } from './partials/SummaryTable';
import { getSummaryPageComponentByObject } from '../../hoc/get-summary-page-component';
import { CreateNewCertificationButton } from './partials/CreateNewCertificationButton';
import { getCertificationSummary } from '../../../api/certification';
import { certificationStatuses, certificationStatusTitles } from '../../../helpers/constants/certification-statuses';
import { CertificationSummaryRecord } from './types';
import { downloadBundleHandler } from '../../../api/certification/helper/downloadBundleHandler';
import { useToasts } from 'react-toast-notifications';
import { DownloadBundleButton } from '../../../shared-components/button/DownloadBundleButton';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';

const DownloadBundleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SummaryPage = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [downloadingBundle, setDownloadingBundle] = useState(false);

  const SummaryPageComponent = useMemo(
    () =>
      getSummaryPageComponentByObject<CertificationSummaryRecord>({
        title: t('certifications.pageTitle'),
        apiCall: getCertificationSummary,
        object: certificationStatuses,
        titles: certificationStatusTitles,
        SummaryTable,
        pageRightAction: <CreateNewCertificationButton />,
        defaultSorting: { sortColumn: 'date', sortType: 'desc' },
        rightContentTab: (
          <DownloadBundleWrapper>
            Tool certifications bundle
            <DownloadBundleButton
              className="ml-1"
              onClick={() => downloadBundleHandler(addToast, setDownloadingBundle)}
            />
          </DownloadBundleWrapper>
        ),
        useAdvancedFilters: true,
        isCertificationSummary: true,
      }),
    [],
  );

  return (
    <>
      {downloadingBundle && <CircularLoader fullPage backdrop content={t('common.placeholders.loadingData')} />}
      <SummaryPageComponent />
    </>
  );
};
export const CertificationSummaryPage = React.memo(SummaryPage);
