import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { AppFlowSteps, setCompareCCInfo, updateApplicationProperty } from '../../../redux/modules/application-flow';
import { StepPageProps, ApplicationInfoRecord } from './types';
import { AxiosResponse } from 'axios';
import { mergeObjects } from '../../../helpers';
import { getSingleProduct } from '../../../api/product';
import { initialState, MemberClearData } from '../../../redux/modules/application-flow/data';
import { useChangeWrapper } from '../../../helpers/storage/formik-change-wrapper';
import { BasicProductRecord } from '../product/types';
import { NewApplicationFlowPage } from './NewApplicationFlowPage';
import { ComponentCombination, ProductInfoStep, FormCertification } from '../../../api/application/types';
import { IsModifiedProps } from '../../../helpers/hooks/useChangeDetection';
import { getVariantInfoById } from '../../../api/product';

export const PageComponent = ({
  match,
  step,
  onNext,
  ...props
}: RouteComponentProps<{ formId: string }> & StepPageProps & IsModifiedProps & { step: number }) => {
  const dispatch = useDispatch();
  const { getForm } = useChangeWrapper(match.params.formId);

  const [initialProductInfo, setInitialProductInfo] = useState<ProductInfoStep>(initialState.productInfo);
  const [initialComponentCombination, setComponentCombination] = useState<ComponentCombination>(
    initialState.componentCombination.componentCombination,
  );

  useEffect(() => {
    const storageData = getForm();
    if (!storageData.id) {
      const { componentCombination } = storageData;
      const isCCPresent =
        componentCombination &&
        Object.values(componentCombination).some(item => Array.isArray(item) && item.length !== 0);
      if (!componentCombination || !isCCPresent) {
        // get CC
        getVariantInfoById(storageData.companyContactInfo.source.id).then(
          (response: AxiosResponse<ApplicationInfoRecord & { certifications: FormCertification[] }>) => {
            const dataCC = response.data;
            const updatedCC = mergeObjects(initialState.componentCombination, dataCC.componentCombination);
            dispatch(
              updateApplicationProperty(updatedCC, 'componentCombination', MemberClearData.NONE, AppFlowSteps.PRODUCT),
            );
            dispatch(
              setCompareCCInfo({
                initialCC: updatedCC.componentCombination,
              }),
            );
            setComponentCombination(updatedCC.componentCombination);

            if (!storageData.productInfo.id) {
              // get PRODUCT INFO
              getSingleProduct(storageData.companyContactInfo.source.productId).then(
                ({ data }: AxiosResponse<BasicProductRecord>) => {
                  const updated = mergeObjects(initialState.productInfo, {
                    id: data.id,
                    cid: data.cid,
                    additionalCategories: data.additionalCategories,
                    modelNumber: data.modelNumber,
                    primaryCategory: data.primaryCategory,
                    description: data.description,
                    image: data.image,
                    name: data.name,
                    documents: data.documents || [],
                    url: data.url || '',
                    qualifiedSolution: data.qualifiedSolution,
                    certified: data.certified,
                    modelVariants: [
                      {
                        productType: dataCC.componentCombination.componentCombinationVariantSource?.productType,
                        draftCertified: false,
                      },
                    ],
                  });

                  dispatch(
                    updateApplicationProperty(updated, 'productInfo', MemberClearData.NONE, AppFlowSteps.PRODUCT),
                  );
                  setInitialProductInfo(updated);
                },
              );
            }
          },
        );
      }
    }
  }, []);

  return (
    <NewApplicationFlowPage
      onNext={onNext}
      step={step}
      initialProductInfo={initialProductInfo}
      initialComponentCombination={initialComponentCombination}
      {...props}
    />
  );
};

export const VariantApplicationFlowPage = withRouter(PageComponent);
