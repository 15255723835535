/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'rsuite';
import { FormControlProps } from 'rsuite/lib/FormControl/FormControl';
import styled from 'styled-components';
import classNames from 'classnames';
import dot from 'dot-object';
// @ts-ignore
import _scss from '../../styles/_variables.scss';

const ErrorText = styled.small`
  position: absolute;
  bottom: 4px;
  left: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  &:hover {
    overflow: unset;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  padding-bottom: 1.2rem;
  position: relative;
  z-index: 1;

  &.has-error .rs-radio:not(.rs-radio-disabled) .rs-radio-wrapper .rs-radio-inner::before {
    border: 1px solid ${_scss.errorRed};
  }
`;

export interface RsFieldProps extends FormControlProps<any> {
  customErrorMessage?: string;
  required?: boolean;
  noMargin?: boolean;
  noPadding?: boolean;
  boldTitle?: boolean;
  fullWidth?: boolean;
}

export const RsField = ({
  name,
  label,
  customErrorMessage,
  accepter,
  errors,
  style,
  boldTitle = false,
  required = false,
  noMargin = false,
  noPadding = false,
  fullWidth = false,
  className,
  ...props
}: RsFieldProps) => {
  const error = errors ? dot.pick(name as string, errors) || errors[name as string] || null : null;
  return (
    <StyledFormGroup
      className={classNames(className, { 'has-error': Boolean(error), 'm-0': noMargin, 'p-0': noPadding })}
      style={style}
    >
      {label ? (
        <ControlLabel className={classNames({ 'text-bold': boldTitle })}>
          {required ? <span className={classNames('text-danger')}>*</span> : null} {label}
        </ControlLabel>
      ) : null}
      <FormControl
        name={name}
        accepter={accepter}
        errorMessage={null}
        style={fullWidth ? { width: '100%' } : {}}
        {...props}
      />
      {customErrorMessage || error ? (
        <ErrorText className={classNames('text-danger')}>{customErrorMessage || error}</ErrorText>
      ) : null}
    </StyledFormGroup>
  );
};
