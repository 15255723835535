import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip';
import { HelpOutlineSharp } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
// @ts-ignore
import _var from '../../styles/_variables.scss';

const StyledTooltip = withStyles<'tooltip' | 'arrow', {}, { width?: number }>({
  tooltip: {
    color: _var.textGray,
    backgroundColor: _var.white,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
    padding: 12,
    fontSize: 12,
    maxWidth: props => props.width ?? 280,
  },
  arrow: {
    color: _var.white,
  },
})(MuiTooltip);

export interface TooltipProps extends Omit<MuiTooltipProps, 'children' | 'title'> {
  width?: number;
  translationKey?: string;
  children?: ReactElement;
  content?: NonNullable<React.ReactNode>;
}

export const Tooltip = (props: TooltipProps) => {
  const {
    width,
    content,
    children,
    arrow = true,
    translationKey,
    leaveDelay = 200,
    interactive = true,
    placement = 'top-start',
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <StyledTooltip
      {...rest}
      width={width}
      arrow={arrow}
      placement={placement}
      leaveDelay={leaveDelay}
      interactive={interactive}
      title={translationKey ? (t(translationKey) as string) : content ?? ''}
    >
      {children || (
        <HelpOutlineSharp style={{ margin: '0 0.5rem', fontSize: '1.33333em', top: '3px', position: 'relative' }} />
      )}
    </StyledTooltip>
  );
};
