import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaymentStep, ProductModelStep, ReviewStep } from './partials/new-application-flow-steps';
import { useDispatch, useSelector } from 'react-redux';
import { AppFlowSteps, ApplicationFlowState, updateApplicationProperty } from '../../../redux/modules/application-flow';
import { Button, FlexboxGrid } from 'rsuite';
import { ApplicationSummarySideBar, NewApplicationSteps } from './partials';
import { useTranslation } from 'react-i18next';
import { PageTemplate } from '../../partials';
import { ApplicationInfoRecord, StepPageProps } from './types';
import { AxiosResponse } from 'axios';
import { FormCertification } from '../../../api/application/types';
import { RootReducer } from '../../../redux/rootReducer';
import { confirmAction, getStoredSummaryUrl, mergeObjects } from '../../../helpers';
import { getVariantInfoById } from '../../../api/product';
import { initialState, MemberClearData } from '../../../redux/modules/application-flow/data';
import { IsModifiedProps, usePageChangeDetection } from '../../../helpers/hooks/useChangeDetection';
import classNames from 'classnames';
import { BackToListButton } from '../../../shared-components/button/BackToListButton';

export const PageComponent = ({
  history,
  step,
  onPrevious,
  onStepChange,
  submitting,
  saving,
  onNext,
  setIsModified,
  isModified,
  stepTitles,
}: RouteComponentProps<{ formId: string }> &
  StepPageProps &
  IsModifiedProps & { step: number; stepTitles: Array<string> }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reduxData = useSelector<RootReducer, ApplicationFlowState>(state => state.applicationFlow);

  const [loading, setLoading] = useState(false);
  const { resetChangeDetection } = usePageChangeDetection(value => JSON.stringify(value), isModified);

  const onBackToList = () =>
    confirmAction(
      () => !!isModified,
      () => {
        resetChangeDetection();
        history.push(getStoredSummaryUrl('application'));
      },
    );

  const getStepComponent = () => {
    switch (step) {
      case 1:
        return <ProductModelStep setIsModified={setIsModified} setLoading={setLoading} />;
      case 2:
        return <ReviewStep onStepChange={onStepChange} setIsModified={setIsModified} />;
      case 3:
        return <PaymentStep setIsModified={setIsModified} setLoading={setLoading} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (reduxData.companyContactInfo.source && !reduxData.certifications.length) {
      getVariantInfoById(reduxData.companyContactInfo.source.id).then(
        ({ data }: AxiosResponse<ApplicationInfoRecord & { certifications: FormCertification[] }>) => {
          dispatch(
            updateApplicationProperty(
              data.certifications,
              'certifications',
              MemberClearData.NONE,
              AppFlowSteps.PRODUCT,
            ),
          );
          dispatch(
            updateApplicationProperty(
              {
                ...data.componentCombination,
                componentCombination: mergeObjects(
                  initialState.componentCombination.componentCombination,
                  data.componentCombination.componentCombination,
                ),
              },
              'componentCombination',
              MemberClearData.NONE,
              AppFlowSteps.PRODUCT,
            ),
          );
        },
      );
    }
  }, []);

  return (
    <PageTemplate
      title="New Application"
      actionLeft={<BackToListButton onClick={onBackToList} className="btn-back-to-list" disabled={loading} />}
      footerActionLeft={<></>}
      actionRight={
        <div className="btn-wrapper">
          {step > 1 ? (
            <Button className="previous-step" onClick={onPrevious}>
              {t('common.navigation.previous')}
            </Button>
          ) : null}
          <Button
            appearance="primary"
            className={step !== 3 ? 'next-step' : 'submit'}
            loading={submitting}
            disabled={saving || loading}
            onClick={onNext}
          >
            {step === 3 ? t('common.navigation.submit') : t('common.navigation.next')}
          </Button>
        </div>
      }
      footerAddition={
        step === 1 && (
          <span className="text-muted pr-1">
            <span className="text-danger text-bold">* </span> {t('common.requiredFields')}
          </span>
        )
      }
    >
      <FlexboxGrid className="page-container" justify="space-between">
        <FlexboxGrid.Item colspan={24}>
          <NewApplicationSteps onClick={onStepChange} currentStep={step} stepTitles={stepTitles} />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={18}>
          <h5 className={classNames('mt-1', 'mb-2')}>{`${t(`applications.steps.step`, {
            number: step,
          })}${stepTitles[step - 1]}`}</h5>
          {getStepComponent()}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <ApplicationSummarySideBar />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </PageTemplate>
  );
};

export const DerivativeApplicationFlowPage = withRouter(PageComponent);
