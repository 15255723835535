import React from 'react';
import { Button } from 'rsuite';
import { useTranslation } from 'react-i18next';

interface DefaultFiltersButtonsProps {
  onApply: () => void;
  onClear: () => void;
}

const DefaultFiltersButtons = ({ onApply, onClear }: DefaultFiltersButtonsProps) => {
  const { t } = useTranslation();
  return (
    <div data-testid="defaultFiltersButtons">
      <Button appearance="link" onClick={onClear}>
        {t('common.actions.clearAll')}
      </Button>
      <Button appearance="primary" onMouseDown={onApply}>
        {t('common.actions.apply')}
      </Button>
    </div>
  );
};

export default DefaultFiltersButtons;
