import React from 'react';
import { ControlLabel } from 'rsuite';
import { ControlLabelProps } from 'rsuite/lib/ControlLabel';

export const RsControlLabel = ({ children, required, ...props }: ControlLabelProps) => {
  let internalChildren = children;
  if (required) {
    if (children && Array.isArray(children)) {
      internalChildren = children.map(child => (
        <>
          <span className="text-danger">*</span> {child}
        </>
      ));
    } else if (children) {
      internalChildren = (
        <>
          <span className="text-danger">*</span> {children}
        </>
      );
    }
  }
  return <ControlLabel {...props}>{internalChildren}</ControlLabel>;
};
