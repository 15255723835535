import React from 'react';
import { Icon, Radio } from 'rsuite';
import { StyledNegationInputWrapper } from './StyledComponents';

interface NegationInputProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

export const NegationInput = ({ value, onChange }: NegationInputProps) => {
  return (
    <StyledNegationInputWrapper inline appearance="picker" value={value} onChange={onChange}>
      <Radio value={false}>
        <Icon icon="check" />
      </Radio>
      <Radio value={true}>
        <Icon icon="close" />
      </Radio>
    </StyledNegationInputWrapper>
  );
};
