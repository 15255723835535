import { AnyAction } from 'redux';
import {
  Lab,
  LabCertification,
  LabSummaryCertificationByGroup,
  Qualification,
} from '../../../../components/pages/admin/atl-qualifications/types';
import { LabInfo } from '../../../../components/pages/lab-info/types';
import { GetSummaryWithFiltersOptions } from '../../../../helpers/types';
import * as actionTypes from './actionTypes';

export type ActionWithToast = AnyAction & { addToast: AddFn };

export function resetState() {
  return { type: actionTypes.RESET_STATE };
}

export function loadLabs(addToast: AddFn) {
  return { type: actionTypes.LOAD_LABS_LIST, addToast } as ActionWithToast;
}

export function setLabs(payload: Lab[]) {
  return { type: actionTypes.SET_LABS_LIST, payload };
}

export function loadLabInfo(labId: number | string, addToast: AddFn) {
  return { type: actionTypes.LOAD_LAB_INFO, payload: labId, addToast } as ActionWithToast;
}

export function setLabInfo(payload: LabInfo) {
  return { type: actionTypes.SET_LAB_INFO, payload };
}

export function loadAvailableCertifications(labId: number | string, addToast: AddFn) {
  return { type: actionTypes.LOAD_AVAILABLE_CERTIFICATIONS_LIST, payload: labId, addToast } as ActionWithToast;
}

export function setAvailableCertifications(payload: LabCertification[] = []) {
  return { type: actionTypes.SET_AVAILABLE_CERTIFICATIONS_LIST, payload };
}

export function showUpdateQualificationModal(certification: LabSummaryCertificationByGroup) {
  return { type: actionTypes.SHOW_UPDATE_QUALIFICATIONS_MODAL, payload: certification };
}

export function hideUpdateQualificationModal() {
  return { type: actionTypes.HIDE_UPDATE_QUALIFICATIONS_MODAL };
}

export function removeLabQualification(
  labId: number | string,
  certification: LabSummaryCertificationByGroup,
  addToast: AddFn,
  refreshSummary?: (options?: GetSummaryWithFiltersOptions) => void,
) {
  const versionIds = certification.version
    ? [certification.version.id]
    : certification.children.map(c => c.version?.id);
  return {
    type: actionTypes.REMOVE_LAB_QUALIFICATION,
    payload: { labId, versionIds },
    refreshSummary,
    addToast,
  } as ActionWithToast;
}

export function labQualificationRemoved() {
  return { type: actionTypes.LAB_QUALIFICATION_REMOVED };
}

export function updateLabQualifications(
  labId: number | string,
  certification: LabSummaryCertificationByGroup,
  interoperability: boolean,
  conformance: boolean,
  addToast: AddFn,
  refreshSummary?: (options?: GetSummaryWithFiltersOptions) => void,
) {
  return {
    type: actionTypes.UPDATE_LAB_QUALIFICATION,
    payload: { labId, certification, interoperability, conformance },
    refreshSummary,
    addToast,
  } as ActionWithToast;
}

export function addSelectedCertifications(data: Qualification[]) {
  return { type: actionTypes.ADD_LAB_CERTIFICATIONS, payload: data };
}

export function updateSelectedCertificationProperty(versionId: number, property: string, value: boolean) {
  return { type: actionTypes.UPDATE_SELECTED_CERTIFICATION_PROPERTY, payload: { versionId, property, value } };
}

export function removeSelectedCertification(versionIds: number[]) {
  return { type: actionTypes.REMOVE_SELECTED_CERTIFICATION, payload: versionIds };
}

export function publishSelectedCertification(labId: number[] | string[], addToast: AddFn, redirect: () => void) {
  return { type: actionTypes.PUBLISH_SELECTED_CERTIFICATIONS, payload: labId, addToast, redirect } as ActionWithToast;
}

export function selectedCertificationPublished() {
  return { type: actionTypes.SELECTED_CERTIFICATIONS_PUBLISHED };
}

export function publishSelectedCertificationError() {
  return { type: actionTypes.PUBLISH_SELECTED_CERTIFICATIONS_ERROR };
}
