import { call, put, takeLatest, all } from 'redux-saga/effects';
import i18next from 'i18next';

import { CHECK_REVOKE_STATUS } from './';
import { RevokeModal } from './reducer';
import { RevokeActionTypes } from './constants';
import * as actionTypes from './actionTypes';
import { Confirm } from '../../../helpers/confirmationPopup/Confirm';

export function* checkRevokeSaga({ payload }: { payload: Partial<RevokeModal> }) {
  try {
    yield call(
      payload.checkApi,
      payload.revokeModalType === RevokeActionTypes.REVOKE_PRODUCT ? payload.productId : payload.appId,
    );
    yield put({
      type: actionTypes.SET_REVOKE_MODAL_VALUE,
      payload,
    });
  } catch (error) {
    if (error.response.status === 404) {
      Confirm({
        title: i18next.t(
          `revokes.${
            payload.revokeModalType === RevokeActionTypes.REVOKE_PRODUCT
              ? 'revokeProduct'
              : payload.revokeModalType === RevokeActionTypes.REVOKE_APPLICATION
              ? 'revokeApplication'
              : 'uncertifyApplication'
          }.checkErrorTitle`,
        ),
        message: error.response.data.message,
        cancelable: false,
        yesText: i18next.t('revokes.fields.okay'),
      });
    }
  }
}

function* watchRevokeCheckSaga() {
  yield takeLatest(CHECK_REVOKE_STATUS, checkRevokeSaga);
}

export default function* watchMultipleRevokesSagas() {
  yield all([watchRevokeCheckSaga()]);
}
