import { CLASSIFICATIONS_LOADED } from './actionTypes';
import { AnyAction } from 'redux';
import { Classification } from '../../../components/pages/certification/types';

const initialState = {
  classifications: [],
};

export type ClassificationsState = Readonly<{
  classifications: Classification[];
}>;

export const classificationsReducer = (state: ClassificationsState = initialState, action: AnyAction) => {
  switch (action.type) {
    case CLASSIFICATIONS_LOADED:
      return {
        ...state,
        classifications: action.payload,
      };
    default:
      return state;
  }
};
