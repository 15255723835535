import React, { useState } from 'react';
import { Icon, IconButton } from 'rsuite';
import { Transaction } from '../types';
import ReplayIcon from '@material-ui/icons/Replay';
import { makeStyles } from '@material-ui/core/styles';
import TransactionViewDialog from './TransactionViewDialog';
import { resendSalesforceApiCall } from '../../../../../api/transactions/resend-salesforce-api-call';
import { getStoredSummaryUrl } from '../../../../../helpers';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router';
import { handleRequestFail } from '../../../../../helpers/request-fail-handler';

const useStyles = makeStyles({
  iconHolder: { paddingLeft: '0 !important', paddingTop: '10px !important', position: 'fixed' },
  buttonHolder: { width: '50%' },
});

const TransactionsActions = ({ row }: { row: Transaction }) => {
  const classes = useStyles();
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();

  const onView = () => setViewDialogOpen(!viewDialogOpen);
  const onResend = () => {
    resendSalesforceApiCall(row.eventId)
      .then(() => {
        addToast(`Event #${row.eventId} was successfully processed.`, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        history.go(getStoredSummaryUrl('admin/transactions'));
      })
      .catch(error => {
        handleRequestFail(error, addToast);
      });
  };
  return (
    <div className="action-icons align-items-center">
      <div className={classes.buttonHolder}>
        {row.showView && <IconButton onClick={onView} icon={<Icon icon="eye" />} appearance="link" />}
      </div>
      <div className={classes.buttonHolder}>
        {!row.processed && (
          <IconButton
            data-testid="resendButton"
            className={classes.iconHolder}
            onClick={onResend}
            icon={<ReplayIcon fontSize={'small'} />}
          />
        )}
      </div>
      <TransactionViewDialog open={viewDialogOpen} onClose={onView} row={row} />
    </div>
  );
};

export default TransactionsActions;
