import React, { useState } from 'react';
import { RowWithMargin } from '../../application/partials';
import { Col } from 'rsuite';
import { DetailsItem } from '../../../partials';
import { formatFee } from '../../../../helpers/billing';
import { opportunityDateFormat, reformatDateToFormat } from '../../../../helpers';
import { ExpandCollapseIcon } from '../../../../shared-components/theme';
import { Fee, GetFeeResponse } from '../../../partials/billing/types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { ApplicationInfoRecord } from '../../application/types';
import { Anchor } from '../../../../shared-components/styled-components';
import { salesForceUrl } from '../../../../config';

const useStyles = makeStyles({
  holder: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    cursor: 'pointer',
  },
});

const ReviewAppBillingSection = ({
  fee,
  applicationRecord,
}: {
  fee: GetFeeResponse;
  applicationRecord: ApplicationInfoRecord;
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const { fees, opportunity } = fee;
  const isMemberFee = (fee: Fee) => fee.feeType === 'SUBMITTER';

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {fees.map(fee => (
        <div key={fee.priceBookEntry.id} data-test-id="reviewAppBillingWrapper">
          <div className={classes.holder} onClick={onToggle}>
            <ExpandCollapseIcon isOpen={isOpen} />
            <strong style={{ marginLeft: '0.3rem' }}>
              {isMemberFee(fee) ? t('billing.member') : t('billing.atlCompany')}
            </strong>
          </div>
          {isOpen && (
            <div className="ml-2">
              <RowWithMargin gutter={20}>
                <Col xs={12}>
                  <DetailsItem
                    label={isMemberFee(fee) ? t('billing.membersFee') : t('billing.atlFee')}
                    value={formatFee(fee.priceBookEntry.price, fee.priceBookEntry.name)}
                  />
                </Col>
                <Col xs={12}>
                  <DetailsItem label={t('billing.requestingAccount')} value={opportunity?.owner.name} />
                </Col>
              </RowWithMargin>
              <RowWithMargin gutter={20}>
                <Col xs={12}>
                  <DetailsItem label={t('applications.payment.PONumber')} value={applicationRecord.additionalInfo.po} />
                </Col>
                <Col xs={12}>
                  <DetailsItem
                    label={t('applications.payment.CTIANumber')}
                    value={applicationRecord.additionalInfo.ctia}
                  />
                </Col>
              </RowWithMargin>
              <RowWithMargin gutter={20}>
                <Col xs={12}>
                  <DetailsItem
                    label={t('billing.opportunityId')}
                    value={
                      opportunity?.id ? (
                        <Anchor href={`${salesForceUrl}${opportunity.id}`} target="_blank">
                          {opportunity.id}
                        </Anchor>
                      ) : null
                    }
                  />
                </Col>
                <Col xs={12}>
                  <DetailsItem
                    label={t('billing.opportunityCreated')}
                    value={
                      opportunity?.created ? reformatDateToFormat(opportunity?.created, opportunityDateFormat) : null
                    }
                  />
                </Col>
              </RowWithMargin>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default ReviewAppBillingSection;
