import React from 'react';
import styled from 'styled-components';

import { WarningTooltip } from '../tooltip/WarningTooltip';
import { CertificationStatuses } from '../../helpers/constants/certification-statuses';
// @ts-ignore
import _var from '../../styles/_variables.scss';
import { TooltipProps } from '../tooltip/Tooltip';

const WarningWrapper = styled.div<{ width?: string; padding?: string; height?: string }>`
  background-color: ${_var.white};
  font-size: 14px;
  display: flex;
  color: ${_var.textGray};
  align-items: center;
  border: 1px solid #e8e8ed;
  border-left: 4px solid ${_var.errorRed};
  line-height: 20px;
  border-radius: 4px;
  width: ${props => props.width ?? '100%'};
  height: ${props => props.height ?? 'auto'};
  padding: ${props => props.padding ?? '14px 10px 14px 0px'};
`;

const WrapperContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const WarningContent = styled.div`
  color: ${_var.gray2};
  a {
    text-decoration: underline;
    color: ${_var.gray2};
  }
`;

interface WarningCardProps {
  status?: CertificationStatuses;
  content: React.ReactNode | string;
  padding?: string;
  width?: string;
  height?: string;
  onHoverTooltipText?: string;
  placementIcon?: TooltipProps['placement'];
}

export const WarningCard: React.FC<WarningCardProps> = ({
  status,
  content,
  padding,
  width,
  height,
  onHoverTooltipText,
  placementIcon,
}) => {
  return (
    <WarningWrapper padding={padding} width={width} height={height}>
      <WrapperContainer>
        <WarningTooltip
          variant="outlined"
          translationKey={onHoverTooltipText}
          placement={placementIcon}
          status={status ?? CertificationStatuses.THIRD_RETIREMENT_PHASE}
        />
        <WarningContent>{content}</WarningContent>
      </WrapperContainer>
    </WarningWrapper>
  );
};
