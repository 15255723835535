import React from 'react';
import ActionsColumn from './ActionsColumn';
import { TCActions, TCRecord } from '../types';
import { SummaryTableProps } from '../../../../helpers/types';
import { useTranslation } from 'react-i18next';
import { reformatDate } from '../../../../helpers';
import DataTable from '../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../shared-components/table/DataTableColumn';
import { WarningTooltip } from '../../../../shared-components/tooltip/WarningTooltip';
import { CertificationStatuses } from '../../../../helpers/constants/certification-statuses';

type TestCase = TCRecord & {
  id: number;
  capabilities: { versionId: number; name: string }[];
  hasDraftCapability: boolean;
};

export const SummaryTable = (props: SummaryTableProps<TCRecord>): React.ReactElement<string> => {
  const { data, loading, onChangeSorting, sorting, total, activePage, setActivePage } = props;
  const { t } = useTranslation();

  return (
    <DataTable<TestCase>
      sticky
      pagination
      data={data as TestCase[]}
      total={total}
      sorting={sorting}
      loading={loading}
      activePage={activePage}
      setActivePage={setActivePage}
      onChangeSorting={onChangeSorting}
      useEnvIdentifier={true}
    >
      <DataTableColumn field="id" label={t('testCases.id')} minWidth={80} sortable />
      <DataTableColumn field="name" label={t('testCases.name')} minWidth={200} width="35%" sortable wordWrap />
      <DataTableColumn
        field="description"
        label={t('testCases.description')}
        minWidth={200}
        width="35%"
        sortable
        wordWrap
      />
      <DataTableColumn field="capabilities" label={t('testCases.primaryCert')} minWidth={130} width="10%">
        {(row: TestCase) => {
          if (row.capabilities.length == 0) return null;
          return (
            <ul style={{ paddingInlineStart: 15 }}>
              {row.capabilities.map(c => (
                <li key={`cap-${row.id}-${c.versionId}`}>{c.name}</li>
              ))}
            </ul>
          );
        }}
      </DataTableColumn>
      <DataTableColumn field="hasDraftCapability" label={''} minWidth={80}>
        {(row: TestCase) => {
          if (row.hasDraftCapability === true)
            return (
              <WarningTooltip
                status={CertificationStatuses.FIRST_RETIREMENT_PHASE}
                variant="outlined"
                translationKey={'testCases.hasDraftCapability'}
              />
            );
        }}
      </DataTableColumn>
      <DataTableColumn field="createdAt" label={t('testCases.createdAt')} minWidth={130} sortable>
        {(row: TestCase) => reformatDate(row.createdAt)}
      </DataTableColumn>
      <DataTableColumn field="actions" label={t('common.actions.title')} minWidth={100}>
        {(row: TestCase & { actions: TCActions }) => <ActionsColumn record={row} />}
      </DataTableColumn>
    </DataTable>
  );
};
