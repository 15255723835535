import { AppCertification, AppCertPrereqs } from '../../../../redux/modules/application-flow';
import { GetCertificationsByCC } from '../../../../api/application/get-certifications-by-CC';

export const isPrimaryPreReq = (
  list: GetCertificationsByCC[],
  certifications: AppCertification[],
  id: number,
): AppCertPrereqs | null => {
  let preReqInfo: AppCertPrereqs | null = null;
  list.forEach(item => {
    const idx = item.versions.findIndex(
      el =>
        certifications.some(cert => cert.version.id === el.version.id) &&
        el.prerequisites.find(preReq => preReq.certification.id === id),
    );
    if (idx !== -1) {
      preReqInfo = item.versions[idx].prerequisites.find(preReq => preReq.certification.id === id) || null;
    }
  });
  return preReqInfo;
};

export const getFormattedCertifications = (
  list: GetCertificationsByCC[],
  certifications: AppCertification[],
): AppCertification[] => {
  // add pre-reqs from list items
  return certifications.map(item => {
    const cert = list.find(({ versions }) => versions.some(cert => cert.version.id === item.version.id));
    if (cert) {
      const version = cert.versions.find(el => el.version.id === item.version.id);
      const preReqInfo = isPrimaryPreReq(list, certifications, item.id);
      const allowedVersions = item.allowedVersions || preReqInfo?.allowedVersions;
      return {
        ...item,
        version: {
          ...item.version,
          prerequisites: version?.prerequisites || [],
        },
        allowedVersions,
        isPreReq: Boolean(preReqInfo),
        prequalified: cert.certification.prequalified,
        previouslySelected: cert.certification.previouslySelected,
      };
    }
    return item;
  });
};
