export enum Role {
  MEMBER = 'MEMBER',
  IMPLEMENTER = 'IMPLEMENTER',
  LAB = 'LAB',
}

export interface ImpersonateAutoCompleteItem {
  id: number;
  name: string;
}
