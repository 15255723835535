import { ReactNode } from 'react';

export const handleRequestSuccess = (message: string, addToast: (content: ReactNode, options?: Options) => void) => {
  addToast(message, {
    appearance: 'success',
    autoDismiss: true,
    // @ts-ignore
    autoDismissTimeout: 3000,
  });
};
