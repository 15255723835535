import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// @ts-ignore
import _var from '../../styles/_variables.scss';

interface DragDropListProps<T> {
  data: T[];
  setDataState: (reorderData: T[]) => void;
  renderRow: (rowData: T, rowIndex: number) => React.ReactNode;
  isStriped?: boolean;
}

const useStyles = makeStyles({
  striped: {
    background: _var.secondary,
  },
  hover: {
    '&:hover': {
      background: _var.secondaryLighten,
    },
  },
});

export default function DragDropList<T>({ data, setDataState, renderRow, isStriped = true }: DragDropListProps<T>) {
  const classes = useStyles();

  const reorder = (list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    // set data to parent component
    setDataState(reorder(data, result.source.index, result.destination.index));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {data.map((rowData, index) => (
              <Draggable key={`data${index}`} draggableId={`data${index}`} index={index}>
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classNames(isStriped && index % 2 !== 0 ? classes.striped : '', classes.hover)}
                  >
                    {renderRow(rowData, index)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
