import { OtherRolesProps } from '../../types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RolesState } from '../../../../../redux/modules/roles/reducer';
import { Role } from '../../../../../redux/modules/roles/types';
import { DetailsItem } from '../../../../partials';
import React from 'react';
import { Col } from 'rsuite';
import { RowWithMargin } from '../ApplicationDetails';
import { RfViewComponent } from './RfViewComponent';
import { WrapWithPublicIcon } from '../../../../../shared-components/helper-components/WrapWithPublicIcon';

const PartialView = ({
  isSubmitResult = false,
  record,
  showPublicIcon = false,
}: {
  isSubmitResult?: boolean;
  record: OtherRolesProps;
  isEditable?: boolean;
  showPublicIcon?: boolean;
}) => {
  const { t } = useTranslation();
  if (isSubmitResult) {
    return (
      <>
        <RowWithMargin gutter={20}>
          <Col xs={12}>
            <DetailsItem label={t('applications.cc.driver')} value={record.core.driver} className="mb-1" />
            <DetailsItem label="Supported">
              <RfViewComponent record={record} />
            </DetailsItem>
          </Col>
          <Col xs={12}>
            <DetailsItem label={t('applications.cc.rfComponents')} value={record.rfComponents} className="mb-1" />
            <DetailsItem label={t('applications.cc.antenna')} value={record.antenna} className="mb-1" />
          </Col>
        </RowWithMargin>
      </>
    );
  } else {
    return (
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem label={t('applications.cc.driver')} value={record.core.driver} className="mb-1" />
          <DetailsItem
            label={
              showPublicIcon ? (
                <WrapWithPublicIcon content={t('applications.cc.rfArchitecture')} />
              ) : (
                t('applications.cc.rfArchitecture')
              )
            }
          >
            <RfViewComponent record={record} />
          </DetailsItem>
        </Col>
        <Col xs={12}>
          <DetailsItem label={t('applications.cc.rfComponents')} value={record.rfComponents} className="mb-1" />
          <DetailsItem label={t('applications.cc.antenna')} value={record.antenna} className="mb-1" />
        </Col>
      </RowWithMargin>
    );
  }
};

export const DefaultRoles = ({
  record,
  roleProperty,
  isSubmitResult = false,
  isEditable = false,
  showPublicIcon = false,
}: {
  record: OtherRolesProps;
  roleProperty: string;
  isSubmitResult?: boolean;
  isEditable?: boolean;
  showPublicIcon?: boolean;
}) => {
  const { t } = useTranslation();
  const roles = useSelector<{ rolesReducer: RolesState }, Role[]>(state => state.rolesReducer.roles);
  const roleName = roles.find(item => item.propertyName === roleProperty)?.name;

  return (
    <div className="mb-2">
      <p className="mb-1">
        <strong>{t('applications.cc.role')}:</strong> {roleName}
      </p>
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem label={t('applications.cc.chipset')} value={record.core.chipset} />
        </Col>
        <Col xs={12}>
          <DetailsItem
            label={
              showPublicIcon ? (
                <WrapWithPublicIcon content={t('applications.cc.componentOS')} />
              ) : (
                t('applications.cc.componentOS')
              )
            }
            value={record.os}
          />
        </Col>
      </RowWithMargin>
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem
            label={
              showPublicIcon ? (
                <WrapWithPublicIcon content={t('applications.cc.firmware')} />
              ) : (
                t('applications.cc.firmware')
              )
            }
            value={record.firmware}
          />
        </Col>
        <Col xs={12}>
          <DetailsItem label={t('applications.cc.interface')} value={record.physicalInterface} />
        </Col>
      </RowWithMargin>
      <PartialView
        isSubmitResult={isSubmitResult}
        record={record}
        isEditable={isEditable}
        showPublicIcon={showPublicIcon}
      />
    </div>
  );
};
