import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import { getSingleTC } from '../../../api/test-case';
import { TCRecord } from './types';
import { TCDetails } from './partials';
import { ErrorCard } from '../../../shared-components';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';

export const TCDetailsPage = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState();
  const [tcItem, setTCItem] = useState();
  useEffect(() => {
    getSingleTC(match.params.id)
      .then((response: AxiosResponse<TCRecord>) => {
        setTCItem(response.data);
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        setLoadingError(error);
      });
  }, []);
  return (
    <>
      {loading ? <CircularLoader fullPage backdrop content={t('common.placeholders.loadingData')} /> : null}
      {loadingError ? <ErrorCard error={loadingError} /> : null}
      {tcItem ? <TCDetails item={tcItem} /> : null}
    </>
  );
};
export default withRouter(TCDetailsPage);
