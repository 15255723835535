import { Path } from '../types';
import { pathToString } from '../helpers';
import React from 'react';
import styled from 'styled-components';
import { StyledNodePlaceholderElement } from './StyledComponents';

const StyledRunElement2 = styled(StyledNodePlaceholderElement)`
  padding: 25px;
`;

export const NodePlaceholder = ({ path, targetPath }: { path: Path; targetPath?: Path }) => {
  const isSelected = targetPath && pathToString(path) === pathToString(targetPath);

  if (!isSelected) {
    return <span data-path={pathToString(path)} className="drop-placeholder" />;
  }

  return (
    <div data-path={pathToString(path)} className="drop-placeholder run-element-wrapper">
      <StyledRunElement2 className="run-element" />
    </div>
  );
};
