import { AnyAction } from 'redux';
import { jsonCopy, mergeObjects, prepareYupModel } from '../../../helpers';
import {
  LRInteroperabilityResults,
  LRRequestedTest,
  LabRequest,
  LRCertification,
} from '../../../components/pages/atl-testing/types';
import {
  LAB_REQUEST_LOADED,
  RESTORE_TESTING_DATA,
  SET_CONFORMANCE_RESULTS,
  SET_INTEROPERABILITY_RESULT,
  SET_INTEROPERABILITY_RESULT_FILE,
  SET_VALIDATION_ERRORS,
  UPDATE_EXPLANATION,
  UPDATE_LAB_REQUEST_PROPERTY,
} from './actionTypes';
import dot from 'dot-object';
import { testingExplanationScheme } from '../../../components/pages/application/data/validation-schemes';
import * as Yup from 'yup';
import { selectRequestedCertifications } from './selectors';
import { LRStatus } from '../../../helpers/constants';

export const initialState: LabRequest | null = null;

const validationModel = prepareYupModel(
  Yup.object().shape({
    testingException: testingExplanationScheme,
  }),
);

const getInteroperabilityTestTemplateResults = (labRequest: LabRequest) => {
  const certifications = selectRequestedCertifications({ testingFlow: labRequest });

  if ([LRStatus.PENDING_APPROVAL, LRStatus.APPROVED].includes(labRequest.status)) {
    return labRequest?.interoperabilityTestTemplateResults;
  }

  const result: LRInteroperabilityResults[] = [];
  certifications.forEach((certification: LRCertification) => {
    if (certification.uniqueTemplate && certification.interoperabilityTemplate) {
      const testResult = labRequest.interoperabilityTestTemplateResults.find(
        item => item.template.id === certification.interoperabilityTemplate?.id,
      );
      result.push({ template: certification.interoperabilityTemplate, result: testResult?.result ?? null });
    }
  });

  return result;
};

export const testingFlowReducer = (state: LabRequest | null = initialState, action: AnyAction) => {
  const newState = jsonCopy<LabRequest>(state as LabRequest);

  switch (action.type) {
    case LAB_REQUEST_LOADED:
      const labRequest = action.payload as LabRequest;
      const interoperabilityTestTemplateResults = getInteroperabilityTestTemplateResults(labRequest);
      return { ...labRequest, interoperabilityTestTemplateResults, errors: {} };
    case UPDATE_LAB_REQUEST_PROPERTY:
      const { name } = action.payload;
      if (newState && name) {
        dot.set(name, action.payload.value, newState);
        const errors = validationModel.checkForFieldFormatted(name, newState);
        return mergeObjects(newState, { errors });
      }

      return mergeObjects(newState, action.payload.value);
    case SET_INTEROPERABILITY_RESULT:
      const { parentId, id, value } = action.payload;

      if (parentId) {
        const index = (state as LabRequest).requestedTests.findIndex(item => item.certification.id === parentId);
        const subIndex = (state as LabRequest).requestedTests[index].capabilities.findIndex(
          subCert => subCert.certification.id === id,
        );
        if (index !== -1 && subIndex !== -1) {
          newState.requestedTests[index].capabilities[subIndex].testResult.interoperability = value;
        }
      } else {
        const index = (state as LabRequest).requestedTests.findIndex(item => item.certification.id === id);
        if (index !== -1) {
          newState.requestedTests[index].testResult.interoperability = value;
        }
      }

      return newState;
    case SET_CONFORMANCE_RESULTS: {
      newState.conformanceTestResult = action.payload;

      const { certificationResults } = action.payload as { certificationResults: LRRequestedTest[] };
      newState.requestedTests.forEach(cert => {
        const primary = certificationResults.find(item => item.certification.id === cert.certification.id);
        if (primary) {
          cert.testResult.conformance = primary.testResult.conformance;
          cert.capabilities.forEach(subCert => {
            const sub = primary.capabilities.find(item => item.certification.id === subCert.certification.id);
            if (sub) {
              subCert.testResult.conformance = sub.testResult.conformance;
            }
          });
        }
      });
      return newState;
    }
    case SET_INTEROPERABILITY_RESULT_FILE:
      if (newState) {
        const index = newState.interoperabilityTestTemplateResults.findIndex(
          item => item.template.id === action.payload.templateId,
        );
        if (index !== -1) {
          newState.interoperabilityTestTemplateResults[index].result = action.payload.resultFile;
        }
      }
      return newState;
    case UPDATE_EXPLANATION:
      return {
        ...state,
        errors: {},
        testingException: action.payload,
      };
    case SET_VALIDATION_ERRORS:
      return mergeObjects(newState || {}, { errors: action.payload });
    case RESTORE_TESTING_DATA:
      return jsonCopy(initialState);
    default:
      return state;
  }
};
