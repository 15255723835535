import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { MaterialAddButton } from '../../../../shared-components/button/MaterialAddButton';

export const CreateNewTCButton = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <MaterialAddButton
      appearance="primary"
      onClick={() => {
        history.push('/test-case/new');
      }}
      className="new-test-case"
    >
      {t('testCases.createTitle')}
    </MaterialAddButton>
  );
};
