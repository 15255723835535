import { UploadedFile } from '../../shared-components/file-upload/types';
import { EasyMeshController, HomeDesignProps, OtherRolesProps } from '../../components/pages/application/types';
import { IterableObject } from '../../helpers/types';

export interface ProductCategories {
  id: number;
  name?: string;
}

export enum DecisionType {
  CERTIFY = 'CERTIFIED',
  QUALIFY = 'QUALIFIED',
}

export interface MajorEvents {
  approved?: {
    createdAt: string | null;
    createdBy: string | null;
    email: string | null;
  } | null;
  submitted?: {
    createdAt: string | null;
    createdBy: string | null;
    email: string | null;
  } | null;
  firstSubmitted?: {
    createdAt: string | null;
    createdBy: string | null;
    email: string | null;
  } | null;
}

export interface ProductInfoStep {
  name: string;
  modelNumber: string;
  url: string | null;
  description: string;
  image: UploadedFile | null;
  primaryCategory: ProductCategories | null;
  additionalCategories: ProductCategories[];
  cid?: string;
  id?: number;
  modelVariants: AppModelVariant[];
  documents: UploadedFile[];
  certified: boolean | null;
  qualifiedSolution: boolean | null;
}

export interface AppModelVariant {
  id?: number;
  name: string;
  searchableByPublic: boolean | null;
  availableAsDerivative: boolean | null;
  availableAsQualifiedSolution: boolean | null;
  productType: string | undefined;
  draftCertified?: boolean | null;
}

export interface BasicCertification {
  id: number;
  name: string;
  checked?: boolean;
  roleName?: string;
  role?: string;
}

export interface FormCertification extends BasicCertification {
  versionName?: string;
  id: number;
  name: string;
  type?: string;
  capabilities: BasicCertification[];
  version?: number;
  testingDetails: {
    conformanceId?: number;
    interoperabilityId?: number;
  } | null;
}

export interface NewRoles {
  roleName: string;
  sortOrder?: number;
}

export interface WiFiComponents
  extends IterableObject<OtherRolesProps[] | EasyMeshController[] | HomeDesignProps[] | NewRoles[] | null> {
  accessPoint: OtherRolesProps[] | null;
  station: OtherRolesProps[] | null;
  configurator: OtherRolesProps[] | null;
  display: OtherRolesProps[] | null;
  source: OtherRolesProps[] | null;
  easyMeshController: EasyMeshController[] | null;
  homeDesign: HomeDesignProps[] | null;
  newRoles: NewRoles[];
}

export type ComponentCombination = WiFiComponents | null;

export interface PaymentDataForm {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  additionalInfo?: string;
  cardNumber?: string;
  expMonth?: string;
  expYear?: string;
  cvv?: string;
}
export interface PaymentData extends PaymentDataForm {
  type: string;
}

export type DerivativeType = null | { id: number | undefined; cid: string; productId: number };

export interface LoadCCCompany {
  name: string;
  id: number;
}

export interface LoadCCComponentProperties {
  qualifiedSolution: boolean;
  basedOnQualifiedSolution: boolean;
  other: boolean;
}

export interface LoadCCVariant {
  id: number;
  name: string;
  certified: boolean;
  componentProperties: LoadCCComponentProperties;
}

export interface LoadCCSourceProduct {
  id: number;
  cid: string;
  name: string;
  componentProperties: LoadCCComponentProperties;
  variants: LoadCCVariant[];
}
