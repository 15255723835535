import { GenericNodeType, NodeTypes, Path } from '../types';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { isAllowedToMove, stringToPath } from '../helpers';
import { useDrop } from 'react-dnd';
import { XYCoord } from 'dnd-core';

const MIN_DROP_OFFSET = 70;

type DropWrapperProps = PropsWithChildren<{
  element: GenericNodeType;
  onTargetChange: (path?: Path) => void;
  onDrop: (source: GenericNodeType) => void;
}>;

export const DroppableWrapper = ({ children, element, onDrop, onTargetChange }: DropWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [sourceOffset, setSourceOffset] = useState<number>();
  const [sourceNode, setSourceNode] = useState<GenericNodeType>();
  const [closestTargetPath, setClosestTargetPath] = useState<string>();

  // detect drag offset change
  useEffect(() => {
    if (!ref.current || sourceOffset === undefined || sourceNode === undefined) {
      return;
    }

    let minDistance = MIN_DROP_OFFSET; // doesn't allow to show a placeholder when dragging element is far away
    let closestPath: string | undefined = undefined;

    ref.current.querySelectorAll('.drop-placeholder').forEach(element => {
      const targetOffset = element.getBoundingClientRect().top;
      const distance = Math.round(Math.abs(sourceOffset - targetOffset));
      if (distance < minDistance) {
        minDistance = distance;
        closestPath = element.getAttribute('data-path') as string;
      }
    });

    setClosestTargetPath(closestPath);
  }, [sourceOffset]);

  // detect target change
  useEffect(() => {
    if (ref.current && sourceNode && sourceOffset !== undefined) {
      const targetPath = closestTargetPath ? stringToPath(closestTargetPath) : undefined;
      onTargetChange(targetPath && isAllowedToMove(sourceNode.path, targetPath, element) ? targetPath : undefined);
    }
  }, [closestTargetPath]);

  const [{}, connectDrop] = useDrop({
    accept: [NodeTypes.GROUP, NodeTypes.ELEMENT],
    drop: item => onDrop(item as GenericNodeType),
    hover(item, monitor) {
      const sourceOffset = (monitor.getClientOffset() as XYCoord).y;
      setSourceOffset(sourceOffset);
      setSourceNode(item as GenericNodeType);
    },
  });

  connectDrop(ref);

  // const style = { height: 700, overflowY: 'scroll', border: '1px solid', padding: '20px 0' } as CSSProperties;

  return <div ref={ref}>{children}</div>;
};
