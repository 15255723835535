import { Theme, makeStyles } from '@material-ui/core';

// @ts-ignore
import _var from '../../styles/_variables.scss';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      padding: theme.spacing(2.5),
      fontSize: '14px',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      marginTop: theme.spacing(3.75),
    },
    '& .MuiDialogContentText-root': {
      fontSize: '14px',
      color: _var.textGraySubtitle,
    },
    '& .MuiButton-root': {
      boxShadow: 'none',
      fontWeight: 'bold',
      fontSize: '14px',
      textTransform: 'none',
    },
    '& .MuiButton-containedPrimary': {
      color: _var.white,
      backgroundColor: _var.primary,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: _var.primary,
        opacity: 0.8,
      },
    },
    '& .Mui-disabled': {
      opacity: 0.27,
      backgroundColor: _var.black,
    },
    '& .MuiButton-containedSecondary': {
      color: _var.primary,
      backgroundColor: 'transparent',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: _var.lightGray,
        opacity: 0.8,
      },
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiButton-root': {
      color: _var.textLightGray,
      opacity: 0.6,
      transition: '0.2s',
      minWidth: 36,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: 0.8,
      },
    },
  },
  buttonClose: {
    color: _var.textLightGray,
    opacity: 0.6,
    transition: '0.2s',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      opacity: 0.8,
    },
  },
}));
