import { IconButton } from 'rsuite';
import React from 'react';

export const MaterialIconButton = props => {
  const disabled = props.disabled;
  const IconComponent = props.iconComponent;
  const style = props.style
    ? props.style
    : {
        position: 'absolute',
        top: 0,
        left: 0,
        fontSize: '14px',
        width: '36px',
        height: '36px',
        padding: '8px 0',
      };
  return (
    <IconButton icon={<IconComponent style={style} />} appearance="link" disabled={disabled}>
      {props.children}
    </IconButton>
  );
};
