import React, { forwardRef, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Button as MaterialButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Icon, Button, Divider } from 'rsuite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .rs-icon,
  h4 {
    display: inline-block;
  }

  .pointer {
    user-select: none;
    position: relative;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    editIcon: {
      margin: 0,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
);

interface Props {
  title: string;
  badge?: string | JSX.Element | boolean;
  isEdit?: boolean;
  showEditIcon?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  onEditIconClick?: () => void;
  isSaving?: boolean;
  rightActions?: ReactNode;
  disabledBtn?: boolean;
  hideDivider?: boolean;
  editIcon?: JSX.Element;
  titleComponent?: JSX.Element | null;
  onCollapse?: () => Promise<boolean | undefined>;
  hideEditIconWhenCollapse?: boolean;
  subTitle?: string;
  subEditIcon?: JSX.Element;
  onSubEditIconClick?: () => void;
}

export const CollapsableSection = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(function CollapsableSection(
  {
    children,
    title,
    badge,
    showEditIcon = false,
    isEdit = false,
    onCancel,
    onSave,
    onEditIconClick,
    isSaving = false,
    rightActions,
    disabledBtn,
    hideDivider,
    editIcon,
    titleComponent,
    onCollapse,
    hideEditIconWhenCollapse = false,
    subTitle,
    subEditIcon,
    onSubEditIconClick,
  }: PropsWithChildren<Props>,
  ref,
) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [opened, setOpened] = useState(true);

  const onClick = async () => {
    if (onCollapse && opened) {
      const acceptResult = await onCollapse();
      if (acceptResult === true || acceptResult === undefined) setOpened(!opened);
    } else {
      setOpened(!opened);
    }
  };

  useEffect(() => {
    if (!opened && isEdit) {
      setOpened(true);
    }
  }, [isEdit]);
  return (
    <Wrapper className="mt-1" ref={ref}>
      {hideDivider === true ? null : <Divider style={{ marginBottom: '12px' }} />}
      <div className="header">
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <span className="pointer" onClick={onClick}>
            <Icon icon={opened ? 'minus-square-o' : 'plus-square-o'} size="lg" className="pr-1" />
            <h4>{title}</h4>
          </span>
          {titleComponent ? titleComponent : null}
          <span className={titleComponent ? '' : 'pl-1'}>
            {hideEditIconWhenCollapse && !opened ? null : showEditIcon && !isEdit ? (
              editIcon ? (
                <MaterialButton className={classes.editIcon} onClick={onEditIconClick} disableRipple={true}>
                  {editIcon}
                </MaterialButton>
              ) : (
                <Icon icon="edit" className="pointer" onClick={onEditIconClick} />
              )
            ) : null}
          </span>
          {badge && <span className="pl-1">{badge}</span>}
        </div>
        <div>
          {isEdit ? (
            <div className="btn-wrapper">
              <Button className="cancel" onClick={onCancel} disabled={isSaving}>
                {t('common.navigation.cancel')}
              </Button>
              <Button appearance="primary" onClick={onSave} loading={isSaving} disabled={disabledBtn}>
                {t('common.navigation.save')}
              </Button>
            </div>
          ) : null}
          {rightActions}
        </div>
      </div>
      {subTitle ? (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <span className="pl-5">
            <h4>{subTitle}</h4>
          </span>
          <span className={'pl-1'}>
            {hideEditIconWhenCollapse && !opened ? null : showEditIcon && !isEdit ? (
              subEditIcon ? (
                <MaterialButton className={classes.editIcon} onClick={onSubEditIconClick} disableRipple={true}>
                  {subEditIcon}
                </MaterialButton>
              ) : (
                <Icon icon="edit" className="pointer" onClick={onSubEditIconClick} />
              )
            ) : null}
          </span>
          {badge && <span className="pl-1">{badge}</span>}
        </div>
      ) : null}
      {opened ? children : null}
    </Wrapper>
  );
});
