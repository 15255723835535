import React from 'react';
import { ARCHIVED } from '../../../test-case/data/constants';
import classNames from 'classnames';
import { tcStatusTitles } from '../../../../../helpers/constants/test-case-statuses';
import { ErrorObject } from '../../../../../helpers/types';
import { useTranslation } from 'react-i18next';

export const TCStatus = ({ status, index, errors }: { status: string; index?: number; errors?: ErrorObject }) => {
  const { t } = useTranslation();
  const errorKey = `testCriteria[${index}].test.status`;
  const error = errors && errors[errorKey];

  return (
    <>
      <p
        className={classNames({
          'text-warning': status === ARCHIVED,
        })}
      >
        [{tcStatusTitles[status]}]
      </p>
      {typeof error === 'string' ? (
        <p className="text-danger">
          <small>{error.replace(errorKey, t('certifications.conformanceTest.status'))}</small>
        </p>
      ) : null}
    </>
  );
};
