import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { BundleResponse } from '../../../components/pages/certification/types';
import { AxiosResponse } from 'axios';
import { downloadStringAsFile } from '../../../helpers/download-file-from-string';
import { downloadBundle } from '../download-bundle';
import { ReactNode } from 'react';

export const downloadBundleHandler = (
  addToast: (content: ReactNode, options?: Options) => void,
  setLoading: (v: boolean) => void,
) => {
  setLoading(true);
  return downloadBundle()
    .then((response: AxiosResponse<BundleResponse>) =>
      downloadStringAsFile(`bundle_${Date.now()}.json`, JSON.stringify(response.data), 'data:text/json'),
    )
    .catch(error => handleRequestFail(error, addToast))
    .finally(() => {
      setLoading(false);
    });
};
