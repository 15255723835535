import React from 'react';
import { RunCondition, RunConditionTypes } from '../../types';
import { useTranslation } from 'react-i18next';
import { ConditionLabel } from '../../../../../shared-components/condition-builder/ConditionLabel';
import { GenericNodeType } from '../../../../../shared-components/condition-builder/types';

export const RunConditionLabel = ({ condition }: { condition: RunCondition }) => {
  const { t } = useTranslation();

  return (
    <>
      {condition['@type'] === RunConditionTypes.ALWAYS_ON ? (
        t('certifications.testGroup.runConditionAlways')
      ) : (
        <ConditionLabel value={condition.raw as GenericNodeType} />
      )}
    </>
  );
};
