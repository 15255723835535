import React, { forwardRef, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import dot from 'dot-object';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import * as Yup from 'yup';

import { CollapsableSection } from './CollapsableSection';
import { BasicSaveResponse, CertificationType, CollapsedSectionProps, OnChangeType, VersionInfo } from '../types';
import { ErrorObject, ValidationError } from '../../../../helpers/types';
import { EditVersionSection, ViewVersionSection } from './version';
import { CertificationStatuses } from '../../../../helpers/constants/certification-statuses';
import { primaryCertVersionSchema, versionSchema } from '../validation-schemes';
import { confirmAction, jsonCopy, mergeObjects, prepareYupModel } from '../../../../helpers';
import { updateCertificationVersion } from '../../../../api/certification/partial-update/update-certification-version';
import { validate } from './helpers/validate';
import { RootReducer } from '../../../../redux/rootReducer';
import {
  CertificationDefinition,
  SectionEditState,
  setLoading,
  setSectionEditState,
  setValidationErrors,
  updateCertificationProperty,
} from '../../../../redux/modules/certification';
import { isErrorsNotEmpty, saveData } from './helpers';
import { formatErrors } from '../../../../helpers/request';
import { handleRequestSuccess } from '../../../../helpers/handle-request-success';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { IsModifiedProps, useChangeDetection } from '../../../../helpers/hooks/useChangeDetection';
import LifeCycleStatusBadge from './version/LifeCycleStatusBadge';

interface State {
  version: VersionInfo;
  type: CertificationType;
  status?: CertificationStatuses;
  versionId: number;
  sectionsEditState: SectionEditState;
  errors: ErrorObject;
  save: boolean;
}

const calculateHashValue = (values: VersionInfo) => {
  return JSON.stringify({
    version: values.version ?? '',
    revision: values.revision ?? '',
    ecn: values.ecn ?? '',
    certificationStart: values.certificationStart ?? '',
    publicLaunch: values.publicLaunch ?? '',
    firstRetirementPhaseDate: values.firstRetirementPhaseDate ?? '',
    secondRetirementPhaseDate: values.secondRetirementPhaseDate ?? '',
    thirdRetirementPhaseDate: values.thirdRetirementPhaseDate ?? '',
    changesSummary: values.changesSummary ?? '',
    labNotes: values.labNotes ?? '',
    memberNotes: values.memberNotes ?? '',
    visibleOnCertificate: values.visibleOnCertificate ?? '',
    profileType: values.profile.type ?? '',
  });
};

type VersionSectionProps = CollapsedSectionProps & Partial<IsModifiedProps>;

export const VersionSection = forwardRef<HTMLDivElement, VersionSectionProps>(function VersionSection(
  { isView = false, setIsModified: setIsSectionModified }: VersionSectionProps,
  ref,
) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const {
    version,
    type,
    status = CertificationStatuses.DRAFT,
    versionId,
    sectionsEditState,
    errors,
    save,
  } = useSelector<RootReducer, State>(
    ({ certificationReducer }) => ({
      version: certificationReducer.certification.version,
      type: certificationReducer.certification['@type'],
      status: certificationReducer.certification.status,
      versionId: certificationReducer.certification.versionId as number,
      sectionsEditState: certificationReducer.sectionsEditState,
      errors: certificationReducer.errors,
      save: certificationReducer.loadingState.save,
    }),
    shallowEqual,
  );
  const [state, setState] = useState({ version });
  const [errorsState, setErrorsState] = useState<ErrorObject>({});
  const [isModified, setIsModified] = useState(false);

  const setIsModifiedState = (value: boolean) => {
    setIsModified(value);
    setIsSectionModified && setIsSectionModified(value);
  };

  const emptyErrors = _.isEmpty(errorsState);
  const isEmptyValueOnError = Object.values(errorsState).some(err => err === '');
  const hasErrors = (!emptyErrors && !isEmptyValueOnError) || typeof errorsState === 'string';

  const setChangeDetectionInitialValue = useChangeDetection(setIsModifiedState, calculateHashValue, state.version);

  const validationSchema = Yup.object().shape({
    version: type === CertificationType.CAPABILITY ? versionSchema : primaryCertVersionSchema,
  });

  const onSave = () => {
    setErrorsState({});
    dispatch(setValidationErrors({}));
    if (validate<{ version: VersionInfo }>(validationSchema, state, setErrorsState)) {
      dispatch(setLoading({ save: true }));
      return updateCertificationVersion(versionId, state.version)
        .then(({ data: { version, ...dataToUpdate } }: AxiosResponse<{ version: VersionInfo } & BasicSaveResponse>) => {
          // @ts-ignore
          saveData('version', version as Partial<CertificationDefinition>, 'version', dataToUpdate);
          handleRequestSuccess(t('certifications.notifications.saved'), addToast);
          setIsModifiedState(false);
          setChangeDetectionInitialValue(state.version);
        })
        .catch((errors: AxiosError) => {
          handleRequestFail(errors, addToast);
          if (errors.response?.data.errors) {
            dispatch(setValidationErrors(formatErrors(errors.response?.data.errors as ValidationError[])));
          } else {
            dispatch(setLoading({ save: false }));
          }
        });
    }
  };
  const handleChange = (value: OnChangeType, name: string) => {
    if (isView) {
      setState(prevState => {
        const newState = jsonCopy(prevState);
        dot.set(name, value, newState);
        const errors = prepareYupModel(validationSchema).checkForFieldFormatted(name, newState);
        if (errors) {
          setErrorsState(prevErrors => {
            let prev = null;
            if (typeof prevErrors === 'string') {
              prev = { [name]: prevErrors };
            } else {
              prev = { ...prevErrors };
            }
            const newErrorState = mergeObjects(prev, { ...errors });
            return newErrorState;
          });
        }
        return newState;
      });
    } else {
      dispatch(updateCertificationProperty(value, name));
    }
  };

  const handleChangeDate = (value: MaterialUiPickersDate, name: string) => {
    if (value?.isValid) {
      const dateToFormat = value.toFormat('yyyy-MM-dd');
      return handleChange(dateToFormat, name);
    } else {
      return handleChange(null, name);
    }
  };

  const resetForm = () => {
    setState({ version });
    setErrorsState({});
    setIsModifiedState(false);
    dispatch(setSectionEditState({ version: false }));
  };

  const onCancel = () => confirmAction(() => isModified, resetForm);

  useEffect(() => {
    if (isView && isErrorsNotEmpty(errors, 'version') && sectionsEditState.version) {
      setErrorsState(errors);
    }
  }, [errors]);

  return (
    <CollapsableSection
      title={t('certifications.version.title')}
      badge={<LifeCycleStatusBadge status={status} />}
      showEditIcon={isView}
      isEdit={sectionsEditState.version}
      onSave={onSave}
      isSaving={save}
      onCancel={onCancel}
      ref={ref}
      onEditIconClick={() => dispatch(setSectionEditState({ version: true }))}
      disabledBtn={hasErrors}
    >
      {isView && !sectionsEditState.version ? (
        <ViewVersionSection type={type} data={version} status={status} />
      ) : (
        <EditVersionSection
          status={status}
          data={isView ? state.version : version}
          initialData={version}
          type={type}
          errors={isView ? errorsState : errors}
          onChange={handleChange}
          handleChangeDate={handleChangeDate}
        />
      )}
    </CollapsableSection>
  );
});
