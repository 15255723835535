import React, { forwardRef, useState } from 'react';
import { BasicSaveResponse, CollapsedSectionProps, OnChangeType, TestGroup } from '../types';
import { ErrorObject, ValidationError } from '../../../../helpers/types';
import { CollapsableSection } from './CollapsableSection';
import { testGroupsSchema } from '../validation-schemes';
import { confirmAction, jsonCopy, mergeObjects, prepareYupModel } from '../../../../helpers';
import dot from 'dot-object';
import { formatErrors } from '../../../../helpers/request';
import { handleRequestSuccess } from '../../../../helpers/handle-request-success';
import { useToasts } from 'react-toast-notifications';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { updateCertificationConformance } from '../../../../api/certification/partial-update/update-certification-conformance';
import { CertificationStatuses } from '../../../../helpers/constants/certification-statuses';
import * as Yup from 'yup';
import { validate } from './helpers/validate';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '../../../../redux/rootReducer';
import {
  CertificationDefinition,
  SectionEditState,
  setLoading,
  setSectionEditState,
  setValidationErrors,
  updateCertificationProperty,
} from '../../../../redux/modules/certification';
import { saveData } from './helpers';
import { IsModifiedProps, useChangeDetection } from '../../../../helpers/hooks/useChangeDetection';
import { ViewTestGroupSection } from './conformance/ViewTestGroupSection';
import { EditTestGroupSection } from './conformance/EditTestGroupSection';
import { GenericNodeType, GroupNodeType, SingleNodeType } from '../../../../shared-components/condition-builder/types';
import { tcStatuses } from '../../../../helpers/constants/test-case-statuses';

interface State {
  testGroups: TestGroup[];
  status?: CertificationStatuses;
  versionId: number;
  sectionsEditState: SectionEditState;
  errors: ErrorObject;
  save: boolean;
}

const calculateRunConditionHashValue = (value: Partial<GenericNodeType>): string =>
  JSON.stringify({
    type: '@type' in value ? value['@type'] : '',
    negation: value.negation ?? '',
    operator: (value as GroupNodeType)?.operator ?? '',
    certification: (value as SingleNodeType)?.certification?.id ?? '',
    elements: (value as GroupNodeType)?.elements?.map(element => calculateRunConditionHashValue(element)) ?? '',
  });

const calculateHashValue = (values: TestGroup[]) => {
  return JSON.stringify(
    jsonCopy(values)
      .map(testGroup => ({
        name: testGroup.name ?? '',
        condition: {
          type: testGroup.condition['@type'],
          raw: testGroup.condition?.raw ? calculateRunConditionHashValue(testGroup.condition.raw) : '',
        },
        testCriteria: testGroup.testCriteria
          ?.map(testCriteria => ({
            id: testCriteria.test.id ?? '',
            criteria: testCriteria.criteria.sort((a, b) => a.measurement.id - b.measurement.id),
          }))
          .sort((a, b) => a.id - b.id),
      }))
      .sort((a, b) => a.name.localeCompare(b.name)),
  );
};

type ConformanceSectionProps = CollapsedSectionProps & Partial<IsModifiedProps>;

export const ConformanceTestingSection = forwardRef<HTMLDivElement, ConformanceSectionProps>(
  function ConformanceTestingSection(props: ConformanceSectionProps, ref) {
    const { isView = false, setIsModified: setIsSectionModified } = props;
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const {
      testGroups,
      status = CertificationStatuses.DRAFT,
      versionId,
      sectionsEditState,
      errors,
      save,
    } = useSelector<RootReducer, State>(
      ({ certificationReducer }) => ({
        testGroups: certificationReducer.certification.testGroups,
        status: certificationReducer.certification.status,
        versionId: certificationReducer.certification.versionId as number,
        sectionsEditState: certificationReducer.sectionsEditState,
        errors: certificationReducer.errors,
        save: certificationReducer.loadingState.save,
      }),
      shallowEqual,
    );
    const [state, setState] = useState({ testGroups });
    const [errorsState, setErrorsState] = useState<ErrorObject>({});
    const [isModified, setIsModified] = useState(false);

    const setIsModifiedState = (value: boolean) => {
      setIsModified(value);
      setIsSectionModified && setIsSectionModified(value);
    };

    const setChangeDetectionInitialValue = useChangeDetection(setIsModifiedState, calculateHashValue, state.testGroups);

    const validationSchema = Yup.object().shape({
      testGroups: testGroupsSchema([tcStatuses.published, tcStatuses.archived]),
    });

    const handleChange = (value: OnChangeType, name: string) => {
      if (isView) {
        setState(prevState => {
          const newState = jsonCopy(prevState);
          dot.set(name, value, newState);
          const errors = prepareYupModel(validationSchema).checkForFieldFormatted(name, newState);
          if (errors) {
            setErrorsState(prevErrors => mergeObjects(prevErrors, { ...errors }));
          }
          return newState;
        });
      } else {
        dispatch(updateCertificationProperty(value, name));
      }
    };

    const resetForm = () => {
      setState({ testGroups });
      setErrorsState({});
      setIsModifiedState(false);
      dispatch(setSectionEditState({ conformanceTest: false }));
    };

    const onCancel = () => confirmAction(() => isModified, resetForm);

    const onSave = () => {
      if (validate(validationSchema, state, setErrorsState)) {
        dispatch(setLoading({ save: true }));
        return updateCertificationConformance(versionId, state.testGroups)
          .then(
            ({
              data: { testGroups, ...dataToUpdate },
            }: AxiosResponse<{ testGroups: TestGroup[] } & BasicSaveResponse>) => {
              saveData('testGroups', testGroups as Partial<CertificationDefinition>, 'version', dataToUpdate);
              handleRequestSuccess(t('certifications.notifications.saved'), addToast);
              dispatch(setSectionEditState({ conformanceTest: false }));
              setIsModifiedState(false);
              setChangeDetectionInitialValue(state.testGroups);
            },
          )
          .catch((errors: AxiosError) => {
            handleRequestFail(errors, addToast);
            if (errors.response?.data.errors) {
              dispatch(setValidationErrors(formatErrors(errors.response?.data.errors as ValidationError[])));
            }
          });
      }
    };

    return (
      <CollapsableSection
        title={t('certifications.conformanceTest.title', {
          count: isView ? state.testGroups.length : testGroups.length,
        })}
        showEditIcon={isView && status === CertificationStatuses.DRAFT}
        isEdit={sectionsEditState.conformanceTest}
        onCancel={onCancel}
        onSave={onSave}
        isSaving={save}
        ref={ref}
        onEditIconClick={() => dispatch(setSectionEditState({ conformanceTest: true }))}
      >
        {isView && !sectionsEditState.conformanceTest ? (
          <ViewTestGroupSection data={state.testGroups} />
        ) : (
          <EditTestGroupSection
            data={sectionsEditState.conformanceTest ? state.testGroups : testGroups}
            errors={sectionsEditState.conformanceTest ? errorsState : errors}
            onChange={handleChange}
          />
        )}
      </CollapsableSection>
    );
  },
);
