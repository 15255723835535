import { LabCertification, Qualification } from '../../../components/pages/admin/atl-qualifications/types';
import { SortingOptions } from '../../../components/pages/test-case/types';
import axios from '../../../helpers/axios-setup';
import { buildSummaryURL } from '../../../helpers/build-summary-url';
import { SummaryFiltersObject } from '../../../helpers/types';

interface LabCertificationRequestType {
  certification: {
    id: number;
  };
  conformance: boolean;
  interoperability: boolean;
}

export const getLabCertificationsSummaryById = (
  labId: number | string,
  page: number,
  sortingOptions?: SortingOptions,
  filters?: SummaryFiltersObject,
) => axios.get(buildSummaryURL(`company/lab-management/certifications/${labId}`, page, sortingOptions, filters));

export const getAvailableLabCertificationsById = (labId: number) =>
  axios.get<LabCertification[]>(`/company/lab-management/certifications/${labId}/available`);

export const getAllLabCertifications = () =>
  axios.get<LabCertification[]>(`/company/lab-management/certifications/all`);

export const removeLabCertification = (labId: number | string, certId: number) =>
  axios.delete(`/company/lab-management/enable-testing/${labId}/${certId}`);

export const removeLabCertifications = (labId: number | string, certIds: number[]) =>
  axios.delete(`/company/lab-management/enable-testing/${labId}`, { data: certIds });

export const updateLabCertification = (
  labId: number | string,
  certId: number,
  interoperability: boolean,
  conformance: boolean,
) =>
  axios.put<LabCertificationRequestType>(`/company/lab-management/enable-testing/${labId}`, {
    certification: {
      id: certId,
    },
    interoperability,
    conformance,
  });

export const addLabCertifications = (labIds: number[] | string[], data: Qualification[]) =>
  axios.post<LabCertificationRequestType[]>(`/company/lab-management/enable-testing`, {
    labIds: labIds.map(labId => (typeof labId == 'string' ? parseInt(labId) : labId)),
    testingRules: data.map(({ certification, conformance, interoperability }) => ({
      certification: {
        id: certification.version.id,
      },
      interoperability,
      conformance,
    })),
  });
