import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TransactionDecisionTypes } from '../types';
import TransactionsBadge, { getBadgeDetails } from './TransactionsBadge';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  wrapper: { display: 'flex', justifyContent: 'start', flexDirection: 'row' },
  box: {
    display: 'flex',
    border: '1px solid #E8E8ED',
    width: 1020,
    flexDirection: 'column',
    padding: 20,
    paddingLeft: 40,
  },
  legendHolder: {
    display: 'flex',
    height: 55,
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  legendItem: { display: 'flex', marginRight: 24, alignItems: 'center' },
});

const TransactionsSummaryLegend = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.box}>
        <div className="text-bold">Legend</div>
        <div className={classes.legendHolder}>
          {(Object.keys(TransactionDecisionTypes) as TransactionDecisionTypes[]).map(key => (
            <div key={key} className={classes.legendItem}>
              <TransactionsBadge status={key} useTooltip={false} />
              <div style={{ marginLeft: 5 }}>{t(`admin.transactions.decisionTypes.${getBadgeDetails(key)}`)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransactionsSummaryLegend;
