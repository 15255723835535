import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ControlLabel, Grid, Icon, Row } from 'rsuite';
import { reformatDate } from '../../../../../helpers';
import { labSubmissionStatusTitles } from '../../../../../helpers/constants/lab-submission-status';
import { LabInfo } from '../../../lab-info/types';
import { getLabSubTypeFullName } from './LabInfoSection';

export const LabInfoTable = ({ labs, removeLab }: { labs: LabInfo[]; removeLab: (id: number) => void }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsOverflow(ref.current.offsetHeight < ref.current.scrollHeight);
    }
  }, [ref.current?.offsetHeight, ref.current?.scrollHeight, window.innerHeight]);

  return (
    <Grid fluid>
      <Row style={{ lineHeight: '1.25rem', paddingRight: isOverflow ? 16 : 0 }}>
        <Col xs={8} style={{ paddingLeft: 25 }}>
          <ControlLabel>{t('lab.company')}</ControlLabel>
        </Col>
        <Col xs={5}>
          <ControlLabel>{t('lab.subType')}</ControlLabel>
        </Col>
        <Col xs={4}>
          <ControlLabel>{t('lab.email')}</ControlLabel>
        </Col>
        <Col xs={3}>
          <ControlLabel>{t('lab.status')}</ControlLabel>
        </Col>
        <Col xs={4}>
          <ControlLabel>{t('lab.statusChangeDate')}</ControlLabel>
        </Col>
      </Row>
      <div
        style={{ maxHeight: labs.length > 6 ? 150 : undefined, overflowY: 'auto', overflowX: 'hidden', width: '100%' }}
        ref={ref}
      >
        {labs.map(data => {
          const subTypeFullName = data?.subtype ? getLabSubTypeFullName(data.subtype) : null;
          return (
            <Row key={`lab-data-${data.id}`} style={{ lineHeight: '1.5rem' }}>
              <Col xs={8}>
                <div style={{ display: 'flex' }}>
                  <Icon
                    icon="trash-o"
                    style={{ marginRight: 10, paddingTop: 5, cursor: 'pointer' }}
                    onClick={() => removeLab(data.id)}
                  />
                  <div className="details-item-content">{data.name}</div>
                </div>
              </Col>
              <Col xs={5}>
                <div className="details-item-content">
                  {data?.subtype
                    ? subTypeFullName
                      ? data.subtype + ' (' + t(subTypeFullName) + ')'
                      : data.subtype
                    : null}
                </div>
              </Col>
              <Col xs={4}>
                <div className="details-item-content">{data.companyContact}</div>
              </Col>
              <Col xs={3}>
                <div className="details-item-content">
                  {data?.submissionStatus ? labSubmissionStatusTitles[data.submissionStatus] : null}
                </div>
              </Col>
              <Col xs={4}>
                <div className="details-item-content">
                  {data?.lastStatusUpdate ? reformatDate(data.lastStatusUpdate) : null}
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
    </Grid>
  );
};
