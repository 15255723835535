import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dot from 'dot-object';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

import { CollapsableSection } from '../../../../../certification/partials/CollapsableSection';
import { CollapsedSectionProps } from '../../../../../certification/types';
import { confirmAction } from '../../../../../../../helpers';
import { saveProductInfo } from '../../../../../../../api/application/partial-update/save-product-info';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { IsModifiedProps } from '../../../../../../../helpers/hooks/useChangeDetection';
import {
  AppFlows,
  ApplicationFlowState,
  setReviewSectionEditState,
  updateApplicationProperty,
} from '../../../../../../../redux/modules/application-flow';
import { ProductCategories, ProductInfoStep } from '../../../../../../../api/application/types';
import { UploadedFile } from '../../../../../../../shared-components/file-upload/types';
import { jsonCopy, mergeObjects, prepareYupModel } from '../../../../../../../helpers';
import { ErrorObject } from '../../../../../../../helpers/types';
import { productScheme } from '../../../../data/validation-schemes';
import { handleRequestFail } from '../../../../../../../helpers/request-fail-handler';
import { LabelType } from '../../../../../../../shared-components/label-type/LabelType';
import { productTypesTitles } from '../../../../../../../helpers/constants/product-types';
import { isProductOfSolutionProvider } from '../../helpers';
import { Confirm } from '../../../../../../../helpers/confirmationPopup/Confirm';

import { ViewVariantSection } from './ViewVariantSection';
import { EditVariantSection } from './EditVariantSection';
import { SectionEditButton } from './SectionEditButton';

type ProductSectionProps = CollapsedSectionProps & Partial<IsModifiedProps>;

export const VariantSection = forwardRef<HTMLDivElement, ProductSectionProps>(function VariantSection(
  { setIsModified: setIsSectionModified }: ProductSectionProps,
  ref,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { reviewSectionEditState, productInfo, formId, ...application } = useSelector<
    RootReducer,
    ApplicationFlowState
  >(state => state.applicationFlow);

  const [state, setState] = useState({ productInfo });
  const [errorsState, setErrorsState] = useState<ErrorObject>({});
  const [isModified, setIsModified] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const [appDocLoading, setAppDocLoading] = useState(false);

  const showQualifiedSolutionFields =
    isProductOfSolutionProvider(application) && application['@type'] !== AppFlows.DERIVATIVE;

  const setIsModifiedState = (value: boolean) => {
    setIsModified(value);
    setIsSectionModified && setIsSectionModified(value);
  };

  const validationSchema = Yup.object().shape({
    productInfo: productScheme(),
  });

  const resetForm = () => {
    setIsModifiedState(false);
    setState({ productInfo });
    setErrorsState({});
    dispatch(setReviewSectionEditState({ variantInfo: false }));
  };

  const onCancel = () => confirmAction(() => isModified, resetForm, 'common.unsavedDataWarning');

  const onCollapse = async () => {
    if (isModified) {
      return await Confirm({
        title: t('common.placeholders.areYouSure'),
        message: t('common.unsavedDataWarning'),
        onAccept: resetForm,
      });
    } else {
      resetForm();
    }
  };

  const changeData = (
    value: string | ProductCategories | ProductCategories[] | UploadedFile | UploadedFile[] | boolean | null,
    name: string,
  ) => {
    setState(prevState => {
      const newState = jsonCopy(prevState);
      dot.set(name, value, newState);
      const errors = prepareYupModel(validationSchema).checkForFieldFormatted(name, newState);
      if (errors) {
        setErrorsState(prevErrors => mergeObjects(prevErrors, { ...errors }));
      }
      return newState;
    });
  };

  const handleChange = (
    value: string | ProductCategories | ProductCategories[] | UploadedFile | UploadedFile[] | boolean | null,
    name: string,
  ) => {
    changeData(value, name);
  };

  const catchPromiseError = (error: AxiosError) => {
    handleRequestFail(error, addToast);
    setSaveState(false);
    throw error;
  };

  const onSave = () => {
    const hasError = Object.keys(errorsState).some(key => errorsState[key] !== '');
    if (!hasError) {
      setSaveState(true);

      const copyInfo = jsonCopy(productInfo);
      copyInfo.modelVariants[0].name = state.productInfo.modelVariants[0].name;
      copyInfo.modelVariants[0].availableAsDerivative = state.productInfo.modelVariants[0].availableAsDerivative;
      copyInfo.modelVariants[0].searchableByPublic = state.productInfo.modelVariants[0].searchableByPublic;
      copyInfo.documents = state.productInfo.documents;
      if (showQualifiedSolutionFields) {
        copyInfo.modelVariants[0].availableAsQualifiedSolution =
          state.productInfo.modelVariants[0].availableAsQualifiedSolution;
      }

      saveProductInfo(Number(formId), copyInfo)
        .then(({ data }: AxiosResponse<ProductInfoStep>) => {
          dispatch(setReviewSectionEditState({ variantInfo: false }));
          dispatch(updateApplicationProperty(data, 'productInfo'));
          setIsModifiedState(false);
          setSaveState(false);

          addToast(t('applications.notifications.saved'), {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        })
        .catch(catchPromiseError);
    }
  };

  useEffect(() => {
    // only update related factor
    setState(prevState => {
      return {
        productInfo: {
          ...prevState.productInfo,
          qualifiedSolution: productInfo.qualifiedSolution,
          certified: productInfo.certified,
        },
      };
    });
  }, [productInfo]);

  return (
    <CollapsableSection
      title={t('applications.review.section.variant.title')}
      showEditIcon={true}
      isEdit={reviewSectionEditState.variantInfo}
      onSave={onSave}
      isSaving={saveState || appDocLoading}
      disabledBtn={!isModified}
      onCancel={onCancel}
      ref={ref}
      onEditIconClick={() => dispatch(setReviewSectionEditState({ variantInfo: true }))}
      hideDivider={true}
      editIcon={<SectionEditButton />}
      titleComponent={
        productInfo.modelVariants[0].productType ? (
          <LabelType label={productTypesTitles[productInfo.modelVariants[0].productType]} />
        ) : null
      }
      onCollapse={onCollapse}
      hideEditIconWhenCollapse={true}
    >
      {!reviewSectionEditState.variantInfo ? (
        <ViewVariantSection data={productInfo} />
      ) : (
        <EditVariantSection
          data={state.productInfo}
          onChange={handleChange}
          setLoading={setAppDocLoading}
          setIsModified={setIsModifiedState}
          errors={errorsState}
        />
      )}
    </CollapsableSection>
  );
});
