import React from 'react';
import { ApplicationSummaryRecord, SingleAppStaffSummaryRecord } from './types';
import { getApplicationsSummary } from '../../../api/application';
import { applicationStatuses, applicationStatusTitles, wfaStaffRoles } from '../../../helpers/constants';
import { CreateNewApplicationButton, SummaryTable } from './partials';
import { useSelector } from 'react-redux';
import { userState } from '../../../redux/modules/user/reducer';
import { User } from '../../../redux/modules/user/types';
import { getSummaryPageComponentByObject } from '../../hoc/get-summary-page-component';
import { useTranslation } from 'react-i18next';
import { getSummaryWithoutTabPageComponentByObject } from '../../hoc/get-summary-without-tabs-page-component';
import { ApplicationsSummaryTable } from '../staff/partials/ApplicationsSummaryTable';
import { getSummaryApplications } from '../../../api/staff';

const SummaryPage = () => {
  const user = useSelector<{ userReducer: userState }, User>(state => state.userReducer.user as User);
  const { t } = useTranslation();

  if (wfaStaffRoles.includes(user.authority)) {
    const SummaryStaffPage = getSummaryWithoutTabPageComponentByObject<SingleAppStaffSummaryRecord>({
      title: t('applications.title'),
      apiCall: getSummaryApplications,
      SummaryTable: ApplicationsSummaryTable,
      defaultSorting: {
        sortColumn: 'staffAllApplicationsDate',
        sortType: 'desc',
      },
      useAdvancedFilters: true,
      isApplicationSummary: true,
    });

    return <SummaryStaffPage />;
  }
  const SummaryPage = getSummaryPageComponentByObject<ApplicationSummaryRecord>({
    title: t('applications.title'),
    apiCall: getApplicationsSummary,
    object: applicationStatuses,
    titles: applicationStatusTitles,
    SummaryTable,
    pageRightAction: <CreateNewApplicationButton />,
    defaultSorting: { sortColumn: 'memberDate', sortType: 'desc' },
    useAdvancedFilters: true,
    isApplicationSummary: true,
  });

  return <SummaryPage />;
};

export const ApplicationSummaryPage = React.memo(SummaryPage);
