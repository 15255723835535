import React, { PropsWithChildren } from 'react';

interface CardProps {
  header?: React.ReactNode;
  type?: 'dark' | 'light';
}

const Card = ({
  children,
  className,
  style,
  header,
  type = 'dark',
}: PropsWithChildren<React.HTMLAttributes<{}> & CardProps>) => {
  return (
    <div style={style} className={`el-card ${className || ''} is-${type}`}>
      {header && <div className="el-card__header">{header}</div>}
      <div className="el-card__body">{children}</div>
    </div>
  );
};

export default Card;
