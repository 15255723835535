import {
  AuditingInfo,
  CertificationType,
  FormCapability,
  InteroperabilityTest,
  Prerequisites,
  TestGroup,
  UmbrellaInfo,
  VersionInfo,
} from '../../../components/pages/certification/types';
import { CertificationStatuses } from '../../../helpers/constants/certification-statuses';
import { initialState } from './data';
import { AnyAction } from 'redux';
import {
  RESTORE_CERTIFICATION,
  UPDATE_CERTIFICATION_PROPERTY,
  CERTIFICATION_FOR_EDIT_LOADED,
  SET_LOADING,
  SET_CERTIFICATION_VALIDATION_ERRORS,
  SET_SECTIONS_EDIT_STATE,
} from './actionTypes';
import dot from 'dot-object';
import { jsonCopy, mergeObjects, prepareYupModel } from '../../../helpers';
import { ErrorObject } from '../../../helpers/types';
import { getSchemeByTypeAndStatus } from '../../../components/pages/certification/validation-schemes';
import { Additions } from './actionCreators';

export type CertificationDefinition = {
  id?: number;
  versionId?: number;
  status?: CertificationStatuses;
  auditingInformation?: AuditingInfo;
  '@type': CertificationType;
  generalInfo: UmbrellaInfo;
  version: VersionInfo;
  capabilities: FormCapability[];
  interoperabilityTest: InteroperabilityTest;
  testGroups: TestGroup[];
  prerequisites: Prerequisites[];
};

export type SectionEditState = {
  generalInfo: boolean;
  version: boolean;
  capabilities: boolean;
  prerequisites: boolean;
  interoperabilityTest: boolean;
  conformanceTest: boolean;
};

export type LoadingState = {
  save: boolean;
  loading: boolean;
  publishing: boolean;
};

export type CertificationState = {
  certification: CertificationDefinition;
  errors: ErrorObject;
  loadingState: LoadingState;
  sectionsEditState: SectionEditState;
};

export const certificationReducer = (state: CertificationState = initialState, action: AnyAction) => {
  const stateCopy = jsonCopy(state);
  switch (action.type) {
    case RESTORE_CERTIFICATION:
      return initialState;
    case CERTIFICATION_FOR_EDIT_LOADED:
      stateCopy.loadingState.loading = false;
      return mergeObjects(stateCopy, {
        certification: action.payload,
        sectionsEditState: initialState.sectionsEditState,
        errors: {},
      });
    case SET_LOADING:
      return mergeObjects(stateCopy, { loadingState: { ...action.payload } });
    case UPDATE_CERTIFICATION_PROPERTY:
      const { value, name, additions } = action.payload as {
        value: Partial<CertificationDefinition> | string | boolean | Date | number | null;
        name: string;
        additions: Additions;
      };
      dot.set(name, value, stateCopy.certification);
      if (additions?.auditingInfo && stateCopy.certification.auditingInformation) {
        const { auditingInfo } = additions;
        stateCopy.certification.auditingInformation[auditingInfo.propertyToUpdate] = mergeObjects(
          stateCopy.certification.auditingInformation[auditingInfo.propertyToUpdate],
          auditingInfo.dataToUpdate,
        );
      }

      if (
        name === '@type' &&
        value === CertificationType.PRIMARY &&
        !stateCopy.certification.interoperabilityTest.templateRequired
      ) {
        stateCopy.certification.interoperabilityTest.templateRequired = true;
      }

      if (additions?.errorToClear) {
        stateCopy.errors[additions.errorToClear] = '';
      }

      if (additions?.saving !== undefined) {
        stateCopy.loadingState.save = additions.saving;
      }

      if (additions?.sectionEditState) {
        stateCopy.sectionsEditState = mergeObjects(stateCopy.sectionsEditState, { ...additions.sectionEditState });
      }

      const errors = prepareYupModel(
        getSchemeByTypeAndStatus(stateCopy.certification['@type'], stateCopy.certification.status),
      ).checkForFieldFormatted(name, stateCopy.certification);
      return mergeObjects(stateCopy, { errors });
    case SET_CERTIFICATION_VALIDATION_ERRORS:
      const { validationErrors, sectionsToEdit } = action.payload;
      if (sectionsToEdit) {
        stateCopy.sectionsEditState = mergeObjects(stateCopy.sectionsEditState, sectionsToEdit);
      }
      return {
        ...jsonCopy(stateCopy),
        errors: validationErrors,
        loadingState: {
          loading: false,
          publishing: false,
          saving: false,
        },
      };
    case SET_SECTIONS_EDIT_STATE:
      const payloadKeys = Object.keys(action.payload);
      const filteredData = payloadKeys.filter(key => action.payload[key] === false);
      if (filteredData.length > 0) {
        const filteredErrors = Object.keys(stateCopy.errors).reduce((accum, curr) => {
          if (payloadKeys.some(key => curr.includes(key))) {
            accum[curr] = '';
          }
          return accum;
        }, {} as ErrorObject);
        stateCopy.errors = mergeObjects(stateCopy.errors, filteredErrors);
      }

      return { ...stateCopy, sectionsEditState: mergeObjects(stateCopy.sectionsEditState, action.payload) };
    default:
      return state;
  }
};
