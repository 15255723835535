import React, { useEffect } from 'react';
import { env, WOOTRIC_ACCOUNT_TOKEN, WOOTRIC_NO_SURVEYED_COOKIE, WOOTRIC_SURVEY_IMMEDIATELY } from '../../config';
import { useSelector } from 'react-redux';
import { RootReducer } from '../../redux/rootReducer';
import { User } from '../../redux/modules/user/types';
import { wfaStaffRoles } from '../constants';

const Wootric = () => {
  const user = useSelector<RootReducer, User>(state => state.userReducer.user as User);
  const showSurvey =
    env === 'production' &&
    WOOTRIC_ACCOUNT_TOKEN &&
    user &&
    !user?.originalUser &&
    !wfaStaffRoles.includes(user?.authority);

  useEffect(() => {
    if (showSurvey) {
      const createdAt = Math.round(new Date().getTime() / 1000);

      const setupScript = document.createElement('script');
      setupScript.type = 'text/javascript';
      setupScript.id = 'wootric-settings';
      setupScript.async = true;
      setupScript.innerHTML = `
      wootric_no_surveyed_cookie = ${WOOTRIC_NO_SURVEYED_COOKIE};
      wootric_survey_immediately = ${WOOTRIC_SURVEY_IMMEDIATELY};
      window.wootricSettings = {
        email: '${user.email}',
        created_at: ${createdAt},
        campaign_name: 'cert',
        account_token: "${WOOTRIC_ACCOUNT_TOKEN}"
      };`;

      if (document.getElementById('wootric-settings') == null) {
        document.body.appendChild(setupScript);
      }

      // Beacon
      const beacon = document.createElement('script');
      beacon.type = 'text/javascript';
      beacon.id = 'wootric-beacon';
      beacon.async = true;

      beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
      beacon.onload = () => {
        // @ts-ignore
        window.wootric('run');
      };
      if (document.getElementById('wootric-beacon') == null) {
        document.body.appendChild(beacon);
      }
    }
  }, []);

  return <div />;
};

export default Wootric;
