import React from 'react';
import { TCRecord } from '../types';
import { DetailsItem, NoTableData, PageTemplate } from '../../../partials';
import { FormGroup, Row, Col, Table, ControlLabel, Button, Whisper, IconButton, Icon, Popover, Dropdown } from 'rsuite';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getStoredSummaryUrl, reformatDate, closeWindow } from '../../../../helpers';
import { RsTable } from '../../../../shared-components/theme';
import { ARCHIVED, DRAFT, PUBLISHED } from '../data/constants';
import { Confirm } from '../../../../helpers/confirmationPopup/Confirm';
import { updateTCState } from '../../../../api/test-case';
import { AxiosError } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { defaultWhisperProps } from '../../../../helpers/constants';
import { SummaryTable } from '../../certification/partials/SummaryTable';
import { certificationStatuses, certificationStatusTitles } from '../../../../helpers/constants/certification-statuses';
import { CertificationSummaryRecord } from '../../certification/types';
import { getCertificationSummary } from '../../../../api/certification';
import { getSummaryPageComponent } from '../../../hoc/get-summary-page-component';
import { BackToListButton } from '../../../../shared-components/button/BackToListButton';
import { CloseButton } from '../../../../shared-components/button/CloseButton';

const { Column, Cell, HeaderCell } = Table;

const TCDetails = ({ item, history }: { item: TCRecord } & RouteComponentProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onBackToList = () => {
    history.push(getStoredSummaryUrl('test-case'));
  };

  const onEdit = () => {
    history.push(`/test-case/${item.id}/edit`);
  };

  const onCopy = () => {
    history.push(`/test-case/new/?sourceId=${item.id}`);
  };

  const handleStateChange = () => {
    const status = item.status === DRAFT ? PUBLISHED : ARCHIVED;
    Confirm({
      title: 'Are you sure?',
      message: `You want to ${item.status === DRAFT ? t('common.actions.publish') : t('common.actions.archive')} ${
        item.name
      }`,
      onAccept: () => {
        return updateTCState({ id: item.id as number, status })
          .then(() => {
            addToast(`Test Case status is successfully updated to ${status}`, {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
            history.push(`/test-case?tab=${status}`);
          })
          .catch((error: AxiosError) => handleRequestFail(error, addToast));
      },
    });
  };

  const showLastBtn = false;
  const SummaryPageComponent = getSummaryPageComponent<CertificationSummaryRecord>(
    '',
    getCertificationSummary,
    certificationStatuses,
    certificationStatusTitles,
    SummaryTable,
    null,
    { sortColumn: 'date', sortType: 'desc' },
    showLastBtn,
    true,
    null,
    true,
    true,
    { testCaseId: item.id },
  );

  return (
    <PageTemplate
      title={t('testCases.detailsHeader')}
      className="details-page"
      withBorder
      actionLeft={
        window.opener ? (
          <CloseButton onClick={closeWindow} />
        ) : (
          <BackToListButton className="cancel" onClick={onBackToList} />
        )
      }
      footerActionLeft={<></>}
      actionRight={
        <div>
          {item.status === ARCHIVED ? (
            <Button appearance="primary" className="duplicate" onClick={onCopy}>
              {t('common.actions.copy')}
            </Button>
          ) : (
            <>
              <Button appearance="primary" className="edit" onClick={onEdit}>
                {t('common.actions.edit')}
              </Button>
              <Whisper
                {...defaultWhisperProps}
                speaker={
                  <Popover full>
                    <Dropdown.Menu className="action-icons">
                      <Dropdown.Item className="copy-tc" onClick={onCopy}>
                        <IconButton icon={<Icon icon="copy" />} appearance="link">
                          {t('common.actions.copy')}
                        </IconButton>
                      </Dropdown.Item>
                      {item.status === DRAFT ? (
                        <Dropdown.Item className="publish-tc" onClick={handleStateChange}>
                          <IconButton icon={<Icon icon="check" />} appearance="link">
                            {t('common.actions.publish')}
                          </IconButton>
                        </Dropdown.Item>
                      ) : null}
                      {item.status === PUBLISHED ? (
                        <Dropdown.Item className="archive-tc" onClick={handleStateChange}>
                          <IconButton icon={<Icon icon="ban" />} appearance="link">
                            {t('common.actions.archive')}
                          </IconButton>
                        </Dropdown.Item>
                      ) : null}
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <IconButton
                  className="ml-1 more-actions"
                  size="lg"
                  appearance="link"
                  icon={<Icon icon="more" className="icon pointer" size="2x" />}
                />
              </Whisper>
            </>
          )}
        </div>
      }
    >
      <Row gutter={20} className="mb-1">
        <Col xs={12}>
          <DetailsItem label={t('testCases.name')} value={item.name} />
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={8}>
              <DetailsItem className="test-case-id" label={t('testCases.id')} value={item.id} />
            </Col>
            <Col xs={8}>
              <DetailsItem label={t('testCases.createdAt')} value={reformatDate(item.createdAt)} />
            </Col>
            <Col xs={8}>
              <DetailsItem label={t('testCases.createdBy')} value={item.createdBy} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={20} className="mb-1">
        <Col xs={12}>
          <DetailsItem label={t('testCases.description')} value={item.description} />
        </Col>
        <Col xs={12}>
          <DetailsItem label={t('testCases.instruction')} value={item.instructions} />
        </Col>
      </Row>
      <Row gutter={20} className="mb-1">
        <Col xs={12}>
          <DetailsItem label={t('testCases.pythonScript')} value={item.pythonScript} />
        </Col>
        <Col xs={12}>
          <DetailsItem label={t('testCases.pythonScriptHash')} value={item.pythonScriptHash} />
        </Col>
      </Row>
      <FormGroup className="mt-2">
        <ControlLabel className="is-heading">{t('testCases.measurementsHeader')}</ControlLabel>
        <RsTable wordWrap data={item.measurements} renderEmpty={() => <NoTableData />}>
          <Column align="left" fixed minWidth={350} flexGrow={1} verticalAlign="middle">
            <HeaderCell>{t('testCases.measurement.name')}</HeaderCell>
            <Cell dataKey="name" />
          </Column>

          <Column verticalAlign="middle" width={100}>
            <HeaderCell>{t('testCases.measurement.type')}</HeaderCell>
            <Cell dataKey="measurementType" />
          </Column>

          <Column flexGrow={1} verticalAlign="middle">
            <HeaderCell>{t('testCases.measurement.comment')}</HeaderCell>
            <Cell dataKey="comment" />
          </Column>
        </RsTable>
      </FormGroup>
      <FormGroup className="mt-2">
        <ControlLabel className="is-heading">{t('certifications.title')}</ControlLabel>
        <div className="mt-1">
          <SummaryPageComponent />
        </div>
      </FormGroup>
    </PageTemplate>
  );
};

export default withRouter(TCDetails);
