import { Button } from 'rsuite';
import React, { AllHTMLAttributes, PropsWithChildren } from 'react';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import { useTranslation } from 'react-i18next';

export const BackToListButton = ({ disabled, onClick, className }: PropsWithChildren<AllHTMLAttributes<{}>>) => {
  const { t } = useTranslation();
  return (
    <Button appearance="link" className={className} onClick={onClick} disabled={disabled}>
      <ArrowLeftLineIcon style={{ margin: '0 5px 3px -30px', fontSize: '18px' }} />
      {t('common.actions.backToList')}
    </Button>
  );
};
