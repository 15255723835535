import React, { useEffect, useMemo, useState } from 'react';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { useToasts } from 'react-toast-notifications';
import { AxiosResponse } from 'axios';
import { Fee, FeeTypes, GetFeeResponse } from './types';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import { getFeeByLabRequest } from '../../../api/billing/get-fee-by-lab-request';
import { useSelector } from 'react-redux';
import { selectLabRequest } from '../../../redux/modules/testing-flow/selectors';
import EstimatedFees from './EstimatedFees';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  feeHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  title: {
    margin: '0px 8px',
    fontWeight: 'bold',
    color: '#252c35',
    lineHeight: '40px',
  },
  fee: {
    margin: '0px 10px',
  },
});

const BillingInfo = () => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [fees, setFees] = useState<Fee[]>([]);
  const [isEstimated, setIsEstimated] = useState(false);
  const labRequest = useSelector(selectLabRequest);
  const estimatedFees = useMemo(() => fees.reduce((accum, fee: Fee) => accum + fee.priceBookEntry.price, 0), [fees]);

  useEffect(() => {
    if (labRequest?.id && labRequest?.application.productInfo.certified) {
      setLoading(true);
      getFeeByLabRequest(labRequest?.id)
        .then((response: AxiosResponse<GetFeeResponse[]>) => {
          if (response.data && response.data[0]) {
            setIsEstimated(response.data[0]['@type'] === FeeTypes.ESTIMATE);
            const { fees } = response.data[0];
            if (fees) setFees(fees);
          }
          setLoading(false);
        })
        .catch(error => {
          handleRequestFail(error, addToast);
          setLoading(false);
        });
    }
  }, []);

  return (
    <div className="my-1" data-test-id="billingInfoWrapper">
      {loading ? (
        <CircularLoader />
      ) : fees.length ? (
        <>
          <Divider />
          <div className={classes.feeHolder}>
            <EstimatedFees
              estimatedFees={estimatedFees}
              title={isEstimated ? t('billing.estimatedFees') : t('billing.fees')}
              testingView
            />
          </div>
          <Divider />
        </>
      ) : null}
    </div>
  );
};

export default BillingInfo;
