import React, { useMemo, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { debounce } from '@material-ui/core';

import { Autocomplete, GenericAutocompleteProps } from './Autocomplete';

interface AsyncAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends GenericAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  searchService: (query: string) => Promise<AxiosResponse<T[]>>;
}

export function AsyncAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: AsyncAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { value, searchService, ...rest } = props;

  const { addToast } = useToasts();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = React.useState<T[]>([]);
  const [inputValue, setInputValue] = React.useState('');

  const fetch = React.useCallback(
    (request: { search: string }, callback: (results?: T[]) => void) => {
      searchService(encodeURIComponent(request.search))
        .then((response: AxiosResponse) => {
          callback(response.data);
        })
        .catch((error: AxiosError) => {
          if (!axios.isCancel(error)) {
            setFetching(false);
            addToast(error.message, {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
          }
        });
    },
    [setFetching, searchService, addToast],
  );

  const debouncedFetch = useMemo(() => debounce(fetch, 400), [fetch]);

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setFetching(false);
    } else {
      setFetching(true);
      debouncedFetch({ search: inputValue }, (results?: T[]) => {
        if (active) {
          let newOptions = [] as T[];

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
          setFetching(false);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, debouncedFetch]);

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      value={value}
      options={options}
      loading={fetching}
      filterSelectedOptions
      onInputChange={setInputValue}
      {...rest}
    />
  );
}
