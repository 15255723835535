import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loadUser } from './redux/modules/user';
import './styles/element-theme.scss';
import { ApplicationRoutes } from './components/partials';
import { version } from '../package.json';
import { RootReducer } from './redux/rootReducer';
import { APP_STATUS, AppHealth } from './redux/modules/app/reducer';
import { healthCheck } from './api/maintenance/health-check';
import { AxiosResponse } from 'axios';
import { setAppHealth, setHealthLastChecked } from './redux/modules/app';
import { healthCheckTimout } from './helpers/constants';
import Wootric from './helpers/survey/Wootric';

export const App = ({ healthCheckDelay }: { healthCheckDelay?: number }): React.ReactElement<string> => {
  const dispatch = useDispatch();

  const { loading, loaded, appHealth } = useSelector<
    RootReducer,
    { loaded: boolean; loading: boolean; appHealth: AppHealth }
  >(
    state => ({
      loaded: state.appReducer.loaded,
      loading: state.userReducer.loading,
      appHealth: state.appReducer.appHealth,
    }),
    shallowEqual,
  );

  useEffect(() => {
    const isMaintenancePage = window.location.hash.includes('maintenance');

    if (appHealth.status === APP_STATUS.UP && isMaintenancePage) {
      window.location.href = `${window.location.origin}/#/`;
    } else if (appHealth.status === APP_STATUS.DOWN) {
      if (!isMaintenancePage) {
        window.location.href = `${window.location.origin}/#/maintenance`;
      }
      const interval = setInterval(
        () =>
          healthCheck().then((response: AxiosResponse<{ status: APP_STATUS }>) => {
            const { status } = response.data;
            if (appHealth.status !== status) {
              dispatch(setAppHealth(status));
              clearInterval(interval);
            } else {
              dispatch(setHealthLastChecked());
            }
          }),
        healthCheckDelay || healthCheckTimout.onFail,
      );
    }
  }, [appHealth.status]);

  useEffect(() => {
    dispatch(loadUser());
    const storageVersion = localStorage.getItem('version');
    if (storageVersion !== version) {
      localStorage.setItem('version', version);
      if ('caches' in window) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(names => {
          // eslint-disable-next-line no-restricted-syntax
          for (const name of names) caches.delete(name);
        });
      }
      // eslint-disable-next-line
      window.location.reload(true);
    }
  }, []);

  return useMemo(
    () =>
      loaded && !loading ? (
        <>
          <ApplicationRoutes />
          <Wootric />
        </>
      ) : (
        <div className="wifi-loader" />
      ),
    [loaded, loading],
  );
};
