import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'rsuite';
import { reformatDate } from '../../../../../helpers';
import { labSubmissionStatusTitles } from '../../../../../helpers/constants/lab-submission-status';
import { DetailsItem } from '../../../../partials';
import { LabInfo } from '../../../lab-info/types';

export const getLabSubTypeFullName = (subType: string) => {
  switch (subType) {
    case 'ATL':
      return 'lab.subTypeName.atl';
    case 'CATL':
      return 'lab.subTypeName.catl';
    case 'MCTL':
      return 'lab.subTypeName.mctl';
    case 'STL':
      return 'lab.subTypeName.stl';
    default:
      return null;
  }
};

export const LabInfoSection = ({ data }: { data?: LabInfo | null }) => {
  const { t } = useTranslation();
  const subTypeFullName = data?.subtype ? getLabSubTypeFullName(data.subtype) : null;

  return (
    <div>
      <h4>{t('lab.generalInfo')}</h4>
      <Row gutter={10}>
        <Col xs={5}>
          <DetailsItem label={t('lab.company')} value={data?.name} />
        </Col>
        <Col xs={5}>
          <DetailsItem label={t('lab.email')} value={data?.companyContact} />
        </Col>
        <Col xs={5}>
          <DetailsItem
            label={t('lab.subType')}
            value={
              data?.subtype ? (subTypeFullName ? data.subtype + ' (' + t(subTypeFullName) + ')' : data.subtype) : null
            }
          />
        </Col>
        <Col xs={5}>
          <DetailsItem
            label={t('lab.status')}
            value={data?.submissionStatus ? labSubmissionStatusTitles[data.submissionStatus] : null}
          />
        </Col>
        <Col xs={4}>
          <DetailsItem
            label={t('lab.statusChangeDate')}
            value={data?.lastStatusUpdate ? reformatDate(data.lastStatusUpdate) : null}
          />
        </Col>
      </Row>
    </div>
  );
};
