import { IterableObject } from './types';

export const parseUrlParams = (search: string) => {
  const params: IterableObject<string> = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  for (const entry of new URLSearchParams(search).entries() as any) {
    const [key, value] = entry;
    if (params[key]) params[key] += `,${value}`;
    else params[key] = value;
  }
  return params;
};
