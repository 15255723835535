import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'rsuite';
import { useHistory } from 'react-router';
import { WhisperActions } from './WhisperActions';
import { ApplicationSummaryRecord } from '../../application/types';

export const ActionsColumn = ({ record, status }: { record: ApplicationSummaryRecord; status: string }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const onReview = () => history.push(`/pending-approval/${record.id}`);

  return (
    <div className="actions">
      <Button appearance="primary" size="sm" onClick={onReview}>
        {t('common.actions.review')}
      </Button>
      <WhisperActions applicationId={record.id} record={record} status={status} />
    </div>
  );
};
