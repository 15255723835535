import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { useToasts } from 'react-toast-notifications';
import { Button, FlexboxGrid } from 'rsuite';

import { PageTemplate } from '../../partials';
import { ReviewCertificationsStep, ReviewResultsStep, TestingSummarySidebar } from './partials';
import { TestingApplicationSteps } from './partials/TestingApplicationSteps';
import { ApplicationDetails } from '../application/partials';
import { LabRequest, LRCertification, LRInteroperabilityResults, LRConformanceResults } from './types';
import { applicationStatuses, LRStatus, LRSummaryStatuses } from '../../../helpers/constants';
import { UploadResultsStep } from './partials/UploadResultsStep';
import { acceptLR, getLabRequest, recallLRFromApprover, submitLR, saveLR } from '../../../api/lab-request';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { Confirm } from '../../../helpers/confirmationPopup/Confirm';
import { confirmAction, getStoredSummaryUrl, jsonCopy, mergeObjects, prepareYupModel } from '../../../helpers';
import { labRequestLoaded, restoreTestingFlowData, updateValidationErrors } from '../../../redux/modules/testing-flow';
import { ComplexObjectError, ErrorObject, ValidationErrorResponse } from '../../../helpers/types';
import { formatErrors } from '../../../helpers/request';
import { testingExplanationScheme } from '../application/data/validation-schemes';
import { showAddNoteModal } from '../../../redux/modules/modals';
import { NoteActionTypes } from '../../../redux/modules/modals/constants';
import {
  selectAllLabRequestCertifications,
  selectIsConformanceResultsInvalid,
  selectIsInteroperabilityNotPassed,
  selectIsInteroperabilityNotUploaded,
  selectLabRequest,
  selectLabRequestLabInfo,
  selectRequestedCertifications,
} from '../../../redux/modules/testing-flow/selectors';
import { numberSort, useChangeDetection, usePageChangeDetection } from '../../../helpers/hooks/useChangeDetection';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import { closeWindow } from '../../../helpers';
import { BackToListButton } from '../../../shared-components/button/BackToListButton';
import { CloseButton } from '../../../shared-components/button/CloseButton';

const calculateHashValue = (value: {
  labRequest: LabRequest | null;
  certifications: LRCertification[];
  interoperabilityTestResults: LRInteroperabilityResults[];
  conformanceTestResult: LRConformanceResults | null;
}) => {
  const { labRequest, certifications, interoperabilityTestResults, conformanceTestResult } = jsonCopy(value);
  return JSON.stringify({
    labRequest: {
      testingException: {
        explanation: labRequest?.testingException?.explanation,
        attachments: labRequest?.testingException?.attachments.map(item => item.id).sort(numberSort),
      },
      attachments: labRequest?.attachments.map(item => item.id).sort(numberSort),
    },
    certifications: certifications
      .map(cert => ({
        id: cert.id,
        conformanceTestResult: cert.conformanceTestResult,
        interoperabilityTestResult: cert.interoperabilityTestResult,
        capabilities: cert.capabilities
          .map(capability => ({
            id: capability.id,
            conformanceTestResult: capability.conformanceTestResult,
            interoperabilityTestResult: capability.interoperabilityTestResult,
          }))
          .sort((a, b) => a.id - b.id),
      }))
      .sort((a, b) => a.id - b.id),
    interoperabilityTestResults: interoperabilityTestResults
      .map(item => ({
        id: item.template.id,
        resultId: item.result?.id,
      }))
      .sort((a, b) => a.id - b.id),
    conformanceTestResult: conformanceTestResult?.id,
  });
};

export const formatValidationErrors = (errors: ComplexObjectError[]): ErrorObject => {
  return errors.reduce((prev: ErrorObject, current) => {
    const errorKey = 'interoperabilityTemplate.missing';
    if (!current.errorDescriptions.find(item => item.messageKey.includes(errorKey))) {
      return prev;
    }

    return mergeObjects(prev, {
      [current.id]: i18next.t(`common.validation.${errorKey}`),
    });
  }, Object.create(null));
};

const TestingApplicationFlowComponent = ({
  history,
  location,
  match,
}: RouteComponentProps<{ id: string; action: string }>) => {
  const [reviewOnly, setReviewOnly] = useState(match.params.action === 'view');
  const stepParam = new URLSearchParams(location.search).get('step');
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [step, setStep] = useState(stepParam ? Number(stepParam) : 1);
  const [isShowedPopup, setIsShowedPopup] = useState(false);
  const labRequest = useSelector(selectLabRequest);
  const certifications = useSelector(selectRequestedCertifications);
  const allCertifications = useSelector(selectAllLabRequestCertifications);
  const isInteroperabilityNotUploaded = useSelector(selectIsInteroperabilityNotUploaded);
  const isInteroperabilityNotPassed = useSelector(selectIsInteroperabilityNotPassed);
  const isConformanceResultsInvalid = useSelector(selectIsConformanceResultsInvalid);
  const labRequestLabInfo = useSelector(selectLabRequestLabInfo);
  const [loading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [loadingButtons, setLoadingButtons] = useState(false);
  const [fileIsLoading, setFileIsLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ErrorObject>({});
  const { resetChangeDetection } = usePageChangeDetection(value => JSON.stringify(value), isModified);

  const changeStep = (stepNumber: number) => {
    window.scrollTo(0, 0);
    setStep(stepNumber);
    history.push({
      pathname: location.pathname,
      search: `?step=${stepNumber}`,
    });
  };

  useEffect(() => {
    dispatch(restoreTestingFlowData());
    if (!stepParam) {
      changeStep(1);
    }

    getLabRequest(Number(match.params.id))
      .then((response: AxiosResponse<LabRequest>) => {
        if (response.data.conformanceTestResult !== null) {
          setIsShowedPopup(true);
        }
        dispatch(labRequestLoaded(response.data));
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (reviewOnly === false && [LRStatus.PENDING_APPROVAL, LRStatus.APPROVED].includes(labRequest?.status)) {
      setReviewOnly(true);
      if (match.params.action !== 'view') {
        history.push({
          pathname: `${location.pathname}/view`,
          search: location.search,
        });
      }
    }
  }, [labRequest]);

  const resultsData = {
    labRequest,
    certifications,
    interoperabilityTestResults: labRequest?.interoperabilityTestTemplateResults || [],
    conformanceTestResult: labRequest?.conformanceTestResult || null,
  };

  const useChanges = useChangeDetection(setIsModified, calculateHashValue, resultsData);
  useEffect(() => {
    useChanges(resultsData);
  }, [loading, isSaving]);

  const getValidationSchemeAndData = (): {
    scheme: Schema<Partial<LabRequest>> | null;
    data: Partial<LabRequest> | null;
  } => {
    switch (step) {
      case 3:
        if (labRequest?.testingException) {
          return {
            scheme: Yup.object().shape({ testingException: testingExplanationScheme }),
            data: { testingException: labRequest?.testingException },
          };
        }
        return {
          scheme: null,
          data: null,
        };
      default:
        return {
          scheme: null,
          data: labRequest,
        };
    }
  };

  const validateForm = () => {
    const { scheme, data } = getValidationSchemeAndData();
    if (scheme !== null && data !== null) {
      const errors = prepareYupModel(scheme).checkFormatted(data);
      if (errors !== null) {
        setValidationErrors(errors as ErrorObject);
        dispatch(updateValidationErrors(errors as ErrorObject));
        return Promise.reject();
      }
    }
    return Promise.resolve();
  };

  const handlePreviousStep = () => {
    changeStep(step - 1);
  };

  const getDataToSubmit = (labRequest: LabRequest) => {
    return mergeObjects(labRequest, {
      interoperabilityTestTemplateResults: labRequest.interoperabilityTestTemplateResults.filter(
        item => item.result !== null,
      ),
    });
  };

  const submitForm = (labRequest: LabRequest) => {
    setLoadingButtons(true);
    const dataToSubmit = getDataToSubmit(labRequest);

    return validateForm()
      .then(() =>
        submitLR(labRequest.id, dataToSubmit)
          .then(() => {
            addToast(t('testing.submitMessage', { id: labRequest?.application.id }), {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
            history.push(`/lab-request?tab=${applicationStatuses.pendingApproval}`);
            setLoadingButtons(false);
          })
          .catch((error: AxiosError<ValidationErrorResponse>) => {
            const data = error?.response?.data;
            if (data?.messageKey.includes('ValidationError') && data?.errors) {
              const formatted = formatErrors(data.errors);
              dispatch(updateValidationErrors(formatted));
            }

            if (data?.messageKey.includes('complexObjectErrors') && data?.errors) {
              setValidationErrors(formatValidationErrors((data.errors as unknown) as ComplexObjectError[]));
            }

            handleRequestFail(error, addToast);
            setLoadingButtons(false);
          }),
      )
      .catch(() => setLoadingButtons(false));
  };

  const saveForm = (labRequest: LabRequest) => {
    setSaving(true);
    const dataToSubmit = getDataToSubmit(labRequest);

    return validateForm()
      .then(() =>
        saveLR(labRequest.id, dataToSubmit)
          .then((response: AxiosResponse<LabRequest>) => {
            addToast(t('testing.saveMessage'), {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
            dispatch(labRequestLoaded(response.data));
            setSaving(false);
          })
          .catch((error: AxiosError<ValidationErrorResponse>) => {
            const data = error?.response?.data;
            if (data?.messageKey.includes('ValidationError') && data?.errors) {
              const formatted = formatErrors(data.errors);
              dispatch(updateValidationErrors(formatted));
            }

            if (data?.messageKey.includes('complexObjectErrors') && data?.errors) {
              setValidationErrors(formatValidationErrors((data.errors as unknown) as ComplexObjectError[]));
            }

            handleRequestFail(error, addToast);
            setSaving(false);
          }),
      )
      .catch(() => setSaving(false));
  };

  const handleNextStep = () => {
    if (step === 3 && labRequest) {
      return submitForm(labRequest);
    }
    changeStep(step + 1);
  };

  const handleSave = () => {
    if (step === 3 && labRequest) {
      return saveForm(labRequest);
    }
  };

  const handleAccept = () => {
    if (!labRequest) {
      return;
    }
    if (labRequest?.status !== LRStatus.PENDING_ACCEPTANCE) {
      return changeStep(2);
    }
    Confirm({
      title: t('common.placeholders.areYouSure'),
      message: t('common.placeholders.cantBeUndone'),
      onAccept: () => {
        setLoadingButtons(true);
        return acceptLR(labRequest.id)
          .then((response: AxiosResponse<LabRequest>) => {
            dispatch(labRequestLoaded(response.data));
            changeStep(2);
            setLoadingButtons(false);
          })
          .catch((error: AxiosError) => {
            handleRequestFail(error, addToast);
            setLoadingButtons(false);
          });
      },
    });
  };

  const onReturnSuccess = () => {
    addToast(t('testing.returnMessage', { id: labRequest?.application.id }), {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    history.push(getStoredSummaryUrl('lab-request'));
  };

  const onRecallSuccess = () => {
    addToast(`Lab Request #${labRequest?.id} was successfully recalled.`, {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    history.push(`/lab-request?tab=${LRSummaryStatuses.RETURNED}`);
  };

  const handleReturnAndRecallClick = () => {
    if (reviewOnly) {
      dispatch(
        showAddNoteModal(
          {
            appId: labRequest?.id,
            onSuccess: onRecallSuccess,
            callApi: recallLRFromApprover,
          },
          NoteActionTypes.RECALL_FROM_APPROVER,
        ),
      );
    } else {
      dispatch(
        showAddNoteModal(
          { appId: labRequest?.id, onSuccess: onReturnSuccess },
          NoteActionTypes.RETURN_TO_SUBMITTER_LAB,
        ),
      );
    }
  };

  const onBackToList = () =>
    confirmAction(
      () => isModified,
      () => {
        resetChangeDetection();
        history.push(getStoredSummaryUrl('lab-request'));
      },
    );

  const getStepButtons = () => {
    switch (step) {
      case 2:
        return (
          <div className="btn-wrapper">
            <Button className="previous-step" onClick={handlePreviousStep}>
              {t('common.navigation.previous')}
            </Button>
            <Button appearance="primary" className="next-step" onClick={handleNextStep}>
              {t('common.navigation.next')}
            </Button>
          </div>
        );
      case 3:
        if (reviewOnly) {
          return (
            <Button className="previous-step" onClick={handlePreviousStep} disabled={fileIsLoading}>
              {t('common.navigation.previous')}
            </Button>
          );
        }

        const disableSubmitBtn =
          !labRequest?.testingException &&
          (isInteroperabilityNotUploaded || isInteroperabilityNotPassed || isConformanceResultsInvalid);

        return (
          <div className="btn-wrapper">
            <Button className="previous-step" onClick={handlePreviousStep} disabled={fileIsLoading}>
              {t('common.navigation.previous')}
            </Button>
            <Button
              appearance="primary"
              className="save"
              onClick={handleSave}
              loading={isSaving}
              disabled={!isModified || fileIsLoading || loadingButtons}
            >
              {t('common.navigation.save')}
            </Button>
            <Button
              color="green"
              className="submit"
              onClick={handleNextStep}
              loading={loadingButtons}
              disabled={disableSubmitBtn || fileIsLoading || isSaving}
            >
              {t('common.actions.submitResults')}
            </Button>
          </div>
        );
      default:
        return labRequest?.status === LRStatus.PENDING_ACCEPTANCE ? (
          <Button color="green" className="accept" onClick={handleAccept} loading={loadingButtons}>
            {t('common.actions.acceptApp')}
          </Button>
        ) : (
          <Button appearance="primary" className="next-step" onClick={handleAccept} loading={loadingButtons}>
            {t('common.navigation.next')}
          </Button>
        );
    }
  };

  const getStepComponent = () => {
    if (labRequest !== null) {
      if (step !== 1 && labRequest.status === LRStatus.PENDING_ACCEPTANCE) {
        changeStep(1);
        return;
      }
      switch (step) {
        case 1:
          return (
            <ApplicationDetails
              application={labRequest.application}
              certifications={allCertifications}
              labInfo={labRequestLabInfo}
              showCertificationNote
              useCollapse
            />
          );
        case 2:
          return <ReviewCertificationsStep labRequest={labRequest} certifications={certifications} />;
        case 3:
          if ([LRStatus.PENDING_APPROVAL, LRStatus.APPROVED].includes(labRequest.status) && reviewOnly) {
            return <ReviewResultsStep labRequest={labRequest} certifications={certifications} />;
          }
          return (
            <UploadResultsStep
              labRequest={labRequest}
              certifications={certifications}
              interoperabilityTestResults={labRequest.interoperabilityTestTemplateResults}
              isShowedPopup={isShowedPopup}
              setIsShowedPopup={setIsShowedPopup}
              setFileIsLoading={setFileIsLoading}
              validationErrors={validationErrors}
            />
          );
        default:
          return <span>not supported step</span>;
      }
    }
    return <span>No lab request</span>;
  };

  const onStepClick = (step: number) => {
    changeStep(step);
  };

  return (
    <PageTemplate
      title="Requested Application"
      actionLeft={
        <div className="btn-wrapper">
          {window.opener ? (
            <CloseButton onClick={closeWindow} disabled={fileIsLoading} />
          ) : (
            <BackToListButton onClick={onBackToList} className="btn-back-to-list" disabled={fileIsLoading} />
          )}
          {!labRequest || labRequest.status === LRStatus.APPROVED ? null : (
            <Button
              appearance="primary"
              onClick={handleReturnAndRecallClick}
              disabled={labRequest === null || fileIsLoading}
            >
              {reviewOnly ? t('common.actions.recallFromApprover') : t('common.actions.returnToSubmitter')}
            </Button>
          )}
        </div>
      }
      footerActionLeft={
        <div className="btn-wrapper">
          {!labRequest || labRequest.status === LRStatus.APPROVED ? null : (
            <Button
              appearance="primary"
              onClick={handleReturnAndRecallClick}
              disabled={labRequest === null || fileIsLoading}
            >
              {reviewOnly ? t('common.actions.recallFromApprover') : t('common.actions.returnToSubmitter')}
            </Button>
          )}
        </div>
      }
      actionRight={getStepButtons()}
    >
      <FlexboxGrid className="page-container" justify="space-between">
        <FlexboxGrid.Item colspan={24}>
          <TestingApplicationSteps currentStep={step} onClick={onStepClick} action={match.params.action} />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={18}>
          <section className="my-2">
            {loading ? (
              <section style={{ height: '200px' }}>
                <CircularLoader content={t('common.placeholders.loadingData')} size={20} />
              </section>
            ) : (
              getStepComponent()
            )}
          </section>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          {!loading && labRequest ? (
            <TestingSummarySidebar step={step} certifications={certifications} application={labRequest.application} />
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </PageTemplate>
  );
};

export const TestingApplicationFlowPage = withRouter(TestingApplicationFlowComponent);
