import React from 'react';
import { Icon } from 'rsuite';
import styled from 'styled-components';
import { Tooltip } from '../tooltip/Tooltip';

const StyledIcon = styled(Icon)`
  margin-left: 0.5rem;
`;

export const WrapWithPublicIcon = ({ content }: { content: string }) => (
  <span>
    {content}
    <Tooltip translationKey="tooltips.publicFields">
      <StyledIcon icon="globe" size="lg" />
    </Tooltip>
  </span>
);
