import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReportProblemSharpIcon from '@material-ui/icons/ReportProblemSharp';

import { CertificationStatuses } from '../../../../../helpers/constants/certification-statuses';
import {
  getLifeCycleStatusKey,
  isRetirementStatus,
  statusColors,
} from '../../../../../helpers/constants/life-cycle-status';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const StyledBadge = styled.div<{ statusKey: string }>`
  padding: 4px 12px;
  border-radius: 16px;
  color: ${({ statusKey }) => (statusKey in statusColors ? statusColors[statusKey].color : _var.textLightGray)};
  background: ${({ statusKey }) => (statusKey in statusColors ? statusColors[statusKey].background : _var.grayBorder)};
  display: inline-flex;
  align-items: center;
`;

const WarningIcon = styled(ReportProblemSharpIcon)<{ statusKey: string }>`
  color: ${({ statusKey }) => (statusKey in statusColors ? statusColors[statusKey].color : _var.textLightGray)};
  margin-right: 8px;
  && {
    font-size: 20px;
  }
`;

const Title = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
`;

interface LifeCycleStatusBudgeProps {
  status: CertificationStatuses;
}

const LifeCycleStatusBadge = ({ status }: LifeCycleStatusBudgeProps) => {
  const { t } = useTranslation();
  const statusKey = getLifeCycleStatusKey(status);
  return (
    <StyledBadge statusKey={statusKey}>
      {isRetirementStatus(statusKey) ? <WarningIcon statusKey={statusKey} /> : null}
      <Title>{t('certifications.lifecycle.status.' + statusKey)}</Title>
    </StyledBadge>
  );
};

export default LifeCycleStatusBadge;
