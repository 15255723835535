import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Uploader } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { FileType } from 'rsuite/lib/Uploader';
import { IterableObject, UploaderInstance, UploaderServerErrorResponse } from '../../helpers/types';
import { UploadedFile } from '../../shared-components/file-upload/types';
import { messageKeyPrefix } from '../../helpers/constants';
import { getAuthHeader } from '../../helpers/axios-setup';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { RsField } from '../../shared-components/rsuite';

const useStyles = makeStyles(() => ({
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    color: '#641246',
    '&:hover': {
      color: '#641246',
    },
    marginRight: 10,
  },
  modalBody: {
    minHeight: '200px',
    padding: 20,
  },
  uploader: {
    display: 'flex',
    alignItems: 'center',
    '& .rs-uploader-file-item-status': {
      display: 'none',
    },
    '& .rs-uploader-file-item': {
      '&:hover': { backgroundColor: '#fff' },
    },
    '& .rs-uploader-file-item-icon-wrapper': {
      display: 'none',
    },
    '& .rs-uploader-file-items': {
      width: '100%',
      marginTop: '0 !important',
      '& .rs-uploader-file-item-text': {
        padding: '8px 30px 8px 20px',
        width: 390,
      },
    },
    '& .rs-uploader-trigger-btn': {
      background: '#f5ecf0',
      color: '#641246',
      fontWeight: 'bold',
      '&:hover': {
        opacity: 0.8,
        backgroundColor: '#f7eff3 !important',
        color: '#641246 !important',
      },
      '&:active, &:focus': {
        background: '#f5ecf0 !important',
        color: '#641246 !important',
      },
    },
  },
  actions: {
    padding: 20,
  },
  dialogTitle: {
    padding: '10px 20px',
    fontSize: '1rem',
  },
  description: { paddingBottom: 10, fontWeight: 'bold' },
  descriptionText: { paddingBottom: 10, fontSize: 12 },
  input: { marginBottom: '0 !important', paddingBottom: 10, minWidth: 560 },
  uploaderHolder: {
    display: 'flex',
    alignItems: 'center',
  },
  marginRight: { marginRight: 10, fontWeight: 'bold' },
  fileInfo: { textAlign: 'left' },
  noFile: { marginLeft: 20, opacity: 0.6 },
}));

interface FileWithError extends FileType {
  errorMessage?: string;
}

interface FileUploadModalProps {
  showPopup: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: (response: any) => void;
  url: string;
  bodyParams?: IterableObject<string>;
  title?: string;
  slot?: React.ReactNode | null;
  onError?: (response: UploaderServerErrorResponse) => Promise<boolean>;
  name: string;
  onNameChange: (value: string) => void;
}

export const LabKeyUploadModal = ({
  showPopup,
  onClose,
  onSuccess,
  url,
  bodyParams = {},
  title = 'File Upload',
  name,
  onError,
  onNameChange,
}: FileUploadModalProps) => {
  const uploaderRef = useRef<UploaderInstance>();
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileWithError[]>([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const clearInputValue = () => {
    uploaderRef.current?.cleanInputValue();
    setLoading(false);
  };

  const handleChange = (array: FileType[] = []) => setFiles(array.filter((item, index) => index === array.length - 1));

  useEffect(() => {
    onNameChange(files[0]?.name || '');
  }, [files]);

  const handleClose = () => {
    setFiles([]);
    clearInputValue();
    onClose();
    // @ts-ignore
    uploaderRef.current.getFileList().forEach(file => {
      // @ts-ignore
      if (file.fileKey in uploaderRef.current.xhrs) {
        // @ts-ignore
        uploaderRef.current.xhrs[file.fileKey].abort();
      }
    });
    uploaderRef.current?.cleanInputValue();
  };

  const handleSuccess = (data: UploadedFile) => {
    onSuccess(data);
    setFiles([]);
  };

  const handleError = async (error: UploaderServerErrorResponse, file: FileType) => {
    const { message, messageKey, messageParameters } = error.response;
    const translatedMessage = t(messageKey.replace(messageKeyPrefix, ''), messageParameters);
    const errorMessage = `${messageKeyPrefix}${translatedMessage}` === messageKey ? message : translatedMessage;

    if (onError) {
      const result = await onError(error);
      if (result) {
        setFiles([]);
        setLoading(false);
        return;
      }
    }
    setFiles([
      {
        ...file,
        fileKey: 'errorFile',
        errorMessage,
      },
    ]);
    setLoading(false);
  };

  const upload = () => uploaderRef.current?.start();

  return (
    <Dialog open={showPopup} onClose={handleClose} onExit={() => setLoading(false)}>
      <div className={classes.dialogTitle}>
        <span className="rs-control-label">{title}</span>
      </div>
      <DialogContent className={classes.modalBody}>
        <Form fluid>
          <div className={classes.description}>{t('lab.modal.keyDescription')}</div>
          <RsField
            className={classes.input}
            name="name"
            value={name}
            onChange={(value: string) => onNameChange(value)}
          />
          <div className={classes.descriptionText}>{t('lab.modal.descriptionText')}</div>
          <div className={classes.uploaderHolder}>
            <div className={classes.marginRight}>File:</div>
            <Uploader
              className={classes.uploader}
              autoUpload={false}
              multiple={false}
              action={url}
              headers={{ Authorization: getAuthHeader() }}
              onUpload={() => setLoading(true)}
              data={bodyParams}
              onChange={handleChange}
              onSuccess={handleSuccess}
              onError={handleError}
              onRemove={clearInputValue}
              fileList={files}
              ref={uploaderRef}
              renderFileInfo={(file: FileWithError) => (
                <div className={classes.fileInfo}>
                  <span>{file.name}</span>
                  {file.errorMessage ? <p>{file.errorMessage}</p> : null}
                </div>
              )}
            >
              <Button>{t('lab.modal.chooseFile')}</Button>
            </Uploader>
            {!files.length && <div className={classes.noFile}>No file selected</div>}
          </div>
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <div className={classes.btnWrapper}>
          <Button
            className={classes.cancelButton}
            disableRipple
            onClick={onClose}
            appearance="subtle"
            variant="contained"
            data-testid="btnCancel"
          >
            {t('common.navigation.cancel')}{' '}
          </Button>
          <Button onClick={upload} appearance="primary" disabled={!files.length} loading={loading}>
            {t('common.actions.upload')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
