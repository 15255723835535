import styled from 'styled-components';

export const Td = styled.td`
  vertical-align: top;
  padding: 1rem;
`;

export const SubQualTd = styled.td`
  vertical-align: top;
  padding: 0.2rem 1rem;
`;

export const TdIcon = styled.td`
  vertical-align: top;
  padding: 1.5rem 0;
  text-align: center;
  & svg {
    margin-top: 3px;
  }
`;

export const Th = styled.th`
  font-weight: normal;
  text-align: left;
  opacity: 0.6;
  padding: 1rem;
`;

export const ThIcon = styled.th`
  padding: 0;
  width: 25px;
`;

export const TdRight = styled.td`
  vertical-align: top;
  text-align: right;
  padding: 1rem;
`;

export const Tr = styled.tr`
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
`;

export const Table = styled.table`
  width: 100%;
  margin-bottom: 2rem;

  & p {
    margin-top: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;

  .input-wrapper {
    margin-top: 0.5rem;
    width: 300px;
  }
`;
