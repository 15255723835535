import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { productTypesTitles, productTypesTooltips } from '../../helpers/constants/product-types';
import { LabelType } from '../../shared-components/label-type/LabelType';

interface VariantInfoTitleProps {
  productType?: string;
  withMargin?: boolean;
}

const useStyles = makeStyles(() => ({
  variantTitleInfo: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: ({ withMargin }: { withMargin: boolean }) => (withMargin ? '1rem' : '0'),
  },
}));

export const VariantInfoTitle: React.FC<VariantInfoTitleProps> = ({ productType, withMargin = true }) => {
  const classes = useStyles({ withMargin });
  const { t } = useTranslation();

  const renderLabelType = () => {
    if (productType) {
      const productTypeTitle = productTypesTitles[productType];
      const productTypeTooltip = productTypesTooltips[productType];

      if (!productTypeTitle || !productTypeTooltip) return null;

      return <LabelType label={productTypeTitle} tooltipText={productTypeTooltip} />;
    }
  };

  return (
    <div className={classes.variantTitleInfo}>
      <h4>{t('products.variantInfoTitle')}</h4>
      {renderLabelType()}
    </div>
  );
};
