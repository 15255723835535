export const emptyMeasurement = {
  name: '',
  measurementType: '',
  alwaysProduced: false,
  comment: '',
};

export const emptyTCItem = {
  name: '',
  pythonScript: '',
  pythonScriptHash: '',
  description: '',
  instructions: '',
  measurements: [emptyMeasurement],
};

export const measurementTypeValues = [
  { label: 'BOOLEAN', value: 'BOOLEAN' },
  { label: 'INTEGER', value: 'INTEGER' },
  { label: 'DOUBLE', value: 'DOUBLE' },
  { label: 'STRING', value: 'STRING' },
];

export const DRAFT = 'DRAFT';
export const PUBLISHED = 'PUBLISHED';
export const ARCHIVED = 'ARCHIVED';

export const tcStatusesMapping: { [x: string]: 'DRAFT' | 'PUBLISHED' | 'ARCHIVED' } = {
  [DRAFT]: DRAFT,
  [PUBLISHED]: PUBLISHED,
  [ARCHIVED]: ARCHIVED,
};
