// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function flat<T>(input: any[], depth = 1, stack: T[] = []) {
  for (const item of input) {
    if (item instanceof Array && depth > 0) {
      flat(item, depth - 1, stack);
    } else {
      stack.push(item);
    }
  }

  return stack;
}
