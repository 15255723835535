import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal, Button, Form } from 'rsuite';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { RsFieldMemo } from '../../../shared-components/rsuite';
import { ErrorObject } from '../../../helpers/types';
import { prepareYupModel } from '../../../helpers';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { RootReducer } from '../../../redux/rootReducer';
import { ModalsReducerState } from '../../../redux/modules/modals/reducer';
import { hideAddNoteModal } from '../../../redux/modules/modals';
import { RsCenterModal } from '../../../shared-components/rsuite';

const ModalBody = styled(Modal.Body)``;

export const AddNoteModal = () => {
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorObject>({});

  const { noteModal } = useSelector<RootReducer, ModalsReducerState>(state => state.modalsReducer);
  const dispatch = useDispatch();

  const { addToast } = useToasts();
  const { t } = useTranslation();

  const onCancel = () => {
    setNote('');
    setErrors({});
    dispatch(hideAddNoteModal(noteModal.noteModalType));
  };

  const validateNote = (note: string) => {
    const noteErrors = prepareYupModel(
      Yup.object().shape({
        note: Yup.string()
          .trim()
          .required(t('common.validation.required')),
      }),
    ).checkForFieldFormatted('note', { note });
    setErrors(noteErrors as ErrorObject);
    return Boolean(noteErrors.note);
  };

  const onCreate = () => {
    if (validateNote(note) || noteModal.appId == null) {
      return;
    }
    setLoading(true);
    return noteModal
      .callApi({ id: noteModal.appId as number, note })
      .then(response => {
        noteModal.onSuccess && noteModal.onSuccess(response.data);
        onCancel();
      })
      .catch(error => {
        handleRequestFail(error, addToast);
        noteModal.setParentLoading && noteModal.setParentLoading(false);
        onCancel();
      });
  };

  const handleChange = (value: string) => {
    setNote(value);
    validateNote(value);
  };

  const handleAfterClosed = () => {
    setLoading(false);
  };

  return (
    <RsCenterModal backdrop show={noteModal.appId !== null} onHide={onCancel} onExited={handleAfterClosed}>
      <Modal.Header>
        <Modal.Title>{noteModal.modalTitle}</Modal.Title>
      </Modal.Header>
      <ModalBody style={{ overflow: 'hidden' }}>
        {noteModal.legend ? <p>{noteModal.legend}</p> : null}
        <Form fluid>
          <RsFieldMemo
            required
            label={noteModal.fieldLabel}
            componentClass="textarea"
            placeholder={noteModal.fieldLabel}
            rows={6}
            name="note"
            value={note}
            errors={errors}
            onChange={handleChange}
          />
        </Form>
      </ModalBody>
      <Modal.Footer>
        <Button appearance="link" onClick={onCancel} disabled={loading}>
          {t('common.navigation.cancel')}
        </Button>
        <Button appearance="primary" id="add-note" onClick={onCreate} loading={loading}>
          {noteModal.successTextBtn}
        </Button>
      </Modal.Footer>
    </RsCenterModal>
  );
};
