import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';
import axios from '../../helpers/axios-setup';

export const logoutUser = () =>
  axios.post('/logout').then((response: AxiosResponse<{ logoutUrl: string }>) => {
    Cookies.remove('access-token');
    if (response.data && response.data.logoutUrl) {
      window.location.href = response.data.logoutUrl;
    }
  });
