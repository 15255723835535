import React, { PropsWithChildren, ReactNode, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

export enum StepStatus {
  WAIT = 'wait',
  PROCESS = 'process',
  FINISH = 'finish',
  SUCCESS = 'success',
}

const StepWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: 1023px) {
    flex-wrap: ${props => (props.totalSteps > 3 ? 'wrap' : 'nowrap')};
  }
`;

export const Steps = ({
  viewOnly = false,
  active = 0,
  finishStatus = StepStatus.FINISH,
  processStatus = StepStatus.PROCESS,
  children,
  currentStep,
}: PropsWithChildren<ReactNode> & {
  viewOnly: boolean;
  active: number;
  currentStep: number;
  finishStatus?: StepStatus;
  processStatus?: StepStatus;
}) => {
  const childrenLength = React.Children.count(children);
  const [lasteViewedStep, setLasteViewedStep] = useState(currentStep);
  if (currentStep > lasteViewedStep) {
    setLasteViewedStep(currentStep);
  }
  const getStatus = (index: number): StepStatus => {
    if (active > index) {
      return finishStatus;
    } else if (active === index) {
      return processStatus;
    }
    return StepStatus.WAIT;
  };

  const stepRef = useRef(null);
  const [stepMaxWidth, setStepMaxWidth] = useState(-1);
  const [stepCountPerRow, setStepCountPerRow] = useState(childrenLength);

  useEffect(() => {
    if (stepMaxWidth > 0) {
      const callback = () => {
        setStepCountPerRow(Math.ceil(stepRef.current.clientWidth / stepMaxWidth));
      };
      callback();
      window.addEventListener('resize', callback);
      return () => window.removeEventListener('resize', callback);
    }
  }, [stepMaxWidth]);

  useEffect(() => {
    if (stepRef.current) {
      setStepMaxWidth((window.screen.availWidth * (stepRef.current.clientWidth / window.innerWidth)) / childrenLength);
    }
  }, [stepRef.current]);

  return (
    <StepWrapper ref={stepRef} totalSteps={childrenLength}>
      {stepCountPerRow > 0 &&
        React.Children.map(children, (child, index) => {
          const stepNumber = index + 1;
          const style = {
            flex: `1 1 ${100 / stepCountPerRow}%`,
          };
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return React.cloneElement(child as any, {
            style,
            stepNumber,
            totalSteps: childrenLength,
            isCurrent: index === currentStep - 1,
            isActive: index === active,
            status: getStatus(index),
            isPrevious: stepNumber === currentStep - 1,
            zIndex: childrenLength + 1 - index,
            viewOnly: viewOnly,
            isViewed: index < lasteViewedStep,
          });
        })}
    </StepWrapper>
  );
};
