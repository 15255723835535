import styled from 'styled-components';
import { Button, Icon, IconButton } from 'rsuite';
import React, { HTMLAttributes, PropsWithChildren } from 'react';

export const RoundIcon = styled(IconButton)`
  &.rs-btn-icon {
    width: 28px;
    padding: 4px;
    height: 28px;
    margin-left: 0.8rem;
    margin-top: 0.5rem;

    &.rs-btn-icon-circle > .rs-icon {
      line-height: 1.5;
      font-size: 12px;
      height: 28px;
      width: 26px;
      padding: 4px 0;
    }
  }
`;

const StyledButton = styled(Button)`
  border: 0;
  width: 200px;
  text-align: left;
  padding-left: 0 !important;
  margin-left: 0.8rem;
  span {
    padding: 10px;
  }
  button {
    margin: 0 !important;
  }
`;

export const AddButton = ({ children, onClick, className }: PropsWithChildren<HTMLAttributes<{}>>) => (
  <StyledButton className={className} appearance="ghost" componentClass="span" onClick={onClick}>
    <RoundIcon icon={<Icon icon="plus" />} circle />
    <span>{children}</span>
  </StyledButton>
);
