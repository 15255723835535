/* eslint-disable @typescript-eslint/no-explicit-any */
import { jsonCopy } from './json-copy';
import clonedeep from 'lodash.clonedeep';

export const mergeObjects = (current: any, update: any) => {
  const newCurrent = jsonCopy(current);
  Object.keys(update).forEach(function(key) {
    if (
      newCurrent.hasOwnProperty(key) &&
      newCurrent[key] !== null &&
      update[key] !== null &&
      typeof newCurrent[key] === 'object' &&
      !(newCurrent[key] instanceof Array)
    ) {
      newCurrent[key] = mergeObjects(newCurrent[key], update[key]);
    } else {
      newCurrent[key] = update[key];
    }
  });
  return newCurrent;
};

export const mergeObjectsByLodash = (current: any, update: any) => {
  const newCurrent = clonedeep(current);
  Object.keys(update).forEach(function(key) {
    if (
      newCurrent.hasOwnProperty(key) &&
      newCurrent[key] !== null &&
      update[key] !== null &&
      typeof newCurrent[key] === 'object' &&
      !(newCurrent[key] instanceof Array)
    ) {
      newCurrent[key] = mergeObjectsByLodash(newCurrent[key], update[key]);
    } else {
      newCurrent[key] = update[key];
    }
  });
  return newCurrent;
};
