import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// import { useStyles } from '../../../../../shared-components/confirm-dialog/styles';
import { DetailsItem } from '../../../../partials';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Anchor, AnchorLink } from '../../../../../shared-components/styled-components';
import { ApplicationStatuses, Transaction } from '../types';
import { getTransactionKeyForAppType } from './TransactionsTable';
import TransactionDetailsItem from './TransactionDetailsItem';
import { opportunityDateFormat, reformatDate, reformatDateToFormat } from '../../../../../helpers';
import { salesForceUrl } from '../../../../../config';
import { CircularLoader } from '../../../../../shared-components/loader/CircularLoader';
import { AxiosResponse } from 'axios';
import { handleRequestFail } from '../../../../../helpers/request-fail-handler';
import { getEvent } from '../../../../../api/transactions/get-event';
import { useToasts } from 'react-toast-notifications';
import { GetFeeResponse } from '../../../../partials/billing/types';
import { formatFee } from '../../../../../helpers/billing';

const useStyles = makeStyles({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiButton-root': {
      color: '#666666',
      opacity: 0.6,
      transition: '0.2s',
      minWidth: 36,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: 0.8,
      },
    },
    padding: '20px 20px 0px 24px',
  },
  holder: {
    display: 'flex',
    marginBottom: 20,
  },
  detailsItem: {
    display: 'flex',
    alignItems: 'center',
    width: 350,
    flexDirection: 'row',
  },
  label: {
    width: 200,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  leftCol: {
    minWidth: 200,
  },
  divider: { marginBottom: 20 },
  dialogHolder: { minWidth: 648, minHeight: 597 },
  title: {
    paddingTop: 12,
    paddingLeft: 24,
  },
});

const TransactionViewDialog = ({ open, onClose, row }: { open: boolean; onClose: () => void; row: Transaction }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<GetFeeResponse[]>([]);

  useEffect(() => {
    if (open) {
      getEvent(row.eventId)
        .then((response: AxiosResponse<GetFeeResponse[]>) => {
          setEvents(response.data);
          setLoading(false);
        })
        .catch(error => {
          handleRequestFail(error, addToast);
          setLoading(false);
        });
    }
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <div className={classes.header}>
        <span className="text-muted text-bold" style={{ fontSize: '16px' }}>
          {t('admin.transactions.details')}
        </span>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </div>
      <DialogContent className={classes.dialogHolder} data-test-id="transactionDialogContent">
        {loading ? (
          <CircularLoader />
        ) : (
          <>
            <div className={classes.holder}>
              <DetailsItem label={t('admin.transactions.cid')} className={classes.leftCol}>
                {row.applicationStatus !== ApplicationStatuses.REVOKED ? (
                  <AnchorLink to={`/product/${row.productId}/${row.productModelVariantId}`}>{row.cid}</AnchorLink>
                ) : (
                  <span data-test-id="revokedCID" style={{ color: '#641246', fontWeight: 'bold' }}>
                    {row.cid}
                  </span>
                )}
              </DetailsItem>
              <div>
                <TransactionDetailsItem label={t('admin.transactions.appId')}>
                  <AnchorLink to={`/application/${row.applicationId}`}>{row.applicationId}</AnchorLink>
                </TransactionDetailsItem>
                <TransactionDetailsItem
                  label={t('admin.transactions.type')}
                  value={t(getTransactionKeyForAppType(row.applicationType))}
                />
                <TransactionDetailsItem
                  label={t('admin.transactions.productName')}
                  value={events[0].application?.productName}
                />
                <TransactionDetailsItem
                  label={t('admin.transactions.productModelVariant')}
                  value={events[0].application?.variantName}
                />
                <TransactionDetailsItem
                  label={t('admin.transactions.certified')}
                  value={row.date ? reformatDate(row.date) : ''}
                />
                <TransactionDetailsItem
                  label={t('admin.transactions.accountName')}
                  value={events[0].application?.owner.name}
                />
              </div>
            </div>
            {events.map(event => (
              <div key={`${event['@type']}${event.fees[0].feeType}`}>
                <Divider className={classes.divider} />
                <div className={classes.holder}>
                  <DetailsItem label={t('admin.transactions.opportunityId')} className={classes.leftCol}>
                    <Anchor href={`${salesForceUrl}${event.opportunity?.id}`} target="_blank">
                      {event.opportunity?.id}
                    </Anchor>
                  </DetailsItem>
                  <div>
                    <TransactionDetailsItem
                      label={t(`admin.transactions.opportunityCreated`)}
                      value={
                        event?.opportunity?.created
                          ? reformatDateToFormat(event?.opportunity?.created, opportunityDateFormat)
                          : ''
                      }
                    />
                    <TransactionDetailsItem
                      label={t(`admin.transactions.opportunityName`)}
                      value={event?.opportunity?.name}
                    />
                    <TransactionDetailsItem label={t(`applications.payment.PONumber`)} value={event.application?.po} />
                    <TransactionDetailsItem
                      label={t(`applications.payment.CTIANumber`)}
                      value={event.application?.ctia}
                    />
                    <TransactionDetailsItem
                      label={t('admin.transactions.priceDescription')}
                      value={event.fees[0].priceBookEntry.name}
                    />
                    <TransactionDetailsItem
                      label={t('admin.transactions.amount')}
                      value={formatFee(event.fees[0].priceBookEntry.price)}
                    />
                    <TransactionDetailsItem
                      label={t('admin.transactions.requestingAccount')}
                      value={event?.opportunity?.owner.name}
                    />
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TransactionViewDialog;
