import React, { ReactNode } from 'react';
import { ErrorObject } from '../../helpers/types';
import { withLabel, WrappedInputProps } from './withLabel';
import { MultiCascader } from 'rsuite';
import { MultiCascaderProps } from 'rsuite/lib/MultiCascader';
import { extractError } from './utils';

interface Props extends MultiCascaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderMenuItem: (label: ReactNode, item: any) => ReactNode | JSX.Element;
}

const Field = ({ name, ...props }: Props) => {
  return <MultiCascader name={name} {...props} />;
};
const MemorizedField = React.memo(withLabel<Props>(Field), (prevProps, nextProps) => {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error
  );
});

interface LabeledFieldProps extends Props {
  errors?: ErrorObject;
}
export const LabeledMultiCascader = ({ errors, name, ...props }: WrappedInputProps<LabeledFieldProps>) => {
  // @ts-ignore
  return <MemorizedField name={name} error={extractError(errors, name)} {...props} />;
};
