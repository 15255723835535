import { StepItem, Steps } from '../../../../shared-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationFlowState } from '../../../../redux/modules/application-flow';
import { getApplicationLastStep } from '../../../../helpers/get-application-last-step';
import { RootReducer } from '../../../../redux/rootReducer';
import { StepStatus } from '../../../../shared-components/step/Steps';

interface NewApplicationStepsProps {
  currentStep: number;
  onClick?: (step: number) => void;
  noMarginBottom?: boolean;
  stepTitles: Array<string>;
}

export const NewApplicationSteps = ({ currentStep, stepTitles }: NewApplicationStepsProps) => {
  const reduxData = useSelector<RootReducer, ApplicationFlowState>(state => state.applicationFlow);
  const lastStep = getApplicationLastStep(reduxData);
  const lastHighlightedStep = lastStep > currentStep ? lastStep : currentStep;
  return (
    <>
      <Steps active={lastHighlightedStep - 1} currentStep={currentStep} finishStatus={StepStatus.SUCCESS}>
        {stepTitles.map((title: string) => (
          <StepItem title={title} key={title} />
        ))}
      </Steps>
    </>
  );
};
