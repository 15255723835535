import { RootReducer } from '../../../rootReducer';
import { createSelector } from 'reselect';
import { LabSummaryCertification } from '../../../../components/pages/admin/atl-qualifications/types';

export const selectAtlQualificationsState = (state: RootReducer) => state.atlQualificationsReducer;

export const selectAddedCertifications = createSelector(
  [selectAtlQualificationsState],
  ({ selectedCertifications }): LabSummaryCertification[] =>
    selectedCertifications
      .map(({ certification, conformance, interoperability }) => ({
        ...certification,
        version: { ...certification.version, conformance, interoperability },
      }))
      .sort((a, b) => a.name.localeCompare(b.name)),
);
