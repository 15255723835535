const { REACT_APP_BACKEND_HOST, REACT_APP_SALESFORCE_URL } = process.env;

export const baseUrl = `${window.location.protocol}//${REACT_APP_BACKEND_HOST}`;
export const apiBaseUrl = `${baseUrl}/api`;
export const env = process.env.NODE_ENV;
export const debug = env === 'development';
export const salesForceUrl = `${REACT_APP_SALESFORCE_URL}`;
export const ECNUrl = 'https://groups.wi-fi.org/wg/COG/document/folder/39';

export const WOOTRIC_ACCOUNT_TOKEN = process.env.REACT_APP_WOOTRIC_ACCOUNT_TOKEN ?? ''; // This is your unique account token.
export const WOOTRIC_SURVEY_IMMEDIATELY = process.env.REACT_APP_WOOTRIC_SURVEY_IMMEDIATELY ?? false; // Shows survey immediately for testing purposes.
export const WOOTRIC_NO_SURVEYED_COOKIE = process.env.REACT_APP_WOOTRIC_NO_SURVEYED_COOKIE ?? false; // Bypass cookie based throttle for testing purposes.
