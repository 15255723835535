import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { configure } from 'axios-hooks';
import { ThemeProvider } from '@material-ui/core/styles';

// these style imports are deliberately going above the others to make sure that the precedence of
// styles defined in styled components will take place
import './styles/rsuite.less';
import './styles/index.scss';

import axios from './helpers/axios-setup';
import { store } from './redux/applicationStore';
import { App } from './App';
import { CustomToastContainer } from './shared-components';
import { theme } from './shared-components/theme/theme';
import './helpers/i18n-setup';
import './helpers/polyfills';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

configure({ axios });

ReactDOM.render(
  <Provider store={store}>
    <ToastProvider components={{ ToastContainer: CustomToastContainer }}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ToastProvider>
  </Provider>,
  document.getElementById('root'),
);
