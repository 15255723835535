import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { Col, Row } from 'rsuite';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { CertificationDefinition } from '../../../../../redux/modules/certification';
import { LabeledCheckbox } from '../../../../../shared-components/labeled-inputs';
import { getInteroperabilityTemplates } from '../../../../../api/certification';
import { UploadFileDialog } from '../../../../../shared-components/upload-file-dialog/UploadFileDialog';
import { uploadFileTemplates } from '../../../../../api/certification/upload-file-templates';
import { handleRequestSuccess } from '../../../../../helpers/handle-request-success';
import { ErrorObject } from '../../../../../helpers/types';
import {
  CertificationType,
  InteroperabilityTemplateSelectOptions,
  InteroperabilityTest,
  OnChangeType,
  TestResultsTemplate,
} from '../../types';

// @ts-ignore
import _var from '../../../../../styles/_variables.scss';
import { AutocompleteField } from '../../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';

interface EditInteropSectionProps {
  data: InteroperabilityTest;
  type: CertificationType;
  errors: ErrorObject;
  onChange: (value: OnChangeType, name: string, errorToClear?: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTypography-body2': {
      color: _var.bodyTextGray,
      lineHeight: '18px',
    },
    '& .MuiButton-text': {
      color: _var.primary,
      fontWeight: 700,
      textTransform: 'none',
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 6,
      paddingRight: 0,
      lineHeight: '18px',
      marginBottom: 3,
    },
  },
  uploadWrapper: {
    marginLeft: theme.spacing(1),
  },
}));

export const EditInteropSection: React.FC<EditInteropSectionProps> = ({ data, errors, onChange, type }) => {
  const [templates, setTemplates] = useState<InteroperabilityTemplateSelectOptions[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [uploadFileId, setUploadFileId] = useState(null);
  const [loadingUploadDialog, setLoadingUploadDialog] = useState(false);
  const [errorUploadFile, setErrorUploadFile] = useState('');

  const { t } = useTranslation();
  const classes = useStyles();
  const { addToast } = useToasts();

  const getTemplates = async () => {
    try {
      const { data }: AxiosResponse<InteroperabilityTemplateSelectOptions[]> = await getInteroperabilityTemplates();
      setTemplates(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTemplates();
  }, [uploadFileId]);

  const handleUploadFile = (files: File[] | null) => {
    if (files) {
      setFile(files[0]);
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setErrorUploadFile('');
  };

  const handleRemoveFile = () => {
    setFile(null);
    setErrorUploadFile('');
  };

  const handleSubmitUploadFile = async (file?: File | null) => {
    try {
      if (file) {
        setLoadingUploadDialog(true);
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await uploadFileTemplates(formData);
        const { id, name } = data;
        onChange({ id, name } as TestResultsTemplate, 'interoperabilityTest.testResultsTemplate');
        setLoadingUploadDialog(false);
        handleCloseDialog();
        handleRequestSuccess(t('certifications.interoperabilityTest.uploadedFileSuccess'), addToast);
        return setUploadFileId(id);
      } else return null;
    } catch (err) {
      setLoadingUploadDialog(false);
      if (err.response?.data.message) {
        return setErrorUploadFile(err.response?.data.message);
      }
      handleCloseDialog();
      return addToast(t('certifications.interoperabilityTest.uploadedFileError'), {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Row gutter={20}>
        <Col xs={12}>
          <LabeledCheckbox
            noMargin
            name="interoperabilityTest.testingRequired"
            errors={errors}
            onChange={(value: boolean, checked: boolean) => {
              if (checked) {
                onChange(checked, 'interoperabilityTest.testingRequired');
              } else {
                onChange(
                  {
                    testingRequired: checked,
                    templateRequired: false,
                    testResultsTemplate: null,
                  } as Partial<CertificationDefinition>,
                  'interoperabilityTest',
                );
              }
            }}
            checked={data.testingRequired}
          >
            {t('certifications.interoperabilityTest.testingRequires')}
          </LabeledCheckbox>
        </Col>
        <Col xs={12}>
          {type === CertificationType.PRIMARY ? (
            <>
              <LabeledCheckbox
                noMargin
                noPadding
                name="isTemplateRequired"
                onChange={(value: boolean, checked: boolean) => {
                  if (data.testResultsTemplate) {
                    onChange(
                      {
                        testingRequired: data.testingRequired,
                        templateRequired: checked,
                        testResultsTemplate: null,
                      } as Partial<CertificationDefinition>,
                      'interoperabilityTest',
                    );
                  } else {
                    onChange(checked, 'interoperabilityTest.templateRequired');
                  }
                }}
                checked={data.templateRequired}
                disabled={!data.testingRequired}
              >
                {t('certifications.interoperabilityTest.templateRequired')}
              </LabeledCheckbox>
              {data.templateRequired ? (
                <div className={classes.uploadWrapper}>
                  <div style={{ marginBottom: 8 }}>
                    <AutocompleteField
                      data={templates}
                      name="interoperabilityTest.testResultsTemplate"
                      errors={errors}
                      value={data.testResultsTemplate?.id || null}
                      placeholder={'Choose test result template'}
                      onChange={(value: number) => {
                        const item = templates.find(template => template.id === value);
                        onChange(
                          item ? ({ id: item.id, name: item.name } as TestResultsTemplate) : null,
                          'interoperabilityTest.testResultsTemplate',
                        );
                      }}
                      onClean={() => onChange(null, 'interoperabilityTest.testResultsTemplate')}
                      valueKey="id"
                      labelKey="name"
                    />
                  </div>
                  <Typography variant="body2" component={'div'}>
                    <Trans
                      i18nKey="certifications.uploadFileTitle"
                      components={{
                        upload: (
                          <Button
                            variant="text"
                            startIcon={<CloudUploadOutlinedIcon />}
                            onClick={() => setOpen(true)}
                          />
                        ),
                      }}
                    />
                  </Typography>
                </div>
              ) : null}
            </>
          ) : null}
        </Col>
      </Row>
      <UploadFileDialog
        open={open}
        handleClose={handleCloseDialog}
        handleConfirm={handleSubmitUploadFile}
        filesLimit={1}
        fullWidth
        // @ts-ignore
        onChange={handleUploadFile}
        fileDetails={file}
        onRemoveFile={handleRemoveFile}
        disabledConfrimButton={!file}
        style={{ width: 600 }}
        loading={loadingUploadDialog}
        errorMessage={errorUploadFile}
      />
    </div>
  );
};
