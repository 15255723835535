import React from 'react';
import { PrereqAutocomplete } from '../../types';

export const Item = ({
  item,
  selectedElement = null,
}: {
  item?: PrereqAutocomplete;
  selectedElement?: string | null;
}) => {
  if (!item) {
    return <>{selectedElement}</>;
  }
  return (
    <p>
      {item.name}{' '}
      <span className="text-muted" style={{ verticalAlign: 'baseline' }}>
        ({item.role})
      </span>
    </p>
  );
};
