import { IterableObject } from '../types';

export const productStatuses: IterableObject<string> = {
  certified: 'CERTIFIED',
  qualified: 'QUALIFIED',
};

export const productStatusTitles: IterableObject<string> = {
  [productStatuses.certified]: 'Certified',
  [productStatuses.qualified]: 'Qualified',
};
