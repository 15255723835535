import { getDateDifference } from '../../../../../helpers';
import { LifeCycleInfo } from '../../types';
import { CertificationStatuses } from '../../../../../helpers/constants/certification-statuses';

export const getLifeCycleActiveStep = (data: LifeCycleInfo, status: CertificationStatuses): number => {
  if (status === CertificationStatuses.DRAFT) {
    return -1;
  }

  const array = [
    data.certificationStart,
    data.publicLaunch,
    data.firstRetirementPhaseDate,
    data.secondRetirementPhaseDate,
    data.thirdRetirementPhaseDate,
  ];

  // Get last activated date
  let lastActiveIndex = -1;
  array.forEach((date, index) => {
    if (date && getDateDifference(date) <= 0) {
      lastActiveIndex = index;
    }
  });

  // when all date are completed we need to set active as max + 1
  return lastActiveIndex + 1 === array.length ? array.length : lastActiveIndex;
};
