import React from 'react';
import Button from '@material-ui/core/Button';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { apiBaseUrl } from '../../../../../../../config';

const StyledButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      color: '#976083',
      backgroundColor: theme.palette.secondary.main,
    },
    paddingLeft: 12,
    paddingRight: 12,
    '&$disabled': {
      color: '#fff',
    },
  },
  disabled: {
    backgroundColor: '#bbb',
  },
}))(Button);

const StyledMenuList = withStyles(() => ({
  padding: {
    paddingTop: 4,
    paddingBottom: 4,
  },
}))(MenuList);

const StyledMenuItem = withStyles(theme => ({
  root: {
    minHeight: 32,
    fontSize: 14,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))(MenuItem);

interface Props {
  variantId: number;
  token: string | null;
  publicVariant?: boolean;
  certified?: boolean | null;
  upside?: boolean;
}

export const DownloadPreviewCertificateButton = ({
  variantId,
  token,
  publicVariant,
  certified,
  upside = false,
}: Props): React.ReactElement<string> => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const downloadFile = (fileUrl: string) => {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.click();
    handleClose();
  };

  return (
    <>
      <StyledButton onClick={handleClick} disabled={!certified} disableRipple={true}>
        <CloudDownloadOutlinedIcon />
        <div style={{ fontSize: 14, paddingLeft: 8 }}>{t('applications.review.downloadPreviewCertificate.button')}</div>
        {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
      </StyledButton>
      <Popover
        anchorOrigin={{
          vertical: upside ? 'top' : 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: upside ? 'bottom' : 'top',
          horizontal: 'right',
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={1}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <StyledMenuList>
            {publicVariant && (
              <StyledMenuItem
                disableTouchRipple={true}
                onClick={() => {
                  downloadFile(`${apiBaseUrl}/certificate/preview/public?variantId=${variantId}&token=${token}`);
                }}
              >
                <div style={{ color: '#575757' }}>{t('applications.review.downloadPreviewCertificate.menuPublic')}</div>
              </StyledMenuItem>
            )}
            <StyledMenuItem
              disableTouchRipple={true}
              onClick={() => {
                downloadFile(`${apiBaseUrl}/certificate/preview?variantId=${variantId}&token=${token}`);
              }}
            >
              <div style={{ color: '#575757' }}>{t('applications.review.downloadPreviewCertificate.menuPrivate')}</div>
            </StyledMenuItem>
          </StyledMenuList>
        </ClickAwayListener>
      </Popover>
    </>
  );
};
