/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';
import { DerivativeType, MajorEvents, ProductInfoStep, ComponentCombination } from '../../../api/application/types';
import { IterableObject } from '../../../helpers/types';
import {
  AppCapability,
  AppCertification,
  AppCertVersion,
  AppFlows,
  BasicCertification,
  CERTIFY_PRODUCT_COMPANY,
  ComponentCombinationWrapper,
  TestingException,
  TestingInfo,
  CoreCCException,
} from '../../../redux/modules/application-flow';
import { LabRequest, PrerequisiteOf } from '../atl-testing/types';
import { PreReqAutoCompleteResponse } from '../certification/types';
import { LabsTestingInfo } from '../../../api/application/get-labs-by-app-id';

export interface ApplicationActions {
  setLoadingData: (x: boolean) => void;
  getSummary: (data?: any) => void;
}

export interface CreateApplicationForm {
  type: AppFlows | '';
  majorEvents: MajorEvents;
  certifyProductCompany: CERTIFY_PRODUCT_COMPANY;
  companyContactInfo: {
    additionalEmail: string[];
    source: DerivativeType;
    owningCompany: { id: number; name: string; solutionProvider?: boolean } | null;
    creatingCompany: { id: number; name: string; solutionProvider?: boolean } | null;
  };
  allowedAppTypes: AppFlows[];
  acknowledged: boolean;
}

export enum ATLStatus {
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  IN_TESTING = 'IN_TESTING',
}

interface AtlItem {
  id: number;
  name: string;
  status: ATLStatus;
}

export interface ApplicationSummaryRecord {
  id: number;
  cid: string;
  productName: string;
  type: string;
  atl: AtlItem[];
  owningCompany: string;
  submittingCompany: string;
  sourceCid: string | null;
  sourceId: number | null;
  status: string;
  state: string;
  date: string;
  actions: ApplicationActions;
}

export interface ProductInfoRecord extends ProductInfoStep {
  cid: string;
}

export interface AppCompanyContactInfo {
  applicationOwner: {
    '@type': CERTIFY_PRODUCT_COMPANY;
  };
  submittingCompany: {
    id: number;
    name: string;
  } | null;
  owningCompany: {
    id: number;
    name: string;
    solutionProvider: boolean;
  } | null;
  creatingCompany: {
    id: number;
    name: string;
    solutionProvider: boolean;
  } | null;
  submittedBy: string;
  additionalEmail: string[];
  source: DerivativeType;
}

export interface CCInfoRecord {
  accessPoint?: OtherRolesProps[];
  configurator?: OtherRolesProps[];
  display?: OtherRolesProps[];
  easyMeshController?: EasyMeshController[];
  homeDesign?: HomeDesignProps[];
  source?: OtherRolesProps[];
  station?: OtherRolesProps[];
}

export interface BasicApplicationInfoRecord {
  id: number;
  '@type': AppFlows;
  productInfo: ProductInfoRecord;
  componentCombination: ComponentCombinationWrapper<CCInfoRecord>;
  companyContactInfo: AppCompanyContactInfo;
  testingInfo: TestingInfo;
  majorEvents: MajorEvents;
}

export interface ApplicationLabRequestDetails extends LabRequest {
  lab: { id: number; name: string };
}

export enum AppStatuses {
  DRAFT = 'DRAFT',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
  IN_TESTING = 'IN_TESTING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REVOKED = 'REVOKED',
}

export interface CapabilityInfo extends AppCapability {
  prerequisiteOf: PrerequisiteOf[];
  version: AppCertVersion;
}

export interface CertificationInfo extends AppCertification {
  prerequisiteOf: PrerequisiteOf[];
  capabilities: CapabilityInfo[];
}

export interface ApplicationInfoRecord extends BasicApplicationInfoRecord {
  status: AppStatuses;
  certifications: CertificationInfo[];
  labRequests: ApplicationLabRequestDetails[];
  testingException: TestingException | null;
  coreCCException: CoreCCException | null;
  previewCertificateToken: string | null;
  additionalInfo: AdditionalInfo;
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
export interface rfArchitectureProps {
  band?: string;
  rx?: number;
  tx?: number;

  [x: string]: any;
}

export interface ApProducts {
  field: string;
}

export interface AdditionalInfo {
  '@type': string;
  ctia: string;
  po: string;
  payer?: { id: number };
}

export interface EquipmentVendor {
  id: number;
  name: string;
}

export interface Equipment {
  id: number;
  cid: string;
  variantId: number;
  modelNumber: string;
  vendor: EquipmentVendor;
}

export interface HomeDesignProps {
  '@type': string;
  units?: number | null;
  floors?: number | null;
  squareFootage: string;
  apInterconnect: string;
  numberOfAccessPoints: number;
  equipment: Equipment[];
}

export interface EasyMeshController {
  '@type': string;
  os: string;
  sortOrder?: number;
}

export interface OtherRolesProps {
  '@type': string;
  roleName: string;
  antenna: string;
  firmware: string;
  core: {
    chipset: string;
    driver: string;
    rfArchitecture: rfArchitectureProps[];
  };
  os: string;
  physicalInterface: string;
  rfComponents: string;
  sortOrder?: number;
}

export interface CertificationValidationError {
  id: number;
  errorMessages: string[];
}

export interface StepPageProps {
  onNext?: () => void;
  saveData?: () => void;
  handleSetInitialValues?: (initCC: ComponentCombination) => void;
  onPrevious?: () => void;
  submitting?: boolean;
  setSubmitting?: Dispatch<SetStateAction<boolean>>;
  saving?: boolean;
  setSaving?: Dispatch<SetStateAction<boolean>>;
  onStepChange: (step: number) => void;
  validationErrors?: any[];
  setValidationErrors?: Dispatch<SetStateAction<CertificationValidationError[]>>;
  errors?: IterableObject<string>;
  setErrors?: (x: SetStateAction<IterableObject<string>>) => void;
}

export interface CategoryItem {
  id: number;
  name: string;
  displayOrder: number;
  groupId: number;
}

export interface Categories {
  id: number;
  name: string;
  displayOrder: number;
  categories: CategoryItem[];
}

export enum COMPANY_SUB_TYPE {
  ATL = 'ATL',
  CATL = 'CATL',
  MATL = 'MATL',
  MRCL = 'MRCL',
  MCTL = 'MCTL',
  STL = 'STL',
}

export interface TestingLab {
  id: number | null;
  companySubType?: COMPANY_SUB_TYPE;
  name: string;
  allowedTesting: {
    interoperability: boolean;
    conformance: boolean;
  };
}

export interface TestingItem {
  id: number;
  name: string;
}

export interface TestingDetails {
  conformance: TestingItem | null;
  interoperability: TestingItem | null;
}

export interface TestingInfoLab {
  version: {
    id: number;
  };
  testingDetails: TestingDetails;
}

export interface CertificationWithLabData {
  certification: BasicCertification & { testingType: LabsTestingInfo } & { version: { id: number } };
  requiredTesting: LabsTestingInfo;
  version: AppCertVersion;
  laboratories: TestingLab[];
}

export interface CertificationByCCVersion {
  id: number;
  name: string;
  key?: string;
  instructions: string;
  prequalified: {
    conformance: boolean;
    interoperability: boolean;
  };
  prerequisites: PreReqAutoCompleteResponse;
  previouslySelected: boolean;
  version: string;
}

export interface PrimaryCertificationByCCVersion extends CertificationByCCVersion {
  subs: CertificationByCCVersion[];
}

export interface CertificationByCC {
  name: string;
  role: string;
  versions: PrimaryCertificationByCCVersion[];
}

export interface TreeToEnable {
  id: number;
  enable?: boolean;
}

export interface TreeToUncheck {
  id: number;
  name?: string;
  keep: boolean;
}

export enum HistoryEventTypes {
  NOTE = 'NoteHistoryEvent',
  STATUS = 'StatusHistoryEvent',
}

export enum AppEventTypes {
  APPLICATION_CREATED = 'APPLICATION_CREATED',
  APPLICATION_UPDATED = 'APPLICATION_UPDATED',
  APPLICATION_QUALIFIED = 'APPLICATION_QUALIFIED',
  APPLICATION_CERTIFIED = 'APPLICATION_CERTIFIED',
  APPLICATION_RECALLED = 'APPLICATION_RECALLED',
  APPLICATION_RETURNED_FROM_STAFF_TO_MEMBER = 'APPLICATION_RETURNED_FROM_STAFF_TO_MEMBER',
  APPLICATION_SENT_TO_LAB = 'APPLICATION_SENT_TO_LAB',
  APPLICATION_SENT_TO_STAFF = 'APPLICATION_SENT_TO_STAFF',
  APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED',
  APPLICATION_WIZARD_PRODUCT_INFO_UPDATED = 'APPLICATION_WIZARD_PRODUCT_INFO_UPDATED',
  APPLICATION_WIZARD_CC_UPDATED = 'APPLICATION_WIZARD_CC_UPDATED',
  APPLICATION_WIZARD_CERTIFICATIONS_UPDATED = 'APPLICATION_WIZARD_CERTIFICATIONS_UPDATED',
  APPLICATION_WIZARD_CAPABILITIES_UPDATED = 'APPLICATION_WIZARD_CAPABILITIES_UPDATED',
  APPLICATION_WIZARD_LABS_UPDATED = 'APPLICATION_WIZARD_LABS_UPDATED',
  LAB_REQUEST_SENT = 'LAB_REQUEST_SENT',
  LAB_REQUEST_ACCEPTED = 'LAB_REQUEST_ACCEPTED',
  LAB_REQUEST_REJECTED = 'LAB_REQUEST_REJECTED',
  LAB_REQUEST_SENT_TO_STAFF = 'LAB_REQUEST_SENT_TO_STAFF',
  PRODUCT_MODEL_UPDATE = 'PRODUCT_MODEL_UPDATE',
  PRODUCT_MODEL_VARIANT_UPDATE = 'PRODUCT_MODEL_VARIANT_UPDATE',
}

export interface HistoryEvent {
  '@type': HistoryEventTypes;
  user: string;
  originalUser: string;
  date: string;
  eventType?: { key: string; label: string };
  applicationStatus?: { key: string; label: string };
  note?: string;
}

export enum testingFieldTypes {
  conformance = 'conformance',
  interoperability = 'interoperability',
}

export enum StaffAppStatuses {
  DRAFT = 'DRAFT',
  QUALIFIED = 'QUALIFIED',
  CERTIFIED = 'CERTIFIED',
  IN_PROGRESS = 'IN_PROGRESS',
  REVOKED = 'REVOKED',
}

export interface SingleAppStaffSummaryRecord {
  id: number;
  atl: AtlItem[];
  cid: string;
  date: string | null;
  source: DerivativeType;
  owningCompany: string;
  name: string;
  staffStatus: StaffAppStatuses | null;
  submittingCompany: string;
  type: string;
}

export enum ExceptionTypes {
  TEST_EXCEPTION = 'TEST_EXCEPTION',
  CC_EXCEPTION = 'CC_EXCEPTION',
  LAB_EXCEPTION = 'LAB_EXCEPTION',
}
