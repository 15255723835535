import { SortType } from '../../components/pages/test-case/types';
import React, { PropsWithChildren } from 'react';
import { Icon } from 'rsuite';
import styled from 'styled-components';
// @ts-ignore
import _var from '../../styles/_variables.scss';

const StyledLabel = styled.span`
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  color: ${_var.primary};
  font-size: 12px;
  font-weight: bold;
  padding-left: 5px;
`;

interface DataTableSortLabelProps {
  active: boolean;
  direction?: SortType;
  onSort: () => void;
  sortWrap?: boolean;
}

export const DataTableSortLabel = (props: PropsWithChildren<DataTableSortLabelProps>) => {
  const { active, direction, onSort, sortWrap = true, children } = props;
  return (
    <StyledLabel onClick={onSort} style={sortWrap ? {} : { display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
      {children}
      {active || <StyledIcon icon="sort" />}
      {active && direction === 'desc' && <StyledIcon icon="sort-desc" />}
      {active && direction === 'asc' && <StyledIcon icon="sort-up" />}
    </StyledLabel>
  );
};
