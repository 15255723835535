import React, { forwardRef, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, NavLinkProps, useRouteMatch } from 'react-router-dom';
import { RouteProps, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectUnsavedChanges } from '../../redux/modules/app/selectors';
import { Confirm } from '../../helpers/confirmationPopup/Confirm';
import { setUnsavedChanges } from '../../redux/modules/app';
import classnames from 'classnames';
import { Menu as MaterialMenu } from '@material-ui/core';
// @ts-ignore
import _var from '../../styles/_variables.scss';

interface MenuItemProps extends NavLinkProps {
  dropDown?: boolean;
  label?: string;
}

const useStyles = makeStyles({
  menu: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    height: '100%',
  },
  dropDownMenu: {
    '& .MuiPaper-root .MuiList-root li': {
      width: '280px',
      padding: '0 16px',
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '4px',
        height: '100%',
        borderRadius: '3px',
        backgroundColor: 'transparent',
        bottom: '0px',
        left: '0px',
        pointerEvents: 'none',
      },
      '&:hover': {
        backgroundColor: `${_var.secondary}`,
      },
      '& a': {
        lineHeight: '48px',
      },
    },
    '& .MuiPaper-root .MuiList-root $isActive': {
      backgroundColor: `${_var.secondary}`,
    },
  },
  menuItem: {
    height: '100%',
    padding: '0 0.6rem',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      height: '6px',
      width: 'calc(100% - 1.2rem)',
      borderRadius: '3px',
      backgroundColor: 'transparent',
      bottom: '5px',
      pointerEvents: 'none',
    },
    '&:hover': {
      '&:before': {
        backgroundColor: `${_var.secondary}`,
      },
      '& a': {
        color: `${_var.textGray}`,

        '&:hover, &:focus': {
          color: `${_var.textGray}`,
        },
      },
    },
    '& a': {
      display: 'block',
      lineHeight: '64px',
      color: `${_var.textGray}`,
      fontWeight: 'bold',
      textDecoration: 'none !important',
    },
  },
  isActive: {
    '&:before': {
      backgroundColor: `${_var.primary} !important`,
    },
  },
});

export const Menu = (props: PropsWithChildren<{}>) => {
  const classes = useStyles();
  return <ul className={classes.menu}>{props.children}</ul>;
};

// eslint-disable-next-line react/display-name
export const MenuItem = forwardRef(({ dropDown, ...props }: PropsWithChildren<MenuItemProps>, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const unsavedChanges = useSelector(selectUnsavedChanges);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActive = useRouteMatch({
    path: props.to,
    exact: props.exact,
  } as RouteProps);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMoveTo = (event: any) => {
    if (unsavedChanges.isUnsaved && unsavedChanges.url === url) {
      event.stopPropagation();
      event.preventDefault();
      return Confirm({
        onAccept: () => {
          dispatch(setUnsavedChanges(url, false));
          history.push(props.to as string);
        },
        title: t('common.placeholders.areYouSure'),
        message: t('common.unsavedDataLeaveWarning'),
      });
    }
  };

  if (dropDown) {
    return (
      <>
        <li className={classnames(classes.menuItem, isActive && classes.isActive)}>
          <NavLink onClick={handleClick} {...props}>
            {props.label}
          </NavLink>
        </li>

        <MaterialMenu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          ref={ref}
          className={classes.dropDownMenu}
          disablePortal
        >
          {props.children}
        </MaterialMenu>
      </>
    );
  }

  return (
    <li className={classnames(classes.menuItem, isActive && classes.isActive)}>
      <NavLink onClick={handleMoveTo} {...props} />
    </li>
  );
});
