import React from 'react';
import { ErrorObject } from '../../helpers/types';
import { SelectPickerProps } from 'rsuite/lib/SelectPicker';
import { SelectPicker } from 'rsuite';
import { withLabel } from './withLabel';
import { extractError } from './utils';

interface FieldProps extends SelectPickerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (value: any, item: any) => void;
}

const Field = ({ name, placeholder, value, ...props }: FieldProps) => {
  return (
    <SelectPicker placeholder={placeholder} name={name} type="text" className="rs-input" value={value} {...props} />
  );
};
const MemorizedField = React.memo(withLabel<FieldProps>(Field), (prevProps, nextProps) => {
  return (
    prevProps.data === nextProps.data &&
    JSON.stringify(prevProps.disabledItemValues) === JSON.stringify(nextProps.disabledItemValues) &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error &&
    prevProps.renderMenu === nextProps.renderMenu
  );
});

interface LabeledFieldProps extends FieldProps {
  errors?: ErrorObject;
}
export const LabeledSelect = ({ errors, name, ...props }: LabeledFieldProps) => {
  // @ts-ignore
  return <MemorizedField name={name} error={extractError(errors, name)} {...props} />;
};
