import CloseIcon from '@material-ui/icons/Close';
import React, { AllHTMLAttributes, PropsWithChildren } from 'react';
import { MaterialIconButton } from '../../shared-components/button/MaterialIconButton';
import { useTranslation } from 'react-i18next';

export const CloseButton = ({ onClick, disabled = false }: PropsWithChildren<AllHTMLAttributes<{}>>) => {
  const { t } = useTranslation();
  const MaterialIconButtonStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    fontSize: '14px',
    width: '18px',
    height: '18px',
    marginLeft: '20px',
    marginTop: '9px',
  };
  return (
    <span style={{ marginLeft: '-23px', marginRight: '23px' }} onClick={onClick} className="btn-close">
      <MaterialIconButton style={MaterialIconButtonStyle} iconComponent={CloseIcon} disabled={disabled}>
        {t('common.actions.close')}
      </MaterialIconButton>
    </span>
  );
};
