import { CertificationType, ProfileType, RunConditionTypes, UmbrellaInfo, VersionInfo } from './types';
import * as Yup from 'yup';
import i18next from 'i18next';
import { CertificationStatuses } from '../../../helpers/constants/certification-statuses';
import { tcStatuses } from '../../../helpers/constants/test-case-statuses';
import { GenericNodeType, NodeTypes } from '../../../shared-components/condition-builder/types';
import { StringSchema } from 'yup';
import { isDateAfter } from '../../../helpers';

export const oneOfEnum = <T>(enumObject: { [s: string]: T } | ArrayLike<T>) =>
  Yup.mixed<T>().oneOf(Object.values(enumObject), i18next.t('common.validation.required'));

export const generalInfoSchema = Yup.object().shape<UmbrellaInfo>({
  name: Yup.string()
    .trim()
    .required(i18next.t('common.validation.required')),
  visibleOnCertificate: Yup.mixed().nullable(),
  role: Yup.object().shape({
    id: Yup.number()
      .nullable()
      .required(i18next.t('common.validation.required')),
  }),
  classification: Yup.object()
    .shape({
      id: Yup.number(),
    })
    .nullable(),
  description: Yup.string().trim(),
  internalComments: Yup.string().trim(),
});

export const versionSchema = Yup.object().shape<VersionInfo>({
  version: Yup.string().trim(),
  visibleOnCertificate: Yup.mixed().nullable(),
  profile: Yup.object().shape({
    type: oneOfEnum<ProfileType>(ProfileType),
    name: Yup.string().when('type', {
      is: value => [ProfileType.PROFILE, ProfileType.ASD].includes(value),
      then: Yup.string()
        .required(i18next.t('common.validation.required'))
        .trim(),
    }),
  }),
  ecn: Yup.string().trim(),
  revision: Yup.string()
    .trim()
    .required(i18next.t('common.validation.required')),
  certificationStart: Yup.string()
    .nullable()
    .trim(),
  publicLaunch: Yup.string()
    .nullable()
    .trim()
    .when(['certificationStart'], (date1: string, schema: StringSchema) => {
      return schema.test({
        test: value => isDateAfter(value, [date1]),
        message: i18next.t('common.validation.dateSequence'),
      });
    }),
  firstRetirementPhaseDate: Yup.string()
    .nullable()
    .trim()
    .when(['certificationStart'], (date1: string, schema: StringSchema) => {
      return schema.test({
        test: value => isDateAfter(value, [date1]),
        message: i18next.t('common.validation.dateSequence'),
      });
    }),
  secondRetirementPhaseDate: Yup.string()
    .nullable()
    .trim()
    .when(['certificationStart', 'firstRetirementPhaseDate'], (date1: string, date2: string, schema: StringSchema) => {
      return schema.test({
        test: value => isDateAfter(value, [date1, date2]),
        message: i18next.t('common.validation.dateSequence'),
      });
    }),
  thirdRetirementPhaseDate: Yup.string()
    .nullable()
    .trim()
    .when(
      ['certificationStart', 'firstRetirementPhaseDate', 'secondRetirementPhaseDate'],
      (date1: string, date2: string, date3: string, schema: StringSchema) => {
        return schema.test({
          test: value => isDateAfter(value, [date1, date2, date3]),
          message: i18next.t('common.validation.dateSequence'),
        });
      },
    ),
  changesSummary: Yup.string().trim(),
  labNotes: Yup.string().trim(),
  memberNotes: Yup.string().trim(),
});

export const primaryCertVersionSchema = versionSchema.concat(
  Yup.object().shape({
    version: Yup.string()
      .trim()
      .required(i18next.t('common.validation.required')),
  }),
);

export const interoperabilityPrimaryCertTestSchema = Yup.object().shape({
  testingRequired: Yup.boolean(),
  templateRequired: Yup.boolean(),
  testResultsTemplate: Yup.mixed().when('templateRequired', {
    is: true,
    then: Yup.object()
      .nullable()
      .required(i18next.t('common.validation.required')),
    otherwise: Yup.object().nullable(),
  }),
});

export const prerequisitesSchema = Yup.array().of(
  Yup.object().shape({
    versions: Yup.array().min(1, i18next.t('certification.preRequisite.mustBePublished')),
  }),
);

export const testCriteriaSchema = (allowedStatuses = [tcStatuses.published]) =>
  Yup.array().of(
    Yup.object().shape({
      test: Yup.object().shape({
        id: Yup.number(),
        name: Yup.string()
          .trim()
          .required(i18next.t('common.validation.required')),
        status: Yup.string().oneOf(allowedStatuses),
      }),
      criteria: Yup.array().of(
        Yup.object().shape({
          comment: Yup.string().trim(),
          required: Yup.boolean(),
          compareOperation: Yup.string()
            .trim()
            .required(i18next.t('common.validation.required')),
          operand1: Yup.mixed()
            .when('measurement.type', {
              is: value => ['INTEGER', 'DOUBLE'].includes(value),
              then: Yup.number()
                .typeError(i18next.t('common.validation.number'))
                .when(['compareOperation', 'operand2'], {
                  is: (value, operand) => operand && value === 'BETWEEN',
                  then: Yup.number()
                    .lessThan(Yup.ref('operand2'), i18next.t('common.validation.lessMore'))
                    .typeError(i18next.t('common.validation.number')),
                }),
            })
            .when('measurement.type', {
              is: value => ['STRING', 'BOOLEAN'].includes(value),
              then: Yup.string().trim(),
            })
            .required(i18next.t('common.validation.required')),
          operand2: Yup.mixed().when('compareOperation', {
            is: 'BETWEEN',
            then: Yup.number()
              .required(i18next.t('common.validation.required'))
              .typeError(i18next.t('common.validation.number'))
              .when(['compareOperation', 'operand1'], {
                is: (value, operand) => value === 'BETWEEN' && operand,
                then: Yup.number()
                  .moreThan(Yup.ref('operand1'), i18next.t('common.validation.numberMore'))
                  .typeError(i18next.t('common.validation.number')),
              }),
          }),
          measurement: Yup.object().shape({
            id: Yup.number().required(i18next.t('common.validation.required')),
            name: Yup.string().required(i18next.t('common.validation.required')),
            type: Yup.string(),
            required: Yup.boolean(),
            comment: Yup.string(),
            measurementType: Yup.string(),
          }),
        }),
      ),
    }),
  );

// @ts-ignore
export const runConditionTreeSchema = Yup.object<GenericNodeType>().shape({
  '@type': oneOfEnum<NodeTypes>([NodeTypes.GROUP, NodeTypes.ELEMENT]),
  negation: Yup.boolean().required(),
  elements: Yup.array()
    .of(Yup.lazy(() => runConditionTreeSchema.default(undefined)))
    // .min(1),
    .test(
      'minNodeElementsCount',
      i18next.t('certifications.runCondition.certificationRequired'),
      (value?: GenericNodeType[]) => {
        return value === undefined || value.length > 0;
      },
    ),
});

export const testGroupSchema = (allowedTestCriteriaStatuses = [tcStatuses.published]) =>
  Yup.object().shape({
    name: Yup.string()
      .max(512, i18next.t('common.validation.length', { length: 512 }))
      .required(i18next.t('common.validation.required')),
    condition: Yup.object().shape({
      '@type': oneOfEnum<RunConditionTypes>([RunConditionTypes.ALWAYS_ON, RunConditionTypes.CONDITIONAL]),
    }),
    testCriteria: testCriteriaSchema(allowedTestCriteriaStatuses).required(),
  });

export const testGroupsSchema = (allowedTestCriteriaStatuses = [tcStatuses.published]) =>
  Yup.array().of(testGroupSchema(allowedTestCriteriaStatuses));

export const primaryCertDraftSchema = Yup.object().shape({
  '@type': oneOfEnum<CertificationType>([CertificationType.PRIMARY]),
  generalInfo: generalInfoSchema,
  version: primaryCertVersionSchema,
  capabilities: Yup.array(),
  prerequisites: Yup.array(),
  interoperabilityTest: interoperabilityPrimaryCertTestSchema,
  testGroups: Yup.array(),
});

export const primaryCertPublishSchema = Yup.object().shape({
  '@type': oneOfEnum<CertificationType>([CertificationType.PRIMARY]),
  generalInfo: generalInfoSchema.concat(
    Yup.object().shape({
      visibleOnCertificate: Yup.mixed()
        .nullable()
        .required(i18next.t('common.validation.required')),
      classification: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .nullable()
        .required(i18next.t('common.validation.required')),
    }),
  ),
  version: versionSchema.concat(
    Yup.object().shape({
      version: Yup.string()
        .trim()
        .required(i18next.t('common.validation.required')),
      visibleOnCertificate: Yup.mixed()
        .nullable()
        .required(i18next.t('common.validation.required')),
      certificationStart: Yup.string()
        .trim()
        .required(i18next.t('common.validation.required')),
      publicLaunch: Yup.string()
        .trim()
        .required(i18next.t('common.validation.required')),
    }),
  ),
  capabilities: Yup.array().of(
    Yup.object().shape({
      capability: Yup.object().shape({
        status: Yup.string().oneOf(['PUBLISHED'], i18next.t('certifications.noPublishedVersions')),
      }),
    }),
  ),
  prerequisites: prerequisitesSchema,
  interoperabilityTest: interoperabilityPrimaryCertTestSchema,
  testGroups: Yup.array(),
});

export const capabilityCertDraftSchema = Yup.object().shape({
  '@type': oneOfEnum<CertificationType>([CertificationType.CAPABILITY]),
  generalInfo: generalInfoSchema,
  version: versionSchema,
  capabilities: Yup.array(),
  prerequisites: Yup.array(),
  interoperabilityTest: interoperabilityPrimaryCertTestSchema,
  testGroups: testGroupsSchema(),
});

export const capabilityCertPublishSchema = Yup.object().shape({
  '@type': oneOfEnum<CertificationType>([CertificationType.CAPABILITY]),
  generalInfo: generalInfoSchema.concat(
    Yup.object().shape({
      visibleOnCertificate: Yup.mixed()
        .nullable()
        .required(i18next.t('common.validation.required')),
    }),
  ),
  version: versionSchema,
  capabilities: Yup.array(),
  prerequisites: prerequisitesSchema,
  interoperabilityTest: interoperabilityPrimaryCertTestSchema,
  testGroups: testGroupsSchema(),
});

export const getSchemeByTypeAndStatus = (type: string, status?: CertificationStatuses) => {
  if (type === CertificationType.PRIMARY) {
    if (status === CertificationStatuses.PUBLISHED) {
      return primaryCertPublishSchema;
    }
    return primaryCertDraftSchema;
  }
  if (status === CertificationStatuses.PUBLISHED) {
    return capabilityCertPublishSchema;
  }
  return capabilityCertDraftSchema;
};
