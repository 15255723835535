import React, { CSSProperties, HTMLAttributes, PropsWithChildren } from 'react';
import { Card } from '../../../shared-components';
import classnames from 'classnames';
import styled from 'styled-components';
// @ts-ignore
import _var from '../../../styles/_variables.scss';
import { NotesList } from '../notes/NotesList';
import BillingInfo from '../billing/BillingInfo';

const CardWrapper = styled(Card)`
  background-color: ${_var.bodyGray};
  margin-top: 20px;

  .el-card__body {
    padding: 0;
  }

  .summary-header,
  .summary-body {
    padding: 0.5rem 1.5rem;
    text-align: center;
    word-break: break-word;
  }

  .summary-header {
    background-color: ${_var.headerGray};
    border-radius: 4px 4px 0 0;
    color: #fff;

    h4 {
      font-size: 1.125rem;
    }
  }
`;

export const BoldParagraph = styled.p`
  color: ${_var.labelGray};
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
`;
export const Article = styled.article`
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: ${_var.textGray};
  font-size: 1rem;
  text-align: left;
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

interface Props {
  title: string;
  className?: string;
  style?: CSSProperties;
  withBorder?: boolean;
  applicationId?: number | null;
  includeBilling?: boolean;
}

export const SummaryTemplate = ({
  title,
  children,
  className = '',
  style,
  withBorder = false,
  applicationId = null,
  includeBilling = false,
}: HTMLAttributes<{}> & PropsWithChildren<Props>) => {
  return (
    <>
      <CardWrapper
        style={style}
        className={classnames('summary-template', className, { 'with-header-border': withBorder })}
      >
        <section className={classnames('summary-header', 'mb-2')}>
          <h4>{title}</h4>
        </section>
        <section className={classnames('summary-body', 'mb-1', { 'py-2': withBorder })}>{children}</section>
      </CardWrapper>
      {includeBilling && <BillingInfo />}
      <NotesList applicationId={applicationId} />
    </>
  );
};

export const SummaryItem = ({ children, title }: PropsWithChildren<{ title: string }>) => (
  <Article>
    <BoldParagraph>{title}</BoldParagraph>
    {children}
  </Article>
);
