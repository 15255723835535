// @ts-ignore
import _var from '../../styles/_variables.scss';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const AnchorStyles = `
  color: ${_var.primary};
  font-weight: bold;

  &:link,
  &:visited,
  &:active {
    color: ${_var.primary};

    &:hover {
      color: ${_var.primary};
      opacity: 0.8;
    }
  }
`;

export const Anchor = styled.a`
  ${AnchorStyles}
`;

export const AnchorLink = styled(Link)`
  ${AnchorStyles}
`;
