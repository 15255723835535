import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Grid } from 'rsuite';
import styled from 'styled-components';

import { RowWithMargin } from '../../../application/partials';
import { AccountInfo, getAccountSubTypeFullName } from '../../../staff/types';
import { DetailsItem } from '../../../../partials';
import { YesNoColumn } from '../../../../../shared-components';

const RowWithMarginBottomBorder = styled(Row)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #bbbbbb;
  margin-left: 0px;
`;

interface GeneralInfomationProps {
  companyFrom: AccountInfo;
  companyTo: AccountInfo;
}

export const GeneralInfomation = (props: GeneralInfomationProps) => {
  const { t } = useTranslation();

  const { companyFrom, companyTo } = props;

  return (
    <Grid fluid>
      <RowWithMargin gutter={20} style={{ marginBottom: 0 }}>
        <Col xs={12}>
          <h4 style={{ lineHeight: '40px' }}>{t('admin.changeOwningCompany.companyInfo.title')}</h4>
        </Col>
      </RowWithMargin>
      <RowWithMarginBottomBorder gutter={20}>
        <Col xs={6}>
          <DetailsItem label={t('admin.changeOwningCompany.companyInfo.fromCompany')} value={companyFrom?.name} />
        </Col>
        <Col xs={6}>
          <DetailsItem
            label={t('admin.changeOwningCompany.companyInfo.subMembershipType')}
            value={t(getAccountSubTypeFullName(companyFrom?.subtype))}
          />
        </Col>
        <Col xs={6}>
          <DetailsItem
            label={t('admin.changeOwningCompany.companyInfo.solutionProvider')}
            value={<YesNoColumn value={companyFrom?.qualifiedSolutionProvider} />}
          />
        </Col>
        <Col xs={6}>
          <DetailsItem
            label={t('admin.changeOwningCompany.companyInfo.companyStatus')}
            value={
              companyFrom?.active
                ? t('admin.changeOwningCompany.companyInfo.active')
                : t('admin.changeOwningCompany.companyInfo.inactive')
            }
          />
        </Col>
      </RowWithMarginBottomBorder>
      <RowWithMarginBottomBorder gutter={20}>
        <Col xs={6}>
          <DetailsItem label={t('admin.changeOwningCompany.companyInfo.toCompany')} value={companyTo?.name} />
        </Col>
        <Col xs={6}>
          <DetailsItem
            label={t('admin.changeOwningCompany.companyInfo.subMembershipType')}
            value={t(getAccountSubTypeFullName(companyTo?.subtype))}
          />
        </Col>
        <Col xs={6}>
          <DetailsItem
            label={t('admin.changeOwningCompany.companyInfo.solutionProvider')}
            value={<YesNoColumn value={companyTo?.qualifiedSolutionProvider} />}
          />
        </Col>
        <Col xs={6}>
          <DetailsItem
            label={t('admin.changeOwningCompany.companyInfo.companyStatus')}
            value={
              companyTo?.active
                ? t('admin.changeOwningCompany.companyInfo.active')
                : t('admin.changeOwningCompany.companyInfo.inactive')
            }
          />
        </Col>
      </RowWithMarginBottomBorder>
    </Grid>
  );
};
