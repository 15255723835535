import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styled from 'styled-components';

import { SummaryItem, Article, BoldParagraph } from './SummaryTemplate';
import { renderTreeToggleIconFunction } from '../../../shared-components/theme';
import { sortByProperty } from '../../../helpers/sort-by-property';
import { jsonCopy } from '../../../helpers';
import { CertificationStatuses } from '../../../helpers/constants/certification-statuses';
import { WarningTooltip } from '../../../shared-components/tooltip/WarningTooltip';
import { getRetirementStatus } from '../../../helpers/constants';

const CertificationWrapper = styled.div`
  display: flex;
  &.with-subs {
    display: block;

    .primary {
      margin-left: 0;
      display: flex;

      .icon-wrapper,
      .content {
        flex: 1;
      }

      .icon-wrapper {
        max-width: 20px;
        margin-right: 0.5rem;
      }
    }
  }

  .primary {
    margin-left: 1.8rem;
    margin-top: 0.6rem;
  }
  .sub {
    margin-left: 2.4rem;
  }
`;

const ContentWrapper = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
`;

interface GroupedCertifications {
  id: number;
  name: string;
  capabilities: { id: number; name: string }[];
  status?: CertificationStatuses;
}

export interface SummaryCertifications {
  id: number;
  name: string;
  roleName: string;
  capabilities: { id: number; name: string }[];
  status?: CertificationStatuses;
}

export const GroupedCertifications = ({ certifications }: { certifications: SummaryCertifications[] }) => {
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const defaultValue: { role: string; records: GroupedCertifications[] }[] = [];

  const groupedCertifications = certifications.reduce((acc, next) => {
    const roleItem = acc.find(certification => certification.role === next.roleName);
    const newRecord = {
      id: next.id,
      name: next.name,
      capabilities: next.capabilities || [],
      status: next.status,
    };
    if (roleItem) {
      roleItem.records.push(newRecord);
    } else {
      acc.push({ role: next.roleName, records: [newRecord] });
    }
    return acc;
  }, defaultValue);

  const handleExpand = (id: number, isSubs: boolean) => {
    if (isSubs) {
      setExpandedRows(prevState => (prevState.includes(id) ? prevState.filter(num => num !== id) : [...prevState, id]));
    }
  };

  const isSomeSubs = groupedCertifications.some(cert => cert.records.some(record => record.capabilities.length > 0));

  const getTooltipTextStatus = (status: CertificationStatuses) => {
    switch (status) {
      case CertificationStatuses.FIRST_RETIREMENT_PHASE:
        return t('certification.firstRetirementPhase');
      case CertificationStatuses.SECOND_RETIREMENT_PHASE:
        return t('certification.secondRetirementPhase');
      case CertificationStatuses.THIRD_RETIREMENT_PHASE:
        return t('certification.thirdRetirementPhase');
      default:
        return '';
    }
  };

  return (
    <SummaryItem title={t('applications.summary.sideBar.productCertifications')}>
      {groupedCertifications.map((certification, key) => (
        <Article key={key}>
          <BoldParagraph>
            {t('applications.certifications.role')}: {certification.role}
          </BoldParagraph>
          {jsonCopy(certification.records)
            .sort((a, b) =>
              sortByProperty(a, b, {
                sortColumn: 'name',
                sortType: 'asc',
              }),
            )
            .map((cert, index) => {
              const isSubs = cert.capabilities.length > 0;
              const isOpen = expandedRows.includes(cert.id);
              return (
                <CertificationWrapper
                  key={index}
                  className={classNames({
                    'with-subs': isSubs,
                  })}
                >
                  <div
                    className={classNames('primary', { 'ml-0': !isSomeSubs, pointer: isSubs })}
                    onClick={() => handleExpand(cert.id, isSubs)}
                  >
                    <span className="text-center icon-wrapper">{renderTreeToggleIconFunction(isOpen, isSubs)}</span>
                    <ContentWrapper>
                      {cert.name}
                      {cert.status && getRetirementStatus(cert.status) ? (
                        <WarningTooltip
                          status={cert.status}
                          variant="rounded"
                          translationKey={getTooltipTextStatus(cert.status)}
                        />
                      ) : null}
                    </ContentWrapper>
                  </div>
                  {isOpen
                    ? cert.capabilities.map((subCert, key) => (
                        <div key={key} className="sub">
                          <span className="content">{subCert.name}</span>
                        </div>
                      ))
                    : null}
                </CertificationWrapper>
              );
            })}
        </Article>
      ))}
    </SummaryItem>
  );
};
