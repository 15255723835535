import React from 'react';
import { RsField, RsFieldProps } from './RsField';

const Field = (props: RsFieldProps) => <RsField {...props} />;

export const RsFieldMemo = React.memo(Field, (prevProps, nextProps) => {
  const disableChanged = prevProps.disabled === nextProps.disabled;
  const valuesErrorsChanged =
    prevProps.errors[prevProps.name as string] === nextProps.errors[nextProps.name as string] &&
    prevProps.value === nextProps.value;
  if (prevProps.hasOwnProperty('checked')) {
    return prevProps.checked === nextProps.checked && valuesErrorsChanged && disableChanged;
  }
  if (prevProps.hasOwnProperty('data')) {
    const isDataLoaded = prevProps.data.length === nextProps.data.length;
    if (prevProps.hasOwnProperty('disabledItemValues')) {
      return (
        valuesErrorsChanged &&
        isDataLoaded &&
        prevProps.disabledItemValues.length === nextProps.disabledItemValues.length &&
        disableChanged
      );
    }
    return valuesErrorsChanged && isDataLoaded && disableChanged;
  }

  return valuesErrorsChanged && disableChanged;
});
