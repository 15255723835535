import { useEffect } from 'react';
import { getLoginUrl } from '../../../helpers/get-login-url';
import '../../../styles/LoginPage.scss';
import { RouteComponentProps, withRouter } from 'react-router';

const SSORedirect = (props: RouteComponentProps) => {
  useEffect(() => {
    window.location.href = getLoginUrl(props.location.pathname);
  });
  return null;
};

export default withRouter(SSORedirect);
