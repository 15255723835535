import React from 'react';

import { EasyMesh } from './EasyMesh';
import { DefaultRoles } from './DefaultRoles';
import { roleNameMapping } from '../../data/constants';
import { DefaultRolesRows } from './DefaultRolesRows';
import { CCInfoRecord } from '../../types';
import { HomeDesign } from './HomeDesign';
import { CombinationVariantDetails } from '../../../../../redux/modules/application-flow';
import { LoadedFromBreadcrumps } from '../../../../partials/LoadedFromBreadcrumbs.tsx';

export const CCDetails = ({
  cc,
  isSubmitResult = false,
  rowsStyle = false,
  isEditable = true,
  showPublicIcon = false,
  ccVariantSource,
}: {
  cc: CCInfoRecord;
  isSubmitResult?: boolean;
  rowsStyle?: boolean;
  isEditable?: boolean;
  showPublicIcon?: boolean;
  ccVariantSource?: CombinationVariantDetails;
}) => {
  const DefaultRolesComponent = rowsStyle ? DefaultRolesRows : DefaultRoles;

  return (
    <>
      {!!ccVariantSource && (
        <div style={{ marginTop: '1rem' }}>
          <LoadedFromBreadcrumps items={ccVariantSource} />
        </div>
      )}
      {cc?.accessPoint?.map((record, index) => (
        <DefaultRolesComponent
          key={index}
          record={record}
          roleProperty={roleNameMapping.accessPoint}
          isSubmitResult={isSubmitResult}
          isEditable={isEditable}
          showPublicIcon={showPublicIcon}
        />
      ))}
      {cc?.station?.map((record, index) => (
        <DefaultRolesComponent
          key={index}
          record={record}
          roleProperty={roleNameMapping.station}
          isSubmitResult={isSubmitResult}
          isEditable={isEditable}
          showPublicIcon={showPublicIcon}
        />
      ))}
      {cc?.configurator?.map((record, index) => (
        <DefaultRolesComponent
          key={index}
          record={record}
          roleProperty={roleNameMapping.configurator}
          isSubmitResult={isSubmitResult}
          isEditable={isEditable}
          showPublicIcon={showPublicIcon}
        />
      ))}
      {cc?.source?.map((record, index) => (
        <DefaultRolesComponent
          key={index}
          record={record}
          roleProperty={roleNameMapping.source}
          isSubmitResult={isSubmitResult}
          isEditable={isEditable}
          showPublicIcon={showPublicIcon}
        />
      ))}
      {cc?.display?.map((record, index) => (
        <DefaultRolesComponent
          key={index}
          record={record}
          roleProperty={roleNameMapping.display}
          isSubmitResult={isSubmitResult}
          isEditable={isEditable}
          showPublicIcon={showPublicIcon}
        />
      ))}
      {cc?.easyMeshController?.map((record, index) => (
        <EasyMesh key={index} record={record} showPublicIcon={showPublicIcon} />
      ))}
      {cc?.homeDesign?.map((record, index) => (
        <HomeDesign key={index} record={record} showPublicIcon={showPublicIcon} />
      ))}
    </>
  );
};
