import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Icon } from 'rsuite';
import { customIconsSet } from '../../helpers/constants';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  user-select: none;
`;

interface DataTableExpandButtonProps extends HTMLAttributes<{}> {
  open: boolean;
}

export const DataTableExpandButton = ({ open, ...rest }: DataTableExpandButtonProps) => (
  <StyledIcon
    icon={open ? customIconsSet.MINUS_SQUARE : customIconsSet.PLUS_SQUARE}
    className={open ? 'is-expanded' : 'is-collapsed'}
    size="lg"
    {...rest}
  />
);
