import React from 'react';
import { Col } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { DetailsItem } from '../../../../../../partials';
import { CategoryItem } from '../../../../../application/types';
import { AppFlows, ApplicationFlowState } from '../../../../../../../redux/modules/application-flow';
import { RowWithMargin, Section } from '../../../../../application/partials';
import { ImagePreview } from '../../../../../../../shared-components/helper-components/ImagePreview';
import { buildDownloadUrl } from '../../../../../../../helpers/build-download-url';
import { WrapWithPublicIcon } from '../../../../../../../shared-components/helper-components/WrapWithPublicIcon';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { ProductInfoStep } from '../../../../../../../api/application/types';
import { isProductOfSolutionProvider } from '../../helpers';

export const ViewProductSection = ({ data }: { data: ProductInfoStep }) => {
  const { t } = useTranslation();

  const { ...application } = useSelector<RootReducer, ApplicationFlowState>(
    state => state.applicationFlow,
    shallowEqual,
  );

  const showQualifiedSolutionFields =
    isProductOfSolutionProvider(application) && application['@type'] !== AppFlows.DERIVATIVE;

  const isVariant = application['@type'] === AppFlows.VARIANT;

  const isQualifying = application.productInfo.certified === false;

  const isQualifiedSolution = application.productInfo.qualifiedSolution;

  return (
    <Section>
      {showQualifiedSolutionFields ? (
        <RowWithMargin gutter={20}>
          <Col xs={12}>
            <DetailsItem
              label={t('applications.info.isQualifiedSolution')}
              value={
                (application.productInfo.qualifiedSolution ? t('common.options.yes') : t('common.options.no')) as string
              }
            />
          </Col>
          <Col xs={12}>
            {isQualifiedSolution && isVariant && isQualifying ? (
              <DetailsItem
                label={t('applications.info.isWiFiCertified')}
                value={
                  (application.productInfo.modelVariants[0].draftCertified
                    ? t('common.options.yes')
                    : t('common.options.no')) as string
                }
              />
            ) : (
              <DetailsItem
                label={t('applications.info.isWiFiCertified')}
                value={(application.productInfo.certified ? t('common.options.yes') : t('common.options.no')) as string}
              />
            )}
          </Col>
        </RowWithMargin>
      ) : null}
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem label={t('applications.info.cid')} value={data.cid} />
        </Col>
        <Col xs={12}>
          <DetailsItem
            label={t('applications.summary.company')}
            value={application.companyContactInfo?.owningCompany?.name}
          />
        </Col>
      </RowWithMargin>
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem label={<WrapWithPublicIcon content={t('applications.info.name')} />} value={data.name} />
        </Col>
        <Col xs={12}>
          <DetailsItem
            label={<WrapWithPublicIcon content={t('applications.info.modelNumber')} />}
            value={data.modelNumber}
          />
        </Col>
      </RowWithMargin>
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem
            label={<WrapWithPublicIcon content={t('applications.info.primaryCategory')} />}
            value={(data.primaryCategory as CategoryItem)?.name}
          />
        </Col>
        <Col xs={12}>
          <DetailsItem
            label={<WrapWithPublicIcon content={t('applications.info.additionalCategory')} />}
            value={(data.additionalCategories as CategoryItem[]).map(item => item.name).join(', ')}
          />
        </Col>
      </RowWithMargin>
      <DetailsItem className="mb-1" label={<WrapWithPublicIcon content={t('applications.info.url')} />}>
        {data.url ? (
          <a href={data.url} target="_blank" rel="noopener noreferrer">
            {data.url}
          </a>
        ) : null}
      </DetailsItem>
      <DetailsItem
        className="mb-1"
        label={<WrapWithPublicIcon content={t('applications.info.description')} />}
        value={data.description}
      />
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem label={<WrapWithPublicIcon content={t('applications.info.image')} />}>
            {data.image ? (
              <div style={{ display: 'flex', alignItems: 'top', wordBreak: 'break-all' }}>
                <ImagePreview src={buildDownloadUrl(data.image)} className="mr-1 ml-0" />
              </div>
            ) : null}
          </DetailsItem>
        </Col>
      </RowWithMargin>
    </Section>
  );
};
