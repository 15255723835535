import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { DataTableSortLabel } from './DataTableSortLabel';
import { useStickyStyles } from './DataTableStickyWrapper';
import { DataTableColumnProps } from './DataTableColumn';
import { SortingOptions } from '../../components/pages/test-case/types';
import classNames from 'classnames';

export const StyledTableHeadCell = withStyles(() => ({
  head: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
}))(TableCell);

export interface DataTableHeadCellProps<T> {
  settings: DataTableColumnProps<T>;
  sorting?: SortingOptions;
  rowSpan?: number;
  className?: string;
  getSortHandler: (property: string) => () => void;
}

export function DataTableHeadCell<T>(props: DataTableHeadCellProps<T>) {
  const { settings: column, sorting, getSortHandler, rowSpan, className } = props;
  const stickyClasses = useStickyStyles();

  return (
    <StyledTableHeadCell
      key={column.field}
      rowSpan={rowSpan}
      className={classNames(stickyClasses.headerColumn, className)}
      style={
        column.style
          ? {
              ...column.style,
              width: column.width ?? 100,
              minWidth: column.minWidth ?? 0,
              textAlign: column.textAlign ?? 'left',
            }
          : {
              width: column.width ?? 100,
              minWidth: column.minWidth ?? 0,
              textAlign: column.textAlign ?? 'left',
            }
      }
    >
      {sorting !== undefined && column.sortable ? (
        <DataTableSortLabel
          active={sorting?.sortColumn === column.field}
          onSort={getSortHandler(column.field)}
          direction={sorting?.sortColumn === column.field ? sorting?.sortType : 'asc'}
          sortWrap={column.sortWrap}
        >
          {column.label}
        </DataTableSortLabel>
      ) : (
        <span>{column.label}</span>
      )}
    </StyledTableHeadCell>
  );
}
