import React from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ReportProblemSharpIcon from '@material-ui/icons/ReportProblemSharp';

import { AppStatuses, StaffAppStatuses } from '../../../components/pages/application/types';
import { StaffSummaryStatuses } from '../../../components/pages/staff/types';
import { getLifeCycleStatusKey, statusColors } from '../../../helpers/constants';
import { CertificationStatuses } from '../../../helpers/constants/certification-statuses';
import { CertificationType, certificationTypeTitles } from '../../../components/pages/certification/types';
import { RoleType, RoleTypeTitles } from '../../../redux/modules/roles/types';
// @ts-ignore
import _var from '../../../styles/_variables.scss';

const WarningIconOutlined = styled(ReportProblemOutlinedIcon)<{ status: string }>`
  color: ${({ status }) => (status in statusColors ? statusColors[status].color : _var.textLightGray)};
  margin-right: 7px;
  && {
    font-size: 22px;
  }
`;

const IndigoWarningIcon = styled(ReportProblemSharpIcon)<{ status: string }>`
  color: ${({ status }) => (status in statusColors ? statusColors[status].color : _var.textLightGray)};
  margin-right: 7px;
  && {
    font-size: 22px;
  }
`;

export const ApplicationTypeData = [
  { label: i18next.t('filters.condition.applicationType.label.new'), value: '1' },
  { label: i18next.t('filters.condition.applicationType.label.variant'), value: '2' },
  { label: i18next.t('filters.condition.applicationType.label.derivative'), value: '3' },
];

export const CanCreateDerivativeData = [
  {
    label: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <WarningIconOutlined status={getLifeCycleStatusKey(CertificationStatuses.THIRD_RETIREMENT_PHASE)} />
        <div style={{ alignSelf: 'center' }}>{i18next.t('filters.condition.canCreateDerivative.label.phase3')}</div>
      </div>
    ),
    value: 'false',
  },
];

export const WorstCertificationVersionStatusData = [
  {
    label: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CheckCircleIcon style={{ fill: _var.statusPublishedText, fontSize: 20, marginRight: 7 }} />
        <div style={{ alignSelf: 'center' }}>
          {i18next.t('filters.condition.worstCertificationVersionStatus.label.published')}
        </div>
      </div>
    ),
    value: CertificationStatuses.PUBLISHED,
  },
  {
    label: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IndigoWarningIcon status={getLifeCycleStatusKey(CertificationStatuses.FIRST_RETIREMENT_PHASE)} />
        <div style={{ alignSelf: 'center' }}>
          {i18next.t('filters.condition.worstCertificationVersionStatus.label.phase1')}
        </div>
      </div>
    ),
    value: CertificationStatuses.FIRST_RETIREMENT_PHASE,
  },
  {
    label: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IndigoWarningIcon status={getLifeCycleStatusKey(CertificationStatuses.SECOND_RETIREMENT_PHASE)} />
        <div style={{ alignSelf: 'center' }}>
          {i18next.t('filters.condition.worstCertificationVersionStatus.label.phase2')}
        </div>
      </div>
    ),
    value: CertificationStatuses.SECOND_RETIREMENT_PHASE,
  },
  {
    label: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IndigoWarningIcon status={getLifeCycleStatusKey(CertificationStatuses.THIRD_RETIREMENT_PHASE)} />
        <div style={{ alignSelf: 'center' }}>
          {i18next.t('filters.condition.worstCertificationVersionStatus.label.phase3')}
        </div>
      </div>
    ),
    value: CertificationStatuses.THIRD_RETIREMENT_PHASE,
  },
];

export const BooleanTypeData = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const DecisionTypeData = [
  {
    label: i18next.t('filters.condition.decision.label.certified'),
    value: i18next.t('filters.condition.decision.label.certified'),
  },
  {
    label: i18next.t('filters.condition.decision.label.qualified'),
    value: i18next.t('filters.condition.decision.label.qualified'),
  },
];

export const AmountData = [
  { label: '100', value: '100' },
  { label: '600', value: '600' },
  { label: '2,000', value: '2000' },
  { label: '2,500', value: '2500' },
  { label: '3,000', value: '3000' },
  { label: '4,000', value: '4000' },
  { label: '4,500', value: '4500' },
  { label: '5,000', value: '5000' },
  { label: '5,500', value: '5500' },
  { label: '6,900', value: '6900' },
  { label: '7,400', value: '7400' },
  { label: '7,500', value: '7500' },
];

export const ApplicationStatusData = [
  { label: StaffSummaryStatuses.DRAFT as string, value: StaffAppStatuses.DRAFT as string },
  { label: StaffSummaryStatuses.IN_PROGRESS as string, value: StaffAppStatuses.IN_PROGRESS as string },
  { label: StaffSummaryStatuses.PENDING_APPROVAL as string, value: AppStatuses.PENDING_APPROVAL as string },
  { label: StaffSummaryStatuses.CERTIFIED as string, value: StaffAppStatuses.CERTIFIED as string },
  { label: StaffSummaryStatuses.QUALIFIED as string, value: StaffAppStatuses.QUALIFIED as string },
  { label: StaffSummaryStatuses.REVOKED as string, value: StaffAppStatuses.REVOKED as string },
];

export const CertificationTypeData = [
  { label: certificationTypeTitles[CertificationType.PRIMARY], value: CertificationType.PRIMARY as string },
  { label: certificationTypeTitles[CertificationType.CAPABILITY], value: CertificationType.CAPABILITY as string },
];

export const RolesData = [
  { label: RoleTypeTitles[RoleType.ACCESS_POINT], value: `${RoleType.ACCESS_POINT}` },
  { label: RoleTypeTitles[RoleType.STATION], value: `${RoleType.STATION}` },
  { label: RoleTypeTitles[RoleType.CONFIGURATOR], value: `${RoleType.CONFIGURATOR}` },
  { label: RoleTypeTitles[RoleType.SOURCE], value: `${RoleType.SOURCE}` },
  { label: RoleTypeTitles[RoleType.DISPLAY], value: `${RoleType.DISPLAY}` },
  { label: RoleTypeTitles[RoleType.CONTROLLER], value: `${RoleType.CONTROLLER}` },
  { label: RoleTypeTitles[RoleType.WIFI_HOME_DESIGN], value: `${RoleType.WIFI_HOME_DESIGN}` },
];

export const HasDraftCapabilityData = [
  { label: i18next.t('filters.condition.hasDraftCapability.label.draft'), value: 'true' },
  { label: i18next.t('filters.condition.hasDraftCapability.label.published'), value: 'false' },
];
