import React from 'react';
import i18next from 'i18next';
import styled from 'styled-components';

const StyledBlock = styled.div`
  padding: 3rem;
  text-align: center;
`;

export const DataTableNoDataMessage = ({ text = i18next.t('common.placeholders.noDataFound') }: { text?: string }) => (
  <StyledBlock>{text}</StyledBlock>
);
