import React from 'react';
import { Button } from 'rsuite';
import { ApplicationLabRequestDetails } from '../../application/types';
import { useTranslation } from 'react-i18next';
import { showAddNoteModal } from '../../../../redux/modules/modals';
import { NoteActionTypes } from '../../../../redux/modules/modals/constants';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { LRStatus } from '../../../../helpers/constants';
import { useHistory } from 'react-router';
import { getStoredSummaryUrl } from '../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
// @ts-ignore
import _vars from '../../../../styles/_variables.scss';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

export const useStyles = makeStyles(() => ({
  precertified: {
    background: _vars.lightGray,
  },
}));

interface TableFooterComponentProps {
  labRequests: ApplicationLabRequestDetails[];
  onLrUpdate: () => void;
  labs: { id: number; name: string; requestId: number }[];
}

export const TestingResultTableFooter = (props: TableFooterComponentProps) => {
  const { labRequests, onLrUpdate, labs } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();

  const LRStatuses = labRequests.reduce((prev, lr) => {
    prev[lr.id] = lr.status;
    return prev;
  }, Object.create(null));

  const onReturnSuccess = (id: number) => {
    addToast(`Application was successfully returned to the lab.`, {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    if (!labRequests?.some(lr => lr.id !== id && lr.status === LRStatus.PENDING_APPROVAL)) {
      history.push(getStoredSummaryUrl('pending-approval'));
      return;
    }
    onLrUpdate();
  };

  const returnToLab = (id: number, name: string) =>
    dispatch(
      showAddNoteModal(
        {
          appId: id,
          onSuccess: () => onReturnSuccess(id),
          modalTitle: t('notes.returnToLab.title', { name }),
        },
        NoteActionTypes.RETURN_TO_LAB,
      ),
    );

  return (
    <TableRow>
      <TableCell />
      <TableCell />
      <TableCell />
      {labs.map(({ name, requestId }) => (
        <TableCell key={requestId} colSpan={2} style={{ textAlign: 'center' }}>
          {LRStatuses[requestId] === LRStatus.PENDING_APPROVAL && (
            <Button appearance="primary" size="sm" onClick={() => returnToLab(requestId, name)}>
              {t('staff.returnToLab')}
            </Button>
          )}
        </TableCell>
      ))}
      <TableCell />
    </TableRow>
  );
};
