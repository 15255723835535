import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { PageTemplate } from '../../partials';
import { confirmAction, getStoredSummaryUrl, jsonCopy, mergeObjects, prepareYupModel } from '../../../helpers';
import { CertificationType, DetailedCertificationRecord } from './types';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { BtnLoadingState } from '../../../helpers/constants/loading-states';
import { AxiosError, AxiosResponse } from 'axios';
import { createNewCertification, publishCertification } from '../../../api/certification';
import { useToasts } from 'react-toast-notifications';
import { ErrorObject, MixedValidationErrorResponse } from '../../../helpers/types';
import { CertificationStatuses, certificationStatuses } from '../../../helpers/constants/certification-statuses';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import {
  AuditingInfo,
  CapabilitiesSection,
  UmbrellaSection,
  VersionSection,
  InteroperabilityTestingSection,
  ConformanceTestingSection,
  PrerequisiteSection,
} from './partials';
import { handleRequestSuccess } from '../../../helpers/handle-request-success';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '../../../redux/rootReducer';
import {
  certificationReducer as reducer,
  CertificationState,
  CertificationDefinition,
  setValidationErrors,
  updateCertificationProperty,
} from '../../../redux/modules/certification';
import { getSchemeByTypeAndStatus } from './validation-schemes';
import { formatErrors } from '../../../helpers/request';
import { initialState } from '../../../redux/modules/certification/data';
import { getRefsForSections, getSectionsToEditByErrors, moveToErrorSection } from './partials/helpers';
import { usePageChangeDetection } from '../../../helpers/hooks/useChangeDetection';
import { validateTreeDataChildrenLimit } from './partials/helpers/validate';

const getCertificationInitialState = (type: CertificationType) =>
  reducer(initialState, updateCertificationProperty(type as CertificationType, '@type')).certification;

const calculateHashValue = (values: CertificationDefinition) => {
  return JSON.stringify(jsonCopy(values));
};

export const CertificationCreationPage = withRouter(({ match }: RouteComponentProps<{ type: string }>) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(BtnLoadingState.NONE);
  const { certification } = useSelector<RootReducer, CertificationState>(state => state.certificationReducer);
  const type = certification['@type'];
  const certInitState = useMemo(() => getCertificationInitialState(certification['@type']), [certification['@type']]);
  const refs = getRefsForSections();
  const { isModified, resetChangeDetection, setChangeDetectionState } = usePageChangeDetection(
    calculateHashValue,
    certification,
    certInitState,
  );
  const reformatData = () => {
    const prerequisites = certification.prerequisites.filter(prereq => prereq.certification.id !== -1);
    const newCertification = jsonCopy(certification);
    // remove unnecessary value
    newCertification.capabilities.forEach(capability => delete capability.isErrorChildrenFields);
    return mergeObjects(newCertification, { prerequisites });
  };

  const onCancel = () =>
    confirmAction(
      () => isModified,
      () => {
        resetChangeDetection();
        history.push(getStoredSummaryUrl('certification'));
      },
    );

  const validate = (status?: CertificationStatuses) => {
    // validate capability minChildren / maxChildren
    const isValidateCapabilitesChildren =
      type === CertificationType.PRIMARY ? validateTreeDataChildrenLimit(certification.capabilities) : true;

    const validationSchema = getSchemeByTypeAndStatus(type, status || certification.status);
    const validationErrors = prepareYupModel(validationSchema).checkFormatted(certification) as ErrorObject;
    if (validationErrors || !isValidateCapabilitesChildren) {
      let sectionsToEdit = validationErrors ? getSectionsToEditByErrors(validationErrors) : {};
      // if capability minChildren / maxChildren error, move to capability section
      if (!isValidateCapabilitesChildren) sectionsToEdit = { ...sectionsToEdit, capabilities: true };
      moveToErrorSection(Object.keys(sectionsToEdit)[0], refs);
      dispatch(setValidationErrors(validationErrors));
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const setResponseError = (error: AxiosError) => {
    if (error.response?.data.errors) {
      const formatted = formatErrors(error.response?.data.errors);
      const sectionsToEdit = getSectionsToEditByErrors(formatted);
      moveToErrorSection(Object.keys(sectionsToEdit)[0], refs);
      dispatch(setValidationErrors(formatted));
    }
    setBtnLoading(BtnLoadingState.NONE);
    handleRequestFail(error, addToast);
  };

  const showSuccessMessage = (message: string) => {
    handleRequestSuccess(message, addToast);
    setBtnLoading(BtnLoadingState.NONE);
  };

  const handleSubmit = () => {
    validate()
      .then(() => {
        setBtnLoading(BtnLoadingState.SAVE);
        return createNewCertification(reformatData())
          .then(() => {
            showSuccessMessage(t('certifications.notifications.saved'));
            history.push('/certification');
          })
          .catch((error: AxiosError) => setResponseError(error));
      })
      .catch(() => setBtnLoading(BtnLoadingState.NONE));
  };

  const handleSaveAndStay = () => {
    validate()
      .then(() => {
        setBtnLoading(BtnLoadingState.SAVE_AND_STAY);
        return createNewCertification(reformatData())
          .then((response: AxiosResponse<DetailedCertificationRecord>) => {
            showSuccessMessage(t('certifications.notifications.saved'));
            history.push(`/certification/${response.data.versionId}`);
          })
          .catch((error: AxiosError) => setResponseError(error));
      })
      .catch(() => setBtnLoading(BtnLoadingState.NONE));
  };

  const handlePublish = () => {
    validate()
      .then(() => {
        setBtnLoading(BtnLoadingState.PUBLISH);
        return publishCertification(reformatData())
          .then(() => {
            showSuccessMessage(t('certifications.notifications.published'));
            history.push(`/certification?tab=${certificationStatuses.published}`);
          })
          .catch((error: AxiosError<MixedValidationErrorResponse>) => setResponseError(error));
      })
      .catch(() => setBtnLoading(BtnLoadingState.NONE));
  };

  useEffect(() => {
    const modifiedType = match.params.type.toUpperCase();
    if (
      !modifiedType ||
      !([CertificationType.PRIMARY, CertificationType.CAPABILITY] as string[]).includes(modifiedType)
    ) {
      history.push('/certification');
    } else {
      dispatch(updateCertificationProperty(modifiedType as CertificationType, '@type'));
      const certInitState = getCertificationInitialState(modifiedType as CertificationType);
      setChangeDetectionState(certInitState);
    }
  }, []);

  return (
    <PageTemplate
      title={t('certifications.createTitle')}
      withBorder
      actionLeft={
        <div className="btn-wrapper">
          <Button className="cancel" onClick={onCancel}>
            {t('common.navigation.cancel')}
          </Button>
          <Button
            appearance="primary"
            className="save-and-close"
            onClick={handleSubmit}
            loading={btnLoading === BtnLoadingState.SAVE}
            disabled={btnLoading !== BtnLoadingState.NONE}
          >
            {t('common.navigation.saveAndExit')}
          </Button>
        </div>
      }
      actionRight={
        <div className="btn-wrapper">
          <Button
            appearance="primary"
            className="save"
            onClick={handleSaveAndStay}
            loading={btnLoading === BtnLoadingState.SAVE_AND_STAY}
            disabled={btnLoading !== BtnLoadingState.NONE}
          >
            {t('common.navigation.save')}
          </Button>
          <Button
            appearance="primary"
            className="publish"
            onClick={handlePublish}
            loading={btnLoading === BtnLoadingState.PUBLISH}
            disabled={btnLoading !== BtnLoadingState.NONE}
          >
            {t('common.actions.publish')}
          </Button>
        </div>
      }
    >
      <AuditingInfo />
      <UmbrellaSection ref={refs.generalInfo} />
      <VersionSection ref={refs.version} />
      {type === CertificationType.PRIMARY ? <CapabilitiesSection ref={refs.capabilities} /> : null}
      <PrerequisiteSection ref={refs.prerequisites} />
      <InteroperabilityTestingSection ref={refs.interoperabilityTest} />
      {type === CertificationType.CAPABILITY ? <ConformanceTestingSection ref={refs.testGroups} /> : null}
    </PageTemplate>
  );
});
