import React, { PropsWithChildren } from 'react';
import { NavLink, NavLinkProps, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
// @ts-ignore
import _var from '../../styles/_variables.scss';
import { RouteProps, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnsavedChanges } from '../../redux/modules/app/selectors';
import { Confirm } from '../../helpers/confirmationPopup/Confirm';
import { useTranslation } from 'react-i18next';
import { setUnsavedChanges } from '../../redux/modules/app';

const StyledMenuItem = styled.li`
  height: 100%;
  padding: 0 0.6rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 6px;
    width: calc(100% - 1.2rem);
    border-radius: 3px;
    background-color: transparent;
    bottom: 5px;
    pointer-events: none;
  }

  &:hover {
    &:before {
      background-color: ${_var.secondary};
    }

    a {
      color: ${_var.textGray};

      &:hover,
      &:focus {
        color: ${_var.textGray};
        text-decoration: none !important;
      }
    }
  }

  &.is-active {
    &:before {
      background-color: ${_var.primary};
    }
  }

  a {
    display: block;
    line-height: 64px;
    color: ${_var.textGray};
    font-weight: bold;
  }
`;

const MenuItem = (props: PropsWithChildren<NavLinkProps>) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const unsavedChanges = useSelector(selectUnsavedChanges);

  const isActive = useRouteMatch({
    path: props.to,
    exact: props.exact,
  } as RouteProps);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMoveTo = (event: any) => {
    if (unsavedChanges.isUnsaved && unsavedChanges.url === url) {
      event.stopPropagation();
      event.preventDefault();
      Confirm({
        onAccept: () => {
          dispatch(setUnsavedChanges(url, false));
          history.push(props.to as string);
        },
        title: t('common.placeholders.areYouSure'),
        message: t('common.unsavedDataLeaveWarning'),
      });
    }
  };

  return (
    <StyledMenuItem className={isActive ? 'is-active' : ''}>
      <NavLink onClick={handleMoveTo} {...props} />
    </StyledMenuItem>
  );
};

export default React.memo(MenuItem);
