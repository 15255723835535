import styled from 'styled-components';
import { Table } from '../../application/partials/new-application-flow-steps/partials';
const Td = styled.td`
  vertical-align: top;
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
  word-break: break-all;
`;
const Th = styled.th`
  font-weight: normal;
  text-align: left;
  opacity: 0.6;
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
`;

export { Table, Td, Th };
