import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LabelTypes } from '../../../../helpers/constants/label-types';
import { productTypes, productTypesTitles } from '../../../../helpers/constants/product-types';
import { sortByProperty } from '../../../../helpers/sort-by-property';
import { AppFlows, ApplicationFlowState } from '../../../../redux/modules/application-flow';
import { getUniqueCapabilities, isParentPresent } from '../../../../redux/modules/application-flow/helpers';
import { RootReducer } from '../../../../redux/rootReducer';
import { LabelType } from '../../../../shared-components/label-type/LabelType';
import { ApplicationTypeDetails } from '../../../partials/summary-template/ApplicationTypeDetails';
import { GroupedCertifications } from '../../../partials/summary-template/GroupedCertifications';
import { SummaryItem, SummaryTemplate } from '../../../partials/summary-template/SummaryTemplate';
import { TestingItem } from '../types';

export const ApplicationSummarySideBar = (): React.ReactElement => {
  const { t } = useTranslation();
  const {
    companyContactInfo,
    testingInfo,
    certifications,
    id,
    productInfo,
    componentCombination,
    ...application
  } = useSelector<RootReducer, ApplicationFlowState>(state => state.applicationFlow);

  const filteredLabs = [AppFlows.NEW, AppFlows.VARIANT].includes(application['@type'])
    ? testingInfo.testingLabs.reduce((accum, next) => {
        if (
          next.testingDetails.interoperability &&
          !accum.some(item => item.id === next.testingDetails.interoperability?.id)
        ) {
          accum.push(next.testingDetails.interoperability);
        }
        if (next.testingDetails.conformance && !accum.some(item => item.id === next.testingDetails.conformance?.id)) {
          accum.push(next.testingDetails.conformance);
        }
        return accum;
      }, [] as TestingItem[])
    : [];

  const isVariant = application['@type'] === AppFlows.VARIANT;

  const isQualifying = productInfo.certified === false;

  const isQualifiedSolution = productInfo.qualifiedSolution;

  const isUseDraftCertified = isQualifiedSolution && isVariant && isQualifying;

  const wrapWithLink = isVariant;
  const formattedCertifications = certifications.map(cert => ({
    id: cert.id,
    name: cert.name,
    roleName: cert.role.name,
    capabilities: getUniqueCapabilities(
      (cert.capabilities || []).filter(el => isParentPresent(el, cert.capabilities || [])),
    ).sort((a, b) =>
      sortByProperty(
        a,
        b,
        {
          sortColumn: 'index',
          sortType: 'asc',
        },
        true,
      ),
    ),
    status: cert.version.status,
  }));

  return (
    <SummaryTemplate title={t('applications.summary.sideBar.header')} applicationId={id}>
      {(productInfo.modelVariants && productInfo.modelVariants[0].productType) ||
      productInfo.cid ||
      productInfo.qualifiedSolution ? (
        <SummaryItem title={t('applications.summary.sideBar.applicationId')}>
          {productInfo.cid}
          {productInfo.qualifiedSolution ? (
            <LabelType
              labelTypes={LabelTypes.SUMMARY_LABEL}
              label={productTypesTitles[productTypes.qualifiedSolution]}
            />
          ) : (
            <>
              {productInfo.modelVariants &&
              productInfo.modelVariants[0].productType &&
              application['@type'] !== AppFlows.DERIVATIVE ? (
                <LabelType
                  labelTypes={LabelTypes.SUMMARY_LABEL}
                  label={productTypesTitles[productInfo.modelVariants[0].productType]}
                />
              ) : null}
              {componentCombination.componentCombinationVariantSource?.productType &&
              application['@type'] === AppFlows.DERIVATIVE ? (
                <LabelType
                  labelTypes={LabelTypes.SUMMARY_LABEL}
                  label={
                    productTypesTitles[
                      componentCombination.componentCombinationVariantSource.productType ===
                      productTypes.qualifiedSolution
                        ? productTypes.quickTrack
                        : componentCombination.componentCombinationVariantSource.productType
                    ]
                  }
                />
              ) : null}
            </>
          )}
        </SummaryItem>
      ) : null}
      <SummaryItem title={t('applications.summary.sideBar.applicationType')}>
        <ApplicationTypeDetails
          applicationType={application['@type']}
          companyContactInfo={companyContactInfo}
          productInfo={productInfo}
          wrapWithLink={wrapWithLink}
        />
      </SummaryItem>
      {productInfo.qualifiedSolution && productInfo.certified !== null ? (
        <SummaryItem title={t('applications.summary.sideBar.wifiCertified')}>
          <LabelType
            labelTypes={LabelTypes.BLOCK_LABEL}
            label={
              (isUseDraftCertified
              ? productInfo.modelVariants[0].draftCertified === true
              : productInfo.certified)
                ? 'Yes'
                : 'No'
            }
          />
        </SummaryItem>
      ) : null}
      {componentCombination.componentCombinationVariantSource &&
      componentCombination.componentCombinationVariantSource.productType ? (
        <SummaryItem title={t('applications.summary.sideBar.componentLoadedFrom')}>
          <LabelType
            labelTypes={LabelTypes.SUMMARY_LABEL}
            label={productTypesTitles[componentCombination.componentCombinationVariantSource.productType]}
          />
        </SummaryItem>
      ) : null}
      {companyContactInfo.owningCompany?.name ? (
        <SummaryItem title={t('applications.summary.sideBar.owningCompany')}>
          {companyContactInfo.owningCompany?.name}
        </SummaryItem>
      ) : null}
      {productInfo.name ? (
        <SummaryItem title={t('applications.summary.sideBar.name')}>{productInfo.name}</SummaryItem>
      ) : null}
      {formattedCertifications.length > 0 ? <GroupedCertifications certifications={formattedCertifications} /> : null}
      {filteredLabs.length > 0 ? (
        <SummaryItem title={t('applications.summary.sideBar.testingLabs')}>
          {filteredLabs.map((item, key) => (
            <p key={key}>{item.name}</p>
          ))}
        </SummaryItem>
      ) : null}
    </SummaryTemplate>
  );
};
