import React from 'react';
import styled from 'styled-components';
import { Timeline as RSTimeline } from 'rsuite';
import { HistoryEvent, HistoryEventTypes } from '../../components/pages/application/types';
import { eventDateFormat, reformatDateToFormat } from '../../helpers';
import TimelineIcon from './TimelineIcon';
// @ts-ignore
import _variables from '../../styles/_variables.scss';
import { useTranslation } from 'react-i18next';
import { messageKeyPrefix } from '../../helpers/constants';

const UserLabel = styled.p`
  color: ${_variables.primary};
  font-weight: bold;
`;

const StatusLabel = styled.p`
  color: ${_variables.labelGray};
`;

const TimeLineWrapper = styled(RSTimeline)`
  margin-left: 20px;
  .rs-timeline-item-custom-dot .rs-icon {
    position: absolute;
    background: #fff;
    top: 0;
    left: -2px;
    border: 2px solid #ddd;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    padding-top: 9px;
    color: #999;
    margin-left: -13px;
  }
  .rs-timeline-item-content {
    margin-left: 14px;
  }
  .rs-timeline-item-time {
    padding-right: 26px !important;
    padding-top: 15px;
  }
  .rs-timeline-item-last {
    .rs-icon {
      border: 2px solid ${_variables.primary};
    }
  }
`;

const Timeline = ({ events }: { events: HistoryEvent[] }) => {
  const { t, i18n } = useTranslation();

  const getLabel = (event: { key: string; label: string } | undefined) =>
    event?.key && i18n.exists(event.key.replace(messageKeyPrefix, ''))
      ? t((event?.key).replace(messageKeyPrefix, ''))
      : event?.label;

  return (
    <TimeLineWrapper endless>
      {events.map((event: HistoryEvent) => (
        <RSTimeline.Item
          key={event.date}
          time={reformatDateToFormat(event.date, eventDateFormat)}
          dot={<TimelineIcon event={event} />}
        >
          <UserLabel>{event.originalUser}</UserLabel>
          {event['@type'] === HistoryEventTypes.STATUS && (
            <StatusLabel>
              <b>Status:</b> {getLabel(event.applicationStatus)}
            </StatusLabel>
          )}
          <p>{event['@type'] === HistoryEventTypes.STATUS ? getLabel(event.eventType) : event.note}</p>
        </RSTimeline.Item>
      ))}
    </TimeLineWrapper>
  );
};

export default Timeline;
