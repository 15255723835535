import { all } from 'redux-saga/effects';
import watchMultipleUserSagas from './modules/user/sagas';
import watchMultipleRolesSagas from './modules/roles/sagas';
import watchMultipleCategoriesSagas from './modules/categories/sagas';
import watchMultipleClassificationsSagas from './modules/classification/sagas';
import watchAtlQualificationsSagas from './modules/admin/atl-qualifications/sagas';
import watchMultipleAppFlowSagas from './modules/application-flow/sagas';
import watchMultipleRevokesSagas from './modules/modals/sagas';

export function* rootSaga() {
  yield all([
    watchMultipleUserSagas(),
    watchMultipleRolesSagas(),
    watchMultipleCategoriesSagas(),
    watchMultipleClassificationsSagas(),
    watchAtlQualificationsSagas(),
    watchMultipleAppFlowSagas(),
    watchMultipleRevokesSagas(),
  ]);
}
