/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useState, HTMLAttributes, useEffect } from 'react';
import View from '../../helper-components/View';
import classNames from 'classnames';
import { TabContent, TabHeader, TabHeadersWrapper, TabsHeader } from './styled-components';

export interface TabsProps extends PropsWithChildren<any>, HTMLAttributes<HTMLDivElement> {
  value?: string;
  onTabClick?: (tab: any, e: any) => any;
  rightContent?: React.ReactElement<string> | string | null;
}

export const Tabs = ({
  style,
  className,
  onTabClick,
  children,
  value,
  rightContent = null,
}: TabsProps): React.ReactElement<string> => {
  const [currentName, setCurrentName] = useState(value ? value : children ? (children as any[])[0].props.name : '');
  const handleTabClick = (tab: any, e: any) => {
    if (tab.props.disabled) {
      return;
    }
    setCurrentName(tab.props.name);
    onTabClick && onTabClick(tab, e);
  };

  useEffect(() => {
    if (value && value !== currentName) {
      setCurrentName(value);
    }
  }, [value]);

  return (
    <div className={classNames('tabs-wrapper', className)} style={style}>
      <TabsHeader className={classNames('tabs-header')}>
        <TabHeadersWrapper>
          {React.Children.map(children, (item: any, index) => {
            const { name, label, disabled } = item.props;
            return (
              <TabHeader
                key={index}
                className={classNames('tab-header', { 'is-active': name === currentName, 'is-disabled': disabled })}
                onClick={e => handleTabClick(item, e)}
              >
                {label}
              </TabHeader>
            );
          })}
        </TabHeadersWrapper>
        <aside>{rightContent}</aside>
      </TabsHeader>
      <TabContent>
        {React.Children.map(children, (item: any) => (
          <View show={item.props.name === currentName}>{item}</View>
        ))}
      </TabContent>
    </div>
  );
};
