import React from 'react';
import { LRSummaryRecord } from '../types';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Icon, IconButton, Popover, Whisper } from 'rsuite';
import { useHistory, useLocation } from 'react-router';
import { Confirm } from '../../../../helpers/confirmationPopup/Confirm';
import { acceptLR, recallLRFromApprover } from '../../../../api/lab-request';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { useToasts } from 'react-toast-notifications';
import { ApplicationActions } from '../../application/types';
import { useDispatch } from 'react-redux';
import { showAddNoteModal } from '../../../../redux/modules/modals';
import { NoteActionTypes } from '../../../../redux/modules/modals/constants';
import { LRSummaryStatuses, defaultWhisperProps } from '../../../../helpers/constants';

interface RecordWithActions extends LRSummaryRecord {
  actions: ApplicationActions;
}

export const ActionsColumn = ({ record }: { record: RecordWithActions }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id, appId, state } = record;
  const onReview = (path = '') => history.push(`/lab-request/${id}${path}`);
  const onView = (path = '') => window.open(`#/lab-request/${id}${path}`);
  const onAccept = () => {
    Confirm({
      title: t('common.placeholders.areYouSure'),
      message: t('common.placeholders.cantBeUndone'),
      onAccept: () => {
        return acceptLR(id)
          .then(() => history.push(`/lab-request/${id}?step=2`))
          .catch(error => handleRequestFail(error, addToast));
      },
    });
  };
  const onViewSubmit = () => history.push(`/lab-request/${id}?step=2`);

  const onReturnSuccess = () => {
    addToast(`Application #${appId} was successfully returned.`, {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    return record.actions.getSummary({ tab: state });
  };

  const onRecallSuccess = () => {
    addToast(`Application #${appId} was successfully recalled.`, {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    history.push({
      pathname: location.pathname,
      search: `?tab=${LRSummaryStatuses.RETURNED}`,
    });
    return record.actions.getSummary({ tab: LRSummaryStatuses.RETURNED });
  };

  return (
    <div className="actions text-center">
      {record.state === LRSummaryStatuses.NEW ? (
        <>
          <Button appearance="primary" className="mr-1" size="sm" onClick={() => onReview()}>
            {t('common.actions.review')}
          </Button>
          <Button color="green" size="sm" onClick={onAccept}>
            {t('common.actions.accept')}
          </Button>
        </>
      ) : null}
      {[LRSummaryStatuses.IN_TESTING, LRSummaryStatuses.RETURNED].includes(record.state) ? (
        <Button color="green" size="sm" onClick={onViewSubmit}>
          {t('common.actions.viewSubmit')}
        </Button>
      ) : null}
      {[LRSummaryStatuses.PENDING_APPROVAL, LRSummaryStatuses.APPROVED, LRSummaryStatuses.REVOKED].includes(
        record.state,
      ) ? (
        <IconButton onClick={() => onView('/view')} icon={<Icon icon="eye" />} appearance="link" />
      ) : null}
      {![LRSummaryStatuses.APPROVED, LRSummaryStatuses.REVOKED].includes(record.state) && (
        <Whisper
          {...defaultWhisperProps}
          speaker={
            <Popover full>
              <Dropdown.Menu className="action-icons">
                {record.state === LRSummaryStatuses.PENDING_APPROVAL ? (
                  <Dropdown.Item
                    onClick={() =>
                      dispatch(
                        showAddNoteModal(
                          {
                            appId: record.id,
                            onSuccess: onRecallSuccess,
                            setParentLoading: record.actions.setLoadingData,
                            callApi: recallLRFromApprover,
                          },
                          NoteActionTypes.RECALL_FROM_APPROVER,
                        ),
                      )
                    }
                  >
                    <IconButton icon={<Icon icon="undo" />} appearance="link">
                      {t('common.actions.recallFromApprover')}
                    </IconButton>
                  </Dropdown.Item>
                ) : null}
                {[LRSummaryStatuses.NEW, LRSummaryStatuses.IN_TESTING, LRSummaryStatuses.RETURNED].includes(
                  record.state,
                ) ? (
                  <Dropdown.Item
                    onClick={() =>
                      dispatch(
                        showAddNoteModal(
                          {
                            appId: record.id,
                            onSuccess: onReturnSuccess,
                            setParentLoading: record.actions.setLoadingData,
                          },
                          NoteActionTypes.RETURN_TO_SUBMITTER_LAB,
                        ),
                      )
                    }
                  >
                    <IconButton icon={<Icon icon="undo" />} appearance="link">
                      {t('common.actions.returnToSubmitter')}
                    </IconButton>
                  </Dropdown.Item>
                ) : null}
              </Dropdown.Menu>
            </Popover>
          }
        >
          <IconButton appearance="link" icon={<Icon icon="more" className="icon pointer medium" />} />
        </Whisper>
      )}
    </div>
  );
};
