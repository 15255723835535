import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import { getScrollOffset } from '../../helpers/get-scroll-offset';
import throttle from 'lodash.throttle';
import { useTestEnvironment } from '../../layouts/partials/EnvironmentIdentifier';

export const useStickyStyles = makeStyles(() =>
  createStyles({
    table: {},
    mainTable: {
      display: 'table',
    },
    stickyTable: {
      display: 'none',
      position: 'relative',
      borderTop: '1px solid #f9f9f9',
    },
    stickyTableWrapper: {
      zIndex: 1,
      overflow: 'hidden',
      position: 'fixed',
      background: 'white',
    },
    headerColumn: {
      boxSizing: 'border-box',
      padding: '16px 10px',
      verticalAlign: 'top',
    },
  }),
);

interface DataTableStickyWrapperProps {
  sticky: boolean;
  minWidth?: number;
  topOffset?: number;
  header: () => ReactElement;
  useEnvIdentifier?: boolean;
  loading?: boolean;
}

export const DataTableStickyWrapper = (props: PropsWithChildren<DataTableStickyWrapperProps>) => {
  const { children, header, sticky, minWidth = 960, topOffset = 0, useEnvIdentifier = false, loading = false } = props;
  const classes = useStickyStyles();
  const isTestEnvironment = useTestEnvironment();

  const headerHeight = React.useRef<number>(0);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  let newTopOffset = topOffset;
  if (sticky && useEnvIdentifier && isTestEnvironment) newTopOffset += 20;

  /**
   * Performs action on the horizontal scrolling table container
   * @param action
   */
  const withTableScrollContainer = (action: (element: HTMLElement) => void) => {
    if (wrapperRef.current) {
      const container = wrapperRef.current.parentElement;
      if (container) {
        action(container);
      }
    }
  };

  /**
   * Performs action on the main table
   * @param action callback to perform an action
   */
  const withMainTable = (action: (element: HTMLElement) => void) => {
    if (wrapperRef.current) {
      const mainTable = wrapperRef.current.querySelector<HTMLElement>(`.${classes.mainTable}`);
      if (mainTable) {
        action(mainTable);
      }
    }
  };

  /**
   * Performs action on the sticky header cloned table
   * @param action callback to perform an action
   */
  const withStickyTable = (action: (element: HTMLElement) => void) => {
    if (wrapperRef.current) {
      const stickyTable = wrapperRef.current.querySelector<HTMLElement>(`.${classes.stickyTable}`);
      if (stickyTable) {
        action(stickyTable);
      }
    }
  };

  /**
   * Shows sticky header
   */
  const showStickyHeader = () => {
    withStickyTable(stickyTable => {
      stickyTable.style.display = 'table';
    });
  };

  /**
   * Hides sticky header
   */
  const hideStickyHeader = () => {
    withStickyTable(stickyTable => {
      stickyTable.style.display = 'none';
    });
  };

  /**
   * Recalculates sizes of sticky table elements
   */
  const updateStickyHeaderSize = () => {
    if (wrapperRef.current) {
      const wrapper = wrapperRef.current;

      // Sets sticky header container width
      const stickyWrapper = wrapper.querySelector<HTMLElement>(`.${classes.stickyTableWrapper}`);
      if (stickyWrapper) {
        const { width = 0 } = wrapper.parentElement?.getBoundingClientRect() || {};
        stickyWrapper.style.width = `${width}px`;
        stickyWrapper.style.top = `${headerHeight.current + newTopOffset}px`;
      }

      // Sets sticky header table width
      withMainTable(mainTable => {
        withStickyTable(stickyTable => {
          const { width = 0 } = mainTable.getBoundingClientRect() || {};
          stickyTable.style.width = `${width}px`;
        });
      });

      // Sets width of sticky header columns
      if (stickyWrapper) {
        const mainColumns = wrapper.querySelectorAll<HTMLElement>(`.${classes.mainTable} .${classes.headerColumn}`);
        const stickyColumns = wrapper.querySelectorAll<HTMLElement>(`.${classes.stickyTable} .${classes.headerColumn}`);
        mainColumns.forEach((column, index) => {
          const { width = 0 } = column.getBoundingClientRect() || {};
          stickyColumns.item(index).style.width = `${width}px`;
        });
      }
    }
  };

  const throttledUpdateStickyHeaderSize = throttle(updateStickyHeaderSize, 50);

  /**
   * Updates horizontal position of sticky header when main page is scrolled.
   * @param event
   */
  const horizontalScrollListener = (event: Event) => {
    withStickyTable(stickyTable => {
      if (event.target) {
        const left = (event.target as HTMLElement).scrollLeft;
        stickyTable.style.left = `-${left}px`;
      }
    });
  };

  const throttledHorizontalScrollListener = throttle(horizontalScrollListener, 20);

  /**
   * Displays sticky header when page is scrolled to the header.
   */
  const verticalScrollListener = () => {
    withMainTable(mainTable => {
      const scrollOffset = getScrollOffset();
      const headerOffset = headerHeight.current;
      const tablePosition = mainTable.offsetTop - mainTable.scrollTop + mainTable.clientTop;

      if (scrollOffset > tablePosition - headerOffset - newTopOffset) {
        updateStickyHeaderSize();
        showStickyHeader();
      } else {
        hideStickyHeader();
      }
    });
  };

  const throttledVerticalScrollListener = throttle(verticalScrollListener, 100);

  /**
   * Detects header height. Used as Y offset
   */
  React.useEffect(() => {
    if (sticky) {
      const header = document.getElementById('header-wrapper');
      if (header) {
        headerHeight.current = header.clientHeight;
      }
    }
  }, []);

  /**
   * Detects main table resizing
   */
  React.useEffect(() => {
    if (sticky && wrapperRef.current) {
      const resizeObserver = new ResizeObserver(throttledUpdateStickyHeaderSize);
      resizeObserver.observe(wrapperRef.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  /**
   * Detects page vertical scrolling
   */
  useEffect(() => {
    if (sticky) {
      document.addEventListener('scroll', throttledVerticalScrollListener);
      return () => {
        document.removeEventListener('scroll', throttledVerticalScrollListener);
      };
    }
  }, []);

  /**
   * Detects main table horizontal scrolling
   */
  useEffect(() => {
    if (sticky) {
      withTableScrollContainer(container => {
        container.addEventListener('scroll', throttledHorizontalScrollListener);
      });

      return () => {
        withTableScrollContainer(container => {
          container.removeEventListener('scroll', throttledHorizontalScrollListener);
        });
      };
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      withMainTable(mainTable => {
        const scrollOffset = getScrollOffset();
        const headerOffset = headerHeight.current;
        const tablePosition = mainTable.offsetTop - mainTable.scrollTop + mainTable.clientTop;

        if (scrollOffset > tablePosition - headerOffset - newTopOffset) {
          updateStickyHeaderSize();
          showStickyHeader();
        }
      });
    }
  }, [loading]);

  return (
    <div ref={wrapperRef}>
      <Table className={classNames(classes.table, classes.mainTable)} style={{ minWidth }}>
        {header()}
        {children}
      </Table>
      {sticky && (
        <div className={classes.stickyTableWrapper}>
          <Table className={classNames(classes.table, classes.stickyTable)} style={{ minWidth }}>
            {header()}
          </Table>
        </div>
      )}
    </div>
  );
};
