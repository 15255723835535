import React, { Fragment } from 'react';
import { deleteApplication } from '../../../../api/application';
import { AxiosError } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationActions, ApplicationSummaryRecord, ATLStatus } from '../types';
import { Dropdown, Icon, IconButton, Popover, Whisper } from 'rsuite';
import { applicationStatuses, defaultWhisperProps } from '../../../../helpers/constants';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { Confirm } from '../../../../helpers/confirmationPopup/Confirm';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RESTORE_APPLICATION_DATA } from '../../../../redux/modules/application-flow';
import { useChangeWrapper } from '../../../../helpers/storage/formik-change-wrapper';
import { NoteActionTypes } from '../../../../redux/modules/modals/constants';
import { showAddNoteModal } from '../../../../redux/modules/modals';
import { removeApplication } from '../../../../api/application/remove-application';

const ColumnComponent = ({
  record,
  history,
  location,
}: {
  record: ApplicationSummaryRecord & { actions: ApplicationActions };
} & RouteComponentProps<{}>): React.ReactElement<string> => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { actions } = record;
  const dispatch = useDispatch();
  const onDuplicate = () => null;

  const handleRemove = async (id: number, needsAttention: boolean) => {
    if (needsAttention) return removeApplication(id);
    return deleteApplication(id);
  };

  const onDelete = (withSubmission: boolean) => {
    Confirm({
      title: t('common.placeholders.areYouSure'),
      message: withSubmission
        ? t('common.placeholders.cantBeUndoneNewSubmissionRequired')
        : t('common.placeholders.cantBeUndone'),
      onAccept: () => {
        actions.setLoadingData(true);
        return handleRemove(record.id, withSubmission)
          .then(() => {
            addToast(t('applications.notifications.removed'), {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
            actions.setLoadingData(false);
            return actions.getSummary(withSubmission === true ? { tab: applicationStatuses.needsAttention } : {});
          })
          .catch((error: AxiosError) => {
            actions.setLoadingData(false);
            handleRequestFail(error, addToast);
          });
      },
    });
  };

  const onEdit = () => {
    const { clearForm, getForm } = useChangeWrapper(String(record.id));
    if (getForm()) {
      clearForm();
      dispatch({ type: RESTORE_APPLICATION_DATA });
    }
    history.push(`/application/${record.id}/edit`);
  };

  const onView = () => window.open(`#/application/${record.id}`);

  const onRecallSuccess = () => {
    addToast(`Application #${record.id} was successfully recalled.`, {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    actions.setLoadingData(false);
    history.push({
      pathname: location.pathname,
      search: `?tab=${applicationStatuses.needsAttention}`,
    });
  };

  return (
    <Fragment>
      <div className="actions">
        {[applicationStatuses.draft, applicationStatuses.needsAttention].includes(record.state) ? (
          <IconButton onClick={onEdit} icon={<Icon icon="edit" />} appearance="link" />
        ) : null}
        {[
          applicationStatuses.inTesting,
          applicationStatuses.pendingApproval,
          applicationStatuses.approved,
          applicationStatuses.revoked,
        ].includes(record.state) ? (
          <IconButton onClick={onView} icon={<Icon icon="eye" />} appearance="link" />
        ) : null}
        {record.state !== applicationStatuses.revoked && (
          <Whisper
            {...defaultWhisperProps}
            speaker={
              <Popover full>
                <Dropdown.Menu className="action-icons">
                  <Dropdown.Item disabled onClick={onDuplicate}>
                    <IconButton icon={<Icon icon="copy" />} appearance="link">
                      {t('applications.summary.duplicate')}
                    </IconButton>
                  </Dropdown.Item>
                  {[applicationStatuses.draft, applicationStatuses.needsAttention].includes(record.state) ? (
                    <Dropdown.Item onClick={() => onDelete(record.state === applicationStatuses.needsAttention)}>
                      <IconButton icon={<Icon icon="trash-o" />} appearance="link">
                        {t('applications.summary.delete')}
                      </IconButton>
                    </Dropdown.Item>
                  ) : null}
                  {record.state === applicationStatuses.inTesting ? (
                    <Dropdown.Item
                      onClick={() =>
                        dispatch(
                          showAddNoteModal(
                            { appId: record.id, onSuccess: onRecallSuccess, setParentLoading: actions.setLoadingData },
                            NoteActionTypes.RECALL_FROM_LAB,
                          ),
                        )
                      }
                      disabled={record.atl.some(atl => atl.status !== ATLStatus.PENDING_ACCEPTANCE)}
                    >
                      <IconButton icon={<Icon icon="undo" />} appearance="link">
                        {t('applications.summary.recallFromLab')}
                      </IconButton>
                    </Dropdown.Item>
                  ) : null}
                  {record.state === applicationStatuses.pendingApproval ? (
                    <Dropdown.Item
                      onClick={() =>
                        dispatch(
                          showAddNoteModal(
                            { appId: record.id, onSuccess: onRecallSuccess, setParentLoading: actions.setLoadingData },
                            NoteActionTypes.RECALL_FROM_APPROVER,
                          ),
                        )
                      }
                    >
                      <IconButton icon={<Icon icon="undo" />} appearance="link">
                        {t('common.actions.recallFromApprover')}
                      </IconButton>
                    </Dropdown.Item>
                  ) : null}
                </Dropdown.Menu>
              </Popover>
            }
          >
            <IconButton appearance="link" icon={<Icon icon="more" className="icon pointer medium" />} />
          </Whisper>
        )}
      </div>
    </Fragment>
  );
};

export const ActionsColumn = withRouter(ColumnComponent);
