import React from 'react';
import { CertLabCheckbox } from './CertLabCheckbox';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid #f5f5f5;
  padding: 1rem;
  margin-top: 1rem;

  .title {
    font-weight: bold;
  }

  .rs-checkbox {
    &:not(:first-child) {
      margin-left: 45px;
    }
    &:first-child {
      margin-left: 30px;
    }
    .rs-checkbox-wrapper {
      left: -30px;
    }

    .rs-checkbox-checker {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 3;
    }
  }
`;

const Section = styled.div`
  display: flex;
  span {
    flex: 1;
  }
`;

const CertificationsWrapper = styled.div`
  display: flex;
`;

export const TestingInfoLegend = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <span />
      <Wrapper>
        <span className="title">{t('applications.labs.legend.title')}</span>
        <CertificationsWrapper>
          <CertLabCheckbox inline className="required" readOnly>
            {t('applications.labs.legend.required')}
          </CertLabCheckbox>
          <CertLabCheckbox inline className="previously-certified" readOnly isPrequalified>
            {t('applications.labs.legend.preCertified')}
          </CertLabCheckbox>
          <CertLabCheckbox inline isNotAuthorized>
            {t('applications.labs.legend.notAuthorized')}
          </CertLabCheckbox>
        </CertificationsWrapper>
      </Wrapper>
    </Section>
  );
};
