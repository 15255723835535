import React, { CSSProperties, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateRange, DefinedRange } from 'materialui-daterange-picker';
import { createStyles, makeStyles } from '@material-ui/core';
import { Icon, Input, InputGroup } from 'rsuite';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from '../../shared-components/date-range-picker/DateRangePicker';
import { RootReducer } from '../../redux/rootReducer';
import { Role } from '../../redux/modules/roles/types';
import { CertificationType, certificationTypeTitles } from '../pages/certification/types';
import { SummaryFiltersObject } from '../../helpers/types';
import { jsonCopy } from '../../helpers';
import { ApplicationTypes, applicationTypeTitles } from '../pages/admin/transactions/types';
import ClearIcon from '@material-ui/icons/Clear';
import LabeledMaterialSelect from '../../shared-components/labeled-inputs/LabeledMaterialSelect';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    applicationType: {
      width: 220,
      marginRight: '1rem',
      '& a': {
        paddingTop: '12px !important',
        paddingBottom: '9px !important',
        '& .rs-picker-toggle-clean': {
          fontSize: 20,
          marginRight: '5px',
          color: 'rgba(0, 0, 0, 0.87)',
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
        '& .rs-picker-toggle-caret': {
          fontSize: 20,
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    searchButton: {
      height: '98% !important',
      paddingRight: '10px !important',
      paddingLeft: '5px !important',
      color: 'rgba(0, 0, 0, 0.87) !important',
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.87) !important',
      },
    },
    eraseSearchButton: {
      marginRight: '28px',
      height: '98% !important',
      padding: '0 5px !important',
      color: 'rgba(0, 0, 0, 0.87) !important',
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.87) !important',
      },
      '& > i': {
        fontWeight: 'bold',
      },
    },
    labeledSelect: {
      paddingRight: '1rem !important',
      '& .rs-btn': {
        paddingTop: '9px !important',
        paddingBottom: '9px !important',
      },
      '& .rs-picker-toggle-caret, .rs-picker-toggle-clean': {
        top: '10px !important',
      },
    },
  }),
);

interface Props {
  onChange?: (value: SummaryFiltersObject) => void;
  onFiltersApply: (value: SummaryFiltersObject) => void;
  appliedFilters: SummaryFiltersObject;
  style?: CSSProperties;
  hideTimeFilter?: boolean;
  hideRoleFilter?: boolean;
  hideCertificationTypeFilter?: boolean;
  hideAppTypeFilter?: boolean;
  searchRef?: React.MutableRefObject<string>;
  updateFilters?: (value: SummaryFiltersObject) => void;
}

const Filters = ({
  onChange,
  appliedFilters,
  onFiltersApply,
  style,
  hideTimeFilter = false,
  hideRoleFilter = true,
  hideCertificationTypeFilter = true,
  hideAppTypeFilter = true,
  searchRef,
  updateFilters,
}: Props) => {
  const { roles } = useSelector<RootReducer, { roles: Role[] }>(state => state.rolesReducer);

  const certificationTypes = [
    { id: CertificationType.PRIMARY, name: certificationTypeTitles[CertificationType.PRIMARY] },
    { id: CertificationType.CAPABILITY, name: certificationTypeTitles[CertificationType.CAPABILITY] },
  ];

  const applicationTypes = [
    { id: ApplicationTypes.NEW, name: applicationTypeTitles[ApplicationTypes.NEW] },
    { id: ApplicationTypes.VARIANT, name: applicationTypeTitles[ApplicationTypes.VARIANT] },
    { id: ApplicationTypes.DERIVATIVE, name: applicationTypeTitles[ApplicationTypes.DERIVATIVE] },
  ];

  const defaultRoleValue = appliedFilters.roleId ? appliedFilters.roleId : null;
  const defaultTypeValue = appliedFilters.type ? appliedFilters.type : null;
  const [filters, setFilters] = useState<SummaryFiltersObject>({
    search: decodeURIComponent(appliedFilters.search) || '',
    from: decodeURIComponent(appliedFilters.from) || '',
    to: decodeURIComponent(appliedFilters.to) || '',
    roleId: defaultRoleValue,
    type: defaultTypeValue,
    certificationId: appliedFilters.certificationId || '',
    applicationType: appliedFilters.applicationType || null,
  });
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSearchStringChange = (search: string) => {
    setFilters(prevState => {
      const newState = jsonCopy(prevState);
      newState.search = search;
      newState.certificationId = '';
      if (onChange) {
        onChange(newState);
      }
      if (searchRef) searchRef.current = search;
      return newState;
    });
  };

  const transformFilters = ({ from, to, search, ...rest }: SummaryFiltersObject) => {
    return {
      from: encodeURIComponent(from),
      to: encodeURIComponent(to),
      search: encodeURIComponent(search),
      ...rest,
    };
  };

  const applyFilters = (filters: SummaryFiltersObject) => onFiltersApply(transformFilters(filters));

  const handleSearchStringClean = () => {
    setFilters(prevState => {
      const newState = jsonCopy(prevState);
      newState.search = '';
      newState.certificationId = '';
      if (onChange) {
        onChange(newState);
      }
      if (searchRef && updateFilters) {
        searchRef.current = '';
        updateFilters(transformFilters(newState));
      }
      applyFilters(newState);
      return newState;
    });
  };

  const handleSearchStringApply = () => {
    if (filters.search !== appliedFilters.search) {
      applyFilters(filters);
      if (searchRef && updateFilters) {
        searchRef.current = '';
        updateFilters(transformFilters(filters));
      }
    }
  };

  const handleTimeFilterChange = (time: DateRange) => {
    let from = '',
      to = '';
    const { startDate, endDate } = time;
    setFilters(prevState => {
      const newState = jsonCopy(prevState);
      if (startDate && endDate) {
        from = DateTime.fromJSDate(startDate)
          .startOf('day')
          .toISO();
        to = DateTime.fromJSDate(endDate)
          .endOf('day')
          .toISO();
      }
      newState.from = from;
      newState.to = to;
      onChange && onChange(newState);
      applyFilters(newState);
      return newState;
    });
  };

  const getDefinedRanges = (): DefinedRange[] => {
    const date = new Date();
    const currentYear = date.getFullYear();

    return [
      {
        startDate: new Date(currentYear, 0),
        endDate: date,
        label: t('applications.summary.filters.thisYear'),
      },
      {
        startDate: new Date(currentYear - 1, 0),
        endDate: new Date(currentYear - 1, 11, 31),
        label: t('applications.summary.filters.lastYear'),
      },
      {
        startDate: new Date(currentYear - 3, 0),
        endDate: new Date(currentYear - 1, 11, 31),
        label: t('applications.summary.filters.lastThree'),
      },
    ];
  };

  const onRoleFilterChange = (id: number | null) => {
    setFilters(prevState => {
      const newState = jsonCopy(prevState);
      newState.roleId = id;
      onChange && onChange(newState);
      applyFilters(newState);
      return newState;
    });
  };

  const onAppTypeFilterChange = (id: ApplicationTypes | null) => {
    setFilters(prevState => {
      const newState = jsonCopy(prevState);
      newState.applicationType = id;
      onChange && onChange(newState);
      applyFilters(newState);
      return newState;
    });
  };

  const onTypeFilterChange = (value: CertificationType | null) => {
    setFilters(prevState => {
      const newState = jsonCopy(prevState);
      newState.type = value;
      onChange && onChange(newState);
      applyFilters(newState);
      return newState;
    });
  };

  const dateRange = filters.from ? { startDate: new Date(filters.from), endDate: new Date(filters.to) } : {};

  return (
    <section style={style} className={classes.root} data-test-id="filtersRoot">
      {hideCertificationTypeFilter || (
        <LabeledMaterialSelect
          onChange={value => onTypeFilterChange(value)}
          value={filters.type}
          items={certificationTypes}
          style={{ width: 150 }}
        />
      )}
      {hideRoleFilter ? null : (
        <LabeledMaterialSelect
          onChange={value => onRoleFilterChange(value)}
          value={filters.roleId}
          items={roles}
          style={{ width: 250 }}
        />
      )}
      {hideAppTypeFilter ? null : (
        <LabeledMaterialSelect
          onChange={value => onAppTypeFilterChange(value as ApplicationTypes)}
          value={filters.applicationType}
          items={applicationTypes}
          placeholder={t('admin.transactions.appTypeSelectPlaceholder')}
        />
      )}
      <InputGroup className="mr-1" inside style={{ maxWidth: '194px' }}>
        <Input
          placeholder={t('common.placeholders.search')}
          id="search-input"
          value={filters.search}
          onPressEnter={handleSearchStringApply}
          onChange={value => handleSearchStringChange(value as string)}
        />
        {filters.search || appliedFilters.search ? (
          <InputGroup.Button
            className={classes.eraseSearchButton}
            onClick={handleSearchStringClean}
            id="clean-search-filter"
          >
            <ClearIcon fontSize="small" />
          </InputGroup.Button>
        ) : null}
        <InputGroup.Button onClick={handleSearchStringApply} id="apply-search-filter" className={classes.searchButton}>
          <Icon icon="search" />
        </InputGroup.Button>
      </InputGroup>
      {hideTimeFilter ? null : (
        <>
          <DateRangePicker
            id="time-filter"
            handleDateFilterChange={handleTimeFilterChange}
            ranges={getDefinedRanges()}
            initialDateRange={dateRange}
            value={dateRange}
            title="YYYY-MM-DD ~ YYYY-MM-DD"
            useFilterPositioning={!hideAppTypeFilter}
          />
        </>
      )}
    </section>
  );
};

export const DefaultSummaryFilters = React.memo(Filters);
