import {
  SET_CERTIFICATION_CLONE_MODAL_VALUE,
  SET_ADD_NOTE_MODAL_VALUE,
  RESTORE_ADD_NOTE_MODAL_VALUE,
  TOGGLE_UPDATE_NOTES,
  RESTORE_REVOKE_NOTE_MODAL_VALUE,
  SET_REVOKE_MODAL_VALUE,
} from './actionTypes';
import { AnyAction } from 'redux';
import { mergeObjectsByLodash as mergeObjects } from '../../../helpers';
import { AxiosResponse } from 'axios';
import {
  modalPropsByActionTypes,
  NoteActionTypes,
  RevokeActionTypes,
  revokeModalPropsByActionTypes,
} from './constants';
import { CertificationType } from '../../../components/pages/certification/types';

const initialState = {
  certificationModal: null,
  noteModal: modalPropsByActionTypes[NoteActionTypes.ADD_NOTE],
  updateNotes: true,
  revokeModal: revokeModalPropsByActionTypes[RevokeActionTypes.REVOKE_APPLICATION],
};

export interface NoteModal {
  appId: number | null;
  legend: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (response: any) => void;
  fieldLabel: string;
  modalTitle: string;
  successTextBtn: string;
  callApi: (data: { id: number; note: string }) => Promise<AxiosResponse>;
  setParentLoading?: (x: boolean) => void;
  noteModalType: NoteActionTypes;
}

export interface CertificationModal {
  id: number;
  type: CertificationType;
}

export interface RevokeModal {
  cid?: string | null;
  productId: number | null;
  totalVariants: number;
  appId: number | null;
  legend: string;
  reasons: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (response: any) => void;
  modalTitle: string;
  reasonTitle: string;
  callApi: (data: { id: number; comment: string; reason: string }) => Promise<AxiosResponse>;
  checkApi?: (id: number) => Promise<AxiosResponse>;
  revokeModalType: RevokeActionTypes;
}

export type ModalsReducerState = Readonly<{
  certificationModal: CertificationModal | null;
  noteModal: NoteModal;
  updateNotes: boolean;
  revokeModal: RevokeModal;
}>;

export const modalsReducer = (state: ModalsReducerState = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_CERTIFICATION_CLONE_MODAL_VALUE:
      return mergeObjects(state, { certificationModal: action.payload });
    case SET_ADD_NOTE_MODAL_VALUE:
      return mergeObjects(state, { noteModal: action.payload });
    case RESTORE_ADD_NOTE_MODAL_VALUE:
      return mergeObjects(state, {
        certificationModal: null,
        noteModal: modalPropsByActionTypes[action.payload ?? NoteActionTypes.ADD_NOTE],
        updateNotes: true,
      });
    case TOGGLE_UPDATE_NOTES:
      return mergeObjects(state, { updateNotes: action.payload });
    case RESTORE_REVOKE_NOTE_MODAL_VALUE:
      return mergeObjects(state, {
        revokeModal: revokeModalPropsByActionTypes[action.payload ?? RevokeActionTypes.REVOKE_APPLICATION],
      });
    case SET_REVOKE_MODAL_VALUE:
      return mergeObjects(state, { revokeModal: action.payload });
    default:
      return state;
  }
};
