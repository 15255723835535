import React, { HTMLAttributes, PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, Theme } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

const StyledButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    paddingLeft: 16,
    paddingRight: 16,
  },
}))(Button);

export const AddMaximumButton = ({ children, onClick, className }: PropsWithChildren<HTMLAttributes<{}>>) => (
  <StyledButton
    className={className}
    onClick={onClick}
    startIcon={<AddCircleOutlineOutlinedIcon color="primary" />}
    color="secondary"
  >
    {children}
  </StyledButton>
);
