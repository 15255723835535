import React from 'react';
import { Dialog, DialogProps, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from 'rsuite';

import { useStyles } from './styles';

interface ConfirmDialogProps {
  handleConfirm?: () => void;
  handleClose: () => void;
  cancelTitle?: string;
  confirmTitle?: string;
  confirmButton?: React.ReactElement;
  withButtonClose?: boolean;
  subtitle?: string;
}

export const ConfirmDialog: React.FC<DialogProps & ConfirmDialogProps> = ({
  open,
  handleConfirm,
  handleClose,
  title,
  children,
  disableBackdropClick,
  disableEscapeKeyDown = false,
  fullScreen,
  fullWidth,
  maxWidth,
  onBackdropClick,
  onEnter,
  onExit,
  confirmTitle = 'Yes',
  cancelTitle = 'Cancel',
  confirmButton,
  withButtonClose,
  subtitle,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onBackdropClick={onBackdropClick}
      onEnter={onEnter}
      onExit={onExit}
      className={classes.root}
    >
      <div className={classes.header}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        {withButtonClose ? (
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        ) : null}
      </div>
      <DialogContent>
        <DialogContentText>{subtitle}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} appearance="link" className="MuiButton-containedSecondary">
          {cancelTitle}
        </Button>
        {confirmButton ? (
          confirmButton
        ) : (
          <Button onClick={handleConfirm} appearance="primary" className="MuiButton-containedPrimary">
            {confirmTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
