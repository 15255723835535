import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { CollapsableSection } from './CollapsableSection';
import { BasicSaveResponse, CapabilitiesResponseItem, CollapsedSectionProps, FormCapability, RoleInfo } from '../types';
import { confirmAction, jsonCopy } from '../../../../helpers';
import { handleRequestSuccess } from '../../../../helpers/handle-request-success';
import { useToasts } from 'react-toast-notifications';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { updateCertifcationCapabilities } from '../../../../api/certification/partial-update/update-certification-capabilities';
import { CapabilitiesTreeRenderer } from '../../../../shared-components/helper-components/CapabilitiesTreeRenderer';
import SortableTree, {
  changeNodeAtPath,
  getFlatDataFromTree,
  removeNodeAtPath,
  TreeItem,
  getTreeFromFlatData,
} from 'react-sortable-tree';
import { searchCapabilities } from '../../../../api/certification';
import { CertificationStatuses } from '../../../../helpers/constants/certification-statuses';
import { RootReducer } from '../../../../redux/rootReducer';
import {
  CertificationDefinition,
  SectionEditState,
  setLoading,
  setSectionEditState,
  updateCertificationProperty,
} from '../../../../redux/modules/certification';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { saveData } from './helpers';
import { IsModifiedProps, useChangeDetection } from '../../../../helpers/hooks/useChangeDetection';
import { AsyncAutocomplete } from '../../../../shared-components/inputs/AsyncAutocomplete';
import { validateTreeDataChildrenLimit } from './helpers/validate';

const getReformattedData = (treeData: TreeItem[], isSaveRemote = false) =>
  getFlatDataFromTree({ treeData, getNodeKey: ({ treeIndex }) => treeIndex, ignoreCollapsed: false }).map(item => {
    const newItem = {
      ...item.node,
      capability: {
        id: item.node.id,
        name: item.node.title as string,
        version: item.node.version as string,
        status: item.node.status,
      },
      displayParent: item.path.length > 1 ? (item.path[item.path.length - 2] as number) : null,
      mandatory: item.node.mandatory,
      minChildren: item.node.minChildren,
      maxChildren: item.node.maxChildren,
      isErrorChildrenFields: item.node.isErrorChildrenFields,
    };
    // remove unnecessary value
    if (isSaveRemote) {
      delete newItem.isErrorChildrenFields;
    }
    return newItem;
  });

const calculateHashValue = (values: TreeItem[]) => {
  return JSON.stringify(
    jsonCopy(values).map((item: TreeItem) => ({ id: item.id ?? '', mandatory: item.mandatory ?? '' })),
  );
};

interface State {
  capabilities: FormCapability[];
  role: RoleInfo;
  versionId: number;
  status?: CertificationStatuses;
  sectionsEditState: SectionEditState;
  save: boolean;
}

type CapabilitiesSectionProps = CollapsedSectionProps & Partial<IsModifiedProps>;

export const CapabilitiesSection = forwardRef<HTMLDivElement, CapabilitiesSectionProps>(function CapabilitiesSection(
  { isView = false, setIsModified: setIsSectionModified }: CapabilitiesSectionProps,
  ref,
) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { capabilities, role, versionId, status = CertificationStatuses.DRAFT, sectionsEditState, save } = useSelector<
    RootReducer,
    State
  >(
    ({ certificationReducer }) => ({
      capabilities: certificationReducer.certification.capabilities,
      role: certificationReducer.certification.generalInfo.role,
      versionId: certificationReducer.certification.versionId as number,
      status: certificationReducer.certification.status,
      sectionsEditState: certificationReducer.sectionsEditState,
      save: certificationReducer.loadingState.save,
    }),
    shallowEqual,
  );

  const [isModified, setIsModified] = useState(false);
  const [treeData, setTreeData] = useState<TreeItem[]>([]);

  const setIsModifiedState = (value: boolean) => {
    setIsModified(value);
    setIsSectionModified && setIsSectionModified(value);
  };

  const isEditMode = () => (isView && sectionsEditState.capabilities) || !isView;

  const resetUpdatedTreeChildrenLimit = (updatedTreeData: TreeItem[]) => {
    updatedTreeData.forEach(item => {
      if (!item.children || item.children.length == 0) return;
      // clear minChildren / maxChildren
      if (
        item.children.length < 2 &&
        !item.children.some(c => c.mandatory === true) &&
        (item.minChildren != 0 || item.maxChildren != -1)
      ) {
        item.minChildren = 0;
        item.maxChildren = -1;
      }
      // recursive handle children
      if (item.children.length > 0) resetUpdatedTreeChildrenLimit(item.children as TreeItem[]);
    });
  };

  const handleTreeChange = (updatedTreeData: TreeItem[]) => {
    // reset minChildren / maxChildren
    resetUpdatedTreeChildrenLimit(updatedTreeData);
    // validate minChildren / maxChildren
    validateTreeDataChildrenLimit(updatedTreeData);

    if (isView) {
      setTreeData(updatedTreeData);
    } else {
      dispatch(
        updateCertificationProperty(
          getReformattedData(updatedTreeData) as Partial<CertificationDefinition>,
          'capabilities',
        ),
      );
    }
  };

  const removeByPath = (path: number[]) =>
    handleTreeChange(removeNodeAtPath({ treeData, path, getNodeKey: ({ treeIndex }) => treeIndex }));

  const getData = () =>
    getTreeFromFlatData({
      flatData: jsonCopy(capabilities).map((item, index) => ({
        ...item,
        id: item.capability.id,
        title: item.capability?.name as string,
        status: item.capability.status,
        version: (item.capability?.version as string) || item.capability?.revision,
        displayParent: item.displayParent,
        mandatory: item.mandatory,
        minChildren: item.minChildren ?? 0,
        maxChildren: item.maxChildren ?? -1,
        isErrorChildrenFields: item.isErrorChildrenFields ?? [false, false],
        removeItem: removeByPath,
        index,
      })),
      getKey: node => node.index,
      getParentKey: node => node.displayParent,
      // @ts-ignore
      rootKey: null,
    });

  const setChangeDetectionInitialValue = useChangeDetection(
    setIsModifiedState,
    calculateHashValue,
    treeData,
    getData(),
  );

  const onSave = () => {
    // validate minChildren / maxChildren
    if (!validateTreeDataChildrenLimit(treeData)) {
      return;
    }

    dispatch(setLoading({ save: true }));
    return updateCertifcationCapabilities(versionId, getReformattedData(treeData, true))
      .then(
        ({
          data: { capabilities, ...dataToUpdate },
        }: AxiosResponse<{ capabilities: FormCapability[] } & BasicSaveResponse>) => {
          saveData('capabilities', capabilities as Partial<CertificationDefinition>, 'version', dataToUpdate);
          handleRequestSuccess(t('certifications.notifications.saved'), addToast);
          setIsModifiedState(false);
          setChangeDetectionInitialValue(treeData);
        },
      )
      .catch((errors: AxiosError) => handleRequestFail(errors, addToast));
  };

  const searchService = useCallback(
    async (search: string): Promise<AxiosResponse<CapabilitiesResponseItem[]>> => {
      if (role.id === null) {
        return { data: [] as CapabilitiesResponseItem[] } as AxiosResponse<CapabilitiesResponseItem[]>;
      }
      return searchCapabilities(role.id, search);
    },
    [role],
  );

  const addItem = (itemToAdd: CapabilitiesResponseItem) => {
    const formattedItem = {
      title: itemToAdd.name,
      status: itemToAdd.status,
      id: itemToAdd.id,
      version: itemToAdd.version,
      displayParent: null,
      mandatory: false,
      minChildren: 0,
      maxChildren: -1,
      isErrorChildrenFields: [false, false],
      removeItem: removeByPath,
      children: [],
    };
    handleTreeChange([...treeData, formattedItem]);
  };

  const resetForm = () => {
    setTreeData(getData());
    setIsModifiedState(false);
    dispatch(setSectionEditState({ capabilities: false }));
  };

  const onCancel = () => confirmAction(() => isModified, resetForm);

  const assignRemoveByPathProperty = (treeData: TreeItem[]): TreeItem[] => {
    return treeData.map((item: TreeItem) => ({
      ...item,
      removeItem: removeByPath,
      children: item.children ? assignRemoveByPathProperty(item.children as TreeItem[]) : undefined,
    })) as TreeItem[];
  };

  const getItems = (treeData: TreeItem[]) => {
    return assignRemoveByPathProperty(treeData);
  };

  useEffect(() => {
    if ((isView && !isEditMode() && treeData.length === 0) || !isView) {
      setTreeData(getData());
    }
  }, [capabilities]);

  const reformattedData = getReformattedData(treeData);

  return (
    <CollapsableSection
      title={`${t('certifications.capabilities.title')} ${t('certifications.capabilities.addition', {
        count: isView ? reformattedData.length : capabilities.length,
      })}`}
      showEditIcon={isView && status === CertificationStatuses.DRAFT}
      isEdit={sectionsEditState.capabilities}
      onCancel={onCancel}
      onSave={onSave}
      isSaving={save}
      ref={ref}
      onEditIconClick={() => dispatch(setSectionEditState({ capabilities: true }))}
    >
      {capabilities.length === 0 && isView && !sectionsEditState.capabilities ? (
        <p className="mt-1">{t('common.placeholders.noDataSpecified')}</p>
      ) : (
        <>
          {isEditMode() && (
            <AsyncAutocomplete<CapabilitiesResponseItem, false, true, false>
              key={`autocomplete-${treeData.length}`} // forces input re-creation
              className="mt-1"
              onChange={addItem}
              searchService={searchService}
              getOptionLabel={option => option.name}
              disabled={role.id === null}
              renderOption={option => (
                <div>
                  <span>{option.name}</span>
                  <small className="text-muted pl-1">{option.version}</small>
                </div>
              )}
            />
          )}
          <div style={{ height: reformattedData.length * 80 < 560 ? reformattedData.length * 80 : 560 }}>
            <SortableTree
              treeData={getItems(treeData)}
              onChange={handleTreeChange}
              canDrag={isEditMode()}
              isVirtualized
              // @ts-ignore
              nodeContentRenderer={CapabilitiesTreeRenderer}
              generateNodeProps={rowInfo => ({
                handleCheck: () => {
                  handleTreeChange(
                    changeNodeAtPath({
                      treeData,
                      path: rowInfo.path,
                      newNode: {
                        ...rowInfo.node,
                        mandatory: !rowInfo.node.mandatory,
                      },
                      getNodeKey: ({ treeIndex }) => treeIndex,
                    }),
                  );
                },
                handleMinChildrenChange: (value: number) => {
                  handleTreeChange(
                    changeNodeAtPath({
                      treeData,
                      path: rowInfo.path,
                      newNode: {
                        ...rowInfo.node,
                        minChildren: value,
                      },
                      getNodeKey: ({ treeIndex }) => treeIndex,
                    }),
                  );
                },
                handleMaxChildrenChange: (value: number) => {
                  handleTreeChange(
                    changeNodeAtPath({
                      treeData,
                      path: rowInfo.path,
                      newNode: {
                        ...rowInfo.node,
                        maxChildren: value,
                      },
                      getNodeKey: ({ treeIndex }) => treeIndex,
                    }),
                  );
                },
                handleMaxiumButtonPress: (value: number) => {
                  handleTreeChange(
                    changeNodeAtPath({
                      treeData,
                      path: rowInfo.path,
                      newNode: {
                        ...rowInfo.node,
                        maxChildren: value,
                      },
                      getNodeKey: ({ treeIndex }) => treeIndex,
                    }),
                  );
                },
              })}
            />
          </div>
        </>
      )}
    </CollapsableSection>
  );
});
