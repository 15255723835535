import { Panel, PanelGroup } from 'rsuite';
import { DetailsItem } from './DetailsItem';
import { FileItem } from './FileItem';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TestingException } from '../../redux/modules/application-flow';
import { LabelTypes } from '../../helpers/constants/label-types';
import { LabelType } from '../../shared-components/label-type/LabelType';
import { ExceptionTypes } from '../../components/pages/application/types';
// @ts-ignore
import _var from '../../styles/_variables.scss';

export const ExceptionsPanel = ({ data }: { data: TestingException[] }) => {
  const { t } = useTranslation();

  const renderLabel = (exceptionType: string) => {
    switch (exceptionType) {
      case ExceptionTypes.TEST_EXCEPTION:
        return (
          <LabelType label={t('applications.labs.exceptionLabLabelTxt')} labelTypes={LabelTypes.EXCEPTION_LABEL} />
        );
      case ExceptionTypes.LAB_EXCEPTION:
        return (
          <LabelType label={t('applications.labs.exceptionLabLabelTxt')} labelTypes={LabelTypes.EXCEPTION_LABEL} />
        );
      case ExceptionTypes.CC_EXCEPTION:
        return (
          <LabelType
            label={t('applications.labs.exceptionConformanceLabelTxt')}
            labelTypes={LabelTypes.CC_EXCEPTION_LABEL}
            tooltipContent={
              <Trans i18nKey="applications.labs.exceptionConformanceLabelTooltip">
                prefix
                <a
                  href="https://www.wi-fi.org/file/quicktrack-qualified-solution-policy"
                  target="_Blank"
                  rel="noreferrer"
                  style={{ color: _var.primary }}
                >
                  QuickTrack Qualified Solution Policy
                </a>
                suffix
              </Trans>
            }
          />
        );
      default:
        return (
          <LabelType label={t('applications.labs.exceptionTestLabelTxt')} labelTypes={LabelTypes.EXCEPTION_LABEL} />
        );
    }
  };

  return (
    <>
      {data.length ? (
        <PanelGroup style={{ marginTop: 10 }} accordion bordered>
          {data.map((exception, index) => (
            <Panel
              key={index}
              header={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    {exception.requestedBy?.name}
                    {' @ '}
                    {exception.requestedBy?.company?.name}
                  </div>
                  {exception.exceptionType ? renderLabel(exception.exceptionType) : null}
                </div>
              }
              defaultExpanded={index === 0}
            >
              <DetailsItem label={t('applications.labs.explanation')}>
                <p>{exception.explanation}</p>
              </DetailsItem>
              <DetailsItem label={t('applications.labs.supportingDocs')} className="mb-1">
                {exception.attachments.length ? (
                  exception.attachments.map(file => <FileItem file={file} key={file.id} />)
                ) : (
                  <span className="text-muted">{t('common.placeholders.noDataSpecified')}</span>
                )}
              </DetailsItem>
            </Panel>
          ))}
        </PanelGroup>
      ) : (
        <span className="text-muted">{t('common.placeholders.noDataSpecified')}</span>
      )}
    </>
  );
};
