import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';

import {
  AppCertification,
  AppCertPrereqs,
  ApplicationFlowState,
  BasicAppCertification,
  checkCapability,
  PossiblePrimaries,
  unCheckCapability,
  updateApplicationProperty,
  setCapabilitisByCC,
} from '../../../../../redux/modules/application-flow';
import {
  CapabilitiesResponse,
  getCapabilitiesByAppId,
  GetCapabilitiesByCC,
} from '../../../../../api/application/get-capabilities-by-CC';
import { RootReducer } from '../../../../../redux/rootReducer';
import { CapabilityRow } from './partials/capabilities-step/CapabilityRow';
import { AppCapability, AllowedVersion, AppCertVersion } from '../../../../../redux/modules/application-flow';
import { CertificationType } from '../../../certification/types';
import { Confirm } from '../../../../../helpers/confirmationPopup/Confirm';
import { isParentPresent } from '../../../../../redux/modules/application-flow/helpers';
import { IsModifiedProps, numberSort, useChangeDetection } from '../../../../../helpers/hooks/useChangeDetection';
import { jsonCopy } from '../../../../../helpers';
import { CircularLoader } from '../../../../../shared-components/loader/CircularLoader';
import { getCapabilityValidateErrorIds } from './partials/capabilities-step/helpers';

interface Capability extends CapabilitiesResponse {
  index: number;
}

export interface CapabilitiesStepData extends BasicAppCertification {
  isPreReq?: boolean;
  version: AppCertVersion;
  allowedVersions?: AllowedVersion[];
  capabilities: Capability[];
  prerequisites: AppCertPrereqs[];
}

export interface PreReqTree {
  id: number;
  capabilities: AppCapability[];
}

const calculateHashValue = (values: AppCertification[]) => {
  return JSON.stringify(
    jsonCopy(values)
      .map(cert => ({
        id: cert.id,
        capabilities: cert.capabilities.map(item => item.id).sort(numberSort),
      }))
      .sort((a, b) => a.id - b.id),
  );
};

interface CapabilitiesStepProps {
  onPreviousStep: () => void;
  setIsModified: IsModifiedProps['setIsModified'];
  handleCapabilitesMissingInfo: (value: boolean) => void;
}

export const CapabilitiesStep = ({
  setIsModified,
  onPreviousStep,
  handleCapabilitesMissingInfo,
}: CapabilitiesStepProps) => {
  const [certificationsArray, setCertificationsArray] = useState<CapabilitiesStepData[]>([]);
  const [expandedRow, setExpandedRow] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { t } = useTranslation();

  const {
    id,
    certifications,
    testingInfo,
    selectedTestingLabs,
    autoValidateCapability,
    capabilitisByCC,
  }: ApplicationFlowState = useSelector<RootReducer, ApplicationFlowState>(state => state.applicationFlow);

  const dispatch = useDispatch();
  const defaultMissingInfo = {
    skipAdding: false,
    certification: {
      name: '',
      roleName: '',
      type: '',
    },
    possiblePrimaries: [] as PossiblePrimaries[],
  };
  const missingInfoRef = useRef(defaultMissingInfo);

  const updateChangeDetectionState = useChangeDetection(setIsModified, calculateHashValue, certifications);

  const needToShowPopUp =
    testingInfo.testingLabs.length > 0 ||
    Boolean(testingInfo.testingException) ||
    selectedTestingLabs.some(el => el.id !== null);

  const handleExpand = (id: number) => {
    setExpandedRow(prevState => (prevState.includes(id) ? prevState.filter(num => num !== id) : [...prevState, id]));
  };

  const showPrereqWarning = () => {
    const { certification, possiblePrimaries } = missingInfoRef.current;
    return Confirm({
      title: 'Some prerequisites are missing',
      yesText: 'Go back to Certifications',
      noText: 'Stay on Capabilities',
      message: `${
        certification.type === CertificationType.PRIMARY
          ? 'Please return to Certifications step and add'
          : 'Capability is missing:'
      } ${certification.name} (${certification.roleName}). ${
        certification.type === CertificationType.PRIMARY
          ? ''
          : 'Certifications which have this capability:' + possiblePrimaries.map(el => el.name).join(', ')
      }`,
      onAccept: () => {
        if (certification.type === CertificationType.PRIMARY) {
          onPreviousStep();
        }
        missingInfoRef.current = defaultMissingInfo;
      },
      size: 'sm',
    });
  };

  const mergePreReqs = (dataToMerge: PreReqTree[], additionalPrereqs: PreReqTree[]) => {
    return additionalPrereqs.forEach(item => {
      const idx = dataToMerge.findIndex(cert => cert.id === item.id);
      if (idx !== -1) {
        item.capabilities.forEach(el => {
          const idxCap = dataToMerge[idx].capabilities.findIndex(capability => capability.id === el.id);
          if (idxCap !== -1) {
            const dataToMergeCurrEl = dataToMerge[idx].capabilities[idxCap];

            dataToMergeCurrEl.isPreReq = el.isPreReq || dataToMergeCurrEl.isPreReq;
            dataToMergeCurrEl.preReqOf = Array.from(
              new Set<number>([...(el.preReqOf || []), ...(dataToMergeCurrEl.preReqOf || [])]),
            );
          } else {
            dataToMerge[idx].capabilities.push(el);
          }
        });
      } else {
        dataToMerge.push(item);
      }
    });
  };

  const getFormattedCapability = (item: Capability, isPreReq = false, prereqOf?: number[]) => ({
    id: item.capability.id,
    name: item.capability.name,
    mandatory: item.mandatory,
    prequalified: item.capability.prequalified,
    previouslySelected: item.capability.previouslySelected,
    prerequisites: item.capability.prerequisites,
    isPreReq,
    prereqOf,
    index: item.index,
    displayParent: item.displayParent,
    minChildren: item.minChildren,
    maxChildren: item.maxChildren,
  });

  const buildPreReqTree = ({ id, prerequisites: preReqs }: AppCapability): PreReqTree[] => {
    if (!preReqs || preReqs.length === 0 || missingInfoRef.current.skipAdding) {
      return [];
    }
    const tree: PreReqTree[] = [];
    preReqs.forEach(preReq => {
      const certToSet: PreReqTree[] = [];
      // when we have prereqs for primary, they don't have type that is why !prereq.type
      // means it is capability
      if ((preReq.type === CertificationType.CAPABILITY || !preReq.type) && preReq.allowedVersions.length > 0) {
        let capabilities: AppCapability[] = [];
        certificationsArray.forEach(el => {
          const capabilitiesForPrimary = el.capabilities.reduce((accum, curr) => {
            const versionId = preReq.allowedVersions.find(version => version.id === curr.capability.id)?.id;
            if (versionId) {
              const item = getFormattedCapability(curr, true, [id]);
              accum.push(
                item,
                ...el.capabilities
                  .map(cap => getFormattedCapability(cap))
                  .filter(
                    cap =>
                      cap.mandatory &&
                      isParentPresent(
                        cap,
                        el.capabilities.map(element => getFormattedCapability(element)),
                      ),
                  ),
              );
            }
            return accum;
          }, [] as AppCapability[]);
          if (capabilitiesForPrimary.length > 0) {
            certToSet.push({
              id: el.version.id,
              capabilities: capabilitiesForPrimary,
            });
            capabilities = [
              ...capabilities,
              ...capabilitiesForPrimary.filter(el => !capabilities.some(item => el.id === item.id)),
            ];
          }
        });
        if (capabilities.length > 0) {
          capabilities.forEach(capability => {
            if (capability?.prerequisites && capability?.prerequisites.length > 0) {
              mergePreReqs(certToSet, buildPreReqTree(capability));
            }
          });
        } else if (preReq.allowedVersions.length > 0) {
          missingInfoRef.current = {
            skipAdding: true,
            certification: {
              name: preReq.certification.name,
              roleName: preReq.certification.role.name,
              type: preReq.type,
            },
            possiblePrimaries: preReq.possiblePrimaries,
          };
        }
      } else {
        // IF NOT THROW ERROR
        const isPrimaryPresent = certificationsArray.some(el =>
          preReq.allowedVersions.some(item => item.id === el.version.id),
        );
        if (!isPrimaryPresent && preReq.allowedVersions.length > 0) {
          missingInfoRef.current = {
            skipAdding: true,
            certification: {
              name: preReq.certification.name,
              roleName: preReq.certification.role.name,
              type: preReq.type,
            },
            possiblePrimaries: preReq.possiblePrimaries,
          };
        }
      }
      mergePreReqs(tree, certToSet);
    });
    return tree;
  };

  const dispatchUnCheckCapability = (capability: AppCapability, cleanup: boolean) => {
    if (
      missingInfoRef.current.skipAdding &&
      capability.prerequisites?.some(
        prereq =>
          prereq.certification.name === missingInfoRef.current.certification.name &&
          prereq.certification.role.name === missingInfoRef.current.certification.roleName &&
          prereq.type === missingInfoRef.current.certification.type,
      )
    ) {
      missingInfoRef.current = defaultMissingInfo;
    }
    dispatch(unCheckCapability({ capability, cleanup }));
  };

  const changeCheckedCapability = (
    capability: AppCapability,
    checked: boolean,
    cleanup = false,
    needToShowUncheckPrecertified: boolean,
  ) => {
    if (checked) {
      const preReqsInfo = buildPreReqTree(capability);
      if (!missingInfoRef.current.skipAdding) {
        const additions = certificationsArray
          .filter(cert => cert.capabilities.some(item => item.capability.id === capability.id))
          .map(el => ({
            id: el.version.id,
            capabilities: el.capabilities
              .filter(
                item =>
                  (item.mandatory && item.displayParent === capability.index) || item.capability.id === capability.id,
              )
              .map(cap => getFormattedCapability(cap)),
          }));
        dispatch(checkCapability({ additions, prereqs: preReqsInfo, cleanup }));
      } else {
        return showPrereqWarning();
      }
    } else {
      if (needToShowUncheckPrecertified) {
        return Confirm({
          title: t('applications.cc.warning.title'),
          message: t('applications.certifications.removePreCertifiedWarning', {
            addition: 'capability',
          }),
          onAccept: () => {
            dispatchUnCheckCapability(capability, cleanup);
          },
        });
      }
      dispatchUnCheckCapability(capability, cleanup);
    }
  };

  const handleCapabilityCheck = (
    capability: AppCapability,
    checked: boolean,
    needToShowUncheckPrecertified: boolean,
  ) => {
    if (needToShowPopUp) {
      return Confirm({
        title: t('applications.cc.warning.title'),
        message: t('applications.cc.warning.message', {
          addition: t('applications.cc.warning.additions.labs'),
        }),
        onAccept: () => {
          setTimeout(() => changeCheckedCapability(capability, checked, true, needToShowUncheckPrecertified));
        },
      });
    }
    return changeCheckedCapability(capability, checked, false, needToShowUncheckPrecertified);
  };

  const syncMandatoryCapabilities = (tableData: CapabilitiesStepData[]) => {
    // looking for capability which is mandatory
    const mandatoryCapabilities: number[] = [];
    tableData.map(cert => {
      cert.capabilities.map(caps => {
        if (caps.mandatory) {
          mandatoryCapabilities.push(caps.capability.id);
        }
      });
    });
    // sync mandatory capabilities among certifications
    tableData.map(cert => {
      cert.capabilities.map(caps => {
        if (mandatoryCapabilities.includes(caps.capability.id)) {
          caps.mandatory = true;
        }
      });
    });
  };

  useEffect(() => {
    getCapabilitiesByAppId(id as number)
      .then(({ data }: AxiosResponse<GetCapabilitiesByCC[]>) => {
        const tableData = data.map(item => {
          const certification = certifications.find(cert => cert.version.id === item.id) as AppCertification;
          return {
            ...item,
            ...certification,
            capabilities: item.capabilities.map((el, index) => ({ ...el, index })) as Capability[],
          };
        });
        if (expandedRow.length === 0) {
          const id = tableData.find(el => el.capabilities.length > 0)?.id;
          setExpandedRow(id ? [id] : []);
        }

        syncMandatoryCapabilities(tableData);
        setCertificationsArray(tableData);
        // save to redux for cross component capabilit validate use
        dispatch(setCapabilitisByCC(data));
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    const isSomeCapabilitiesSelected = certifications.some(cert => cert.capabilities.length > 0);
    const preReqs: PreReqTree[] = [];
    certificationsArray.forEach(cert => {
      const buildTreeByCert = buildPreReqTree(cert);
      mergePreReqs(preReqs, buildTreeByCert);
      cert.capabilities.forEach(cap => {
        const { mandatory, capability } = cap;
        const isSelectedCapability = certifications.some(item => {
          if (item.version.id === cert.version.id) {
            return item.capabilities.some(citem => citem.id === capability.id);
          }
          return false;
        });
        if (
          mandatory ||
          ((capability.prequalified || capability.previouslySelected) &&
            ((isSomeCapabilitiesSelected && isSelectedCapability) || !isSomeCapabilitiesSelected)) ||
          preReqs.some(el => el.capabilities.some(cap => cap.id === capability.id))
        ) {
          const item = getFormattedCapability(cap);

          const capabilityAdditions = buildPreReqTree(capability);

          const idx = capabilityAdditions.findIndex(el => el.id === cert.version.id);
          if (idx !== -1) {
            capabilityAdditions[idx].capabilities.push(item);
            mergePreReqs(preReqs, capabilityAdditions);
          } else {
            mergePreReqs(preReqs, [
              ...capabilityAdditions,
              {
                id: cert.version.id,
                capabilities: [item],
              },
            ]);
          }
        }
      });
    });
    if (preReqs.length > 0) {
      const data = certifications.map(item => {
        const cert = preReqs.find(el => el.id === item.version.id);
        const capabilities = cert?.capabilities || [];

        const newCapabilitiesOfNotPreReqs: AppCapability[] = [];
        const capabilitiesOfNotPreReqs = item.capabilities.filter(c => !capabilities.find(cap => cap.id === c.id));
        if (capabilitiesOfNotPreReqs.length > 0) {
          const tempCert = certificationsArray.find(cert => cert.version.id === item.version.id);
          if (tempCert) {
            capabilitiesOfNotPreReqs.forEach(el => {
              const tempCapability = tempCert.capabilities.find(cap => cap.capability.id === el.id);
              if (tempCapability) {
                newCapabilitiesOfNotPreReqs.push(getFormattedCapability(tempCapability));
              }
            });
          }
        }

        const mergedCapabilities = [...capabilities, ...newCapabilitiesOfNotPreReqs];
        return { ...item, capabilities: mergedCapabilities };
      });
      dispatch(updateApplicationProperty(data, 'certifications'));
      updateChangeDetectionState(data);
    }
    if (missingInfoRef.current.skipAdding) {
      showPrereqWarning();
    }
  }, [certificationsArray]);

  useEffect(() => {
    if (autoValidateCapability) {
      const errorIds = getCapabilityValidateErrorIds(certifications, capabilitisByCC);
      setExpandedRow(prevState => Array.from(new Set([...prevState, ...errorIds])));
    }
  }, [certifications, autoValidateCapability]);

  useEffect(() => {
    handleCapabilitesMissingInfo(missingInfoRef.current.skipAdding);
  }, [missingInfoRef.current.skipAdding]);

  return (
    <>
      <p>Please select applicable capabilities.</p>
      <div className="my-1">
        {loading ? (
          <div style={{ minHeight: 200 }}>
            <CircularLoader content={t('common.placeholders.loadingData')} size={20} />
          </div>
        ) : (
          certificationsArray.map(cert => (
            <CapabilityRow
              certification={cert}
              isExpanded={expandedRow.includes(cert.id)}
              setExpanded={handleExpand}
              handleCapabilityCheck={handleCapabilityCheck}
              key={uniqid()}
            />
          ))
        )}
      </div>
    </>
  );
};
