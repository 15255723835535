import React from 'react';

import { withStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';

const StyledBox = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:hover': {
      color: '#976083',
    },
    display: 'flex',
    alignItems: 'center',
  },
}))(Box);

export const GoBackStepButton = () => {
  const { t } = useTranslation();

  return (
    <StyledBox component="span">
      <KeyboardArrowLeftRoundedIcon style={{ fontSize: 26 }} />
      <span>{t('applications.review.goBackButton')}</span>
    </StyledBox>
  );
};
