import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { Card } from '../../../shared-components';
import { PageTemplateProps } from './types';
import classnames from 'classnames';
import { ActionsWrapper } from '../../../shared-components/theme/tabs/styled-components';
import { Collapse } from '@material-ui/core';
import CollapseWrapper from '../../../shared-components/table/CollapseWrapper';

export const PageTemplate = ({
  title,
  actionLeft,
  footerActionLeft,
  actionRight,
  footerActionRight,
  children,
  className = '',
  style,
  noFooter = false,
  footerAddition,
  withBorder = false,
  footerActions,
  filters,
  filterButtons,
  useCollapse = false,
  collapseOpen = false,
}: HTMLAttributes<{}> & PropsWithChildren<PageTemplateProps>) => {
  const hasACtionLeft = !!actionLeft;

  return (
    <Card
      type="light"
      style={style}
      className={classnames('page-template', className, { 'with-header-border': withBorder })}
    >
      <section className={classnames('page-header', { 'mb-1': !!title })}>
        {hasACtionLeft ? (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {actionLeft}
          </div>
        ) : null}
        <div
          style={{
            flex: hasACtionLeft ? 1.3 : 1,
            display: 'flex',
            justifyContent: hasACtionLeft ? 'center' : 'flex-start',
            alignItems: 'center',
          }}
        >
          <h4>{title}</h4>
        </div>
        <div
          style={{
            flex: hasACtionLeft ? 1 : 3,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <ActionsWrapper>
            {!useCollapse && filters}
            {actionRight || <span></span>}
          </ActionsWrapper>
        </div>
      </section>
      {useCollapse && (
        <section>
          <Collapse in={collapseOpen}>
            <CollapseWrapper>
              {filters}
              {filterButtons}
            </CollapseWrapper>
          </Collapse>
        </section>
      )}
      <section className={classnames({ 'py-2': withBorder })}>{children}</section>
      {noFooter ? null : (
        <section className="page-footer">
          {footerActionLeft ? footerActionLeft : actionLeft ? actionLeft : null}
          <span></span>
          {footerActionRight ? (
            footerActionRight
          ) : actionRight ? (
            <div className="page-footer-buttons-right">
              {footerAddition}
              {footerActions}
              {actionRight}
            </div>
          ) : (
            <div>{footerAddition}</div>
          )}
        </section>
      )}
    </Card>
  );
};
