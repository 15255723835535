import { Confirm } from './confirmationPopup/Confirm';
import i18n from './i18n-setup';

export const confirmAction = (
  confirmationRequired: () => boolean,
  onAccept: () => void,
  messageKey = 'common.unsavedDataLeaveWarning',
) => {
  if (confirmationRequired()) {
    Confirm({
      title: i18n.t('common.placeholders.areYouSure'),
      message: i18n.t(messageKey),
      onAccept,
    });
  } else {
    onAccept();
  }
};
