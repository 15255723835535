import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatFee } from '../../../helpers/billing';
import classnames from 'classnames';

const useStyles = makeStyles({
  title: {
    marginRight: '8px',
    fontWeight: 'bold',
    color: '#252c35',
    lineHeight: '40px',
  },
  feeContainer: {
    marginTop: '10px',
  },
  fee: {
    fontSize: '16px',
    lineHeight: '18px',
    marginBottom: '15px',
  },
  cwgrfNote: {
    marginBottom: '16px',
  },
  feeWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  feeTesting: {
    justifyContent: 'space-around',
    width: '100%',
  },
});

export interface EstimatedFee {
  estimatedFees: number;
  variant?: string;
}

const EstimatedFees = ({
  estimatedFees = 0,
  title,
  variant,
  notes,
  testingView,
  fees,
}: {
  estimatedFees?: number;
  title: string;
  variant?: string;
  notes?: string;
  testingView?: boolean;
  fees?: EstimatedFee[];
}) => {
  const classes = useStyles();

  return (
    <>
      {notes && <div className={classes.cwgrfNote}>{notes}</div>}
      <div className={classnames(classes.feeWrapper, testingView && classes.feeTesting)} data-test-id="estimatedFees">
        <label className={classes.title}>{title}</label>
        <div className={classes.feeContainer}>
          {fees ? (
            fees.map(({ estimatedFees, variant }, index) => (
              <div key={`feeString${index}`} className={classes.fee} data-test-id="feeString">
                {formatFee(estimatedFees, variant)}
              </div>
            ))
          ) : (
            <div className={classes.fee} data-test-id="feeString">
              {formatFee(estimatedFees, variant)}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EstimatedFees;
