import axios from '../../helpers/axios-setup';
import { BasicCertification } from '../../redux/modules/application-flow';
import { TestingLab } from '../../components/pages/application/types';

export interface LabsTestingInfo {
  interoperability: boolean;
  conformance: boolean;
}

export interface LabsResponse {
  certification: BasicCertification & { testingType: LabsTestingInfo } & { version: { id: number; name: string } };
  requiredTesting: LabsTestingInfo;
  laboratories: TestingLab[];
}

export const getLabsByAppId = (appId: number) => {
  return axios.get(`/applications/builder/details/labs/${appId}`);
};
