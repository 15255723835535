import React, { useMemo, useState } from 'react';
import { OnChangeFunc, TestCriteria } from '../../types';
import { ErrorObject } from '../../../../../helpers/types';
import { Affix, Whisper, Popover, Icon } from 'rsuite';
import { testCaseAutoComplete } from '../../../../../api/test-case';
import { TCAutocomplete } from '../../../test-case/types';
import { LabeledCheckbox } from '../../../../../shared-components/labeled-inputs';
import { MeasurementComparison } from '../../partials';
import { Th, Td, Table } from '../table-components';
import { SuggestionItem } from '../../partials';
import { useTranslation } from 'react-i18next';
import { CertificationDefinition } from '../../../../../redux/modules/certification';
import { TCStatus } from './TCStatus';
import styled from 'styled-components';
import { ErrorMessage } from '../../../../../shared-components/helper-components/ErrorMessage';
import { AsyncAutocomplete } from '../../../../../shared-components/inputs/AsyncAutocomplete';
import { MaterialAddButton } from '../../../../../shared-components/button/MaterialAddButton';

const StyledEditConfWrapper = styled.div<{ collapsed: boolean }>`
  padding-bottom: 2em;
  display: ${props => (props.collapsed ? 'none' : 'block')};
  .rs-btn-ghost {
    margin-left: 2em;
  }
`;

export const EditConfSection = (props: {
  data: TestCriteria[];
  errors: ErrorObject;
  onChange: OnChangeFunc;
  testGroupIndex: number;
  collapsed?: boolean;
}) => {
  const { data, errors, onChange, testGroupIndex, collapsed = false } = props;
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState(false);

  const hasTestCriteriaError = () => `testGroups[${testGroupIndex}].testCriteria` in errors;

  const getOperator = (type: string) => {
    switch (type) {
      case 'STRING':
        return 'REGEX';
      case 'BOOLEAN':
        return 'EQ';
    }
    return '';
  };

  const disabledItemValues = useMemo(() => data.map(testCriteria => testCriteria.test.id), [data]);

  return (
    <StyledEditConfWrapper collapsed={collapsed}>
      <Table style={{ width: '100%' }}>
        <thead>
          <tr>
            <Th style={{ width: '25%' }}>{t('testCases.name')}</Th>
            <Th>{t('testCases.measurementsHeader')}</Th>
            <Th>{t('certifications.measurements.required')}</Th>
            <Th>{t('certifications.measurements.test')}</Th>
            <Th>{t('certifications.measurements.comment')}</Th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td className="text-center text-muted p-2" colSpan={6}>
                <div>No Data</div>
                {hasTestCriteriaError() && (
                  <ErrorMessage>{t('certifications.testGroup.testCaseRequired')}</ErrorMessage>
                )}
              </td>
            </tr>
          ) : null}
          {data.map((testCase, testIndex) =>
            testCase.criteria.map((criteria, index) => (
              <tr key={index}>
                {index === 0 ? (
                  <Td rowSpan={testCase.criteria.length}>
                    <Icon
                      size="lg"
                      className="mr-1 is-cursor-pointer text-muted"
                      icon="trash-o"
                      onClick={() =>
                        onChange(
                          data.filter(item => item.test.id !== testCase.test.id) as Partial<CertificationDefinition>,
                          `testGroups.${testGroupIndex}.testCriteria`,
                        )
                      }
                    />
                    <span className="has-word-break">{testCase.test.name}</span>
                    <TCStatus status={testCase.test.status} errors={errors} index={testIndex} />
                    {errors[`testCriteria[${testIndex}]`] ? (
                      <p className="text-danger">
                        <small>{errors[`testInvokers[${testIndex}]`]}</small>
                      </p>
                    ) : null}
                  </Td>
                ) : null}
                <Td className="has-word-break">{criteria.measurement.name}</Td>
                <Td>
                  <LabeledCheckbox
                    name={`testGroups.${testGroupIndex}.testCriteria.${testIndex}.criteria.${index}.required`}
                    checked={criteria.required}
                    errors={errors}
                    onChange={(value: string, state: boolean) =>
                      onChange(
                        state,
                        `testGroups.${testGroupIndex}.testCriteria.${testIndex}.criteria.${index}.required`,
                      )
                    }
                  />
                </Td>
                <Td>
                  <MeasurementComparison
                    criteria={criteria}
                    handleChange={onChange}
                    testIndex={testIndex}
                    testGroupIndex={testGroupIndex}
                    measurementIndex={index}
                    formErrors={errors}
                  />
                </Td>
                <Td>
                  {criteria.comment ? (
                    <Whisper placement="left" trigger="hover" speaker={<Popover>{criteria.comment}</Popover>}>
                      <Icon icon="eye" size="2x" />
                    </Whisper>
                  ) : null}
                </Td>
              </tr>
            )),
          )}
        </tbody>
      </Table>
      {showSearch && (
        <Affix top={64}>
          <div className="my-1 bg-white">
            <AsyncAutocomplete<TCAutocomplete, false, true, false>
              className="testCriteriaAutocomplete"
              searchService={testCaseAutoComplete}
              renderOption={(item: TCAutocomplete) => <SuggestionItem item={item} />}
              getOptionLabel={item => item.name}
              onChange={(item: TCAutocomplete) => {
                const newItem = {
                  test: { id: item.id, name: item.name, status: item.status },
                  criteria: item.measurements.map(measurement => ({
                    measurement,
                    comment: '',
                    required: true,
                    compareOperation: getOperator(measurement.type),
                    operand: '',
                  })),
                };
                onChange(
                  [...data, newItem] as Partial<CertificationDefinition>,
                  `testGroups.${testGroupIndex}.testCriteria`,
                );
                setShowSearch(false);
              }}
              getOptionDisabled={item => disabledItemValues.includes(item.id)}
            />
          </div>
        </Affix>
      )}
      {showSearch || (
        <MaterialAddButton className="add-test-case" onClick={() => setShowSearch(true)} appearance="ghost">
          {t('certifications.testGroup.testCase')}
        </MaterialAddButton>
      )}
    </StyledEditConfWrapper>
  );
};
