import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Icon } from 'rsuite';
import EditIcon from '@material-ui/icons/EditOutlined';
import { mergeObjects } from '../../helpers';

const stepHeight = 44;
const arrowWidth = Math.sqrt(Math.pow(stepHeight / 2, 2) + Math.pow(stepHeight / 2, 2)) + 2;
const numberWidth = 30;
const brandGreen = '#26716d';
const waitColor = '#d9d9d9';
const hoverColor = '#f4f8f8';
const shadowColor = 'rgba(0, 0, 0, 0.1)';
const gradientColorFrom = brandGreen;
const gradientColorTo = '#00474C';
const processBackgroundColor = '#f8f8f8';
const stepCountThreshold = 3;

const StepWrapper = styled.div`
  height: ${stepHeight}px;
  text-align: center;
  box-shadow: 0px 0px 10px ${shadowColor};
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  .step-title {
    color: ${brandGreen};
    flex-shrink: 1;
    text-align: left;
  }

  .step-number {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: ${numberWidth}px;
    height: ${numberWidth}px;
    margin-right: ${numberWidth / 3}px;
    background-color: white;
    border-radius: ${numberWidth / 2}px;
  }

  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  &:first-of-type {
    border-radius: 4px 0 0 4px;
    padding: 8px 0 8px 8px;
  }

  &:not(:first-of-type) {
    border-left: none;
    padding: 8px 0 8px ${arrowWidth}px;
  }

  &:not(:last-of-type) {
    border-right: none;

    :after {
      content: '';
      transform: rotate(-45deg);
      box-shadow: ${shadowColor} 1.95px 1.95px 2.6px;
      background-color: white;
      width: ${arrowWidth}px;
      height: ${arrowWidth}px;
      right: -${arrowWidth / 2}px;
      position: absolute;
      z-index: -1;
      border-radius: 4px;
    }
  }

  &.success,
  &.active {
    .step-number {
      background: white;
      box-shadow: 0px 0px 4px ${shadowColor};
      color: ${brandGreen};
    }
  }

  &.process.active:not(.current) {
    background: ${processBackgroundColor};
    .step-number {
      color: linear-gradient(180deg, ${gradientColorFrom} 0%, ${gradientColorTo} 100%);
      &.is-icon {
        background-color: ${processBackgroundColor};
        box-shadow: 0 0 0 0;
      }
    }
    :after {
      background-color: ${processBackgroundColor};
    }
  }

  &.current {
    .step-title {
      color: white;
    }
    .step-number {
      color: ${brandGreen};
      background-color: white;
    }
    background: linear-gradient(180deg, ${gradientColorFrom} 0%, ${gradientColorTo} 100%);
    box-shadow: 0px 0px 10px ${shadowColor};
    :after {
      background: linear-gradient(225deg, ${gradientColorFrom} 0%, ${gradientColorTo} 100%);
      box-shadow: 0px 0px 10px ${shadowColor};
    }
  }

  &.wait {
    &.viewed {
      .step-title {
        color: ${brandGreen};
      }
      .step-number {
        background-color: white;
        box-shadow: 0px 0px 4px ${shadowColor};
        color: ${brandGreen};
      }
    }

    .step-title {
      color: ${waitColor};
    }
    .step-number {
      background-color: white;
      box-shadow: 0px 0px 4px ${shadowColor};
      color: ${waitColor};
    }

    :after {
      background-color: white;
    }
  }

  &:not(.current):is(.clickable) {
    :hover {
      background-color: ${hoverColor};
      :after {
        background-color: ${hoverColor};
      }
    }
  }

  @media (max-width: 1280px) {
    font-size: ${props => (props.totalSteps > stepCountThreshold ? 11.5 : 14)}px;

    .step-number {
      width: ${props => (props.totalSteps > stepCountThreshold ? numberWidth - 4 : numberWidth)}px;
      height: ${props => (props.totalSteps > stepCountThreshold ? numberWidth - 4 : numberWidth)}px;
      margin-right: ${props => (props.totalSteps > stepCountThreshold ? (numberWidth - 4) / 3.5 : numberWidth / 3)}px;
    }
  }

  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: ${props => (props.totalSteps > stepCountThreshold ? 13 : 14)}px;

    .step-number {
      width: ${props => (props.totalSteps > stepCountThreshold ? numberWidth - 2 : numberWidth)}px;
      height: ${props => (props.totalSteps > stepCountThreshold ? numberWidth - 2 : numberWidth)}px;
      margin-right: ${props => (props.totalSteps > stepCountThreshold ? (numberWidth - 2) / 3.5 : numberWidth / 3)}px;
    }
  }
`;

export const StepItem = ({
  status,
  stepNumber,
  title,
  isPrevious,
  isActive,
  isCurrent,
  onFinishedClick,
  style,
  totalSteps,
  zIndex,
  viewOnly,
  isViewed,
}: {
  status?: string;
  stepNumber?: number;
  title: string;
  isPrevious?: boolean;
  isCurrent?: boolean;
  isActive?: boolean;
  onFinishedClick?: (value: number) => void;
  style?: CSSProperties;
  totalSteps?: number;
  zIndex?: number;
  viewOnly: boolean;
  isViewed: boolean;
}) => {
  const getStepNumber = () => {
    if (isCurrent || (!isActive && !isCurrent && status !== 'success')) {
      return stepNumber;
    }
    if (isActive && !isCurrent) {
      return <EditIcon />;
    }
    if (status === 'success') {
      if (viewOnly) {
        return stepNumber;
      } else {
        return <Icon icon="check" />;
      }
    }
    return null;
  };

  const isIncludeStatus = (): boolean => ['finish', 'success', 'process'].includes(status as string);

  const isClickable = isIncludeStatus() && onFinishedClick;
  const wrapOnClickEvent = () => {
    if (isCurrent) {
      return;
    }
    if (isClickable) {
      onFinishedClick(stepNumber as number);
    }
  };

  const computedStyles = mergeObjects(style || {}, { zIndex });

  return (
    <StepWrapper
      style={computedStyles}
      className={classNames({
        [status as string]: true,
        active: isActive,
        current: isCurrent,
        previous: isPrevious,
        clickable: isClickable,
        viewed: isViewed,
        'is-cursor-pointer': Boolean(onFinishedClick) && isIncludeStatus() && !isCurrent,
      })}
      onClick={() => wrapOnClickEvent()}
      totalSteps={totalSteps}
    >
      <span
        className={classNames('step-number', {
          'is-icon': typeof getStepNumber() !== 'number',
        })}
      >
        {getStepNumber()}
      </span>
      <span className="step-title" dangerouslySetInnerHTML={{ __html: `${title}` }} />
    </StepWrapper>
  );
};
