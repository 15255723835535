import { StepItem, Steps } from '../../../../shared-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StepStatus } from '../../../../shared-components/step/Steps';

interface NewApplicationStepsProps {
  currentStep: number;
  onClick?: (step: number) => void;
  isReview?: boolean;
}

export const PendingApprovalSteps = ({ currentStep, onClick, isReview = false }: NewApplicationStepsProps) => {
  const { t } = useTranslation();
  const stepTitles = [t('staff.steps.0'), isReview ? t('staff.steps.review') : t('staff.steps.1')];
  const lastStep = 1;
  const lastHighlightedStep = lastStep > currentStep ? lastStep : currentStep;

  return (
    <>
      <Steps
        active={lastHighlightedStep - 1}
        currentStep={currentStep}
        finishStatus={StepStatus.SUCCESS}
        viewOnly={true}
      >
        {stepTitles.map((title: string) => (
          <StepItem title={title} onFinishedClick={onClick} key={title} />
        ))}
      </Steps>
    </>
  );
};
