import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { DateRange, DateRangePicker, DefinedRange } from 'materialui-daterange-picker';

import { ConditionComponentProps, FilterParameterValuesType } from '../types';
import { useStyles } from '../styles';

const getDefinedRanges = (): DefinedRange[] => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentYearQuarterNumber = DateTime.fromJSDate(date).quarter;

  const currentYearQuarters = [
    {
      startDate: new Date(currentYear, 9),
      endDate: new Date(currentYear, 11, 31),
      label: `${i18next.t('filters.condition.dateRange.label.currentYear')} - ${i18next.t(
        'filters.condition.dateRange.label.q4',
      )}`,
    },
    {
      startDate: new Date(currentYear, 6),
      endDate: new Date(currentYear, 8, 31),
      label: `${i18next.t('filters.condition.dateRange.label.currentYear')} - ${i18next.t(
        'filters.condition.dateRange.label.q3',
      )}`,
    },
    {
      startDate: new Date(currentYear, 3),
      endDate: new Date(currentYear, 5, 30),
      label: `${i18next.t('filters.condition.dateRange.label.currentYear')} - ${i18next.t(
        'filters.condition.dateRange.label.q2',
      )}`,
    },
    {
      startDate: new Date(currentYear, 0),
      endDate: new Date(currentYear, 2, 31),
      label: `${i18next.t('filters.condition.dateRange.label.currentYear')} - ${i18next.t(
        'filters.condition.dateRange.label.q1',
      )}`,
    },
  ];

  return [
    ...currentYearQuarters.slice(4 - currentYearQuarterNumber, 4),
    {
      startDate: new Date(currentYear, 0),
      endDate: date,
      label: i18next.t('filters.condition.dateRange.label.currentYear'),
    },
    {
      startDate: new Date(currentYear - 1, 9),
      endDate: new Date(currentYear - 1, 11, 31),
      label: `${i18next.t('filters.condition.dateRange.label.previousYear')} - ${i18next.t(
        'filters.condition.dateRange.label.q4',
      )}`,
    },
    {
      startDate: new Date(currentYear - 1, 6),
      endDate: new Date(currentYear - 1, 8, 31),
      label: `${i18next.t('filters.condition.dateRange.label.previousYear')} - ${i18next.t(
        'filters.condition.dateRange.label.q3',
      )}`,
    },
    {
      startDate: new Date(currentYear - 1, 3),
      endDate: new Date(currentYear - 1, 5, 30),
      label: `${i18next.t('filters.condition.dateRange.label.previousYear')} - ${i18next.t(
        'filters.condition.dateRange.label.q2',
      )}`,
    },
    {
      startDate: new Date(currentYear - 1, 0),
      endDate: new Date(currentYear - 1, 2, 31),
      label: `${i18next.t('filters.condition.dateRange.label.previousYear')} - ${i18next.t(
        'filters.condition.dateRange.label.q1',
      )}`,
    },
    {
      startDate: new Date(currentYear - 1, 0),
      endDate: new Date(currentYear - 1, 11, 31),
      label: i18next.t('filters.condition.dateRange.label.previousYear'),
    },
    {
      startDate: new Date(currentYear - 3, 0),
      endDate: new Date(currentYear - 1, 11, 31),
      label: i18next.t('filters.condition.dateRange.label.lastThreeYears'),
    },
  ].map(d => {
    return {
      ...d,
      endDate: DateTime.fromJSDate(d.endDate)
        .endOf('day')
        .toJSDate(),
    };
  });
};

// to generate initial data for outside
export const generateDateRangeConditionInitParameterValue = (
  from: Date,
  to: Date,
  showSelectedLabel: boolean,
): FilterParameterValuesType => {
  const defined = getDefinedRanges();
  const gotIt = defined.find(d => {
    return (
      DateTime.fromJSDate(d.startDate).equals(DateTime.fromJSDate(from)) &&
      DateTime.fromJSDate(d.endDate).equals(DateTime.fromJSDate(to))
    );
  });
  return {
    values: [{ value: gotIt ? gotIt : { startDate: from, endDate: to, label: '' }, label: gotIt ? gotIt.label : '' }],
    showSelectedLabel,
  };
};

export const getDateRangeConditionCurrentValueStartDate = (obj: FilterParameterValuesType): Date => {
  return obj.values[0].value.startDate as Date;
};

export const getDateRangeConditionCurrentValueEndDate = (obj: FilterParameterValuesType): Date => {
  return obj.values[0].value.endDate as Date;
};

export function DateRangeCondition({
  parameterName,
  updateFilterParameter,
  currentConditionParameterValues,
  showSelectedLabel,
}: ConditionComponentProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  // initial data from outside
  const [dateRange, setDateRange] = useState<DateRange>(
    currentConditionParameterValues && currentConditionParameterValues.length > 0
      ? (currentConditionParameterValues[0].value as DateRange)
      : {},
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  // for clear all action from outside
  useEffect(() => {
    if (!currentConditionParameterValues) setDateRange({});
    else setDateRange(currentConditionParameterValues[0].value as DateRange);
  }, [currentConditionParameterValues]);

  const generateDateRangeString = (dateRange: DateRange): string => {
    return !dateRange.startDate
      ? ''
      : `${dateRange.startDate?.toLocaleDateString()} - ${dateRange.endDate?.toLocaleDateString()}`;
  };

  const dateRangeString = generateDateRangeString(dateRange);

  const generateParameterValue = (range: DateRange): FilterParameterValuesType => {
    return { values: [{ value: range, label: generateDateRangeString(range) }], showSelectedLabel };
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggle = () => setAnchorEl(null);

  const handleChange = (range: DateRange) => {
    setDateRange(range);
    setAnchorEl(null);
    updateFilterParameter(parameterName, generateParameterValue(range));
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>) => {
    setDateRange({});
    updateFilterParameter(parameterName, null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div className={classes.dateRangeWrapper} onClick={handleOpen}>
        <div className={classNames(classes.dateRangeLabel, dateRangeString ? '' : classes.dateRangeLabelPlaceholder)}>
          {dateRangeString ? dateRangeString : t('filters.condition.dateRange.placeholder')}
        </div>
        {Object.keys(dateRange).length == 0 ? null : (
          <IconButton size="small" disableRipple onClick={handleClear} className={classes.adornmentIconButton}>
            <ClearIcon className={classes.adornmentIcon} />
          </IconButton>
        )}
        <IconButton size="small" disableRipple className={classes.adornmentIconButton}>
          <ScheduleIcon className={classes.adornmentIcon} />
        </IconButton>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DateRangePicker
          open={open}
          toggle={toggle}
          onChange={handleChange}
          definedRanges={getDefinedRanges()}
          initialDateRange={dateRange}
        />
      </Popover>
    </>
  );
}
