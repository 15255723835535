/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal } from 'rsuite';
import classnames from 'classnames';
import { TypeAttributes } from 'rsuite/lib/@types/common';
import { AxiosError } from 'axios';

import { handleRequestFail } from '../request-fail-handler';
import { RsCenterModal } from '../../shared-components/rsuite/RsCenterModal';

const confirmRoot = document.createElement('div');
document.body.appendChild(confirmRoot);

const ConfirmModal = ({
  onResolve,
  onReject,
  message,
  title = 'Are you sure?',
  yesText = 'Yes',
  noText = 'Cancel',
  cancelable = true,
  closeBtn = false,
  size = 'xs',
  loading,
}: any) => {
  const okRef = useRef<HTMLButtonElement>(null);

  // useEffect(() => {
  //   setTimeout(() => {
  //     okRef.current && (okRef.current as HTMLButtonElement).focus();
  //   }, 0);
  // }, []);

  return (
    <RsCenterModal backdrop="static" show={true} onHide={onReject} size={size}>
      <Modal.Header closeButton={closeBtn}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ whiteSpace: 'pre-line' }}>{message}</Modal.Body>
      <Modal.Footer className={classnames({ 'text-center': !cancelable })}>
        {cancelable ? (
          <Button onClick={onReject} appearance="link">
            {noText}
          </Button>
        ) : null}
        <Button onClick={onResolve} ref={okRef} appearance="primary" loading={loading}>
          {yesText}
        </Button>
      </Modal.Footer>
    </RsCenterModal>
  );
};

export interface ConfirmArguments {
  message: string | JSX.Element;
  title?: string | JSX.Element;
  yesText?: string;
  noText?: string;
  onAccept: () => any;
  onReject?: () => any;
  cancelable?: boolean;
  closeBtn?: boolean;
  addToast?: any;
  size?: TypeAttributes.Size;
}

export const Confirm = ({ onAccept, onReject, addToast, ...options }: ConfirmArguments): Promise<boolean> =>
  new Promise(resolve => {
    const rejectPromise = async () => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      if (onReject) {
        await onReject();
      }
      resolve(false);
    };
    const resolvePromise = async () => {
      ReactDOM.render(
        <ConfirmModal onResolve={resolvePromise} onReject={rejectPromise} {...options} loading={true} />,
        confirmRoot,
      );
      try {
        await onAccept();
      } catch (e) {
        addToast && handleRequestFail(e as AxiosError, addToast);
      }
      ReactDOM.unmountComponentAtNode(confirmRoot);
      resolve(true);
    };

    ReactDOM.render(
      <ConfirmModal onResolve={resolvePromise} onReject={rejectPromise} {...options} loading={false} />,
      confirmRoot,
    );
  });
