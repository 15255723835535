import * as actionTypes from './actionTypes';
import { Classification } from '../../../components/pages/certification/types';

export function classificationsLoaded(classifications: Classification[]) {
  return {
    type: actionTypes.CLASSIFICATIONS_LOADED,
    payload: classifications,
  };
}
export function loadClassifications() {
  return {
    type: actionTypes.LOAD_CLASSIFICATIONS,
  };
}
