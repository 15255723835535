import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Dropdown, Icon, IconButton, Popover, Whisper } from 'rsuite';

import { getSingleCertification, publishCertificationByVersionId } from '../../../api/certification';
import { archiveCertification } from '../../../api/certification/archive-certification';
import { CertificationType } from './types';
import { ErrorCard } from '../../../shared-components';
import { Confirm } from '../../../helpers/confirmationPopup/Confirm';
import { useTranslation } from 'react-i18next';
import { confirmAction, getStoredSummaryUrl, jsonCopy, closeWindow } from '../../../helpers';
import {
  AuditingInfo,
  CapabilitiesSection,
  ConformanceTestingSection,
  InteroperabilityTestingSection,
  PrerequisiteSection,
  UmbrellaSection,
  VersionSection,
} from './partials';
import { PageTemplate } from '../../partials';
import { showCloneCertificationModal } from '../../../redux/modules/modals';
import { certificationStatuses, CertificationStatuses } from '../../../helpers/constants/certification-statuses';
import { handleRequestSuccess } from '../../../helpers/handle-request-success';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import {
  CertificationDefinition,
  CertificationState,
  editCertificationLoaded,
  resetCertification,
  setLoading,
  setValidationErrors,
} from '../../../redux/modules/certification';
import { RootReducer } from '../../../redux/rootReducer';
import { getRefsForSections, scrollToErrors } from './partials/helpers';
import { usePageChangeDetection } from '../../../helpers/hooks/useChangeDetection';
import { defaultWhisperProps } from '../../../helpers/constants';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import { BackToListButton } from '../../../shared-components/button/BackToListButton';
import { CloseButton } from '../../../shared-components/button/CloseButton';

const calculateHashValue = (values: { [key: string]: boolean }) => JSON.stringify(jsonCopy(values));

const modifiedSectionsInitialValue = {
  general: false,
  version: false,
  capabilities: false,
  prerequisites: false,
  interoperability: false,
  conformance: false,
};

export const CertificationDetailsPage = withRouter(({ match, history }: RouteComponentProps<{ id: string }>) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const refs = getRefsForSections();
  const [modifiedSections, setModifiedSections] = useState(modifiedSectionsInitialValue);

  const { isModified, resetChangeDetection } = usePageChangeDetection(calculateHashValue, modifiedSections);

  const [loadingError, setLoadingError] = useState();
  const {
    certification,
    loadingState: { loading, publishing },
  } = useSelector<RootReducer, CertificationState>(state => state.certificationReducer);
  const { '@type': type, versionId, status = CertificationStatuses.DRAFT, id, auditingInformation } = certification;

  useEffect(() => {
    dispatch(resetCertification());
    getSingleCertification(Number(match.params.id))
      .then((response: AxiosResponse<CertificationDefinition>) => {
        dispatch(editCertificationLoaded(response.data));
        setModifiedSections(modifiedSectionsInitialValue);
      })
      .catch((error: AxiosError) => {
        setLoadingError(error);
      });
  }, [match]);

  const updateModifiedSections = (section: string) => (value: boolean) => {
    setModifiedSections(prevState => ({ ...prevState, [section]: value }));
  };

  const onClone = () =>
    dispatch(
      showCloneCertificationModal({
        id: versionId as number,
        type,
      }),
    );

  const onBackToList = () => {
    resetChangeDetection();
    history.push(getStoredSummaryUrl('certification'));
  };

  const confirmationRequired = () => isModified;

  const onPublish = () => {
    dispatch(setLoading({ publishing: true }));
    return publishCertificationByVersionId(versionId as number)
      .then(() => {
        resetChangeDetection();
        dispatch(setLoading({ publishing: false }));
        handleRequestSuccess(t('certifications.notifications.published'), addToast);
        history.push(`/certification?tab=${certificationStatuses.published}`);
      })
      .catch((error: AxiosError) => {
        const { validationErrors, sectionsToEdit } = scrollToErrors(
          type,
          CertificationStatuses.PUBLISHED,
          certification,
          refs,
        );
        dispatch(setValidationErrors(validationErrors, sectionsToEdit));
        handleRequestFail(error, addToast);
      });
  };

  const onArchive = () => {
    return Confirm({
      title: t('common.placeholders.areYouSure'),
      message: t('certifications.retirementWarning', { name: certification.generalInfo.name }),
      onAccept: () =>
        archiveCertification(versionId as number)
          .then(() => {
            handleRequestSuccess(t('certifications.notifications.archived'), addToast);
            history.push(`/certification?tab=${certificationStatuses.thirdRetirementPhase}`);
          })
          .catch((error: AxiosError) => handleRequestFail(error, addToast)),
    });
  };

  return (
    <>
      {loading ? <CircularLoader fullPage content={t('common.placeholders.loadingData')} /> : null}
      {loadingError ? <ErrorCard error={loadingError} /> : null}
      {!loading && !loadingError ? (
        <PageTemplate
          title={t('certifications.viewTitle')}
          className="details-page"
          withBorder
          actionLeft={
            window.opener ? (
              <CloseButton onClick={() => confirmAction(confirmationRequired, closeWindow)} disabled={publishing} />
            ) : (
              <BackToListButton
                className="btn-close"
                onClick={() => confirmAction(confirmationRequired, onBackToList)}
                disabled={publishing}
              />
            )
          }
          footerActionLeft={<></>}
          actionRight={
            <div className="btn-wrapper">
              {status === CertificationStatuses.DRAFT ? (
                <Button
                  appearance="primary"
                  className="publish"
                  onClick={() => confirmAction(confirmationRequired, onPublish, 'common.unsavedDataGenericWarning')}
                  loading={publishing}
                >
                  {t('common.actions.publish')}
                </Button>
              ) : null}
              <Button
                appearance="primary"
                className="duplicate"
                onClick={() => confirmAction(confirmationRequired, onClone, 'common.unsavedDataGenericWarning')}
                disabled={publishing}
              >
                {t('common.actions.duplicate')}
              </Button>
              {status !== CertificationStatuses.DRAFT && status !== CertificationStatuses.THIRD_RETIREMENT_PHASE && (
                <Whisper
                  {...defaultWhisperProps}
                  speaker={
                    <Popover full>
                      <Dropdown.Menu className="action-icons">
                        <Dropdown.Item onClick={onArchive}>
                          <IconButton icon={<Icon icon="ban" />} appearance="link">
                            {t('common.actions.archive')}
                          </IconButton>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Popover>
                  }
                >
                  <IconButton
                    appearance="link"
                    size={'lg'}
                    icon={<Icon icon="more" className="icon pointer" size="2x" />}
                  />
                </Whisper>
              )}
            </div>
          }
        >
          <AuditingInfo data={auditingInformation?.certification} id={id} />
          <UmbrellaSection isView ref={refs.generalInfo} setIsModified={updateModifiedSections('general')} />
          <AuditingInfo data={auditingInformation?.version} id={versionId} withDivider />
          <VersionSection isView ref={refs.version} setIsModified={updateModifiedSections('version')} />
          {type === CertificationType.PRIMARY ? (
            <CapabilitiesSection
              isView
              ref={refs.capabilities}
              setIsModified={updateModifiedSections('capabilities')}
            />
          ) : null}
          <PrerequisiteSection
            isView
            ref={refs.prerequisites}
            setIsModified={updateModifiedSections('prerequisites')}
          />
          <InteroperabilityTestingSection
            ref={refs.interoperabilityTest}
            isView
            setIsModified={updateModifiedSections('interoperability')}
          />
          {type === CertificationType.CAPABILITY ? (
            <ConformanceTestingSection
              isView
              ref={refs.testGroups}
              setIsModified={updateModifiedSections('conformance')}
            />
          ) : null}
        </PageTemplate>
      ) : null}
    </>
  );
});
