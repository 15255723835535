import React, { useRef, useState } from 'react';
import { Button, Modal, Uploader, Form } from 'rsuite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FileType } from 'rsuite/lib/Uploader';
import { IterableObject, UploaderInstance, UploaderServerErrorResponse } from '../../helpers/types';
import { UploadedFile } from '../../shared-components/file-upload/types';
import { messageKeyPrefix } from '../../helpers/constants';
import { getAuthHeader } from '../../helpers/axios-setup';
import { RsCenterModal } from '../../shared-components/rsuite';

const StyledUploader = styled(Uploader)`
  .rs-uploader-trigger {
    text-align: center;
  }

  .rs-uploader-file-item-status {
    display: none;
  }
`;

const ModalBody = styled(Modal.Body)`
  min-height: 200px;
  text-align: center;
`;

interface FileWithError extends FileType {
  errorMessage?: string;
}

interface FileUploadModalProps {
  showPopup: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: (response: any) => void;
  url: string;
  bodyParams?: IterableObject<string>;
  title?: string;
  bodyTitle?: string;
  slot?: React.ReactNode | null;
  onError?: (response: UploaderServerErrorResponse) => Promise<boolean>;
}

export const FileUploadModal = ({
  showPopup,
  onClose,
  onSuccess,
  url,
  bodyParams = {},
  title = 'File Upload',
  bodyTitle = '',
  slot = null,
  onError,
}: FileUploadModalProps) => {
  const uploaderRef = useRef<UploaderInstance>();
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileWithError[]>([]);
  const [loading, setLoading] = useState(false);

  const clearInputValue = () => {
    uploaderRef.current?.cleanInputValue();
    setLoading(false);
  };

  const handleChange = (array: FileType[] = []) => setFiles(array.filter((item, index) => index === array.length - 1));

  const handleClose = () => {
    clearInputValue();
    setFiles([]);
    onClose();

    // @ts-ignore
    uploaderRef.current.getFileList().forEach(file => {
      // @ts-ignore
      if (file.fileKey in uploaderRef.current.xhrs) {
        // @ts-ignore
        uploaderRef.current.xhrs[file.fileKey].abort();
      }
    });
    uploaderRef.current?.cleanInputValue();
  };

  const handleError = async (error: UploaderServerErrorResponse, file: FileType) => {
    const { message, messageKey, messageParameters } = error.response;
    const translatedMessage = t(messageKey.replace(messageKeyPrefix, ''), messageParameters);
    const errorMessage = `${messageKeyPrefix}${translatedMessage}` === messageKey ? message : translatedMessage;

    if (onError) {
      const result = await onError(error);
      if (result) {
        setFiles([]);
        setLoading(false);
        return;
      }
    }
    setFiles([
      {
        ...file,
        fileKey: 'errorFile',
        errorMessage,
      },
    ]);
    setLoading(false);
  };

  const handleSuccess = (data: UploadedFile) => {
    onSuccess(data);
    setFiles([]);
  };

  const upload = () => uploaderRef.current?.start();

  return (
    <RsCenterModal
      size="sm"
      show={showPopup}
      onHide={handleClose}
      onExited={() => setLoading(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        {bodyTitle ? <p className="mb-4">{bodyTitle}</p> : null}
        <Form fluid>
          {slot}
          <StyledUploader
            autoUpload={false}
            multiple={false}
            action={url}
            headers={{ Authorization: getAuthHeader() }}
            onUpload={() => setLoading(true)}
            data={bodyParams}
            onChange={handleChange}
            onSuccess={handleSuccess}
            onError={handleError}
            onRemove={clearInputValue}
            fileList={files}
            ref={uploaderRef}
            renderFileInfo={(file: FileWithError) => (
              <div className="text-left">
                <span>{file.name}</span>
                {file.errorMessage ? <p>{file.errorMessage}</p> : null}
              </div>
            )}
          >
            <Button>{t('lab.modal.chooseFile')}</Button>
          </StyledUploader>
        </Form>
      </ModalBody>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="link" disabled={loading}>
          {t('common.actions.willDoLate')}
        </Button>
        <Button onClick={upload} appearance="primary" disabled={!files} loading={loading}>
          {t('common.actions.upload')}
        </Button>
      </Modal.Footer>
    </RsCenterModal>
  );
};
