import { CapabilitiesResponse, GetCapabilitiesByCC } from '../../../../../../../api/application/get-capabilities-by-CC';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { ReformattedCapabilities } from './CapabilityRow';
import { AppCapability, AppCertification } from '../../../../../../../redux/modules/application-flow';

export const getCapabilitiesTree = (capabilities: CapabilitiesResponse[]): ReformattedCapabilities[] => {
  return getTreeFromFlatData({
    flatData: capabilities.map((item, index) => ({
      ...item,
      id: item.capability.id,
      name: item.capability.name,
      prerequisites: item.capability.prerequisites,
      displayParent: item.displayParent,
      mandatory: item.mandatory,
      index,
      minChildren: item.minChildren,
      maxChildren: item.maxChildren,
    })),
    getKey: node => node.index,
    getParentKey: node => node.displayParent,
    // @ts-ignore
    rootKey: null,
  });
};

export const isCapabilityNeedValidate = (capabilityInfo: ReformattedCapabilities): boolean => {
  return !(capabilityInfo.minChildren === 0 && capabilityInfo.maxChildren === -1);
};

export const isCapabilityValidateError = (
  selectedCapabilities: AppCapability[],
  currentCapability: ReformattedCapabilities,
): boolean => {
  const { minChildren, maxChildren, children = [] } = currentCapability;

  // count how many child node be selected
  if (minChildren !== 0 || maxChildren !== -1) {
    const countOfSelectedChildNode = selectedCapabilities.reduce(
      (previousValue: number, selectedCapability: AppCapability) => {
        if (children.find(capability => capability.id === selectedCapability.id)) return previousValue + 1;
        return previousValue;
      },
      0,
    );
    if (minChildren > 0 && countOfSelectedChildNode < minChildren) return true;
    else if (maxChildren >= 0 && countOfSelectedChildNode > maxChildren) return true;
    else if (minChildren === maxChildren && minChildren > 0 && minChildren !== countOfSelectedChildNode) return true;
  }
  return false;
};

export const getCapabilityValidateErrorIds = (
  certifications: AppCertification[],
  capabilitisByCC: GetCapabilitiesByCC[],
): number[] => {
  const errorIds: number[] = [];

  capabilitisByCC.forEach(certification => {
    let isCertificationError = false;

    const capabilitiesTree = getCapabilitiesTree(certification.capabilities);
    //console.log(capabilitiesTree);

    const validateCapability = (capability: ReformattedCapabilities): boolean => {
      // check if need validate
      if (!isCapabilityNeedValidate(capability)) return false;
      let isError = false;
      // count selected children and validate
      const selectedCapabilities = certifications.find(cert => cert.version.id === certification.id)?.capabilities;
      const checkedCapability = selectedCapabilities?.find(el => el.id === capability.id);
      // if uncheck skip validate
      if (!Boolean(checkedCapability)) return false;
      //console.log('selectedCapabilities', selectedCapabilities);
      isError = isCapabilityValidateError(selectedCapabilities ?? [], capability) || isError;
      //console.log(capability.name, isError);

      // go into children
      if (capability.children && capability.children.length > 0) {
        for (const sub of capability.children) {
          isError = validateCapability(sub) || isError;
        }
      }
      return isError;
    };

    for (const capability of capabilitiesTree) {
      isCertificationError = validateCapability(capability) || isCertificationError;
    }

    if (isCertificationError) {
      errorIds.push((certifications.find(cert => cert.version.id === certification.id) as AppCertification).id);
    }
  });

  return errorIds;
};

export const isSomeCapabilityValidateError = (
  certifications: AppCertification[],
  capabilitisByCC: GetCapabilitiesByCC[],
) => {
  return getCapabilityValidateErrorIds(certifications, capabilitisByCC).length !== 0;
};
