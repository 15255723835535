import axios from '../../helpers/axios-setup';
import { AppFlows, CERTIFY_PRODUCT_COMPANY, CompanyContactInfo } from '../../redux/modules/application-flow';
import { MajorEvents, ProductInfoStep } from './types';

export interface CreateApplicationParams {
  id?: number;
  state?: string;
  '@type': AppFlows;
  companyContactInfo: CompanyContactInfo & {
    applicationOwner: {
      '@type': CERTIFY_PRODUCT_COMPANY;
      owningCompany: null | { id: number; name: string }; // object with ID for ANOTHER_COMPANY
    };
  };
  productInfo: ProductInfoStep;
  majorEvents: MajorEvents;
}

export const createNewApplication = (params: CreateApplicationParams) => axios.post('/applications/builder', params);
