import axios from '../../helpers/axios-setup';
import { AppCertPrereqs, BasicAppCertification } from '../../redux/modules/application-flow';

export interface CapabilitiesResponse {
  capability: BasicAppCertification & { prerequisites: AppCertPrereqs[] };
  displayParent: number | null;
  mandatory: boolean;
  minChildren?: number;
  maxChildren?: number;
}

export interface GetCapabilitiesByCC extends BasicAppCertification {
  id: number;
  prerequisites: AppCertPrereqs[];
  capabilities: CapabilitiesResponse[];
}

export const getCapabilitiesByAppId = (appId: number) => {
  return axios.get(`/applications/builder/details/capabilities/${appId}`);
};
