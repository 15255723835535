import React, { useEffect, useState } from 'react';
import { Button, Modal, Radio, RadioGroup } from 'rsuite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GenericNodeType } from '../../../../../shared-components/condition-builder/types';
import { ConditionBuilder } from '../../../../../shared-components/condition-builder/ConditionBuilder';
import { RunCondition, RunConditionTypes } from '../../types';
import { createGroup, reindexTree } from '../../../../../shared-components/condition-builder/helpers';
import { jsonCopy, prepareYupModel } from '../../../../../helpers';
import { ConditionLabel } from '../../../../../shared-components/condition-builder/ConditionLabel';
import { runConditionTreeSchema } from '../../validation-schemes';
import { ErrorObject } from '../../../../../helpers/types';
import { RsCenterModal } from '../../../../../shared-components/rsuite';

const RadioWrapper = styled.div`
  font-size: 1.15em;
  margin-top: 15px;
`;

const ModalBody = styled(Modal.Body)`
  margin-top: 0;
  padding-bottom: 0;
`;

const ModalHeader = styled(Modal.Header)`
  padding-bottom: 20px;
`;

interface RunConditionModalProps {
  show: boolean;
  onApply: (value: RunCondition) => void;
  onCancel: () => void;
  value: RunCondition;
}

const getInitData = (condition: RunCondition) =>
  condition['@type'] === RunConditionTypes.CONDITIONAL && condition.raw
    ? reindexTree(jsonCopy(condition.raw))
    : reindexTree(createGroup() as GenericNodeType);

export const RunConditionModal = ({ show, onApply, onCancel, value: condition }: RunConditionModalProps) => {
  const { t } = useTranslation();
  const [type, setType] = useState<RunConditionTypes>(condition['@type']); // const rootData = reindexTree(jsonCopy(rootBuilderData));
  const [value, setValue] = useState<GenericNodeType>(getInitData(condition));
  const [errors, setErrors] = useState<ErrorObject>({});

  const validationSchema = prepareYupModel(runConditionTreeSchema);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      const errors = validationSchema.checkFormatted(value) ?? {};
      setErrors(errors as ErrorObject);
    }
  }, [value]);

  const handleApply = () => {
    if (type === RunConditionTypes.CONDITIONAL) {
      const errors = validationSchema.checkFormatted(value);
      if (errors) {
        setErrors(errors as ErrorObject);
        return;
      }
    }

    onApply({ '@type': type, raw: type === RunConditionTypes.CONDITIONAL ? value : undefined });
  };

  return (
    <RsCenterModal backdrop overflow full={type === RunConditionTypes.CONDITIONAL} show={show} onHide={onCancel}>
      <ModalHeader>
        <Modal.Title>{t('certifications.runCondition.modal.title')}</Modal.Title>
        <RadioWrapper>
          <span>{t('certifications.runCondition.modal.chooseConditionType')}:</span>
          <RadioGroup inline value={type} onChange={setType}>
            <Radio value={RunConditionTypes.ALWAYS_ON}>{t('certifications.testGroup.runConditionAlways')}</Radio>
            <Radio value={RunConditionTypes.CONDITIONAL}>{t('certifications.testGroup.runConditionConditional')}</Radio>
          </RadioGroup>
          {type === RunConditionTypes.CONDITIONAL && <ConditionLabel value={value} />}
        </RadioWrapper>
      </ModalHeader>
      <ModalBody>
        {type === RunConditionTypes.CONDITIONAL && (
          <ConditionBuilder value={value} onChange={setValue} errors={errors} />
        )}
      </ModalBody>
      <Modal.Footer>
        <Button appearance="link" onClick={onCancel}>
          {t('common.navigation.cancel')}
        </Button>
        <Button appearance="primary" onClick={handleApply}>
          {t('common.actions.apply')}
        </Button>
      </Modal.Footer>
    </RsCenterModal>
  );
};
