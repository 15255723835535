import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { Icon } from 'rsuite';
import Button from '@material-ui/core/Button';
import { withStyles, Theme } from '@material-ui/core/styles';

const StyledButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    paddingLeft: '1rem',
    paddingRight: 0,
    '&:hover': {
      color: '#976083',
    },
  },
}))(Button);

export const RemoveMaximumButton = ({ children, onClick, className }: PropsWithChildren<HTMLAttributes<{}>>) => (
  <StyledButton className={className} onClick={onClick} startIcon={<Icon icon="trash" />} color="secondary">
    {children}
  </StyledButton>
);
