import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

interface EnvironmentSettingType {
  name: string;
  urlPrefix: string;
  backgroundColor: string;
}

const EnvironmentSetting: { [key: string]: EnvironmentSettingType } = {
  DEVINT: { name: 'DevInt', urlPrefix: 'devint-web', backgroundColor: '#37444F' },
  STAGING: { name: 'Staging', urlPrefix: 'staging-web', backgroundColor: '#26716D' },
  HOTFIX: { name: 'HotFix', urlPrefix: 'hotfix-web', backgroundColor: '#617485' },
  FEATURE: { name: 'Feature', urlPrefix: 'feature-web-ecs', backgroundColor: '#BE5627' },
  TOOL: { name: 'Tool', urlPrefix: 'tool-www', backgroundColor: '#F49E4A' },
  FEATURE_TOOL: { name: 'Feature-Tool', urlPrefix: 'feature-tool-www', backgroundColor: '#CE2F62' },
  PRODUCTION: { name: 'Prodcution', urlPrefix: 'www', backgroundColor: '' },
  LOCALHOST: { name: 'localhost', urlPrefix: 'localhost', backgroundColor: 'green' },
};

const getEnvironmentType = () => {
  const hostname = window.location.hostname.split('.')[0];
  Object.keys(EnvironmentSetting);

  for (const key of Object.keys(EnvironmentSetting)) {
    if (hostname && hostname === EnvironmentSetting[key].urlPrefix) return key;
  }
};

const getColorByTestEnvironment = () => {
  const type = getEnvironmentType();
  return type && EnvironmentSetting[type].backgroundColor;
};

const getNameByTestEnvironment = () => {
  const type = getEnvironmentType();
  return type && EnvironmentSetting[type].name;
};

export const useTestEnvironment = (): boolean => {
  const type = getEnvironmentType();
  return type !== undefined && type !== 'PRODUCTION';
};

const StyledBox = withStyles(() => ({
  root: {
    height: 20,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))(Box);

const StyledText = withStyles(() => ({
  root: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white',
  },
}))(Box);

export const EnvironmentIdentifier = () => {
  const envName = useMemo(() => getNameByTestEnvironment(), []);
  const envColor = useMemo(() => getColorByTestEnvironment(), []);

  return (
    <StyledBox
      style={{
        backgroundColor: envColor,
      }}
    >
      <StyledText component="span">Environment: {envName}</StyledText>
    </StyledBox>
  );
};
