import React, { ChangeEvent, SetStateAction, useEffect, useState } from 'react';
import { buildDownloadUrl } from '../../../../helpers/build-download-url';
import { BasicProductInfo, PrimaryCategory, SingleProductRecord } from '../types';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Icon, Popover, Whisper } from 'rsuite';
import { RowWithMargin } from '../../application/partials';
import { DetailsItem } from '../../../partials';
import { Categories, CategoryItem } from '../../application/types';
import { ImagePreview } from '../../../../shared-components/helper-components/ImagePreview';
import { BtnLoadingState } from '../../../../helpers/constants/loading-states';
import { LabeledInput, LabeledTextArea } from '../../../../shared-components/labeled-inputs';
import { ProductCategories } from '../../../../api/application/types';
import { UploadedFile } from '../../../../shared-components/file-upload/types';
import dot from 'dot-object';
import { confirmAction, jsonCopy, mergeObjects, prepareYupModel } from '../../../../helpers';
import { updateProductInfo } from '../../../../api/product';
import { AxiosError, AxiosResponse } from 'axios';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { useToasts } from 'react-toast-notifications';
import { RootReducer } from '../../../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReformattedCategories,
  reformatFromIdToNumber,
  ReformattedCategories,
} from '../../../../helpers/categories';
import { loadCategories } from '../../../../redux/modules/categories';
import { RsControlLabel } from '../../../../shared-components/rsuite';
import { FileUploader } from '../../../../shared-components';
import { ErrorObject, ValidationErrorResponse } from '../../../../helpers/types';
import { formatErrors } from '../../../../helpers/request';
import { User } from '../../../../redux/modules/user/types';
import { EditMode, memberRoles } from '../../../../helpers/constants';
import { productInfoScheme } from '../validation-schemes';
import classNames from 'classnames';
import styled from 'styled-components';
import { WrapWithPublicIcon } from '../../../../shared-components/helper-components/WrapWithPublicIcon';
import { AsteriskState } from '../ProductDetailsPage';
import {
  supportedImageTypes,
  UploadFileType,
  validateAppImage,
} from '../../../../shared-components/uploader/FileUploader';
import { IsModifiedProps, numberSort, useChangeDetection } from '../../../../helpers/hooks/useChangeDetection';
import { isSolutionProvider } from '../../staff/partials/helpers';
import { AppFlows } from '../../../../redux/modules/application-flow';
import {
  AutocompleteField,
  AutocompleteMultipleField,
} from '../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckBoxIcon from '@material-ui/icons/CheckBoxSharp';

const StyledIcon = styled(Icon)`
  font-size: 1.2rem;
`;

const calculateHashValue = (info: BasicProductInfo) => {
  const values = jsonCopy(info);
  return JSON.stringify({
    ...values,
    primaryCategory: values.primaryCategory?.id,
    additionalCategories: values.additionalCategories.map(item => item.id).sort(numberSort),
  });
};

type ProductInfoProps = {
  productItem: SingleProductRecord;
  updateProduct: (x: SetStateAction<SingleProductRecord | null>) => void;
  setAsteriskLegend: (x: SetStateAction<AsteriskState>) => void;
} & Partial<IsModifiedProps>;

export const EditableProductInfo = ({
  productItem,
  updateProduct,
  setAsteriskLegend,
  setIsModified: setIsSectionModified,
}: ProductInfoProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const { categories, user } = useSelector<RootReducer, { categories: Categories[]; user: User }>(state => ({
    categories: state.categoriesReducer.categories,
    user: state.userReducer.user as User,
  }));
  const reformattedCategories = getReformattedCategories(categories);
  const productInfoData: BasicProductInfo = {
    name: productItem.name,
    modelNumber: productItem.modelNumber,
    description: productItem.description,
    primaryCategory: productItem.primaryCategory as PrimaryCategory,
    additionalCategories: productItem.additionalCategories as PrimaryCategory[],
    url: productItem.url,
    image: productItem.image,
  };
  const [state, setState] = useState(productInfoData);
  const [errors, setErrors] = useState<ErrorObject>({});
  const [btnLoading, setBtnLoading] = useState(BtnLoadingState.NONE);
  const [mode, setMode] = useState(EditMode.READONLY);
  const [appImageLoading, setAppImageLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const setIsModifiedState = (value: boolean) => {
    setIsModified(value);
    setIsSectionModified && setIsSectionModified(value);
  };

  const setChangeDetectionInitialValue = useChangeDetection(setIsModifiedState, calculateHashValue, state);

  const isEditMode = mode === EditMode.EDIT;
  const isMember = memberRoles.includes(user.authority);
  const showQualifiedSolutionFields =
    isSolutionProvider(productItem.variant) && productItem.variant.application.type !== AppFlows.DERIVATIVE;

  const handleCancel = () => {
    setMode(EditMode.READONLY);
    setState(productInfoData);
    setErrors({});
    setIsModifiedState(false);
    setAsteriskLegend(prevState => {
      const newState = jsonCopy(prevState);
      newState.productInfo = false;
      return newState;
    });
  };

  const onCancel = () => confirmAction(() => isModified, handleCancel);

  const editModeOn = () => {
    setMode(EditMode.EDIT);
    setAsteriskLegend(prevState => {
      const newState = jsonCopy(prevState);
      newState.productInfo = true;
      return newState;
    });
  };

  const handleSave = () => {
    const validationErrors = prepareYupModel<BasicProductInfo>(productInfoScheme).checkFormatted(state);
    if (validationErrors) {
      setErrors(prevState => mergeObjects(prevState, validationErrors));
      return;
    }
    setBtnLoading(BtnLoadingState.SAVE);
    return updateProductInfo(productItem.id, state)
      .then((response: AxiosResponse<SingleProductRecord>) => {
        setBtnLoading(BtnLoadingState.NONE);
        setMode(EditMode.READONLY);
        updateProduct({ ...productItem, ...response.data });
        setIsModifiedState(false);
        setChangeDetectionInitialValue(state);
        addToast(t('products.productInfoUpdated'), {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      })
      .catch((error: AxiosError<ValidationErrorResponse>) => {
        setBtnLoading(BtnLoadingState.NONE);
        if (error.response?.data.errors) {
          const validationErrors = formatErrors(error.response?.data.errors);
          setErrors(prevState => mergeObjects(prevState, validationErrors));
        }
        handleRequestFail(error, addToast);
      });
  };

  const handleChange = (
    value: string | ProductCategories | ProductCategories[] | UploadedFile | UploadedFile[] | null,
    name: string,
  ) => {
    setState(prevState => {
      const newState = jsonCopy(prevState);
      dot.set(name, value, newState);
      const error = prepareYupModel(productInfoScheme).checkForFieldFormatted(name, newState);
      setErrors(prevErrors => ({ ...prevErrors, ...error }));
      return newState;
    });
  };

  useEffect(() => {
    if (!categories.length) {
      dispatch(loadCategories());
    }
  }, [categories]);

  return (
    <>
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <h4 className="mt-2" style={{ lineHeight: '1.3rem' }}>
            {t('applications.steps.1')}
            {isMember ? (
              <>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Popover style={{ maxWidth: '250px' }}>{t('products.editInformation')}</Popover>}
                >
                  <StyledIcon className="pointer text-muted" icon="question2" style={{ margin: '0 0.5rem' }} />
                </Whisper>
                {mode === EditMode.READONLY ? (
                  <StyledIcon className="pointer" icon="edit" onClick={editModeOn} />
                ) : null}
              </>
            ) : null}
          </h4>
        </Col>
        <Col xs={12}>
          {isEditMode ? (
            <div className="btn-wrapper text-right">
              <Button className="cancel" onClick={onCancel} disabled={appImageLoading}>
                {t('common.navigation.cancel')}
              </Button>
              <Button
                appearance="primary"
                loading={btnLoading === BtnLoadingState.SAVE}
                disabled={btnLoading !== BtnLoadingState.NONE || appImageLoading}
                onClick={handleSave}
                className="save"
              >
                {t('common.navigation.save')}
              </Button>
            </div>
          ) : null}
        </Col>
      </RowWithMargin>
      {showQualifiedSolutionFields && (
        <RowWithMargin gutter={20}>
          <Col xs={12}>
            <DetailsItem
              label={t('applications.info.isQualifiedSolution')}
              value={(productItem.qualifiedSolution ? t('common.options.yes') : t('common.options.no')) as string}
            />
          </Col>
          <Col xs={12}>
            <DetailsItem
              label={t('applications.info.isWiFiCertified')}
              value={(productItem.certified ? t('common.options.yes') : t('common.options.no')) as string}
            />
          </Col>
        </RowWithMargin>
      )}
      <RowWithMargin gutter={20} className={classNames({ 'mb-0': isEditMode })}>
        <Col xs={12}>
          <DetailsItem label={t('applications.info.cid')} value={productItem.cid} />
        </Col>
        <Col xs={12}>
          {isEditMode ? (
            <LabeledInput
              noMargin
              label={<WrapWithPublicIcon content={t('applications.info.name')} />}
              name="name"
              placeholder={t('applications.info.name')}
              required
              errors={errors}
              onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => handleChange(value, event.target.name)}
              defaultValue={state.name}
            />
          ) : (
            <DetailsItem
              label={
                isMember ? <WrapWithPublicIcon content={t('applications.info.name')} /> : t('applications.info.name')
              }
              value={productItem.name}
            />
          )}
        </Col>
      </RowWithMargin>
      <RowWithMargin gutter={20} className={classNames({ 'mb-0': isEditMode })}>
        <Col xs={12}>
          {isEditMode ? (
            <LabeledInput
              noMargin
              label={<WrapWithPublicIcon content={t('applications.info.modelNumber')} />}
              name="modelNumber"
              placeholder={t('applications.info.modelNumber')}
              errors={errors}
              required
              onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => handleChange(value, event.target.name)}
              defaultValue={state.modelNumber}
            />
          ) : (
            <DetailsItem
              label={
                isMember ? (
                  <WrapWithPublicIcon content={t('applications.info.modelNumber')} />
                ) : (
                  t('applications.info.modelNumber')
                )
              }
              value={productItem.modelNumber}
            />
          )}
        </Col>
        <Col xs={12}>
          <DetailsItem label={t('applications.summary.company')} value={productItem.company.name} />
        </Col>
      </RowWithMargin>
      <RowWithMargin gutter={20} className={classNames({ 'mb-0': isEditMode })}>
        <Col xs={12}>
          {isEditMode ? (
            <>
              <label className="rs-control-label">
                <span className="text-danger">* </span>
                <WrapWithPublicIcon content={t('applications.info.primaryCategory')} />
              </label>
              <AutocompleteField
                value={state?.primaryCategory?.id}
                onChange={id => handleChange({ id }, 'primaryCategory')}
                onClean={() => handleChange(null, 'primaryCategory')}
                getOptionDisabled={option => state.additionalCategories.some(category => category.id === option.value)}
                data={reformattedCategories}
                labelKey={'label'}
                name={'primaryCategory'}
                placeholder={'Start typing...'}
                valueKey={'value'}
                groupBy={'role'}
                errors={errors}
              />
            </>
          ) : (
            <DetailsItem
              label={
                isMember ? (
                  <WrapWithPublicIcon content={t('applications.info.primaryCategory')} />
                ) : (
                  t('applications.info.primaryCategory')
                )
              }
              value={(productItem.primaryCategory as CategoryItem)?.name}
            />
          )}
        </Col>
        <Col xs={12}>
          {isEditMode ? (
            <>
              <label className="rs-control-label">
                <WrapWithPublicIcon content={t('applications.info.additionalCategory')} />
              </label>
              <AutocompleteMultipleField
                value={reformatFromIdToNumber(state.additionalCategories)}
                onChange={(values: ReformattedCategories[]) =>
                  handleChange(
                    values.map(category => ({ id: category.value })),
                    'additionalCategories',
                  )
                }
                data={reformattedCategories}
                labelKey={'label'}
                errors={errors}
                name={'additionalCategories'}
                placeholder={'Start typing...'}
                valueKey={'value'}
                groupBy={'role'}
                getOptionDisabled={option => option.value === state.primaryCategory?.id}
                disableCloseOnSelect
                renderMenuItem={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={selected}
                      style={{ padding: 0, paddingRight: 10 }}
                    />
                    {option.label}
                  </>
                )}
              />
            </>
          ) : (
            <DetailsItem
              label={
                isMember ? (
                  <WrapWithPublicIcon content={t('applications.info.additionalCategory')} />
                ) : (
                  t('applications.info.additionalCategory')
                )
              }
              value={(productItem.additionalCategories as CategoryItem[]).map(item => item.name).join(', ')}
            />
          )}
        </Col>
      </RowWithMargin>
      {isEditMode ? (
        <>
          <LabeledInput
            noMargin
            label={<WrapWithPublicIcon content={t('applications.info.url')} />}
            name="url"
            placeholder={t('applications.info.url')}
            errors={errors}
            onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => handleChange(value, event.target.name)}
            defaultValue={state.url || ''}
          />
          <LabeledTextArea
            noMargin
            rows={6}
            onChange={(value: string, event: ChangeEvent<HTMLTextAreaElement>) =>
              handleChange(value, event.target.name)
            }
            defaultValue={state.description}
            label={<WrapWithPublicIcon content={t('applications.info.description')} />}
            name="description"
            placeholder={t('applications.info.description')}
            errors={errors}
          />
        </>
      ) : (
        <>
          <DetailsItem
            className="mb-1"
            label={isMember ? <WrapWithPublicIcon content={t('applications.info.url')} /> : t('applications.info.url')}
          >
            {productItem.url ? (
              <a href={productItem.url} target="_blank" rel="noopener noreferrer">
                {productItem.url}
              </a>
            ) : null}
          </DetailsItem>
          <DetailsItem
            className="mb-1"
            label={
              isMember ? (
                <WrapWithPublicIcon content={t('applications.info.description')} />
              ) : (
                t('applications.info.description')
              )
            }
            value={state.description}
          />
        </>
      )}
      <RowWithMargin gutter={20} className={classNames({ 'mb-0': isEditMode })}>
        <Col xs={12}>
          {isEditMode ? (
            <FormGroup style={{ marginBottom: 0 }}>
              <RsControlLabel>
                <WrapWithPublicIcon content={t('applications.info.image')} />
              </RsControlLabel>
              <FileUploader
                multiple={false}
                fileType={UploadFileType.APP_IMAGE}
                onChange={(files: UploadedFile[]) => handleChange(files && files.length ? files[0] : null, 'image')}
                onLoadingChange={setAppImageLoading}
                maxNumberOfFiles={1}
                validateFile={validateAppImage}
                accept={supportedImageTypes}
                files={state.image !== null && state.image ? [state.image] : []}
              />
              <div>{state.image ? <ImagePreview src={buildDownloadUrl(state.image)} alt="Product Image" /> : null}</div>
            </FormGroup>
          ) : (
            <DetailsItem
              label={
                isMember ? <WrapWithPublicIcon content={t('applications.info.image')} /> : t('applications.info.image')
              }
            >
              {productItem.image ? (
                <div style={{ display: 'flex', alignItems: 'top', wordBreak: 'break-all' }}>
                  <ImagePreview src={buildDownloadUrl(productItem.image)} className="mr-1 ml-0" />
                </div>
              ) : null}
            </DetailsItem>
          )}
        </Col>
      </RowWithMargin>
    </>
  );
};
