import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row } from 'rsuite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { jsonCopy } from '../../../../helpers';
import { SortingOptions, SortType } from '../../test-case/types';
import { sortByProperty } from '../../../../helpers/sort-by-property';
import { AppCapability, AppCertification, AppCertVersion } from '../../../../redux/modules/application-flow';
import { DataTableColumn } from '../../../../shared-components/table/DataTableColumn';
import DataTable, { DataTableChildType } from '../../../../shared-components/table/DataTable';
import { ChangeSortingAction, ExpandAction } from '../../../../shared-components/table/types';
// @ts-ignore
import _var from '../../../../styles/_variables.scss';
import { WarningTooltip } from '../../../../shared-components/tooltip/WarningTooltip';
import { getRetirementStatus } from '../../../../helpers/constants';
import SectionWithCollapse from '../../../partials/SectionWithCollapse';
import { LRLabInfo } from '../../atl-testing/types';
import { QualifiedToTest } from '../../atl-testing/partials';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Whisper, Popover } from 'rsuite';
import { ECNUrl } from '../../../../config';

const useStyles = makeStyles({
  highlighted: {
    backgroundColor: _var.igoPlumDarken,
    '&.MuiTableRow-hover:hover': {
      backgroundColor: `${_var.igoPlumDarken} !important`,
    },
  },
  conformanceHolder: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 15px',
  },
  subHeader: {
    fontSize: 14,
    color: '#617485',
    padding: '0 5px',
  },
  headerHolder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 6px',
    width: 250,
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  testRequested: { display: 'flex', alignItems: 'center', width: 110, padding: '0 10px' },
  markerTransparent: { opacity: '50%' },
  certificationNote: { fontSize: 13, paddingTop: 20, paddingBottom: 8 },
  labName: { textAlign: 'center' },
});

const VersionWrapper = styled.span<{ highlighted?: boolean }>`
  color: ${_var.textLightGray};
  background-color: ${props => (props.highlighted ? _var.igoPlumDarken : 'transparent')};
  border-radius: 4px;
  padding: 2px 10px;
  display: inline-block;
`;

const IconWrapper = styled.div`
  height: 24px;
`;

interface MergedAppCertification extends AppCertification {
  differentVersions?: boolean;
  differentCapabilities?: boolean;
  capabilities: (AppCapability & { differentVersions?: boolean })[];
}

interface CapabilityRow {
  id: number;
  name: string;
  roleName: string;
  version: AppCertVersion;
  hidden?: boolean;
  _parent: CertificationRow | null;
}

export interface CertificationRow extends CapabilityRow {
  differentVersions?: boolean;
  differentCapabilities?: boolean;
  conformanceTestRequested?: boolean;
  interoperabilityTestRequested?: boolean;
  conformanceTestRequestedByOtherLab?: boolean;
  interoperabilityTestRequestedByOtherLab?: boolean;
  children: CertificationRow[];
}

// check if capabilities in both arrays are exactly the same or if some are missing
// or different in either left or right side
const areSameCapabilities = (left: AppCapability[], right: AppCapability[]): boolean =>
  left.length === right.length &&
  left
    .filter(c => !right.some(x => x.version?.id === c.version?.id))
    .concat(right.filter(c => !left.some(x => x.version?.id === c.version?.id))).length === 0;

export const mergeCertifications = (
  list1: AppCertification[],
  list2: AppCertification[],
  swapVersion = false, // a way to keep correct sorting and version
): MergedAppCertification[] => {
  const merged = jsonCopy(list1) as MergedAppCertification[];
  for (const certification of list2) {
    const existing = merged.find(v => v.id === certification.id);
    // if certification already exists then merge lists of capabilities
    if (existing) {
      let hasDifferentCapabilityVersions = false;
      for (const capability of certification.capabilities) {
        const existingCapability = existing.capabilities.find(c => c.id === capability.id);
        if (!existingCapability) {
          existing.capabilities.push(capability);
          hasDifferentCapabilityVersions = true;
        } else {
          existingCapability.differentVersions = capability.version?.id !== existingCapability.version?.id;
          if (existingCapability.differentVersions) {
            hasDifferentCapabilityVersions = true;
            if (swapVersion) {
              existingCapability.version = capability.version;
            }
          }
        }
      }

      existing.differentVersions = existing.version.id !== certification.version.id;
      existing.differentCapabilities =
        hasDifferentCapabilityVersions || !areSameCapabilities(certification.capabilities, existing.capabilities);

      if (swapVersion && existing.differentVersions) {
        existing.version = certification.version;
      }
    } else {
      // otherwise add certification to the list
      merged.push(certification);
    }
  }
  return merged;
};

const formatCertifications = (
  merged: MergedAppCertification[],
  visible: AppCertification[],
  sorting: SortingOptions,
): CertificationRow[] =>
  merged
    .map(certification => {
      return {
        id: certification.id,
        name: certification.name,
        roleName: certification.role.name,
        version: certification.version,
        differentVersions: certification.differentVersions,
        differentCapabilities: certification.differentCapabilities,
        hidden: !visible.some(c => c.id == certification.id),
        conformanceTestRequested: certification.conformanceTestRequested,
        interoperabilityTestRequested: certification.interoperabilityTestRequested,
        conformanceTestRequestedByOtherLab: certification.conformanceTestRequestedByOtherLab,
        interoperabilityTestRequestedByOtherLab: certification.interoperabilityTestRequestedByOtherLab,
        children: certification.capabilities.map(capability => {
          const parent = visible.find(c => c.id == certification.id);
          return {
            id: capability.id,
            name: capability.name,
            version: capability.version,
            differentVersions: capability.differentVersions,
            hidden: !parent || !parent.capabilities.some(c => c.id == capability.id),
            roleName: certification.role.name,
          };
        }),
      } as CertificationRow;
    })
    .sort((a, b) => sortByProperty(a, b, sorting, false, { sortColumn: 'name', sortType: 'asc' }));

const getRowOptions = (row: CertificationRow) => ({
  hidden: row.hidden,
});

const CertDetailsTable = ({
  data,
  sortable = false,
  hideHeader = false,
  onSortColumn,
  sorting,
  onExpandChange,
  expandedRows,
  labInfo,
}: {
  data: CertificationRow[];
  sortable?: boolean;
  hideHeader?: boolean;
  onSortColumn: ChangeSortingAction;
  sorting: SortingOptions;
  onExpandChange: ExpandAction;
  expandedRows: string[];
  labInfo?: LRLabInfo;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [cellFontSize, setCellFontSize] = useState(14);
  const [columnWidths, setColumnWidths] = useState([230, 210, 44, 100]);

  const renderOtherLabs = useMemo(
    () => data?.some(row => row.conformanceTestRequestedByOtherLab || row.interoperabilityTestRequestedByOtherLab),
    [data],
  );

  const renderHeader = (name: string) => (
    <div className={classes.headerHolder}>
      <div className={classes.labName}>{name}</div>
      <div className={classes.conformanceHolder}>
        <div className={classes.subHeader}>{t('applications.labs.conformance')}</div>
        <div className={classes.subHeader}>{t('applications.labs.interoperability')}</div>
      </div>
    </div>
  );

  useEffect(() => {
    const callback = () => {
      setCellFontSize(window.outerWidth <= 1440 ? 12 : window.outerWidth <= 1680 ? 13 : 14);
      const widthFactor =
        window.outerWidth <= 1440 ? 0.75 : window.outerWidth <= 1560 ? 0.85 : window.outerWidth <= 1700 ? 0.9 : 1;
      setColumnWidths([
        225 * (window.outerWidth / 1920) * widthFactor,
        205 * (window.outerWidth / 1920) * widthFactor,
        44,
        110,
      ]);
    };
    callback();
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, []);

  return (
    <DataTable<CertificationRow>
      expandable
      data={data}
      minWidth={500}
      onExpand={onExpandChange}
      expandedRows={expandedRows}
      sorting={sortable ? sorting : undefined}
      getRowOptions={getRowOptions}
      getRowClassName={(row, nested) =>
        row.differentCapabilities || (nested && row.differentVersions) ? classes.highlighted : ''
      }
      onChangeSorting={sortable ? onSortColumn : undefined}
    >
      <DataTableColumn
        width={labInfo ? 150 : columnWidths[0]}
        field="name"
        label={<span style={{ fontSize: '1rem' }}>{hideHeader ? <>&nbsp;</> : t('applications.steps.3')}</span>}
        sortable={sortable}
        style={{ fontSize: cellFontSize }}
        fixed={labInfo ? false : true}
      >
        {(row: CertificationRow) => row.name}
      </DataTableColumn>
      <DataTableColumn
        width={labInfo ? 130 : columnWidths[1]}
        field="version"
        label={<>&nbsp;</>}
        style={{ fontSize: cellFontSize }}
        fixed={labInfo ? false : true}
      >
        {(row: CertificationRow, nested) =>
          !nested && row.differentVersions ? (
            <VersionWrapper highlighted={true}>{row.version?.name}</VersionWrapper>
          ) : (
            row.version?.name
          )
        }
      </DataTableColumn>
      {labInfo ? (
        <DataTableColumn
          width={130}
          field="versionECN"
          label={
            <span style={{ fontSize: '1rem' }}>
              {t('certifications.versionECN')}
              <a href={ECNUrl} rel="noreferrer" target="_blank">
                <OpenInNewIcon
                  style={{ color: _var.primary, fontSize: '17px', marginLeft: '5px', verticalAlign: 'text-top' }}
                />
              </a>
            </span>
          }
          style={{ fontSize: cellFontSize }}
          fixed={false}
        >
          {(row: CertificationRow) => {
            const hasNote = row.version.note !== '';

            return hasNote ? (
              <Whisper
                placement="topStart"
                trigger="hover"
                speaker={
                  <Popover style={{ maxWidth: '550px' }}>
                    <span className="text-muted">{'Notes'}</span>
                    <div className="note-body" style={{ fontWeight: 'bold' }}>
                      {row.version.note}
                    </div>
                  </Popover>
                }
              >
                <div style={{ fontWeight: 'bold' }}>{row.version?.ecn}</div>
              </Whisper>
            ) : (
              <div>{row.version.ecn}</div>
            );
          }}
        </DataTableColumn>
      ) : (
        ((null as unknown) as DataTableChildType<CertificationRow>)
      )}

      <DataTableColumn
        width={labInfo ? 10 : columnWidths[2]}
        field="retire"
        label={<>&nbsp;</>}
        style={{ textAlign: 'right' }}
      >
        {(row: CertificationRow) => (
          <IconWrapper>
            {row.version?.status && getRetirementStatus(row.version?.status) ? (
              <WarningTooltip variant="rounded" status={row.version?.status} onHoverTooltipTextWarning />
            ) : null}
          </IconWrapper>
        )}
      </DataTableColumn>
      <DataTableColumn
        width={labInfo ? 80 : columnWidths[3]}
        field="roleName"
        sortable={sortable}
        label={<span style={{ fontSize: '1rem' }}>{hideHeader ? <>&nbsp;</> : t('certifications.role')}</span>}
        style={{ fontSize: cellFontSize }}
        sortWrap={false}
        fixed={labInfo ? false : true}
      >
        {(row: CertificationRow, nested) => (!nested ? row.roleName : null)}
      </DataTableColumn>
      {labInfo ? (
        <DataTableColumn
          label={
            <div className={classes.wrapper}>
              {renderHeader(labInfo?.name)}
              {renderOtherLabs && renderHeader(t('certifications.otherLabs'))}
            </div>
          }
          field={'conformanceTestRequested'}
        >
          {(row: CertificationRow) => (
            <div className={classes.wrapper}>
              <div className={classes.conformanceHolder}>
                <div className={classes.testRequested}>{row.conformanceTestRequested ? <QualifiedToTest /> : null}</div>
                <div className={classes.testRequested}>
                  {row.interoperabilityTestRequested ? <QualifiedToTest /> : null}
                </div>
              </div>
              {renderOtherLabs && (
                <div className={classes.conformanceHolder}>
                  <div className={classes.testRequested}>
                    {row.conformanceTestRequestedByOtherLab ? (
                      <QualifiedToTest className={classes.markerTransparent} />
                    ) : null}
                  </div>
                  <div className={classes.testRequested}>
                    {row.interoperabilityTestRequestedByOtherLab ? (
                      <QualifiedToTest className={classes.markerTransparent} />
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          )}
        </DataTableColumn>
      ) : (
        ((null as unknown) as DataTableChildType<CertificationRow>)
      )}
    </DataTable>
  );
};

export const CertificationsSections = ({
  certifications,
  compareCertifications,
  useCollapse = false,
  labInfo,
  hideTitle = false,
  showNote = false,
}: {
  certifications: AppCertification[];
  compareCertifications: AppCertification[];
  useCollapse?: boolean;
  labInfo?: LRLabInfo;
  hideTitle?: boolean;
  showNote?: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [sorting, setSorting] = useState<SortingOptions>({
    sortColumn: 'name',
    sortType: 'asc',
  });

  const handleSortChange = (sortColumn: string, sortType: SortType) => {
    setSorting({ sortColumn, sortType });
  };

  const handleExpandChange = (isOpen: boolean, rowKey: string) =>
    setExpandedRows(prevState => (isOpen ? [...prevState, rowKey] : prevState.filter(v => v !== rowKey)));

  const mergedCertifications = useMemo(() => mergeCertifications(certifications, compareCertifications), [
    certifications,
    compareCertifications,
  ]);

  const mergedCompareCertifications = useMemo(() => mergeCertifications(certifications, compareCertifications, true), [
    certifications,
    compareCertifications,
  ]);

  const formattedCerts: CertificationRow[] = useMemo(
    () => formatCertifications(mergedCertifications, certifications, sorting),
    [mergedCertifications, certifications, sorting],
  );

  const formattedCompareCerts: CertificationRow[] = useMemo(
    () => formatCertifications(mergedCompareCertifications, compareCertifications, sorting),
    [mergedCompareCertifications, certifications, sorting],
  );

  const renderCertifications = () => (
    <CertDetailsTable
      sortable
      data={formattedCerts}
      sorting={sorting}
      onSortColumn={handleSortChange}
      onExpandChange={handleExpandChange}
      expandedRows={expandedRows}
      labInfo={labInfo}
    />
  );

  return (
    <SectionWithCollapse
      useCollapse={useCollapse}
      header={hideTitle ? null : t('applications.steps.3')}
      withoutMarginBottom
    >
      {showNote && <div className={classes.certificationNote}>{t('certifications.certificationsNote')}</div>}
      <Row gutter={20}>
        {labInfo ? renderCertifications() : <Col xs={12}>{renderCertifications()}</Col>}
        <Col xs={12}>
          {!!compareCertifications.length && (
            <CertDetailsTable
              hideHeader
              sorting={sorting}
              data={formattedCompareCerts}
              onSortColumn={handleSortChange}
              onExpandChange={handleExpandChange}
              expandedRows={expandedRows}
            />
          )}
        </Col>
      </Row>
    </SectionWithCollapse>
  );
};
