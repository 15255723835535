import styled from 'styled-components';
import { StyledRadioGroup } from '../../labeled-inputs';
// @ts-ignore
import _var from '../../../styles/_variables.scss';

const spacing = '10px';

export const StyledConditionBuilderWrapper = styled.div`
  .run-element {
    flex: 1;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: -70px;
      width: 70px;
      height: calc(50% + 5px);
      border-color: ${_var.textGray};
      border-style: dashed;
      border-width: 0 0 1px 1px;
      box-sizing: border-box;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      border-width: 0 0 0 1px;
      left: -70px;
      width: 70px;
      height: calc(50% + 5px);
      border-color: ${_var.textGray};
      border-style: dashed;
    }

    &:last-child::after {
      border-color: ${_var.textGray};
    }
  }

  .run-group > div.run-element-wrapper:nth-of-type(2) > .run-element::before {
    top: -15px;
    height: calc(50% + 15px);
  }

  .run-group > div.run-element-wrapper:last-of-type > .run-element::after {
    display: none;
  }
`;

export const StyledNodeElement = styled.div`
  padding: ${spacing};
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${_var.grayBorder};
`;

export const StyledNodePlaceholderElement = styled(StyledNodeElement)`
  border-color: ${_var.primary};
  border-style: dotted;
  margin-bottom: ${spacing};
  margin-left: 75px;
`;

export const StyledSingleNodeElement = styled(StyledNodeElement)`
  flex: 1;
  display: flex;
  justify-content: space-between;
  background: ${_var.secondary};
`;

export const StyledGroupNodeElement = styled(StyledNodeElement)`
  border-color: ${_var.grayBorder};
`;

export const StyledGroupNodeOperatorWrapper = styled.div`
  margin-bottom: ${spacing};
`;

export const StyledGroupNodeActionsWrapper = styled.span`
  text-align: right;
  display: block;
  padding-right: 11px;
`;

export const StyledNodeWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${spacing};

  &.is-dragging {
    opacity: 0.5;
  }
`;

const StyledRadioGroupWrapper = styled(StyledRadioGroup)`
  z-index: 1;
  margin-right: 5px;

  .rs-radio label {
    padding: 2px 6px;
  }
`;

export const StyledNegationInputWrapper = styled(StyledRadioGroupWrapper)`
  margin-left: 17px;
`;

export const StyledOperatorInputWrapper = styled(StyledRadioGroupWrapper)`
  .rs-radio-disabled {
    background-color: ${_var.igoPlumLighten} !important;
    border-right-color: ${_var.grayBorder} !important;

    label {
      color: ${_var.headerGray} !important;
    }
  }
`;
