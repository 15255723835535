import { ROLES_LOADED } from './actionTypes';
import { Role } from './types';
import { AnyAction } from 'redux';

const initialState = {
  roles: [],
};

export type RolesState = Readonly<{
  roles: Role[];
}>;

export const rolesReducer = (state: RolesState = initialState, action: AnyAction) => {
  switch (action.type) {
    case ROLES_LOADED:
      return {
        ...state,
        roles: action.payload,
      };
    default:
      return state;
  }
};
