import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../shared-components/tooltip/Tooltip';
import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const StyledLabel = styled.span<{ highlighted?: boolean }>`
  color: ${({ highlighted = false }) => (highlighted ? _var.stepGreen : 'inherit')};
`;

export const LifeCycleDetailsLabel = ({
  translationKey,
  highlighted = false,
}: {
  translationKey: string;
  highlighted?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledLabel highlighted={highlighted}>{t(translationKey)}</StyledLabel>
      <Tooltip translationKey={`${translationKey}Tooltip`} />
    </>
  );
};
