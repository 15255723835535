import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { TCActions, TCRecord } from '../types';
import { useToasts } from 'react-toast-notifications';
import { AxiosError } from 'axios';
import { ARCHIVED, DRAFT, PUBLISHED } from '../data/constants';
import { Dropdown, Whisper, Popover, IconButton, Icon } from 'rsuite';
import { removeTestCase, updateTCState } from '../../../../api/test-case';
import { Confirm } from '../../../../helpers/confirmationPopup/Confirm';
import { useTranslation } from 'react-i18next';
import { defaultWhisperProps } from '../../../../helpers/constants';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';

const ActionsColumn = (
  props: RouteComponentProps & { record: TCRecord & { actions: TCActions } },
): React.ReactElement<string> => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { record, history } = props;
  const { id, status, actions, name } = record;

  const onView = () => {
    window.open(`#/test-case/${id}`);
  };

  const onDelete = () => {
    Confirm({
      title: 'Are you sure?',
      message: `You want to delete ${name}`,
      onAccept: () => {
        actions.setLoadingData(true);
        return removeTestCase(id as number)
          .then(() => {
            addToast('Test Case is successfully removed', {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
            actions.setLoadingData(false);
            return actions.getSummary();
          })
          .catch((error: AxiosError) => {
            actions.setLoadingData(false);
            addToast(error.message, {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
          });
      },
    });
  };

  const onEdit = () => {
    history.push(`/test-case/${id}/edit`);
  };

  const onCopy = () => {
    history.push(`/test-case/new/?sourceId=${id}`);
  };

  const onChangeState = () => {
    const updateStateValue = status === DRAFT ? PUBLISHED : ARCHIVED;
    Confirm({
      title: 'Are you sure?',
      message: `You want to ${status === DRAFT ? t('common.actions.publish') : t('common.actions.archive')} ${name}`,
      onAccept: () => {
        actions.setLoadingData(true);
        return updateTCState({ id: id as number, status: updateStateValue })
          .then(() => {
            addToast(`Test Case state is successfully updated to ${updateStateValue}`, {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
            actions.setLoadingData(false);
            history.push(`/test-case?tab=${status}`);
            return actions.getSummary({ tab: status });
          })
          .catch((error: AxiosError) => {
            actions.setLoadingData(false);
            handleRequestFail(error, addToast);
          });
      },
    });
  };
  return (
    <div className="actions">
      {status !== DRAFT ? (
        <IconButton onClick={onView} icon={<Icon icon="eye" />} appearance="link" />
      ) : (
        <IconButton onClick={onEdit} icon={<Icon icon="edit" />} appearance="link" />
      )}
      <Whisper
        {...defaultWhisperProps}
        speaker={
          <Popover full>
            <Dropdown.Menu className="action-icons">
              {status === DRAFT ? (
                <Dropdown.Item onClick={onView}>
                  <IconButton icon={<Icon icon="eye" />} appearance="link">
                    {t('common.actions.view')}
                  </IconButton>
                </Dropdown.Item>
              ) : null}
              {status === PUBLISHED ? (
                <Dropdown.Item onClick={onEdit}>
                  <IconButton icon={<Icon icon="edit" />} appearance="link">
                    {t('common.actions.edit')}
                  </IconButton>
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item onClick={onCopy}>
                <IconButton icon={<Icon icon="copy" />} appearance="link">
                  {t('common.actions.copy')}
                </IconButton>
              </Dropdown.Item>
              {status === DRAFT ? (
                <Dropdown.Item onClick={onChangeState}>
                  <IconButton icon={<Icon icon="check" />} appearance="link">
                    {t('common.actions.publish')}
                  </IconButton>
                </Dropdown.Item>
              ) : null}
              {status === PUBLISHED ? (
                <Dropdown.Item onClick={onChangeState}>
                  <IconButton icon={<Icon icon="ban" />} appearance="link">
                    {t('common.actions.archive')}
                  </IconButton>
                </Dropdown.Item>
              ) : null}
              {status === DRAFT ? (
                <Dropdown.Item onClick={onDelete}>
                  <IconButton icon={<Icon icon="trash-o" />} appearance="link">
                    Remove
                  </IconButton>
                </Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          </Popover>
        }
      >
        <IconButton appearance="link" icon={<Icon icon="more" className="icon pointer medium" />} />
      </Whisper>
    </div>
  );
};

export default withRouter(ActionsColumn);
