import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { HashRouter, useLocation } from 'react-router-dom';
import { atlRoles, memberRoles, wfaAdminRole, wfaEngineerRole, wfaStaffRoles } from '../../../helpers/constants';
import LayoutComponent from '../../../layouts/DashboardLayout';
import { ScrollToTopButton } from '../../../shared-components/button/ScrollToTopButton';
import {
  ApplicationDetailsPage,
  ApplicationFlowPage,
  ApplicationHistoryPage,
  ApplicationSummaryPage,
  AtlQualificationsEnablePage,
  AtlQualificationsPage,
  AtlQualificationsSummaryPage,
  CertificationCreationPage,
  CertificationDetailsPage,
  CertificationSummaryPage,
  CreateApplicationPage,
  DevLoginPage,
  HomePageComponent,
  LabInfoPage,
  LandingPage,
  LoginFailedPage,
  LoginSuccessHandler,
  MaintenancePage,
  PendingApprovalFlowPage,
  ProductDetailsPage,
  ProductHistoryPage,
  ProductSummaryPage,
  TCCreatePage,
  TCDetailsPage,
  TCSummaryPage,
  TCUpdatePage,
  TestingApplicationFlowPage,
} from '../../pages';
import { AtlQualificationsMultipleEnablePage } from '../../pages/admin/atl-qualifications/AtlQualificationsMultipleEnablePage';
import { ChangeOwningCompanyPage } from '../../pages/admin/change-owning-company/ChangeOwningCompanyPage';
import { ChangeOwningCompanySummaryPage } from '../../pages/admin/change-owning-company/ChangeOwningCompanySummaryPage';
import { TransactionsSummaryPage } from '../../pages/admin/transactions/TransactionsSummaryPage';
import { TestingSummaryPage } from '../../pages/atl-testing';
import { CloneCertificationModal } from '../../pages/certification/partials/CloneCertificationModal';
import { PendingApprovalPage } from '../../pages/staff/PendingApprovalPage';
import { AddNoteModal } from '../notes/AddNoteModal';
import { RevokeModal } from '../notes/RevokeModal';
import { PrivateRoute } from './PrivateRoute';

const SwaggerUIPage = React.lazy(() => import('../../../components/pages/swagger/SwaggerUI'));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
const wrapDashboardLayout = (ComponentToWrap: any) => () => (
  <LayoutComponent>
    <ComponentToWrap />
    <CloneCertificationModal />
    <AddNoteModal />
    <RevokeModal />
  </LayoutComponent>
);
export const ApplicationRoutes = () => (
  <HashRouter>
    <ScrollToTop />
    <ScrollToTopButton />
    <Switch>
      <PrivateRoute path="/" exact component={wrapDashboardLayout(HomePageComponent)} />
      <PrivateRoute
        grantedRoles={[...memberRoles, ...wfaStaffRoles, ...atlRoles]}
        path="/application"
        exact
        component={wrapDashboardLayout(ApplicationSummaryPage)}
      />
      <PrivateRoute
        grantedRoles={wfaStaffRoles}
        path="/application/:id/history"
        component={wrapDashboardLayout(ApplicationHistoryPage)}
      />
      <PrivateRoute
        grantedRoles={[...memberRoles, ...atlRoles]}
        path="/application/:formId/:action"
        component={wrapDashboardLayout(ApplicationFlowPage)}
      />
      <PrivateRoute
        grantedRoles={[...memberRoles, ...atlRoles]}
        path="/application/new"
        exact
        component={wrapDashboardLayout(CreateApplicationPage)}
      />
      <PrivateRoute
        grantedRoles={[...memberRoles, ...wfaStaffRoles, ...atlRoles]}
        path="/application/:id"
        component={wrapDashboardLayout(ApplicationDetailsPage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole, wfaEngineerRole]}
        path="/test-case"
        exact
        component={wrapDashboardLayout(TCSummaryPage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole, wfaEngineerRole]}
        path="/test-case/new"
        component={wrapDashboardLayout(TCCreatePage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole, wfaEngineerRole]}
        path="/test-case/:id/edit"
        component={wrapDashboardLayout(TCUpdatePage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole, wfaEngineerRole]}
        path="/test-case/:id"
        component={wrapDashboardLayout(TCDetailsPage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole, wfaEngineerRole]}
        path="/certification"
        exact
        component={wrapDashboardLayout(CertificationSummaryPage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole, wfaEngineerRole]}
        path="/certification/:type/new"
        component={wrapDashboardLayout(CertificationCreationPage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole, wfaEngineerRole]}
        path="/certification/:id"
        component={wrapDashboardLayout(CertificationDetailsPage)}
      />
      <PrivateRoute grantedRoles={atlRoles} path="/lab-info" component={wrapDashboardLayout(LabInfoPage)} />
      <PrivateRoute
        grantedRoles={atlRoles}
        path="/lab-request/:id/:action"
        component={wrapDashboardLayout(TestingApplicationFlowPage)}
      />
      <PrivateRoute
        grantedRoles={atlRoles}
        path="/lab-request/:id"
        component={wrapDashboardLayout(TestingApplicationFlowPage)}
      />
      <PrivateRoute grantedRoles={atlRoles} path="/lab-request" component={wrapDashboardLayout(TestingSummaryPage)} />
      <PrivateRoute
        grantedRoles={wfaStaffRoles}
        path="/pending-approval/:id/history"
        component={wrapDashboardLayout(ApplicationHistoryPage)}
      />
      <PrivateRoute
        grantedRoles={wfaStaffRoles}
        path="/pending-approval/:id"
        component={wrapDashboardLayout(PendingApprovalFlowPage)}
      />
      <PrivateRoute
        grantedRoles={wfaStaffRoles}
        path="/pending-approval"
        component={wrapDashboardLayout(PendingApprovalPage)}
      />
      <PrivateRoute
        grantedRoles={wfaStaffRoles}
        path="/product/:id/history"
        component={wrapDashboardLayout(ProductHistoryPage)}
      />
      <PrivateRoute
        grantedRoles={[...wfaStaffRoles, ...memberRoles]}
        path="/product/:id"
        component={wrapDashboardLayout(ProductDetailsPage)}
      />
      <PrivateRoute
        grantedRoles={[...wfaStaffRoles, ...memberRoles]}
        path="/product"
        component={wrapDashboardLayout(ProductSummaryPage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole]}
        path="/admin/atl-qualifications/enable"
        exact
        component={wrapDashboardLayout(AtlQualificationsMultipleEnablePage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole]}
        path="/admin/atl-qualifications/:id/enable"
        exact
        component={wrapDashboardLayout(AtlQualificationsEnablePage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole]}
        path="/admin/atl-qualifications/:id"
        exact
        component={wrapDashboardLayout(AtlQualificationsSummaryPage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole]}
        path="/admin/atl-qualifications"
        exact
        component={wrapDashboardLayout(AtlQualificationsPage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole]}
        path="/admin/change-owning-company-summary"
        exact
        component={wrapDashboardLayout(ChangeOwningCompanySummaryPage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole]}
        path="/admin/change-owning-company/:from/:to"
        exact
        component={wrapDashboardLayout(ChangeOwningCompanyPage)}
      />
      <PrivateRoute
        grantedRoles={[wfaAdminRole]}
        path="/admin/transactions"
        exact
        component={wrapDashboardLayout(TransactionsSummaryPage)}
      />
      <Route path="/maintenance" exact component={MaintenancePage} />
      <Route path="/landing" exact component={LandingPage} />
      <Route path="/login/dev" exact component={DevLoginPage} />
      <Route path="/login/success" exact component={LoginSuccessHandler} />
      <Route path="/login/fail" exact component={LoginFailedPage} />
      <Suspense fallback={<div>Loading...</div>}>
        <Route path="/swagger-ui" exact component={wrapDashboardLayout(SwaggerUIPage)} />
      </Suspense>
    </Switch>
  </HashRouter>
);
