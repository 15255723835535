import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { CollapsableSection } from './CollapsableSection';
import {
  BasicSaveResponse,
  CertificationType,
  CollapsedSectionProps,
  FormCapability,
  OnChangeType,
  UmbrellaInfo,
} from '../types';
import { ErrorObject, ValidationError } from '../../../../helpers/types';
import { ViewUmbreallSection, EditUmbrellaSection } from './umbrella';
import { confirmAction, jsonCopy, mergeObjects, prepareYupModel } from '../../../../helpers';
import { generalInfoSchema } from '../validation-schemes';
import { updateCertificationUmbrella } from '../../../../api/certification/partial-update/update-certification-umrella';
import { AxiosError, AxiosResponse } from 'axios';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { useToasts } from 'react-toast-notifications';
import { handleRequestSuccess } from '../../../../helpers/handle-request-success';
import { useTranslation } from 'react-i18next';
import { formatErrors } from '../../../../helpers/request';
import dot from 'dot-object';
import { validate } from './helpers/validate';
import * as Yup from 'yup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '../../../../redux/rootReducer';
import {
  CertificationDefinition,
  SectionEditState,
  setLoading,
  setSectionEditState,
  setValidationErrors,
  updateCertificationProperty,
} from '../../../../redux/modules/certification';
import { Confirm } from '../../../../helpers/confirmationPopup/Confirm';
import { isErrorsNotEmpty, saveData } from './helpers';
import { IsModifiedProps, useChangeDetection } from '../../../../helpers/hooks/useChangeDetection';

interface State {
  generalInfo: UmbrellaInfo;
  type: CertificationType;
  versionId: number;
  id: number;
  capabilities: FormCapability[];
  sectionsEditState: SectionEditState;
  errors: ErrorObject;
  save: boolean;
}

const calculateHashValue = (values: UmbrellaInfo) => {
  return JSON.stringify({
    name: values.name ?? '',
    description: values.description ?? '',
    internalComments: values.internalComments ?? '',
    visibleOnCertificate: values.visibleOnCertificate ?? '',
    roleId: values.role.id ?? '',
    classificationId: values.classification?.id ?? '',
  });
};

type UmbrellaSectionProps = CollapsedSectionProps & Partial<IsModifiedProps>;

export const UmbrellaSection = forwardRef<HTMLDivElement, UmbrellaSectionProps>(function UmbrellaSection(
  { isView = false, setIsModified: setIsSectionModified }: UmbrellaSectionProps,
  ref,
) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const { generalInfo, type, versionId, id, capabilities, sectionsEditState, errors, save } = useSelector<
    RootReducer,
    State
  >(
    ({ certificationReducer }) => ({
      generalInfo: certificationReducer.certification.generalInfo,
      type: certificationReducer.certification['@type'],
      versionId: certificationReducer.certification.versionId as number,
      id: certificationReducer.certification.id as number,
      capabilities: certificationReducer.certification.capabilities,
      sectionsEditState: certificationReducer.sectionsEditState,
      errors: certificationReducer.errors,
      save: certificationReducer.loadingState.save,
    }),
    shallowEqual,
  );
  const capabilityRef = useRef<number>(capabilities.length);
  const [state, setState] = useState({ generalInfo });
  const [errorsState, setErrorsState] = useState<ErrorObject>({});
  const [isModified, setIsModified] = useState(false);

  const setIsModifiedState = (value: boolean) => {
    setIsModified(value);
    setIsSectionModified && setIsSectionModified(value);
  };

  const setChangeDetectionInitialValue = useChangeDetection(setIsModifiedState, calculateHashValue, state.generalInfo);

  const validationSchema = Yup.object().shape({
    generalInfo: generalInfoSchema,
  });

  const resetForm = () => {
    setIsModifiedState(false);
    setState({ generalInfo });
    setErrorsState({});
    dispatch(setSectionEditState({ generalInfo: false }));
  };

  const onCancel = () => confirmAction(() => isModified, resetForm);

  const changeData = (value: OnChangeType, name: string) => {
    if (isView) {
      setState(prevState => {
        const newState = jsonCopy(prevState);
        dot.set(name, value, newState);
        const errors = prepareYupModel(validationSchema).checkForFieldFormatted(name, newState);
        if (errors) {
          setErrorsState(prevErrors => mergeObjects(prevErrors, { ...errors }));
        }
        return newState;
      });
    } else {
      dispatch(updateCertificationProperty(value, name));
    }
  };

  const handleChange = (value: OnChangeType, name: string) => {
    if (name.includes('role') && capabilityRef.current > 0) {
      return Confirm({
        title: t('common.placeholders.areYouSure'),
        message: t('certifications.certWithCapabilities'),
        onAccept: () => {
          changeData(value, name);
          changeData([] as Partial<CertificationDefinition>, 'capabilities');
        },
      });
    } else {
      changeData(value, name);
    }
  };

  const onSave = () => {
    setErrorsState({});
    dispatch(setValidationErrors({}));
    if (validate<{ generalInfo: UmbrellaInfo }>(validationSchema, state, setErrorsState)) {
      dispatch(setLoading({ save: true }));
      return updateCertificationUmbrella(versionId, state.generalInfo)
        .then(
          ({
            data: { generalInfo, ...dataToUpdate },
          }: AxiosResponse<{ generalInfo: UmbrellaInfo } & BasicSaveResponse>) => {
            saveData('generalInfo', generalInfo as Partial<CertificationDefinition>, 'certification', dataToUpdate);
            handleRequestSuccess(t('certifications.notifications.saved'), addToast);
            setIsModifiedState(false);
            setChangeDetectionInitialValue(state.generalInfo);
          },
        )
        .catch((errors: AxiosError) => {
          handleRequestFail(errors, addToast);
          if (errors.response?.data.errors) {
            dispatch(setValidationErrors(formatErrors(errors.response?.data.errors as ValidationError[])));
          }
        });
    }
  };

  useEffect(() => {
    if (capabilities.length !== capabilityRef.current) {
      capabilityRef.current = capabilities.length;
    }
  }, [capabilities.length]);

  useEffect(() => {
    if (isView && isErrorsNotEmpty(errors, 'generalInfo') && sectionsEditState.generalInfo) {
      setErrorsState(errors);
    }
  }, [errors]);

  return (
    <CollapsableSection
      title={t('certifications.certification')}
      showEditIcon={isView}
      isEdit={sectionsEditState.generalInfo}
      onSave={onSave}
      isSaving={save}
      onCancel={onCancel}
      ref={ref}
      onEditIconClick={() => dispatch(setSectionEditState({ generalInfo: true }))}
    >
      {isView && !sectionsEditState.generalInfo ? (
        <ViewUmbreallSection data={generalInfo} type={type} />
      ) : (
        <EditUmbrellaSection
          id={id as number}
          data={isView ? state.generalInfo : generalInfo}
          type={type}
          errors={isView ? errorsState : errors}
          onChange={handleChange}
        />
      )}
    </CollapsableSection>
  );
});
