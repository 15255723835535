import { AppFlows, ComponentCombinationSource } from './reducer';
import { TestingLab } from '../../../components/pages/application/types';
import { ProductInfoStep } from '../../../api/application/types';

export enum MemberClearData {
  NONE = 'NONE',
  CERTIFICATIONS = 'CERTIFICATIONS',
  LABS = 'LABS',
  PAYMENT = 'PAYMENT',
}

export const stepsFields = {
  default: ['id', 'certifyProductCompany', 'companyContactInfo'],
  product: ['productInfo'],
  cc: ['componentCombination'],
  certifications: ['certifications'],
  capabilities: ['capabilities'],
  labs: ['testingInfo'],
  payment: ['payment'],
};

export const initialState = {
  id: null,
  // step0
  '@type': AppFlows.NEW,
  certifyProductCompany: 'MY_COMPANY',
  companyContactInfo: {
    additionalEmail: [],
    source: null,
  },
  // step1
  productInfo: {
    cid: '',
    name: '',
    modelNumber: '',
    url: '',
    description: '',
    image: null,
    primaryCategory: null,
    additionalCategories: [],
    documents: [],
    modelVariants: [
      {
        name: '',
        searchableByPublic: null,
        availableAsDerivative: null,
        availableAsQualifiedSolution: null,
        productType: 'FLEXTRACK',
        draftCertified: false,
      },
    ],
    certified: null,
    qualifiedSolution: null,
  } as ProductInfoStep,
  // step2
  componentCombination: {
    '@type': ComponentCombinationSource.BASE,
    componentCombination: {
      accessPoint: null,
      configurator: null,
      display: null,
      easyMeshController: null,
      homeDesign: null,
      source: null,
      station: null,
      newRoles: [],
    },
    componentCombinationVariantSource: {
      id: null,
      name: null,
      company: {
        id: null,
        name: null,
      },
      product: {
        id: null,
        name: null,
        cid: null,
      },
    },
  },
  // step3 & step 4
  certifications: [],
  autoValidateCapability: false,
  capabilitisByCC: [],
  // step 5
  testingInfo: {
    testingLabs: [],
    testingException: null,
  },
  // step 6
  payment: {
    type: 'INVOICE',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    additionalInfo: '',
  },
  errors: {},
  showPopup: true,
  certificationsLoading: false,
  validated: {
    product: false,
    cc: false,
    certifications: false,
    capabilities: false,
    labs: false,
    payment: false,
  },
  certificationsLabs: [],
  compareCCInfo: {
    initialCC: {},
    showConfirmNotes: false,
    showVariantWarning: [],
  },
  legacy: {
    terms: false,
    agreement: false,
  },
  acknowledged: false,
  selectedTestingLabs: [],
  additionalInfo: {
    po: '',
    ctia: '',
    payer: {
      id: null,
    },
  },
  reviewSectionEditState: {
    productInfo: false,
    variantInfo: false,
  },
  fees: [],
};

export const defaultSelectedLabValue: TestingLab = {
  id: null,
  name: '',
  allowedTesting: {
    conformance: false,
    interoperability: false,
  },
};
