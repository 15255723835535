import styled from 'styled-components';
import { Icon } from 'rsuite';
// @ts-ignore
import _var from '../../styles/_variables.scss';

export const HeaderWrapper = styled.div`
  color: ${_var.textGray};
  box-shadow: 0 1px 3px ${_var.lightGray};
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
`;

export const LogoMenuWrapper = styled.aside`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  img {
    height: 40px;
    margin: 1rem 0.5rem;
  }
`;

export const MenuWrapper = styled.div`
  height: 100%;
`;

export const UserInfoWrapper = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 0.9rem;
  }
`;

export const ImpersonateIcon = styled(Icon)`
  margin: 0 0.3rem;
  cursor: pointer;
`;
