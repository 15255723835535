import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionsTable } from './components/TransactionsTable';
import { getTransactionsList } from '../../../../api/transactions/get-transactions';
import { Transaction } from './types';
import { getSummaryWithoutTabPageComponentByObject } from '../../../hoc/get-summary-without-tabs-page-component';
import TransactionsSummaryLegend from './components/TransactionsSummaryLegend';

const TransactionsPage = () => {
  const { t } = useTranslation();

  const SummaryPage = getSummaryWithoutTabPageComponentByObject<Transaction>({
    title: t('admin.transactions.transactionsTitle'),
    apiCall: getTransactionsList,
    SummaryTable: TransactionsTable,
    defaultSorting: { sortColumn: 'date', sortType: 'desc' },
    useAdvancedFilters: true,
    isTransactionSummary: true,
  });
  return (
    <>
      <SummaryPage />
      <div style={{ marginLeft: '20px' }}>
        <TransactionsSummaryLegend />
      </div>
    </>
  );
};

export const TransactionsSummaryPage = React.memo(TransactionsPage);
