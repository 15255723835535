import React from 'react';
import { OtherRolesProps } from '../../application/types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RolesState } from '../../../../redux/modules/roles/reducer';
import { Role } from '../../../../redux/modules/roles/types';
import { DetailsItem } from '../../../partials';
import { RfCard } from '../../application/partials/cc-details/RfViewComponent';
import classNames from 'classnames';
import dot from 'dot-object';
import { RoleItemName } from './RoleItemName';
import { WrapWithPublicIcon } from '../../../../shared-components/helper-components/WrapWithPublicIcon';
import { Col, Row } from 'rsuite';
import styled from 'styled-components';

export const RowWithMargin = styled(Row)`
  margin-bottom: 1rem;
`;

export const DefaultRolesRowsCompare = ({
  record,
  roleProperty,
  compareRecord,
  expandedCC,
  toggleCC,
  toggleKey,
  showPublicIcon = false,
  ccChanged = false,
  isFullSizeCol = false,
}: {
  record: OtherRolesProps;
  compareRecord?: OtherRolesProps;
  roleProperty: string;
  expandedCC: string[];
  toggleCC: (key: string, isOpen: boolean) => void;
  toggleKey: string;
  showPublicIcon?: boolean;
  ccChanged?: boolean;
  isFullSizeCol?: boolean;
}) => {
  const { t } = useTranslation();
  const roles = useSelector<{ rolesReducer: RolesState }, Role[]>(state => state.rolesReducer.roles);
  const roleName = roles.find(item => item.propertyName === roleProperty)?.name;

  const compare = (name: string) => (compareRecord ? dot.dot(compareRecord)[name] !== dot.dot(record)[name] : false);

  const compareRfProperty = (name: string, index: number) => {
    if (compareRecord) {
      if (!compareRecord.core.rfArchitecture[index]) {
        return true;
      }
      return (
        dot.dot(compareRecord.core.rfArchitecture[index])[name] !== dot.dot(record.core.rfArchitecture[index])[name]
      );
    }
    return false;
  };

  const isOpen = expandedCC.includes(toggleKey);

  return (
    <div className="mb-2">
      <RoleItemName isOpen={isOpen} roleName={roleName} onToggle={() => toggleCC(toggleKey, !isOpen)} />
      {isOpen ? (
        <div className="ml-2">
          <Row>
            <Col sm={isFullSizeCol ? 24 : 12}>
              <RowWithMargin gutter={20}>
                <Col xs={12}>
                  <DetailsItem
                    label={t('applications.cc.chipset')}
                    className={
                      ccChanged
                        ? classNames({ 'core-change-text-different': compare('core.chipset') })
                        : classNames({ 'text-different': compare('core.chipset') })
                    }
                    value={record.core.chipset}
                  />
                </Col>
                <Col xs={12}>
                  <DetailsItem
                    label={
                      showPublicIcon ? (
                        <WrapWithPublicIcon content={t('applications.cc.componentOS')} />
                      ) : (
                        t('applications.cc.componentOS')
                      )
                    }
                    className={
                      ccChanged
                        ? classNames({ 'core-change-text-different': compare('os') })
                        : classNames({ 'text-different': compare('os') })
                    }
                    value={record.os}
                  />
                </Col>
              </RowWithMargin>
              <RowWithMargin gutter={20}>
                <Col xs={12}>
                  <DetailsItem
                    label={
                      showPublicIcon ? (
                        <WrapWithPublicIcon content={t('applications.cc.firmware')} />
                      ) : (
                        t('applications.cc.firmware')
                      )
                    }
                    className={
                      ccChanged
                        ? classNames({ 'core-change-text-different': compare('firmware') })
                        : classNames({ 'text-different': compare('firmware') })
                    }
                    value={record.firmware}
                  />
                </Col>
                <Col xs={12}>
                  <DetailsItem
                    label={t('applications.cc.interface')}
                    className={
                      ccChanged
                        ? classNames({ 'core-change-text-different': compare('physicalInterface') })
                        : classNames({ 'text-different': compare('physicalInterface') })
                    }
                    value={record.physicalInterface}
                  />
                </Col>
              </RowWithMargin>
              <RowWithMargin gutter={20}>
                <Col xs={12}>
                  <DetailsItem
                    label={t('applications.cc.driver')}
                    className={
                      ccChanged
                        ? classNames({ 'core-change-text-different': compare('core.driver') })
                        : classNames({ 'text-different': compare('core.driver') })
                    }
                    value={record.core.driver}
                  />
                </Col>
                <Col xs={12}>
                  <DetailsItem
                    label={t('applications.cc.rfComponents')}
                    className={
                      ccChanged
                        ? classNames({ 'core-change-text-different': compare('rfComponents') })
                        : classNames({ 'text-different': compare('rfComponents') })
                    }
                    value={record.rfComponents}
                  />
                </Col>
              </RowWithMargin>
              <RowWithMargin gutter={20}>
                <Col xs={12}>
                  <></>
                </Col>
                <Col xs={12}>
                  <DetailsItem
                    label={t('applications.cc.antenna')}
                    className={
                      ccChanged
                        ? classNames({ 'core-change-text-different': compare('antenna') })
                        : classNames({ 'text-different': compare('antenna') })
                    }
                    value={record.antenna}
                  />
                </Col>
              </RowWithMargin>
              <RowWithMargin gutter={20}>
                <Col xs={22}>
                  <DetailsItem
                    label={
                      showPublicIcon ? (
                        <WrapWithPublicIcon content={t('applications.cc.rfArchitecture')} />
                      ) : (
                        t('applications.cc.rfArchitecture')
                      )
                    }
                  >
                    <RfCard bordered>
                      <table>
                        <thead>
                          <tr>
                            <th>{t('applications.cc.rf.bands')}</th>
                            <th>{t('applications.cc.rf.tx')}</th>
                            <th>{t('applications.cc.rf.rx')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {record.core.rfArchitecture.map((item, index) => (
                            <tr
                              key={item.band}
                              className={
                                ccChanged
                                  ? classNames('mb-1', {
                                      'core-change-text-different': compareRfProperty('band', index),
                                    })
                                  : classNames('mb-1', { 'text-different': compareRfProperty('band', index) })
                              }
                            >
                              <td>{item.band}</td>
                              <td
                                className={
                                  ccChanged
                                    ? classNames('mb-1', {
                                        'core-change-text-different': compareRfProperty('tx', index),
                                      })
                                    : classNames('mb-1', { 'text-different': compareRfProperty('tx', index) })
                                }
                              >
                                {item.tx}
                              </td>
                              <td
                                className={
                                  ccChanged
                                    ? classNames('mb-1', {
                                        'core-change-text-different': compareRfProperty('rx', index),
                                      })
                                    : classNames('mb-1', { 'text-different': compareRfProperty('rx', index) })
                                }
                              >
                                {item.rx}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </RfCard>
                  </DetailsItem>
                </Col>
              </RowWithMargin>
            </Col>
            {/* compared source */}
            {ccChanged && compareRecord ? (
              <Col sm={12}>
                <RowWithMargin gutter={20}>
                  <Col xs={12}>
                    <DetailsItem label={t('applications.cc.chipset')} value={compareRecord.core.chipset} />
                  </Col>
                  <Col xs={12}>
                    <DetailsItem
                      label={
                        showPublicIcon ? (
                          <WrapWithPublicIcon content={t('applications.cc.componentOS')} />
                        ) : (
                          t('applications.cc.componentOS')
                        )
                      }
                      value={compareRecord.os}
                    />
                  </Col>
                </RowWithMargin>
                <RowWithMargin gutter={20}>
                  <Col xs={12}>
                    <DetailsItem
                      label={
                        showPublicIcon ? (
                          <WrapWithPublicIcon content={t('applications.cc.firmware')} />
                        ) : (
                          t('applications.cc.firmware')
                        )
                      }
                      value={compareRecord.firmware}
                    />
                  </Col>
                  <Col xs={12}>
                    <DetailsItem label={t('applications.cc.interface')} value={compareRecord.physicalInterface} />
                  </Col>
                </RowWithMargin>
                <RowWithMargin gutter={20}>
                  <Col xs={12}>
                    <DetailsItem label={t('applications.cc.driver')} value={compareRecord.core.driver} />
                  </Col>
                  <Col xs={12}>
                    <DetailsItem label={t('applications.cc.rfComponents')} value={compareRecord.rfComponents} />
                  </Col>
                </RowWithMargin>
                <RowWithMargin gutter={20}>
                  <Col xs={12}>
                    <></>
                  </Col>
                  <Col xs={12}>
                    <DetailsItem label={t('applications.cc.antenna')} value={compareRecord.antenna} />
                  </Col>
                </RowWithMargin>
                <RowWithMargin gutter={20}>
                  <Col xs={22}>
                    <DetailsItem
                      label={
                        showPublicIcon ? (
                          <WrapWithPublicIcon content={t('applications.cc.rfArchitecture')} />
                        ) : (
                          t('applications.cc.rfArchitecture')
                        )
                      }
                    >
                      <RfCard bordered>
                        <table>
                          <thead>
                            <tr>
                              <th>{t('applications.cc.rf.bands')}</th>
                              <th>{t('applications.cc.rf.tx')}</th>
                              <th>{t('applications.cc.rf.rx')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {compareRecord.core.rfArchitecture.map(item => (
                              <tr key={item.band}>
                                <td>{item.band}</td>
                                <td>{item.tx}</td>
                                <td>{item.rx}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </RfCard>
                    </DetailsItem>
                  </Col>
                </RowWithMargin>
              </Col>
            ) : null}
          </Row>
        </div>
      ) : null}
    </div>
  );
};
