import React from 'react';
import styled from 'styled-components';
import { Whisper, Tooltip } from 'rsuite';
import { reformatDate } from '../../../../helpers';
import { ActionsColumn } from './ActionsColumn';
import { useTranslation } from 'react-i18next';
import { SummaryTableProps } from '../../../../helpers/types';
import { ApplicationSummaryRecord, ATLStatus } from '../../application/types';
import classNames from 'classnames';
// @ts-ignore
import _var from '../../../../styles/_variables.scss';
import DataTable from '../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../shared-components/table/DataTableColumn';

const ATLWrapper = styled.p`
  display: flex;
  align-items: center;

  .status-marker {
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: ${_var.igoYellow};
    display: inline-block;
    margin-right: 0.5rem;

    &.is-submitted {
      background-color: ${_var.stepGreen};
    }
  }
`;

export const SummaryTable = (props: SummaryTableProps<ApplicationSummaryRecord>) => {
  const { data, loading, onChangeSorting, sorting, total, status, activePage, setActivePage, showLastBtn } = props;
  const { t } = useTranslation();
  return (
    <DataTable<ApplicationSummaryRecord>
      sticky
      pagination
      data={data}
      total={total}
      sorting={sorting}
      loading={loading}
      activePage={activePage}
      setActivePage={setActivePage}
      onChangeSorting={onChangeSorting}
      useEnvIdentifier={true}
      showLastBtn={showLastBtn}
    >
      <DataTableColumn field="cid" label={t('applications.summary.cid')} minWidth={110} sortable />
      <DataTableColumn field="id" label={t('applications.summary.appId')} minWidth={100} sortable />
      <DataTableColumn
        field="productName"
        label={t('applications.summary.name')}
        minWidth={160}
        width="20%"
        sortable
        wordWrap
      />
      <DataTableColumn
        field="variantName"
        label={t('applications.info.variantName')}
        minWidth={160}
        width="20%"
        sortable
        wordWrap
      />
      <DataTableColumn
        field="owningCompanyName"
        label={t('applications.summary.company')}
        minWidth={100}
        width="20%"
        sortable
        wordWrap
      >
        {(row: ApplicationSummaryRecord) => row.owningCompany}
      </DataTableColumn>
      <DataTableColumn
        field="submittingCompanyName"
        label={t('applications.summary.submittingCompany')}
        minWidth={150}
        width="20%"
        sortable
        wordWrap
      >
        {(row: ApplicationSummaryRecord) => row.submittingCompany}
      </DataTableColumn>
      <DataTableColumn field="contact" label={t('staff.pendingApproval.contact')} minWidth={220} sortable />
      <DataTableColumn field="atl" label={t('staff.pendingApproval.atls.title')} minWidth={170} width="20%" wordWrap>
        {(row: ApplicationSummaryRecord) =>
          row.atl.map(atl => {
            const isPendingApproval = atl.status === ATLStatus.PENDING_APPROVAL;
            return (
              <ATLWrapper key={atl.id}>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      {t(`staff.pendingApproval.atls.statuses.${isPendingApproval ? 'submitted' : 'inTesting'}`)}
                    </Tooltip>
                  }
                >
                  <span className={classNames('status-marker', { 'is-submitted': isPendingApproval })} />
                </Whisper>
                {atl.name}
              </ATLWrapper>
            );
          })
        }
      </DataTableColumn>
      <DataTableColumn field="staffPendingDate" label={t('staff.pendingApproval.submitted')} minWidth={130} sortable>
        {(row: ApplicationSummaryRecord) => (row.date ? reformatDate(row.date) : null)}
      </DataTableColumn>
      <DataTableColumn field="actions" label={t('common.actions.title')} minWidth={140}>
        {(row: ApplicationSummaryRecord) => <ActionsColumn record={row} status={status} />}
      </DataTableColumn>
    </DataTable>
  );
};
