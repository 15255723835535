/* eslint-disable @typescript-eslint/no-explicit-any */
import { Schema, ValidationError } from 'yup';

export interface CheckResult {
  hasError: boolean;
  errorMessage?: string;
}

export function prepareYupModel<T>(schema: Schema<T>) {
  const checkForFieldFormatted = (fieldName: string, data: T): { [x: string]: string } => {
    try {
      schema.validateSyncAt(fieldName, data);
    } catch (e) {
      return { [fieldName]: e.path === fieldName ? e.message : '' };
    }
    return { [fieldName]: '' };
  };

  const checkForField = (fieldName: string, data: T): CheckResult => {
    try {
      schema.validateSyncAt(fieldName, data);
    } catch (e) {
      return { hasError: true, errorMessage: e.message };
    }
    return { hasError: false };
  };

  const check = (data: T): CheckResult | { [x: string]: CheckResult } => {
    try {
      schema.validateSync(data, { abortEarly: false });
    } catch (e) {
      if (e.errors && e.errors.length) {
        return (e.inner as ValidationError[]).reduce<{ [x: string]: CheckResult }>(
          (prev, curr: { path: string; message: string }) => {
            prev[curr.path] = { hasError: true, errorMessage: curr.message };
            return prev;
          },
          Object.create(null),
        );
      }
      return { hasError: true, errorMessage: e.message };
    }
    return { hasError: false };
  };

  const checkFormatted = (data: T) => {
    const checkResult = check(data);
    if (typeof checkResult.hasError !== 'undefined') {
      return (checkResult && checkResult.errorMessage) || null;
    }
    return Object.keys(checkResult).reduce<{ [x: string]: string }>((prev, curr: string) => {
      // @ts-ignore
      prev[curr] = (checkResult[curr] as CheckResult).errorMessage as string;
      return prev;
    }, Object.create(null));
  };

  return {
    checkForFieldFormatted,
    checkFormatted,
    checkForField,
    check,
  };
}
