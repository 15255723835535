import React from 'react';
import { useTranslation } from 'react-i18next';
import { staffSummaryAppTypes, staffSummaryTitles } from '../../../helpers/constants';
import { SummaryTable } from './partials/SummaryTable';
import { getPendingApprovalSummary } from '../../../api/staff';
import { getSummaryPageComponentByObject } from '../../hoc/get-summary-page-component';
import { ApplicationSummaryRecord } from '../application/types';
import { useLocation } from 'react-router';
import { parseUrlParams } from '../../../helpers';
import { AppFlows } from '../../../redux/modules/application-flow';

const SummaryPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = parseUrlParams(location.search);

  const SummaryPage = getSummaryPageComponentByObject<ApplicationSummaryRecord>({
    title: t('staff.pendingApproval.title'),
    apiCall: getPendingApprovalSummary,
    object: staffSummaryAppTypes,
    titles: staffSummaryTitles,
    SummaryTable,
    defaultSorting: { sortColumn: 'staffPendingDate', sortType: params?.tab === AppFlows.DERIVATIVE ? 'asc' : 'desc' },
    showLastBtn: true,
    useAdvancedFilters: true,
  });

  return <SummaryPage />;
};

export const PendingApprovalPage = React.memo(SummaryPage);
