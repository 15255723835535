export const RESET_STATE = 'ATL_QUALIFICATIONS_RESET_STATE';

export const LOAD_LABS_LIST = 'ATL_QUALIFICATIONS_LOAD_LABS_LIST';
export const SET_LABS_LIST = 'ATL_QUALIFICATIONS_SET_LABS_LIST';

export const LOAD_LAB_INFO = 'ATL_QUALIFICATIONS_LOAD_LAB_INFO';
export const SET_LAB_INFO = 'ATL_QUALIFICATIONS_SET_LAB_INFO';

export const LOAD_CERTIFICATIONS_LIST = 'ATL_QUALIFICATIONS_LOAD_CERTIFICATIONS_LIST';
export const SET_CERTIFICATIONS_LIST = 'ATL_QUALIFICATIONS_SET_CERTIFICATIONS_LIST';

export const LOAD_AVAILABLE_CERTIFICATIONS_LIST = 'ATL_QUALIFICATIONS_LOAD_AVAILABLE_CERTIFICATIONS_LIST';
export const SET_AVAILABLE_CERTIFICATIONS_LIST = 'ATL_QUALIFICATIONS_SET_AVAILABLE_CERTIFICATIONS_LIST';

export const SHOW_UPDATE_QUALIFICATIONS_MODAL = 'ATL_QUALIFICATIONS_SHOW_UPDATE_QUALIFICATIONS_MODAL';
export const HIDE_UPDATE_QUALIFICATIONS_MODAL = 'ATL_QUALIFICATIONS_HIDE_UPDATE_QUALIFICATIONS_MODAL';

export const UPDATE_LAB_QUALIFICATION = 'ATL_QUALIFICATIONS_UPDATE_LAB_QUALIFICATIONS';
export const REMOVE_LAB_QUALIFICATION = 'ATL_QUALIFICATIONS_REMOVE_LAB_QUALIFICATIONS';
export const LAB_QUALIFICATION_REMOVED = 'ATL_QUALIFICATIONS_LAB_QUALIFICATION_REMOVED';

export const ADD_LAB_CERTIFICATIONS = 'ATL_QUALIFICATIONS_ADD_LAB_CERTIFICATIONS';
export const UPDATE_SELECTED_CERTIFICATION_PROPERTY = 'ATL_QUALIFICATIONS_UPDATE_SELECTED_CERTIFICATION_PROPERTY';
export const REMOVE_SELECTED_CERTIFICATION = 'ATL_QUALIFICATIONS_REMOVE_SELECTED_CERTIFICATION';

export const PUBLISH_SELECTED_CERTIFICATIONS = 'ATL_QUALIFICATIONS_PUBLISH_SELECTED_CERTIFICATIONS';
export const PUBLISH_SELECTED_CERTIFICATIONS_ERROR = 'ATL_QUALIFICATIONS_SELECTED_CERTIFICATIONS_PUBLISHED_ERROR';
export const SELECTED_CERTIFICATIONS_PUBLISHED = 'ATL_QUALIFICATIONS_SELECTED_CERTIFICATIONS_PUBLISHED';
