import React from 'react';
import { Certification, Path, ConditionOperator } from './types';
import { ErrorObject } from '../../helpers/types';

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
export const ConditionBuilderContext = React.createContext({
  errors: {} as ErrorObject,
  removeNode: (path: Path) => {},
  addGroupNode: (path: Path) => {},
  addSingleNode: (path: Path, certification: Certification) => {},
  setOperatorValue: (path: Path, operator: ConditionOperator) => {},
  setNegationValue: (path: Path, value: boolean) => {},
});
