import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { Button, FlexboxGrid } from 'rsuite';

import { PageTemplate } from '../../partials';
import { TestingSummarySidebar } from '../atl-testing/partials';
import { ActionLeft } from './partials/HeaderActions';
import { PendingApprovalSteps } from './partials/PendingApprovalSteps';
import { WhisperActions } from './partials/WhisperActions';
import { ReviewAppStep } from './partials/ReviewAppStep';
import { ReviewAndApproveStep } from './partials/ReviewAndApproveStep';
import { approveApplication, getSingleApplication } from '../../../api/application';
import { ApplicationInfoRecord } from '../application/types';
import { applicationTypes, LRStatus } from '../../../helpers/constants';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { showAddNoteModal } from '../../../redux/modules/modals';
import { NoteActionTypes } from '../../../redux/modules/modals/constants';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import { getFeeByAppId } from '../../../api/billing/get-fee-by-app-id';
import { GetFeeResponse } from '../../partials/billing/types';

const PendingApprovalFlowComponent = ({
  history,
  location,
  match,
  isReview = false,
}: RouteComponentProps<{ id: string; action: string }> & { isReview?: boolean }) => {
  const stepParam = new URLSearchParams(location.search).get('step');
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [step, setStep] = useState(stepParam ? Number(stepParam) : 1);
  const [application, setApplication] = useState<ApplicationInfoRecord | null>(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [feesLoading, setFeesLoading] = useState(true);
  const [applicationFees, setApplicationFees] = useState<GetFeeResponse[]>([]);
  const dispatch = useDispatch();

  const isDerivative = application && application['@type'] === applicationTypes.derivative;
  const hideApprovalSteps = !application || application['@type'] === applicationTypes.derivative;
  const isDisabledApprove = !application?.labRequests?.every(lr => lr.status === LRStatus.PENDING_APPROVAL);
  const isLRNonDraftExists =
    !isDerivative &&
    (application?.labRequests.length === 0 ||
      application?.labRequests?.some(lr => lr.status !== LRStatus.DRAFT) ||
      application?.testingException !== null);
  const isFirstStep = step === 1;
  const isSecondStep = step === 2;
  const isApprovePossible = !isReview && (isSecondStep || (isFirstStep && isDerivative));

  const isTestingExceptionFromLab = application?.labRequests?.some(req => !!req.testingException);
  const isTestingException = !!application?.testingException || isTestingExceptionFromLab;

  const fetchApplication = () => {
    setLoading(true);
    getSingleApplication(Number(match.params.id))
      .then((response: AxiosResponse<ApplicationInfoRecord>) => {
        const applicationData = response.data;
        setApplication(applicationData);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  const handleLrUpdates = () => fetchApplication();

  const changeStep = (stepNumber: number) => {
    window.scrollTo(0, 0);
    setStep(stepNumber);
    history.push({
      pathname: location.pathname,
      search: `?step=${stepNumber}`,
    });
  };

  useEffect(() => {
    if (!stepParam) {
      changeStep(1);
    }
    fetchApplication();
  }, []);

  useEffect(() => {
    if (application === null) return;
    if (
      application?.id &&
      (application.productInfo.certified || application.productInfo.modelVariants[0].draftCertified)
    ) {
      getFeeByAppId(application.id)
        .then((response: AxiosResponse<GetFeeResponse[]>) => {
          setApplicationFees(response?.data);
          setFeesLoading(false);
        })
        .catch(() => {
          setFeesLoading(false);
        });
    } else {
      setFeesLoading(false);
    }
  }, [application]);

  const handlePreviousStep = () => {
    changeStep(step - 1);
  };

  const handleNextStep = () => {
    changeStep(step + 1);
  };

  const handleApproveApp = () => {
    setSubmitting(true);
    return approveApplication(Number(match.params.id))
      .then(() => {
        addToast(`Application #${match.params.id} was successfully approved.`, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        setSubmitting(false);
        const tab = application && application['@type'];
        history.push(tab ? `/pending-approval?tab=${tab}&page=1` : '/pending-approval');
      })
      .catch(error => {
        handleRequestFail(error, addToast);
        setSubmitting(false);
      });
  };

  const handleApprove = () => {
    if (isTestingException) {
      dispatch(
        showAddNoteModal(
          {
            appId: application?.id,
            onSuccess: () => handleApproveApp(),
          },
          NoteActionTypes.CONFIRM_APPROVAL,
        ),
      );
      return;
    }
    handleApproveApp();
  };

  const getStepComponent = () => {
    if (application !== null) {
      switch (step) {
        case 1:
          return <ReviewAppStep application={application} applicationFees={applicationFees} />;
        case 2:
          return (
            <ReviewAndApproveStep
              application={application}
              onLrUpdate={handleLrUpdates}
              isReview={isReview}
              isTestingException={isTestingException}
            />
          );
        default:
          return <span>not supported step</span>;
      }
    }
    return !loading ? <span>No application</span> : null;
  };

  const getApproveButton = () => (
    <>
      {isApprovePossible ? (
        <Button
          appearance="primary"
          className="approve"
          loading={submitting}
          disabled={application?.labRequests && isDisabledApprove}
          onClick={handleApprove}
          style={{ marginLeft: '20px' }}
        >
          {t('common.actions.approve')}
        </Button>
      ) : null}
    </>
  );

  const getRightButtons = (isFooter: boolean) => {
    if (loading || feesLoading) {
      return null;
    }
    if (isReview) {
      return (
        <div>
          {isLRNonDraftExists &&
            (isFirstStep ? (
              <Button appearance="primary" className="next-step" onClick={handleNextStep}>
                {t('common.navigation.next')}
              </Button>
            ) : (
              <Button onClick={handlePreviousStep} className="ml-1 previous-step">
                {t('common.navigation.previous')}
              </Button>
            ))}
          {isFooter ? getApproveButton() : null}
          {application && (
            <WhisperActions applicationId={application.id} isFlowPage isReview={isReview} application={application} />
          )}
        </div>
      );
    } else {
      return (
        <div>
          {!isDerivative &&
            (isFirstStep ? (
              <Button appearance="primary" className="next-step" onClick={handleNextStep}>
                {t('common.navigation.next')}
              </Button>
            ) : (
              <Button onClick={handlePreviousStep} className="ml-1 previous-step">
                {t('common.navigation.previous')}
              </Button>
            ))}
          {isFooter ? getApproveButton() : null}
          {application && <WhisperActions applicationId={application.id} isFlowPage />}
        </div>
      );
    }
  };

  const onStepClick = (step: number) => {
    changeStep(step);
  };

  return (
    <PageTemplate
      title={isReview ? t('applications.viewApplication') : t('staff.pendingApproval.flowTitle')}
      actionLeft={<ActionLeft isFooter={false} history={history} isReview={isReview} />}
      footerActionLeft={<ActionLeft isFooter={true} history={history} isReview={isReview} />}
      actionRight={getRightButtons(false)} // isFooter=false
      footerActionRight={getRightButtons(true)} // isFooter=true
    >
      <FlexboxGrid className="page-container" justify="space-between">
        <FlexboxGrid.Item colspan={24}>
          {!(hideApprovalSteps || !isLRNonDraftExists) && (
            <PendingApprovalSteps currentStep={step} onClick={onStepClick} isReview={isReview} />
          )}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={18}>
          <section className="my-2" style={{ minHeight: 500 }}>
            {loading || feesLoading ? (
              <CircularLoader backdrop content={t('common.placeholders.loadingData')} />
            ) : (
              getStepComponent()
            )}
          </section>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          {!loading && application ? (
            <TestingSummarySidebar
              step={step}
              certifications={application.certifications.map(cert => ({
                id: cert.id,
                name: cert.name,
                roleName: cert.role.name,
                capabilities: cert.capabilities,
                status: cert.version.status,
              }))}
              application={application}
              hideName
            />
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </PageTemplate>
  );
};

export const PendingApprovalFlowPage = withRouter(PendingApprovalFlowComponent);
