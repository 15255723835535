import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress } from '@material-ui/core';
import { Button } from 'rsuite';

import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { FindProductVariantInputs, getTrueKeys } from './FindProductVariantInputs';
import {
  addCCLoadedNote,
  AppCertification,
  AppFlows,
  AppFlowSteps,
  CompanyContactInfo,
  ComponentCombinationSource,
  ComponentCombinationWrapper,
  setCompareCCInfo,
  updateApplicationProperty,
} from '../../../../redux/modules/application-flow';
import { MemberClearData } from '../../../../redux/modules/application-flow/data';
import { RootReducer } from '../../../../redux/rootReducer';
import {
  ComponentCombination,
  LoadCCSourceProduct,
  LoadCCCompany,
  LoadCCVariant,
  LoadCCComponentProperties,
} from '../../../../api/application/types';
import { updateCCWithOrder } from '../../../../helpers/add-order-to-cc';
import { reformatCC } from '../data/formatters';
import {
  getCCSourceProductByAppId,
  getLoadCCCompaniesById,
  getVariantCCById,
} from '../../../../api/application/load-cc';
import { BadgesFiltering } from './BadgesFiltering';
import { ConfirmDialog } from '../../../../shared-components/confirm-dialog/ConfirmDialog';
import { getProductTypes } from '../../../../helpers/constants/product-types';
// @ts-ignore
import _var from '../../../../styles/_variables.scss';

const useStyles = makeStyles(theme => ({
  root: {
    '& 	.MuiDialogTitle-root': {
      '& .MuiTypography-h6': {
        fontWeight: 'bold',
        fontSize: '16px',
      },
      padding: theme.spacing(2, 3),
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(1, 5, 7),
      '& .MuiDialogActions-root': {
        padding: 0,
      },
      '& .MuiDialogActions-spacing > :not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
      '& .MuiTypography-subtitle1': {
        paddingBottom: theme.spacing(3.5),
      },
      '& .MuiTypography-subtitle2': {
        fontWeight: 'bold',
        paddingBottom: theme.spacing(0.8),
      },
    },
    '& .MuiButton-containedPrimary': {
      color: _var.white,
      backgroundColor: _var.primary,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: _var.primary,
        opacity: 0.8,
      },
    },
    '& .MuiButton-containedSecondary': {
      color: _var.primary,
      backgroundColor: 'transparent',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: _var.lightGray,
        opacity: 0.8,
      },
    },
  },
  cancelBtn: {
    maxWidth: theme.spacing(13),
    height: '40px',
    width: '100%',
    color: _var.primary,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontWeight: 'bold',
    fontSize: '14px',
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  submitBtn: {
    maxWidth: theme.spacing(13),
    height: '40px',
    width: '100%',
    color: _var.white,
    backgroundColor: _var.primary,
    boxShadow: 'none',
    fontWeight: 'bold',
    fontSize: '14px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: _var.primary,
      boxShadow: 'none',
      opacity: 0.8,
    },
  },
  form: {
    width: '650px',
  },
}));

interface LoadCCDialogProps {
  active: boolean;
  onClose: () => void;
}

export enum Loading {
  NONE,
  COMPANIES,
  PRODUCTS,
  CC,
}

export const LoadCCDialog: React.FC<LoadCCDialogProps> = ({ active, onClose }) => {
  const [loading, setLoading] = useState<Loading>(Loading.NONE);
  const [companies, setCompanies] = useState<LoadCCCompany[]>();
  const [selectedCompany, setSelectedCompany] = useState<LoadCCCompany | null>(null);
  const [products, setProducts] = useState<LoadCCSourceProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<LoadCCSourceProduct | null>(null);
  const [selectedVariant, setSelectedVariant] = useState<LoadCCVariant | null>(null);
  const [variants, setVariants] = useState<LoadCCVariant[]>([]);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [filterOption, setFilterOption] = useState<LoadCCComponentProperties>({
    qualifiedSolution: true,
    basedOnQualifiedSolution: false,
    other: false,
  });

  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { certifications, companyContactInfo, cc, appId, type } = useSelector<
    RootReducer,
    {
      certifications: AppCertification[];
      companyContactInfo: CompanyContactInfo;
      cc: ComponentCombination;
      appId: number | null;
      type: AppFlows;
    }
  >(state => ({
    appId: state.applicationFlow.id,
    certifications: state.applicationFlow.certifications,
    companyContactInfo: state.applicationFlow.companyContactInfo,
    cc: state.applicationFlow.componentCombination.componentCombination,
    type: state.applicationFlow['@type'],
  }));

  const classes = useStyles();

  const getCompanies = async (owningCompanyId: number, filterOption: LoadCCComponentProperties) => {
    try {
      setLoading(Loading.COMPANIES);
      const { data } = await getLoadCCCompaniesById(owningCompanyId, filterOption);
      setCompanies(data);
      setLoading(Loading.NONE);
    } catch (err) {
      console.error(err);
      setLoading(Loading.NONE);
    }
  };

  const getProducts = async (selectedCompanyId: number, filterOption: LoadCCComponentProperties) => {
    try {
      setLoading(Loading.PRODUCTS);
      const { data } = await getCCSourceProductByAppId(selectedCompanyId, filterOption);
      setProducts(data);
      setLoading(Loading.NONE);
    } catch (err) {
      console.log(err);
      setLoading(Loading.NONE);
    }
  };

  const clearAllFields = () => {
    setCompanies([]);
    setSelectedCompany(null);
    setProducts([]);
    setSelectedProduct(null);
    setVariants([]);
    setSelectedVariant(null);
  };

  useEffect(() => {
    if (companyContactInfo.owningCompany) {
      getCompanies(companyContactInfo.owningCompany?.id, filterOption);
    }
  }, [filterOption]);

  useEffect(() => {
    if (selectedCompany) {
      getProducts(selectedCompany.id, filterOption);
    }
  }, [filterOption, selectedCompany]);

  useEffect(() => {
    if (selectedProduct) {
      setVariants(selectedProduct.variants);
    }
  }, [filterOption, selectedProduct]);

  const handleChangeFilterOption = (option: LoadCCComponentProperties) => {
    clearAllFields();
    setFilterOption(option);
  };

  const loadCCRequest = () => {
    setLoading(Loading.CC);
    const result = async () => {
      try {
        const { data } = await getVariantCCById(selectedVariant?.id as number);
        dispatch(
          updateApplicationProperty(
            {
              '@type': ComponentCombinationSource.LOADED,
              componentCombination: updateCCWithOrder(data),
              componentCombinationVariantSource: {
                id: selectedVariant?.id,
                name: selectedVariant?.name,
                company: {
                  id: selectedCompany?.id,
                  name: selectedCompany?.name,
                },
                product: {
                  id: selectedProduct?.id,
                  cid: selectedProduct?.cid,
                  name: selectedProduct?.name,
                },
                productType: getProductTypes(getTrueKeys(selectedProduct?.componentProperties)[0]),
              },
            } as ComponentCombinationWrapper<ComponentCombination>,
            'componentCombination',
            MemberClearData.CERTIFICATIONS,
            AppFlowSteps.CC,
          ),
        );

        if (data && type === AppFlows.NEW) {
          dispatch(setCompareCCInfo({ initialCC: updateCCWithOrder(data) as ComponentCombination }));
        }

        if (appId && selectedVariant?.name && selectedProduct?.cid) {
          dispatch(addCCLoadedNote(appId, selectedProduct?.cid, selectedVariant?.name));
        }

        setLoading(Loading.NONE);
        onClose();
      } catch (err) {
        console.error(err);
        setLoading(Loading.NONE);
        handleRequestFail(err, addToast);
      }
    };

    return result();
  };

  const onLoadCC = () => {
    if (reformatCC(cc) !== null) {
      onClose();
      return setOpenConfirm(true);
    }
    onClose();
    loadCCRequest();
  };

  const resetToInitialState = () => {
    setFilterOption((prev: LoadCCComponentProperties) => ({
      ...prev,
      qualifiedSolution: true,
      basedOnQualifiedSolution: false,
      other: false,
    }));
    clearAllFields();
  };

  const handleChangeSelectedCompany = (company: LoadCCCompany | null) => {
    setSelectedCompany(company);
    setSelectedProduct(null);
    setSelectedVariant(null);
  };

  const handleChangeSelectedProduct = (product: LoadCCSourceProduct | null) => {
    setSelectedProduct(product);
    setSelectedVariant(null);
  };

  const handleChangeSelectedVariant = (variant: LoadCCVariant | null) => {
    setSelectedVariant(variant);
  };

  const handleConfirmDialog = () => {
    loadCCRequest();
    setOpenConfirm(false);
  };

  return (
    <>
      <Dialog open={active} onClose={onClose} onEnter={resetToInitialState} maxWidth="md">
        <div className={classes.root}>
          <DialogTitle id="form-dialog-title">{t('applications.cc.loadCC.title')}</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1">{t('applications.cc.loadCC.legend')}</Typography>
            <Typography variant="subtitle2" gutterBottom>
              {t('applications.cc.loadCC.subtitle')}
            </Typography>
            <BadgesFiltering filterData={filterOption} handleChangeFilterOption={handleChangeFilterOption} />
            <div className={classes.form}>
              <FindProductVariantInputs
                loading={loading}
                companies={companies || []}
                selectedCompany={selectedCompany}
                products={products || []}
                selectedProduct={selectedProduct}
                selectedVariant={selectedVariant}
                variants={variants || []}
                handleChangeSelectedCompany={handleChangeSelectedCompany}
                handleChangeSelectedProduct={handleChangeSelectedProduct}
                handleChangeSelectedVariants={handleChangeSelectedVariant}
              />
            </div>
            {certifications.length > 0 ? (
              <p>
                <small className="text-danger">
                  {t('applications.cc.warning.warning', {
                    addition: t('applications.cc.warning.additions.certsAndLabs'),
                  })}
                </small>
              </p>
            ) : null}
            <DialogActions>
              <Button onClick={onClose} appearance="link" className="cancel-cc-btn">
                {t('common.navigation.cancel')}
              </Button>
              <Button onClick={onLoadCC} appearance="primary" disabled={!selectedVariant?.id} className="load-cc-btn">
                {loading === Loading.CC ? <CircularProgress size={24} color="inherit" /> : t('common.actions.load')}
              </Button>
            </DialogActions>
          </DialogContent>
        </div>
      </Dialog>
      <ConfirmDialog
        handleConfirm={handleConfirmDialog}
        handleClose={() => setOpenConfirm(false)}
        title={t('applications.cc.warning.title')}
        open={openConfirm}
      >
        <p>
          {t('applications.cc.warning.warning', {
            addition: t('applications.cc.warning.additions.cc'),
          })}
        </p>
      </ConfirmDialog>
    </>
  );
};
