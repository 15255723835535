import React, { useEffect, useState } from 'react';
import { AccessPointLikeRoleItem, RoleItemProps } from './types';
import { Checkbox, Col, Row } from 'rsuite';
import { LabeledInlineAutoComplete, RsField } from '../../../../../../../shared-components/rsuite';
import { BandSelect } from './BandSelect';
import {
  BandHeadingWrapper,
  RfArchitectureCard,
  RfArchitectureError,
  RfArchitectureWrapper,
  WrapperCard,
} from './styled-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RemoveRoleButton } from './RemoveRoleButton';
import { getDefaultRFBands, getDefaultStreamNumber } from '../../../../../../../helpers/constants';
import {
  addRfBand,
  AppFlows,
  AppFlowSteps,
  ApplicationFlowState,
  ComponentCombinationSource,
  removeRfBand,
  setCompareCCInfo,
  updateApplicationProperty,
} from '../../../../../../../redux/modules/application-flow';
import { Confirm } from '../../../../../../../helpers/confirmationPopup/Confirm';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { MemberClearData } from '../../../../../../../redux/modules/application-flow/data';
import { WrapWithPublicIcon } from '../../../../../../../shared-components/helper-components/WrapWithPublicIcon';
import { Tooltip } from '../../../../../../../shared-components/tooltip/Tooltip';
import styled from 'styled-components';

const Label = styled.span`
  font-weight: normal;
`;

interface Props extends RoleItemProps {
  roleItem: AccessPointLikeRoleItem;
}

interface RFState {
  [x: string]: {
    rx: number;
    tx: number;
  };
}

const APLikeRoleComponent = ({ roleItem, prefix, onChange, onRemove, onFocus, ccLength, errors, showPopUp }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { compareCCInfo, certifications, ...application } = useSelector<RootReducer, ApplicationFlowState>(
    state => state.applicationFlow,
  );

  const rfItem = roleItem.core.rfArchitecture;

  const warningRequired =
    application['@type'] === AppFlows.VARIANT ||
    application.componentCombination['@type'] === ComponentCombinationSource.LOADED;

  const getRFDefaultValue = (band: string) => {
    const itemData = rfItem.find(item => item.band === band);
    if (itemData) {
      return {
        rx: itemData.rx,
        tx: itemData.tx,
      };
    }
    return {
      rx: 1,
      tx: 1,
    };
  };

  const defaultRFBands = getDefaultRFBands(roleItem.roleName);

  const getStateValue = () =>
    defaultRFBands.reduce((prev, curr) => {
      prev[curr] = getRFDefaultValue(curr);
      return prev;
    }, Object.create(null));

  const [rf, setRf] = useState<RFState>(getStateValue());

  const isBandChecked = (band: string) => rfItem.some(item => item.band === band);
  const bandChecked = defaultRFBands.reduce((prev, curr) => {
    prev[curr] = isBandChecked(curr);
    return prev;
  }, Object.create(null));

  const changeRfProperty = (band: string, property: string, value: number) => {
    const index = rfItem.findIndex(item => item.band === band);
    setRf(prevState => ({ ...prevState, [band]: { ...prevState[band], [property]: value } }));
    dispatch(
      updateApplicationProperty(
        value,
        `${prefix}.core.rfArchitecture[${index}].${property}`,
        certifications.length > 0 ? MemberClearData.CERTIFICATIONS : MemberClearData.NONE,
        AppFlowSteps.CC,
      ),
    );
  };

  const handleRfChanges = (band: string, property: string, value: number) => {
    const name = `${prefix}.core.rfArchitecture`;
    if (warningRequired && !compareCCInfo.showVariantWarning.includes(name)) {
      return Confirm({
        title: t('applications.cc.variantFlow.title', {
          addition: 'RfArchitecture',
        }),
        message: t('applications.cc.variantFlow.rfArchitecture'),
        onAccept: () => {
          changeRfProperty(band, property, value);
          dispatch(setCompareCCInfo({ showVariantWarning: [...compareCCInfo.showVariantWarning, name] }));
        },
      });
    }
    if (showPopUp.current) {
      return Confirm({
        title: t('applications.cc.warning.title'),
        message: t('applications.cc.warning.message', {
          addition: t('applications.cc.warning.additions.certsAndLabs'),
        }),
        onAccept: () => {
          showPopUp.current = false;
          changeRfProperty(band, property, value);
        },
      });
    }
    return changeRfProperty(band, property, value);
  };

  const changeRFCheckBox = (band: string, checked: boolean) => {
    if (checked) {
      dispatch(addRfBand({ band, ...rf[band] }, roleItem.roleName, roleItem.index));
    } else {
      const index = rfItem.findIndex(item => item.band === band);
      dispatch(removeRfBand(`${prefix}.core.rfArchitecture[${index}]`));
    }
  };

  const handleRfCheck = (band: string, checked: boolean) => {
    const name = `${prefix}.core.rfArchitecture`;
    if (warningRequired && !compareCCInfo.showVariantWarning.includes(name)) {
      return Confirm({
        title: t('applications.cc.variantFlow.title', {
          addition: 'RfArchitecture',
        }),
        message: t('applications.cc.variantFlow.rfArchitecture'),
        onAccept: () => {
          changeRFCheckBox(band, checked);
          dispatch(setCompareCCInfo({ showVariantWarning: [...compareCCInfo.showVariantWarning, name] }));
        },
      });
    }
    if (showPopUp.current) {
      return Confirm({
        title: t('applications.cc.warning.title'),
        message: t('applications.cc.warning.message', {
          addition: t('applications.cc.warning.additions.certsAndLabs'),
        }),
        onAccept: () => {
          showPopUp.current = false;
          changeRFCheckBox(band, checked);
        },
      });
    }
    return changeRFCheckBox(band, checked);
  };

  useEffect(() => {
    const newRf = getStateValue();
    if (!shallowEqual(newRf, rf)) {
      setRf(newRf);
    }
  }, [roleItem]);

  useEffect(() => {
    const rfBands = getDefaultRFBands(roleItem.roleName);
    rfItem.forEach((rf, index) => {
      const bandIndex = rfBands.findIndex(item => item === rf.band);
      if (bandIndex === -1) {
        dispatch(removeRfBand(`${prefix}.core.rfArchitecture[${index}]`));
      }
    });
  }, [roleItem.roleName]);

  return (
    <WrapperCard>
      <Row gutter={20}>
        <Col xs={12}>
          <LabeledInlineAutoComplete
            name={`${prefix}.core.chipset`}
            label={
              <>
                {t('applications.cc.chipset')}
                <Tooltip translationKey="tooltips.chipset" />
              </>
            }
            required
            onChange={(value: string) => onChange(value, `${prefix}.core.chipset`)}
            url="/elemental-components/chipset/autocomplete"
            placeholder={t('common.placeholders.autoComplete')}
            value={roleItem.core.chipset}
            errors={errors}
            onFocus={onFocus}
            autoComplete="off"
          />
        </Col>
        <Col xs={12}>
          <LabeledInlineAutoComplete
            name={`${prefix}.os`}
            label={
              <Label>
                <WrapWithPublicIcon content={t('applications.cc.componentOS')} />
                <Tooltip translationKey="tooltips.componentOS" />
              </Label>
            }
            required
            onChange={(value: string) => onChange(value, `${prefix}.os`)}
            url="/elemental-components/os/autocomplete"
            placeholder={t('common.placeholders.autoComplete')}
            value={roleItem.os}
            errors={errors}
            onFocus={onFocus}
            autoComplete="off"
          />
        </Col>
        <Col xs={12}>
          <LabeledInlineAutoComplete
            name={`${prefix}.firmware`}
            label={
              <>
                <WrapWithPublicIcon content={t('applications.cc.firmware')} />
                <Tooltip translationKey="tooltips.firmware" />
              </>
            }
            required
            onChange={(value: string) => onChange(value, `${prefix}.firmware`)}
            url="/elemental-components/firmware/autocomplete"
            placeholder={t('common.placeholders.autoComplete')}
            value={roleItem.firmware}
            errors={errors}
            onFocus={onFocus}
            autoComplete="off"
          />
        </Col>
        <Col xs={12}>
          <LabeledInlineAutoComplete
            name={`${prefix}.physicalInterface`}
            label={
              <Label>
                {t('applications.cc.interface')}
                <Tooltip translationKey="tooltips.interface" />
              </Label>
            }
            required
            onChange={(value: string) => onChange(value, `${prefix}.physicalInterface`)}
            url="/elemental-components/physicalInterface/autocomplete"
            placeholder={t('common.placeholders.autoComplete')}
            value={roleItem.physicalInterface}
            errors={errors}
            onFocus={onFocus}
            autoComplete="off"
          />
        </Col>
        <Col xs={12}>
          <LabeledInlineAutoComplete
            name={`${prefix}.core.driver`}
            label={
              <>
                {t('applications.cc.driver')}
                <Tooltip translationKey="tooltips.driver" />
              </>
            }
            required
            onChange={(value: string) => onChange(value, `${prefix}.core.driver`)}
            url="/elemental-components/driver/autocomplete"
            placeholder={t('common.placeholders.autoComplete')}
            value={roleItem.core.driver}
            errors={errors}
            onFocus={onFocus}
            autoComplete="off"
          />
        </Col>
        <Col xs={12}>
          <LabeledInlineAutoComplete
            name={`${prefix}.rfComponents`}
            label={
              <Label>
                {t('applications.cc.rfComponents')}
                <Tooltip translationKey="tooltips.rfComponents" />
              </Label>
            }
            required
            onChange={(value: string) => onChange(value, `${prefix}.rfComponents`)}
            url="/elemental-components/rfComponents/autocomplete"
            placeholder={t('common.placeholders.autoComplete')}
            value={roleItem.rfComponents}
            errors={errors}
            onFocus={onFocus}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={12}>
          <RfArchitectureWrapper>
            <label className="rs-control-label">
              <span className="text-danger">*</span>{' '}
              <WrapWithPublicIcon content={t('applications.cc.rfArchitecture')} />
              <Tooltip translationKey="tooltips.rfArchitecture" />
            </label>
            <RfArchitectureCard type="light" className="with-border">
              <BandHeadingWrapper className="mb-1">
                <span className="rs-control-label">{t('applications.cc.rf.bands')}</span>
                <span className="rs-control-label">{t('applications.cc.rf.tx')}</span>
                <span className="rs-control-label">{t('applications.cc.rf.rx')}</span>
              </BandHeadingWrapper>
              {defaultRFBands.map((band, key) => (
                <Row gutter={5} key={key}>
                  <Col xs={8}>
                    <Checkbox
                      className={key !== 2 ? 'mb-1' : ''}
                      onChange={(value: string, checked: boolean) => handleRfCheck(band, checked)}
                      value={band}
                      checked={bandChecked[band]}
                    >
                      {band}
                    </Checkbox>
                  </Col>
                  <Col xs={8}>
                    <RsField
                      noPadding
                      className={key !== defaultRFBands.length - 1 ? 'mb-1' : ''}
                      accepter={BandSelect}
                      onChange={(value: number) => handleRfChanges(band, 'tx', value)}
                      name={`tx_${key}`}
                      value={rf[band].tx}
                      disabled={!bandChecked[band]}
                      streamNumber={getDefaultStreamNumber(band)}
                    >
                      {band}
                    </RsField>
                  </Col>
                  <Col xs={8}>
                    <RsField
                      noPadding
                      className={key !== 2 ? 'mb-1' : ''}
                      accepter={BandSelect}
                      onChange={(value: number) => handleRfChanges(band, 'rx', value)}
                      name={`rx_${key}`}
                      value={rf[band].rx}
                      disabled={!bandChecked[band]}
                      streamNumber={getDefaultStreamNumber(band)}
                    >
                      {band}
                    </RsField>
                  </Col>
                </Row>
              ))}
            </RfArchitectureCard>
            <RfArchitectureError>
              <small className="text-danger">{errors && errors[`${prefix}.core.rfArchitecture`]}</small>
            </RfArchitectureError>
          </RfArchitectureWrapper>
        </Col>
        <Col xs={12}>
          <Row gutter={20}>
            <Col span="24">
              <LabeledInlineAutoComplete
                name={`${prefix}.antenna`}
                label={
                  <Label>
                    {t('applications.cc.antenna')}
                    <Tooltip translationKey="tooltips.antenna" />
                  </Label>
                }
                required
                onChange={(value: string) => onChange(value, `${prefix}.antenna`)}
                url="/elemental-components/antenna/autocomplete"
                placeholder={t('common.placeholders.autoComplete')}
                value={roleItem.antenna}
                errors={errors}
                onFocus={onFocus}
                autoComplete="off"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {ccLength > 1 ? <RemoveRoleButton onClick={() => onRemove(roleItem.roleName, roleItem.index)} /> : null}
    </WrapperCard>
  );
};

export const APLikeRoleItem = React.memo(APLikeRoleComponent, (prev, next) => {
  const isEqualRole = shallowEqual(prev.roleItem, next.roleItem);
  const isEqualError = shallowEqual(prev.errors, next.errors);
  return prev.showPopUp === next.showPopUp && prev.ccLength === next.ccLength && isEqualRole && isEqualError;
});
