import { OtherRolesProps } from '../../types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RolesState } from '../../../../../redux/modules/roles/reducer';
import { Role } from '../../../../../redux/modules/roles/types';
import { DetailsItem } from '../../../../partials';
import React from 'react';
import { RfViewComponent } from './RfViewComponent';

export const DefaultRolesRows = ({ record, roleProperty }: { record: OtherRolesProps; roleProperty: string }) => {
  const { t } = useTranslation();
  const roles = useSelector<{ rolesReducer: RolesState }, Role[]>(state => state.rolesReducer.roles);
  const roleName = roles.find(item => item.propertyName === roleProperty)?.name;

  return (
    <div className="mb-2">
      <p className="mb-1">
        <strong>{t('applications.cc.role')}:</strong> {roleName}
      </p>
      <DetailsItem label={t('applications.cc.chipset')} value={record.core.chipset} />
      <DetailsItem label={t('applications.cc.firmware')} value={record.firmware} />
      <DetailsItem label={t('applications.cc.driver')} value={record.core.driver} />
      <RfViewComponent record={record} />
      <DetailsItem label={t('applications.cc.componentOS')} value={record.os} />
      <DetailsItem label={t('applications.cc.interface')} value={record.physicalInterface} />
      <DetailsItem label={t('applications.cc.rfComponents')} value={record.rfComponents} className="mb-1" />
      <DetailsItem label={t('applications.cc.antenna')} value={record.antenna} className="mb-1" />
    </div>
  );
};
