import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Checkbox, Popover, Whisper } from 'rsuite';
import { ReformattedCertTableRow } from '../CertificationsStep';

interface Props {
  row: ReformattedCertTableRow;
  disabled: boolean;
  checked: boolean;
  handleChange: (row: ReformattedCertTableRow, checked: boolean) => void;
}

export const CheckboxCell = ({ row, disabled, checked, handleChange, children }: PropsWithChildren<Props>) => {
  const checkboxRef = useRef();
  const [cellWidth, setCellWidth] = useState(0);
  useEffect(() => {
    if (checkboxRef.current) {
      // @ts-ignore
      const label = checkboxRef.current.closest('label');
      // @ts-ignore
      const { offsetWidth } = checkboxRef.current.closest('.rs-table-cell');
      if (label.offsetWidth > offsetWidth - 45) {
        setCellWidth(offsetWidth);
      } else {
        setCellWidth(0);
      }
    }
  }, [checkboxRef.current]);
  const hasEllipsis = cellWidth > 0;
  return (
    <Checkbox
      onChange={(value: string, checked: boolean) => handleChange(row, checked)}
      className="qualifications-checkbox"
      checked={checked}
      disabled={disabled}
      inputRef={checkboxRef}
    >
      <span
        style={
          hasEllipsis
            ? { width: cellWidth - 37, display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis' }
            : {}
        }
      >
        {hasEllipsis ? (
          <Whisper
            placement="topStart"
            speaker={<Popover style={{ maxWidth: window.innerWidth / 3, wordBreak: 'break-word' }}>{children}</Popover>}
          >
            <span className="inner">{children}</span>
          </Whisper>
        ) : (
          children
        )}
      </span>
    </Checkbox>
  );
};
