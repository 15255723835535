export const getDefaultRFBands = (roleName: string): string[] => {
  switch (roleName) {
    case 'accessPoint':
    case 'station':
      return ['Sub-1 GHz', '2.4 GHz', '5 GHz', '6 GHz', '60 GHz'];
    default:
      return ['2.4 GHz', '5 GHz', '60 GHz'];
  }
};

export const getDefaultStreamNumber = (bandName: string) => {
  switch (bandName) {
    case 'Sub-1 GHz':
      return 4;
    default:
      return 8;
  }
};
