import { makeStyles } from '@material-ui/core/styles';

// @ts-ignore
import _var from '../../styles/_variables.scss';

export const useStyles = makeStyles({
  outlinedInput: {
    '&.MuiOutlinedInput-root': {
      height: 32,
      fontSize: 14,
      paddingRight: 12,
    },
    '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: _var.grayBorder,
    },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      transition: 'border-color ease-in-out 0.3s',
      borderColor: _var.primary,
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      transition: 'border-color ease-in-out 0.3s',
      borderColor: _var.primary,
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        color: 'rgb(21, 21, 21)',
      },
    },
  },
  autocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      height: 32,
      fontSize: 14,
      padding: '0px 4px',
      flexWrap: 'nowrap',
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: _var.bodyTextGray,
    },
    '& .MuiAutocomplete-popupIndicator:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: _var.bodyTextGray,
      marginRight: -4,
    },
    '& .MuiAutocomplete-clearIndicator:hover': {
      backgroundColor: 'transparent',
    },
  },
  autocompleteWithValue: {
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
    },
  },
  autocompleteInput: {
    '& .MuiInputBase-input': {
      padding: '8px 0px 8px 8px !important',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: _var.grayBorder,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      transition: 'border-color ease-in-out 0.3s',
      borderColor: _var.primary,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      transition: 'border-color ease-in-out 0.3s',
      borderColor: _var.primary,
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        color: 'rgb(21, 21, 21)',
      },
    },
  },
  autocompleteNoOptions: {
    fontSize: 12,
  },
  autocompleteOption: {
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    '&[data-focus="true"]': {
      backgroundColor: _var.secondary,
    },
    '&[aria-selected="true"]': {
      fontWeight: 'bold',
      color: _var.primary,
      backgroundColor: _var.secondary,
    },
  },
  autocompleteTagChip: {
    '&.MuiChip-root': {
      fontSize: 12,
      height: 20,
      marginRight: 2,
    },
    '& .MuiChip-label': {
      paddingRight: 12,
      paddingLeft: 8,
      maxWidth: 150,
    },
  },
  autocompleteTagChipIcon: {
    '&.MuiSvgIcon-root': {
      width: 14,
      height: 14,
      color: 'rgb(51, 51, 51)',
    },
  },
  autocompleteIcon: {
    fontSize: 18,
  },
  autocompleteCheckbox: {
    '&.MuiCheckbox-root': {
      padding: '0px 9px 0px 0px',
    },
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      padding: '0px 9px',
    },
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
  adornmentIconButton: {
    '&.MuiIconButton-root': {
      padding: 0,
      '&:hover': {
        color: _var.primary,
        backgroundColor: 'transparent',
      },
    },
  },
  adornmentIcon: {
    '&.MuiSvgIcon-root': {
      fontSize: 18,
      color: _var.bodyTextGray,
    },
  },
  filterButton: {
    '&.MuiButton-outlined': {
      padding: 0,
      minWidth: 32,
      width: 32,
      height: 32,
      border: `1px solid ${_var.primary}`,
    },
    '&.MuiButton-outlined:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${_var.primaryDisabled}`,
    },
  },
  filterButtonWithFocus: {
    '&.MuiButton-outlined': {
      border: `1px solid ${_var.primary}`,
    },
  },
  filterButtonIcon: {
    '&.MuiSvgIcon-root': {
      padding: 3,
      color: _var.primary,
    },
    '&.MuiSvgIcon-root:hover': {
      color: _var.primaryDisabled,
    },
  },
  filterButtonIconWithFocus: {
    '&.MuiSvgIcon-root': {
      color: _var.primary,
    },
  },
  clearAllButton: {
    '&.MuiButton-root': {
      textTransform: 'inherit',
      fontSize: 14,
      fontWeight: 700,
      borderWidth: 2,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  selectedChip: {
    '&.MuiChip-root': {
      fontSize: 12,
      marginRight: 10,
      marginBottom: 5,
      height: 24,
      color: 'rgb(51, 51, 51)',
      background: 'rgb(250, 250, 250)',
      borderRadius: 4,
    },
    '& .MuiChip-label': {
      paddingRight: 12,
      paddingLeft: 8,
    },
  },
  selectedChipIcon: {
    '&.MuiSvgIcon-root': {
      width: 14,
      height: 14,
      color: 'rgb(51, 51, 51)',
    },
  },
  popupConditionTitle: {
    fontWeight: 600,
    color: 'rgba(51, 51, 51, 0.4)',
  },
  dateRangeWrapper: {
    display: 'flex',
    border: `1px solid ${_var.grayBorder}`,
    padding: '2px 11px',
    height: 32,
    borderRadius: 4,
    justifyContent: 'center',
    '&:hover': {
      transition: 'border-color ease-in-out 0.3s',
      borderColor: _var.primary,
    },
  },
  dateRangeLabel: {
    display: 'flex',
    flex: 1,
    padding: '0px 2px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  dateRangeLabelPlaceholder: {
    color: 'rgba(21, 21, 21, 0.4)',
  },
});
