import React from 'react';
import { useTranslation } from 'react-i18next';
import { getApplicationHistory } from '../../../api/application';
import { getEventHistoryPageComponent } from '../../hoc/get-event-history-page-component';

const ApplicationHistoryPage = () => {
  const { t } = useTranslation();

  const ApplicationHistoryPage = getEventHistoryPageComponent(t('applications.history.title'), getApplicationHistory);

  return <ApplicationHistoryPage />;
};

export default ApplicationHistoryPage;
