import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Radio } from 'rsuite';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import { ErrorObject } from '../../../helpers/types';
import { prepareYupModel } from '../../../helpers';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { RootReducer } from '../../../redux/rootReducer';
import { ModalsReducerState } from '../../../redux/modules/modals/reducer';
import { hideRevokeNoteModal } from '../../../redux/modules/modals';
import { RsCenterModal } from '../../../shared-components/rsuite';
import { LabeledRadioGroup, LabeledInput, LabeledTextArea } from '../../../shared-components/labeled-inputs';
import { RevokeActionTypes } from '../../../redux/modules/modals/constants';
import { closeWindow } from '../../../helpers';

// @ts-ignore
import _var from '../../../styles/_variables.scss';

const useStyles = makeStyles(() => ({
  modalBody: {
    marginTop: '26px',
    paddingBottom: 0,
  },
  cidContainer: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  idContent: {
    color: _var.primary,
    marginLeft: '5px',
  },
  appIdContainer: {
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  legendContainer: {
    paddingBottom: '16px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.05)',
  },
  reasonContainer: {
    marginBottom: 0,
    paddingBottom: '12px',
  },
}));

const OTHER_REASON_MAX_LENGTH = 60;
const COMMENT_MAX_LENGTH = 100;
interface RevokeObject {
  reasonType: string;
  otherReason: string;
  comment: string;
}

export const RevokeModal = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const { revokeModal } = useSelector<RootReducer, ModalsReducerState>(state => state.modalsReducer);
  const [revokeObject, setRevokeObject] = useState<RevokeObject>({
    reasonType: '',
    otherReason: '',
    comment: '',
  });
  const [errors, setErrors] = useState<ErrorObject>({});

  const validateData = (revokeObject: RevokeObject) => {
    const noteErrors = prepareYupModel<Partial<RevokeObject>>(
      Yup.object().shape({
        reasonType: Yup.string()
          .trim()
          .required(t('common.validation.required')),
        otherReason: Yup.mixed()
          .test('is-required-otherReason', t('common.validation.required'), function(value) {
            const isRequired = revokeObject.reasonType === t('revokes.revokeApplication.reason.other');
            return !(isRequired && value.trim().length === 0);
          })
          .test(
            'exceed-max-length-otherReason',
            t('common.validation.length', { length: OTHER_REASON_MAX_LENGTH }),
            function(value) {
              const isRequired = revokeObject.reasonType === t('revokes.revokeApplication.reason.other');
              return !(isRequired && value.trim().length > OTHER_REASON_MAX_LENGTH);
            },
          ),
        comment: Yup.string()
          .trim()
          .max(COMMENT_MAX_LENGTH, t('common.validation.length', { length: COMMENT_MAX_LENGTH }))
          .required(t('common.validation.required')),
      }),
    ).checkFormatted(revokeObject);
    setErrors(noteErrors as ErrorObject);
    return Boolean(noteErrors !== null);
  };

  const handleReasonType = (value: string) => {
    setRevokeObject(previous => {
      const newRevokeObject = {
        ...previous,
        reasonType: value,
        otherReason: value === t('revokes.revokeApplication.reason.other') ? previous.otherReason : '',
      };
      isValidated && validateData(newRevokeObject);
      return newRevokeObject;
    });
  };

  const handleOtherReason = (value: string) => {
    setRevokeObject(previous => {
      const newRevokeObject = { ...previous, otherReason: value };
      isValidated && validateData(newRevokeObject);
      return newRevokeObject;
    });
  };

  const handleComment = (value: string) => {
    setRevokeObject(previous => {
      const newRevokeObject = { ...previous, comment: value };
      isValidated && validateData(newRevokeObject);
      return newRevokeObject;
    });
  };

  const onClose = () => {
    dispatch(hideRevokeNoteModal(revokeModal.revokeModalType));
  };

  const onSubmit = () => {
    !isValidated && setIsValidated(true);
    if (validateData(revokeObject)) {
      return;
    }
    setLoading(true);
    return revokeModal
      .callApi({
        id:
          revokeModal.revokeModalType === RevokeActionTypes.REVOKE_PRODUCT
            ? (revokeModal.productId as number)
            : (revokeModal.appId as number),
        comment: revokeObject.comment,
        reason:
          revokeObject.reasonType === t('revokes.revokeApplication.reason.other')
            ? revokeObject.otherReason
            : revokeObject.reasonType,
      })
      .then(() => {
        addToast(t('revokes.revokeSuccessMessage'), {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000,
          onDismiss: () => {
            if (revokeModal.revokeModalType === RevokeActionTypes.REVOKE_PRODUCT && window.opener) {
              window.opener.location.reload();
              setTimeout(() => {
                closeWindow();
              }, 200);
            } else {
              window.location.reload();
            }
          },
        });
        onClose();
      })
      .catch(error => {
        handleRequestFail(error, addToast);
        onClose();
      });
  };

  const handleAfterClosed = () => {
    setErrors({});
    setLoading(false);
    setIsValidated(false);
    setRevokeObject({ reasonType: '', otherReason: '', comment: '' });
  };

  return (
    <RsCenterModal
      backdrop={loading ? 'static' : true}
      show={revokeModal.appId !== null || revokeModal.productId !== null}
      onHide={onClose}
      onExited={handleAfterClosed}
    >
      <Modal.Header closeButton={!loading}>
        <Modal.Title>{revokeModal.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'hidden' }} className={classes.modalBody}>
        <div className={classes.cidContainer}>
          {t('revokes.fields.cid')}:<span className={classes.idContent}>{revokeModal.cid}</span>
        </div>
        {revokeModal.appId && (
          <div className={classes.appIdContainer}>
            {t('revokes.fields.appId')}:<span className={classes.idContent}>{revokeModal.appId}</span>
          </div>
        )}
        {revokeModal.productId && (
          <div className={classes.appIdContainer}>
            {t('revokes.fields.totalVariants')}:<span className={classes.idContent}>{revokeModal.totalVariants}</span>
          </div>
        )}
        <div className={classes.legendContainer}>{revokeModal.legend}</div>

        <LabeledRadioGroup
          required
          name="reasonType"
          errors={errors}
          label={revokeModal.reasonTitle}
          value={revokeObject.reasonType}
          className={classes.reasonContainer}
          onChange={handleReasonType}
        >
          {revokeModal.reasons?.map(reason => (
            <Radio value={reason} key={reason}>
              {reason}
            </Radio>
          ))}
        </LabeledRadioGroup>
        {revokeObject.reasonType === t('revokes.revokeApplication.reason.other') && (
          <LabeledInput
            name="otherReason"
            placeholder={t('revokes.fields.otherPlaceholder')}
            errors={errors}
            value={revokeObject.otherReason}
            onChange={handleOtherReason}
            showCharacterNumber={true}
            maxLength={OTHER_REASON_MAX_LENGTH}
          />
        )}

        <LabeledTextArea
          required
          label={t('revokes.fields.comment')}
          name="comment"
          placeholder={t('revokes.fields.commentPlaceholder')}
          errors={errors}
          value={revokeObject.comment}
          onChange={handleComment}
          showCharacterNumber={true}
          maxLength={COMMENT_MAX_LENGTH}
          className="mb-1"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="link" onClick={onClose} disabled={loading}>
          {t('common.navigation.cancel')}
        </Button>
        <Button appearance="primary" id="add-note" onClick={onSubmit} loading={loading}>
          {t('common.navigation.submit')}
        </Button>
      </Modal.Footer>
    </RsCenterModal>
  );
};
