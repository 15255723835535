import React, { useEffect, useState } from 'react';
import { Button, Icon, Table } from 'rsuite';
import { renderTreeToggleIconFunction, RsColumn, RsTable } from '../../../../shared-components/theme';
import styled from 'styled-components';
import { LabRequest, LRCertification } from '../types';
import { useTranslation } from 'react-i18next';
import { SortingOptions, SortType } from '../../test-case/types';
import { jsonCopy } from '../../../../helpers';
import { sortByProperty } from '../../../../helpers/sort-by-property';
import { TestingException } from '../../../../redux/modules/application-flow';
import { buildDownloadUrl } from '../../../../helpers/build-download-url';
import { ExceptionsPanel } from '../../../partials/ExceptionsPanel';
import { customIconsSet } from '../../../../helpers/constants';
import PreviouslyCertifiedLegend from '../../../../shared-components/styled-components/PreviouslyCertifiedLegend';
import { downloadApplicationProfileHandler } from '../../../../api/lab-request/helper/downloadApplicationProfileHandler';
import { useToasts } from 'react-toast-notifications';
import { notEmpty } from '../../../../helpers/not-empty';
import { ExceptionTypes } from '../../../../components/pages/application/types';

const { HeaderCell, Cell } = Table;

export const QualifiedToTest = styled.div`
  width: 20px;
  height: 20px;
  background: #667687;
  border-radius: 1rem;
  margin: 0 auto;
`;

const StyledDownloadButtonWrapper = styled.div`
  text-align: right;
  margin-top: 20px;
`;

interface CertificationRow extends LRCertification {
  children: { name: string }[];
}

interface Props {
  certifications: LRCertification[];
  labRequest: LabRequest;
}

export const ReviewCertificationsStep = ({ certifications, labRequest }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [appProfileDownloading, setAppProfileDownloading] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [sorting, setSorting] = useState<SortingOptions>({
    sortColumn: 'name',
    sortType: 'asc',
  });

  const handleSortChange = (sortColumn: string, sortType: SortType) => {
    setSorting({ sortColumn, sortType });
  };

  const formattedCertifications: CertificationRow[] = certifications
    .map(certification => ({
      ...certification,
      children: certification.capabilities.map(capability => ({
        name: capability.name,
        roleName: certification.roleName,
      })),
    }))
    .sort((a, b) => sortByProperty(a, b, sorting));

  const conformanceTestRequested = formattedCertifications.some(value => value.conformanceTestRequested);

  const handleDownloadAppProfile = () => {
    setAppProfileDownloading(true);
    downloadApplicationProfileHandler(labRequest.id, labRequest.application.id, addToast).then(() => {
      setAppProfileDownloading(false);
    });
  };

  // force render again at first that is workaround for fix RsTable weird row height
  useEffect(() => {
    setSorting({ sortColumn: 'name', sortType: 'asc' });
  }, []);

  const exceptions: TestingException[] = [
    labRequest.application.testingException
      ? { exceptionType: ExceptionTypes.TEST_EXCEPTION, ...labRequest.application.testingException }
      : null,
    labRequest.application.coreCCException
      ? { exceptionType: ExceptionTypes.CC_EXCEPTION, ...labRequest.application.coreCCException }
      : null,
  ].filter(notEmpty);

  return (
    <div>
      <h3>{t('testing.review.title')}</h3>
      <PreviouslyCertifiedLegend>
        <span className="mark" />: {t('applications.certifications.prevCertifiedLegend')}
      </PreviouslyCertifiedLegend>
      <RsTable
        wordWrap
        isTree
        data={jsonCopy(formattedCertifications)}
        rowKey="id"
        onExpandChange={(isOpen, rowData) => {
          setExpandedRows(prevState =>
            isOpen ? [...prevState, rowData.id] : prevState.filter(num => num !== rowData.id),
          );
        }}
        renderTreeToggle={(icon, rowData) =>
          renderTreeToggleIconFunction(expandedRows.includes(rowData.id), rowData.children.length > 0)
        }
        onSortColumn={handleSortChange}
        sortColumn={sorting.sortColumn}
        sortType={sorting.sortType}
        rowClassName={rowData => (rowData?.prequalified ? 'is-precertified' : rowData?.isStriped ? 'is-stripe' : '')}
      >
        {formattedCertifications.some(item => item.children?.length) ? (
          <RsColumn width={40} fixed>
            <HeaderCell />
            <Cell dataKey="id"> </Cell>
          </RsColumn>
        ) : null}
        <RsColumn flexGrow={1} minWidth={250} align="left" fixed sortable>
          <HeaderCell>{t('certifications.title')}</HeaderCell>
          <Cell dataKey="name" />
        </RsColumn>
        <RsColumn width={165} sortable>
          <HeaderCell>{t('certifications.role')}</HeaderCell>
          <Cell dataKey="roleName" />
        </RsColumn>
        <RsColumn width={150} align="center">
          <HeaderCell>{t('applications.labs.conformance')}</HeaderCell>
          <Cell dataKey="conformanceTestRequested">
            {(rowData: LRCertification) => (rowData.conformanceTestRequested ? <QualifiedToTest /> : null)}
          </Cell>
        </RsColumn>
        <RsColumn width={150} align="center">
          <HeaderCell>{t('applications.labs.interoperability')}</HeaderCell>
          <Cell dataKey="interoperabilityTestRequested">
            {(rowData: LRCertification) => (rowData.interoperabilityTestRequested ? <QualifiedToTest /> : null)}
          </Cell>
        </RsColumn>
        <RsColumn width={150} align="center">
          <HeaderCell>{t('testing.review.template')}</HeaderCell>
          <Cell>
            {(rowData: LRCertification) =>
              rowData.interoperabilityTemplate && rowData.uniqueTemplate ? (
                <a href={buildDownloadUrl(rowData.interoperabilityTemplate)} download>
                  <Icon icon={customIconsSet.DOWNLOAD_ICON} size="2x" />
                </a>
              ) : null
            }
          </Cell>
        </RsColumn>
        <RsColumn width={170} align="center">
          <HeaderCell>{t('applications.certifications.version')}</HeaderCell>
          <Cell dataKey="versionName" />
        </RsColumn>
      </RsTable>
      <StyledDownloadButtonWrapper>
        <Button
          appearance="primary"
          loading={appProfileDownloading}
          disabled={!conformanceTestRequested || appProfileDownloading}
          className="download-application-profile"
          onClick={handleDownloadAppProfile}
        >
          {t('labRequest.downloadApplicationProfile')}
        </Button>
      </StyledDownloadButtonWrapper>
      {exceptions ? (
        <>
          <h3 className="my-1">{t('testing.review.exceptionsRequested')}</h3>
          <ExceptionsPanel data={exceptions} />
        </>
      ) : null}
    </div>
  );
};
