import {
  CertificationType,
  ProfileType,
  ProfileTypeTitle,
  TestGroup,
  RunConditionTypes,
} from '../../../components/pages/certification/types';

export const defaultPrerequisiteValue = {
  certification: {
    id: -1,
  },
  version: '',
  revision: '',
};

export const defaultTestGroupValue = {
  name: '',
  condition: {
    '@type': RunConditionTypes.ALWAYS_ON,
  },
  testCriteria: [],
} as TestGroup;

export const profileData = [
  {
    label: ProfileTypeTitle[ProfileType.STANDARD],
    value: ProfileType.STANDARD,
  },
  { label: ProfileTypeTitle[ProfileType.PROFILE], value: ProfileType.PROFILE },
  { label: ProfileTypeTitle[ProfileType.ASD], value: ProfileType.ASD },
];

export const initialState = {
  id: null,
  versionId: null,
  certification: {
    '@type': CertificationType.PRIMARY,
    generalInfo: {
      name: '',
      visibleOnCertificate: null,
      role: {
        id: null,
      },
      classification: null,
      description: '',
      internalComments: '',
    },
    version: {
      version: '',
      visibleOnCertificate: null,
      profile: {
        type: ProfileType.STANDARD,
        name: '',
      },
      ecn: '',
      revision: '',
      certificationStart: '',
      publicLaunch: '',
      firstRetirementPhaseDate: '',
      secondRetirementPhaseDate: '',
      thirdRetirementPhaseDate: '',
      changesSummary: '',
      labNotes: '',
      memberNotes: '',
    },
    capabilities: [],
    prerequisites: [defaultPrerequisiteValue],
    interoperabilityTest: {
      testingRequired: true,
      templateRequired: false,
      testResultsTemplate: null,
    },
    testGroups: [],
  },
  errors: {},
  loadingState: {
    loading: true,
    save: false,
    publishing: false,
  },
  sectionsEditState: {
    generalInfo: false,
    version: false,
    capabilities: false,
    prerequisites: false,
    interoperabilityTest: false,
    conformanceTest: false,
  },
};
