import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Icon, IconButton } from 'rsuite';
import { Confirm } from '../../../../../helpers/confirmationPopup/Confirm';
import { showUpdateQualificationModal } from '../../../../../redux/modules/admin/atl-qualifications';
import { LabSummaryCertificationByGroup } from '../types';

interface QualificationsSummaryTableActionsProps {
  creationMode?: boolean;
  certification: LabSummaryCertificationByGroup;
  deleteQualification: (certification: LabSummaryCertificationByGroup) => void;
  nested: boolean;
}

export const QualificationsSummaryTableActions = (props: QualificationsSummaryTableActionsProps) => {
  const { certification, creationMode = false, deleteQualification, nested } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onEdit = () => dispatch(showUpdateQualificationModal(certification));

  const onDelete = () => {
    return Confirm({
      title: t('common.placeholders.areYouSure'),
      message: t('common.placeholders.cantBeUndone'),
      onAccept: () => dispatch(deleteQualification(certification)),
    });
  };

  const onView = () => certification.version && window.open(`#/certification/${certification.version.id}`);

  return (
    <div className="actions">
      {creationMode && certification.version && (
        <IconButton onClick={onView} icon={<Icon icon="eye" />} appearance="link" />
      )}
      {!creationMode && nested && <IconButton onClick={onEdit} icon={<Icon icon="edit" />} appearance="link" />}
      {!creationMode && <IconButton onClick={onDelete} icon={<Icon icon="trash-o" />} appearance="link" />}
    </div>
  );
};
