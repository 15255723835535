import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Button, Divider } from 'rsuite';

import { confirmAction } from '../../../../helpers';
import {
  addSelectedCertifications,
  loadAvailableCertifications,
  loadLabInfo,
  publishSelectedCertification,
  removeSelectedCertification,
  resetState,
  selectAddedCertifications,
  selectAtlQualificationsState,
} from '../../../../redux/modules/admin/atl-qualifications';
import { CloseButton } from '../../../../shared-components/button/CloseButton';
import { CircularLoader } from '../../../../shared-components/loader/CircularLoader';
import { PageTemplate } from '../../../partials';
import { LabInfoSection } from './components/LabInfoSection';
import { QualificationsInput } from './components/QualificationsInput';
import { QualificationsSummaryTable } from './components/QualificationsSummaryTable';
import { reformatFlatSummaryDataByGroup } from './helpers';
import { LabSummaryCertification, LabSummaryCertificationByGroup } from './types';

export const useStickyStyles = makeStyles({
  pageTemplate: {
    '& .el-card__body': {
      paddingTop: 0,
    },
    '& .page-header': {
      position: 'sticky',
      zIndex: 5,
      top: 64,
      paddingTop: 24,
      background: 'white',
    },
  },
});

export const AtlQualificationsEnablePage = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const labId = match.params.id;
  const classes = useStickyStyles();

  const addedCertifications = reformatFlatSummaryDataByGroup(useSelector(selectAddedCertifications));
  const {
    availableCertifications,
    isLoadingAvailableCertifications,
    isPublishing,
    isLoadingLabInfo,
    labInfo,
  } = useSelector(selectAtlQualificationsState);

  useEffect(() => {
    dispatch(loadAvailableCertifications(labId, addToast));

    return () => {
      dispatch(resetState());
    };
  }, [match]);

  useEffect(() => {
    dispatch(loadLabInfo(labId, addToast));

    return () => {
      dispatch(resetState());
    };
  }, [labId]);

  const confirmationRequired = () => addedCertifications.length > 0;

  const onClose = () => history.push(`/admin/atl-qualifications/${labId}`);

  const onPublish = () => dispatch(publishSelectedCertification([labId], addToast, onClose));

  const addQualification = (
    certifications: LabSummaryCertification[],
    interoperability: boolean,
    conformance: boolean,
  ) => {
    dispatch(
      addSelectedCertifications(
        certifications.map(certification => ({
          certification,
          interoperability,
          conformance,
        })),
      ),
    );
  };

  const deleteQualification = (certification: LabSummaryCertificationByGroup) => {
    if (certification.version) {
      dispatch(removeSelectedCertification([certification.version.id]));
    } else {
      const versionIds = certification.children.map(c => c.version?.id ?? -1);
      dispatch(removeSelectedCertification(versionIds));
    }
  };

  return (
    <PageTemplate
      withBorder
      className={classNames('add-new-qualifications-page', classes.pageTemplate)}
      title={t('admin.qualifications.newQualificationsTitle', { lab: labInfo ? `- ${labInfo?.name}` : '' })}
      actionLeft={<CloseButton onClick={() => confirmAction(confirmationRequired, onClose)} />}
      footerActionLeft={<></>}
      actionRight={
        <div className="btn-wrapper">
          <Button
            appearance="primary"
            className="btn-publish"
            loading={isPublishing}
            disabled={!addedCertifications.length}
            style={{ minWidth: 110 }}
            onClick={() => confirmAction(confirmationRequired, onPublish, 'admin.qualifications.publishWarning')}
          >
            {t('common.actions.apply')}
          </Button>
        </div>
      }
    >
      {(isPublishing || isLoadingLabInfo) && (
        <CircularLoader fullPage backdrop content={t('common.placeholders.loadingData')} />
      )}

      {labInfo && <LabInfoSection data={labInfo} />}

      <Divider style={{ marginBottom: '12px' }} />

      {labId && (
        <div className="mt-2">
          <h4 className="mb-2">{t('admin.qualifications.addNewQualifications')}</h4>
          <QualificationsInput
            onSelect={addQualification}
            certifications={availableCertifications}
            loading={isLoadingAvailableCertifications}
            disabledCertifications={addedCertifications}
          />
        </div>
      )}

      <div className="mt-3">
        <h4 className="mb-2">{t('admin.qualifications.addedQualifications')}</h4>
        <QualificationsSummaryTable creationMode deleteQualification={deleteQualification} data={addedCertifications} />
      </div>
    </PageTemplate>
  );
};
