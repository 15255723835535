import React, { ChangeEvent, useEffect, useState } from 'react';
// import { Col, Panel, Radio, Row } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
// import visaLogo from '../../../../../assets/credit-cards/visa.svg';
// import discoverLogo from '../../../../../assets/credit-cards/discover.svg';
// import mastercardLogo from '../../../../../assets/credit-cards/mastercard.svg';
// import amexLogo from '../../../../../assets/credit-cards/american-express.svg';
// import styled from 'styled-components';
// import classNames from 'classnames';
// import { getCardNameByNumber } from '../../../../../helpers/credit-card';
// import { countriesList } from '../../../../../helpers/constants';
// import { FormInstance } from 'rsuite/lib/Form';
import {
  AdditionalInfo,
  AppFlows,
  ApplicationFlowState,
  setFeesResponse,
  updateApplicationProperty,
  updateValidationErrors,
} from '../../../../../redux/modules/application-flow';
// import { RsControlLabel, RsField } from '../../../../../shared-components/rsuite';
import { useTranslation } from 'react-i18next';
import { LegalContentCheckbox } from './partials';
import { SupportingFilesType } from '../../../../../helpers/types';
import { RootReducer } from '../../../../../redux/rootReducer';
import { LabeledInput } from '../../../../../shared-components/labeled-inputs';
import { IsModifiedProps, useChangeDetection } from '../../../../../helpers/hooks/useChangeDetection';
import {
  selectFeesResponse,
  selectInvoiceToOptions,
  selectRequiresPurchaseOrder,
  selectsShowInvoiceToOptions,
} from '../../../../../redux/modules/application-flow/selectors';
import { jsonCopy } from '../../../../../helpers';
import EstimatedFees from '../../../../partials/billing/EstimatedFees';
import { GetFeeResponse, Fee } from '../../../../partials/billing/types';
import { getFeeByAppId } from '../../../../../api/billing/get-fee-by-app-id';
import { handleRequestFail } from '../../../../../helpers/request-fail-handler';
import { AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import classnames from 'classnames';
import { CircularLoader } from '../../../../../shared-components/loader/CircularLoader';

const useStyles = makeStyles({
  billingPanel: {
    backgroundColor: '#f2f2f2',
    padding: '20px 20px 0 20px',
    borderRadius: 6,
  },
  row: {
    display: 'flex',
    marginRight: '-5px',
    marginLeft: '-5px',
  },
  col: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  halfCol: {
    paddingLeft: '5px',
    paddingRight: '5px',
    width: '50%',
  },
  invoiceTo: {
    display: 'flex',
    marginLeft: '5.2rem',
    padding: '1.2rem 0',
    position: 'relative',
    zIndex: 1,
  },
  invoiceLabel: {
    position: 'relative',
    padding: '0 5px 0 0',
    fontWeight: 'bold',
    color: '#252c35',
    lineHeight: '40px',
    margin: 0,
  },
  radioGroup: {
    paddingLeft: '13px',
  },
  feeHolder: {
    marginTop: '16px',
  },
  radioError: { color: '#d0021b' },
});

// const { StringType, NumberType } = Schema.Types;

/*const ImagesSection = styled.section`
  display: flex;
  justify-content: center;
  height: 40px;

  & > img {
    max-height: 40px;
    width: auto;
    opacity: 0.4;

    &:not(:last-of-type) {
      margin-right: 5px;
    }

    &.is-active {
      opacity: 1;
    }
  }
`;

const CardNumberSection = styled.section`
  margin-top: 15px;
`;

const CardAdditionalInfoSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const CardExpirationBlock = styled(FormGroup)`
  display: flex;

  .rs-control-label {
    width: 65px;
  }
  .rs-input {
    width: 60px !important;
  }

  aside {
    display: flex;

    span {
      font-size: 1.4rem;
      padding: 0 5px;
    }
  }
`;

const CVSection = styled(FormGroup)`
  display: flex;

  .rs-control-label {
    width: 60px;
    text-align: right;
    margin-right: 5px;
  }

  .rs-input {
    width: 60px !important;
  }
`;*/

// interface FormValues {
//   firstName: string;
//   lastName: string;
//   address: string;
//   city: string;
//   state: string;
//   country: string;
//   postalCode: string;
//   additionalInfo?: string;
//   cardNumber?: string;
//   cardHolder?: string;
//   expMonth?: string;
//   expYear?: string;
//   cvv?: string;
// }

/*interface FormErrors {
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  additionalInfo?: string;
  cardNumber?: string;
  cardHolder?: string;
  expMonth?: string;
  expYear?: string;
  cvv?: string;
}*/
type Props = IsModifiedProps & {
  setLoading: (value: boolean) => void;
};

const calculateHashValue = (info: AdditionalInfo) => JSON.stringify(jsonCopy(info));

export const PaymentStep = ({ setIsModified, setLoading }: Props) => {
  const { t } = useTranslation();
  const { errors, legacy, additionalInfo, ...application } = useSelector<RootReducer, ApplicationFlowState>(
    state => state.applicationFlow,
  );
  const dispatch = useDispatch();
  const requiresPurchaseOrder = useSelector(selectRequiresPurchaseOrder);
  const showInvoiceTo = useSelector(selectsShowInvoiceToOptions);
  const invoiceToOptions = useSelector(selectInvoiceToOptions);
  const { addToast } = useToasts();
  const fees = useSelector(selectFeesResponse);
  const classes = useStyles();
  const [loadingFees, setLoadingFees] = useState(true);

  useChangeDetection(setIsModified, calculateHashValue, additionalInfo);

  /*const [formValues, setFormValues] = useState<FormValues>({
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      additionalInfo: '',
      cardNumber: '',
      expMonth: '',
      expYear: '',
      cvv: '',
    });


      const [isProvider, setProvideCheckbox] = useState(false);
      const [cardHolder, setCardHolder] = useState<string>('');
      const [expirationMonth, setExpirationMonth] = useState<string>('');
      const [expirationYear, setExpirationYear] = useState<string>('');
      const [cvv, setCVV] = useState<string>('');
      const formRef = useRef<FormInstance<FormValues>>(null);
      const [formErrors, setFormErrors] = useState<FormErrors>({});
      const [paymentType, setPaymentType] = useState<'INVOICE' | 'CARD' | 'COMPANY_INVOICE'>('INVOICE');
      const [cardName, setCardName] = useState<string | null>(null);
      const [cardNumber, setCardNumber] = useState<string>('');

      const billingAddressFormKeys = ['firstName', 'lastName', 'address', 'city', 'state', 'country', 'postalCode'];
      const additionalInfoFormKeys = ['additionalInfo'];
      const creditCardFormKeys = ['cardNumber', 'expMonth', 'expYear', 'cvv'];

      const billingAddressValidationSchema = Schema.Model({
        address: StringType().isRequired('Field is required'),
        firstName: StringType().isRequired('Field is required'),
        lastName: StringType().isRequired('Field is required'),
        city: StringType().isRequired('Field is required'),
        country: StringType().isRequired('Field is required'),
        state: StringType().isRequired('Field is required'),
        postalCode: StringType().isRequired('Field is required'),
      });

      const creditCardValidationSchema = Schema.Model({
        cardNumber: NumberType()
          .isRequired('Field is required')
          .isInteger('Must be a valid number'),
        expMonth: NumberType()
          .isRequired('Field is required')
          .addRule((value, data) => {
            if (data.expYear) {
              return isExpirationDateValid(value, data.expYear);
            }
            return true;
          }, 'Expiration date is not valid'),
        expYear: NumberType()
          .isRequired('Field is required')
          .addRule((value, data) => {
            if (data.expMonth) {
              return isExpirationDateValid(data.expMonth, value);
            }
            return true;
          }, 'Expiration date is not valid'),
        cvv: NumberType()
          .isRequired('Field is required')
          .addRule((value, data) => isSecurityCodeValid(data.cardNumber, value), 'Code length is not valid for this card'),
      });

      const additionalInformationValidationSchema = Schema.Model({
        additionalInfo: StringType(),
      });

      const schemas = [billingAddressValidationSchema];
      if (paymentType === 'CARD') {
        schemas.push(creditCardValidationSchema);
      } else if (paymentType === 'INVOICE') {
        schemas.push(additionalInformationValidationSchema);
      }

      // @ts-ignore
      const billingFormValidationSchema = Schema.Model.combine(...schemas);

      const handleCardNumberChange = (value: string) => {
        setCardName(getCardNameByNumber(value));
        setCardNumber(value);
      };

      const getDataForRedux = (values = formValues, type = paymentType) => {
        const keys = [...billingAddressFormKeys];
        if (type === 'CARD') {
          keys.push(...creditCardFormKeys);
        } else if (type === 'INVOICE') {
          keys.push(...additionalInfoFormKeys);
        }
        return keys.reduce(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (prev: any, key: string) => {
            if (values.hasOwnProperty(key)) {
              // @ts-ignore
              prev[key] = values[key];
            }
            return prev;
          },
          { type },
        );
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const handleFormChange = (values: any) => {
        setFormValues(values);
        dispatch(updatePaymentData(getDataForRedux(values)));
      };

      const handlePaymentTypeChange = (value: 'INVOICE' | 'CARD' | 'COMPANY_INVOICE') => {
        setPaymentType(value);
        dispatch(updatePaymentData(getDataForRedux(formValues, value)));
      };*/

  const handleLegacyChange = (checked: boolean, name: string) => dispatch(updateApplicationProperty(checked, name));

  const handleChange = (value: string | number, name: string) => dispatch(updateApplicationProperty(value, name));

  const handleInvoiceToChange = (value: string) => {
    handleChange(parseInt(value), 'additionalInfo.payer.id');
    dispatch(updateValidationErrors({ 'additionalInfo.po': '' }));
  };

  useEffect(() => {
    if (
      application.id &&
      (application.productInfo.certified || application.productInfo.modelVariants[0].draftCertified)
    ) {
      setLoading(true);
      getFeeByAppId(application.id)
        .then((response: AxiosResponse<GetFeeResponse[]>) => {
          const fees = response?.data.reduce<Fee[]>((accu, curr) => {
            return accu.concat(curr.fees);
          }, []);
          if (fees) dispatch(setFeesResponse(fees));
          setLoading(false);
          setLoadingFees(false);
        })
        .catch(error => {
          handleRequestFail(error, addToast);
          setLoading(false);
          setLoadingFees(false);
        });
    } else {
      setLoadingFees(false);
    }
    return () => {
      dispatch(setFeesResponse(null));
    };
  }, []);

  if (loadingFees) {
    return (
      <section className="pt-4">
        <CircularLoader content={t('common.placeholders.loadingData')} />
      </section>
    );
  }

  return (
    <>
      <div className="my-2">
        <div className={classes.billingPanel}>
          <div className={classes.row}>
            <div className={classes.col}>
              <h4>{t('billing.billingInfo')}</h4>
              {!!fees.length && (
                <div className={classes.feeHolder}>
                  <EstimatedFees
                    title={`${t('billing.estimatedLicenseFee')}:`}
                    notes={fees.find(fee => fee?.priceBookEntry?.comment?.length > 0)?.priceBookEntry?.comment}
                    fees={fees.map(fee => {
                      return { estimatedFees: fee.priceBookEntry.price, variant: fee.priceBookEntry.name };
                    })}
                  />
                </div>
              )}
              {showInvoiceTo && !!fees.length && (
                <div className={classes.invoiceTo}>
                  <div className={classes.invoiceLabel}>{t('applications.payment.invoiceTo')}:</div>
                  <RadioGroup
                    className={classes.radioGroup}
                    data-test-id="additionalInfo.payer.id"
                    value={additionalInfo.payer.id}
                    onChange={e => handleInvoiceToChange(e.target.value)}
                  >
                    {invoiceToOptions.map(company => (
                      <FormControlLabel
                        key={company.id}
                        value={company.id}
                        control={
                          <Radio
                            size={'small'}
                            className={classnames(!!errors['additionalInfo.payer.id'] && classes.radioError)}
                          />
                        }
                        label={company.name}
                      />
                    ))}
                    {!!errors['additionalInfo.payer.id'] && (
                      <small className="text-danger">{errors['additionalInfo.payer.id']}</small>
                    )}
                  </RadioGroup>
                </div>
              )}
            </div>
          </div>
          <div className="my-1">
            {t('applications.payment.description', {
              addition:
                application['@type'] === AppFlows.DERIVATIVE ? '' : t('applications.payment.descriptionAddition'),
            })}
          </div>
          <div className={classes.row}>
            <div className={classes.halfCol}>
              <LabeledInput
                key={`purchaseOrder${requiresPurchaseOrder}`}
                name="additionalInfo.po"
                placeholder={
                  requiresPurchaseOrder
                    ? t('applications.payment.PONumber')
                    : t('applications.payment.PONumberOptional')
                }
                defaultValue={additionalInfo.po}
                errors={errors}
                onChange={(value: string, event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(value, event.target.name)
                }
              />
            </div>
            <div className={classes.halfCol}>
              <LabeledInput
                name="additionalInfo.ctia"
                placeholder={t('applications.payment.CTIANumberOptional')}
                defaultValue={additionalInfo.ctia}
                errors={errors}
                onChange={(value: string, event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(value, event.target.name)
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/*<section className="my-2">
        <Checkbox
          checked={isProvider}
          className="mb-1"
          onChange={(value, checked) => setProvideCheckbox(checked)}
          disabled
        >
          {t('applications.payment.applyAsProvider')}
        </Checkbox>
        {!isProvider ? (
          <Panel
            header={
              <b>
                {t('applications.payment.totalFees')} <span className="text-danger">Based on Pricing logic</span>
              </b>
            }
            className="is-dark"
            bordered
          >
            <FormGroup controlId="radioList">
              <RadioGroup name="radioList" value={paymentType} onChange={handlePaymentTypeChange} inline>
                <Radio value="INVOICE">{t('applications.payment.invoice')}</Radio>
                <Radio value="CARD">{t('applications.payment.card')}</Radio>
                <Radio value="COMPANY_INVOICE" disabled>
                  {t('applications.payment.companyInvoice', { company: (user && user.company.brandName) || 'Company' })}
                </Radio>
              </RadioGroup>
            </FormGroup>
            <Form ref={formRef} onChange={handleFormChange} onCheck={setFormErrors} formValue={formValues} fluid>
              <Row className="mt-2" gutter={20}>
                <Col xs={12}>
                  <FormGroup>
                    <RsControlLabel>{t('applications.payment.billingAddress.label')}</RsControlLabel>
                    <Row>
                      <Col xs={12}>
                        <RsField
                          name="firstName"
                          placeholder={t('applications.payment.billingAddress.firstName')}
                          errors={formErrors}
                        />
                      </Col>
                      <Col xs={12}>
                        <RsField
                          name="lastName"
                          placeholder={t('applications.payment.billingAddress.lastName')}
                          errors={formErrors}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <RsField
                          name="address"
                          placeholder={t('applications.payment.billingAddress.address')}
                          errors={formErrors}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <RsField
                          name="city"
                          placeholder={t('applications.payment.billingAddress.city')}
                          errors={formErrors}
                        />
                      </Col>
                      <Col xs={12}>
                        <RsField
                          name="state"
                          placeholder={t('applications.payment.billingAddress.state')}
                          errors={formErrors}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <RsField
                          accepter={SelectPicker}
                          data={countriesList}
                          cleanable={false}
                          placement="topStart"
                          block
                          labelKey="name"
                          valueKey="code"
                          name="country"
                          placeholder={t('applications.payment.billingAddress.country')}
                          errors={formErrors}
                        />
                      </Col>
                      <Col xs={12}>
                        <RsField
                          name="postalCode"
                          placeholder={t('applications.payment.billingAddress.postalCode')}
                          errors={formErrors}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  {paymentType === 'CARD' ? (
                    <>
                      <FormGroup style={{ marginBottom: 0 }}>
                        <RsControlLabel>{t('applications.payment.cardData.label')}</RsControlLabel>
                        <CardNumberSection>
                          <ImagesSection>
                            <img src={visaLogo} className={classNames({ 'is-active': cardName === 'Visa' })} />
                            <img
                              src={mastercardLogo}
                              className={classNames({ 'is-active': cardName === 'Mastercard' })}
                            />
                            <img src={amexLogo} className={classNames({ 'is-active': cardName === 'Amex' })} alt="" />
                            <img src={discoverLogo} className={classNames({ 'is-active': cardName === 'Discover' })} />
                          </ImagesSection>
                          <RsField
                            name="cardNumber"
                            placeholder={t('applications.payment.cardData.number')}
                            value={cardNumber}
                            onChange={handleCardNumberChange}
                            errors={formErrors}
                          />
                        </CardNumberSection>
                      </FormGroup>
                      <RsField
                        name="cardHolder"
                        placeholder={t('applications.payment.cardData.holder')}
                        value={cardHolder}
                        onChange={(value: string) => setCardHolder(value)}
                        errors={formErrors}
                        noMargin
                      />
                      <CardAdditionalInfoSection>
                        <CardExpirationBlock className="m-0">
                          <RsControlLabel>{t('applications.payment.cardData.expDate')}</RsControlLabel>
                          <aside>
                            <RsField
                              name="expMonth"
                              placeholder={t('applications.payment.cardData.expMonth')}
                              value={expirationMonth}
                              onChange={(value: string) => setExpirationMonth(value)}
                              errors={formErrors}
                              noMargin
                            />
                            <span>/</span>
                            <RsField
                              name="expYear"
                              placeholder={t('applications.payment.cardData.expYear')}
                              value={expirationYear}
                              onChange={(value: string) => setExpirationYear(value)}
                              errors={formErrors}
                              noMargin
                            />
                          </aside>
                        </CardExpirationBlock>
                        <CVSection className="m-0">
                          <RsControlLabel>{t('applications.payment.cardData.cvv')}</RsControlLabel>
                          <RsField
                            name="cvv"
                            value={cvv}
                            onChange={(value: string) => setCVV(value)}
                            errors={formErrors}
                            noMargin
                          />
                        </CVSection>
                      </CardAdditionalInfoSection>
                    </>
                  ) : (
                    <FormGroup>
                      <RsControlLabel>{t('applications.payment.POLabel')}</RsControlLabel>
                      <RsField
                        name="additionalInfo"
                        placeholder={t('applications.payment.PONumber')}
                        errors={formErrors}
                      />
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </Form>
          </Panel>
        ) : (
          <Panel className="is-dark" bordered>
            {t('applications.payment.testingOnly')}
          </Panel>
        )}
      </section>*/}
      <section className="mb-2">
        <h4>{t('legalContent.termsAndAgreements.title')}</h4>
        <p className="my-1">{t('legalContent.termsAndAgreements.description')}</p>
        <LegalContentCheckbox
          name="legacy.terms"
          type={SupportingFilesType.ACKNOWLEDGEMENT}
          errors={errors}
          checked={legacy.terms}
          onChange={handleLegacyChange}
          messageKey="legalContent.termsAndAgreements.terms"
        />
        <LegalContentCheckbox
          name="legacy.agreement"
          type={SupportingFilesType.LICENSE_AGREEMENT}
          errors={errors}
          checked={legacy.agreement}
          onChange={handleLegacyChange}
          messageKey="legalContent.termsAndAgreements.agreements"
        />
      </section>
    </>
  );
};
