import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import dot from 'dot-object';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Row, Col } from 'rsuite';

import { getSingleCertification } from '../../../../api/certification';
import { duplicateCertification } from '../../../../api/certification/duplicate-certification';
import { RootReducer } from '../../../../redux/rootReducer';
import { hideCloneCertificationModal } from '../../../../redux/modules/modals';
import { CertificationModal } from '../../../../redux/modules/modals/reducer';
import { profileData } from '../../../../redux/modules/certification/data';
import { CertificationDefinition } from '../../../../redux/modules/certification';
import { CircularLoader } from '../../../../shared-components/loader/CircularLoader';
import { LabeledSelect, LabeledInput } from '../../../../shared-components/labeled-inputs';
import { oneOfEnum } from '../validation-schemes';
import { DetailsItem } from '../../../partials';
import { jsonCopy, mergeObjects, prepareYupModel, resetSummaryUrl } from '../../../../helpers';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { handleRequestSuccess } from '../../../../helpers/handle-request-success';
import { formatErrors } from '../../../../helpers/request';
import { ErrorObject, ValidationErrorResponse } from '../../../../helpers/types';
import { CertificationType, Profile, ProfileType } from '../types';
import { RsCenterModal } from '../../../../shared-components/rsuite';

export interface CertificationCloneData {
  profile: Profile;
  version: string;
  revision: string;
  name?: string;
  role?: string;
}

const { Header, Title, Body, Footer } = Modal;

export const CloneCertificationModal = () => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { addToast } = useToasts();
  const defaultValue = {
    name: '',
    role: '',
    profile: { type: ProfileType.STANDARD, name: '' },
    version: '',
    revision: '',
  };
  const [certification, setCertification] = useState<CertificationCloneData>(defaultValue);
  const initialCertification = useSelector<RootReducer, CertificationModal | null>(
    state => state.modalsReducer.certificationModal,
  );
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState<ErrorObject>({});

  const cloneModalSchema = Yup.object().shape<CertificationCloneData>({
    profile: Yup.object().shape({
      type: oneOfEnum<ProfileType>(ProfileType),
      name: Yup.string().when('type', {
        is: value => [ProfileType.PROFILE, ProfileType.ASD].includes(value),
        then: Yup.string()
          .required(t('common.validation.required'))
          .trim(),
      }),
    }),
    version:
      initialCertification?.type === CertificationType.PRIMARY
        ? Yup.string().required(t('common.validation.required'))
        : Yup.string(),
    revision: Yup.string().required(t('common.validation.required')),
  });

  const closeModal = () => {
    dispatch(hideCloneCertificationModal());
  };

  const afterCloseModal = () => {
    setSaving(false);
    setCertification(defaultValue);
    setFormErrors({});
  };

  useEffect(() => {
    if (initialCertification?.id) {
      getSingleCertification(initialCertification.id)
        .then(({ data }: AxiosResponse<CertificationDefinition>) => {
          setCertification({
            ...defaultValue,
            version: data.version.version || '',
            name: data.generalInfo.name,
            role: data.generalInfo.role.name,
          });
          setLoading(false);
        })
        .catch(error => {
          handleRequestFail(error, addToast);
          setLoading(false);
          closeModal();
        });
    }
  }, [initialCertification]);

  const handleChange = (value: string, name: string) => {
    setCertification(prevState => {
      const newState = jsonCopy(prevState);
      dot.set(name, value, newState);
      const errors = prepareYupModel(cloneModalSchema).checkForFieldFormatted(name, newState);
      if (errors) {
        setFormErrors(prevErrors => mergeObjects(prevErrors, { ...errors }));
      }
      return newState;
    });
  };

  const save = () => {
    if (initialCertification === null) {
      return;
    }
    const errors = prepareYupModel(cloneModalSchema).checkFormatted(certification);
    if (errors) {
      setFormErrors(prevErrors => mergeObjects(prevErrors, errors));
      return;
    }
    setSaving(true);
    const dataToSubmit = jsonCopy(certification);
    dataToSubmit.version = dataToSubmit.version.trim();
    dataToSubmit.revision = dataToSubmit.revision.trim();
    return duplicateCertification(initialCertification.id, dataToSubmit)
      .then(({ data }: AxiosResponse<CertificationDefinition>) => {
        handleRequestSuccess(t('certifications.notifications.saved'), addToast);
        closeModal();
        history.push(`/certification/${data.versionId}`);
        resetSummaryUrl('certification', '/certification');
      })
      .catch((error: AxiosError<ValidationErrorResponse>) => {
        if (error.response?.data.errors) {
          setFormErrors(formatErrors(error.response?.data.errors));
        }
        setCertification(dataToSubmit);
        handleRequestFail(error, addToast);
        setSaving(false);
      });
  };

  return (
    <RsCenterModal overflow={false} show={initialCertification !== null} onHide={closeModal} onExited={afterCloseModal}>
      <Header>
        <Title>{t('certifications.clone.title')}</Title>
      </Header>
      <Body className="px-1">
        {loading || !certification ? (
          <CircularLoader content={t('certifications.clone.loadingCert')} size={24} />
        ) : (
          <Form fluid>
            <p className="mb-1">
              {initialCertification?.type === CertificationType.PRIMARY
                ? t('certifications.clone.primary')
                : t('certifications.clone.capability')}
            </p>
            <Row gutter={20}>
              <Col xs={12}>
                <DetailsItem label={t('certifications.name')} value={certification.name} className="mb-1" />
              </Col>
              <Col xs={12}>
                <DetailsItem label={t('certifications.role')} value={certification.role} />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={12}>
                <LabeledSelect
                  block
                  noMargin
                  required
                  cleanable={false}
                  searchable={false}
                  label={t('certifications.version.profileType')}
                  name="profile.type"
                  data={profileData}
                  placeholder={t('common.placeholders.select')}
                  value={certification.profile.type}
                  errors={formErrors}
                  onSelect={value => handleChange(value, 'profile.type')}
                />
              </Col>
              <Col xs={12}>
                {[ProfileType.PROFILE, ProfileType.ASD].includes(certification.profile.type) ? (
                  <LabeledInput
                    required
                    label={t(
                      `certifications.version.${
                        certification.profile.type === ProfileType.PROFILE ? 'profileName' : 'ASDTestPlan'
                      }`,
                    )}
                    name="profile.name"
                    value={certification.profile.name}
                    errors={formErrors}
                    placeholder={t(
                      `certifications.version.${
                        certification.profile.type === ProfileType.PROFILE ? 'profileName' : 'ASDTestPlan'
                      }`,
                    )}
                    onChange={(value, event) => handleChange(value, event.target.name)}
                  />
                ) : null}
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={12}>
                <LabeledInput
                  required
                  label={t('certifications.version.version')}
                  name="version"
                  value={certification.version}
                  errors={formErrors}
                  disabled={initialCertification?.type === CertificationType.CAPABILITY}
                  onChange={(value, event) => handleChange(value, event.target.name)}
                />
              </Col>
              <Col xs={12}>
                <LabeledInput
                  required
                  label={t('certifications.version.revision.title')}
                  name="revision"
                  value={certification.revision}
                  errors={formErrors}
                  onChange={(value, event) => handleChange(value, event.target.name)}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Body>
      <Footer className="is-justify-space-between">
        <Button appearance="link" onClick={closeModal}>
          {t('common.navigation.cancel')}
        </Button>
        <Button appearance="primary" onClick={save} loading={saving} disabled={loading} id="submit">
          {t('common.actions.duplicate')}
        </Button>
      </Footer>
    </RsCenterModal>
  );
};
