import { ComponentCombination, WiFiComponents } from '../api/application/types';
import { initialState } from '../redux/modules/application-flow/data';
import { jsonCopy } from './json-copy';
import { OtherRolesProps } from '../components/pages/application/types';

export const updateCCWithOrder = (
  cc: ComponentCombination = null,
  initial: ComponentCombination = initialState.componentCombination.componentCombination,
): ComponentCombination => {
  let order = 0;
  const newCC: ComponentCombination = jsonCopy(initial);
  if (newCC !== null && cc !== null) {
    Object.keys(cc).forEach(key => {
      const roleItems = cc[key as keyof WiFiComponents];
      if (Array.isArray(roleItems)) {
        (newCC[key as keyof ComponentCombination] as OtherRolesProps[]) = (roleItems as OtherRolesProps[]).map(item => {
          if (!item.hasOwnProperty('sortOrder')) {
            item.sortOrder = order;
          }
          order = (item.sortOrder as number) + 1;
          return item;
        });
      }
    });
  }
  return newCC;
};
