import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { ErrorObject } from '../../../../helpers/types';
import { getVariantSources } from '../../../../api/product/get-variant-sources';
import { SourceProduct } from './FindProductVariant';
import { useTranslation } from 'react-i18next';
import HierarchicalDropdown, {
  HierarchicalDropdownProps,
} from '../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';

interface Variant {
  id: number;
  name: string;
  certified: boolean;
}

interface Props {
  companyId: number;
  productId?: number | null;
  appId?: number | null;
  defaultVariantId?: number;
  defaultSourceProductId?: number;
  onAppIdChange: (value?: number, variant?: Variant) => void;
  onProductIdChange: (value?: number) => void;
  errors?: ErrorObject;
}

export const FindVariantForApp = ({
  defaultVariantId,
  defaultSourceProductId,
  companyId,
  productId,
  appId,
  onAppIdChange,
  onProductIdChange,
  errors,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sourceProducts, setSourceProducts] = useState<SourceProduct[]>([]);
  const [variants, setVariants] = useState<Variant[]>([]);

  const handleSourceChange = (productId: number, sourceProduct?: SourceProduct) => {
    if (appId !== -1) {
      onAppIdChange(-1);
    }
    const product = sourceProduct || sourceProducts.find(product => product.id === productId);
    onProductIdChange(productId);
    setVariants(product?.variants || []);
  };

  const handleVariantChange = (value: number) => {
    const variant = variants.find(item => item.id === value);
    onAppIdChange(value || -1, variant);
  };

  useEffect(() => {
    setLoading(true);
    getVariantSources(companyId)
      .then((response: AxiosResponse<SourceProduct[]>) => {
        const result = response.data.map(product => ({ ...product, fullName: `${product.cid} - ${product.name}` }));
        setSourceProducts(result);

        const defaultProduct = result.find(item => item.id === defaultSourceProductId);
        if (defaultProduct) {
          handleSourceChange(defaultProduct.id, defaultProduct);
          const defaultVariant = defaultProduct.variants.find(item => item.id === defaultVariantId);
          if (defaultVariant) {
            onAppIdChange(
              defaultVariant.id,
              defaultProduct.variants.find(item => item.id === defaultVariant.id),
            );
          }
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [companyId]);

  const handleSourceClean = () => {
    onAppIdChange(-1);
    onProductIdChange(-1);
    setVariants([]);
  };

  const dropdownData: HierarchicalDropdownProps[] = [
    {
      data: sourceProducts,
      value: productId,
      labelKey: 'fullName',
      valueKey: 'id',
      placeholder: 'Choose a product',
      onChange: handleSourceChange,
      onClean: handleSourceClean,
      name: 'companyContactInfo.source.productId',
      errors,
      loading,
    },
    {
      data: variants,
      value: appId,
      labelKey: 'name',
      valueKey: 'id',
      placeholder: t('applications.cc.chooseVariant'),
      onChange: handleVariantChange,
      onClean: () => onAppIdChange(-1),
      name: 'companyContactInfo.source.id',
      errors,
    },
  ];

  return <HierarchicalDropdown dropdownData={dropdownData} />;
};
