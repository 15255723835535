import React from 'react';
import { Dropdown, Icon, IconButton, Popover, Whisper } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
//import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { IconButtonProps } from 'rsuite/lib/IconButton';

import { getStoredSummaryUrl } from '../../../../helpers';
import { ApplicationSummaryRecord, AppStatuses, ApplicationInfoRecord } from '../../application/types';
import { useDispatch, useSelector } from 'react-redux';
import { showAddNoteModal, showRevokeModal } from '../../../../redux/modules/modals';
import { RevokeActionTypes, NoteActionTypes } from '../../../../redux/modules/modals/constants';
import { RootReducer } from '../../../../redux/rootReducer';
import { User } from '../../../../redux/modules/user/types';
import { defaultWhisperProps, wfaAdminRole } from '../../../../helpers/constants';
import { MaterialIconButton } from '../../../../shared-components/button/MaterialIconButton';

export const WhisperActions = ({
  applicationId,
  record,
  isFlowPage = false,
  isReview = false,
  status,
  application,
}: {
  applicationId: number;
  record?: ApplicationSummaryRecord;
  isFlowPage?: boolean;
  isReview?: boolean;
  status?: string;
  application?: ApplicationInfoRecord;
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const user = useSelector<RootReducer, User>(state => state.userReducer.user as User);

  const handleRevokeApp = () => {
    dispatch(
      showRevokeModal(
        {
          cid: application?.productInfo.cid,
          appId: application?.id,
        },
        RevokeActionTypes.REVOKE_APPLICATION,
      ),
    );
  };

  /*const handleUncertifyApp = () => {
    dispatch(
      showRevokeModal(
        {
          cid: application?.productInfo.cid,
          appId: application?.id,
        },
        RevokeActionTypes.UNCERTIFY_APPLICATION,
      ),
    );
  };*/

  const onReturnSuccess = () => {
    addToast(`Application #${applicationId} was successfully returned.`, {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    if (record) {
      record.actions.setLoadingData(true);
      return record.actions.getSummary({ tab: status });
    } else {
      history.push(getStoredSummaryUrl('pending-approval'));
    }
  };

  const route = location.pathname.startsWith('/pending-approval') ? 'pending-approval' : 'application';
  const onHistory = () => window.open(`#/${route}/${applicationId}/history`);

  const iconBtnProps: IconButtonProps = isFlowPage ? { className: 'ml-1', size: 'lg' } : { appearance: 'link' };

  return (
    <Whisper
      {...defaultWhisperProps}
      speaker={
        <Popover full>
          <Dropdown.Menu className="action-icons">
            {!isReview && (
              <>
                <Dropdown.Item
                  onClick={() =>
                    dispatch(
                      showAddNoteModal(
                        { appId: applicationId, onSuccess: onReturnSuccess },
                        NoteActionTypes.RETURN_TO_SUBMITTER_STAFF,
                      ),
                    )
                  }
                >
                  <IconButton icon={<Icon icon="undo" />} appearance="link">
                    {t('common.actions.returnToSubmitter')}
                  </IconButton>
                </Dropdown.Item>
                <Dropdown.Item disabled>
                  <IconButton icon={<Icon icon="flag" />} appearance="link">
                    {t('common.actions.flagForAudit')}
                  </IconButton>
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item onClick={onHistory}>
              <IconButton icon={<Icon icon="history" />} appearance="link">
                {t('applications.summary.history')}
              </IconButton>
            </Dropdown.Item>
            {[wfaAdminRole].includes((user as User).authority) && application?.status == AppStatuses.APPROVED ? (
              <>
                <Dropdown.Item onClick={handleRevokeApp}>
                  <MaterialIconButton iconComponent={CancelOutlinedIcon}>
                    {t('applications.summary.revoke')}
                  </MaterialIconButton>
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={handleUncertifyApp}>
                  <MaterialIconButton iconComponent={KeyboardReturnIcon}>{t('revokes.uncertify')}</MaterialIconButton>
                </Dropdown.Item> */}
              </>
            ) : null}
          </Dropdown.Menu>
        </Popover>
      }
    >
      <IconButton {...iconBtnProps} icon={<Icon icon="more" className="icon pointer" size="2x" />} />
    </Whisper>
  );
};
