/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
(function() {
  if (!Element.prototype.closest) {
    Element.prototype.closest = function(css: string) {
      let node = this;

      while (node) {
        if (node.matches(css)) {
          return node;
        } else {
          // @ts-ignore
          node = node.parentElement;
        }
      }
      return null;
    };
  }
  if (!Element.prototype.matches) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }
  if (!String.prototype.repeat) {
    String.prototype.repeat = function(count) {
      'use strict';
      if (this === null) throw new TypeError("can't convert " + this + ' to object');

      let str = '' + this;
      count = Number(count);
      if (Number.isNaN(count)) {
        count = 0;
      }

      if (count < 0) {
        throw new RangeError('repeat count must be non-negative');
      }

      if (count == Infinity) {
        throw new RangeError('repeat count must be less than infinity');
      }

      count = Math.floor(count);
      if (str.length == 0 || count == 0) {
        return '';
      }

      if (str.length * count >= 1 << 28) {
        throw new RangeError('repeat count must not overflow maximum string size');
      }

      const maxCount = str.length * count;
      count = Math.floor(Math.log(count) / Math.log(2));
      while (count) {
        str += str;
        count--;
      }
      str += str.substring(0, maxCount - str.length);
      return str;
    };
  }
})();
