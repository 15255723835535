import React from 'react';
import { useTranslation } from 'react-i18next';
import { LabelTypes } from '../../../helpers/constants/label-types';
import { productTypes, productTypesTitles } from '../../../helpers/constants/product-types';
import { AppFlows } from '../../../redux/modules/application-flow';
import { LabelType } from '../../../shared-components/label-type/LabelType';
import { ApplicationTypeDetails } from '../../partials/summary-template/ApplicationTypeDetails';
import { GroupedCertifications } from '../../partials/summary-template/GroupedCertifications';
import { SummaryItem } from '../../partials/summary-template/SummaryTemplate';
import { ApplicationInfoRecord, TestingItem } from './types';

export const ApplicationDetailsSidebar = ({ applicationRecord }: { applicationRecord: ApplicationInfoRecord }) => {
  const { t } = useTranslation();

  const formattedCertifications = applicationRecord.certifications.map(cert => ({
    id: cert.id,
    name: cert.name,
    roleName: cert.role?.name,
    capabilities: cert.capabilities,
    status: cert.version.status,
  }));

  const { productInfo, componentCombination } = applicationRecord;

  const isVariant = applicationRecord['@type'] === AppFlows.VARIANT;

  const isQualifying = productInfo.certified === false;

  const isQualifiedSolution = productInfo.qualifiedSolution;

  const isUseDraftCertified = isQualifiedSolution && isVariant && isQualifying;

  const filteredLabs = [AppFlows.NEW, AppFlows.VARIANT].includes(applicationRecord['@type'])
    ? applicationRecord.testingInfo.testingLabs.reduce((accum, next) => {
        if (
          next.testingDetails.interoperability &&
          !accum.some(item => item.id === next.testingDetails.interoperability?.id)
        ) {
          accum.push(next.testingDetails.interoperability);
        }
        if (next.testingDetails.conformance && !accum.some(item => item.id === next.testingDetails.conformance?.id)) {
          accum.push(next.testingDetails.conformance);
        }
        return accum;
      }, [] as TestingItem[])
    : [];

  return (
    <>
      {(productInfo.modelVariants && productInfo.modelVariants[0].productType) ||
      productInfo.cid ||
      productInfo.qualifiedSolution ? (
        <SummaryItem title={t('applications.summary.sideBar.applicationId')}>
          {productInfo.cid}
          {productInfo.qualifiedSolution ? (
            <LabelType
              labelTypes={LabelTypes.SUMMARY_LABEL}
              label={productTypesTitles[productTypes.qualifiedSolution]}
            />
          ) : (
            <>
              {productInfo.modelVariants && productInfo.modelVariants[0].productType ? (
                <LabelType
                  labelTypes={LabelTypes.SUMMARY_LABEL}
                  label={productTypesTitles[productInfo.modelVariants[0].productType]}
                />
              ) : null}
            </>
          )}
        </SummaryItem>
      ) : null}
      <SummaryItem title={t('applications.summary.sideBar.applicationType')}>
        <ApplicationTypeDetails
          applicationType={applicationRecord['@type']}
          companyContactInfo={applicationRecord.companyContactInfo}
          productInfo={applicationRecord.productInfo}
        />
      </SummaryItem>
      {productInfo.qualifiedSolution && productInfo.certified !== null ? (
        <SummaryItem title={t('applications.summary.sideBar.wifiCertified')}>
          <LabelType
            labelTypes={LabelTypes.BLOCK_LABEL}
            label={
              (isUseDraftCertified
              ? productInfo.modelVariants[0].draftCertified === true
              : productInfo.certified)
                ? 'Yes'
                : 'No'
            }
          />
        </SummaryItem>
      ) : null}
      {componentCombination.componentCombinationVariantSource &&
      componentCombination.componentCombinationVariantSource.productType ? (
        <SummaryItem title={t('applications.summary.sideBar.componentLoadedFrom')}>
          <LabelType
            labelTypes={LabelTypes.SUMMARY_LABEL}
            label={productTypesTitles[componentCombination.componentCombinationVariantSource.productType]}
          />
        </SummaryItem>
      ) : null}
      {applicationRecord.productInfo.name ? (
        <SummaryItem title={t('applications.summary.sideBar.name')}>{applicationRecord.productInfo.name}</SummaryItem>
      ) : null}
      {applicationRecord.productInfo.modelVariants[0]?.name ? (
        <SummaryItem title={t('applications.info.variantName')}>
          {applicationRecord.productInfo.modelVariants[0]?.name}
        </SummaryItem>
      ) : null}
      {filteredLabs.length > 0 ? (
        <SummaryItem title={t('applications.summary.sideBar.testingLabs')}>
          {filteredLabs.map(item => (
            <p key={item.id}>{item.name}</p>
          ))}
        </SummaryItem>
      ) : null}
      <GroupedCertifications certifications={formattedCertifications} />
    </>
  );
};
