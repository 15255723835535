import { User } from './types';
import { LOAD_USER, USER_LOADED } from './actionTypes';
import { AnyAction } from 'redux';

const initialState = {
  user: null,
  loading: false,
};

export type userState = Readonly<{
  user: null | User;
  loading: boolean;
}>;

export const userReducer = (state: userState = initialState, action: AnyAction) => {
  switch (action.type) {
    case LOAD_USER:
      return {
        ...state,
        loading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
