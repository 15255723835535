import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosTransformer } from 'axios';
import { apiBaseUrl } from '../config';
import Cookies from 'js-cookie';
import { getLoginUrl } from './get-login-url';
import { store } from '../redux/applicationStore';
import { healthCheck } from '../api/maintenance/health-check';
import { APP_STATUS } from '../redux/modules/app/reducer';
import { setAppHealth, setHealthLastChecked } from '../redux/modules/app';
import { healthCheckTimout } from './constants';

export const getUserToken = (): string => Cookies.get('access-token') as string;

export const getAuthHeader = (): string => `Bearer ${getUserToken()}`;

export const defaultTransformers = (): AxiosTransformer[] => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) {
    return [];
  } else if (transformRequest instanceof Array) {
    return transformRequest;
  } else {
    return [transformRequest];
  }
};

const instance = axios.create({
  baseURL: apiBaseUrl,
  transformRequest: [
    ...defaultTransformers(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any, headers: { [x: string]: object | string }): any => {
      if (getUserToken()) {
        headers.Authorization = getAuthHeader();
      }
      return data;
    },
  ],
});

export const errorInterceptor = (error: AxiosError) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: AxiosResponse<any> | undefined = error.response;
  if (
    response &&
    response.status === 401 &&
    store.getState().userReducer.user !== null &&
    !window.location.href.includes('#/landing')
  ) {
    Cookies.remove('access-token');
    window.location.href = getLoginUrl(window.location.hash.replace('#', ''));
  }
  throw error;
};

export const requestInterceptor = (config: AxiosRequestConfig) => {
  const { status: appStatus, lastUpdated } = store.getState().appReducer.appHealth;
  if (
    (lastUpdated === 0 || ((Date.now() - lastUpdated) / 100) * 60 >= healthCheckTimout.onRequest) &&
    appStatus !== APP_STATUS.DOWN
  ) {
    return healthCheck()
      .then((healthCheckResponse: AxiosResponse<{ status: APP_STATUS }>) => {
        const { status } = healthCheckResponse.data;
        if (appStatus !== status) {
          store.dispatch(setAppHealth(status));
        } else {
          store.dispatch(setHealthLastChecked());
        }
        return config;
      })
      .catch(() => {
        store.dispatch(setAppHealth(APP_STATUS.DOWN));
        return config;
      });
  }
  return config;
};

instance.interceptors.request.use(requestInterceptor, error => Promise.reject(error));

instance.interceptors.response.use((response: AxiosResponse) => response, errorInterceptor);

export const CancelToken = axios.CancelToken;

export default instance;
