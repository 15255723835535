import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckBoxIcon from '@material-ui/icons/CheckBoxSharp';
import { Col, Form, FormGroup, Row } from 'rsuite';
import { ProductCategories, ProductInfoStep } from '../../../../../api/application/types';
import { jsonCopy } from '../../../../../helpers';
import { buildDownloadUrl } from '../../../../../helpers/build-download-url';
import { IsModifiedProps, numberSort, useChangeDetection } from '../../../../../helpers/hooks/useChangeDetection';
import {
  AppFlowSteps,
  AppFlows,
  ApplicationFlowState,
  updateApplicationProperty,
} from '../../../../../redux/modules/application-flow';
import { MemberClearData, initialState } from '../../../../../redux/modules/application-flow/data';
import { loadCategories } from '../../../../../redux/modules/categories';
import { RootReducer } from '../../../../../redux/rootReducer';
import { UploadedFile } from '../../../../../shared-components/file-upload/types';
import { ImagePreview } from '../../../../../shared-components/helper-components/ImagePreview';
import { WrapWithPublicIcon } from '../../../../../shared-components/helper-components/WrapWithPublicIcon';
import {
  AutocompleteField,
  AutocompleteMultipleField,
} from '../../../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';
import { LabeledBooleanField, LabeledInput, LabeledTextArea } from '../../../../../shared-components/labeled-inputs';
import { RsControlLabel } from '../../../../../shared-components/rsuite';
import { Tooltip } from '../../../../../shared-components/tooltip/Tooltip';
import {
  FileUploader,
  UploadFileType,
  supportedImageTypes,
  validateAppImage,
} from '../../../../../shared-components/uploader/FileUploader';
import { DetailsItem } from '../../../../partials';
import { CCInfoRecord, Categories, CategoryItem } from '../../types';
import { Section } from '../ApplicationDetails';
import { CCDetails } from '../cc-details/CCDetails';
import { VariantNameTooltipContent } from './VariantNameTooltipContent';
import { isProductOfSolutionProvider } from './helpers';

interface ReformattedCategories {
  label: string;
  value: number;
  role: string;
}

const calculateHashValue = (value: ProductInfoStep) => {
  const info = jsonCopy(value);
  return JSON.stringify({
    ...info,
    image: info.image ? info.image.id : null,
    primaryCategory: info.primaryCategory ? info.primaryCategory.id : null,
    additionalCategories: info.additionalCategories.map(item => item.id).sort(numberSort),
    documents: info.documents.map(item => item.id).sort(numberSort),
    certified: info.certified,
    qualifiedSolution: info.qualifiedSolution,
  });
};

type Props = IsModifiedProps & {
  setLoading: (value: boolean) => void;
  setAppImageError?: (value: boolean) => void;
  initialProductInfo?: ProductInfoStep;
};

export const ProductModelStep = ({ setLoading, setAppImageError, setIsModified, initialProductInfo }: Props) => {
  const { t } = useTranslation();
  const { errors, productInfo, componentCombination, ...application } = useSelector<RootReducer, ApplicationFlowState>(
    state => state.applicationFlow,
    shallowEqual,
  );
  const categories = useSelector<RootReducer, Categories[]>(state => state.categoriesReducer.categories);
  const dispatch = useDispatch();

  const isVariant = application['@type'] === AppFlows.VARIANT;

  const isCertifiedSelected = productInfo.certified !== null;

  const isQualifying = productInfo.certified === false;

  const isQualifiedSolution = productInfo.qualifiedSolution;

  const isQualifiedSolutionSelected = isQualifiedSolution !== null;

  const isUseDraftCertified = isQualifiedSolution && isVariant && isQualifying;

  const isDraftCertified = productInfo.modelVariants[0].draftCertified === true;

  const showQualifiedSolutionFields =
    isProductOfSolutionProvider(application) && application['@type'] !== AppFlows.DERIVATIVE;

  const updateChangeDetectionState = useChangeDetection(setIsModified, calculateHashValue, productInfo);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      isVariant &&
      initialProductInfo &&
      calculateHashValue(initialProductInfo) !== calculateHashValue(initialState.productInfo)
    ) {
      updateChangeDetectionState(initialProductInfo);
    }
  }, [initialProductInfo]);

  const [appDocLoading, setAppDocLoading] = useState(false);
  const [appImageLoading, setAppImageLoading] = useState(false);

  useEffect(() => {
    setLoading(appDocLoading || appImageLoading);
  }, [appDocLoading, appImageLoading]);

  const handleChange = (
    value: string | ProductCategories | ProductCategories[] | UploadedFile | UploadedFile[] | boolean | null,
    name: string,
  ) => dispatch(updateApplicationProperty(value, name, MemberClearData.NONE, AppFlowSteps.PRODUCT));

  const onProductImageChange = (file: UploadedFile[]): void => {
    const productImage = file && file.length ? file[0] : null;
    handleChange(productImage, 'productInfo.image');
  };

  const onProductDocumentsChange = (files: UploadedFile[]): void => {
    handleChange(files, 'productInfo.documents');
  };

  // convert from array object like [{ id: 1 }, { id: 2 }] => [1,2]
  const reformatFromIdToNumber = (categories: ProductCategories[]) => {
    return categories.map((category: ProductCategories) => category.id);
  };

  const getReformattedCategories = (): ReformattedCategories[] =>
    categories.reduce((accum, category) => {
      accum.push(
        ...(category.categories.map(categoryItem => ({
          label: categoryItem.name,
          value: categoryItem.id,
          role: category.name,
        })) as ReformattedCategories[]),
      );

      return accum;
    }, [] as ReformattedCategories[]);

  useEffect(() => {
    if (!categories.length) {
      dispatch(loadCategories());
    }
  }, [categories]);

  return (
    <>
      <Form fluid>
        {showQualifiedSolutionFields ? (
          <Row gutter={20} className="mt-2">
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.qualifiedSolution"
                errors={errors}
                label={
                  <>
                    {t('applications.info.isQualifiedSolution')}
                    <Tooltip translationKey="tooltips.isQualifiedSolution" />
                  </>
                }
                disabled={isVariant}
                value={productInfo.qualifiedSolution}
                onChange={value => handleChange(value, 'productInfo.qualifiedSolution')}
              />
            </Col>
            <Col xs={12}>
              {isUseDraftCertified ? (
                <LabeledBooleanField
                  required
                  name="productInfo.modelVariants[0].draftCertified"
                  errors={errors}
                  label={
                    <>
                      {t('applications.info.isWiFiCertified')}
                      <Tooltip
                        width={400}
                        content={
                          <div>
                            <div>{t('tooltips.isWiFiCertifiedTitle')}</div>
                            <ul style={{ paddingInlineStart: '2em' }}>
                              <li>{t('tooltips.isWiFiCertifiedOptionYes')}</li>
                              <li>{t('tooltips.isWiFiCertifiedOptionNo')}</li>
                            </ul>
                          </div>
                        }
                      />
                    </>
                  }
                  value={productInfo.modelVariants[0].draftCertified}
                  onChange={value => handleChange(value, 'productInfo.modelVariants[0].draftCertified')}
                />
              ) : (
                <LabeledBooleanField
                  required
                  name="productInfo.certified"
                  errors={errors}
                  label={
                    <>
                      {t('applications.info.isWiFiCertified')}
                      <Tooltip
                        width={400}
                        content={
                          <div>
                            <div>{t('tooltips.isWiFiCertifiedTitle')}</div>
                            <ul style={{ paddingInlineStart: '2em' }}>
                              <li>{t('tooltips.isWiFiCertifiedOptionYes')}</li>
                              <li>{t('tooltips.isWiFiCertifiedOptionNo')}</li>
                            </ul>
                          </div>
                        }
                      />
                    </>
                  }
                  disabled={!isQualifiedSolution || isVariant}
                  value={productInfo.certified}
                  onChange={value => handleChange(value, 'productInfo.certified')}
                />
              )}
            </Col>
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.modelVariants[0].availableAsQualifiedSolution"
                errors={errors}
                label={
                  <>
                    {t('applications.info.availableAsQualifiedSolution')}
                    <Tooltip translationKey="tooltips.availableAsQualifiedSolution" />
                  </>
                }
                disabled={!isQualifiedSolution || !isCertifiedSelected}
                value={productInfo.modelVariants[0]?.availableAsQualifiedSolution}
                onChange={value => handleChange(value, 'productInfo.modelVariants[0].availableAsQualifiedSolution')}
              />
            </Col>
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.modelVariants[0].searchableByPublic"
                errors={errors}
                label={
                  <>
                    {t('applications.info.searchable')}
                    <Tooltip
                      content={
                        <>
                          {t('tooltips.searchableByPublic')}
                          <br /> <br />
                          {t('tooltips.searchableByPublicNote')}
                        </>
                      }
                    />
                  </>
                }
                disabled={
                  !(isQualifiedSolutionSelected && (isCertifiedSelected || isUseDraftCertified)) ||
                  (isUseDraftCertified && !isDraftCertified) ||
                  (!isUseDraftCertified && isQualifying)
                }
                value={productInfo.modelVariants[0]?.searchableByPublic}
                onChange={value => handleChange(value, 'productInfo.modelVariants[0].searchableByPublic')}
              />
            </Col>
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.modelVariants[0].availableAsDerivative"
                errors={errors}
                label={
                  <>
                    {t('applications.info.available')}
                    <Tooltip translationKey="tooltips.availableAsSource" />
                  </>
                }
                disabled={
                  !(isQualifiedSolutionSelected && isCertifiedSelected) ||
                  (isUseDraftCertified && !isDraftCertified) ||
                  (!isUseDraftCertified && isQualifying)
                }
                value={productInfo.modelVariants[0]?.availableAsDerivative}
                onChange={value => handleChange(value, 'productInfo.modelVariants[0].availableAsDerivative')}
              />
            </Col>
          </Row>
        ) : (
          <Row gutter={20} className="mt-2">
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.modelVariants[0].availableAsDerivative"
                errors={errors}
                label={
                  <>
                    {t('applications.info.available')}
                    <Tooltip translationKey="tooltips.availableAsSource" />
                  </>
                }
                value={productInfo.modelVariants[0]?.availableAsDerivative}
                onChange={value => handleChange(value, 'productInfo.modelVariants[0].availableAsDerivative')}
              />
            </Col>
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="productInfo.modelVariants[0].searchableByPublic"
                errors={errors}
                label={
                  <>
                    {t('applications.info.searchable')}
                    <Tooltip
                      content={
                        <>
                          {t('tooltips.searchableByPublic')}
                          <br /> <br />
                          {t('tooltips.searchableByPublicNote')}
                        </>
                      }
                    />
                  </>
                }
                value={productInfo.modelVariants[0]?.searchableByPublic}
                onChange={value => handleChange(value, 'productInfo.modelVariants[0].searchableByPublic')}
              />
            </Col>
          </Row>
        )}
        <Row gutter={20}>
          <Col xs={12}>
            {isVariant ? (
              <DetailsItem
                label={<WrapWithPublicIcon content={t('applications.info.name')} />}
                value={productInfo.name}
              />
            ) : (
              <LabeledInput
                label={<WrapWithPublicIcon content={t('applications.info.name')} />}
                name="productInfo.name"
                placeholder={t('applications.info.name')}
                errors={errors}
                value={productInfo.name}
                onChange={(value: string, event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(value, event.target.name)
                }
                required
              />
            )}
          </Col>
          <Col xs={12}>
            <LabeledInput
              required
              label={
                <>
                  <WrapWithPublicIcon content={t('applications.info.variantName')} />
                  <Tooltip width={680} content={<VariantNameTooltipContent />} />
                </>
              }
              name="productInfo.modelVariants[0].name"
              placeholder={t('applications.info.variantName')}
              errors={errors}
              value={productInfo.modelVariants[0].name}
              onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => handleChange(value, event.target.name)}
            />
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            {isVariant ? (
              <DetailsItem
                className="mb-1"
                label={<WrapWithPublicIcon content={t('applications.info.modelNumber')} />}
                value={productInfo.modelNumber}
              />
            ) : (
              <LabeledInput
                required
                label={<WrapWithPublicIcon content={t('applications.info.modelNumber')} />}
                name="productInfo.modelNumber"
                placeholder={t('applications.info.modelNumber')}
                errors={errors}
                value={productInfo.modelNumber}
                onChange={(value: string, event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(value, event.target.name)
                }
              />
            )}
          </Col>
          <Col xs={12}>
            {isVariant ? (
              <DetailsItem className="mb-1" label={<WrapWithPublicIcon content={t('applications.info.url')} />}>
                {productInfo.url ? (
                  <a href={productInfo.url} target="_blank" rel="noopener noreferrer">
                    {productInfo.url}
                  </a>
                ) : null}
              </DetailsItem>
            ) : (
              <LabeledInput
                label={<WrapWithPublicIcon content={t('applications.info.url')} />}
                noMargin
                name="productInfo.url"
                placeholder={t('applications.info.url')}
                errors={errors}
                value={productInfo.url || ''}
                onChange={(value: string, event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(value, event.target.name)
                }
              />
            )}
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            {isVariant ? (
              <DetailsItem
                className="mb-1"
                label={<WrapWithPublicIcon content={t('applications.info.primaryCategory')} />}
                value={(productInfo.primaryCategory as CategoryItem)?.name}
              />
            ) : (
              <>
                <label className="rs-control-label">
                  <span className="text-danger">* </span>
                  <WrapWithPublicIcon content={t('applications.info.primaryCategory')} />
                </label>
                <AutocompleteField
                  value={productInfo?.primaryCategory?.id}
                  onChange={id => handleChange({ id }, 'productInfo.primaryCategory')}
                  onClean={() => handleChange(null, 'productInfo.primaryCategory')}
                  getOptionDisabled={option =>
                    productInfo.additionalCategories.some(category => category.id === option.value)
                  }
                  data={getReformattedCategories()}
                  labelKey={'label'}
                  name={'productInfo.primaryCategory'}
                  placeholder={'Start typing...'}
                  valueKey={'value'}
                  groupBy={'role'}
                  errors={errors}
                />
              </>
            )}
          </Col>
          <Col xs={12}>
            {isVariant ? (
              <DetailsItem
                className="mb-1"
                label={<WrapWithPublicIcon content={t('applications.info.additionalCategory')} />}
                value={(productInfo.additionalCategories as CategoryItem[]).map(item => item.name).join(', ')}
              />
            ) : (
              <>
                <label className="rs-control-label">
                  <WrapWithPublicIcon content={t('applications.info.additionalCategory')} />
                </label>
                <AutocompleteMultipleField
                  value={reformatFromIdToNumber(productInfo.additionalCategories)}
                  onChange={(values: ReformattedCategories[]) =>
                    handleChange(
                      values.map(category => ({ id: category.value })),
                      'productInfo.additionalCategories',
                    )
                  }
                  data={getReformattedCategories()}
                  labelKey={'label'}
                  errors={errors}
                  name={'productInfo.additionalCategories'}
                  placeholder={'Start typing...'}
                  valueKey={'value'}
                  groupBy={'role'}
                  getOptionDisabled={option => option.value === productInfo.primaryCategory?.id}
                  disableCloseOnSelect
                  renderMenuItem={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={selected}
                        style={{ padding: 0, paddingRight: 10 }}
                      />
                      {option.label}
                    </>
                  )}
                />
              </>
            )}
          </Col>
        </Row>
        {isVariant ? (
          <DetailsItem
            className="mb-1"
            label={<WrapWithPublicIcon content={t('applications.info.description')} />}
            value={productInfo.description}
          />
        ) : (
          <LabeledTextArea
            label={<WrapWithPublicIcon content={t('applications.info.description')} />}
            name="productInfo.description"
            placeholder={t('applications.info.description')}
            errors={errors}
            value={productInfo.description}
            onChange={(value: string, event: ChangeEvent<HTMLTextAreaElement>) =>
              handleChange(value, event.target.name)
            }
          />
        )}
        <Row gutter={20} className="mb-2">
          <Col xs={12}>
            {isVariant ? (
              <DetailsItem label={<WrapWithPublicIcon content={t('applications.info.image')} />}>
                {productInfo.image ? (
                  <div style={{ display: 'flex', alignItems: 'top', wordBreak: 'break-all' }}>
                    <ImagePreview src={buildDownloadUrl(productInfo.image)} className="mr-1 ml-0" />
                  </div>
                ) : null}
              </DetailsItem>
            ) : (
              <FormGroup style={{ marginBottom: 0 }}>
                <RsControlLabel>
                  <WrapWithPublicIcon content={t('applications.info.image')} />
                </RsControlLabel>
                <FileUploader
                  multiple={false}
                  fileType={UploadFileType.APP_IMAGE}
                  onChange={onProductImageChange}
                  maxNumberOfFiles={1}
                  validateFile={validateAppImage}
                  accept={supportedImageTypes}
                  files={productInfo.image !== null && productInfo.image ? [productInfo.image] : []}
                  onLoadingChange={setAppImageLoading}
                  setError={setAppImageError}
                />
                <div>
                  {productInfo.image ? (
                    <ImagePreview src={buildDownloadUrl(productInfo.image)} alt="Product Image" />
                  ) : null}
                </div>
              </FormGroup>
            )}
          </Col>
          <Col xs={12}>
            <FormGroup style={{ marginBottom: 0 }}>
              <RsControlLabel>{t('applications.info.docs')}</RsControlLabel>
              <FileUploader
                fileType={UploadFileType.APP_DOC}
                files={productInfo.documents}
                onChange={onProductDocumentsChange}
                onLoadingChange={setAppDocLoading}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
      {application['@type'] === AppFlows.DERIVATIVE ? (
        <Section className="cc">
          <h4>{t('applications.cc.wifiComponent')}</h4>
          <CCDetails cc={componentCombination.componentCombination as CCInfoRecord} showPublicIcon />
        </Section>
      ) : null}
    </>
  );
};
