export const NEW_APPLICATION_STARTED = 'NEW_APPLICATION_STARTED';
export const APPLICATION_FOR_EDIT_LOADED = 'APPLICATION_FOR_EDIT_LOADED';
export const SET_APPLICATION_ID = 'SET_APPLICATION_ID';
export const SET_VALIDATION_STATE = 'SET_VALIDATION_STATE';
export const RESTORE_APPLICATION_DATA = 'RESTORE_APPLICATION_DATA';
export const UPDATE_APPLICATION_PROPERTY = 'UPDATE_APPLICATION_PROPERTY';
export const ADD_CC_LOADED_NOTE = 'ADD_CC_LOADED_NOTE';
export const ADD_CC_LOADED_NOTE_WARNING = 'ADD_CC_LOADED_NOTE_WARNING';
export const ADD_RF_BAND = 'ADD_RF_BAND';
export const REMOVE_RF_BAND = 'REMOVE_RF_BAND';
export const ADD_CC_ROLE = 'ADD_CC_ROLE';
export const CHANGE_CC_ROLE = 'CHANGE_CC_ROLE';
export const REMOVE_CC_ROLE = 'REMOVE_CC_ROLE';
export const CHECK_CERTIFICATION = 'CHECK_CERTIFICATION';
export const UN_CHECK_CERTIFICATION = 'UN_CHECK_CERTIFICATION';
export const UN_CHECK_CERT_TREE = 'UN_CHECK_CERT_TREE';
export const RESET_CERTIFICATIONS = 'RESET_CERTIFICATIONS';
export const SET_CERTIFICATIONS_LOADER = 'SET_CERTIFICATIONS_LOADER';
export const SET_APPLICATION_VALIDATION_ERRORS = 'SET_APPLICATION_VALIDATION_ERRORS';
export const SET_SHOW_POPUP = 'SET_SHOW_POPUP';
export const UPDATE_CERTIFICATIONS = 'UPDATE_CERTIFICATIONS';
export const UPDATE_PAYMENT_DATA = 'UPDATE_PAYMENT_DATA';
export const UPDATE_APPLICATION_EXPLANATION = 'UPDATE_APPLICATION_EXPLANATION';
export const UPDATE_APPLICATION_CORE_CC_EXPLANATION = 'UPDATE_APPLICATION_CORE_CC_EXPLANATION';
export const UPDATE_APPLICATION_CORE_CC_EXCEPTION_REQUESTED = 'UPDATE_APPLICATION_CORE_CC_EXCEPTION_REQUESTED';
export const SELECT_ALL_CERT_FOR_LAB = 'SELECT_ALL_CERT_FOR_LAB';
export const SELECT_CERT_LAB_CHECKBOX = 'SELECT_CERT_LAB_CHECKBOX';
export const UPDATE_SELECTED_TESTING_LABS = 'UPDATE_SELECTED_TESTING_LABS';
export const REMOVE_SELECTED_TESTING_LAB = 'REMOVE_SELECTED_TESTING_LAB';
export const ADD_SELECTED_TESTING_LAB = 'ADD_SELECTED_TESTING_LAB';
export const CHANGE_NAME_SELECTED_TESTING_LAB = 'CHANGE_NAME_SELECTED_TESTING_LAB';
export const SET_LABS_RESPONSE = 'SET_LABS_RESPONSE';
export const SET_COMPARE_CC_INFO = 'SET_COMPARE_CC_INFO';
export const CHECK_CAPABILITY = 'CHECK_CAPABILITY';
export const UN_CHECK_CAPABILITY = 'UN_CHECK_CAPABILITY';
export const SET_AUTO_VALIDATE_CAPABILITY = 'SET_AUTO_VALIDATE_CAPABILITY';
export const SET_CAPABILITIES_BY_CC = 'SET_CAPABILITIES_BY_CC';
export const SET_REVIEW_SECTIONS_EDIT_STATE = 'SET_REVIEW_SECTIONS_EDIT_STATE';
export const SET_FEES_RESPONSE = 'SET_FEES_RESPONSE';
