import React from 'react';
import { LRSummaryStatuses, LRStatusTitles } from '../../../helpers/constants';
import { SummaryTable } from './partials';
import { getLRSummary } from '../../../api/lab-request';
import { LRSummaryRecord } from './types';
import { getSummaryPageComponentByObject } from '../../hoc/get-summary-page-component';
import { useTranslation } from 'react-i18next';

const SummaryPage = () => {
  const { t } = useTranslation();

  const SummaryPage = getSummaryPageComponentByObject<LRSummaryRecord>({
    title: t('testing.title'),
    apiCall: getLRSummary,
    object: LRSummaryStatuses,
    titles: LRStatusTitles,
    SummaryTable,
    useAdvancedFilters: true,
    isTestingSummary: true,
  });

  return <SummaryPage />;
};

export const TestingSummaryPage = React.memo(SummaryPage);
