import React from 'react';
import { getStoredSummaryUrl, closeWindow } from '../../../../helpers';
import { BackToListButton } from '../../../../shared-components/button/BackToListButton';
import { CloseButton } from '../../../../shared-components/button/CloseButton';

export const ActionLeft = ({
  history,
  isReview = false,
  isFooter = false,
}: {
  history: { push: (route: string) => void };
  isReview?: boolean;
  isFooter?: boolean;
}) => {
  const handleBackToList = () => history.push(getStoredSummaryUrl(isReview ? 'application' : 'pending-approval'));
  return (
    <div>
      {isFooter ? null : window.opener ? (
        <CloseButton onClick={closeWindow} />
      ) : (
        <BackToListButton onClick={handleBackToList} className="back-to-list" />
      )}
    </div>
  );
};
