import { Certification, GroupNodeType } from '../types';
import React, { PropsWithChildren, useContext, useState } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { ConditionBuilderContext } from '../ConditionBuilderContext';
import {
  StyledGroupNodeOperatorWrapper,
  StyledGroupNodeActionsWrapper,
  StyledGroupNodeElement,
  StyledNodeWrapper,
} from './StyledComponents';
import classNames from 'classnames';
import { NegationInput } from './NegationInput';
import { OperatorInput } from './OperatorInput';
import { DeleteButton } from './Buttons';
import { CertificationInput } from './CertificationInput';
import { getCertificationIds, pathToDotString } from '../helpers';
import { ErrorMessage } from '../../helper-components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { MaterialAddButton } from '../../../shared-components/button/MaterialAddButton';

interface GroupNodeProps {
  root?: boolean;
  node: GroupNodeType;
}

export const GroupNode = ({ node, children, root }: PropsWithChildren<GroupNodeProps>) => {
  const { t } = useTranslation();
  const [showAutocomplete, setShowAutocomplete] = useState(!node.elements.length);

  const [{ isDragging }, connectDrag] = useDrag({
    item: node,
    canDrag: !root,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const { removeNode, addGroupNode, addSingleNode, setOperatorValue, setNegationValue, errors } = useContext(
    ConditionBuilderContext,
  );

  const errorKey = `${!root ? pathToDotString(node.path) + '.' : ''}elements`;

  const handleAddSingleNode = (cert: Certification) => {
    addSingleNode(node.path, cert);
    setShowAutocomplete(false);
  };

  return (
    <StyledNodeWrapper ref={connectDrag} className={classNames('run-element-wrapper', isDragging && 'is-dragging')}>
      {!root && <NegationInput value={node.negation} onChange={value => setNegationValue(node.path, value)} />}
      <StyledGroupNodeElement className="run-element run-group">
        <StyledGroupNodeOperatorWrapper>
          <OperatorInput
            value={node.operator}
            disabled={node.elements.length < 2}
            onChange={value => setOperatorValue(node.path, value)}
          />
        </StyledGroupNodeOperatorWrapper>
        {children}
        {showAutocomplete && (
          <CertificationInput onSelect={handleAddSingleNode} disabledItems={getCertificationIds(node)}>
            <DeleteButton onClick={() => setShowAutocomplete(false)} />
          </CertificationInput>
        )}
        <StyledGroupNodeActionsWrapper>
          <MaterialAddButton onClick={() => setShowAutocomplete(true)} appearance="ghost">
            {t('certifications.runCondition.addElement')}
          </MaterialAddButton>
          <MaterialAddButton onClick={() => addGroupNode(node.path)} appearance="ghost">
            {t('certifications.runCondition.addGroup')}
          </MaterialAddButton>
          {root || <DeleteButton onClick={() => removeNode(node.path)} />}
        </StyledGroupNodeActionsWrapper>
        {errorKey in errors && (
          <span>
            <ErrorMessage>{errors[errorKey]}</ErrorMessage>
          </span>
        )}
      </StyledGroupNodeElement>
    </StyledNodeWrapper>
  );
};
