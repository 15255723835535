import { LabCertification, LabSummaryCertification, LabSummaryCertificationByGroup } from './types';

export const reformatData = (data: LabCertification[], merge = false): LabSummaryCertification[] => {
  const result: LabSummaryCertification[] = [];
  data.forEach(certification => {
    certification.version.forEach(version => {
      const record = { ...certification, version, children: [] };
      const parent = merge ? result.find(value => value.id === record.id) : null;
      (merge && parent ? parent.children : result)?.push(record);
    });
  });
  return result;
};

export const reformatFlatSummaryDataByGroup = (data: LabSummaryCertification[]): LabSummaryCertificationByGroup[] => {
  const result: LabSummaryCertificationByGroup[] = [];

  data.forEach(certification => {
    let parent = result.find(value => value.id === certification.id);
    if (parent == null) {
      parent = { ...certification, version: null, children: [] as LabSummaryCertificationByGroup[] };
      result.push(parent);
    }
    parent.children.push({ ...certification } as LabSummaryCertificationByGroup);
  });

  return result;
};
