import styled from 'styled-components';
import { Card } from '../../../../../../../shared-components';

export const RfArchitectureWrapper = styled.div`
  color: #666666;
  line-height: 40px;
  margin: 0;
`;

export const RfArchitectureCard = styled(Card)`
  width: 100%;
  overflow: hidden !important;
  .el-card__body {
    padding: 8px 20px !important;
  }
`;

export const WrapperCard = styled(Card)`
  .el-card__body {
    padding-bottom: 1rem;
  }
`;

export const BandHeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  span {
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
    text-overflow: ellipsis;

    &:not(:first-of-type) {
      text-align: center;
    }
  }
`;

export const RfArchitectureError = styled.div`
  min-height: 25px;
  line-height: initial;
`;
