import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Col, Form, FormGroup, Icon, Row, Table } from 'rsuite';
import styled from 'styled-components';

import { apiBaseUrl } from '../../../../config';
import { mergeObjects, reformatDate } from '../../../../helpers';
import { buildDownloadUrl } from '../../../../helpers/build-download-url';
import { Confirm } from '../../../../helpers/confirmationPopup/Confirm';
import { customIconsSet, messageKeyPrefix } from '../../../../helpers/constants';
import { ErrorObject, UploaderServerErrorResponse } from '../../../../helpers/types';
import { TestingException } from '../../../../redux/modules/application-flow';
import {
  setConformanceResults,
  setInteroperabilityResult,
  setInteroperabilityResultFile,
  updateExplanation,
  updateLabRequestProperty,
} from '../../../../redux/modules/testing-flow';
import { TestResult } from '../../../../redux/modules/testing-flow/types';
import { FileUploader } from '../../../../shared-components';
import { UploadedFile } from '../../../../shared-components/file-upload/types';
import { ErrorMessage } from '../../../../shared-components/helper-components/ErrorMessage';
import { PreviouslyCertifiedLegendTitles } from '../../../../shared-components/styled-components/PreviouslyCertifiedLegend';
import { RsColumn, RsTable } from '../../../../shared-components/theme';
import { UploadFileType } from '../../../../shared-components/uploader/FileUploader';
import { FileUploadModal } from '../../../partials/FileUploadModal';
import { NAComponent } from '../../../partials/NAComponent';
import { Exception } from '../../../partials/exception/Exception';
import { CCDetails } from '../../application/partials/cc-details/CCDetails';
import { LRCertification, LRConformanceResults, LRInteroperabilityResults, LabRequest } from '../types';
import { IconsLegend, TestResultTable } from './';

const { HeaderCell, Cell } = Table;

const UploadConformanceTestWrapper = styled.div`
  margin-right: 2.2rem;
  min-height: 50px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .rs-icon {
    margin-left: 2.2rem;
  }
`;

interface Props {
  isShowedPopup: boolean;
  setIsShowedPopup: (value: boolean) => void;
  setFileIsLoading: (value: boolean) => void;
  labRequest: LabRequest;
  certifications: LRCertification[];
  interoperabilityTestResults: LRInteroperabilityResults[];
  validationErrors: ErrorObject;
}

export const UploadResultsStep = ({
  isShowedPopup,
  setIsShowedPopup,
  labRequest,
  certifications,
  interoperabilityTestResults,
  setFileIsLoading,
  validationErrors,
}: Props) => {
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [exceptionAttachmentLoading, setExceptionAttachmentLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorObject>({});
  const [showPopup, setShowPopup] = useState(false);
  const [templateId, setTemplateId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [columnFixed, setColumnFixed] = useState(true);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setErrors(validationErrors);
  }, [validationErrors]);

  useEffect(() => {
    setFileIsLoading(attachmentLoading || exceptionAttachmentLoading);
  }, [attachmentLoading, exceptionAttachmentLoading]);

  const isConformanceTestRequested = certifications.some(cert => cert.conformanceTestRequested);
  const isInteroperabilityTestRequested = certifications.some(cert => cert.interoperabilityTestRequested);

  const hasTemplate = templateId !== null;
  const fileUploadUrl = hasTemplate
    ? `${apiBaseUrl}/upload-file`
    : `${apiBaseUrl}/lab-requests/${labRequest.id}/upload-conformance-result`;

  const resetError = (id: number) => {
    if (id in errors) {
      delete errors[id];
    }
    setErrors({ ...errors });
  };

  const handleInteroperabilityResultChange = (id: number, parentId: number | null, value: TestResult) =>
    dispatch(setInteroperabilityResult(id, parentId, value));

  const handlePopupUpload = (file: UploadedFile | null | LRConformanceResults) => {
    setLoading(true);
    if (file) {
      if (templateId === null) {
        dispatch(setConformanceResults(file as LRConformanceResults));
        setShowPopup(false);
        setLoading(false);
      } else {
        resetError(templateId);
        dispatch(setInteroperabilityResultFile(templateId, file as UploadedFile));
        setShowPopup(false);
        setLoading(false);
      }
    }
  };

  const warningConfirm = (message: string, title: string) => {
    setShowPopup(false);
    return Confirm({
      title: title,
      cancelable: false,
      closeBtn: false,
      yesText: t('applications.cc.warning.btn.gotIt'),
      message: message,
      onAccept: () => setShowPopup(true),
    });
  };

  const handleBundleVersionError = (error: UploaderServerErrorResponse) => {
    const messageKey = error.response.messageKey.replace(messageKeyPrefix, '');
    if (messageKey.includes('testValidatorServiceVersionVerificationError')) {
      return warningConfirm(t(messageKey), t('labRequest.titles.serviceVersion'));
    }
    if (messageKey.includes('testCertificationHashVerificationError')) {
      return warningConfirm(t(messageKey), t('labRequest.titles.bundleVersion'));
    }
    return Promise.resolve(false);
  };

  const handlePopupCancel = () => setShowPopup(false);

  const handleShowPopup = (templateId: number | null = null) => {
    setTemplateId(templateId);
    setShowPopup(true);
  };

  const removeResultFile = (id: number) => dispatch(setInteroperabilityResultFile(id, null));

  const handleExplanationChange = (value: string) =>
    dispatch(updateLabRequestProperty(value, 'testingException.explanation'));

  const handleExplanationAttachmentsChange = (files: UploadedFile[]) =>
    dispatch(updateLabRequestProperty(files, 'testingException.attachments'));

  const handleRequestExceptionChange = (newExceptionData: TestingException | null) =>
    dispatch(updateExplanation(newExceptionData));

  useEffect(() => {
    if (!isShowedPopup && isConformanceTestRequested) {
      setIsShowedPopup(true);
      setShowPopup(true);
    }
  }, []);

  // listen window resize event force rerender to avoid content overflow hidden happen on a fixed column
  useEffect(() => {
    const callback = () => {
      setColumnFixed(false);
      setTimeout(() => {
        setColumnFixed(true);
      }, 1000);
    };
    callback();
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, []);

  return (
    <>
      <Form fluid>
        <h4>{t('testing.uploadResults.testResultTitle', { type: t('applications.labs.conformance') })}</h4>
        {isConformanceTestRequested ? (
          <RsTable
            className="mb-1"
            data={labRequest.conformanceTestResult ? [labRequest.conformanceTestResult] : []}
            height={130}
            loading={loading}
            wordWrap
            renderEmpty={() => (
              <UploadConformanceTestWrapper>
                {t('testing.uploadResults.mustUploadResult')}
                <Icon
                  icon={customIconsSet.UPLOAD_ICON}
                  size="2x"
                  className="pointer"
                  onClick={() => handleShowPopup()}
                />
              </UploadConformanceTestWrapper>
            )}
          >
            <RsColumn flexGrow={1} align="left" verticalAlign="middle" fixed>
              <HeaderCell>{t('testing.uploadResults.resultsFileName')}</HeaderCell>
              <Cell dataKey="name">
                {(rowData: LRConformanceResults) => (
                  <>
                    <span style={{ verticalAlign: 'super' }}>{rowData.name}</span>
                    <a href={buildDownloadUrl(rowData as UploadedFile)} download className="pl-1">
                      <Icon icon={customIconsSet.DOWNLOAD_ICON} size="2x" />
                    </a>
                  </>
                )}
              </Cell>
            </RsColumn>
            <RsColumn width={80} verticalAlign="middle" align="center">
              <HeaderCell>Report</HeaderCell>
              <Cell>
                <a
                  href={`${apiBaseUrl}/reports/conformance?token=${labRequest.conformanceTestResult?.conformanceReportToken}&labRequestId=${labRequest.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon icon={customIconsSet.DOWNLOAD_ICON} size="2x" />
                </a>
              </Cell>
            </RsColumn>
            <RsColumn width={100} verticalAlign="middle" align="center">
              <HeaderCell>{t('testing.uploadResults.validated')}</HeaderCell>
              <Cell dataKey="passed">
                {(rowData: LRConformanceResults) => <>{rowData.passed === 'PASS' ? 'Yes' : 'No'}</>}
              </Cell>
            </RsColumn>
            <RsColumn width={140} verticalAlign="middle" align="center">
              <HeaderCell>{t('testing.uploadResults.uploadedDate')}</HeaderCell>
              <Cell dataKey="uploadedAt">{(rowData: LRConformanceResults) => reformatDate(rowData.uploadedAt)}</Cell>
            </RsColumn>
            <RsColumn width={100} verticalAlign="middle" align="center">
              <HeaderCell>{t('common.actions.upload')}</HeaderCell>
              <Cell dataKey="upload">
                {() => (
                  <Icon
                    icon={customIconsSet.UPLOAD_ICON}
                    size="2x"
                    className="pointer"
                    onClick={() => handleShowPopup()}
                  />
                )}
              </Cell>
            </RsColumn>
          </RsTable>
        ) : (
          <NAComponent />
        )}
        <h4>{t('testing.uploadResults.testResultTitle', { type: t('applications.labs.interoperability') })}</h4>
        {isInteroperabilityTestRequested ? (
          <RsTable className="mb-1" data={interoperabilityTestResults} loading={loading} wordWrap>
            <RsColumn flexGrow={1} align="left" verticalAlign="middle" fixed={columnFixed} minWidth={270}>
              <HeaderCell>{t('testing.uploadResults.templateName')}</HeaderCell>
              <Cell dataKey="fileName">
                {(rowData: LRInteroperabilityResults) => (
                  <>
                    {rowData.template.name}
                    <ul style={{ marginLeft: '-15px' }}>
                      {certifications.map(c => {
                        if (
                          c.interoperabilityTemplate?.name === rowData.template.name &&
                          c.interoperabilityTemplate?.id === rowData.template.id &&
                          c.interoperabilityTemplate?.fileToken === rowData.template.fileToken &&
                          c.interoperabilityTestRequested
                        ) {
                          return (
                            <li style={{ fontWeight: 'bold' }}>
                              {c.name}
                              {' (' + c.roleName + ') '}
                              {c.versionName}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </>
                )}
              </Cell>
            </RsColumn>
            <RsColumn flexGrow={1} verticalAlign="middle" align="center">
              <HeaderCell>{t('testing.uploadResults.resultsFileName')}</HeaderCell>
              <Cell dataKey="resultFile">
                {(rowData: LRInteroperabilityResults) => (
                  <>
                    {rowData.result?.name}
                    {rowData.template.id in errors && (
                      <ErrorMessage className="error-message">{errors[rowData.template.id]}</ErrorMessage>
                    )}
                  </>
                )}
              </Cell>
            </RsColumn>
            <RsColumn width={100} verticalAlign="middle" align="left">
              <HeaderCell />
              <Cell>
                {(rowData: LRInteroperabilityResults) =>
                  rowData.result ? (
                    <>
                      <a href={buildDownloadUrl(rowData.result)} download className="pr-1">
                        <Icon icon={customIconsSet.DOWNLOAD_ICON} size="2x" />
                      </a>
                      <Icon
                        icon="trash-o"
                        size="2x"
                        className="pointer text-muted"
                        style={{ verticalAlign: 'top' }}
                        onClick={() => removeResultFile(rowData.template.id)}
                      />
                    </>
                  ) : null
                }
              </Cell>
            </RsColumn>
            <RsColumn width={140} verticalAlign="middle" align="center">
              <HeaderCell>{t('testing.uploadResults.uploadedDate')}</HeaderCell>
              <Cell dataKey="uploadedAt">
                {(rowData: LRInteroperabilityResults) =>
                  rowData.result ? reformatDate(rowData.result.uploadedAt) : null
                }
              </Cell>
            </RsColumn>
            <RsColumn width={100} verticalAlign="middle" align="center">
              <HeaderCell>{t('common.actions.upload')}</HeaderCell>
              <Cell dataKey="upload">
                {(rowData: LRInteroperabilityResults) => (
                  <Icon
                    icon={customIconsSet.UPLOAD_ICON}
                    size="2x"
                    className="pointer"
                    onClick={() => handleShowPopup(rowData.template.id)}
                  />
                )}
              </Cell>
            </RsColumn>
          </RsTable>
        ) : (
          <NAComponent />
        )}

        <h4>{t('testing.uploadResults.confirmResultTitle')}</h4>
        <PreviouslyCertifiedLegendTitles title={t('applications.certifications.prevCertifiedLegend')} />
        <TestResultTable
          certifications={certifications}
          uploadStep
          loading={loading}
          handleInteroperabilityResultChange={handleInteroperabilityResultChange}
        />
        <IconsLegend />
      </Form>
      <Exception
        text={t('applications.labs.requestExceptionRequired')}
        exception={labRequest.testingException || null}
        handleExplanationChange={handleExplanationChange}
        handleExplanationAttachmentsChange={handleExplanationAttachmentsChange}
        requestExceptionChange={handleRequestExceptionChange}
        setExceptionAttachmentLoading={setExceptionAttachmentLoading}
        errors={mergeObjects(labRequest.errors, errors)}
        field="testingException.explanation"
        isChecked={labRequest.testingException !== null}
      />
      <h4>{t('applications.cc.wifiComponent')}</h4>
      <CCDetails
        cc={labRequest.application.componentCombination.componentCombination}
        ccVariantSource={labRequest.application.componentCombination.componentCombinationVariantSource}
      />
      <Row>
        <Col xs={12}>
          <h4>{t('testing.uploadResults.additionalAttachments')}</h4>
          <FormGroup style={{ marginBottom: 0 }}>
            <FileUploader
              fileType={UploadFileType.ATL_ATTACHMENT}
              files={labRequest.attachments}
              onChange={files => {
                dispatch(updateLabRequestProperty({ attachments: files }));
              }}
              onLoadingChange={setAttachmentLoading}
            />
          </FormGroup>
        </Col>
      </Row>
      <FileUploadModal
        url={fileUploadUrl}
        bodyParams={hasTemplate ? { fileType: 'INTEROPERABILITY_TEST_RESULT' } : {}}
        onClose={handlePopupCancel}
        onSuccess={handlePopupUpload}
        showPopup={showPopup}
        title={t('testing.uploadResults.uploadTitle', {
          type: !hasTemplate ? t('applications.labs.conformance') : t('applications.labs.interoperability'),
        })}
        bodyTitle={t('testing.uploadResults.uploadText', {
          type: !hasTemplate
            ? t('applications.labs.conformance').toLowerCase()
            : t('applications.labs.interoperability').toLowerCase(),
        })}
        onError={handleBundleVersionError}
      />
    </>
  );
};
