import { IterableObject } from '../types';
import { AppStatuses } from '../../components/pages/application/types';

export const applicationStatuses: IterableObject<string> = {
  draft: AppStatuses.DRAFT,
  needsAttention: AppStatuses.NEEDS_ATTENTION,
  inTesting: AppStatuses.IN_TESTING,
  pendingApproval: AppStatuses.PENDING_APPROVAL,
  approved: AppStatuses.APPROVED,
  revoked: AppStatuses.REVOKED,
};

export const applicationStatusTitles: IterableObject<string> = {
  [applicationStatuses.draft]: 'Draft',
  [applicationStatuses.needsAttention]: 'Needs Attention',
  [applicationStatuses.inTesting]: 'In Testing',
  [applicationStatuses.pendingApproval]: 'Pending Approval',
  [applicationStatuses.approved]: 'Approved',
  [applicationStatuses.revoked]: 'Revoked',
};

export const applicationDecision: IterableObject<string> = {
  QUALIFIED: 'Qualified',
  CERTIFIED: 'Certified',
};
