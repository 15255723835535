import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
// @ts-ignore
import _var from '../../../styles/_variables.scss';

const StyledAgreementText = styled.div`
  text-align: justify;
  a {
    color: ${_var.primary};
  }
`;

export const GDPRAgreementText = () => (
  <StyledAgreementText>
    <Trans
      i18nKey="legalContent.privateDataTransfer.body"
      components={{
        noticeLink: <a target="_blank" rel="noreferrer" href="https://www.wi-fi.org/gdpr-privacy-notice" />,
      }}
    />
  </StyledAgreementText>
);
