import React, { useEffect, useRef, useState } from 'react';
import { Popover, Whisper, Table } from 'rsuite';
const { Cell } = Table;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CellWithPopover = ({ rowData, dataKey, children, ...props }: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isEllipsisApplied, setIsEllipsisApplied] = useState(false);
  const content = children ? children(rowData) : rowData[dataKey] ? String(rowData[dataKey]) : '';
  const getContent = () => {
    if (isEllipsisApplied) {
      return (
        <Whisper
          placement="topStart"
          speaker={<Popover style={{ maxWidth: window.innerWidth / 3, wordBreak: 'break-word' }}>{content}</Popover>}
        >
          <span className="inner">{content}</span>
        </Whisper>
      );
    }
    return content;
  };

  useEffect(() => {
    if (ref.current) {
      if (ref.current.offsetParent) {
        // @ts-ignore (+20px cuz we have padding in parent element)
        setIsEllipsisApplied(ref.current.offsetWidth + 20 > ref.current.offsetParent.offsetWidth);
      } else {
        setIsEllipsisApplied(false);
      }
    }
  }, [ref.current?.offsetWidth, window.innerWidth, rowData]);

  return (
    <Cell {...props}>
      <span ref={ref}>{getContent()}</span>
    </Cell>
  );
};
