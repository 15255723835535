import axios, { CancelToken } from '../../helpers/axios-setup';
import { CancelTokenSource } from 'axios';

let token: CancelTokenSource | undefined = undefined;
export const searchCapabilities = (roleId: number, search: string) => {
  if (token) {
    (token as CancelTokenSource).cancel();
  }
  token = CancelToken.source();
  return axios.get(`/certifications/capabilities?roleId=${roleId}&search=${search}`, {
    cancelToken: token?.token,
  });
};
