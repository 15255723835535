import React, { useEffect } from 'react';
import '../styles/layout.scss';
import Header from './partials/Header';
import { Footer } from './partials/Footer';
import { EnvironmentIdentifier, useTestEnvironment } from './partials/EnvironmentIdentifier';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '../redux/rootReducer';
import { User } from '../redux/modules/user/types';
import { Confirm } from '../helpers/confirmationPopup/Confirm';
import { GDPRAgreementText } from '../components/partials/gdpr/GDPRAgreementText';
import { acceptGDPRPolicy, logoutUser } from '../redux/modules/user';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

interface SelectorProps {
  user: User | null;
  showGDPRConsent: boolean;
}

const LayoutComponent = (props: React.PropsWithChildren<{}>): React.ReactElement<string> => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { user, showGDPRConsent } = useSelector<RootReducer, SelectorProps>(state => ({
    user: state.userReducer.user,
    showGDPRConsent: !!state.userReducer.user?.requiresGdprDataTransferConsent,
  }));

  useEffect(() => {
    if (showGDPRConsent) {
      Confirm({
        size: 'sm',
        title: <span className="dataTransferTitle">{t('legalContent.privateDataTransfer.title')}</span>,
        message: <GDPRAgreementText />,
        yesText: t('legalContent.privateDataTransfer.accept'),
        noText: t('legalContent.privateDataTransfer.reject'),
        onAccept: () => {
          dispatch(acceptGDPRPolicy(addToast));
        },
        onReject: () => {
          dispatch(logoutUser());
        },
      });
    }
  }, [user]);

  const isTestEnvironment = useTestEnvironment();

  return (
    <div className="layout">
      {!showGDPRConsent && (
        <>
          {isTestEnvironment && <EnvironmentIdentifier />}
          <Header />
          <section className="main" style={isTestEnvironment ? { marginTop: 84 } : {}}>
            {props.children}
          </section>
          <Footer />
        </>
      )}
    </div>
  );
};

export default LayoutComponent;
