import React from 'react';
import styled from 'styled-components';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ReportProblemSharpIcon from '@material-ui/icons/ReportProblemSharp';
import { useTranslation } from 'react-i18next';

import { Tooltip, TooltipProps } from '../tooltip/Tooltip';
import { CertificationStatuses } from '../../helpers/constants/certification-statuses';
import { getLifeCycleStatusKey, statusColors } from '../../helpers/constants';

// @ts-ignore
import _var from '../../styles/_variables.scss';

const WarningIconOutlined = styled(ReportProblemOutlinedIcon)<{ status: string }>`
  color: ${({ status }) => (status in statusColors ? statusColors[status].color : _var.textLightGray)};
  margin-right: 10px;
  margin-left: 10px;
  && {
    font-size: 24px;
  }
`;

const IndigoWarningIcon = styled(ReportProblemSharpIcon)<{ status: string }>`
  color: ${({ status }) => (status in statusColors ? statusColors[status].color : _var.textLightGray)};
  margin-right: 10px;
  margin-left: 10px;
  && {
    font-size: 24px;
  }
`;

interface WarningTooltipProps {
  variant: 'outlined' | 'rounded';
  status: CertificationStatuses;
  onHoverTooltipTextWarning?: boolean;
}

export const WarningTooltip: React.FC<WarningTooltipProps & TooltipProps> = ({
  status,
  placement,
  variant = 'outlined',
  onHoverTooltipTextWarning,
  ...rest
}) => {
  const { t } = useTranslation();

  const getTooltipWarningText = (status: CertificationStatuses) => {
    switch (status) {
      case CertificationStatuses.FIRST_RETIREMENT_PHASE:
        return t('certification.firstRetirementPhase');
      case CertificationStatuses.SECOND_RETIREMENT_PHASE:
        return t('certification.secondRetirementPhase');
      case CertificationStatuses.THIRD_RETIREMENT_PHASE:
        return t('certification.thirdRetirementPhase');
      default:
        return '';
    }
  };

  return (
    <Tooltip
      placement={placement ?? 'top-start'}
      translationKey={onHoverTooltipTextWarning ? getTooltipWarningText(status) : undefined}
      {...rest}
    >
      {variant === 'outlined' ? (
        <WarningIconOutlined
          status={
            status ? getLifeCycleStatusKey(status) : getLifeCycleStatusKey(CertificationStatuses.THIRD_RETIREMENT_PHASE)
          }
        />
      ) : (
        <IndigoWarningIcon
          status={
            status ? getLifeCycleStatusKey(status) : getLifeCycleStatusKey(CertificationStatuses.THIRD_RETIREMENT_PHASE)
          }
        />
      )}
    </Tooltip>
  );
};
