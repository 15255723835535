import React, { ChangeEvent, HTMLProps } from 'react';
import { ErrorObject } from '../../helpers/types';
import { withLabel, WrappedInputProps } from './withLabel';
import { extractError } from './utils';

// @ts-ignore
interface FieldProps extends HTMLProps<HTMLInputElement> {
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactElement<string> | string;
  showCharacterNumber?: boolean;
}

const Field = ({ name, placeholder, value, onChange, showCharacterNumber = false, ...props }: FieldProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => onChange && onChange(event.target.value, event);
  return (
    <>
      <input
        placeholder={placeholder}
        name={name}
        type="text"
        className="rs-input"
        value={value}
        onChange={handleChange}
        {...props}
      />
      {showCharacterNumber && props.maxLength && (
        <div className="rs-control-char-number">
          {value?.toString().length}/{props.maxLength}
        </div>
      )}
    </>
  );
};
const MemorizedField = React.memo(withLabel<FieldProps>(Field), (prevProps, nextProps) => {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.label === nextProps.label &&
    prevProps.value === nextProps.value &&
    prevProps.defaultValue === nextProps.defaultValue &&
    prevProps.error === nextProps.error &&
    prevProps.onChange === nextProps.onChange
  );
});

interface LabeledFieldProps extends FieldProps {
  errors?: ErrorObject;
}
export const LabeledInput = ({ errors, name, ...props }: WrappedInputProps<LabeledFieldProps>) => {
  // @ts-ignore
  return <MemorizedField name={name} error={extractError(errors, name)} {...props} />;
};
