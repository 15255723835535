import React, { CSSProperties, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { loadUser, logoutUser } from '../../redux/modules/user';
import { userState } from '../../redux/modules/user/reducer';
import logo from '../../assets/logo.png';
import { env } from '../../config';
import { User, UserInfo } from '../../redux/modules/user/types';
import { useTranslation } from 'react-i18next';
import { atlRoles, memberRoles, wfaAdminRole, wfaEngineerRole, wfaStaffRoles } from '../../helpers/constants';
import { Popover, Tooltip, Whisper } from 'rsuite';
import { ImpersonateModal } from '../../components/partials/impersonate/ImpersonateModal';
import { unImpersonate } from '../../api/impersonate';
import { AxiosResponse } from 'axios';
import { handleRequestFail } from '../../helpers/request-fail-handler';
import { useToasts } from 'react-toast-notifications';
import Cookie from 'js-cookie';
import { useHistory } from 'react-router';
import { Confirm } from '../../helpers/confirmationPopup/Confirm';
import {
  HeaderWrapper,
  ImpersonateIcon,
  LogoMenuWrapper,
  LogoWrapper,
  MenuWrapper,
  UserInfoWrapper,
} from './header-styled-components';
import { Menu, MenuItem } from '../../shared-components/material-menu/Menu';
import { useTestEnvironment } from './EnvironmentIdentifier';

interface UserReducer {
  logoutUser: () => void;
  loadUser: () => void;
}

const Header = ({
  user,
  logoutUser,
  loadUser,
  withMenu = true,
}: { user: User | null } & UserReducer & { withMenu?: boolean }): React.ReactElement<string> => {
  const { t } = useTranslation();
  if (!withMenu) {
    return (
      <HeaderWrapper>
        <LogoMenuWrapper>
          <LogoWrapper className="logo-wrapper mr-3">
            <img src={logo} alt="Logo" />
            {t('application.title')}
          </LogoWrapper>
        </LogoMenuWrapper>
      </HeaderWrapper>
    );
  }

  const isTestEnvironment = useTestEnvironment();

  const { addToast } = useToasts();
  const history = useHistory();
  const [showImpersonation, setImpersonationModalState] = useState(false);
  const openInteropModal = () => setImpersonationModalState(true);
  const hideInteropModal = () => setImpersonationModalState(false);

  const logout = (): void => {
    Confirm({
      title: t('common.placeholders.areYouSure'),
      message: t('common.placeholders.logoutWarningMessage'),
      onAccept: () => {
        logoutUser();
      },
    });
  };

  const impersonateLogout = (): void => {
    Confirm({
      title: t('common.placeholders.areYouSure'),
      message: t('auth.unImpersonateMessage'),
      onAccept: () =>
        unImpersonate()
          .then((response: AxiosResponse<{ jwt: string }>) => {
            Cookie.set('access-token', response.data.jwt);
            loadUser();
            history.push('/');
          })
          .catch(error => handleRequestFail(error, addToast)),
    });
  };

  const UserPopOver = ({ user, style }: { user: User | UserInfo; style?: CSSProperties }) => (
    <Whisper
      trigger="hover"
      placement="bottomEnd"
      speaker={
        <Popover title="User Info">
          <p>
            <span className="text-muted">{t('auth.userInfo.name')}:</span> {user.firstName} {user.lastName}
          </p>
          <p>
            <span className="text-muted">{t('auth.userInfo.company')}:</span> {user.company.name}
          </p>
        </Popover>
      }
    >
      <span style={style}>
        {user.firstName} {user.lastName}
      </span>
    </Whisper>
  );

  const rightSidePart = user ? (
    <Fragment>
      {user?.originalUser ? (
        <>
          <UserPopOver user={user.originalUser} style={{ marginRight: '0.3rem' }} />(<UserPopOver user={user} />)
        </>
      ) : (
        <UserPopOver user={user} />
      )}
      {wfaStaffRoles.includes(user.authority) ? (
        <Whisper placement="bottomEnd" trigger="hover" speaker={<Tooltip>{t('auth.impersonate')}</Tooltip>}>
          <ImpersonateIcon icon="user-secret" onClick={openInteropModal} size="lg" />
        </Whisper>
      ) : null}
      {user.originalUser ? (
        <Whisper placement="bottomEnd" trigger="hover" speaker={<Tooltip>{t('auth.unImpersonate')}</Tooltip>}>
          <ImpersonateIcon icon="user-times" onClick={impersonateLogout} size="lg" />
        </Whisper>
      ) : null}
      <Whisper placement="bottomEnd" trigger="hover" speaker={<Tooltip>{t('auth.logout')}</Tooltip>}>
        <ImpersonateIcon icon="sign-out" onClick={logout} size="lg" />
      </Whisper>
    </Fragment>
  ) : null;
  return (
    <>
      <HeaderWrapper id="header-wrapper" style={isTestEnvironment ? { top: 20 } : {}}>
        <LogoMenuWrapper>
          <LogoWrapper className="logo-wrapper mr-3">
            <img src={logo} alt="Logo" />
            {t('application.title')}
          </LogoWrapper>
          <MenuWrapper>
            <Menu>
              {wfaStaffRoles.includes((user as User).authority) ? (
                <MenuItem to="/pending-approval">{t('menu.pendingApproval')}</MenuItem>
              ) : null}
              {[...memberRoles, ...wfaStaffRoles, ...atlRoles].includes((user as User).authority) ? (
                <MenuItem to="/application">{t('menu.applications')}</MenuItem>
              ) : null}
              {[...memberRoles, ...wfaStaffRoles].includes((user as User).authority) ? (
                <MenuItem to="/product">{t('menu.products')}</MenuItem>
              ) : null}
              {[wfaAdminRole, wfaEngineerRole].includes((user as User).authority) ? (
                <>
                  <MenuItem to="/certification">{t('menu.certifications')}</MenuItem>
                  <MenuItem to="/test-case">{t('menu.tc')}</MenuItem>
                </>
              ) : null}
              {atlRoles.includes((user as User).authority) ? (
                <>
                  <MenuItem to="/lab-request">{t('menu.labRequest')}</MenuItem>
                  <MenuItem to="/lab-info">{t('menu.labInfo')}</MenuItem>
                </>
              ) : null}
              {[wfaAdminRole].includes((user as User).authority) ? (
                <MenuItem dropDown label={t('menu.admin')} to={'/admin'}>
                  <MenuItem to="/admin/atl-qualifications">{t('menu.atlQualifications')}</MenuItem>
                  <MenuItem to="/admin/change-owning-company-summary">{t('menu.changeOwningCompany')}</MenuItem>
                  <MenuItem to="/admin/transactions">{t('menu.transactions')}</MenuItem>
                </MenuItem>
              ) : null}
              {env === 'development' ? <MenuItem to="/swagger-ui">{t('menu.swagger')}</MenuItem> : null}
            </Menu>
          </MenuWrapper>
        </LogoMenuWrapper>
        <UserInfoWrapper className="user-wrapper">{rightSidePart}</UserInfoWrapper>
      </HeaderWrapper>
      {wfaStaffRoles.includes((user as User).authority) ? (
        <ImpersonateModal isActive={showImpersonation} hideModal={hideInteropModal} />
      ) : null}
    </>
  );
};
const mapStateToProps = (state: { userReducer: userState }): { user: User | null } => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProps, { logoutUser, loadUser })(Header);
