import React from 'react';
import { Col } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { DetailsItem } from '../../../../../../partials';
import { AppFlows, ApplicationFlowState } from '../../../../../../../redux/modules/application-flow';
import { RowWithMargin, Section } from '../../../../../application/partials';
import { FileItem } from '../../../../../../partials/FileItem';
import { RootReducer } from '../../../../../../../redux/rootReducer';
import { ProductInfoStep } from '../../../../../../../api/application/types';
import { isProductOfSolutionProvider } from '../../helpers';
import { WrapWithPublicIcon } from '../../../../../../../shared-components/helper-components/WrapWithPublicIcon';
import { Tooltip } from '../../../../../../../shared-components/tooltip/Tooltip';
import { VariantNameTooltipContent } from '../../VariantNameTooltipContent';

export const ViewVariantSection = ({ data }: { data: ProductInfoStep }) => {
  const { t } = useTranslation();

  const { ...application } = useSelector<RootReducer, ApplicationFlowState>(
    state => state.applicationFlow,
    shallowEqual,
  );

  const showQualifiedSolutionFields =
    isProductOfSolutionProvider(application) && application['@type'] !== AppFlows.DERIVATIVE;

  return (
    <Section>
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem
            value={data.modelVariants[0].name}
            label={
              <>
                <WrapWithPublicIcon content={t('applications.info.variantName')} />
                <Tooltip width={680} content={<VariantNameTooltipContent />} />
              </>
            }
          />
        </Col>
        <Col xs={12}>
          <DetailsItem value={application.id} label={t('applications.summary.appId')} />
        </Col>
      </RowWithMargin>
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem
            label={t('applications.info.searchable')}
            value={
              (data.modelVariants[0].searchableByPublic ? t('common.options.yes') : t('common.options.no')) as string
            }
          />
        </Col>
        <Col xs={12}>
          <DetailsItem
            label={t('applications.info.available')}
            value={
              (data.modelVariants[0].availableAsDerivative ? t('common.options.yes') : t('common.options.no')) as string
            }
          />
        </Col>
      </RowWithMargin>

      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem label={t('applications.info.docs')}>
            {data.documents.length ? data.documents.map(file => <FileItem file={file} key={file.id} />) : null}
          </DetailsItem>
        </Col>
        {showQualifiedSolutionFields ? (
          <Col xs={12}>
            <DetailsItem
              label={t('applications.info.availableAsQualifiedSolution')}
              value={
                (data.modelVariants[0].availableAsQualifiedSolution
                  ? t('common.options.yes')
                  : t('common.options.no')) as string
              }
            />
          </Col>
        ) : null}
      </RowWithMargin>
    </Section>
  );
};
