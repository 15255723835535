import { ReactNode } from 'react';
import { AxiosError } from 'axios';
import { messageKeyPrefix } from './constants';
import i18n from './i18n-setup';

export const handleRequestFail = (error: AxiosError, addToast: (content: ReactNode, options?: Options) => void) => {
  const messageKey = (error.response?.data?.messageKey || '').replace(messageKeyPrefix, '');
  const params = error.response?.data?.messageParameters;
  let message = i18n.t(messageKey, params);
  if (!message || message === messageKey) {
    message = error.response?.data?.message || error.message;
  }

  addToast(message, {
    appearance: 'error',
    autoDismiss: true,
    // @ts-ignore
    autoDismissTimeout: 3000,
  });
};
