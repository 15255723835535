import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { PageTemplate } from '../../../partials';

import { SortingOptions, SortType } from '../../../../components/pages/test-case/types';
import { loadLabs, resetState, selectAtlQualificationsState } from '../../../../redux/modules/admin/atl-qualifications';
import { MaterialAddButton } from '../../../../shared-components/button/MaterialAddButton';
import { LabsSummaryFiltersObject } from './../../../../helpers/types';
import { LabsSummaryAdvancedFilters } from './components/LabsSummaryAdvancedFilters';
import { LabsSummaryTable } from './components/LabsSummaryTable';
import { Lab, LabSubType } from './types';
// @ts-ignore
import _var from '../../../../styles/_variables.scss';

const StatCount = ({ isBold = false, children }: PropsWithChildren<{ isBold?: boolean }>) => {
  return <span style={{ fontWeight: isBold ? 'bolder' : 'normal', marginLeft: isBold ? 0 : 20 }}>{children}</span>;
};

const StatCountWrapper = ({ statCountMap }: { statCountMap: Map<string, number> }) => {
  let totalCount = 0;

  const statList: JSX.Element[] = [];
  for (const [key, value] of statCountMap) {
    totalCount += value;
    statList.push(<StatCount key={key}>{`${key} : ${value}`}</StatCount>);
  }
  statList.splice(0, 0, <StatCount isBold={true} key="total">{`Total: ${totalCount}`}</StatCount>);

  return (
    <span
      style={{
        backgroundColor: _var.lightGray,
        padding: '5px 10px',
        borderRadius: 4,
        fontSize: 12,
        height: 32,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {statList}
    </span>
  );
};

export const AtlQualificationsPage = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [selectedLabIds, setSelectedLabIds] = useState<number[]>([]);

  const state = useSelector(selectAtlQualificationsState);
  const { labs, isLoadingLabs } = state;

  const [filters, setFilters] = useState<LabsSummaryFiltersObject>({
    search: null,
    subtype: null,
  });
  const [sorting, setSorting] = useState<SortingOptions>({ sortColumn: 'name', sortType: 'asc' });

  const handleSortChange = (sortColumn: string, sortType: SortType) => {
    setSorting({ sortColumn, sortType });
  };

  const needFilterSearch = filters.search && filters.search.length > 0;
  const needFilterSubType = filters.subtype && filters.subtype.length > 0;
  const filterBySearch = (lab: Lab) => {
    return (
      lab.id.toString().indexOf(filters.search) != -1 ||
      lab.name.toLowerCase().indexOf(filters.search.toLowerCase()) != -1 ||
      lab.subtype.toLowerCase().indexOf(filters.search.toLowerCase()) != -1
    );
  };
  const filterLabs =
    !needFilterSearch && !needFilterSubType
      ? labs
      : labs.filter((lab: Lab) => {
          return (
            (!needFilterSearch || filterBySearch(lab)) && (!needFilterSubType || filters.subtype.includes(lab.subtype))
          );
        });

  const statCountMap = useMemo(() => {
    const map = new Map<string, number>([
      [LabSubType.ATL, 0],
      [LabSubType.CATL, 0],
      [LabSubType.MCTL, 0],
      [LabSubType.STL, 0],
    ]);
    for (const lab of labs) {
      let count = 0;
      if (map.has(lab.subtype)) {
        count = map.get(lab.subtype);
      }
      map.set(lab.subtype, ++count);
    }
    return new Map<string, number>([...map.entries()].sort());
  }, [labs]);

  const handleSelectSingle = (id: number, checked: boolean) => {
    setSelectedLabIds(prevState => {
      let newState = [...prevState];
      if (checked) newState.push(id);
      else newState = newState.filter(val => val !== id);
      return newState;
    });
  };

  const handleSelectAll = (checked?: boolean) => {
    setSelectedLabIds(checked ? filterLabs.map(l => l.id) : []);
  };

  const isSelectAll = () => {
    return filterLabs.length > 0 && filterLabs.every(l => selectedLabIds.includes(l.id));
  };

  const isSelectPartial = () => {
    return !isSelectAll() && filterLabs.some(l => selectedLabIds.includes(l.id));
  };

  const handleAddLabQualificationsClick = () => {
    const labIds = selectedLabIds.sort().join(',');
    window.open(`#/admin/atl-qualifications/enable?labIds=${labIds}`);
  };

  useEffect(() => {
    setSelectedLabIds([]);
  }, [filters]);

  useEffect(() => {
    dispatch(loadLabs(addToast));
    return () => {
      dispatch(resetState());
    };
  }, []);

  return (
    <PageTemplate
      title={t('admin.qualifications.labsTitle')}
      noFooter
      actionRight={
        <div className="btn-wrapper">
          <MaterialAddButton
            appearance="primary"
            className="add-qualification"
            onClick={handleAddLabQualificationsClick}
            disabled={selectedLabIds.length === 0}
          >
            {t('admin.qualifications.addLabQualification')}
          </MaterialAddButton>
        </div>
      }
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <LabsSummaryAdvancedFilters appliedFilters={filters} updateFilters={setFilters} />
        <StatCountWrapper statCountMap={statCountMap} />
      </div>
      <LabsSummaryTable
        data={filterLabs}
        loading={isLoadingLabs}
        sorting={sorting}
        onChangeSorting={handleSortChange}
        selectedLabIds={selectedLabIds}
        handleSelectSingle={handleSelectSingle}
        handleSelectAll={handleSelectAll}
        isSelectPartial={isSelectPartial() && !isSelectAll()}
        isSelectAll={isSelectAll()}
      />
    </PageTemplate>
  );
};
