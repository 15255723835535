import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon } from 'rsuite';

import { LabeledCheckbox } from '../../../../../../shared-components/labeled-inputs';
import { CircularLoader } from '../../../../../../shared-components/loader/CircularLoader';
import { customIconsSet } from '../../../../../../helpers/constants';
import { ErrorObject, SupportingFilesType } from '../../../../../../helpers/types';
import { UploadedFile } from '../../../../../../shared-components/file-upload/types';
import { getSupportingFileInfo } from '../../../../../../api/files/get-supporting-doc-file-info';
import { handleRequestFail } from '../../../../../../helpers/request-fail-handler';
import { buildDownloadUrl } from '../../../../../../helpers/build-download-url';
import { Anchor } from '../../../../../../shared-components/styled-components';

// @ts-ignore
import _scss from '../../../../../../styles/_variables.scss';

const CheckboxWrapper = styled(LabeledCheckbox)`
  &.has-error {
    .text-danger {
      bottom: -6px;
      left: 9px;
    }

    .rs-checkbox .rs-checkbox-wrapper .rs-checkbox-inner:before {
      border-color: ${_scss.errorRed};
    }
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 4px;
`;

const LoaderWrapper = styled.div`
  position: relative;
`;

export const LegalContentCheckbox = ({
  type,
  messageKey,
  name,
  errors,
  checked,
  onChange,
}: {
  type: SupportingFilesType;
  messageKey: string;
  name: string;
  errors: ErrorObject;
  checked: boolean;
  onChange: (checked: boolean, name: string) => void;
}) => {
  const [fileData, setFileData] = useState<UploadedFile>();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  useEffect(() => {
    getSupportingFileInfo(type)
      .then(({ data }: AxiosResponse<UploadedFile>) => {
        setFileData(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        handleRequestFail(error, addToast);
      });
  }, []);
  const description = t(messageKey);
  const match = description.match(new RegExp(/\[linkStart\](.+)\[linkEnd\]/));

  const getContent = () => {
    if (loading) {
      return null;
    }
    if (fileData && match) {
      const [full, text] = match;
      const [start, end] = description.split(full);
      return (
        <>
          {start}
          <Anchor href={buildDownloadUrl(fileData)} download>
            {text}
            <StyledIcon icon={customIconsSet.PDF} size="2x" className="pl-2" />
          </Anchor>
          {end}
        </>
      );
    }
    return description;
  };

  if (loading) {
    return (
      <LoaderWrapper>
        <CircularLoader size={14} />
      </LoaderWrapper>
    );
  }

  return (
    <CheckboxWrapper
      name={name}
      errors={errors}
      checked={checked}
      noPadding
      onChange={(value: string, checked: boolean) => onChange(checked, name)}
    >
      {getContent()}
    </CheckboxWrapper>
  );
};
