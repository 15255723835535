import { DragObjectWithType } from 'react-dnd';
import { CertificationType } from '../../components/pages/certification/types';

export type Path = number[];

export enum NodeTypes {
  GROUP = 'Group',
  ELEMENT = 'Element',
}

export enum ConditionOperator {
  OR = 'OR',
  AND = 'AND',
  NONE = 'NONE',
}

export interface Role {
  id: number;
  name: string;
}

export interface Certification {
  id: number;
  name: string;
  role: Role;
  type: CertificationType;
}

export interface GenericNodeType extends DragObjectWithType {
  uid: string;
  path: Path;
  '@type': NodeTypes;
  negation: boolean;
}

export interface GroupNodeType extends GenericNodeType {
  operator: ConditionOperator;
  elements: GenericNodeType[];
}

export interface SingleNodeType extends GenericNodeType {
  certification: Certification;
}
