import { withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const LifeCycleStepConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: '-100%',
    right: '100%',
  },
  active: {
    '& $line': {
      background: _var.success,
    },
  },
  completed: {
    '& $line': {
      background: _var.success,
    },
  },
  line: {
    border: 0,
    height: 8,
    backgroundColor: _var.grayBorder,
  },
})(StepConnector);

export default LifeCycleStepConnector;
