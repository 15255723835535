import React, { useState, useEffect, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ConditionComponentProps, FilterParameterValuesType, ConditionParameterValuesType } from '../types';
import { useStyles } from '../styles';

// to generate initial data for outside
export const generateCheckboxConditionInitParameterValue = (
  selected: string[],
  showSelectedLabel: boolean,
  data: ConditionParameterValuesType[],
  prefixLabel?: string,
): FilterParameterValuesType => {
  const initValues = [];
  for (const s of selected) {
    const gotIt = data.find(d => d.value === s);
    if (gotIt) {
      initValues.push({
        value: s,
        label: prefixLabel ? `${prefixLabel}: ${gotIt.label}` : gotIt.label,
      });
    }
  }
  if (initValues.length == 0) return null;
  return {
    values: initValues,
    showSelectedLabel,
  };
};

export const getCheckboxConditionCurrentValue = (obj: FilterParameterValuesType): string[] => {
  return obj.values.map(v => v.value as string);
};

export function CheckboxCondition({
  parameterName,
  updateFilterParameter,
  currentConditionParameterValues,
  showSelectedLabel,
  options,
}: ConditionComponentProps) {
  // const { t } = useTranslation();
  const classes = useStyles();

  // initail data from outside
  const [selected, setSelected] = useState<ConditionParameterValuesType[]>(
    currentConditionParameterValues ? currentConditionParameterValues : [],
  );

  // for clear all action from outside
  useEffect(() => {
    setSelected(currentConditionParameterValues ? currentConditionParameterValues : []);
  }, [currentConditionParameterValues]);

  // options data for specific component rendering
  const { data = [], perRowNumber = 2, prefixLabel } = options;

  const generateParameterValue = (values: ConditionParameterValuesType[]): FilterParameterValuesType => {
    return { values, showSelectedLabel };
  };

  const handleChange = (value: ConditionParameterValuesType, checked: boolean) => {
    if (checked) {
      setSelected(prev => {
        const newState = [...prev];
        newState.push(prefixLabel ? { ...value, label: `${prefixLabel}: ${value.label}` } : value);
        updateFilterParameter(parameterName, generateParameterValue(newState));
        return newState;
      });
    } else {
      setSelected(prev => {
        const index = prev.findIndex(item => item.value === value.value);
        const newState = [...prev.slice(0, index), ...prev.slice(index + 1)];
        updateFilterParameter(parameterName, newState.length === 0 ? null : generateParameterValue(newState));
        return newState;
      });
    }
  };

  const lastRowStartIndex = useMemo(
    () => (data.length % perRowNumber == 0 ? data.length - perRowNumber : data.length - (data.length % perRowNumber)),
    [data.length, perRowNumber],
  );

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {data.map((item: ConditionParameterValuesType, index: number) => {
        return (
          <div
            key={`checkbox-condition-${index}`}
            style={{
              width: `${100 / perRowNumber}%`,
              paddingBottom: index < lastRowStartIndex ? 15 : 0,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon color="primary" />}
                  checkedIcon={<CheckBoxIcon color="primary" />}
                  checked={selected.findIndex(selectedItem => selectedItem.value === item.value) !== -1}
                  onChange={(event, checked) => {
                    handleChange({ value: item.value, label: item.label }, checked);
                  }}
                  className={classes.checkbox}
                />
              }
              label={item.label}
              labelPlacement="end"
              className={classes.checkboxLabel}
            />
          </div>
        );
      })}
    </div>
  );
}
