import React from 'react';
import { Icon } from 'rsuite';
import styled from 'styled-components';
import Header from '../../../layouts/partials/Header';
// @ts-ignore
import _var from '../../../styles/_variables.scss';
import { customIconsSet } from '../../../helpers/constants';
import { useTranslation } from 'react-i18next';

const PageWrapper = styled.section`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: ${_var.secondary};
  .content {
    margin-top: 214px;
    text-align: center;
    .rs-icon {
      height: 96px;
      font-size: 96px;
    }
    svg {
      height: 96px;
    }
    h2,
    p {
      color: black;
    }
    p {
      font-size: 16px;
    }
  }
`;

export const MaintenancePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header withMenu={false} />
      <PageWrapper>
        <div className="content">
          <Icon icon={customIconsSet.MAINTENANCE} />
          <h2 className="my-1">{t('maintenance.header')}</h2>
          <p>{t('maintenance.content')}</p>
          <p>{t('maintenance.footer')}</p>
        </div>
      </PageWrapper>
    </>
  );
};
