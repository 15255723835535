import { Checkbox, Theme, withStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconButton } from 'rsuite';
import { SortingOptions, SortType } from '../../../../../components/pages/test-case/types';
import DataTable from '../../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../../shared-components/table/DataTableColumn';
import { Lab } from '../types';

const StyledCheckbox = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  checked: {
    color: theme.palette.primary.main,
  },
}))(Checkbox);

export const LabsSummaryTable = ({
  data,
  loading,
  sorting,
  onChangeSorting,
  selectedLabIds,
  isSelectPartial,
  isSelectAll,
  handleSelectSingle,
  handleSelectAll,
}: {
  data: Lab[];
  loading: boolean;
  sorting: SortingOptions;
  onChangeSorting: (sortColumn: string, sortType: SortType) => void;
  selectedLabIds: number[];
  isSelectPartial: boolean;
  isSelectAll: boolean;
  handleSelectSingle: (id: number, checked: boolean) => void;
  handleSelectAll: (checked: boolean) => void;
}) => {
  const { t } = useTranslation();

  // do sort
  const sortedLabs = data.sort((a: Lab, b: Lab) => {
    let sortable = 0;
    if (sorting.sortColumn === 'name') {
      sortable = a.name.localeCompare(b.name);
    }
    if (sorting.sortColumn === 'id') {
      sortable = a.id - b.id;
    }
    if (sorting.sortColumn === 'subtype') {
      sortable = a.subtype.localeCompare(b.subtype);
    }
    return sortable * (sorting.sortType === 'asc' ? 1 : -1);
  });

  const onView = (id: number) => window.open(`#/admin/atl-qualifications/${id}`);

  return (
    <DataTable<Lab>
      sticky
      data={sortedLabs}
      loading={loading}
      sorting={sorting}
      onChangeSorting={onChangeSorting}
      className="mb-2"
      useEnvIdentifier={true}
    >
      <DataTableColumn
        field="cid"
        label={
          <StyledCheckbox
            onChange={(event, checked) => {
              handleSelectAll(checked);
            }}
            checked={isSelectAll || isSelectPartial}
            indeterminate={isSelectPartial}
          />
        }
        width={'2%'}
        style={{ padding: '10px 0px 10px 12px' }}
        prefix
      >
        {(row: Lab, nested) =>
          !nested ? (
            <StyledCheckbox
              checked={selectedLabIds.includes(row.id)}
              onChange={(event, checked) => {
                handleSelectSingle(row.id, checked);
              }}
            />
          ) : null
        }
      </DataTableColumn>
      <DataTableColumn wordWrap minWidth={70} field="id" label={t('certifications.id')} sortable />
      <DataTableColumn wordWrap width="60%" field="name" label={t('certifications.name')} sortable />
      <DataTableColumn
        wordWrap
        width="30%"
        field="subtype"
        label={t('admin.qualifications.labsSubTypeTitle')}
        sortable
      />
      <DataTableColumn minWidth={100} field="actions" label={t('common.actions.title')} style={{ textAlign: 'center' }}>
        {(lab: Lab) => (
          <div className="actions text-center">
            <IconButton onClick={() => onView(lab.id)} icon={<Icon icon="eye" className="pr-1" />} appearance="link" />
          </div>
        )}
      </DataTableColumn>
    </DataTable>
  );
};
