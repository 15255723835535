import { InputWrapper } from './InputWrapper';
import React, { ComponentType, CSSProperties } from 'react';

export type WrappedInputProps<T> = T & {
  label?: React.ReactElement<string> | string;
  name: string;
  error?: string;
  required?: boolean;
  noMargin?: boolean;
  noPadding?: boolean;
  boldTitle?: boolean;
  fullWidth?: boolean;
  className?: string;
  style?: CSSProperties;
};

export function withLabel<T extends object>(Component: ComponentType<T>): ComponentType<WrappedInputProps<T>> {
  const wrapperComponent = ({
    label,
    name,
    error,
    required = false,
    noMargin = false,
    noPadding = false,
    boldTitle,
    className,
    style,
    ...props
  }: WrappedInputProps<T>) => (
    <InputWrapper
      error={error}
      label={label}
      className={className}
      style={style}
      noMargin={noMargin}
      noPadding={noPadding}
      required={required}
      boldTitle={boldTitle}
    >
      <Component name={name} {...(props as T)} />
    </InputWrapper>
  );
  return wrapperComponent;
}
