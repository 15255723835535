import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './constants/translations/en.json';
import { debug } from '../config';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'en',
    debug,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    resources: {
      en: { translation: en },
    },
  });

export default i18n;
