import { IterableObject } from '../types';
import i18n from '../i18n-setup';

export const tcStatuses: IterableObject<string> = {
  draft: 'DRAFT',
  published: 'PUBLISHED',
  archived: 'ARCHIVED',
};

export const tcStatusTitles: IterableObject<string> = {
  [tcStatuses.draft]: i18n.t('common.tabs.draft'),
  [tcStatuses.published]: i18n.t('common.tabs.published'),
  [tcStatuses.archived]: i18n.t('common.tabs.archived'),
};
