import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { RsField } from '../../../../shared-components/rsuite';
import { Button, Form, Modal, Radio, RadioGroup } from 'rsuite';
import { CertificationType } from '../types';
import { useDispatch } from 'react-redux';
import { resetCertification } from '../../../../redux/modules/certification';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialAddButton } from '../../../../shared-components/button/MaterialAddButton';

const useStyles = makeStyles(() => ({
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    color: '#641246',
    '&:hover': {
      color: '#641246',
    },
    marginRight: 10,
  },
  modal: { top: '25%' },
}));

export const CreateNewCertificationButton = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [option, setOption] = useState();
  const onClose = () => {
    setOption(null);
    setIsOpen(false);
  };
  const onOpen = () => setIsOpen(true);
  const onCreate = () => {
    dispatch(resetCertification());
    history.push(`/certification/${option.toLowerCase()}/new`);
  };
  const onChange = (value: string) => setOption(value);
  return (
    <>
      <MaterialAddButton appearance="primary" onClick={onOpen}>
        {t('certifications.createTitle')}
      </MaterialAddButton>
      <Modal backdrop show={isOpen} onHide={onClose} className={classes.modal}>
        <Modal.Header>
          <Modal.Title>{t('certifications.modalTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: 'hidden' }}>
          <Form>
            <RsField
              label="Please choose a type below:"
              accepter={RadioGroup}
              name="type"
              value={option}
              onChange={(value: string) => onChange(value)}
              noMargin
            >
              <Radio value={CertificationType.PRIMARY}>Primary Certification</Radio>
              <Radio value={CertificationType.CAPABILITY}>Capability</Radio>
            </RsField>
          </Form>
        </Modal.Body>
        <div className={classes.btnWrapper}>
          <Button
            className={classes.cancelButton}
            disableRipple
            onClick={onClose}
            appearance="subtle"
            variant="contained"
          >
            {t('common.navigation.cancel')}
          </Button>
          <Button appearance="primary" onClick={onCreate} disabled={!option}>
            {t('common.actions.create')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
