import React, { useState } from 'react';
import { Box, Button, ButtonGroup, createStyles, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { DateRange, DateRangePicker as MaterialDateRangePicker, DefinedRange } from 'materialui-daterange-picker';
import DateRangeIcon from '@material-ui/icons/DateRange';
import _ from 'lodash';

// @ts-ignore
import _var from '../../styles/_variables.scss';

interface StylesProps {
  useFilterPositioning: boolean;
  selectedDate: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiBox-root': {
        zIndex: 16,
        position: 'absolute',
        right: ({ useFilterPositioning }: StylesProps) => (useFilterPositioning ? 'unset' : 150),
        top: ({ useFilterPositioning }: StylesProps) => (useFilterPositioning ? 190 : 130),
        width: 'fit-content',
      },
      '& .MuiButtonGroup-root': {
        minWidth: 200,
        width: '100%',
        maxWidth: 250,
        textTransform: 'none',
        border: `1px solid ${_var.grayBorder}`,
        borderRadius: 6,
        color: _var.textGray,
        transition: 'border-color ease-in-out 0.3s',
        '&:hover': {
          borderColor: _var.primary,
        },
      },
      '& .MuiButtonGroup-grouped': {
        border: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        paddingRight: 10,
        minWidth: 30,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiTouchRipple-root': {
          display: 'none',
        },
        '&:nth-child(2n)': {
          paddingLeft: 0,
          paddingRight: 0,
          '&:hover': {
            backgroundColor: _var.lightGray,
          },
        },
        '&:nth-child(3n)': {
          paddingLeft: 5,
          '&:hover': {
            backgroundColor: _var.lightGray,
          },
        },
      },
    },
    labelButton: {
      color: ({ selectedDate }: StylesProps) => (selectedDate ? _var.textGray : _var.gray),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textTransform: 'none',
      paddingLeft: 11,
    },
  }),
);

interface DateRangePickerProps {
  handleDateFilterChange: (range: DateRange) => void;
  ranges?: DefinedRange[];
  initialDateRange?: DateRange;
  value: DateRange;
  minDate?: Date | string;
  maxDate?: Date | string;
  closeOnClickOutside?: boolean;
  id?: string;
  title: string;
  useFilterPositioning?: boolean;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  handleDateFilterChange,
  ranges,
  initialDateRange,
  minDate,
  maxDate,
  closeOnClickOutside,
  id,
  title,
  value,
  useFilterPositioning = false,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const selectedDate = !_.isEmpty(value);
  const classes = useStyles({ selectedDate, useFilterPositioning });

  const dateRangesValues = selectedDate
    ? `${value?.startDate?.toLocaleDateString()} - ${value?.endDate?.toLocaleDateString()}`
    : title;

  const toggle = () => setOpen(!open);

  const handleChange = (range: DateRange) => {
    handleDateFilterChange(range);
  };

  const onClear = () => {
    handleDateFilterChange({});
  };

  return (
    <div className={classes.root} id={id}>
      <ButtonGroup>
        <Button onClick={toggle}>
          <label className={classes.labelButton}>{dateRangesValues}</label>
        </Button>
        <Button onClick={onClear}>
          <ClearIcon fontSize="small" />
        </Button>
        <Button onClick={toggle}>
          <DateRangeIcon fontSize="small" />
        </Button>
      </ButtonGroup>
      <Box>
        <MaterialDateRangePicker
          open={open}
          toggle={toggle}
          onChange={handleChange}
          definedRanges={ranges}
          initialDateRange={initialDateRange}
          minDate={minDate}
          maxDate={maxDate}
          closeOnClickOutside={closeOnClickOutside}
        />
      </Box>
    </div>
  );
};
