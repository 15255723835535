import React from 'react';
import { RedesignCertificationTypeTitles } from '../../../../helpers/constants';
import { CertificationSummaryRecord, ReformattedSummaryCertification, SummaryTableRow } from '../types';
import { useTranslation } from 'react-i18next';
import { SummaryTableProps } from '../../../../helpers/types';
import { jsonCopy, reformatDate } from '../../../../helpers';
import { CustomActions } from './CustomActions';
import DataTable from '../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../shared-components/table/DataTableColumn';

type CertificationSummaryData = ReformattedSummaryCertification & { id: number };

export const SummaryTable = (props: SummaryTableProps<CertificationSummaryRecord>): React.ReactElement<string> => {
  const { data, loading, onChangeSorting, sorting, total, activePage, setActivePage, status } = props;
  const { t } = useTranslation();

  const reformattedData: ReformattedSummaryCertification[] = jsonCopy(data).map(({ versions, ...item }) => {
    // @ts-ignore
    const actions = data[0].actions;
    const mainCert = {
      id: item.id,
      name: item.name,
      actualName: item.name,
      type: item.type,
      roleName: item.roleName,
      capabilities: [],
      visibleOnCertificate: item.visibleOnCertificate,
      user: item.user,
      actions,
    };
    const formatted = versions
      .filter((version, index) => index !== 0)
      .map(({ version, capabilities, id, date, user }) => ({
        id,
        name: '',
        type: item.type,
        roleName: '',
        user,
        version,
        date,
        capabilities: capabilities || [],
        actualName: item.name,
        actions,
      }));

    return {
      ...mainCert,
      id: versions[0]?.id || item.id,
      user: versions[0]?.user || item.user,
      version: versions[0]?.version,
      capabilities: versions[0]?.capabilities || [],
      date: versions[0]?.date || '',
      children: formatted,
    };
  });

  return (
    <DataTable<CertificationSummaryData>
      sticky
      expandable
      pagination
      total={total}
      sorting={sorting}
      loading={loading}
      activePage={activePage}
      setActivePage={setActivePage}
      onChangeSorting={onChangeSorting}
      data={reformattedData as CertificationSummaryData[]}
      useEnvIdentifier={true}
    >
      <DataTableColumn field="name" label={t('certifications.name')} width="25%" minWidth={150} sortable wordWrap />
      <DataTableColumn field="roleName" label={t('certifications.role')} minWidth={150} sortable wordWrap />
      <DataTableColumn field="type" label={t('certifications.type')} minWidth={110} sortable>
        {(row: CertificationSummaryData) => RedesignCertificationTypeTitles[row.type]}
      </DataTableColumn>
      <DataTableColumn
        field="version"
        label={t('certifications.version.version')}
        width="25%"
        minWidth={150}
        sortable
        wordWrap
      />
      <DataTableColumn field="id" label={t('certifications.versionId')} minWidth={120} sortable />
      <DataTableColumn field="capabilities" label={t('certifications.capabilities.title')} width={300} fixed>
        {(row: CertificationSummaryData) => row.capabilities.map(capability => capability.name).join(', ')}
      </DataTableColumn>
      <DataTableColumn field="date" label={t('certifications.updatedAt')} minWidth={160} sortable>
        {(row: CertificationSummaryData) => reformatDate(row.date)}
      </DataTableColumn>
      <DataTableColumn field="user" label={t('certifications.updatedBy')} minWidth={180} sortable wordWrap />
      <DataTableColumn field="actions" label={t('common.actions.title')} minWidth={100}>
        {(row: SummaryTableRow) => <CustomActions record={row} status={status} />}
      </DataTableColumn>
    </DataTable>
  );
};
