import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dropdown, Whisper, Popover, IconButton, Icon } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
//import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import { defaultWhisperProps, wfaAdminRole } from '../../../../helpers/constants';
import { SingleAppStaffSummaryRecord, StaffAppStatuses } from '../../application/types';
import { showRevokeModal } from '../../../../redux/modules/modals';
import { RevokeActionTypes } from '../../../../redux/modules/modals/constants';
import { RootReducer } from '../../../../redux/rootReducer';
import { User } from '../../../../redux/modules/user/types';
import { ApplicationActions } from '../../application/types';
import { MaterialIconButton } from '../../../../shared-components/button/MaterialIconButton';

const ColumnComponent = ({
  record,
}: {
  record: SingleAppStaffSummaryRecord & { actions: ApplicationActions };
} & RouteComponentProps<{}>): React.ReactElement<string> => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector<RootReducer, User>(state => state.userReducer.user as User);

  const onView = () => window.open(`#/application/${record.id}`);

  const onHistory = () => window.open(`#/application/${record.id}/history`);

  const handleRevokeApp = () => {
    dispatch(
      showRevokeModal(
        {
          cid: record.cid,
          appId: record.id,
        },
        RevokeActionTypes.REVOKE_APPLICATION,
      ),
    );
  };

  /*const handleUncertifyApp = () => {
    dispatch(
      showRevokeModal(
        {
          cid: record.cid,
          appId: record.id,
        },
        RevokeActionTypes.UNCERTIFY_APPLICATION,
      ),
    );
  };*/

  return (
    <Fragment>
      <div className="actions">
        <IconButton onClick={onView} icon={<Icon icon="eye" />} appearance="link" />
        <Whisper
          {...defaultWhisperProps}
          speaker={
            <Popover full>
              <Dropdown.Menu className="action-icons">
                <Dropdown.Item onClick={onHistory}>
                  <IconButton icon={<Icon icon="history" />} appearance="link">
                    {t('applications.summary.history')}
                  </IconButton>
                </Dropdown.Item>
                {[wfaAdminRole].includes((user as User).authority) &&
                (record.staffStatus == StaffAppStatuses.CERTIFIED ||
                  record.staffStatus == StaffAppStatuses.QUALIFIED) ? (
                  <>
                    <Dropdown.Item onClick={handleRevokeApp}>
                      <MaterialIconButton iconComponent={CancelOutlinedIcon}>
                        {t('applications.summary.revoke')}
                      </MaterialIconButton>
                    </Dropdown.Item>
                    {/*
                      <Dropdown.Item onClick={handleUncertifyApp}>
                        <MaterialIconButton iconComponent={KeyboardReturnIcon}>
                          {t('revokes.uncertify')}
                        </MaterialIconButton>
                      </Dropdown.Item>
                  */}
                  </>
                ) : null}
              </Dropdown.Menu>
            </Popover>
          }
        >
          <IconButton appearance="link" icon={<Icon icon="more" className="icon pointer medium" />} />
        </Whisper>
      </div>
    </Fragment>
  );
};

export const ApplicationsActionsColumn = withRouter(ColumnComponent);
