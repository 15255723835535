import { AxiosError, AxiosResponse } from 'axios';
import dot from 'dot-object';
import React, { ChangeEvent, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Button, Col, Icon, Row } from 'rsuite';

import { updateVariantInfo } from '../../../../api/product';
import { confirmAction, jsonCopy, mergeObjects, prepareYupModel, reformatDate } from '../../../../helpers';
import { EditMode, applicationStatusTitles, memberRoles, wfaStaffRoles } from '../../../../helpers/constants';
import { CertificationStatuses } from '../../../../helpers/constants/certification-statuses';
import { BtnLoadingState } from '../../../../helpers/constants/loading-states';
import { IsModifiedProps, useChangeDetection } from '../../../../helpers/hooks/useChangeDetection';
import { formatErrors } from '../../../../helpers/request';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { ErrorObject, ValidationErrorResponse } from '../../../../helpers/types';
import { DecisionType } from '../../../../api/application/types';
import { WrapWithPublicIcon } from '../../../../shared-components/helper-components/WrapWithPublicIcon';
import { LabeledBooleanField, LabeledInput } from '../../../../shared-components/labeled-inputs';
import { AnchorLink } from '../../../../shared-components/styled-components';
import { WarningCard } from '../../../../shared-components/warning-card/WarningCard';
import { DetailsItem } from '../../../partials';
import { VariantInfoTitle } from '../../../partials/VariantInfoTitle';
import { RowWithMargin, Section } from '../../application/partials';
import { CCDetails } from '../../application/partials/cc-details/CCDetails';
import { CCInfoRecord } from '../../application/types';
import { isSolutionProvider } from '../../staff/partials/helpers';
import { AsteriskState } from '../ProductDetailsPage';
import { BasicVariantInfo, SingleProductRecord, VariantInfo } from '../types';
import { variantInfoScheme } from '../validation-schemes';
import { SwitchVariants } from './SwitchVariants';

type ProductVariantSectionProps = {
  authority: string;
  productItem: SingleProductRecord;
  updateProduct: (x: SetStateAction<SingleProductRecord | null>) => void;
  updateVariants: (x: SetStateAction<VariantInfo[]>) => void;
  variants: VariantInfo[];
  isRetirementCertStatus?: boolean;
  setAsteriskLegend: (x: SetStateAction<AsteriskState>) => void;
} & Partial<IsModifiedProps>;

export const ProductVariantWithCCDetails = ({
  authority,
  productItem,
  updateProduct,
  variants,
  updateVariants,
  setAsteriskLegend,
  isRetirementCertStatus,
  setIsModified: setIsSectionModified,
}: ProductVariantSectionProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const variantInfoData = {
    id: productItem.variant.application.id,
    name: productItem.variant.name,
    majorEvents: productItem.variant.application.majorEvents,
    status: productItem.variant.application.status,
    searchableByPublic: Boolean(productItem.variant.searchableByPublic),
    availableAsDerivative: Boolean(productItem.variant.availableAsDerivative),
    availableAsQualifiedSolution: Boolean(productItem.variant.availableAsQualifiedSolution),
    visibleAllowed: Boolean(productItem.variant.visibleAllowed),
    productType: productItem.variant.productType,
  };
  const [selectedVariant, setSelectedVariant] = useState<VariantInfo>(productItem.variant);

  const solutionProvider = isSolutionProvider(selectedVariant);

  const getVariantState = () => ({
    id: selectedVariant.id,
    name: selectedVariant.name,
    majorEvents: selectedVariant.application.majorEvents,
    status: selectedVariant.application.status,
    searchableByPublic: Boolean(selectedVariant.searchableByPublic),
    availableAsDerivative: Boolean(selectedVariant.availableAsDerivative),
    availableAsQualifiedSolution: Boolean(selectedVariant.availableAsQualifiedSolution),
    visibleAllowed: Boolean(selectedVariant.visibleAllowed),
    productType: selectedVariant.productType,
    componentCombination: selectedVariant.componentCombination,
    draftCertified: Boolean(selectedVariant.draftCertified),
  });

  const [mode, setMode] = useState(EditMode.READONLY);
  const [errors, setErrors] = useState<ErrorObject>({});
  const [btnLoading, setBtnLoading] = useState(BtnLoadingState.NONE);
  const [state, setState] = useState(getVariantState());
  const [isModified, setIsModified] = useState(false);

  const setIsModifiedState = (value: boolean) => {
    setIsModified(value);
    setIsSectionModified && setIsSectionModified(value);
  };

  const setChangeDetectionState = useChangeDetection(setIsModifiedState, value => JSON.stringify(value), state);

  const isEditMode = mode === EditMode.EDIT;

  const handleCancel = () => {
    setMode(EditMode.READONLY);
    setState(variantInfoData);
    setErrors({});
    setIsModifiedState(false);
    setAsteriskLegend(prevState => {
      const newState = jsonCopy(prevState);
      newState.variantInfo = false;
      return newState;
    });
  };

  const onCancel = () => confirmAction(() => isModified, handleCancel);

  const editModeOn = () => {
    setMode(EditMode.EDIT);
    setAsteriskLegend(prevState => {
      const newState = jsonCopy(prevState);
      newState.variantInfo = true;
      return newState;
    });
  };

  const handleSave = () => {
    const validationErrors = prepareYupModel(variantInfoScheme).checkFormatted(state);
    if (validationErrors) {
      setErrors(prevState => mergeObjects(prevState, validationErrors));
      return;
    }
    setBtnLoading(BtnLoadingState.SAVE);
    return updateVariantInfo(state.id, state)
      .then((response: AxiosResponse<BasicVariantInfo>) => {
        const formattedData = {
          application: {
            id: response.data.id,
            type: productItem.variant.application.type,
          },
          name: response.data.name,
          availableAsDerivative: response.data.availableAsDerivative,
          searchableByPublic: response.data.searchableByPublic,
          availableAsQualifiedSolution: response.data.availableAsQualifiedSolution,
          visibleAllowed: response.data.visibleAllowed,
          publicVariant: response.data.publicVariant,
        };
        setBtnLoading(BtnLoadingState.NONE);
        setMode(EditMode.READONLY);
        setSelectedVariant(prevState => mergeObjects(prevState, formattedData));
        const updatedVariants = variants.map(item => {
          if (item.id === state.id) {
            return mergeObjects(item, state);
          }
          return item;
        });
        updateVariants(updatedVariants);
        updateProduct(mergeObjects(productItem, { variant: formattedData }));
        setIsModifiedState(false);
        setChangeDetectionState(state);
        addToast(t('products.variantUpdated'), {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      })
      .catch((error: AxiosError<ValidationErrorResponse>) => {
        setBtnLoading(BtnLoadingState.NONE);
        if (error.response?.data.errors) {
          const validationErrors = formatErrors(error.response?.data.errors);
          setErrors(prevState => mergeObjects(prevState, validationErrors));
        }
        handleRequestFail(error, addToast);
      });
  };

  const handleChange = (value: string | boolean, name: string) => {
    setState(prevState => {
      const newState = jsonCopy(prevState);
      dot.set(name, value, newState);
      const error = prepareYupModel(variantInfoScheme).checkForFieldFormatted(name, newState);
      setErrors(prevErrors => ({ ...prevErrors, ...error }));
      return newState;
    });
  };

  const getTextForBoolean = (data?: boolean) => (data ? t('common.options.yes') : t('common.options.no'));

  useEffect(() => {
    if (productItem.variant.id !== selectedVariant.id) {
      updateProduct({ ...productItem, variant: selectedVariant });
      const newState = getVariantState();
      setChangeDetectionState(newState);
      setState(newState);
    }
  }, [selectedVariant.id]);

  return (
    <Section>
      <Row>
        <Col xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <VariantInfoTitle productType={state.productType} />
          {memberRoles.includes(authority) && mode === EditMode.READONLY ? (
            <Icon
              className="pointer"
              icon="edit"
              style={{ margin: '0 0.5rem 1rem', fontSize: '1.2rem' }}
              onClick={editModeOn}
            />
          ) : null}
        </Col>
        <Col xs={12} className="btn-wrapper text-right">
          {isEditMode ? (
            <>
              <Button className="cancel" onClick={onCancel}>
                {t('common.navigation.cancel')}
              </Button>
              <Button
                appearance="primary"
                loading={btnLoading === BtnLoadingState.SAVE}
                disabled={btnLoading !== BtnLoadingState.NONE}
                onClick={handleSave}
                className="save"
              >
                {t('common.navigation.save')}
              </Button>
            </>
          ) : null}
        </Col>
      </Row>
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          {isEditMode ? (
            <LabeledInput
              required
              label={<WrapWithPublicIcon content={t('applications.info.variantName')} />}
              name="name"
              placeholder={t('applications.info.variantName')}
              errors={errors}
              defaultValue={state.name}
              onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => handleChange(value, event.target.name)}
            />
          ) : (
            <SwitchVariants
              variants={variants}
              activeVariant={selectedVariant}
              setActiveVariant={setSelectedVariant}
              authority={authority}
            />
          )}
        </Col>
        <Col xs={12}>
          <DetailsItem label={t('applications.summary.appId')}>
            <AnchorLink to={`/application/${productItem.variant?.application.id}`} target="_blank">
              {productItem.variant?.application.id}
            </AnchorLink>
          </DetailsItem>
        </Col>
      </RowWithMargin>
      {isEditMode ? (
        <>
          <RowWithMargin gutter={20}>
            <Col xs={12}>
              <LabeledBooleanField
                required
                name="searchableByPublic"
                errors={errors}
                disabled={DecisionType.CERTIFY !== productItem.variant.variantStatus}
                label={t('applications.info.searchable')}
                value={state.searchableByPublic}
                onChange={(value: string) => handleChange(value, 'searchableByPublic')}
              />
            </Col>
            <Col xs={6}>
              <LabeledBooleanField
                required
                name="availableAsDerivative"
                errors={errors}
                disabled={DecisionType.CERTIFY !== productItem.variant.variantStatus || isRetirementCertStatus}
                label={t('applications.info.available')}
                value={state.availableAsDerivative}
                onChange={(value: string) => handleChange(value, 'availableAsDerivative')}
              />
            </Col>
            <Col xs={6}>
              {isRetirementCertStatus ? (
                <WarningCard
                  content={t('products.warningContent')}
                  status={CertificationStatuses.THIRD_RETIREMENT_PHASE}
                />
              ) : null}
            </Col>
          </RowWithMargin>
          {solutionProvider && (
            <RowWithMargin gutter={20}>
              <Col xs={12} />
              <Col xs={12}>
                <LabeledBooleanField
                  required
                  name="availableAsQualifiedSolution"
                  errors={errors}
                  label={t('applications.info.availableAsQualifiedSolution')}
                  value={state.availableAsQualifiedSolution}
                  disabled={!productItem.qualifiedSolution}
                  onChange={(value: string) => handleChange(value, 'availableAsQualifiedSolution')}
                />
              </Col>
            </RowWithMargin>
          )}
        </>
      ) : (
        <>
          <RowWithMargin gutter={20}>
            <Col xs={12}>
              <DetailsItem
                label={t('applications.info.searchable')}
                value={getTextForBoolean(productItem.variant?.searchableByPublic)}
              />
            </Col>
            <Col xs={6}>
              <DetailsItem
                label={t('applications.info.available')}
                value={getTextForBoolean(productItem.variant?.availableAsDerivative)}
              />
            </Col>
            <Col xs={6}>
              {isRetirementCertStatus ? (
                <WarningCard
                  content={t('products.warningContent')}
                  status={CertificationStatuses.THIRD_RETIREMENT_PHASE}
                />
              ) : null}
            </Col>
          </RowWithMargin>
          {solutionProvider && (
            <RowWithMargin gutter={20}>
              <Col xs={12} />
              <Col xs={6}>
                <DetailsItem
                  label={t('applications.info.availableAsQualifiedSolution')}
                  value={getTextForBoolean(productItem.variant?.availableAsQualifiedSolution)}
                />
              </Col>
            </RowWithMargin>
          )}
        </>
      )}

      <RowWithMargin gutter={20}>
        <Col xs={24}>
          <h4>{t('applications.info.applicationInfo')}</h4>
        </Col>
      </RowWithMargin>
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <DetailsItem label={t('applications.info.applicationStatus')} value={applicationStatusTitles[state.status]} />
        </Col>
        <Col xs={12}>
          <DetailsItem
            label={t('applications.info.approvedDate')}
            value={
              state?.majorEvents?.approved?.createdAt
                ? reformatDate(state.majorEvents.approved.createdAt as string)
                : null
            }
          />
        </Col>
      </RowWithMargin>

      <RowWithMargin gutter={20}>
        <Col xs={12}>
          <h4>{t('applications.cc.wifiComponent')}</h4>
        </Col>
        <Col xs={12}>
          {wfaStaffRoles.includes(authority) ? (
            <Button appearance="primary" disabled>
              {t('products.showAllSameCC')}
            </Button>
          ) : null}
        </Col>
      </RowWithMargin>
      {!!productItem.variant?.componentCombination?.componentCombination && (
        <CCDetails
          cc={productItem.variant.componentCombination.componentCombination as CCInfoRecord}
          showPublicIcon
          ccVariantSource={productItem.variant?.componentCombination?.componentCombinationVariantSource}
        />
      )}
    </Section>
  );
};
