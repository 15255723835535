import React, { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const circleStyle: React.CSSProperties = {
    height: '45px',
    width: '45px',
    backgroundColor: '#ffffffcc',
    borderRadius: '50%',
    border: '2px #6412461A solid',
    position: 'fixed',
    bottom: '50px',
    right: '25px',
    zIndex: 99,
    display: isVisible ? 'block' : 'none',
  };

  const arrowUpStyle = {
    color: '#641246',
    paddingTop: '5px',
    fontSize: '28px',
  };

  return (
    <button type="button" onClick={scrollToTop} style={circleStyle}>
      <ArrowUpwardIcon style={arrowUpStyle} />
    </button>
  );
};
