import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { getLabCertificationsSummaryById } from '../../../../api/company/lab-management/certifications';
import { closeWindow } from '../../../../helpers';
import { SummaryFiltersObject } from '../../../../helpers/types';
import {
  loadLabInfo,
  resetState,
  selectAtlQualificationsState,
} from '../../../../redux/modules/admin/atl-qualifications';
import { BackToListButton } from '../../../../shared-components/button/BackToListButton';
import { CloseButton } from '../../../../shared-components/button/CloseButton';
import { MaterialAddButton } from '../../../../shared-components/button/MaterialAddButton';
import { useSummaryTable } from '../../../hoc/get-summary-without-tabs-page-component';
import { PageTemplate } from '../../../partials';
import { SortingOptions } from '../../test-case/types';
import { useStickyStyles } from './AtlQualificationsEnablePage';
import { CertificationsSection } from './components/CertificationsSection';
import { LabInfoSection } from './components/LabInfoSection';
import { reformatData, reformatFlatSummaryDataByGroup } from './helpers';
import { LabCertification } from './types';

export const AtlQualificationsSummaryPage = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const labId = match.params.id;
  const classes = useStickyStyles();

  const { isLoadingLabInfo, labInfo } = useSelector(selectAtlQualificationsState);

  useEffect(() => {
    dispatch(loadLabInfo(labId, addToast));

    return () => {
      dispatch(resetState());
    };
  }, [labId]);

  const getLabCertificationsSummary = (
    page: number,
    sortingOptions?: SortingOptions,
    filters?: SummaryFiltersObject,
  ) => {
    return getLabCertificationsSummaryById(labId, page, sortingOptions, filters);
  };

  const { data, ...tableProps } = useSummaryTable<LabCertification>(getLabCertificationsSummary, {
    sortColumn: 'name',
    sortType: 'asc',
  });

  const onBackToList = () => {
    history.push(`/admin/atl-qualifications`);
  };

  const onAddQualifications = () => history.push(`/admin/atl-qualifications/${labId}/enable`);

  const tableData = useMemo(() => reformatFlatSummaryDataByGroup(reformatData(data)), [data]);

  return (
    <PageTemplate
      withBorder
      className={classNames('qualifications-details-page', classes.pageTemplate)}
      title={t('admin.qualifications.qualificationsTitle', { lab: labInfo ? `- ${labInfo?.name}` : '' })}
      actionLeft={
        window.opener ? (
          <CloseButton onClick={closeWindow} />
        ) : (
          <BackToListButton className="btn-back-to-list" onClick={onBackToList} />
        )
      }
      footerActionLeft={<></>}
      actionRight={
        <div className="btn-wrapper">
          <MaterialAddButton appearance="primary" className="add-new" onClick={onAddQualifications}>
            {t('admin.qualifications.addQualifications')}
          </MaterialAddButton>
        </div>
      }
    >
      <LabInfoSection data={labInfo} />
      <CertificationsSection
        labId={labId}
        data={tableData}
        {...tableProps}
        loading={tableProps.loading || isLoadingLabInfo}
      />
    </PageTemplate>
  );
};
