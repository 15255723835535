import i18n from '../../../helpers/i18n-setup';
import {
  recallApplication,
  returnToSubmitter,
  revokeApplication,
  uncertifyApplication,
  canRevokeApplication,
  canUncertifyApplication,
} from '../../../api/application';
import { createNote } from '../../../api/notes';
import { IterableObject } from '../../../helpers/types';
import { NoteModal, RevokeModal } from './reducer';
import { returnToLab } from '../../../api/lab-request';
import { returnToLabByStaff } from '../../../api/staff';
import { revokeProduct, canRevokeProduct } from '../../../api/product/revoke-product';

export enum NoteActionTypes {
  ADD_NOTE = 'ADD_NOTE',
  RETURN_TO_SUBMITTER_STAFF = 'RETURN_TO_SUBMITTER_STAFF',
  RETURN_TO_SUBMITTER_LAB = 'RETURN_TO_SUBMITTER_LAB',
  RECALL_FROM_LAB = 'RECALL_FROM_LAB',
  RECALL_FROM_APPROVER = 'RECALL_FROM_APPROVER',
  RETURN_TO_LAB = 'RETURN_TO_LAB',
  REMOVE_CC = 'REMOVE_CC',
  CHANGE_CC = 'CHANGE_CC',
  CONFIRM_APPROVAL = 'CONFIRM_APPROVAL',
}

export const modalPropsByActionTypes: IterableObject<NoteModal> = {
  [NoteActionTypes.ADD_NOTE]: {
    appId: null,
    legend: '',
    fieldLabel: i18n.t('notes.addNoteModal.fieldLabel'),
    modalTitle: i18n.t('notes.addNoteModal.title'),
    successTextBtn: i18n.t('notes.addNoteModal.successBtnText'),
    callApi: createNote,
    noteModalType: NoteActionTypes.ADD_NOTE,
  },
  [NoteActionTypes.RETURN_TO_SUBMITTER_LAB]: {
    appId: null,
    legend: i18n.t('testing.returnToSubmitter.message'),
    fieldLabel: i18n.t('notes.returnToSubmitter.field'),
    modalTitle: i18n.t('common.actions.returnToSubmitter'),
    successTextBtn: i18n.t('notes.returnToSubmitter.successBtnText'),
    callApi: returnToLab,
    noteModalType: NoteActionTypes.RETURN_TO_SUBMITTER_LAB,
  },
  [NoteActionTypes.RETURN_TO_SUBMITTER_STAFF]: {
    appId: null,
    legend: i18n.t('testing.returnToSubmitter.message'),
    fieldLabel: i18n.t('notes.returnToSubmitter.field'),
    modalTitle: i18n.t('common.actions.returnToSubmitter'),
    successTextBtn: i18n.t('notes.returnToSubmitter.successBtnText'),
    callApi: returnToSubmitter,
    noteModalType: NoteActionTypes.RETURN_TO_SUBMITTER_STAFF,
  },
  [NoteActionTypes.RECALL_FROM_LAB]: {
    appId: null,
    legend: i18n.t('notes.recallFromLab.legend'),
    fieldLabel: i18n.t('notes.recallFromLab.field'),
    modalTitle: i18n.t('applications.summary.recallFromLab'),
    successTextBtn: i18n.t('notes.recallFromLab.successBtnText'),
    callApi: recallApplication,
    noteModalType: NoteActionTypes.RECALL_FROM_LAB,
  },
  [NoteActionTypes.RETURN_TO_LAB]: {
    appId: null,
    legend: i18n.t('notes.returnToLab.legend'),
    fieldLabel: i18n.t('notes.returnToLab.field'),
    modalTitle: i18n.t('notes.returnToLab.title'),
    successTextBtn: i18n.t('notes.returnToLab.successBtnText'),
    callApi: returnToLabByStaff,
    noteModalType: NoteActionTypes.RETURN_TO_LAB,
  },
  [NoteActionTypes.RECALL_FROM_APPROVER]: {
    appId: null,
    legend: i18n.t('notes.recallFromApprover.legend'),
    fieldLabel: i18n.t('notes.recallFromLab.field'),
    modalTitle: i18n.t('common.actions.recallFromApprover'),
    successTextBtn: i18n.t('notes.recallFromLab.successBtnText'),
    callApi: recallApplication,
    noteModalType: NoteActionTypes.RECALL_FROM_APPROVER,
  },
  [NoteActionTypes.REMOVE_CC]: {
    appId: null,
    legend: i18n.t('applications.cc.variantFlow.remove_cc.legend'),
    fieldLabel: i18n.t('applications.cc.variantFlow.remove_cc.field'),
    modalTitle: i18n.t('applications.cc.variantFlow.remove_cc.modalTitle'),
    successTextBtn: i18n.t('applications.cc.variantFlow.remove_cc.successTextBtn'),
    callApi: createNote,
    noteModalType: NoteActionTypes.REMOVE_CC,
  },
  [NoteActionTypes.CHANGE_CC]: {
    appId: null,
    legend: i18n.t('applications.cc.variantFlow.change_cc.legend'),
    fieldLabel: i18n.t('applications.cc.variantFlow.remove_cc.field'),
    modalTitle: i18n.t('applications.cc.variantFlow.change_cc.modalTitle'),
    successTextBtn: i18n.t('applications.cc.variantFlow.change_cc.successTextBtn'),
    callApi: createNote,
    noteModalType: NoteActionTypes.CHANGE_CC,
  },
  [NoteActionTypes.CONFIRM_APPROVAL]: {
    appId: null,
    legend: i18n.t('notes.confirmApproval.legend'),
    modalTitle: i18n.t('notes.confirmApproval.title'),
    fieldLabel: i18n.t('notes.confirmApproval.field'),
    successTextBtn: i18n.t('notes.confirmApproval.successBtnText'),
    callApi: createNote,
    noteModalType: NoteActionTypes.CONFIRM_APPROVAL,
  },
};

export enum RevokeActionTypes {
  REVOKE_APPLICATION = 'REVOKE_APPLICATION',
  UNCERTIFY_APPLICATION = 'UNCERTIFY_APPLICATION',
  REVOKE_PRODUCT = 'REVOKE_PRODUCT',
}

export const revokeModalPropsByActionTypes: IterableObject<RevokeModal> = {
  [RevokeActionTypes.REVOKE_APPLICATION]: {
    modalTitle: i18n.t('revokes.revokeApplication.title'),
    cid: null,
    productId: null,
    totalVariants: 0,
    appId: null,
    legend: i18n.t('revokes.revokeApplication.legend'),
    reasonTitle: i18n.t('revokes.fields.reasonOfRevoke'),
    reasons: [
      i18n.t('revokes.revokeApplication.reason.1'),
      i18n.t('revokes.revokeApplication.reason.2'),
      i18n.t('revokes.revokeApplication.reason.other'),
    ],
    callApi: revokeApplication,
    checkApi: canRevokeApplication,
    revokeModalType: RevokeActionTypes.REVOKE_APPLICATION,
  },
  [RevokeActionTypes.UNCERTIFY_APPLICATION]: {
    modalTitle: i18n.t('revokes.uncertifyApplication.title'),
    cid: null,
    productId: null,
    totalVariants: 0,
    appId: null,
    legend: i18n.t('revokes.uncertifyApplication.legend'),
    reasonTitle: i18n.t('revokes.fields.reasonOfUncertify'),
    reasons: [
      i18n.t('revokes.uncertifyApplication.reason.1'),
      i18n.t('revokes.uncertifyApplication.reason.2'),
      i18n.t('revokes.uncertifyApplication.reason.3'),
      i18n.t('revokes.uncertifyApplication.reason.other'),
    ],
    callApi: uncertifyApplication,
    checkApi: canUncertifyApplication,
    revokeModalType: RevokeActionTypes.UNCERTIFY_APPLICATION,
  },
  [RevokeActionTypes.REVOKE_PRODUCT]: {
    modalTitle: i18n.t('revokes.revokeProduct.title'),
    cid: null,
    productId: null,
    totalVariants: 0,
    appId: null,
    legend: i18n.t('revokes.revokeProduct.legend'),
    reasonTitle: i18n.t('revokes.fields.reasonOfRevoke'),
    reasons: [
      i18n.t('revokes.revokeProduct.reason.1'),
      i18n.t('revokes.revokeProduct.reason.2'),
      i18n.t('revokes.revokeProduct.reason.other'),
    ],
    callApi: revokeProduct,
    checkApi: canRevokeProduct,
    revokeModalType: RevokeActionTypes.REVOKE_PRODUCT,
  },
};
