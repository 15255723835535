import { Schema } from 'yup';
import { BasicProductInfo, BasicVariantInfo } from './types';
import * as Yup from 'yup';
import i18n from '../../../helpers/i18n-setup';

export const productInfoScheme: Schema<BasicProductInfo> = Yup.object().shape({
  id: Yup.number(),
  cid: Yup.string(),
  name: Yup.string()
    .trim()
    .max(128, i18n.t('common.validation.length', { length: 128 }))
    .required(i18n.t('common.validation.required')),
  modelNumber: Yup.string()
    .trim()
    .required(i18n.t('common.validation.required'))
    .max(128, i18n.t('common.validation.length', { length: 128 })),
  description: Yup.string().trim(),
  primaryCategory: Yup.object()
    .nullable()
    .shape({ id: Yup.number(), name: Yup.string(), displayOrder: Yup.number(), groupId: Yup.number() })
    .required(i18n.t('common.validation.required')),
  additionalCategories: Yup.array(),
  url: Yup.string()
    .nullable()
    .trim()
    .matches(/^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/, {
      message: i18n.t('common.validation.URL'),
      excludeEmptyString: true,
    }),
  image: Yup.mixed().nullable(),
});

export const variantInfoScheme: Schema<BasicVariantInfo> = Yup.object().shape({
  id: Yup.number(),
  searchableByPublic: Yup.boolean().required(i18n.t('common.validation.required')),
  availableAsDerivative: Yup.boolean().required(i18n.t('common.validation.required')),
  availableAsQualifiedSolution: Yup.boolean(),
  visibleAllowed: Yup.boolean().required(i18n.t('common.validation.required')),
  name: Yup.string()
    .trim()
    .required(i18n.t('common.validation.required'))
    .max(128, i18n.t('common.validation.length', { length: 128 })),
});
