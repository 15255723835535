import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { Note } from './types';
import { getNotesByAppId } from '../../../api/notes';
import { NoteItem } from './NoteItem';
import { jsonCopy } from '../../../helpers';
import { handleRequestFail } from '../../../helpers/request-fail-handler';
import { showAddNoteModal, toggleUpdateNotes } from '../../../redux/modules/modals';
import { NoteActionTypes } from '../../../redux/modules/modals/constants';
import { RootReducer } from '../../../redux/rootReducer';
import { ModalsReducerState } from '../../../redux/modules/modals/reducer';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import { MaterialAddButton } from '../../../shared-components/button/MaterialAddButton';

const NoData = styled.p`
  margin-left: 0.5rem;
`;

export const NotesList = ({ applicationId }: { applicationId: number | null }) => {
  if (applicationId === null || applicationId === 0) {
    return null;
  }

  const { updateNotes } = useSelector<RootReducer, ModalsReducerState>(state => state.modalsReducer);
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [notesList, setNotesList] = useState<Note[]>([]);
  const [loading, setLoading] = useState(false);

  const onSuccess = (newNote: Note) => {
    setNotesList(prevState => {
      const newState = jsonCopy(prevState);
      newState.unshift(newNote);
      return newState;
    });
  };

  useEffect(() => {
    if (updateNotes) {
      setLoading(true);
      getNotesByAppId(applicationId)
        .then((response: AxiosResponse<Note[]>) => {
          setNotesList(response.data);
          setLoading(false);
          dispatch(toggleUpdateNotes(false));
        })
        .catch(error => {
          handleRequestFail(error, addToast);
          setLoading(false);
        });
    }
  }, [updateNotes]);

  useEffect(() => {
    dispatch(toggleUpdateNotes(true));
  }, [applicationId]);

  return (
    <div className="notes-wrapper my-1">
      <h4 className="text-center">{t('notes.memberStaffTitle')}</h4>
      {loading ? (
        <div style={{ height: '200px', position: 'relative' }}>
          <CircularLoader content={t('notes.loadingNotes')} size={20} />
        </div>
      ) : (
        <>
          {notesList.length ? (
            notesList.map((note, index) => <NoteItem note={note} key={index} />)
          ) : (
            <NoData className="text-muted">{t('notes.noData')}</NoData>
          )}
          <MaterialAddButton
            className="my-1"
            id="open-add-note-modal"
            onClick={() => dispatch(showAddNoteModal({ appId: applicationId, onSuccess }, NoteActionTypes.ADD_NOTE))}
          >
            {t('notes.addNoteBtn')}
          </MaterialAddButton>
        </>
      )}
    </div>
  );
};
