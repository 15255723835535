import React from 'react';
import { getTestCases } from '../../../api/test-case';
import { SummaryTable } from './partials/SummaryTable';
import { useTranslation } from 'react-i18next';
import { tcStatuses, tcStatusTitles } from '../../../helpers/constants/test-case-statuses';
import { getSummaryPageComponentByObject } from '../../hoc/get-summary-page-component';
import { CreateNewTCButton } from './partials/CreateNewTCButton';
import { TCRecord } from './types';

const SummaryPage = () => {
  const { t } = useTranslation();
  const SummaryPageComponent = getSummaryPageComponentByObject<TCRecord>({
    title: t('testCases.summaryHeader'),
    apiCall: getTestCases,
    object: tcStatuses,
    titles: tcStatusTitles,
    SummaryTable,
    pageRightAction: <CreateNewTCButton />,
    defaultSorting: { sortColumn: 'createdAt', sortType: 'desc' },
    useAdvancedFilters: true,
    isTestCaseSummary: true,
  });

  return <SummaryPageComponent />;
};

export const TCSummaryPage = React.memo(SummaryPage);
