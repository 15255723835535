import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userState } from '../../../redux/modules/user/reducer';
import { User } from '../../../redux/modules/user/types';
import { atlRoles, wfaEngineerRole, wfaStaffRoles } from '../../../helpers/constants';
import { useHistory } from 'react-router';

const HomePageComponent = (): React.ReactElement<string> => {
  const user = useSelector<{ userReducer: userState }, User>(state => state.userReducer.user as User);
  const history = useHistory();

  useEffect(() => {
    const role = user.authority;
    if (wfaStaffRoles.includes(role)) {
      history.push(role === wfaEngineerRole ? '/certification' : '/pending-approval');
    } else if (atlRoles.includes(role)) {
      history.push('/lab-request');
    } else {
      history.push('/application');
    }
  });

  return <div className="wifi-loader" />;
};

export default HomePageComponent;
