import React from 'react';
import { TestCriteria } from '../../types';
import { Whisper, Popover, Icon } from 'rsuite';
import { Th, Td, Table } from '../../partials/table-components';
import { useTranslation } from 'react-i18next';
import { ComparisonOperatorTitles } from '../../../../../helpers/constants';
import { TCStatus } from './TCStatus';

export const ViewConfSection = ({ data }: { data: TestCriteria[] }) => {
  const { t } = useTranslation();
  return (
    <Table style={{ width: '100%' }}>
      <thead>
        <tr>
          <Th style={{ width: '25%' }}>{t('testCases.name')}</Th>
          <Th>{t('testCases.measurementsHeader')}</Th>
          <Th>{t('certifications.measurements.required')}</Th>
          <Th>{t('certifications.measurements.test')}</Th>
          <Th />
          <Th />
          <Th>{t('certifications.measurements.comment')}</Th>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 ? (
          <tr>
            <td className="text-center text-muted p-2" colSpan={6}>
              No Data
            </td>
          </tr>
        ) : null}
        {data.map(testCriteria =>
          testCriteria.criteria.map((criteria, index) => (
            <tr key={index}>
              {index === 0 ? (
                <Td rowSpan={testCriteria.criteria.length}>
                  <span className="has-word-break">{testCriteria.test.name}</span>
                  <TCStatus status={testCriteria.test.status} />
                </Td>
              ) : null}
              <Td>{criteria.measurement.name}</Td>
              <Td>{criteria.required ? <Icon icon="check" /> : null}</Td>
              <Td>
                <span>{ComparisonOperatorTitles[criteria.compareOperation]}</span>
              </Td>
              <Td>
                <span>{criteria.operand1}</span>
              </Td>
              <Td>
                <span>{criteria.operand2}</span>
              </Td>
              <Td>
                <span>
                  {criteria.comment ? (
                    <Whisper placement="left" trigger="hover" speaker={<Popover>{criteria.comment}</Popover>}>
                      <Icon icon="eye" size="2x" />
                    </Whisper>
                  ) : null}
                </span>
              </Td>
            </tr>
          )),
        )}
      </tbody>
    </Table>
  );
};
