import React from 'react';
import { Modal, Button } from 'rsuite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { RsCenterModal } from '../../../../shared-components/rsuite';

const ModalBody = styled(Modal.Body)``;

export const AcknowledgeModal = ({
  show,
  onAccept,
  onCancel,
}: {
  show: boolean;
  onAccept: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <RsCenterModal backdrop show={show} onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>{t('legalContent.acknowledgeModal.title')}</Modal.Title>
      </Modal.Header>
      <ModalBody style={{ overflow: 'hidden' }}>{t('legalContent.acknowledgeModal.body')}</ModalBody>
      <Modal.Footer>
        <Button appearance="link" onClick={onCancel}>
          {t('common.actions.doNotAccept')}
        </Button>
        <Button appearance="primary" onClick={onAccept}>
          {t('common.actions.accept')}
        </Button>
      </Modal.Footer>
    </RsCenterModal>
  );
};
