import React from 'react';
import { CertificationType, UmbrellaInfo } from '../../types';
import { Row, Col } from 'rsuite';
import { DetailsItem } from '../../../../partials';
import { useTranslation } from 'react-i18next';
import { RedesignCertificationTypeTitles } from '../../../../../helpers/constants';

export const ViewUmbreallSection = ({ data, type }: { data: UmbrellaInfo; type: CertificationType }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={20}>
      <Col xs={12}>
        <Row gutter={10}>
          <Col xs={16}>
            <DetailsItem label={t('certifications.name')} value={data.name} />
          </Col>
          <Col xs={8}>
            <DetailsItem
              label={t('certifications.nameAppearsOnCertificate')}
              value={data.visibleOnCertificate === null ? '' : data.visibleOnCertificate ? 'Yes' : 'No'}
            />
          </Col>
        </Row>
        <DetailsItem label={t('certifications.description')} value={data.description} className="my-1" />
      </Col>
      <Col xs={12}>
        <Row gutter={10}>
          <Col xs={8}>
            <DetailsItem label={t('certifications.role')} value={data.role.name} />
          </Col>
          <Col xs={8}>
            <DetailsItem label={t('certifications.type')} value={RedesignCertificationTypeTitles[type]} />
          </Col>
          <Col xs={8}>
            <DetailsItem label={t('certifications.classification')} value={data.classification?.name} />
          </Col>
        </Row>
        <DetailsItem label={t('certifications.internalComments')} value={data.internalComments} className="my-1" />
      </Col>
    </Row>
  );
};
