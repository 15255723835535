import React from 'react';
import { Equipment as EquipmentType, HomeDesignProps } from '../../application/types';
import { DetailsItem } from '../../../partials';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RolesState } from '../../../../redux/modules/roles/reducer';
import { Role } from '../../../../redux/modules/roles/types';
import { roleNameMapping } from '../../application/data/constants';
import classNames from 'classnames';
import { RoleItemName } from './RoleItemName';
import dot from 'dot-object';
import { Equipment, EquipmentRow } from '../../application/partials/cc-details/HomeDesign';
import { WrapWithPublicIcon } from '../../../../shared-components/helper-components/WrapWithPublicIcon';

const LabelWithIcon = ({ showPublicIcon, label }: { showPublicIcon: boolean; label: string }) =>
  showPublicIcon ? <WrapWithPublicIcon content={label} /> : <>{label}</>;

export const HomeDesignCompare = ({
  record,
  compareRecord,
  expandedCC,
  toggleCC,
  toggleKey,
  showPublicIcon = false,
}: {
  record: HomeDesignProps;
  compareRecord?: HomeDesignProps;
  expandedCC: string[];
  toggleCC: (key: string, isOpen: boolean) => void;
  toggleKey: string;
  showPublicIcon?: boolean;
}) => {
  const { t } = useTranslation();
  const roles = useSelector<{ rolesReducer: RolesState }, Role[]>(state => state.rolesReducer.roles);
  const roleName = roles.find(item => item.propertyName === roleNameMapping.homeDesign)?.name;
  const isOpen = expandedCC.includes(toggleKey);

  const compare = (name: string) => (compareRecord ? dot.dot(compareRecord)[name] !== dot.dot(record)[name] : false);

  const compareEquipment = (equipment: EquipmentType) => {
    return (
      compareRecord && !compareRecord?.equipment.some(equipmentToCompare => equipment.id === equipmentToCompare.id)
    );
  };

  return (
    <div className="mb-2">
      <RoleItemName isOpen={isOpen} roleName={roleName} onToggle={() => toggleCC(toggleKey, !isOpen)} />
      {isOpen ? (
        <div className="ml-2">
          <DetailsItem
            label={<LabelWithIcon showPublicIcon={showPublicIcon} label={t('applications.cc.floors')} />}
            className={classNames({ 'text-different': compare('floors') })}
            value={record.floors}
          />
          <DetailsItem
            label={<LabelWithIcon showPublicIcon={showPublicIcon} label={t('applications.cc.units')} />}
            className={classNames({ 'text-different': compare('units') })}
            value={record.units}
          />
          <DetailsItem
            label={<LabelWithIcon showPublicIcon={showPublicIcon} label={t('applications.cc.numberOfAccessPoints')} />}
            className={classNames({ 'text-different': compare('numberOfAccessPoints') })}
            value={record.numberOfAccessPoints}
          />
          <DetailsItem
            label={<LabelWithIcon showPublicIcon={showPublicIcon} label={t('applications.cc.squareFootage')} />}
            className={classNames({ 'text-different': compare('squareFootage') })}
            value={record.squareFootage}
          />
          <DetailsItem
            label={<LabelWithIcon showPublicIcon={showPublicIcon} label={t('applications.cc.apInterconnect')} />}
            className={classNames({ 'text-different': compare('apInterconnect') })}
            value={record.apInterconnect}
          />
          <p className="mt-2 mb-1">
            <strong>{t('applications.cc.equipment')}</strong>
          </p>
          {record.equipment.map(equipment => (
            <Equipment key={equipment.id} highlighted={compareEquipment(equipment)}>
              <EquipmentRow label={t('applications.cc.cid')} value={equipment.cid} />
              <EquipmentRow label={t('applications.cc.apVendor')} value={equipment.vendor.name} />
              <EquipmentRow label={t('applications.cc.modelNumber')} value={equipment.modelNumber} />
            </Equipment>
          ))}
        </div>
      ) : null}
    </div>
  );
};
