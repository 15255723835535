export interface Transaction {
  eventId: number;
  cid: string;
  applicationId: number;
  applicationType: ApplicationTypes;
  applicationStatus: ApplicationStatuses;
  opportunity: string;
  description: string;
  owningCompany: string;
  amount: number;
  requestingAccount: string;
  date: string;
  processed: boolean;
  decisionKind: TransactionDecisionTypes;
  id: number;
  events: Transaction[];
  productId: number;
  productModelVariantId: number;
  showView?: boolean;
}

export interface TransactionWithChildren extends Transaction {
  children: Transaction[];
}

export enum ApplicationTypes {
  NEW = 'NEW',
  VARIANT = 'VARIANT',
  DERIVATIVE = 'DERIVATIVE',
}

export enum ApplicationStatuses {
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  RETURNED = 'RETURNED',
  CERTIFIED = 'CERTIFIED',
  QUALIFIED = 'QUALIFIED',
  REVOKED = 'REVOKED',
  CANCELED = 'CANCELED',
}

export const applicationTypeTitles = {
  [ApplicationTypes.NEW]: 'New',
  [ApplicationTypes.VARIANT]: 'Variant',
  [ApplicationTypes.DERIVATIVE]: 'Derivative',
};

export enum TransactionDecisionTypes {
  APPROVE_APPLICATION = 'APPROVE_APPLICATION',
  UNAPPROVE_APPLICATION = 'UNAPPROVE_APPLICATION',

  TRANSFER_OWNERSHIP_FROM_COMPANY = 'TRANSFER_OWNERSHIP_FROM_COMPANY',
  TRANSFER_OWNERSHIP_TO_COMPANY = 'TRANSFER_OWNERSHIP_TO_COMPANY',

  MERGE_VARIANT_IN = 'MERGE_VARIANT_IN',
  MERGE_VARIANT_OUT = 'MERGE_VARIANT_OUT',
}
