import { CSSProperties } from 'react';

export interface DataTableColumnProps<T> {
  field: string;
  label: string | JSX.Element;
  width?: number | string;
  minWidth?: number | string;
  style?: CSSProperties;
  sortable?: boolean;
  fixed?: boolean;
  wordWrap?: boolean;
  children?: (row: T, nested: boolean) => JSX.Element | JSX.Element[] | string | number | null | undefined;
  textAlign?: 'right' | 'center' | 'left';
  prefix?: boolean;
  sortWrap?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function DataTableColumn<T>(props: DataTableColumnProps<T>) {
  return null;
}
