import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';

import { DataTableCellContent, StyledTableCell } from './DataTableCell';
import { DataTableExpandButton } from './DataTableExpandButton';
import { DataTableHeadProps } from './DataTableHead';
import { DataTableHeadCell } from './DataTableHeadCell';

export const useExpandColumnStyles = makeStyles(() => ({
  expandColumn: {
    width: '2.1%',
    minWidth: 30,
    padding: '12px 2px',
    textAlign: 'right',
  },
}));

export interface DataTableHeadComponentProps<T> extends Omit<DataTableHeadProps<T>, 'TableHeadComponent'> {
  getSortHandler: (property: string) => () => void;
}

export function DataTableHeadComponent<T>(props: DataTableHeadComponentProps<T>) {
  const {
    sorting,
    settings,
    expandable,
    getSortHandler,
    defaultExpanded = false,
    enableExpandAll = false,
    isExpandAll = false,
    onExpandAll,
  } = props;
  const classes = useExpandColumnStyles();
  const [open, setOpen] = React.useState(defaultExpanded);

  const handleOpen = () =>
    setOpen(prev => {
      if (onExpandAll) {
        onExpandAll(!prev);
      }
      return !prev;
    });

  useEffect(() => {
    if (isExpandAll === true && open === false) setOpen(true);
    if (isExpandAll === false && open === true) setOpen(false);
  }, [isExpandAll, open]);

  return (
    <TableHead>
      <TableRow>
        {settings
          .filter(column => column.prefix === true)
          .map(column => (
            <DataTableHeadCell key={column.field} settings={column} sorting={sorting} getSortHandler={getSortHandler} />
          ))}
        {expandable && !defaultExpanded && (
          <StyledTableCell className={classes.expandColumn}>
            <DataTableCellContent visible={enableExpandAll}>
              {enableExpandAll && <DataTableExpandButton open={open} onClick={handleOpen} />}
            </DataTableCellContent>
          </StyledTableCell>
        )}
        {settings
          .filter(column => column.prefix !== true)
          .map(column => (
            <DataTableHeadCell key={column.field} settings={column} sorting={sorting} getSortHandler={getSortHandler} />
          ))}
      </TableRow>
    </TableHead>
  );
}
