import { makeStyles, Theme } from '@material-ui/core';

// @ts-ignore
import _var from '../../styles/_variables.scss';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 247,
    position: 'relative',
    '& .MuiTypography-body2': {
      marginLeft: '1.5rem',
      color: _var.bodyTextGray,
      lineHeight: '18px',
    },
    '& .MuiButton-text': {
      color: _var.primary,
      fontWeight: 700,
      textTransform: 'none',
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 6,
      paddingRight: 0,
      lineHeight: '18px',
      marginBottom: 3,
    },
    '& .MuiTypography-root': {
      fontSize: theme.spacing(1.75),
      lineHeight: 1.4,
    },
    '& .MuiDropzoneArea-root': {
      minHeight: theme.spacing(18.75),
      marginBottom: theme.spacing(1),
      opacity: 0.5,
      transition: '0.4s',
      color: _var.primary,
      '&:hover': {
        opacity: 1,
      },
      '& .MuiSvgIcon-root': {
        fill: _var.primary,
      },
      '& .MuiTypography-root': {
        fontWeight: 'bold',
      },
    },
    '& .MuiSvgIcon-root': {
      fill: _var.textLightGray,
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingRight: theme.spacing(0.75),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      fill: _var.bodyTextGray,
      fontSize: theme.spacing(3),
      alignSelf: 'baseline',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: theme.spacing(1.5),
    '& .MuiTypography-root': {
      color: _var.textGray,
      maxWidth: 400,
      overflowWrap: 'break-word',
    },
    '& .MuiButton-root': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(1),
      color: _var.textLightGray,
      opacity: 0.6,
      transition: '0.2s',
      minWidth: theme.spacing(4.5),
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: 0.8,
      },
    },
  },
  notFoundWrapper: {
    color: _var.textLightGray,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiTypography-root': {
      opacity: 0.5,
    },
  },
  loader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: theme.spacing(18.75),
    zIndex: 10,
    position: 'absolute',
    background: 'rgba(255,255,255, 1)',
    flexDirection: 'column',
  },
  loadingTitle: {
    fontSize: 14,
    color: _var.bodyTextGray,
    paddingTop: theme.spacing(2),
  },
  error: {
    fontSize: 12,
    color: _var.errorRed,
  },
  fileName: {
    '& .MuiTypography-root': {
      lineHeight: '24px',
    },
  },
}));
