import React, { ChangeEvent, useState } from 'react';
import { Button, Col, FormGroup, Icon, Row } from 'rsuite';
import { RsControlLabel } from '../../../../shared-components/rsuite';
import { NoTableData, PageTemplate } from '../../../partials';
import { TestCaseFormValues, MeasurementsType } from '../types';
import {
  confirmAction,
  getStoredSummaryUrl,
  jsonCopy,
  mergeObjects,
  prepareYupModel,
  reformatDate,
} from '../../../../helpers';
import { RouteComponentProps, withRouter } from 'react-router';
import { ARCHIVED, emptyMeasurement, emptyTCItem, measurementTypeValues, PUBLISHED, DRAFT } from '../data/constants';
import { useSelector } from 'react-redux';
import { userState } from '../../../../redux/modules/user/reducer';
import * as Yup from 'yup';
import { Schema } from 'yup';
import dot from 'dot-object';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { publishTC, updateTCState } from '../../../../api/test-case';
import { AxiosError } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { ErrorObject, ValidationErrorResponse } from '../../../../helpers/types';
import { DateTime } from 'luxon';
import { tcStatuses } from '../../../../helpers/constants/test-case-statuses';
import { formatErrors } from '../../../../helpers/request';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import { LabeledInput, LabeledSelect, LabeledTextArea } from '../../../../shared-components/labeled-inputs';
import { BtnLoadingState } from '../../../../helpers/constants/loading-states';
import { Confirm } from '../../../../helpers/confirmationPopup/Confirm';
import { usePageChangeDetection } from '../../../../helpers/hooks/useChangeDetection';
import { MaterialAddButton } from '../../../../shared-components/button/MaterialAddButton';
import DragDropList from '../../../../shared-components/dnd-list/DragDropList';

const ErrorText = styled.small`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  &:hover {
    overflow: unset;
  }
`;

Yup.addMethod(Yup.array, 'unique', function(mapper = (a: ErrorObject) => a, message = '') {
  return this.test('unique', message, list => {
    const mappedList = list.map(mapper).filter(Boolean);
    return mappedList.length === new Set(mappedList).size;
  });
});

declare module 'yup' {
  interface ArraySchema<T> {
    unique(mapper: (a: T) => string | T, message?: string): ArraySchema<T>;
  }
}

interface Props extends RouteComponentProps {
  item?: TestCaseFormValues;
  onSave: (data: TestCaseFormValues, saveOnly?: boolean) => Promise<void>;
}

const calculateHashValue = (value: TestCaseFormValues) => {
  const testCase = jsonCopy(value);
  return JSON.stringify({
    id: testCase.id,
    status: testCase.status,
    name: testCase.name,
    description: testCase.description,
    instructions: testCase.instructions,
    pythonScript: testCase.pythonScript,
    pythonScriptHash: testCase.pythonScriptHash,
    measurements: testCase.measurements
      .map(measurement => ({
        id: measurement.id,
        name: measurement.name,
        comment: measurement.comment,
        measurementType: measurement.measurementType,
      }))
      .sort((a, b) => (a.name as string).localeCompare(b.name as string)),
  });
};

export const TCForm = withRouter(({ item = jsonCopy(emptyTCItem), onSave, history }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [btnLoading, setBtnLoading] = useState(BtnLoadingState.NONE);
  const [{ tcItem, errors }, setState] = useState<{ tcItem: TestCaseFormValues; errors: ErrorObject }>({
    tcItem: item,
    errors: {},
  });
  const user = useSelector((state: { userReducer: userState }) => state.userReducer.user);

  const { isModified, resetChangeDetection, setChangeDetectionState } = usePageChangeDetection(
    calculateHashValue,
    tcItem,
  );
  const [isFieldValidated, setFieldValidated] = useState(false);

  const DraftTCSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('common.validation.required'))
      .max(512, t('common.validation.length', { length: 512 })),
    description: Yup.string()
      .trim()
      .required(t('common.validation.required')),
    pythonScript: Yup.string()
      .trim()
      .required(t('common.validation.required'))
      .matches(/.+[.][a-zA-Z0-9]+$/, {
        message: t('common.validation.pythonScriptName'),
        excludeEmptyString: true,
      }),
    pythonScriptHash: Yup.string()
      .trim()
      .required(t('common.validation.required')),
    instructions: Yup.string().trim(),
    measurements: Yup.array()
      .of(
        Yup.object().shape({
          comment: Yup.string().trim(),
          alwaysProduced: Yup.bool(),
          name: Yup.string()
            .trim()
            .required(t('common.validation.required'))
            .max(512, t('common.validation.length', { length: 512 })),
          measurementType: Yup.string()
            .trim()
            .required(t('common.validation.required'))
            .max(255, t('common.validation.length', { length: 255 })),
        }),
      )
      .unique(c => c.name, t('common.validation.UniqueMeasurementDefinitionNames'))
      .min(1, t('common.validation.emptyMeasurement')),
  });

  const PublishTCSchema = DraftTCSchema.concat(
    Yup.object().shape({
      measurements: Yup.array().min(1, t('common.validation.emptyMeasurement')),
    }),
  );
  const yupDraftTCModel = prepareYupModel(DraftTCSchema);

  const handleChangeWithValidation = (value: string | boolean, name: string) => {
    setState(prevState => {
      const newState = jsonCopy(prevState);
      dot.str(name, value, newState.tcItem);
      const errors = isFieldValidated ? yupDraftTCModel.checkForFieldFormatted(name, newState.tcItem) : newState.errors;
      return mergeObjects(newState, { errors });
    });
  };

  const isFieldError = Object.values(errors).some(error => error.length > 0);

  const handleChangeInputField = (value: string | boolean, name: string) => {
    handleChangeWithValidation(value, name);
  };

  const handleRemoveMeasurement = (index: number): void => {
    setState(prevState => {
      const newState = jsonCopy(prevState);
      newState.tcItem.measurements.splice(index, 1);
      newState.errors = {};
      const errors = isFieldValidated ? yupDraftTCModel.checkFormatted(newState.tcItem) : newState.errors;
      return mergeObjects(newState, { errors: errors ? errors : {} });
    });
  };

  const handleAddMeasurement = (): void => {
    setState(prevState => {
      const newState = jsonCopy(prevState);
      newState.tcItem.measurements.push({ ...jsonCopy(emptyMeasurement) });
      newState.errors = {};
      const errors = isFieldValidated ? yupDraftTCModel.checkFormatted(newState.tcItem) : newState.errors;
      return mergeObjects(newState, { errors: errors ? errors : {} });
    });
  };

  const validateForm = (scheme: Schema<TestCaseFormValues>) => {
    setFieldValidated(true);
    const errors = prepareYupModel(scheme).checkFormatted(tcItem);
    if (errors) {
      setState(prevState => mergeObjects(prevState, { errors }));
      return false;
    }
    return true;
  };

  const onSaveAndExit = () => {
    if (validateForm(DraftTCSchema)) {
      setBtnLoading(BtnLoadingState.SAVE);
      return onSave(tcItem)
        .then(() => {
          setBtnLoading(BtnLoadingState.NONE);
        })
        .catch(error => {
          setBtnLoading(BtnLoadingState.NONE);
          if (error.response?.data.errors) {
            const errors = formatErrors(error.response?.data.errors);
            setState(prevState => mergeObjects(prevState, { errors }));
          }
        });
    }
  };

  const onSaveAndStay = () => {
    if (validateForm(DraftTCSchema)) {
      setBtnLoading(BtnLoadingState.SAVE_AND_STAY);
      return onSave(tcItem, true)
        .then(() => {
          setChangeDetectionState(tcItem);
          setBtnLoading(BtnLoadingState.NONE);
        })
        .catch(error => {
          setBtnLoading(BtnLoadingState.NONE);
          if (error.response?.data.errors) {
            const errors = formatErrors(error.response?.data.errors);
            setState(prevState => mergeObjects(prevState, { errors }));
          }
        });
    }
  };

  const onPublish = () => {
    if (validateForm(PublishTCSchema)) {
      setBtnLoading(BtnLoadingState.PUBLISH);
      return publishTC(tcItem)
        .then(() => {
          setBtnLoading(BtnLoadingState.NONE);
          addToast(t('testCases.saved'), {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
          history.push(`/test-case?tab=${tcStatuses.published}`);
        })
        .catch((error: AxiosError<ValidationErrorResponse>) => {
          setBtnLoading(BtnLoadingState.NONE);
          if (error.response?.data.errors) {
            const errors = formatErrors(error.response?.data.errors);
            setState(prevState => mergeObjects(prevState, { errors }));
          }
          handleRequestFail(error, addToast);
        });
    }
  };

  const handleMeasurementNameChange = (value: string, name: string) => {
    setState(prevState => {
      const newState = jsonCopy(prevState);
      dot.str(name, value, newState.tcItem);
      console.log('isFieldValidated', isFieldValidated);
      if (isFieldValidated) {
        const errors = mergeObjects(
          yupDraftTCModel.checkForFieldFormatted('measurements', newState.tcItem),
          yupDraftTCModel.checkForFieldFormatted(name, newState.tcItem),
        );
        return mergeObjects(newState, { errors });
      } else {
        const errors = newState.errors;
        return mergeObjects(newState, { errors });
      }
    });
  };

  const onCancel = () =>
    confirmAction(
      () => isModified,
      () => {
        resetChangeDetection();
        history.push(getStoredSummaryUrl('test-case'));
      },
    );

  const onArchive = () => {
    Confirm({
      title: t('common.placeholders.areYouSure'),
      message: t('certifications.archiveOnEditPage'),
      onAccept: () =>
        updateTCState({ id: item.id as number, status: ARCHIVED }).then(() => history.push(`/test-case?tab=ARCHIVED`)),
      addToast,
    });
  };

  const setDragDropDataState = (reorderData: MeasurementsType[]) => {
    setState(prevState => {
      const newState = jsonCopy(prevState);
      newState.tcItem.measurements = reorderData;
      newState.errors = {};
      const errors = isFieldValidated ? yupDraftTCModel.checkFormatted(newState.tcItem) : newState.errors;
      return mergeObjects(newState, { errors: errors ? errors : {} });
    });
  };

  const allFieldEditable = tcItem.status !== DRAFT && tcItem.status !== undefined;

  return (
    <PageTemplate
      title={tcItem.id ? t('testCases.editTitle') : t('testCases.createTitle')}
      withBorder
      actionLeft={
        <div className="btn-wrapper">
          <Button onClick={onCancel} className="cancel">
            {t('common.navigation.cancel')}
          </Button>
          <Button
            appearance="primary"
            onClick={onSaveAndExit}
            className="save-and-exit"
            loading={btnLoading === BtnLoadingState.SAVE}
            disabled={btnLoading !== BtnLoadingState.NONE || isFieldError}
          >
            {t('common.navigation.saveAndExit')}
          </Button>
        </div>
      }
      actionRight={
        <div className="btn-wrapper">
          <Button
            appearance="primary"
            onClick={onSaveAndStay}
            className="save"
            loading={btnLoading === BtnLoadingState.SAVE_AND_STAY}
            disabled={btnLoading !== BtnLoadingState.NONE || isFieldError}
          >
            {t('common.navigation.save')}
          </Button>
          {tcItem.status === PUBLISHED ? (
            <Button appearance="primary" className="archive" onClick={onArchive}>
              {t('common.actions.archive')}
            </Button>
          ) : (
            <Button
              appearance="primary"
              onClick={onPublish}
              className="publish"
              loading={btnLoading === BtnLoadingState.PUBLISH}
              disabled={btnLoading !== BtnLoadingState.NONE || isFieldError}
            >
              {t('common.actions.publish')}
            </Button>
          )}
        </div>
      }
    >
      <Row gutter={20}>
        <Col xs={12}>
          <LabeledInput
            required
            label={t('testCases.name')}
            name="name"
            placeholder={t('testCases.name')}
            defaultValue={tcItem.name}
            errors={errors}
            disabled={allFieldEditable}
            onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => {
              handleChangeInputField(value, event.target.name);
            }}
          />
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={8}>
              <FormGroup>
                {tcItem.id ? (
                  <>
                    <RsControlLabel className="test-case-id">{t('testCases.id')}</RsControlLabel>
                    <p>{tcItem.id}</p>
                  </>
                ) : (
                  <>{null}</>
                )}
              </FormGroup>
            </Col>
            <Col xs={8}>
              <FormGroup>
                <RsControlLabel>{t('testCases.createdAt')}</RsControlLabel>
                <p>{reformatDate(tcItem.createdAt || DateTime.local().toISO())}</p>
              </FormGroup>
            </Col>
            <Col xs={8}>
              <FormGroup>
                <RsControlLabel>{t('testCases.createdBy')}</RsControlLabel>
                <p>{tcItem.createdBy || (user && `${user.firstName} ${user.lastName}`)}</p>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={12}>
          <LabeledTextArea
            required
            label={t('testCases.description')}
            rows={5}
            name="description"
            placeholder={t('testCases.description')}
            onChange={(value: string, event: ChangeEvent<HTMLTextAreaElement>) => {
              handleChangeInputField(value, event.target.name);
            }}
            defaultValue={tcItem.description}
            errors={errors}
          />
        </Col>
        <Col xs={12}>
          <LabeledTextArea
            label={t('testCases.instruction')}
            rows={5}
            name="instructions"
            placeholder={t('testCases.instruction')}
            defaultValue={tcItem.instructions}
            errors={errors}
            onChange={(value: string, event: ChangeEvent<HTMLTextAreaElement>) => {
              handleChangeInputField(value, event.target.name);
            }}
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={12}>
          <LabeledInput
            required
            label={t('testCases.pythonScript')}
            name="pythonScript"
            placeholder={t('testCases.pythonScript')}
            defaultValue={tcItem.pythonScript}
            errors={errors}
            disabled={allFieldEditable}
            onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => {
              handleChangeInputField(value, event.target.name);
            }}
          />
        </Col>
        <Col xs={12}>
          <LabeledInput
            required
            label={t('testCases.pythonScriptHash')}
            name="pythonScriptHash"
            placeholder={t('testCases.pythonScriptHash')}
            defaultValue={tcItem.pythonScriptHash}
            errors={errors}
            disabled={allFieldEditable}
            onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => {
              handleChangeInputField(value, event.target.name);
            }}
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={24}>
          <h3>{t('testCases.measurementsHeader')}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '1rem',
                fontWeight: 'bold',
                marginBottom: 5,
                marginTop: 15,
              }}
            >
              <div style={{ width: 50 }}></div>
              <div style={{ flex: 1, marginRight: 20 }}>{t('testCases.measurement.name')}</div>
              <div style={{ flex: 0.2, minWidth: 150, marginRight: 20 }}>{t('testCases.measurement.type')}</div>
              <div style={{ flex: 1 }}>{t('testCases.measurement.comment')}</div>
            </div>
            {tcItem.measurements.length === 0 ? (
              <div style={{ minHeight: 200 }}>
                <NoTableData />
              </div>
            ) : (
              <DragDropList<MeasurementsType>
                data={tcItem.measurements}
                setDataState={setDragDropDataState}
                renderRow={(rowData: MeasurementsType, rowIndex: number) => (
                  <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 20, paddingRight: 10 }}>
                    <div
                      style={{
                        width: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: 10,
                      }}
                    >
                      <span>
                        {tcItem.status === PUBLISHED ? (
                          <>{null}</>
                        ) : (
                          <Icon icon="trash-o" size="lg" onClick={() => handleRemoveMeasurement(rowIndex)} />
                        )}
                      </span>
                    </div>
                    <div style={{ flex: 1, marginRight: 20 }}>
                      <LabeledInput
                        placeholder={t('testCases.measurement.name')}
                        name={`measurements[${rowIndex}].name`}
                        value={rowData.name}
                        errors={errors}
                        disabled={tcItem.status === PUBLISHED}
                        onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => {
                          handleMeasurementNameChange(value, event.target.name);
                        }}
                      />
                    </div>
                    <div style={{ flex: 0.2, minWidth: 150, marginRight: 20 }}>
                      <LabeledSelect
                        data={measurementTypeValues}
                        searchable={false}
                        cleanable={false}
                        disabled={tcItem.status === PUBLISHED}
                        placeholder={t('common.placeholders.select')}
                        name={`measurements[${rowIndex}].measurementType`}
                        errors={errors}
                        value={rowData.measurementType}
                        onSelect={(value: string) => {
                          handleChangeWithValidation(value, `measurements[${rowIndex}].measurementType`);
                        }}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <LabeledInput
                        placeholder={t('testCases.measurement.comment')}
                        name={`measurements[${rowIndex}].comment`}
                        value={rowData.comment}
                        errors={errors}
                        onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => {
                          handleChangeInputField(value, event.target.name);
                        }}
                      />
                    </div>
                  </div>
                )}
              />
            )}
          </div>

          <div>
            {typeof errors === 'object' && errors.measurements ? (
              <ErrorText className="text-danger">{errors && errors.measurements}</ErrorText>
            ) : (
              <>{null}</>
            )}
          </div>
          {tcItem.status === PUBLISHED ? (
            <>{null}</>
          ) : (
            <MaterialAddButton className="mt-1" onClick={handleAddMeasurement}>
              <span>{t('testCases.measurement.newMeasurementBtn')}</span>
            </MaterialAddButton>
          )}
        </Col>
      </Row>
    </PageTemplate>
  );
});
