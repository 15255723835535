import React from 'react';
import { Button, Icon, IconButton } from 'rsuite';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin-left: 5px;
  padding: 5px 10px !important;
  border: none;
`;

const StyledBigButton = styled(StyledButton)`
  i {
    font-size: 20px;
  }
`;

const RoundIcon = styled(IconButton)`
  &.rs-btn-icon {
    width: 20px;
    margin-right: 4px;
    height: 20px;

    &.rs-btn-icon-circle > .rs-icon {
      font-size: 12px;
      height: 20px;
      width: 18px;
      padding: 1px 0;
    }
  }
`;

export const DeleteButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledBigButton onClick={onClick} appearance="ghost">
      <Icon icon="trash" />
    </StyledBigButton>
  );
};

export const AddButton = ({ onClick, label }: { onClick: () => void; label: string }) => {
  return (
    <StyledButton onClick={onClick} appearance="ghost" componentClass="span">
      <RoundIcon icon={<Icon icon="plus" />} circle />
      <span>{label}</span>
    </StyledButton>
  );
};
