import React from 'react';
import { Icon } from 'rsuite';
import { customIconsSet } from '../../../helpers/constants';

interface Props {
  isOpen: boolean;
  grey?: boolean;
}

export const ExpandCollapseIcon = ({ isOpen, grey }: Props) => {
  const getIcon = () => {
    if (grey) return isOpen ? customIconsSet.MINUS_SQUARE_GREY : customIconsSet.PLUS_SQUARE_GREY;
    return isOpen ? customIconsSet.MINUS_SQUARE : customIconsSet.PLUS_SQUARE;
  };
  return <Icon className={isOpen ? 'is-expanded' : 'is-collapsed'} icon={getIcon()} size="lg" />;
};

export const renderTreeToggleIconFunction = (isOpen: boolean, hasChildren: boolean) =>
  hasChildren ? <ExpandCollapseIcon isOpen={isOpen} /> : null;
