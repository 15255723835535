import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import React from 'react';
import { isDescendant, NodeRendererProps } from 'react-sortable-tree';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Icon, IconButton } from 'rsuite';
import styled from 'styled-components';
import { certificationStatusTitles } from '../../helpers/constants/certification-statuses';
import { RadioField } from '../labeled-inputs';
import { useTranslation } from 'react-i18next';
import {
  CapabilityInputNumber,
  AddMaximumButton,
  RemoveMaximumButton,
} from '../../components/pages/certification/partials/capabilities';

const ErrorText = styled.small`
  display: flex;
  align-items: center;
`;

const ChildNumberLimition = ({ title, value }: { title: string; value: number }) => {
  return (
    <>
      <span className="pl-1" style={{ fontWeight: 'normal' }}>
        {title}:
      </span>
      <span style={{ marginLeft: '0.5rem' }}>{value}</span>
    </>
  );
};

export const CapabilitiesTreeRenderer = ({
  scaffoldBlockPxWidth,
  toggleChildrenVisibility,
  connectDragPreview,
  connectDragSource,
  isDragging,
  canDrop,
  canDrag,
  node,
  title,
  subtitle,
  draggedNode,
  path,
  treeIndex,
  isSearchMatch,
  isSearchFocus,
  buttons,
  handleCheck,
  handleMinChildrenChange,
  handleMaxChildrenChange,
  handleMaxiumButtonPress,
  className,
  style,
  didDrop,
  rowDirection,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isOver,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  treeId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parentNode,
  ...otherProps
}: NodeRendererProps & {
  handleCheck: () => void;
  handleMinChildrenChange: (x: number) => void;
  handleMaxChildrenChange: (x: number) => void;
  handleMaxiumButtonPress: (x: number) => void;
}) => {
  const nodeTitle = title || node.title;
  const nodeSubtitle = subtitle || node.subtitle;
  const removeItem = node.removeItem;
  const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;
  let handle;

  const { t } = useTranslation();

  if (canDrag) {
    if (typeof node.children === 'function' && node.expanded) {
      // Show a loading symbol on the handle when the children are expanded
      //  and yet still defined by a function (a callback to fetch the children)
      handle = (
        <div className="rst__loadingHandle">
          <div className="rst__loadingCircle">
            {[...new Array(12)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classNames('rst__loadingCirclePoint', rowDirectionClass)}
              />
            ))}
          </div>
        </div>
      );
    } else {
      // Show the handle used to initiate a drag-and-drop
      handle = connectDragSource(<div className="rst__moveHandle" />, {
        dropEffect: 'copy',
      });
    }
  }

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
  if (rowDirection === 'rtl') {
    // @ts-ignore
    buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
  }

  const handleAddMaxiumButtonPress = () => {
    handleMaxiumButtonPress(0);
  };

  const handleRemoveMaxiumButtonPress = () => {
    handleMaxiumButtonPress(-1);
  };

  const onViewCertification = (id: number) => window.open(`#/certification/${id}`);

  return (
    <div style={{ height: '100%' }} {...otherProps}>
      {toggleChildrenVisibility && node.children && (node.children.length > 0 || typeof node.children === 'function') && (
        <div>
          <button
            type="button"
            aria-label={node.expanded ? 'Collapse' : 'Expand'}
            className={classNames(node.expanded ? 'rst__collapseButton' : 'rst__expandButton', rowDirectionClass)}
            style={buttonStyle}
            onClick={() =>
              toggleChildrenVisibility({
                node,
                path,
                treeIndex,
              })
            }
          />

          {node.expanded && !isDragging && (
            <div
              style={{ width: scaffoldBlockPxWidth }}
              className={classNames('rst__lineChildren', rowDirectionClass)}
            />
          )}
        </div>
      )}

      <div className={classNames('rst__rowWrapper', rowDirectionClass)}>
        {/* Set the row preview to be used during drag and drop */}
        {connectDragPreview(
          <div
            className={classNames(
              'rst__row',
              isLandingPadActive && 'rst__rowLandingPad',
              isLandingPadActive && !canDrop && 'rst__rowCancelPad',
              isSearchMatch && 'rst__rowSearchMatch',
              isSearchFocus && 'rst__rowSearchFocus',
              rowDirectionClass,
              className,
            )}
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...(style as React.CSSProperties),
            }}
          >
            {handle}

            <div
              className={classNames('rst__rowContents', !canDrag && 'rst__rowContentsDragDisabled', rowDirectionClass)}
            >
              <div className={classNames('rst__rowLabel', rowDirectionClass)} style={{ paddingRight: 5 }}>
                <span
                  className={classNames('rst__rowTitle', node.subtitle && 'rst__rowTitleWithSubtitle')}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {canDrag ? (
                    <Icon icon="trash" size="lg" onClick={() => removeItem(path)} style={{ cursor: 'pointer' }} />
                  ) : null}
                  <RadioField
                    className={classNames({
                      'ml-1': canDrag,
                    })}
                    onChange={handleCheck}
                    value={node.mandatory}
                    yesLabel="M"
                    noLabel="O"
                    disabled={!canDrag}
                  />
                  <span className="pl-1">
                    {typeof nodeTitle === 'function'
                      ? nodeTitle({
                          node,
                          path,
                          treeIndex,
                        })
                      : nodeTitle}
                  </span>
                  <small className="text-muted pl-1">{node.version}</small>
                  <small className="text-muted pl-1">[{certificationStatusTitles[node.status]}]</small>

                  {Array.isArray(node.children) &&
                  (node.children.length > 1 || node.children.some(c => c.mandatory === true)) ? (
                    canDrag ? (
                      <>
                        <span className="pl-1">{`${t('certifications.capabilities.minChildrenNumber')}:`}</span>
                        <span className="pl-1">
                          <CapabilityInputNumber
                            value={node.minChildren}
                            onNumberChange={handleMinChildrenChange}
                            hasErrors={node.isErrorChildrenFields?.[0] === true}
                          />
                        </span>
                        {node.maxChildren >= 0 ? (
                          <>
                            <span className="pl-1">{`${t('certifications.capabilities.maxChildrenNumber')}:`}</span>
                            <span className="pl-1">
                              <CapabilityInputNumber
                                value={node.maxChildren}
                                onNumberChange={handleMaxChildrenChange}
                                hasErrors={node.isErrorChildrenFields?.[1] === true}
                              />
                            </span>
                          </>
                        ) : null}

                        <span className="pl-1">
                          {node.maxChildren < 0 ? (
                            <AddMaximumButton onClick={handleAddMaxiumButtonPress}>
                              {t('certifications.capabilities.maxChildrenNumber')}
                            </AddMaximumButton>
                          ) : (
                            <RemoveMaximumButton onClick={handleRemoveMaxiumButtonPress}>
                              {t('certifications.capabilities.maxChildrenNumber')}
                            </RemoveMaximumButton>
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <ChildNumberLimition
                          title={`${t('certifications.capabilities.minChildrenNumber')}`}
                          value={node.minChildren}
                        />
                        {node.maxChildren >= 0 ? (
                          <ChildNumberLimition
                            title={`${t('certifications.capabilities.maxChildrenNumber')}`}
                            value={node.maxChildren}
                          />
                        ) : null}
                      </>
                    )
                  ) : null}

                  {!canDrag && (
                    <div className="actions">
                      <IconButton
                        appearance="link"
                        onClick={() => onViewCertification(node.id)}
                        icon={<Icon icon="eye" style={{ top: -1 }} />}
                      />
                    </div>
                  )}
                </span>

                {nodeSubtitle && (
                  <span className="rst__rowSubtitle">
                    {typeof nodeSubtitle === 'function'
                      ? nodeSubtitle({
                          node,
                          path,
                          treeIndex,
                        })
                      : nodeSubtitle}
                  </span>
                )}
              </div>

              <div className="rst__rowToolbar">
                {(buttons || []).map((btn, index) => (
                  <div
                    key={index} // eslint-disable-line react/no-array-index-key
                    className="rst__toolbarButton"
                  >
                    {btn}
                  </div>
                ))}
              </div>
            </div>
            {canDrag && (node.isErrorChildrenFields?.[0] === true || node.isErrorChildrenFields?.[1] === true) ? (
              <ErrorText className={classNames('text-danger', 'ml-1', rowDirectionClass)}>
                {t('certifications.capabilities.childrenNumberError')}
              </ErrorText>
            ) : null}
          </div>,
        )}
      </div>
    </div>
  );
};

CapabilitiesTreeRenderer.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: '',
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: 'ltr',
};

CapabilitiesTreeRenderer.propTypes = {
  node: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  // rtl support
  rowDirection: PropTypes.string,
  removeItem: PropTypes.any,
  handleCheck: PropTypes.any,
};
