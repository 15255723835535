import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  accordionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
const CollapseWrapper = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();
  return <div className={classes.accordionWrapper}>{children}</div>;
};

export default CollapseWrapper;
