import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { RESTORE_APPLICATION_DATA } from '../../../../redux/modules/application-flow';
import { useTranslation } from 'react-i18next';
import { MaterialAddButton } from '../../../../shared-components/button/MaterialAddButton';

const ButtonWrapper = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = () => {
    Object.keys(sessionStorage).forEach(key => {
      if (key !== 'summary-url') {
        sessionStorage.removeItem(key);
      }
    });
    dispatch({ type: RESTORE_APPLICATION_DATA });
    history.push('/application/new');
  };
  return (
    <MaterialAddButton appearance="primary" onClick={handleClick} className="ml-2">
      {t('applications.newApplication')}
    </MaterialAddButton>
  );
};

export const CreateNewApplicationButton = withRouter(ButtonWrapper);
