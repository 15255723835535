import React from 'react';
import { Button } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { getStoredSummaryUrl } from '../../../../../helpers';

export const ActionLeft = ({ history }: { history: { push: (route: string) => void } }) => {
  const { t } = useTranslation();
  return (
    <Button onClick={() => history.push(getStoredSummaryUrl('application'))}>{t('common.navigation.cancel')}</Button>
  );
};

export const ActionRight = ({
  handleSubmit,
  handleSaveExit,
  isSubmitting,
  isSaveExitButton,
  isNextButtonDisabled = false,
}: {
  isNextButtonDisabled?: boolean;
  isSubmitting: boolean;
  isSaveExitButton?: boolean;
  handleSaveExit?: (e?: React.FormEvent<HTMLFormElement>) => void;
  handleSubmit?: (e?: React.FormEvent<HTMLFormElement>) => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="btn-wrapper">
      {isSaveExitButton ? (
        <Button appearance="primary" onClick={() => handleSaveExit && handleSaveExit()} loading={isSubmitting}>
          {t('common.navigation.saveAndExit')}
        </Button>
      ) : null}
      <Button
        appearance="primary"
        onClick={() => handleSubmit && handleSubmit()}
        loading={isSubmitting}
        disabled={isNextButtonDisabled}
      >
        {t('common.navigation.next')}
      </Button>
    </div>
  );
};
