import dot from 'dot-object';

import { AppCertification, TestingInfo } from './reducer';
import i18n from '../../../helpers/i18n-setup';
import { ErrorObject } from '../../../helpers/types';
import { ComponentCombination } from '../../../api/application/types';
import { jsonCopy } from '../../../helpers';
import { LabsResponse } from '../../../api/application/get-labs-by-app-id';
import { mergeCertWithLabData } from '../../../components/pages/application/data/formatters';
import {
  CertificationWithLabData,
  testingFieldTypes,
  TestingInfoLab,
} from '../../../components/pages/application/types';
import { AppCapability } from './index';
import { CapabilitiesResponse, GetCapabilitiesByCC } from '../../../api/application/get-capabilities-by-CC';

export const getAllowedVersions = (currentCert: AppCertification, nextCert: AppCertification) => {
  if (currentCert.allowedVersions && nextCert.allowedVersions) {
    return currentCert.allowedVersions.filter(el => nextCert.allowedVersions?.some(item => item.id === el.id));
  }
  if (!nextCert.allowedVersions) {
    return currentCert.allowedVersions || [];
  }
  if (!currentCert.allowedVersions) {
    return nextCert.allowedVersions || [];
  }
};

export const getUniqueCapabilities = (list: AppCapability[]) =>
  list.reduce((accum, curr) => {
    if (!accum.some(el => el.id === curr.id)) {
      accum.push(curr);
    }
    return accum;
  }, [] as AppCapability[]);

export const isParentPresent = (capability: AppCapability, list: AppCapability[]): boolean =>
  capability.displayParent === undefined ||
  capability.displayParent === null ||
  list.some(el => el.index === capability.displayParent && isParentPresent(el, list));

const validateField = (
  data: CertificationWithLabData[],
  testingInfoLabs: TestingInfoLab[],
  errors: ErrorObject,
  field: testingFieldTypes,
  fullValidation = false,
): ErrorObject => {
  data.map(cert => {
    const isCertPresent = cert.certification.testingType[field];
    if (isCertPresent && cert.requiredTesting[field]) {
      const selectedTesting = testingInfoLabs.find(el => el.version.id === cert.version.id);
      if (selectedTesting !== undefined) {
        const isTestingSet = selectedTesting.testingDetails[field] === null;
        errors[`testingInfo[${cert.version.id}].testingDetails.${field}`] = isTestingSet
          ? i18n.t('common.validation.required')
          : '';
      } else if (fullValidation) {
        errors[`testingInfo[${cert.version.id}].testingDetails.${field}`] = i18n.t('common.validation.required');
      }
    }
  });
  return errors;
};

export const getUpdatedCertsWithErrors = (testingInfo: TestingInfo, labId: number) => {
  const validationResult: ErrorObject = {};
  const isExceptionNotRequested = testingInfo.testingException === null;

  const updatedTestingLabs = testingInfo.testingLabs.map(item => {
    if (item.testingDetails.conformance?.id === labId) {
      if (isExceptionNotRequested) {
        validationResult[`testingInfo[${item.version.id}].testingDetails.${testingFieldTypes.conformance}`] = i18n.t(
          'applications.labs.conformance',
        );
      }
      dot.set(`testingDetails.${testingFieldTypes.conformance}`, null, item);
    }
    if (item.testingDetails.interoperability?.id === labId) {
      if (isExceptionNotRequested) {
        validationResult[
          `testingInfo[${item.version.id}].testingDetails.${testingFieldTypes.interoperability}`
        ] = i18n.t('applications.labs.interoperability');
      }
      dot.set(`testingDetails.${testingFieldTypes.interoperability}`, null, item);
    }
    return item;
  });
  return {
    updatedTestingLabs,
    validationResult,
  };
};

export const validateTestingInfo = (
  labInfo: LabsResponse[],
  certifications: AppCertification[],
  testingInfo: TestingInfo,
  field?: testingFieldTypes,
  versionId?: number,
) => {
  const mergedData = mergeCertWithLabData(certifications, labInfo);
  const { testingLabs, testingException } = testingInfo;
  const errors: ErrorObject = {};
  if (testingException !== null) {
    return errors;
  }
  if (versionId && field) {
    const cert = mergedData.find(
      cert => cert.version.id === versionId && cert.certification.testingType[field] && cert.requiredTesting[field],
    );
    if (cert) {
      const idx = testingLabs.findIndex(
        ({ version, testingDetails }) => version.id === cert.version.id && testingDetails[field] === null,
      );
      if (idx !== -1) {
        errors[`testingInfo[${cert.version.id}].testingDetails.${field}`] = i18n.t('common.validation.required');
      } else {
        errors[`testingInfo[${cert.version.id}].testingDetails.${field}`] = '';
      }
    }
  } else if (field) {
    validateField(mergedData, testingLabs, errors, field);
  } else {
    validateField(mergedData, testingLabs, errors, testingFieldTypes.conformance, true);
    validateField(mergedData, testingLabs, errors, testingFieldTypes.interoperability, true);
  }
  return errors;
};

export const filterErrors = (errors: ErrorObject, path: string) =>
  Object.keys(errors).reduce((prev, next) => {
    if (!next.includes(path)) {
      prev[next] = errors[next];
    }
    return prev;
  }, {} as ErrorObject);

export const dropCCRole = (cc: ComponentCombination, roleName: string, index: number) => {
  const initialCC = jsonCopy(cc);
  // @ts-ignore
  dot.delete(`${roleName}[${index}]`, initialCC);
  return initialCC;
};

export const getParentCapability = (
  additions: {
    id: number;
    capabilities: AppCapability[];
  }[],
  capabilitisByCC: GetCapabilitiesByCC[],
): CapabilitiesResponse | undefined => {
  const targetCert = capabilitisByCC.find(cert => additions.some(addition => cert.id === addition.id));
  if (targetCert) {
    const targetCapability = targetCert.capabilities.find(item =>
      additions.some(addition => addition.capabilities.some(capability => item.capability.id === capability.id)),
    );
    return targetCapability && targetCapability.displayParent != null
      ? targetCert.capabilities[targetCapability.displayParent]
      : undefined;
  }
  return undefined;
};

export const isSingleSelectCapability = (
  additions: {
    id: number;
    capabilities: AppCapability[];
  }[],
  capabilitisByCC: GetCapabilitiesByCC[],
): boolean => {
  const parentCapability = getParentCapability(additions, capabilitisByCC);
  if (parentCapability) return parentCapability.minChildren === 1 && parentCapability.maxChildren === 1;
  return false;
};

export const isChildOfParentCapability = (
  certId: number,
  childId: number,
  topParentId: number,
  capabilitisByCC: GetCapabilitiesByCC[],
): boolean => {
  const targetCert = capabilitisByCC.find(cert => cert.id === certId);
  if (targetCert) {
    const childCapability = targetCert.capabilities.find(item => item.capability.id === childId);
    if (childCapability) {
      if (childCapability.capability.id === topParentId) return false;

      const isChildOfParent = (item: CapabilitiesResponse): boolean => {
        if (item.capability.id === topParentId) return true;
        if (item.displayParent === null || item.displayParent === undefined) return false;
        const parent = targetCert.capabilities[item.displayParent];
        return isChildOfParent(parent);
      };
      return isChildOfParent(childCapability);
    }
  }
  return false;
};
