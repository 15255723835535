import React, { PropsWithChildren } from 'react';
import { Button, createStyles, makeStyles, MenuItem, Select } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface LabeledMaterialSelectProps extends PropsWithChildren<any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void;
  value: unknown;
  items: { id: string | number; name: string }[];
  placeholder?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    applicationTypeNew: {
      width: 220,
      marginRight: '1rem',
      fontSize: '14px',
      borderRadius: 6,
      transition: 'border-color ease-in-out 0.3s',
      '& .MuiSelect-select:focus': {
        backgroundColor: '#ffffff',
      },
      '& .MuiOutlinedInput-input': {
        padding: 0,
      },
      '&:hover fieldset': {
        border: '1px solid #4d0630 !important',
      },
      '& fieldset': {
        transition: 'border-color ease-in-out 0.3s',
        border: '1px solid #e5e5ea !important',
      },
      '& svg': {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    selectPlaceholder: { margin: '12px 11px' },
    selectInput: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
    selectButton: {
      height: '43px',
      padding: 0,
      minWidth: '29px',
      '& .MuiButton-label': {
        right: '1px',
        justifyContent: 'flex-start',
      },
    },
    eraseSelectButton: { height: '43px', padding: 0, minWidth: '29px' },
    selectItem: {
      padding: '8px 12px',
      fontWeight: 'normal',
      fontSize: '14px',
      color: '#575757',
      transition: 'color 0.3s linear, background-color 0.3s linear',
      '&:hover': {
        backgroundColor: '#ffedfa',
      },
    },
  }),
);

const LabeledMaterialSelect = ({
  onChange,
  value,
  items,
  placeholder = 'Select',
  ...props
}: LabeledMaterialSelectProps) => {
  const classes = useStyles();
  return (
    <Select
      onChange={e => onChange(e.target.value)}
      value={value || ''}
      variant="outlined"
      className={classes.applicationTypeNew}
      defaultValue=""
      displayEmpty
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      data-test-id="labeled-material-select"
      IconComponent={() => <></>}
      renderValue={value => (
        <div className={classes.selectInput}>
          <div>
            <div className={classes.selectPlaceholder}>
              {value ? (
                items.find(type => type.id === value)?.name
              ) : (
                <span style={{ color: 'rgb(144, 147, 153)' }}>{placeholder}</span>
              )}
            </div>
          </div>
          <div>
            {value && (
              <Button
                className={classes.eraseSelectButton}
                onClick={e => {
                  e.stopPropagation();
                  onChange(null);
                }}
                onMouseDown={e => {
                  e.stopPropagation();
                  onChange(null);
                }}
              >
                <ClearIcon fontSize="small" />
              </Button>
            )}
            <Button className={classes.selectButton}>
              <ExpandMoreIcon />
            </Button>
          </div>
        </div>
      )}
      {...props}
    >
      {items.map(appType => (
        <MenuItem key={appType.id} value={appType.id} className={classes.selectItem}>
          {appType.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LabeledMaterialSelect;
