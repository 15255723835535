import { CATEGORIES_LOADED } from './actionTypes';
import { AnyAction } from 'redux';
import { Categories } from '../../../components/pages/application/types';

const initialState = {
  categories: [],
};

export type CategoriesState = Readonly<{
  categories: Categories[];
}>;

export const categoriesReducer = (state: CategoriesState = initialState, action: AnyAction) => {
  switch (action.type) {
    case CATEGORIES_LOADED:
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return state;
  }
};
