import { call, put, takeLatest, all } from 'redux-saga/effects';

import { LOAD_CLASSIFICATIONS, classificationsLoaded } from './';
import { appFinishLoading } from '../app';
import { getClassifications } from '../../../api/certification';

export function* getClassificationsSaga() {
  try {
    const response = yield call(getClassifications);
    yield put(classificationsLoaded(response.data));
    yield put(appFinishLoading());
  } catch (e) {
    yield put(appFinishLoading());
  }
}

function* watchGetClassificationsSaga() {
  yield takeLatest(LOAD_CLASSIFICATIONS, getClassificationsSaga);
}

export default function* watchMultipleClassificationsSagas() {
  yield all([watchGetClassificationsSaga()]);
}
