import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
// @ts-ignore
import _var from '../../../../styles/_variables.scss';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: 5,
    marginBottom: 35,
    '& .MuiGrid-root .MuiGrid-item': {
      paddingRight: theme.spacing(1.5),
    },
  },
  badge: {
    border: `1px solid ${_var.primary}`,
    borderRadius: '4px',
    padding: '2px 7px',
    display: 'flex',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    color: _var.primary,
  },
  badgeChecked: {
    fontWeight: 'bold',
    border: `1px solid ${_var.primary}`,
    borderRadius: '4px',
    padding: '2px 6px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'cetner',
    cursor: 'pointer',
    backgroundColor: _var.igoPlumLighten,
    color: _var.primary,
  },
  icon: {
    marginRight: '2px',
  },
}));

interface FilterData {
  qualifiedSolution: boolean;
  basedOnQualifiedSolution: boolean;
  other: boolean;
}

interface BadgesFilteringProps {
  filterData: FilterData;
  handleChangeFilterOption: (option: FilterData) => void;
}

export const BadgesFiltering = ({ filterData, handleChangeFilterOption }: BadgesFilteringProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item>
          <Box
            data-testid="box_qualifiedSolution"
            className={filterData.qualifiedSolution ? classes.badgeChecked : classes.badge}
            onClick={() =>
              handleChangeFilterOption({ ...filterData, qualifiedSolution: !filterData.qualifiedSolution })
            }
          >
            {filterData.qualifiedSolution && <CheckIcon fontSize="small" className={classes.icon} />}
            {t('applications.cc.loadCC.filterOptions.qualifiedSolution')}
          </Box>
        </Grid>
        <Grid item>
          <Box
            data-testid="box_basedOnQualifiedSolution"
            className={filterData.basedOnQualifiedSolution ? classes.badgeChecked : classes.badge}
            onClick={() =>
              handleChangeFilterOption({
                ...filterData,
                basedOnQualifiedSolution: !filterData.basedOnQualifiedSolution,
              })
            }
          >
            {filterData.basedOnQualifiedSolution && <CheckIcon fontSize="small" className={classes.icon} />}
            {t('applications.cc.loadCC.filterOptions.basedOnQualifiedSolution')}
          </Box>
        </Grid>
        <Grid item>
          <Box
            data-testid="box_other"
            className={filterData.other ? classes.badgeChecked : classes.badge}
            onClick={() => handleChangeFilterOption({ ...filterData, other: !filterData.other })}
          >
            {filterData.other && <CheckIcon fontSize="small" className={classes.icon} />}
            {t('applications.cc.loadCC.filterOptions.other')}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
