import React from 'react';
import { EMCRoleItem, RoleItemProps } from './types';
import { LabeledInlineAutoComplete } from '../../../../../../../shared-components/rsuite';
import { Card } from '../../../../../../../shared-components';
import { useTranslation } from 'react-i18next';
import { RemoveRoleButton } from './RemoveRoleButton';
import { HalfColumn } from '../styled-components';
import { WrapWithPublicIcon } from '../../../../../../../shared-components/helper-components/WrapWithPublicIcon';
import { Tooltip } from '../../../../../../../shared-components/tooltip/Tooltip';

interface Props extends RoleItemProps {
  roleItem: EMCRoleItem;
}

const EasyMeshRoleComponent = ({ roleItem, prefix, onChange, onRemove, onFocus, ccLength, errors }: Props) => {
  const { t } = useTranslation();
  const name = `${prefix}.os`;

  return (
    <Card>
      <HalfColumn>
        <LabeledInlineAutoComplete
          name={name}
          label={
            <>
              <WrapWithPublicIcon content={t('applications.cc.componentOS')} />
              <Tooltip translationKey="tooltips.componentOS" />
            </>
          }
          required
          onChange={(value: string) => onChange(value, name)}
          url="/elemental-components/os/autocomplete"
          placeholder={t('common.placeholders.autoComplete')}
          value={roleItem.os}
          errors={errors}
          onFocus={onFocus}
          autoComplete="off"
        />
      </HalfColumn>
      {ccLength > 1 ? <RemoveRoleButton onClick={() => onRemove(roleItem.roleName, roleItem.index)} /> : null}
    </Card>
  );
};

export const EasyMeshRoleItem = React.memo(EasyMeshRoleComponent, (prev, next) => {
  return (
    prev.showPopUp === next.showPopUp &&
    prev.ccLength === next.ccLength &&
    JSON.stringify([prev.roleItem, prev.errors]) === JSON.stringify([next.roleItem, next.errors])
  );
});
