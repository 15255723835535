import React from 'react';
import { Icon } from 'rsuite';
import { UploadedFile } from '../../shared-components/file-upload/types';
import styled from 'styled-components';
// @ts-ignore
import _variables from '../../styles/_variables.scss';
import classNames from 'classnames';
import { buildDownloadUrl } from '../../helpers/build-download-url';
import { customIconsSet } from '../../helpers/constants';

export const FileItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3px;

  &:hover {
    background-color: ${_variables.lightGray};
  }

  &.inline {
    display: inline-flex;

    div {
      margin-right: 2rem;
    }
  }
`;

export const FileItem = ({ file, isInline = false }: { file: UploadedFile; isInline?: boolean }) => {
  return (
    <FileItemWrapper key={file.id} className={classNames('text-info', { inline: isInline })}>
      <div>
        <Icon className="icon" icon="file" style={{ marginRight: '.5rem' }} />
        {file.name}
      </div>
      <a href={buildDownloadUrl(file)} target="_blank" rel="noopener noreferrer" download>
        <Icon icon={customIconsSet.DOWNLOAD_ICON} size="2x" />
      </a>
    </FileItemWrapper>
  );
};
