import React from 'react';
import { ApplicationActions, ApplicationSummaryRecord } from '../types';
import { wfaAdminRole, applicationDecision, applicationStatuses, atlRoles } from '../../../../helpers/constants';

import { ActionsColumn } from '../partials';
import { useSelector } from 'react-redux';
import { userState } from '../../../../redux/modules/user/reducer';
import { User } from '../../../../redux/modules/user/types';
import { useTranslation } from 'react-i18next';
import { SummaryTableProps } from '../../../../helpers/types';
import { reformatDate } from '../../../../helpers';
import DataTable from '../../../../shared-components/table/DataTable';
import { DataTableColumn } from '../../../../shared-components/table/DataTableColumn';
interface Props extends SummaryTableProps<ApplicationSummaryRecord> {
  status: string;
}

export const SummaryTable = (props: Props) => {
  const { data, loading, onChangeSorting, sorting, total, activePage, setActivePage, status } = props;
  const user = useSelector<{ userReducer: userState }, User>(state => state.userReducer.user as User);
  const { t } = useTranslation();

  return (
    <DataTable<ApplicationSummaryRecord>
      sticky
      pagination
      data={data}
      total={total}
      sorting={sorting}
      loading={loading}
      activePage={activePage}
      setActivePage={setActivePage}
      onChangeSorting={onChangeSorting}
      useEnvIdentifier={true}
    >
      <DataTableColumn field="cid" label={t('applications.summary.cid')} minWidth={110} sortable />
      <DataTableColumn field="id" label={t('applications.summary.appId')} minWidth={100} sortable />
      <DataTableColumn
        field="productName"
        label={t('applications.summary.name')}
        minWidth={160}
        width="20%"
        sortable
        wordWrap
      />
      <DataTableColumn
        field="variantName"
        label={t('applications.info.variantName')}
        minWidth={160}
        width="20%"
        sortable
        wordWrap
      />
      {atlRoles.includes(user.authority) && (
        <DataTableColumn
          field="owningCompanyName"
          label={t('applications.summary.company')}
          minWidth={100}
          width="20%"
          sortable
          wordWrap
        >
          {(row: ApplicationSummaryRecord) => row.owningCompany}
        </DataTableColumn>
      )}
      <DataTableColumn
        field="typeLabel"
        label={t('applications.summary.type')}
        minWidth={200}
        width="15%"
        sortable
        wordWrap
      >
        {(row: ApplicationSummaryRecord) => row.type}
      </DataTableColumn>
      <DataTableColumn field="atl" label={t('applications.summary.atl')} minWidth={150} width="30%">
        {(row: ApplicationSummaryRecord) => row.atl.map(atl => <p key={atl.id}>{atl.name}</p>)}
      </DataTableColumn>
      {status === applicationStatuses.approved && (
        <DataTableColumn field="status" label={t('applications.summary.decision')} minWidth={120} width="10%">
          {(row: ApplicationSummaryRecord) => applicationDecision[row.status]}
        </DataTableColumn>
      )}
      <DataTableColumn
        field="memberDate"
        label={t(`applications.summary.dates.${status}`)}
        minWidth={[applicationStatuses.inTesting, applicationStatuses.pendingApproval].includes(status) ? 180 : 120}
        sortable
      >
        {(row: ApplicationSummaryRecord) => (row.date ? reformatDate(row.date) : null)}
      </DataTableColumn>
      {user.authority !== wfaAdminRole && (
        <DataTableColumn field="actions" label={t('common.actions.title')} minWidth={100}>
          {(row: ApplicationSummaryRecord & { actions: ApplicationActions }) => <ActionsColumn record={row} />}
        </DataTableColumn>
      )}
    </DataTable>
  );
};
