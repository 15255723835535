export interface BasicRole {
  id: number;
  name: string;
}

export interface Role extends BasicRole {
  displayOrder: number;
  propertyName: string;
}

export enum RoleType {
  ACCESS_POINT = 1,
  STATION = 2,
  CONFIGURATOR = 8,
  SOURCE = 6,
  DISPLAY = 9,
  CONTROLLER = 3,
  WIFI_HOME_DESIGN = 5,
}

export const RoleTypeTitles = {
  [RoleType.ACCESS_POINT]: 'Access Point',
  [RoleType.STATION]: 'Station',
  [RoleType.CONFIGURATOR]: 'Configurator',
  [RoleType.SOURCE]: 'Source',
  [RoleType.DISPLAY]: 'Display',
  [RoleType.CONTROLLER]: 'Controller',
  [RoleType.WIFI_HOME_DESIGN]: 'Wi-Fi Home Design',
};
