import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import {
  AppFlows,
  AppFlowSteps,
  ApplicationFlowState,
  setLabsResponse,
  TestingException,
  CoreCCException,
  TestingInfo,
  updateApplicationProperty,
  updateExplanation,
  updateCoreCCExplanation,
  updateCoreCCExceptionRequested,
  updateSelectedTestingLabs,
  updateValidationErrors,
} from '../../../../../redux/modules/application-flow';
import { getLabsByAppId } from '../../../../../api/application/get-labs-by-app-id';
import { TestingLabsForm } from './partials';
import { TestingInfoLegend } from './partials/TestingInfoLegend';
import { Exception } from '../../../../partials/exception/Exception';
import { UploadedFile } from '../../../../../shared-components/file-upload/types';
import { jsonCopy } from '../../../../../helpers';
import { MemberClearData } from '../../../../../redux/modules/application-flow/data';
import { IsModifiedProps, numberSort, useChangeDetection } from '../../../../../helpers/hooks/useChangeDetection';
import { CircularLoader } from '../../../../../shared-components/loader/CircularLoader';
import { TestingLab, COMPANY_SUB_TYPE } from '../../types';
import { ProductTypes } from '../../../../../helpers/constants/product-types';
import { getSingleApplication } from '../../../../../api/application';
import { saveCoreCcException } from '../../../../../api/application/partial-update/save-core-cc-exception';
import { Confirm } from '../../../../../helpers/confirmationPopup/Confirm';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const LoaderWrapper = styled.div`
  position: relative;
  min-height: 200px;
`;

const calculateHashValue = (value: TestingInfo) => {
  const info = jsonCopy(value);
  return JSON.stringify({
    testingException: {
      explanation: info.testingException?.explanation,
      attachments: info.testingException?.attachments.map(item => item.id).sort(numberSort),
    },
    testingLabs: info.testingLabs
      .map(item => ({
        versionId: item.version.id,
        interoperabilityLab: item.testingDetails.interoperability?.id ?? false,
        conformanceLab: item.testingDetails.conformance?.id ?? false,
      }))
      .filter(item => item.interoperabilityLab !== false || item.conformanceLab !== false)
      .sort((a, b) => a.versionId - b.versionId),
  });
};

type Props = IsModifiedProps & {
  setExceptionAttachmentLoading: (value: boolean) => void;
};

export const TestingLabsStep = ({ setExceptionAttachmentLoading, setIsModified }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [needChangeException, setNeedChangeException] = useState(false);
  const [needRenewLabs, setNeedRenewLabs] = useState(true);

  const {
    id,
    testingInfo,
    errors,
    selectedTestingLabs,
    certificationsLabs: labs,
    componentCombination,
    componentChangeNote,
    ...application
  } = useSelector((state: { applicationFlow: ApplicationFlowState }) => state.applicationFlow);

  const defaultCcExplanation =
    componentCombination.componentCombinationVariantSource?.coreCCChanged && componentChangeNote
      ? componentChangeNote
      : t('applications.labs.exceptionConformanceDefaultTxt');
  const applicationType = application['@type'];
  const productType = application.productInfo.modelVariants[0].productType;

  const isNotContainCWGRF = useMemo(
    () => (labs ? !labs.some(lab => lab.certification.id === 1255 || lab.certification.id === 1256) : true),
    [labs],
  );

  const labSelectionFilter = (lab: TestingLab): boolean => {
    if (lab.companySubType === COMPANY_SUB_TYPE.CATL && isNotContainCWGRF) return false;

    if (
      lab.companySubType === COMPANY_SUB_TYPE.MRCL &&
      (applicationType === AppFlows.NEW ||
        (applicationType === AppFlows.VARIANT && productType === ProductTypes.QUALIFIED_SOLUTION))
    )
      return false;

    if (lab.companySubType === COMPANY_SUB_TYPE.MCTL && productType !== ProductTypes.QUICKTRACK) return false;

    return true;
  };

  const labList = useMemo(
    () =>
      labs
        ? labs
            .reduce<TestingLab[]>((accum, certification) => {
              const notInListLabs = certification.laboratories.filter(item => !accum.some(some => some.id === item.id));
              return accum.concat(notInListLabs);
            }, [])
            .filter(labSelectionFilter)
        : [],
    [labs],
  );

  useChangeDetection(setIsModified, calculateHashValue, testingInfo);

  const handleExplanationChange = (value: string) =>
    dispatch(
      updateApplicationProperty(
        value,
        'testingInfo.testingException.explanation',
        MemberClearData.NONE,
        AppFlowSteps.LABS,
      ),
    );

  const handleCoreCCExplanationChange = (value: string) =>
    dispatch(
      updateApplicationProperty(
        value,
        'testingInfo.coreCCException.explanation',
        MemberClearData.NONE,
        AppFlowSteps.LABS,
      ),
    );

  const handleExplanationAttachmentsChange = (files: UploadedFile[]) =>
    dispatch(
      updateApplicationProperty(
        files,
        'testingInfo.testingException.attachments',
        MemberClearData.NONE,
        AppFlowSteps.LABS,
      ),
    );

  const handleCoreCCExplanationAttachmentsChange = (files: UploadedFile[]) =>
    dispatch(
      updateApplicationProperty(
        files,
        'testingInfo.coreCCException.attachments',
        MemberClearData.NONE,
        AppFlowSteps.LABS,
      ),
    );

  const handleRequestExceptionChange = (newExceptionData: TestingException | null) =>
    dispatch(updateExplanation(newExceptionData));

  const handleDeselctAllCert = () => {
    dispatch(updateApplicationProperty(null, 'applicationFlow', MemberClearData.LABS));
  };

  const handleRequestCoreCCExceptionChange = (newExceptionData: CoreCCException | null) => {
    Confirm({
      cancelable: true,
      title: <div style={{ fontSize: '18px' }}> {t('common.placeholders.areYouSure')}</div>,
      message: (
        <>
          <div>{t('applications.labs.exceptionChangeMessage.title')}</div>
          <ul style={{ color: '#641246', marginLeft: '-20px', marginTop: 'revert' }}>
            <li style={{ marginBottom: 'inherit' }}>
              <b>{t('applications.labs.exceptionChangeMessage.requiredTest')}</b>
            </li>
            <li style={{ marginBottom: 'inherit' }}>
              <b>{t('applications.labs.exceptionChangeMessage.labSelection')}</b>
            </li>
            <li>
              <b>{t('applications.labs.exceptionChangeMessage.certificationPath')}</b>
            </li>
          </ul>
        </>
      ),
      onAccept: () => {
        setLoading(true);
        if (newExceptionData && newExceptionData.explanation == '') {
          newExceptionData.explanation = defaultCcExplanation;
        }
        handleDeselctAllCert();
        dispatch(updateCoreCCExplanation(newExceptionData));
        dispatch(
          updateCoreCCExceptionRequested(
            !componentCombination.componentCombinationVariantSource?.coreCCExceptionRequested,
          ),
        );
        setNeedChangeException(true);
      },
    });
  };

  useEffect(() => {
    if (componentCombination.componentCombinationVariantSource?.coreCCExceptionRequested) {
      const initCoreCCException = { explanation: defaultCcExplanation, attachments: [] };
      dispatch(updateCoreCCExplanation(initCoreCCException));
    }
  }, []);

  useEffect(() => {
    if (needChangeException) {
      handleDeselctAllCert();
      const coreCCExceptionInfo = testingInfo.coreCCException
        ? { coreCCException: testingInfo.coreCCException }
        : { coreCCException: null };
      saveCoreCcException(id as number, coreCCExceptionInfo)
        .then(() => {
          setNeedRenewLabs(true);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [needChangeException]);

  useEffect(() => {
    if (needRenewLabs) {
      Promise.all([getLabsByAppId(id as number), getSingleApplication(id as number)])
        .then(responses => {
          dispatch(setLabsResponse(responses[0].data));
          dispatch(
            updateApplicationProperty(
              responses[1].data.productInfo.modelVariants[0].productType as string,
              'productInfo.modelVariants[0].productType',
            ),
          );
          dispatch(updateValidationErrors({}));
          setLoading(false);
          setNeedRenewLabs(false);
          setNeedChangeException(false);
        })
        .catch(() => setLoading(false));
    }
  }, [needRenewLabs]);

  useEffect(() => {
    if (labs) {
      const selectedLabIds = Array.from(
        testingInfo.testingLabs.reduce<Set<number>>((set, { testingDetails }) => {
          if (testingDetails) {
            Object.values(testingDetails).forEach(item => item?.id && set.add(item.id));
          }
          return set;
        }, new Set()),
      );

      const preSelectedLabs = labList.filter(
        lab =>
          selectedLabIds.includes(lab.id as number) && !selectedTestingLabs.some(reduxLab => reduxLab.id === lab.id),
      );

      if (preSelectedLabs.length) {
        dispatch(
          updateSelectedTestingLabs({
            selectedTestingLabs: [...selectedTestingLabs, ...jsonCopy(preSelectedLabs)],
          }),
        );
      }
    }
  }, [labList]);

  return (
    <div className="testing-labs">
      <SubHeader>
        <span>{t('applications.labs.description')}</span>
      </SubHeader>
      {loading ? (
        <LoaderWrapper>
          <CircularLoader size={20} content={t('common.placeholders.loadingData')} />
        </LoaderWrapper>
      ) : (
        <TestingLabsForm labs={labList} certLabData={labs} />
      )}
      <TestingInfoLegend />
      {componentCombination.componentCombinationVariantSource?.coreCCExceptionAvailable ? (
        <div style={{ marginBottom: '-40px' }}>
          <Exception
            text={t('applications.labs.requestExceptionConformance')}
            field={'testingInfo.coreCCException.explanation'}
            tooltipContent={
              <Trans i18nKey="applications.labs.exceptionConformanceTooltip">
                prefix
                <a
                  href="https://www.wi-fi.org/file/quicktrack-qualified-solution-policy"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: _var.primary }}
                >
                  QuickTrack Qualified Solution Policy
                </a>
                suffix
              </Trans>
            }
            exception={testingInfo.coreCCException}
            defaultExplanation={defaultCcExplanation}
            isChecked={componentCombination.componentCombinationVariantSource?.coreCCExceptionRequested === true}
            handleExplanationChange={handleCoreCCExplanationChange}
            handleExplanationAttachmentsChange={handleCoreCCExplanationAttachmentsChange}
            requestExceptionChange={handleRequestCoreCCExceptionChange}
            setExceptionAttachmentLoading={setExceptionAttachmentLoading}
            errors={errors}
          />
        </div>
      ) : null}
      <Exception
        text={t('applications.labs.requestExceptionRequired')}
        field={'testingInfo.testingException.explanation'}
        exception={testingInfo.testingException}
        isChecked={testingInfo.testingException !== null}
        handleExplanationChange={handleExplanationChange}
        handleExplanationAttachmentsChange={handleExplanationAttachmentsChange}
        requestExceptionChange={handleRequestExceptionChange}
        setExceptionAttachmentLoading={setExceptionAttachmentLoading}
        errors={errors}
      />
    </div>
  );
};
