import React from 'react';
import { ExpandCollapseIcon } from '../../../../shared-components/theme';
import { useTranslation } from 'react-i18next';

export const RoleItemName = ({
  isOpen = false,
  roleName,
  onToggle,
}: {
  isOpen: boolean;
  roleName?: string;
  onToggle: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <p className="mb-1">
      <span className="pointer" onClick={onToggle}>
        <ExpandCollapseIcon isOpen={isOpen} />
        <strong style={{ marginLeft: '0.3rem' }}>{t('applications.cc.role')}:</strong> {roleName}
      </span>
    </p>
  );
};
