import React, { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import PropTypes from 'prop-types';

const View = (props: PropsWithChildren<HTMLAttributes<HTMLDivElement>> & { show: boolean }) => {
  const { children, className = '', show } = props;
  const classNames = [];
  const mixed = { style: { ...(children as ReactElement).props.style } };
  if (!show) {
    mixed.style.display = 'none';
  }
  if ((children as ReactElement).props.className) {
    classNames.push((children as ReactElement).props.className);
  }
  if (className) classNames.push(className);
  // @ts-ignore
  mixed.className = classNames.join(' ');
  // @ts-ignore
  return React.cloneElement(React.Children.only(children), mixed);
};
/* eslint-disable */
View.propTypes = {
  show: PropTypes.any,
};
/* eslint-enable */
View._typeName = 'View';

export default View;
