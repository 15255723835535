import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { AnyAction } from 'redux';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  addLabCertifications,
  getAvailableLabCertificationsById,
  removeLabCertifications,
  updateLabCertification,
} from '../../../../api/company/lab-management/certifications';
import { getImpersonationCompanies } from '../../../../api/impersonate';
import { getLabInfoById } from '../../../../api/lab';
import { Qualification } from '../../../../components/pages/admin/atl-qualifications/types';
import { Role } from '../../../../components/partials/impersonate/types';
import { handleRequestFail } from '../../../../helpers/request-fail-handler';
import {
  ActionWithToast,
  hideUpdateQualificationModal,
  labQualificationRemoved,
  publishSelectedCertificationError,
  selectedCertificationPublished,
  setAvailableCertifications,
  setLabInfo,
  setLabs,
} from './actionCreators';
import {
  LOAD_AVAILABLE_CERTIFICATIONS_LIST,
  LOAD_LAB_INFO,
  LOAD_LABS_LIST,
  PUBLISH_SELECTED_CERTIFICATIONS,
  REMOVE_LAB_QUALIFICATION,
  UPDATE_LAB_QUALIFICATION,
} from './actionTypes';
import { selectAtlQualificationsState } from './selectors';

function* request<T, K>(
  addToast: AddFn,
  callFn: (v: T) => Promise<AxiosResponse<K>>,
  callArg: T,
  action: (data: K) => AnyAction,
) {
  try {
    const response = yield call(callFn, callArg);
    yield put(action(response.data));
  } catch (error) {
    handleRequestFail(error, addToast);
  }
}

export function* loadLabsListSaga(action: ActionWithToast) {
  yield request(action.addToast, getImpersonationCompanies, Role.LAB, setLabs);
}

export function* loadLabInfoSaga(action: ActionWithToast) {
  yield request(action.addToast, getLabInfoById, action.payload, setLabInfo);
}

export function* loadAvailableCertificationsListSaga(action: ActionWithToast) {
  yield request(action.addToast, getAvailableLabCertificationsById, action.payload, setAvailableCertifications);
}

export function* updateLabQualificationSaga(action: ActionWithToast) {
  const { labId, certification, interoperability, conformance } = action.payload;
  try {
    yield call(updateLabCertification, labId, certification.version.id, interoperability, conformance);
    yield fork(action.addToast, i18next.t('admin.qualifications.notifications.saved'), {
      appearance: 'success',
      autoDismiss: true,
    });

    if (action.refreshSummary) {
      yield fork(action.refreshSummary);
    }
  } catch (error) {
    handleRequestFail(error, action.addToast);
  }

  yield put(hideUpdateQualificationModal());
}

export function* removeLabQualificationSaga(action: ActionWithToast) {
  const { labId, versionIds } = action.payload;
  try {
    yield call(removeLabCertifications, labId, versionIds);
    yield fork(action.addToast, i18next.t('admin.qualifications.notifications.removed'), {
      appearance: 'success',
      autoDismiss: true,
    });

    if (action.refreshSummary) {
      yield fork(action.refreshSummary);
    }
  } catch (error) {
    handleRequestFail(error, action.addToast);
  }

  yield put(labQualificationRemoved());
}

export function* publishLabQualificationSaga(action: ActionWithToast) {
  const { selectedCertifications } = yield select(selectAtlQualificationsState);
  const hasInvalidSettings = selectedCertifications.some((q: Qualification) => !q.interoperability && !q.conformance);

  if (hasInvalidSettings) {
    yield fork(action.addToast, i18next.t('admin.qualifications.notifications.validationError'), {
      appearance: 'error',
      autoDismiss: true,
    });
    yield put(publishSelectedCertificationError());
  } else {
    try {
      yield call(addLabCertifications, action.payload, selectedCertifications);
      yield fork(action.addToast, i18next.t('admin.qualifications.notifications.saved'), {
        appearance: 'success',
        autoDismiss: true,
      });

      yield put(selectedCertificationPublished());
      yield fork(action.redirect);
    } catch (error) {
      handleRequestFail(error, action.addToast);
    }
  }
}

function* watchLoadLabsListSagaSaga() {
  yield takeLatest(LOAD_LABS_LIST, loadLabsListSaga);
}

function* watchLoadLabInfoSaga() {
  yield takeLatest(LOAD_LAB_INFO, loadLabInfoSaga);
}

function* watchLoadAvailableCertificationsListSaga() {
  yield takeLatest(LOAD_AVAILABLE_CERTIFICATIONS_LIST, loadAvailableCertificationsListSaga);
}

function* watchUpdateLabQualificationSaga() {
  yield takeLatest(UPDATE_LAB_QUALIFICATION, updateLabQualificationSaga);
}

function* watchRemoveLabQualificationSaga() {
  yield takeLatest(REMOVE_LAB_QUALIFICATION, removeLabQualificationSaga);
}

function* watchPublishLabQualificationSaga() {
  yield takeLatest(PUBLISH_SELECTED_CERTIFICATIONS, publishLabQualificationSaga);
}

export default function* watchAtlQualificationsSagas() {
  yield all([
    watchLoadLabsListSagaSaga(),
    watchLoadLabInfoSaga(),
    watchLoadAvailableCertificationsListSaga(),
    watchUpdateLabQualificationSaga(),
    watchRemoveLabQualificationSaga(),
    watchPublishLabQualificationSaga(),
  ]);
}
