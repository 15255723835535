import * as actionTypes from './actionTypes';
import {
  modalPropsByActionTypes,
  NoteActionTypes,
  RevokeActionTypes,
  revokeModalPropsByActionTypes,
} from './constants';
import { NoteModal, RevokeModal } from './reducer';
import { CertificationType } from '../../../components/pages/certification/types';

export function showCloneCertificationModal(payload: { id: number; type: CertificationType }) {
  return {
    type: actionTypes.SET_CERTIFICATION_CLONE_MODAL_VALUE,
    payload,
  };
}

export function hideCloneCertificationModal() {
  return {
    type: actionTypes.SET_CERTIFICATION_CLONE_MODAL_VALUE,
    payload: null,
  };
}

export function showAddNoteModal(payload: Partial<NoteModal>, action: NoteActionTypes) {
  return {
    type: actionTypes.SET_ADD_NOTE_MODAL_VALUE,
    payload: {
      ...modalPropsByActionTypes[action],
      ...payload,
    },
  };
}

export function toggleUpdateNotes(payload: boolean) {
  return {
    type: actionTypes.TOGGLE_UPDATE_NOTES,
    payload,
  };
}

export function hideAddNoteModal(payload: NoteActionTypes) {
  return {
    type: actionTypes.RESTORE_ADD_NOTE_MODAL_VALUE,
    payload,
  };
}

export function hideRevokeNoteModal(payload: RevokeActionTypes) {
  return {
    type: actionTypes.RESTORE_REVOKE_NOTE_MODAL_VALUE,
    payload,
  };
}

export function showRevokeModal(payload: Partial<RevokeModal>, action: RevokeActionTypes) {
  const revokeModalData = revokeModalPropsByActionTypes[action];
  return {
    type: revokeModalData.checkApi ? actionTypes.CHECK_REVOKE_STATUS : actionTypes.SET_REVOKE_MODAL_VALUE,
    payload: {
      ...revokeModalData,
      ...payload,
    },
  };
}
