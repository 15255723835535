import React from 'react';

import { TextField, TextFieldProps, IconButton, Box } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// @ts-ignore
import _scss from '../../../../../styles/_variables.scss';

type Props = TextFieldProps & {
  hasErrors?: boolean;
  onNumberChange?: (x: number) => void;
  width?: number;
};

const StyledTextField = withStyles((theme: Theme) => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: theme.typography.fontSize,
      '& input': {
        padding: '0.6rem',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 2,
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: _scss.errorRed,
    },
  },
}))(TextField);

const StyledIconButton = withStyles({
  root: {
    padding: 1.5,
  },
})(IconButton);

export const CapabilityInputNumber = ({ onNumberChange, value, hasErrors = false, width = 100 }: Props) => {
  const increaseNum = () => {
    onNumberChange && onNumberChange(parseInt(value as string) + 1);
  };

  const decreaseNum = () => {
    const numberValue = parseInt(value as string);
    onNumberChange && onNumberChange(numberValue == 0 ? 0 : numberValue - 1);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let numberValue = parseInt(event.target.value);
    if (isNaN(numberValue) || numberValue < 0) numberValue = 0;

    onNumberChange && onNumberChange(numberValue);
  };

  return (
    <div style={{ width: width }}>
      <StyledTextField
        value={(value as number).toString()}
        onChange={handleOnChange}
        type="number"
        size="small"
        variant="outlined"
        error={hasErrors}
        InputProps={{
          endAdornment: (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <StyledIconButton onClick={increaseNum}>
                <ExpandLessIcon style={{ fontSize: 12 }} />
              </StyledIconButton>
              <StyledIconButton onClick={decreaseNum}>
                <ExpandMoreIcon style={{ fontSize: 12 }} />
              </StyledIconButton>
            </Box>
          ),
        }}
      />
    </div>
  );
};
