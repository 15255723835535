import { createSelector } from 'reselect';
import { RootReducer } from '../../rootReducer';
import { AppFlows, CERTIFY_PRODUCT_COMPANY } from './reducer';
import { selectUser } from '../user/selectors';

const selectApplicationFlow = (state: RootReducer) => state.applicationFlow;

const selectRoles = (state: RootReducer) => state.rolesReducer.roles;

export const selectMissingRoles = createSelector([selectApplicationFlow, selectRoles], (app, roles) => {
  const selectedCertRoles = Array.from(new Set(app.certifications.map(cert => cert.role.id)));
  const selectedRoles = roles.filter(
    role =>
      app.componentCombination &&
      app.componentCombination.componentCombination &&
      !!app.componentCombination.componentCombination[role.propertyName],
  );

  return selectedRoles.filter(role => !selectedCertRoles.includes(role.id));
});

export const selectsShowInvoiceToOptions = createSelector([selectApplicationFlow], app => {
  return (
    app.companyContactInfo.applicationOwner &&
    app.companyContactInfo.applicationOwner['@type'] === CERTIFY_PRODUCT_COMPANY.ON_BEHALF_OF
  );
});

export const selectRequiresPurchaseOrder = createSelector(
  [selectApplicationFlow, selectUser, selectsShowInvoiceToOptions],
  (app, user, showInvoiceToOptions) => {
    const payerId = app.additionalInfo?.payer?.id;
    if (app['@type'] !== AppFlows.DERIVATIVE || !payerId) {
      return false;
    }

    if (!showInvoiceToOptions || payerId === user.company.id) {
      return user.company.requiresPurchaseOrder;
    }

    return !!app.companyContactInfo.owningCompany?.requiredPurchaseOrder;
  },
);

export const selectInvoiceToOptions = createSelector(
  [selectApplicationFlow, selectUser, selectsShowInvoiceToOptions],
  (app, user, showInvoiceToOptions) => {
    const invoiceToOptions = [
      {
        id: user?.company?.id,
        name: user?.company?.name,
      },
    ];

    if (showInvoiceToOptions) {
      invoiceToOptions.push({
        id: app.companyContactInfo.owningCompany?.id as number,
        name: app.companyContactInfo.owningCompany?.name as string,
      });
    }

    return invoiceToOptions;
  },
);

export const selectComponentCombinationVariantId = createSelector([selectApplicationFlow], app => {
  if (app.componentCombination.componentCombinationVariantSource?.id) {
    return app.componentCombination.componentCombinationVariantSource.id;
  }

  if (app.companyContactInfo?.source?.id) {
    return app.companyContactInfo?.source?.id;
  }

  return null;
});

export const selectFeesResponse = createSelector([selectApplicationFlow], app => {
  return app.fees || [];
});
