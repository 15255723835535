import React from 'react';
import { AppCapability, AppCertPrereqs } from '../../../../../../../redux/modules/application-flow';
import { renderTreeToggleIconFunction } from '../../../../../../../shared-components/theme';
import classNames from 'classnames';
import styled from 'styled-components';
import { CapabilityCheckbox } from './CapabilityCheckbox';
import { getCapabilitiesTree } from './helpers';
import { CapabilitiesStepData } from '../../CapabilitiesStep';
// @ts-ignore
import _var from '../../../../../../../styles/_variables.scss';
import uniqid from 'uniqid';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${_var.secondary};
  border-top: 1px solid ${_var.grayBorder};
  border-bottom: 1px solid ${_var.grayBorder};
  margin-top: 1px;
  padding: 0.7rem 1rem;

  .icon-wrapper {
    min-width: 20px;
    display: inline-block;
  }

  .name-wrapper {
    padding-left: 0.5rem;
  }

  > div {
    flex: 2;
  }

  > span {
    flex: 1;
  }
`;

export interface ReformattedCapabilities {
  id: number;
  name: string;
  displayParent: number | null;
  mandatory: boolean;
  index: number;
  prerequisites: AppCertPrereqs[];
  children: ReformattedCapabilities[];
  minChildren: number;
  maxChildren: number;
}

export const CapabilityRow = ({
  certification,
  isExpanded,
  setExpanded,
  handleCapabilityCheck,
}: {
  certification: CapabilitiesStepData;
  isExpanded: boolean;
  setExpanded: (x: number) => void;
  handleCapabilityCheck: (capability: AppCapability, checked: boolean, needToShowUncheckPrecertified: boolean) => void;
}) => {
  const hasChildren = certification.capabilities.length > 0;

  const handleExpand = () => {
    if (!hasChildren) {
      return;
    }
    setExpanded(certification.id);
  };

  return (
    <div>
      <Header>
        <div className={classNames({ pointer: hasChildren })} onClick={handleExpand}>
          <span className="icon-wrapper">{renderTreeToggleIconFunction(isExpanded, hasChildren)}</span>
          <span className="name-wrapper">{certification.name}</span>
        </div>
        <span>{certification.role.name}</span>
        <span className="text-right">{certification.version.name}</span>
      </Header>
      {isExpanded ? (
        <div style={{ marginLeft: '0rem' }}>
          {getCapabilitiesTree(certification.capabilities).map(item => {
            return (
              <CapabilityCheckbox
                capabilityInfo={item}
                key={uniqid()}
                primaryVersionId={certification.version.id}
                onChange={handleCapabilityCheck}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
