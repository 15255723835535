import axios from '../../helpers/axios-setup';
import { SortingOptions } from '../../components/pages/test-case/types';
import { buildSummaryURL } from '../../helpers/build-summary-url';

export const getTransferProducts = (
  companyId: number,
  page: number,
  sortingOptions?: SortingOptions,
  search?: string | null,
  from?: string | null,
  to?: string | null,
) =>
  axios.get(
    buildSummaryURL(`admin/company/${companyId}/product-models/all`, page, sortingOptions, {
      search,
      from,
      to,
    }),
  );

export const getAllTransferProducts = (companyId: number) =>
  axios.get(`admin/company/${companyId}/product-models/transferableids`);

export const submitTransferProducts = (sourceCompanyId: number, targetCompanyId: number, productIds: number[]) => {
  const payload = {
    sourceCompanyId,
    targetCompanyId,
    productIdInfo: {
      productIds: [...productIds],
    },
  };
  return axios.post('/admin/product-models/transfer', payload);
};

export const getTransferSummary = () => {
  // set size=9999 for pagination by frontend
  return axios.get('/admin/product-models/transfer/summary?size=9999');
};

export const getTransferDetail = (id: number) => {
  return axios.get(`/admin/product-models/transfer/${id}`);
};

export const downloadTransferResult = (id: number, isSucceed: boolean) => {
  return axios.get(`/admin/product-models/transfer/${id}/download?success=${isSucceed}`);
};
