import React from 'react';
import { HomeDesignProps } from '../../types';
import { DetailsItem } from '../../../../partials';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RolesState } from '../../../../../redux/modules/roles/reducer';
import { Role } from '../../../../../redux/modules/roles/types';
import { roleNameMapping } from '../../data/constants';
import { RowWithMargin } from '../ApplicationDetails';
import { Col, Panel } from 'rsuite';
import styled from 'styled-components';
// @ts-ignore
import _variables from '../../../../../styles/_variables.scss';
import { WrapWithPublicIcon } from '../../../../../shared-components/helper-components/WrapWithPublicIcon';

export const Equipment = styled(Panel)`
  .rs-panel-body {
    background-color: ${_variables.lightGray};
    border: 1px solid ${props => (props.highlighted ? _variables.errorRed : _variables.lightGray)};
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-radius: 6px;
    }
  }
`;

export const EquipmentRow = ({ label, value }: { label: string; value: string }) => (
  <RowWithMargin gutter={10}>
    <Col xs={8}>
      <strong>{label}</strong>
    </Col>
    <Col xs={16}>{value}</Col>
  </RowWithMargin>
);

const LabelWithIcon = ({ showPublicIcon, label }: { showPublicIcon: boolean; label: string }) =>
  showPublicIcon ? <WrapWithPublicIcon content={label} /> : <>{label}</>;

export const HomeDesign = ({
  record,
  showPublicIcon = false,
}: {
  record: HomeDesignProps;
  showPublicIcon?: boolean;
}) => {
  const { t } = useTranslation();
  const roles = useSelector<{ rolesReducer: RolesState }, Role[]>(state => state.rolesReducer.roles);
  const roleName = roles.find(item => item.propertyName === roleNameMapping.homeDesign)?.name;

  return (
    <div className="mb-2">
      <p className="mb-1">
        <strong>{t('applications.cc.role')}:</strong> {roleName}
      </p>
      <RowWithMargin gutter={20}>
        <Col xs={12}>
          {!!record.floors && (
            <DetailsItem
              label={<LabelWithIcon showPublicIcon={showPublicIcon} label={t('applications.cc.floors')} />}
              value={record.floors}
            />
          )}
          {!!record.units && (
            <DetailsItem
              label={<LabelWithIcon showPublicIcon={showPublicIcon} label={t('applications.cc.units')} />}
              value={record.units}
            />
          )}
          <DetailsItem
            label={<LabelWithIcon showPublicIcon={showPublicIcon} label={t('applications.cc.numberOfAccessPoints')} />}
            value={record.numberOfAccessPoints}
          />
          <DetailsItem
            label={<LabelWithIcon showPublicIcon={showPublicIcon} label={t('applications.cc.squareFootage')} />}
            value={record.squareFootage}
          />
          <DetailsItem
            label={<LabelWithIcon showPublicIcon={showPublicIcon} label={t('applications.cc.apInterconnect')} />}
            value={record.apInterconnect}
          />
        </Col>
        <Col xs={12}>
          <p className="mb-1">
            <strong>
              <WrapWithPublicIcon content={t('applications.cc.equipment')} />
            </strong>
          </p>
          {record.equipment.map(equipment => (
            <Equipment key={equipment.id}>
              <EquipmentRow label={t('applications.cc.cid')} value={equipment.cid} />
              <EquipmentRow label={t('applications.cc.apVendor')} value={equipment.vendor.name} />
              <EquipmentRow label={t('applications.cc.modelNumber')} value={equipment.modelNumber} />
            </Equipment>
          ))}
        </Col>
      </RowWithMargin>
    </div>
  );
};
