import { IterableObject } from '../types';
import i18n from '../i18n-setup';

export enum ProductTypes {
  QUALIFIED_SOLUTION = 'QUALIFIED_SOLUTION',
  QUICKTRACK = 'QUICKTRACK',
  FLEXTRACK = 'FLEXTRACK',
}

export const productTypes: IterableObject<string> = {
  qualifiedSolution: ProductTypes.QUALIFIED_SOLUTION,
  quickTrack: ProductTypes.QUICKTRACK,
  flexTrack: ProductTypes.FLEXTRACK,
};

export const productTypesTitles: IterableObject<string> = {
  [productTypes.qualifiedSolution]: i18n.t('common.productTypes.qualifiedSolution'),
  [productTypes.quickTrack]: i18n.t('common.productTypes.quickTrack'),
  [productTypes.flexTrack]: i18n.t('common.productTypes.flexTrack'),
};

export const getProductTypes = (type: string) => {
  switch (type) {
    case 'qualifiedSolution':
      return ProductTypes.QUALIFIED_SOLUTION;
    case 'basedOnQualifiedSolution':
      return ProductTypes.QUICKTRACK;
    case 'other':
      return ProductTypes.FLEXTRACK;
    default:
      break;
  }
};

export const productTypesTooltips: IterableObject<string> = {
  [productTypes.qualifiedSolution]: i18n.t('common.productTypes.tooltips.qualifiedSolution'),
  [productTypes.quickTrack]: i18n.t('common.productTypes.tooltips.quickTrack'),
  [productTypes.flexTrack]: i18n.t('common.productTypes.tooltips.flexTrack'),
};
