import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import dot from 'dot-object';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import { AxiosResponse } from 'axios';
import { Button, Col, Form, FormGroup, Radio, RadioGroup, Row } from 'rsuite';

import '../../../styles/NewApplication.scss';

import { CreateApplicationForm } from './types';
import { ActionLeft } from './partials/create-new-application/HeaderActions';
import { AppFlows, CERTIFY_PRODUCT_COMPANY, newApplicationStarted } from '../../../redux/modules/application-flow';
import { useChangeWrapper } from '../../../helpers/storage/formik-change-wrapper';
import { PageTemplate } from '../../partials';
import { RsControlLabel, RsField } from '../../../shared-components/rsuite';
import { AcknowledgeModal, FindProductVariant, FindVariantForApp } from './partials';
import { getDerivativeCompanies } from '../../../api/application';
import { jsonCopy, mergeObjects, prepareYupModel } from '../../../helpers';
import { applicationOptionsScheme } from './data/validation-schemes';
import { DerivativeType } from '../../../api/application/types';
import { ErrorObject } from '../../../helpers/types';
import { userState } from '../../../redux/modules/user/reducer';
import { User } from '../../../redux/modules/user/types';
import { atlRoles, implementerMemberRole } from '../../../helpers/constants';
import 'react-multi-email/style.css';
import { initialState } from '../../../redux/modules/application-flow/data';
import { LabeledSelect } from '../../../shared-components/labeled-inputs';
import { getCompanies } from '../../../api/behalf/get-companies';
import { getVariantInfoById } from '../../../api/product';
import { VariantInfo } from '../product/types';
import { NotSupportedHomeDesignModal } from './partials/NotSupportedHomeDesignModal';
import { ApplicationTypesTooltipContent } from './partials/new-application-flow-steps/VariantNameTooltipContent';
import { Tooltip } from '../../../shared-components/tooltip/Tooltip';
import { WarningCard } from '../../../shared-components/warning-card/WarningCard';
import { CircularLoader } from '../../../shared-components/loader/CircularLoader';
import { AutocompleteField } from '../../../shared-components/hierarchical-dropdown/HierarchicalDropdown';

export interface BehalfCompany {
  id: number;
  name: string;
  allowedAppTypes: AppFlows[];
  qualifiedSolutionProvider: boolean;
}

interface Companies {
  derivative: { id: number; name: string }[];
  behalf: BehalfCompany[];
}

enum LoadingValues {
  NONE,
  DERIVATIVE,
  ON_BEHALF_OF,
}

const pageComponent = ({ history, location }: RouteComponentProps<{}>) => {
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState<ErrorObject>({});
  const [showAcknowledgePopup, setShowAcknowledgePopup] = useState(false);
  const [showHomeDesignPopup, setShowHomeDesignPopup] = useState(false);
  const [companies, setCompanies] = useState<Companies>({ derivative: [], behalf: [] });
  const [loading, setLoading] = useState(LoadingValues.NONE);
  const emptyDerivativeErrors = { 'companyContactInfo.source.id': '', 'companyContactInfo.source.cid': '' };
  const { authority, company } = useSelector<{ userReducer: userState }, User>(state => state.userReducer.user as User);

  const defaultDerivativeValue = {};
  const supportLink = 'support@wi-fi.org';

  const getAllowedAppTypes = useCallback(() => {
    if (atlRoles.includes(authority)) {
      return [];
    }
    return authority === implementerMemberRole
      ? [AppFlows.DERIVATIVE]
      : [AppFlows.NEW, AppFlows.DERIVATIVE, AppFlows.VARIANT];
  }, []);

  const [formValues, setFormValues] = useState<CreateApplicationForm>({
    type: atlRoles.includes(authority) ? '' : AppFlows.NEW,
    majorEvents: {},
    companyContactInfo: {
      additionalEmail: [],
      source: null,
      owningCompany: {
        ...company,
        solutionProvider: company.qualifiedSolutionProvider,
      },
      creatingCompany: {
        ...company,
        solutionProvider: company.qualifiedSolutionProvider,
      },
    },
    certifyProductCompany: atlRoles.includes(authority)
      ? CERTIFY_PRODUCT_COMPANY.ON_BEHALF_OF
      : CERTIFY_PRODUCT_COMPANY.MY_COMPANY,
    allowedAppTypes: getAllowedAppTypes(),
    acknowledged: false,
  });

  const [formId] = useState(uniqid());
  const { formCreated } = useChangeWrapper(formId);
  const dispatch = useDispatch();
  const hasInvalidEmails = formValues.companyContactInfo.additionalEmail.some(email => !isEmail(email));
  const certifyAnotherCompany = formValues.certifyProductCompany === CERTIFY_PRODUCT_COMPANY.ON_BEHALF_OF;

  const getDisabledItems = useCallback(() => {
    if (authority === implementerMemberRole) {
      return [CERTIFY_PRODUCT_COMPANY.ON_BEHALF_OF];
    } else if (atlRoles.includes(authority)) {
      return [CERTIFY_PRODUCT_COMPANY.MY_COMPANY];
    }
    return [];
  }, []);

  const handleFieldChange = (
    value: string | number | undefined | (BehalfCompany & { solutionProvider: boolean }),
    name: string,
  ) => {
    setFormValues(prevState => {
      const newState = jsonCopy(prevState);
      if (name === 'type') {
        if (value === AppFlows.NEW) {
          newState.companyContactInfo.source = null;
          if (location.search) {
            history.push({
              pathname: location.pathname,
            });
          }
        }
        if (value === AppFlows.DERIVATIVE) {
          newState.companyContactInfo.source = defaultDerivativeValue as DerivativeType;
          setFormErrors(errorState => ({ ...errorState, ...emptyDerivativeErrors }));
        }
        if (value === AppFlows.VARIANT) {
          newState.companyContactInfo.source = { cid: '', id: -1, productId: -1 };
          setFormErrors(errorState => ({ ...errorState, 'companyContactInfo.source.id': '' }));
        }
      }
      if (name === 'certifyProductCompany') {
        setFormErrors({});
        if (value === CERTIFY_PRODUCT_COMPANY.MY_COMPANY) {
          newState.type = '';
          newState.allowedAppTypes = getAllowedAppTypes();
          newState.companyContactInfo = {
            ...newState.companyContactInfo,
            owningCompany: company,
          };
        } else {
          newState.allowedAppTypes = [];
          newState.type = '';
          newState.companyContactInfo = { ...newState.companyContactInfo, owningCompany: null };
        }
      }
      if (name === 'companyContactInfo.owningCompany') {
        newState.allowedAppTypes = (value as BehalfCompany).allowedAppTypes;
        newState.type = '';
        newState.companyContactInfo = { ...newState.companyContactInfo };
        setCompanies(prevCompaniesState => ({ ...prevCompaniesState, derivative: [] }));
        setFormErrors({});
      }
      if (name === 'companyContactInfo.source.id') {
        newState.companyContactInfo.source = {
          id: undefined,
          cid: newState.companyContactInfo.source?.cid || '',
          productId: newState.companyContactInfo.source?.productId || -1,
        };
      }
      dot.set(name, value, newState);
      const errors = prepareYupModel(applicationOptionsScheme).checkForFieldFormatted(name, newState);
      setFormErrors(errorState => ({ ...errorState, ...errors }));
      return newState;
    });
  };

  const onApplicationTypeChange = (value: string) => {
    handleFieldChange(value, 'type');
  };

  const onCertifyProductCompanyChange = (value: string) => {
    handleFieldChange(value, 'certifyProductCompany');
  };

  const onOwningCompanySelect = (value: number, company?: BehalfCompany) => {
    const item = (company || companies.behalf.find(behalfCompany => behalfCompany.id === value)) as BehalfCompany;
    handleFieldChange(
      { ...item, solutionProvider: item.qualifiedSolutionProvider },
      'companyContactInfo.owningCompany',
    );
  };

  // as `URLSearchParams().get('')` will return null when param is not present
  // number will convert null to zero
  // we need to check for value && isNaN
  const isNumber = useCallback((value: number) => value && !Number.isNaN(value), []);

  const defaultProps = useMemo(() => {
    const prefilledParams = new URLSearchParams(location.search);

    const props: {
      defaultVariantId: undefined | number;
      defaultSourceProductId: undefined | number;
      defaultCompanyId: undefined | number;
      type: undefined | string;
    } = {
      defaultVariantId: undefined,
      defaultSourceProductId: undefined,
      defaultCompanyId: undefined,
      type: undefined,
    };

    if (location.search) {
      props.type = prefilledParams.get('type') === AppFlows.DERIVATIVE ? AppFlows.DERIVATIVE : AppFlows.VARIANT;
      const defaultVariantId = Number(prefilledParams.get('variantId'));
      const defaultSourceProductId = Number(prefilledParams.get('sourceProductId'));
      const defaultCompanyId = Number(prefilledParams.get('companyId'));
      if (isNumber(defaultVariantId) && isNumber(defaultSourceProductId) && isNumber(defaultCompanyId)) {
        props.defaultVariantId = defaultVariantId;
        props.defaultSourceProductId = defaultSourceProductId;
        props.defaultCompanyId = defaultCompanyId;
        handleFieldChange(props.type, 'type');
        handleFieldChange(defaultVariantId, 'companyContactInfo.source.id');
      }
    }
    return props;
  }, [location.search]);

  useEffect(() => {
    if (defaultProps.type === AppFlows.VARIANT && defaultProps.defaultCompanyId !== company.id) {
      if (formValues.certifyProductCompany === CERTIFY_PRODUCT_COMPANY.MY_COMPANY) {
        onCertifyProductCompanyChange(CERTIFY_PRODUCT_COMPANY.ON_BEHALF_OF);
      }

      const onBehalfOf = companies.behalf.find(item => item.id === defaultProps.defaultCompanyId);
      if (onBehalfOf && !formValues.companyContactInfo.owningCompany) {
        if (onBehalfOf.allowedAppTypes.includes(AppFlows.VARIANT)) {
          onOwningCompanySelect(onBehalfOf.id, onBehalfOf);
          if (defaultProps.type === AppFlows.VARIANT) {
            onApplicationTypeChange(AppFlows.VARIANT);
          }
        } else {
          onCertifyProductCompanyChange(CERTIFY_PRODUCT_COMPANY.MY_COMPANY);
        }
      }
    }
  }, [defaultProps, companies]);

  const handleEmailChange = (value: string[]) =>
    setFormValues(prevState => mergeObjects(prevState, { companyContactInfo: { additionalEmail: value } }));

  const handleNext = () => {
    const errors = prepareYupModel(applicationOptionsScheme).checkFormatted(formValues);
    if (errors || hasInvalidEmails) {
      errors && setFormErrors(errors as ErrorObject);
      return;
    }

    // Uncomment when home design is implemented
    // setShowAcknowledgePopup(true);

    // Remove when home design is implemented
    if (
      [AppFlows.VARIANT, AppFlows.DERIVATIVE].includes(formValues.type as AppFlows) &&
      formValues.companyContactInfo.source
    ) {
      getVariantInfoById(formValues.companyContactInfo.source.id).then(({ data }: AxiosResponse<VariantInfo>) => {
        if (
          data.componentCombination?.componentCombination &&
          'homeDesign' in data.componentCombination?.componentCombination &&
          data.componentCombination.componentCombination.homeDesign
        ) {
          setShowHomeDesignPopup(true);
        } else {
          setShowAcknowledgePopup(true);
        }
      });
    } else {
      setShowAcknowledgePopup(true);
    }
  };

  const onAcceptAcknowledge = () => {
    formValues.acknowledged = true;
    const { companyContactInfo } = formValues;
    if (companyContactInfo.owningCompany === null) {
      companyContactInfo.owningCompany = {
        id: company.id,
        name: company.name,
      };
    }
    formCreated({ ...initialState, formId, ...formValues, companyContactInfo, '@type': formValues.type });
    dispatch(newApplicationStarted(formValues));
    history.push(`/application/${formId}/${formValues.type.toLocaleLowerCase()}?step=1`);
  };

  const onDoNotAcceptAcknowledge = () => setShowAcknowledgePopup(false);

  useEffect(() => {
    // if user is implementer reset type to derivative
    if (authority === implementerMemberRole) {
      handleFieldChange(AppFlows.DERIVATIVE, 'type');
    }
  }, []);

  useEffect(() => {
    if (formValues.type === AppFlows.DERIVATIVE && companies.derivative.length === 0) {
      setLoading(LoadingValues.DERIVATIVE);
      getDerivativeCompanies(formValues.companyContactInfo.owningCompany?.id || company.id)
        .then(response => {
          setCompanies(prevState => ({ ...prevState, derivative: response.data }));
          setLoading(LoadingValues.NONE);
        })
        .catch(() => setLoading(LoadingValues.NONE));
    }
  }, [formValues.type, formValues.companyContactInfo.owningCompany]);

  useEffect(() => {
    if (certifyAnotherCompany && companies.behalf.length === 0) {
      setLoading(LoadingValues.ON_BEHALF_OF);
      getCompanies()
        .then(response => {
          setCompanies(prevState => ({ ...prevState, behalf: response.data }));
          setLoading(LoadingValues.NONE);
        })
        .catch(() => setLoading(LoadingValues.NONE));
    }
  }, [formValues.certifyProductCompany]);

  const isNextDisabled =
    loading !== LoadingValues.NONE ||
    (certifyAnotherCompany && !formValues.companyContactInfo.owningCompany?.id) ||
    (formValues.type !== AppFlows.NEW &&
      (!formValues.companyContactInfo.source?.id || formValues.companyContactInfo.source?.id === -1));

  return (
    <PageTemplate
      title="New Application"
      actionLeft={<ActionLeft history={history} />}
      actionRight={
        <Button appearance="primary" className="next-step" onClick={handleNext} disabled={isNextDisabled}>
          {t('common.navigation.next')}
        </Button>
      }
    >
      <h5 className="mb-2 mt-1">{t('applications.steps.step', { number: 0 }) + t('applications.steps.0')}</h5>
      <Form fluid>
        <Row className="mt-2" gutter={20}>
          <Col xs={24}>
            <FormGroup>
              <Row gutter={20}>
                <Col xs={12}>
                  <LabeledSelect
                    data={[
                      { value: CERTIFY_PRODUCT_COMPANY.MY_COMPANY, label: t('applications.flowSelect.myCompany') },
                      {
                        value: CERTIFY_PRODUCT_COMPANY.ON_BEHALF_OF,
                        label: t('applications.flowSelect.anotherCompany'),
                      },
                    ]}
                    label={t('applications.flowSelect.productOptions')}
                    value={formValues.certifyProductCompany}
                    onChange={onCertifyProductCompanyChange}
                    errors={formErrors}
                    placeholder={t('common.placeholders.select')}
                    name="certifyProductCompany"
                    cleanable={false}
                    searchable={false}
                    disabledItemValues={getDisabledItems()}
                    noMargin
                    block
                  />
                  {certifyAnotherCompany ? (
                    <div style={{ position: 'relative', minHeight: '40px' }}>
                      {loading === LoadingValues.ON_BEHALF_OF ? (
                        <CircularLoader content={t('common.placeholders.loadingData')} size={20} />
                      ) : (
                        <div style={{ paddingBottom: '1.2rem' }}>
                          <AutocompleteField
                            data={companies.behalf}
                            errors={formErrors}
                            name="companyContactInfo.owningCompany"
                            placeholder="Choose a owning company"
                            labelKey="name"
                            valueKey="id"
                            onChange={onOwningCompanySelect}
                            value={formValues.companyContactInfo.owningCompany?.id}
                            hideClose
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                </Col>
                <Col xs={12}>
                  <RsControlLabel>{t('applications.flowSelect.additionalEmail')}</RsControlLabel>
                  <ReactMultiEmail
                    className="rs-input"
                    placeholder={t('applications.flowSelect.additionalEmailPlaceholder')}
                    emails={formValues.companyContactInfo.additionalEmail}
                    onChange={handleEmailChange}
                    validateEmail={() => true}
                    getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
                      return (
                        <div className={!isEmail(email) ? 'is-invalid' : ''} data-tag key={index}>
                          {email}
                          <span data-tag-handle onClick={() => removeEmail(index)}>
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                  {hasInvalidEmails ? (
                    <small className="text-danger">{t('applications.hasInvalidEmails')}</small>
                  ) : null}
                </Col>
              </Row>
              <RsField
                accepter={RadioGroup}
                name="type"
                label={
                  <>
                    {t('applications.flowSelect.applicationType')}
                    <Tooltip width={680} content={<ApplicationTypesTooltipContent />} />
                  </>
                }
                errors={formErrors}
                value={formValues.type}
                disabled={certifyAnotherCompany}
                onChange={onApplicationTypeChange}
                noMargin
              >
                <Radio value={AppFlows.NEW} disabled={!formValues.allowedAppTypes.includes(AppFlows.NEW)}>
                  {t('applications.flowSelect.new')}
                </Radio>
                <Radio value={AppFlows.VARIANT} disabled={!formValues.allowedAppTypes.includes(AppFlows.VARIANT)}>
                  {t('applications.flowSelect.variant')}
                </Radio>
                {formValues.type === AppFlows.VARIANT ? (
                  <Row gutter={20} className="mt-1">
                    <Col xs={12}>
                      <FindVariantForApp
                        appId={formValues.companyContactInfo.source?.id}
                        companyId={formValues.companyContactInfo.owningCompany?.id || company.id}
                        productId={formValues.companyContactInfo.source?.productId}
                        defaultSourceProductId={
                          defaultProps.type === AppFlows.VARIANT ? defaultProps.defaultSourceProductId : undefined
                        }
                        defaultVariantId={
                          defaultProps.type === AppFlows.VARIANT ? defaultProps.defaultVariantId : undefined
                        }
                        errors={formErrors}
                        onAppIdChange={value => handleFieldChange(value, 'companyContactInfo.source.id')}
                        onProductIdChange={value => handleFieldChange(value, 'companyContactInfo.source.productId')}
                      />
                    </Col>
                  </Row>
                ) : null}
                <Radio value={AppFlows.DERIVATIVE} disabled={!formValues.allowedAppTypes.includes(AppFlows.DERIVATIVE)}>
                  {t('applications.flowSelect.derivative')}
                </Radio>
              </RsField>
              {formValues.type === AppFlows.DERIVATIVE ? (
                <Row gutter={20} className="wrapper-derivative">
                  {loading === LoadingValues.DERIVATIVE ? (
                    <CircularLoader content={t('common.placeholders.loadingData')} size={20} />
                  ) : (
                    <>
                      <Col sm={12} style={{ padding: '0 10px' }}>
                        <FindProductVariant
                          products={companies.derivative}
                          defaultCompanyId={
                            defaultProps.type === AppFlows.DERIVATIVE ? defaultProps.defaultCompanyId : undefined
                          }
                          defaultSourceProductId={
                            defaultProps.type === AppFlows.DERIVATIVE ? defaultProps.defaultSourceProductId : undefined
                          }
                          id={formValues.companyContactInfo.source?.id}
                          cid={formValues.companyContactInfo.source?.cid}
                          onChange={value => handleFieldChange(value, 'companyContactInfo.source.id')}
                          onCIDChange={value => handleFieldChange(value, 'companyContactInfo.source.cid')}
                          errors={formErrors}
                          behalfCompanyId={formValues.companyContactInfo.owningCompany?.id}
                        />
                      </Col>
                      <Col sm={12}>
                        <div className="wrapper-derivative-warning">
                          <WarningCard
                            height="88px"
                            content={
                              <>
                                {t('applications.flowSelect.warningDerivative')}
                                <a href="mailto:support@wi-fi.org">{supportLink}</a>
                              </>
                            }
                          />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <AcknowledgeModal
        show={showAcknowledgePopup}
        onAccept={onAcceptAcknowledge}
        onCancel={onDoNotAcceptAcknowledge}
      />
      <NotSupportedHomeDesignModal show={showHomeDesignPopup} onConfirm={() => setShowHomeDesignPopup(false)} />
    </PageTemplate>
  );
};

export const CreateApplicationPage = withRouter(pageComponent);
