import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { UseSummaryTableProps } from '../../../../../helpers/types';
import {
  removeLabQualification,
  selectAtlQualificationsState,
  updateLabQualifications,
} from '../../../../../redux/modules/admin/atl-qualifications';
import { DefaultSummaryFilters } from '../../../../partials/DefaultSummaryFilters';
import { CollapsableSection } from '../../../certification/partials';
import { LabSummaryCertificationByGroup } from '../types';
import { EditQualificationModal } from './EditQualificationModal';
import { QualificationsSummaryTable } from './QualificationsSummaryTable';

type CertificationsSectionProps = UseSummaryTableProps<LabSummaryCertificationByGroup> & { labId: string | number };

export const CertificationsSection = (props: CertificationsSectionProps) => {
  const { labId, filters, onChangeFilters, refreshSummary, loading, ...rest } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { modal, isRemoving } = useSelector(selectAtlQualificationsState);

  const deleteQualification = (certification: LabSummaryCertificationByGroup) => {
    dispatch(removeLabQualification(labId, certification, addToast, refreshSummary));
  };

  const updateQualification = (
    cert: LabSummaryCertificationByGroup,
    interoperability: boolean,
    conformance: boolean,
  ) => {
    dispatch(updateLabQualifications(labId, cert, interoperability, conformance, addToast, refreshSummary));
  };

  return (
    <CollapsableSection
      title={t('certifications.certification')}
      showEditIcon={false}
      rightActions={<DefaultSummaryFilters appliedFilters={filters} onFiltersApply={onChangeFilters} hideTimeFilter />}
    >
      <QualificationsSummaryTable
        pagination
        loading={loading || isRemoving}
        deleteQualification={deleteQualification}
        enableExpandAll={true}
        {...rest}
      />
      {modal.isOpen && !!modal.certification && (
        <EditQualificationModal certification={modal.certification} updateQualification={updateQualification} />
      )}
    </CollapsableSection>
  );
};
